import { UserApi } from "@/client";
import { apiConfig } from "@/mixins";
import SocketUtils from "@/modules/websocket";

export const getDefaultStateUser = () => {
  return {
    uid: "",
    token: "",
    refreshToken: "",
    expiratioTime: "",
    isLoggedIn: false,

    isLoadingMyProfile: false,
    myProfile: {},

    badges: [],
    badgeStatuses: [],

    notices: [],

    me: {},
    loginModalShow: false,
  };
};

export default {
  namespaced: true,
  state: getDefaultStateUser(),
  getters: {
    token: (state) => () => {
      return state.token;
    },
    refreshToken: (state) => () => {
      return state.refreshToken;
    },
    refreshNotices: (state) => () => {
      return state.notices;
    },
    hasBadge: (state) => (code) => {
      for (let index = 0; index < state.badgeStatuses.length; index++) {
        const badge = state.badgeStatuses[index];
        if (badge.code === code) {
          // console.debug("has badge:", code);
          return true;
        }
      }
      return false;
    },
    isLoggedIn: (state) => () => {
      return state.isLoggedIn;
    },
    me: (state) => () => {
      return state.me;
    },
    badgeStatus: (state) => (code) => {
      for (let index = 0; index < state.badgeStatuses.length; index++) {
        const badge = state.badgeStatuses[index];
        if (badge.code === code) {
          // console.debug("has badge status:", code);
          return badge;
        }
      }
      return {};
    },
  },
  mutations: {
    me(state, payload) {
      state.me = payload;
    },
    store(state, user) {
      state.uid = user.uid;
      state.token = user.token;
      if (user.refreshToken) {
        state.refreshToken = user.refreshToken;
      }
      state.expiratioTime = user.expiratioTime;
      state.isLoggedIn = true;
    },
    storeIdToken(state, data) {
      state.token = data.token;
      if (data.refreshToken) {
        state.refreshToken = data.refreshToken;
      }
    },
    storeNotices(state, data) {
      if (data) {
        state.notices = data;
      } else {
        state.notices = [];
      }
    },
    drop(state) {
      state.uid = state.token = state.refreshToken = state.expiratioTime = "";
      state.isLoggedIn = false;
      state.myProfile = {};
      state.badges = [];
      state.badgeStatuses = [];
      state.notices = [];
      state.me = {};
    },
    storeMyProfile(state, profile) {
      state.myProfile = profile;
    },
    updateAvatar(state, avatarUrl) {
      state.myProfile.avatarURL = avatarUrl;
    },
    storeBadges(state, data) {
      state.badges = data;
    },
    storeBadgeStatuses(state, data) {
      state.badgeStatuses = data;
    },
    loadingMyProfile(state) {
      state.isLoadingMyProfile = true;
    },
    loadedMyProfile(state) {
      state.isLoadingMyProfile = false;
    },
  },
  actions: {
    async me(context) {
      const resp = await new UserApi(apiConfig()).getUser("me");
      if (resp.status === 200) {
        context.commit("me", resp.data);
        if (resp.data.id > 0) {
          SocketUtils.connect();
        }
      } else {
        context.commit("me", {});
      }
    },
    store(context, data) {
      context.commit("store", data);
    },
    storeIdToken(context, data) {
      context.commit("storeIdToken", data);
    },
    storeNotices(context, data) {
      context.commit("storeNotices", data);
    },
    drop(context) {
      context.commit("drop");
    },
    storeMyProfile(context, profile) {
      context.commit("storeMyProfile", profile);
    },
    storeBadges(context, data) {
      context.commit("storeBadges", data);
    },
    storeBadgeStatuses(context, data) {
      context.commit("storeBadgeStatuses", data);
    },
    updateAvatar(context, avatarUrl) {
      context.commit("updateAvatar", avatarUrl);
    },
    loadingMyProfile(context) {
      context.commit("loadingMyProfile");
    },
    loadedMyProfile(context) {
      context.commit("loadedMyProfile");
    },
  },
};
