export const getDefaultStateImageManager = () => {
  return {
    showImageManager: false,
    sort: "all",
    imageURLs: [],
    eventType: "",
    title: "",
    category: "",
    mode: "",
    pageSort: "",
  };
};

export default {
  namespaced: true,
  state: getDefaultStateImageManager(),
  getters: {
    imageManager: (state) => {
      return state;
    },
  },
  mutations: {
    drop(state) {
      state.showImageManager = false;
      state.sort = "all";
      state.imageURLs = [];
      state.eventType = "";
      state.title = "";
      state.category = "";
      state.mode = "";
      state.pageSort = "";
    },
    store(state, data) {
      state = data;
    },
    storeShowImageManager(state, data) {
      state.showImageManager = data;
    },
    storeSort(state, data) {
      state.sort = data;
    },
    storeImageURLs(state, data) {
      state.imageURLs = data;
    },
    storeEventType(state, data) {
      state.eventType = data;
    },
    storeTitle(state, data) {
      state.title = data;
    },
    storeCategory(state, data) {
      state.category = data;
    },
    storeMode(state, data) {
      state.mode = data;
    },
    storePageSort(state, data) {
      state.pageSort = data;
    },
  },
  actions: {
    drop(context) {
      context.commit("drop");
    },
    store(context, data) {
      context.commit("store", data);
    },
    storeShowImageManager(context, data) {
      context.commit("storeShowImageManager", data);
    },
    storeSort(context, data) {
      context.commit("storeSort", data);
    },
    storeImageURLs(context, data) {
      context.commit("storeImageURLs", data);
    },
    storeEventType(context, data) {
      context.commit("storeEventType", data);
    },
    storeTitle(context, data) {
      context.commit("storeTitle", data);
    },
    storeCategory(context, data) {
      context.commit("storeCategory", data);
    },
    storeMode(context, data) {
      context.commit("storeMode", data);
    },
    storePageSort(context, data) {
      context.commit("storePageSort", data);
    },
  },
};
