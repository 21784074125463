/* tslint:disable */
/* eslint-disable */
/**
 * Skimie APIs
 * Structured OpenAPI Schema
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { refreshIdToken } from '@/modules/auth';
import { Configuration } from './configuration';
import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from './base';

/**
 * アイテム取得経路情報
 * @export
 * @interface AcquisitionPath
 */
export interface AcquisitionPath {
    /**
     * アイテム取得経路ID
     * @type {number}
     * @memberof AcquisitionPath
     */
    'id': number;
    /**
     * アイテムマスタコード
     * @type {string}
     * @memberof AcquisitionPath
     */
    'item_master_code': string;
    /**
     * アイテム取得経路コード
     * @type {string}
     * @memberof AcquisitionPath
     */
    'acquisition_path_code': string;
    /**
     * アイテムコストコード
     * @type {string}
     * @memberof AcquisitionPath
     */
    'cost_type_code': string;
    /**
     * コスト
     * @type {number}
     * @memberof AcquisitionPath
     */
    'cost': number;
    /**
     * 取得確率
     * @type {number}
     * @memberof AcquisitionPath
     */
    'probability': number;
    /**
     * 最高取得可能数
     * @type {number}
     * @memberof AcquisitionPath
     */
    'max': number;
    /**
     * アンロックアイテムフラグ
     * @type {boolean}
     * @memberof AcquisitionPath
     */
    'is_unlock': boolean;
    /**
     * アンロック状態フラグ
     * @type {boolean}
     * @memberof AcquisitionPath
     */
    'is_unlocked': boolean;
}
/**
 * アクション情報
 * @export
 * @interface Actions
 */
export interface Actions {
    /**
     * 
     * @type {Array<Activity>}
     * @memberof Actions
     */
    'actions': Array<Activity>;
    /**
     * いいね総件数
     * @type {number}
     * @memberof Actions
     */
    'total_likes': number;
    /**
     * ナレッジいいね件数
     * @type {number}
     * @memberof Actions
     */
    'knowledge_likes': number;
    /**
     * ポートフォリオいいね件数
     * @type {number}
     * @memberof Actions
     */
    'portfolio_likes': number;
    /**
     * アクションを全て取得済みかを知らせるフラグ
     * @type {boolean}
     * @memberof Actions
     */
    'is_end': boolean;
}
/**
 * アクティビティ情報
 * @export
 * @interface Activity
 */
export interface Activity {
    /**
     * アクティビティ履歴ID
     * @type {number}
     * @memberof Activity
     */
    'activity_history_id': number;
    /**
     * 
     * @type {User}
     * @memberof Activity
     */
    'user': User;
    /**
     * 
     * @type {User}
     * @memberof Activity
     */
    'reaction_user': User;
    /**
     * アクティビティ種別
     * @type {number}
     * @memberof Activity
     */
    'action_type': number;
    /**
     * アクティビティコンテンツ
     * @type {number}
     * @memberof Activity
     */
    'content_type': number;
    /**
     * アクティビティ発生日時
     * @type {string}
     * @memberof Activity
     */
    'date': string;
    /**
     * アクティビティ詳細
     * @type {string}
     * @memberof Activity
     */
    'description': string;
    /**
     * コンテンツ画像url
     * @type {string}
     * @memberof Activity
     */
    'content_image_url': string;
    /**
     * コンテンツurl
     * @type {string}
     * @memberof Activity
     */
    'content_url': string;
    /**
     * コンテンツいいね数
     * @type {number}
     * @memberof Activity
     */
    'content_likes': number;
    /**
     * コンテンツについたコメント
     * @type {string}
     * @memberof Activity
     */
    'comment': string;
    /**
     * コメントついたリアクション
     * @type {string}
     * @memberof Activity
     */
    'comment_reaction': string;
    /**
     * 報酬として獲得するジェム数
     * @type {number}
     * @memberof Activity
     */
    'reward': number;
    /**
     * すでにジェムを受け取っているかを切り分けるフラグ
     * @type {boolean}
     * @memberof Activity
     */
    'has_reward': boolean;
    /**
     * ナレッジハッシュID
     * @type {string}
     * @memberof Activity
     */
    'article_id': string;
}
/**
 * カテゴリー情報
 * @export
 * @interface ActivityRequest
 */
export interface ActivityRequest {
    /**
     * アクティビティ種別
     * @type {string}
     * @memberof ActivityRequest
     */
    'activity_type': string;
    /**
     * アクティビティ取得数リミット
     * @type {number}
     * @memberof ActivityRequest
     */
    'limit': number;
    /**
     * アクティビティ取得数オフセット
     * @type {number}
     * @memberof ActivityRequest
     */
    'offset': number;
}
/**
 * 
 * @export
 * @interface AnalysesForm
 */
export interface AnalysesForm {
    /**
     * 分析フォームID
     * @type {number}
     * @memberof AnalysesForm
     */
    'id': number;
    /**
     * 分析フォームタイトル
     * @type {string}
     * @memberof AnalysesForm
     */
    'title': string;
    /**
     * 公開状態フラグ
     * @type {boolean}
     * @memberof AnalysesForm
     */
    'open_flg': boolean;
    /**
     * 
     * @type {Array<AnalysesMidItem>}
     * @memberof AnalysesForm
     */
    'mid_items': Array<AnalysesMidItem>;
}
/**
 * 分析フォーム入力値
 * @export
 * @interface AnalysesFormMemberItemRequest
 */
export interface AnalysesFormMemberItemRequest {
    /**
     * 分析項目ID
     * @type {number}
     * @memberof AnalysesFormMemberItemRequest
     */
    'id': number;
    /**
     * 分析項目自己評価
     * @type {number}
     * @memberof AnalysesFormMemberItemRequest
     */
    'value': number;
    /**
     * 分析項目上司評価
     * @type {number}
     * @memberof AnalysesFormMemberItemRequest
     */
    'superior_value': number;
    /**
     * 分析項目評価理由
     * @type {string}
     * @memberof AnalysesFormMemberItemRequest
     */
    'self_reason': string;
    /**
     * 分析項目特記事項
     * @type {string}
     * @memberof AnalysesFormMemberItemRequest
     */
    'superior_remarks': string;
}
/**
 * 分析フォーム入力値情報
 * @export
 * @interface AnalysesFormMemberRequest
 */
export interface AnalysesFormMemberRequest {
    /**
     * 
     * @type {Array<AnalysesFormMemberItemRequest>}
     * @memberof AnalysesFormMemberRequest
     */
    'items': Array<AnalysesFormMemberItemRequest>;
}
/**
 * 
 * @export
 * @interface AnalysesFormPast
 */
export interface AnalysesFormPast {
    /**
     * 分析フォームID
     * @type {number}
     * @memberof AnalysesFormPast
     */
    'id': number;
    /**
     * 分析フォームタイトル
     * @type {string}
     * @memberof AnalysesFormPast
     */
    'title': string;
}
/**
 * 過去分析フォーム情報
 * @export
 * @interface AnalysesFormPastRequest
 */
export interface AnalysesFormPastRequest {
    /**
     * 分析フォームID
     * @type {number}
     * @memberof AnalysesFormPastRequest
     */
    'id': number;
    /**
     * 設定閲覧用か記入用かを切り分けるフラグ
     * @type {boolean}
     * @memberof AnalysesFormPastRequest
     */
    'for_show': boolean;
}
/**
 * 分析フォーム情報
 * @export
 * @interface AnalysesFormRequest
 */
export interface AnalysesFormRequest {
    /**
     * 設定閲覧用か記入用かを切り分けるフラグ
     * @type {boolean}
     * @memberof AnalysesFormRequest
     */
    'for_show': boolean;
}
/**
 * 自己評価フォーマット項目
 * @export
 * @interface AnalysesFormatItemsRequest
 */
export interface AnalysesFormatItemsRequest {
    /**
     * 自己評価フォーマット項目ID
     * @type {number}
     * @memberof AnalysesFormatItemsRequest
     */
    'id'?: number;
    /**
     * 項目タイトル
     * @type {string}
     * @memberof AnalysesFormatItemsRequest
     */
    'title': string;
    /**
     * 項目説明
     * @type {string}
     * @memberof AnalysesFormatItemsRequest
     */
    'description': string;
}
/**
 * 自己評価フォーマット中項目
 * @export
 * @interface AnalysesFormatMidItemsRequest
 */
export interface AnalysesFormatMidItemsRequest {
    /**
     * 自己評価フォーマット中項目ID
     * @type {number}
     * @memberof AnalysesFormatMidItemsRequest
     */
    'id'?: number;
    /**
     * 中項目タイトル
     * @type {string}
     * @memberof AnalysesFormatMidItemsRequest
     */
    'title': string;
    /**
     * 
     * @type {Array<AnalysesFormatItemsRequest>}
     * @memberof AnalysesFormatMidItemsRequest
     */
    'items': Array<AnalysesFormatItemsRequest>;
}
/**
 * 自己評価フォーマット情報
 * @export
 * @interface AnalysesFormatRequest
 */
export interface AnalysesFormatRequest {
    /**
     * 自己評価フォーマットID
     * @type {number}
     * @memberof AnalysesFormatRequest
     */
    'id'?: number;
    /**
     * 自己評価フォーマットタイトル
     * @type {string}
     * @memberof AnalysesFormatRequest
     */
    'title': string;
    /**
     * 
     * @type {Array<AnalysesFormatMidItemsRequest>}
     * @memberof AnalysesFormatRequest
     */
    'mid_items': Array<AnalysesFormatMidItemsRequest>;
}
/**
 * 
 * @export
 * @interface AnalysesFormsPast
 */
export interface AnalysesFormsPast {
    /**
     * 
     * @type {Array<AnalysesFormPast>}
     * @memberof AnalysesFormsPast
     */
    'past_forms': Array<AnalysesFormPast>;
}
/**
 * 
 * @export
 * @interface AnalysesItem
 */
export interface AnalysesItem {
    /**
     * 分析項目ID
     * @type {number}
     * @memberof AnalysesItem
     */
    'id': number;
    /**
     * 分析項目タイトル
     * @type {string}
     * @memberof AnalysesItem
     */
    'title': string;
    /**
     * 分析項目説明
     * @type {string}
     * @memberof AnalysesItem
     */
    'description': string;
    /**
     * 分析項目自己評価
     * @type {number}
     * @memberof AnalysesItem
     */
    'value': number;
    /**
     * 分析項目上司評価
     * @type {number}
     * @memberof AnalysesItem
     */
    'superior_value': number;
    /**
     * 分析項目評価理由
     * @type {string}
     * @memberof AnalysesItem
     */
    'self_reason': string;
    /**
     * 分析項目特記事項
     * @type {string}
     * @memberof AnalysesItem
     */
    'superior_remarks': string;
}
/**
 * 
 * @export
 * @interface AnalysesMidItem
 */
export interface AnalysesMidItem {
    /**
     * 分析中項目ID
     * @type {number}
     * @memberof AnalysesMidItem
     */
    'id': number;
    /**
     * 分析中項目タイトル
     * @type {string}
     * @memberof AnalysesMidItem
     */
    'title': string;
    /**
     * 
     * @type {Array<AnalysesItem>}
     * @memberof AnalysesMidItem
     */
    'items': Array<AnalysesItem>;
}
/**
 * 公開コンテンツ情報
 * @export
 * @interface Article
 */
export interface Article {
    /**
     * articleId
     * @type {string}
     * @memberof Article
     */
    'articleId': string;
    /**
     * タイトル
     * @type {string}
     * @memberof Article
     */
    'subject': string;
    /**
     * wiki内容
     * @type {string}
     * @memberof Article
     */
    'description': string;
    /**
     * 
     * @type {string}
     * @memberof Article
     */
    'created_on': string;
    /**
     * 
     * @type {Category}
     * @memberof Article
     */
    'category'?: Category;
    /**
     * アイキャッチ画像へのURL
     * @type {string}
     * @memberof Article
     */
    'eyecatchURL': string;
    /**
     * エディタのバージョン
     * @type {number}
     * @memberof Article
     */
    'editorVersion'?: number;
    /**
     * 
     * @type {ArticleAuthor}
     * @memberof Article
     */
    'author'?: ArticleAuthor;
    /**
     * いいね数
     * @type {number}
     * @memberof Article
     */
    'like_num': number;
    /**
     * コメント数
     * @type {number}
     * @memberof Article
     */
    'totalComments': number;
    /**
     * 自身がコメントを閲覧したかのフラグ
     * @type {boolean}
     * @memberof Article
     */
    'seenComments': boolean;
}
/**
 * 
 * @export
 * @interface ArticleAuthor
 */
export interface ArticleAuthor {
    /**
     * 
     * @type {boolean}
     * @memberof ArticleAuthor
     */
    'is_team': boolean;
    /**
     * 
     * @type {string}
     * @memberof ArticleAuthor
     */
    'slug': string;
    /**
     * 
     * @type {string}
     * @memberof ArticleAuthor
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof ArticleAuthor
     */
    'avatarURL'?: string;
    /**
     * 
     * @type {string}
     * @memberof ArticleAuthor
     */
    'bannerURL': string;
}
/**
 * 公開コンテンツ情報
 * @export
 * @interface ArticleComment
 */
export interface ArticleComment {
    /**
     * 
     * @type {number}
     * @memberof ArticleComment
     */
    'id': number;
    /**
     * articleId
     * @type {string}
     * @memberof ArticleComment
     */
    'articleId': string;
    /**
     * 返信元のコメント（0の場合は直接コメント）
     * @type {number}
     * @memberof ArticleComment
     */
    'articleCommentId': number;
    /**
     * 他者のコメントを閲覧した上でコメントしたか否かのフラグ
     * @type {boolean}
     * @memberof ArticleComment
     */
    'seenComments': boolean;
    /**
     * コメント内容
     * @type {string}
     * @memberof ArticleComment
     */
    'comment': string;
    /**
     * 
     * @type {string}
     * @memberof ArticleComment
     */
    'createdAt': string;
    /**
     * 
     * @type {User}
     * @memberof ArticleComment
     */
    'toUser': User;
    /**
     * 
     * @type {User}
     * @memberof ArticleComment
     */
    'user': User;
    /**
     * いいね数
     * @type {number}
     * @memberof ArticleComment
     */
    'totalLike': number;
    /**
     * 返信数
     * @type {number}
     * @memberof ArticleComment
     */
    'totalComments': number;
    /**
     * 自身がコメントに対していいねしたかのフラグ
     * @type {boolean}
     * @memberof ArticleComment
     */
    'liked': boolean;
}
/**
 * 公開記事コメント情報
 * @export
 * @interface ArticleCommentLikeReq
 */
export interface ArticleCommentLikeReq {
    /**
     * 公開記事ID
     * @type {string}
     * @memberof ArticleCommentLikeReq
     */
    'articleId': string;
    /**
     * 返信元コメントID
     * @type {number}
     * @memberof ArticleCommentLikeReq
     */
    'articleCommentId': number | null;
    /**
     * いいね
     * @type {boolean}
     * @memberof ArticleCommentLikeReq
     */
    'like': boolean;
}
/**
 * 公開記事コメント情報
 * @export
 * @interface ArticleCommentReq
 */
export interface ArticleCommentReq {
    /**
     * 公開記事ID
     * @type {string}
     * @memberof ArticleCommentReq
     */
    'articleId': string;
    /**
     * 返信元コメントID
     * @type {number}
     * @memberof ArticleCommentReq
     */
    'articleCommentId': number | null;
    /**
     * いいね
     * @type {string}
     * @memberof ArticleCommentReq
     */
    'comment': string;
}
/**
 * 公開ナレッジコメントリスト
 * @export
 * @interface ArticleComments
 */
export interface ArticleComments {
    /**
     * 
     * @type {Array<ArticleComment>}
     * @memberof ArticleComments
     */
    'comments': Array<ArticleComment>;
    /**
     * 
     * @type {PageInfo}
     * @memberof ArticleComments
     */
    'page_info': PageInfo;
}
/**
 * 公開ナレッジいいね情報
 * @export
 * @interface ArticleLike
 */
export interface ArticleLike {
    /**
     * いいね数
     * @type {number}
     * @memberof ArticleLike
     */
    'like_num': number;
}
/**
 * 公開記事いいね情報
 * @export
 * @interface ArticleLikeRequest
 */
export interface ArticleLikeRequest {
    /**
     * 公開記事ID
     * @type {string}
     * @memberof ArticleLikeRequest
     */
    'article_id': string;
    /**
     * いいね
     * @type {boolean}
     * @memberof ArticleLikeRequest
     */
    'is_liked': boolean;
}
/**
 * 公開コンテンツリスト
 * @export
 * @interface Articles
 */
export interface Articles {
    /**
     * 
     * @type {Array<Article>}
     * @memberof Articles
     */
    'articles': Array<Article>;
    /**
     * ヒット件数
     * @type {number}
     * @memberof Articles
     */
    'total_count': number;
    /**
     * オフセット値
     * @type {number}
     * @memberof Articles
     */
    'offset': number;
    /**
     * 上限値
     * @type {number}
     * @memberof Articles
     */
    'limit': number;
}
/**
 * 分析フォーム公開設定情報
 * @export
 * @interface AssessmentAnalysesFormOpenRequest
 */
export interface AssessmentAnalysesFormOpenRequest {
    /**
     * 分析フォームID
     * @type {number}
     * @memberof AssessmentAnalysesFormOpenRequest
     */
    'id': number;
    /**
     * 分析フォームを公開するか閉じるかを切り分けるフラグ
     * @type {boolean}
     * @memberof AssessmentAnalysesFormOpenRequest
     */
    'open': boolean;
}
/**
 * 担当者
 * @export
 * @interface AssingedTo
 */
export interface AssingedTo {
    /**
     * 担当者ID
     * @type {number}
     * @memberof AssingedTo
     */
    'id': number;
    /**
     * 担当者ユーザー名
     * @type {string}
     * @memberof AssingedTo
     */
    'username': string;
    /**
     * 担当者名
     * @type {string}
     * @memberof AssingedTo
     */
    'displayName': string;
    /**
     * 担当者アバター
     * @type {string}
     * @memberof AssingedTo
     */
    'avatarURL': string;
}
/**
 * 作成者
 * @export
 * @interface Author
 */
export interface Author {
    /**
     * ユーザーID
     * @type {number}
     * @memberof Author
     */
    'id': number;
    /**
     * ユーザーネーム
     * @type {string}
     * @memberof Author
     */
    'username': string;
    /**
     * 表示名
     * @type {string}
     * @memberof Author
     */
    'displayName': string;
    /**
     * 表示名
     * @type {string}
     * @memberof Author
     */
    'name': string;
    /**
     * アバター
     * @type {string}
     * @memberof Author
     */
    'avatarURL': string;
}
/**
 * 
 * @export
 * @interface AvatarResp
 */
export interface AvatarResp {
    /**
     * 
     * @type {string}
     * @memberof AvatarResp
     */
    'image': string;
    /**
     * 
     * @type {string}
     * @memberof AvatarResp
     */
    'background'?: string;
    /**
     * スキミーを保有しているかのフラグ
     * @type {boolean}
     * @memberof AvatarResp
     */
    'has_skimmy': boolean;
}
/**
 * バッジ情報
 * @export
 * @interface Badge
 */
export interface Badge {
    /**
     * コードID
     * @type {number}
     * @memberof Badge
     */
    'id': number;
    /**
     * バッジコード
     * @type {string}
     * @memberof Badge
     */
    'code': string;
    /**
     * コード名
     * @type {string}
     * @memberof Badge
     */
    'name': string;
    /**
     * 説明
     * @type {string}
     * @memberof Badge
     */
    'description': string;
    /**
     * バッジ獲得報酬
     * @type {number}
     * @memberof Badge
     */
    'reward'?: number;
    /**
     * バッジ履歴ID
     * @type {number}
     * @memberof Badge
     */
    'badge_history_id': number;
    /**
     * 完了済みフラグ
     * @type {boolean}
     * @memberof Badge
     */
    'completed'?: boolean;
    /**
     * 報酬獲得済みフラグ
     * @type {boolean}
     * @memberof Badge
     */
    'has_reward': boolean;
    /**
     * 
     * @type {Array<BadgeStepup>}
     * @memberof Badge
     */
    'stepups'?: Array<BadgeStepup>;
    /**
     * オペレーション回数
     * @type {number}
     * @memberof Badge
     */
    'operation_count'?: number;
}
/**
 * バッジ情報
 * @export
 * @interface BadgeStepup
 */
export interface BadgeStepup {
    /**
     * コードID
     * @type {number}
     * @memberof BadgeStepup
     */
    'id': number;
    /**
     * バッジコード
     * @type {string}
     * @memberof BadgeStepup
     */
    'code': string;
    /**
     * ステップアップID
     * @type {number}
     * @memberof BadgeStepup
     */
    'stepup_id': number;
    /**
     * コード名
     * @type {string}
     * @memberof BadgeStepup
     */
    'name': string;
    /**
     * 説明
     * @type {string}
     * @memberof BadgeStepup
     */
    'description': string;
    /**
     * バッジ獲得報酬
     * @type {number}
     * @memberof BadgeStepup
     */
    'count': number;
    /**
     * バッジ獲得報酬
     * @type {number}
     * @memberof BadgeStepup
     */
    'reward': number;
    /**
     * 完了済みフラグ
     * @type {boolean}
     * @memberof BadgeStepup
     */
    'completed'?: boolean;
    /**
     * 報酬獲得済みフラグ
     * @type {boolean}
     * @memberof BadgeStepup
     */
    'has_reward'?: boolean;
}
/**
 * バッジコードリスト情報
 * @export
 * @interface Badges
 */
export interface Badges {
    /**
     * 
     * @type {Array<string>}
     * @memberof Badges
     */
    'rewards': Array<string>;
    /**
     * 
     * @type {Array<Badge>}
     * @memberof Badges
     */
    'badges': Array<Badge>;
}
/**
 * 
 * @export
 * @interface BartleChoiceResp
 */
export interface BartleChoiceResp {
    /**
     * 選択肢番号
     * @type {number}
     * @memberof BartleChoiceResp
     */
    'choice_number': number;
    /**
     * 選択肢
     * @type {string}
     * @memberof BartleChoiceResp
     */
    'choice': string;
}
/**
 * 
 * @export
 * @interface BartleQuestionResp
 */
export interface BartleQuestionResp {
    /**
     * 質問番号
     * @type {number}
     * @memberof BartleQuestionResp
     */
    'question_number': number;
    /**
     * 質問
     * @type {string}
     * @memberof BartleQuestionResp
     */
    'question': string;
    /**
     * 
     * @type {Array<BartleChoiceResp>}
     * @memberof BartleQuestionResp
     */
    'choices': Array<BartleChoiceResp>;
}
/**
 * 
 * @export
 * @interface BartleQuestionsResp
 */
export interface BartleQuestionsResp {
    /**
     * 
     * @type {Array<BartleQuestionResp>}
     * @memberof BartleQuestionsResp
     */
    'bartle_questions': Array<BartleQuestionResp>;
}
/**
 * 
 * @export
 * @interface BartleResp
 */
export interface BartleResp {
    /**
     * 属性
     * @type {string}
     * @memberof BartleResp
     */
    'bartle_type': string;
    /**
     * 属性割合
     * @type {number}
     * @memberof BartleResp
     */
    'percentage': number;
}
/**
 * 
 * @export
 * @interface BartleResultRequest
 */
export interface BartleResultRequest {
    /**
     * 
     * @type {Array<number>}
     * @memberof BartleResultRequest
     */
    'answers': Array<number>;
}
/**
 * 
 * @export
 * @interface BartleResultResp
 */
export interface BartleResultResp {
    /**
     * 
     * @type {Array<BartleResp>}
     * @memberof BartleResultResp
     */
    'bartles': Array<BartleResp>;
}
/**
 * 
 * @export
 * @interface BartleSetRequest
 */
export interface BartleSetRequest {
    /**
     * 属性
     * @type {string}
     * @memberof BartleSetRequest
     */
    'bartle': string;
}
/**
 * バトル情報
 * @export
 * @interface Battle
 */
export interface Battle {
    /**
     * バトルID
     * @type {number}
     * @memberof Battle
     */
    'id': number;
    /**
     * バトル種類コード
     * @type {string}
     * @memberof Battle
     */
    'battle_type_code': string;
    /**
     * バトル種類詳細コード
     * @type {string}
     * @memberof Battle
     */
    'battle_type_detail_code': string;
    /**
     * レベル
     * @type {number}
     * @memberof Battle
     */
    'level': number;
    /**
     * サブレベル
     * @type {number}
     * @memberof Battle
     */
    'sublevel': number;
    /**
     * 表示順番
     * @type {number}
     * @memberof Battle
     */
    'order': number;
    /**
     * 
     * @type {string}
     * @memberof Battle
     */
    'image': string;
    /**
     * バトルレベル種類コード
     * @type {string}
     * @memberof Battle
     */
    'battle_level_code': string;
    /**
     * デフォルトで開放されているレベルの切り分けフラグ
     * @type {boolean}
     * @memberof Battle
     */
    'is_default_level': boolean;
    /**
     * 
     * @type {Array<Enemy>}
     * @memberof Battle
     */
    'enemies': Array<Enemy>;
}
/**
 * バトル実施情報
 * @export
 * @interface BattleInitiationReq
 */
export interface BattleInitiationReq {
    /**
     * バトルマスタID
     * @type {number}
     * @memberof BattleInitiationReq
     */
    'battle_master_id': number;
    /**
     * パーティー番号
     * @type {number}
     * @memberof BattleInitiationReq
     */
    'party_number': number;
    /**
     * バトルレベル
     * @type {number}
     * @memberof BattleInitiationReq
     */
    'level': number;
    /**
     * バトルサブレベル
     * @type {number}
     * @memberof BattleInitiationReq
     */
    'sublevel': number;
    /**
     * バトル種類コード
     * @type {string}
     * @memberof BattleInitiationReq
     */
    'battle_type_code': string;
}
/**
 * バトルレベル情報
 * @export
 * @interface BattleLevel
 */
export interface BattleLevel {
    /**
     * バトルレベル
     * @type {number}
     * @memberof BattleLevel
     */
    'level': number;
    /**
     * 
     * @type {Array<Battle>}
     * @memberof BattleLevel
     */
    'battles': Array<Battle>;
}
/**
 * バトル情報
 * @export
 * @interface BattleReq
 */
export interface BattleReq {
    /**
     * バトル種類ID
     * @type {number}
     * @memberof BattleReq
     */
    'battle_type_master_id': number;
    /**
     * バトル種類コード
     * @type {string}
     * @memberof BattleReq
     */
    'battle_type_code': string;
    /**
     * バトル種類詳細コード
     * @type {string}
     * @memberof BattleReq
     */
    'battle_type_detail_code': string;
    /**
     * 取得するバトルレベル
     * @type {number}
     * @memberof BattleReq
     */
    'battle_level': number;
}
/**
 * バトル結果情報
 * @export
 * @interface BattleResult
 */
export interface BattleResult {
    /**
     * バトル結果フラグ
     * @type {boolean}
     * @memberof BattleResult
     */
    'beat_level': boolean;
    /**
     * 
     * @type {Array<UserItem>}
     * @memberof BattleResult
     */
    'battle_bonus': Array<UserItem>;
    /**
     * 新レベル解放フラグ
     * @type {boolean}
     * @memberof BattleResult
     */
    'level_unlocked': boolean;
    /**
     * 解放バトルレベル種類コード
     * @type {string}
     * @memberof BattleResult
     */
    'unlocked_battle_level_code': string;
}
/**
 * バトルチケット情報
 * @export
 * @interface BattleTickets
 */
export interface BattleTickets {
    /**
     * 総バトルチケット数
     * @type {number}
     * @memberof BattleTickets
     */
    'total_battle_tickets': number;
    /**
     * 付与バトルチケット数
     * @type {number}
     * @memberof BattleTickets
     */
    'battle_tickets': number;
    /**
     * 購入バトルチケット数
     * @type {number}
     * @memberof BattleTickets
     */
    'purchased_battle_tickets': number;
    /**
     * 
     * @type {string}
     * @memberof BattleTickets
     */
    'image': string;
}
/**
 * バトルチケット購入情報
 * @export
 * @interface BattleTicketsPurchaseReq
 */
export interface BattleTicketsPurchaseReq {
    /**
     * バトルチケット購入数
     * @type {number}
     * @memberof BattleTicketsPurchaseReq
     */
    'battle_tickets_to_purchase': number;
    /**
     * ジェム消費数
     * @type {number}
     * @memberof BattleTicketsPurchaseReq
     */
    'gems_spent': number;
}
/**
 * バトル種類情報
 * @export
 * @interface BattleType
 */
export interface BattleType {
    /**
     * バトル種類ID
     * @type {number}
     * @memberof BattleType
     */
    'id': number;
    /**
     * バトル種類コード
     * @type {string}
     * @memberof BattleType
     */
    'battle_type_code': string;
    /**
     * バトル種類詳細コード
     * @type {string}
     * @memberof BattleType
     */
    'battle_type_detail_code': string;
    /**
     * 表示順番
     * @type {number}
     * @memberof BattleType
     */
    'order': number;
    /**
     * 
     * @type {string}
     * @memberof BattleType
     */
    'image': string;
}
/**
 * バトル種類リスト情報
 * @export
 * @interface BattleTypes
 */
export interface BattleTypes {
    /**
     * 
     * @type {Array<BattleType>}
     * @memberof BattleTypes
     */
    'battle_types': Array<BattleType>;
}
/**
 * バトルリスト情報
 * @export
 * @interface Battles
 */
export interface Battles {
    /**
     * 
     * @type {BattleTickets}
     * @memberof Battles
     */
    'battle_tickets': BattleTickets;
    /**
     * 
     * @type {Array<BattleLevel>}
     * @memberof Battles
     */
    'battle_levels': Array<BattleLevel>;
}
/**
 * クレジットカード情報
 * @export
 * @interface BillingCardRequest
 */
export interface BillingCardRequest {
    /**
     * 
     * @type {string}
     * @memberof BillingCardRequest
     */
    'option'?: string;
}
/**
 * task情報
 * @export
 * @interface BillingCards
 */
export interface BillingCards {
    /**
     * 
     * @type {string}
     * @memberof BillingCards
     */
    'option'?: string;
}
/**
 * 請求履歴
 * @export
 * @interface BillingHistory
 */
export interface BillingHistory {
    /**
     * 請求履歴ID
     * @type {number}
     * @memberof BillingHistory
     */
    'id': number;
    /**
     * 請求月
     * @type {number}
     * @memberof BillingHistory
     */
    'applicable_month': number;
    /**
     * 請求年
     * @type {number}
     * @memberof BillingHistory
     */
    'applicable_year': number;
    /**
     * 割引前請求額
     * @type {number}
     * @memberof BillingHistory
     */
    'billing_amount': number;
    /**
     * 一ヶ月の請求メンバー数
     * @type {number}
     * @memberof BillingHistory
     */
    'number_of_members': number;
    /**
     * 割引後請求額
     * @type {number}
     * @memberof BillingHistory
     */
    'discounted_bill': number;
    /**
     * 支払いが行われているか
     * @type {boolean}
     * @memberof BillingHistory
     */
    'is_payed': boolean;
}
/**
 * チーム請求情報リスト
 * @export
 * @interface BillingInfo
 */
export interface BillingInfo {
    /**
     * 
     * @type {Team}
     * @memberof BillingInfo
     */
    'team': Team;
    /**
     * 
     * @type {Array<BillingHistory>}
     * @memberof BillingInfo
     */
    'billing_histories': Array<BillingHistory>;
    /**
     * 
     * @type {Array<Estimate>}
     * @memberof BillingInfo
     */
    'estimates': Array<Estimate>;
}
/**
 * バッジ情報
 * @export
 * @interface BillingPlan
 */
export interface BillingPlan {
    /**
     * コードID
     * @type {number}
     * @memberof BillingPlan
     */
    'id': number;
    /**
     * プランコード
     * @type {string}
     * @memberof BillingPlan
     */
    'plan': string;
    /**
     * プラン名
     * @type {string}
     * @memberof BillingPlan
     */
    'name': string;
    /**
     * 説明
     * @type {string}
     * @memberof BillingPlan
     */
    'description': string;
}
/**
 * プランコードリスト情報
 * @export
 * @interface BillingPlans
 */
export interface BillingPlans {
    /**
     * 
     * @type {Array<BillingPlan>}
     * @memberof BillingPlans
     */
    'billing_plans': Array<BillingPlan>;
}
/**
 * ボス図鑑情報
 * @export
 * @interface BossEncyclopedia
 */
export interface BossEncyclopedia {
    /**
     * 
     * @type {Array<Item>}
     * @memberof BossEncyclopedia
     */
    'items': Array<Item>;
    /**
     * 
     * @type {string}
     * @memberof BossEncyclopedia
     */
    'background_image': string;
    /**
     * 
     * @type {Array<Item>}
     * @memberof BossEncyclopedia
     */
    'background_items': Array<Item>;
}
/**
 * アイテム購入情報
 * @export
 * @interface BuyShopItemReq
 */
export interface BuyShopItemReq {
    /**
     * アイテムコード
     * @type {string}
     * @memberof BuyShopItemReq
     */
    'item_code': string;
    /**
     * アイテム購入数
     * @type {number}
     * @memberof BuyShopItemReq
     */
    'buy_count': number;
    /**
     * アイテム購入コスト
     * @type {number}
     * @memberof BuyShopItemReq
     */
    'cost': number;
    /**
     * コストコード
     * @type {string}
     * @memberof BuyShopItemReq
     */
    'cost_type': string;
}
/**
 * カテゴリーリスト情報
 * @export
 * @interface Categories
 */
export interface Categories {
    /**
     * 
     * @type {Array<Category>}
     * @memberof Categories
     */
    'categories': Array<Category>;
    /**
     * ヒット件数
     * @type {number}
     * @memberof Categories
     */
    'total_count': number;
}
/**
 * カテゴリー
 * @export
 * @interface Category
 */
export interface Category {
    /**
     * カテゴリーID
     * @type {number}
     * @memberof Category
     */
    'id': number;
    /**
     * カテゴリー名
     * @type {string}
     * @memberof Category
     */
    'name': string;
}
/**
 * カテゴリー情報
 * @export
 * @interface CategoryRequest
 */
export interface CategoryRequest {
    /**
     * カテゴリーID
     * @type {number}
     * @memberof CategoryRequest
     */
    'id'?: number;
    /**
     * カテゴリー名
     * @type {string}
     * @memberof CategoryRequest
     */
    'name': string;
    /**
     * カテゴリーの種別
     * @type {string}
     * @memberof CategoryRequest
     */
    'category_type': string;
}
/**
 * カテゴリユーザアイテムリスト情報
 * @export
 * @interface CategoryUserItems
 */
export interface CategoryUserItems {
    /**
     * 
     * @type {Array<TypeUserItems>}
     * @memberof CategoryUserItems
     */
    'user_items_by_type': Array<TypeUserItems>;
    /**
     * 
     * @type {PageInfo}
     * @memberof CategoryUserItems
     */
    'page_info': PageInfo;
    /**
     * 武器を装備しているかのフラグ
     * @type {boolean}
     * @memberof CategoryUserItems
     */
    'is_weapon_equipped': boolean;
    /**
     * 装備可能武器種類
     * @type {string}
     * @memberof CategoryUserItems
     */
    'equippable_weapon_type': string;
    /**
     * 装備武器コード
     * @type {string}
     * @memberof CategoryUserItems
     */
    'equipped_weapon_code': string;
}
/**
 * 
 * @export
 * @interface ChatRoom
 */
export interface ChatRoom {
    /**
     * 
     * @type {number}
     * @memberof ChatRoom
     */
    'roomId': number;
    /**
     * 
     * @type {string}
     * @memberof ChatRoom
     */
    'roomName': string;
    /**
     * 
     * @type {string}
     * @memberof ChatRoom
     */
    'avatar': string;
    /**
     * 
     * @type {number}
     * @memberof ChatRoom
     */
    'unreadCount': number;
    /**
     * 
     * @type {number}
     * @memberof ChatRoom
     */
    'index': number;
    /**
     * 
     * @type {ChatRoomLastMessage}
     * @memberof ChatRoom
     */
    'lastMessage': ChatRoomLastMessage;
    /**
     * 
     * @type {Array<ChatRoomUser>}
     * @memberof ChatRoom
     */
    'users': Array<ChatRoomUser>;
    /**
     * 
     * @type {Array<number>}
     * @memberof ChatRoom
     */
    'typingUsers': Array<number>;
}
/**
 * 
 * @export
 * @interface ChatRoomLastMessage
 */
export interface ChatRoomLastMessage {
    /**
     * 
     * @type {string}
     * @memberof ChatRoomLastMessage
     */
    'content': string;
    /**
     * 
     * @type {string}
     * @memberof ChatRoomLastMessage
     */
    'senderId': string;
    /**
     * 
     * @type {string}
     * @memberof ChatRoomLastMessage
     */
    'username': string;
    /**
     * 
     * @type {string}
     * @memberof ChatRoomLastMessage
     */
    'timestamp': string;
    /**
     * 
     * @type {boolean}
     * @memberof ChatRoomLastMessage
     */
    'saved': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ChatRoomLastMessage
     */
    'distributed': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ChatRoomLastMessage
     */
    'seen': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ChatRoomLastMessage
     */
    'new': boolean;
}
/**
 * 
 * @export
 * @interface ChatRoomMessages
 */
export interface ChatRoomMessages {
    /**
     * 
     * @type {Array<ChatRoomsSkimmyTypeResp>}
     * @memberof ChatRoomMessages
     */
    'messages': Array<ChatRoomsSkimmyTypeResp>;
    /**
     * 
     * @type {PageInfo}
     * @memberof ChatRoomMessages
     */
    'page_info': PageInfo;
}
/**
 * 
 * @export
 * @interface ChatRoomUser
 */
export interface ChatRoomUser {
    /**
     * 
     * @type {string}
     * @memberof ChatRoomUser
     */
    '_id': string;
    /**
     * 
     * @type {string}
     * @memberof ChatRoomUser
     */
    'username': string;
    /**
     * 
     * @type {string}
     * @memberof ChatRoomUser
     */
    'avatar': string;
    /**
     * 
     * @type {ChatRoomUserStatus}
     * @memberof ChatRoomUser
     */
    'status': ChatRoomUserStatus;
}
/**
 * 
 * @export
 * @interface ChatRoomUserStatus
 */
export interface ChatRoomUserStatus {
    /**
     * 
     * @type {string}
     * @memberof ChatRoomUserStatus
     */
    'state': string;
    /**
     * 
     * @type {string}
     * @memberof ChatRoomUserStatus
     */
    'lastChanged': string;
}
/**
 * 
 * @export
 * @interface ChatRooms
 */
export interface ChatRooms {
    /**
     * 
     * @type {Array<ChatRoom>}
     * @memberof ChatRooms
     */
    'rooms': Array<ChatRoom>;
    /**
     * 
     * @type {PageInfo}
     * @memberof ChatRooms
     */
    'page_info': PageInfo;
}
/**
 * メッセージ
 * @export
 * @interface ChatRoomsSkimmyTypeRep
 */
export interface ChatRoomsSkimmyTypeRep {
    /**
     * メッセージ
     * @type {string}
     * @memberof ChatRoomsSkimmyTypeRep
     */
    'text': string;
    /**
     * 
     * @type {boolean}
     * @memberof ChatRoomsSkimmyTypeRep
     */
    'is_bartle_test'?: boolean;
    /**
     * バートルテスト番号
     * @type {number}
     * @memberof ChatRoomsSkimmyTypeRep
     */
    'question_number'?: number;
    /**
     * 
     * @type {Array<number>}
     * @memberof ChatRoomsSkimmyTypeRep
     */
    'answers'?: Array<number>;
}
/**
 * 
 * @export
 * @interface ChatRoomsSkimmyTypeResp
 */
export interface ChatRoomsSkimmyTypeResp {
    /**
     * 
     * @type {number}
     * @memberof ChatRoomsSkimmyTypeResp
     */
    '_id': number;
    /**
     * 
     * @type {number}
     * @memberof ChatRoomsSkimmyTypeResp
     */
    'indexId'?: number;
    /**
     * 
     * @type {string}
     * @memberof ChatRoomsSkimmyTypeResp
     */
    'content': string;
    /**
     * 
     * @type {string}
     * @memberof ChatRoomsSkimmyTypeResp
     */
    'senderId': string;
    /**
     * 
     * @type {string}
     * @memberof ChatRoomsSkimmyTypeResp
     */
    'username'?: string;
    /**
     * 
     * @type {string}
     * @memberof ChatRoomsSkimmyTypeResp
     */
    'avatar'?: string;
    /**
     * 
     * @type {string}
     * @memberof ChatRoomsSkimmyTypeResp
     */
    'date': string;
    /**
     * 
     * @type {string}
     * @memberof ChatRoomsSkimmyTypeResp
     */
    'timestamp': string;
    /**
     * 
     * @type {boolean}
     * @memberof ChatRoomsSkimmyTypeResp
     */
    'system'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ChatRoomsSkimmyTypeResp
     */
    'saved'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ChatRoomsSkimmyTypeResp
     */
    'distributed'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ChatRoomsSkimmyTypeResp
     */
    'seen'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ChatRoomsSkimmyTypeResp
     */
    'deleted'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ChatRoomsSkimmyTypeResp
     */
    'failure'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ChatRoomsSkimmyTypeResp
     */
    'disableActions'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ChatRoomsSkimmyTypeResp
     */
    'disableReactions'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ChatRoomsSkimmyTypeResp
     */
    'url': string;
    /**
     * 
     * @type {Array<GemReward>}
     * @memberof ChatRoomsSkimmyTypeResp
     */
    'gem_rewards'?: Array<GemReward>;
    /**
     * 
     * @type {number}
     * @memberof ChatRoomsSkimmyTypeResp
     */
    'room_id'?: number;
    /**
     * 
     * @type {Array<BartleResp>}
     * @memberof ChatRoomsSkimmyTypeResp
     */
    'bartles': Array<BartleResp>;
}
/**
 * 
 * @export
 * @interface ChooseSkimmyListResp
 */
export interface ChooseSkimmyListResp {
    /**
     * 
     * @type {Array<SkimmyResp>}
     * @memberof ChooseSkimmyListResp
     */
    'skimmy_list': Array<SkimmyResp>;
}
/**
 * オペレーションコイン値情報
 * @export
 * @interface CoinOperationValues
 */
export interface CoinOperationValues {
    /**
     * 
     * @type {number}
     * @memberof CoinOperationValues
     */
    'my_task_created': number;
    /**
     * 
     * @type {number}
     * @memberof CoinOperationValues
     */
    'my_knowledge_created': number;
    /**
     * 
     * @type {number}
     * @memberof CoinOperationValues
     */
    'team_task_created': number;
    /**
     * 
     * @type {number}
     * @memberof CoinOperationValues
     */
    'team_knowledge_created': number;
    /**
     * 
     * @type {number}
     * @memberof CoinOperationValues
     */
    'team_quest_created': number;
    /**
     * 
     * @type {number}
     * @memberof CoinOperationValues
     */
    'my_task_deleted': number;
    /**
     * 
     * @type {number}
     * @memberof CoinOperationValues
     */
    'my_knowledge_deleted': number;
    /**
     * 
     * @type {number}
     * @memberof CoinOperationValues
     */
    'team_task_deleted': number;
    /**
     * 
     * @type {number}
     * @memberof CoinOperationValues
     */
    'team_knowledge_deleted': number;
    /**
     * 
     * @type {number}
     * @memberof CoinOperationValues
     */
    'team_quest_deleted': number;
    /**
     * 
     * @type {number}
     * @memberof CoinOperationValues
     */
    'my_task_completed': number;
}
/**
 * task情報
 * @export
 * @interface Comment
 */
export interface Comment {
    /**
     * コメントID
     * @type {number}
     * @memberof Comment
     */
    'id': number;
    /**
     * コメント
     * @type {string}
     * @memberof Comment
     */
    'comment': string;
    /**
     * 編集済みフラグ
     * @type {boolean}
     * @memberof Comment
     */
    'updated': boolean;
    /**
     * 削除済みフラグ
     * @type {boolean}
     * @memberof Comment
     */
    'deleted': boolean;
    /**
     * ナレッジコメントかのフラグ
     * @type {boolean}
     * @memberof Comment
     */
    'is_wiki'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof Comment
     */
    'editorVersion'?: number;
}
/**
 * task情報
 * @export
 * @interface CommentReaction
 */
export interface CommentReaction {
    /**
     * リアクション
     * @type {string}
     * @memberof CommentReaction
     */
    'reaction': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof CommentReaction
     */
    'usernames': Array<string>;
}
/**
 * コメントリアクション情報
 * @export
 * @interface CommentReactionRequest
 */
export interface CommentReactionRequest {
    /**
     * チーム名
     * @type {string}
     * @memberof CommentReactionRequest
     */
    'teamname': string;
    /**
     * プロジェクトオーダー
     * @type {number}
     * @memberof CommentReactionRequest
     */
    'project_order': number;
    /**
     * 
     * @type {Array<CommentReactionRequestSingle>}
     * @memberof CommentReactionRequest
     */
    'reactions'?: Array<CommentReactionRequestSingle>;
}
/**
 * 
 * @export
 * @interface CommentReactionRequestSingle
 */
export interface CommentReactionRequestSingle {
    /**
     * 
     * @type {string}
     * @memberof CommentReactionRequestSingle
     */
    'reaction': string;
    /**
     * 
     * @type {number}
     * @memberof CommentReactionRequestSingle
     */
    'count': number;
    /**
     * 
     * @type {Array<string>}
     * @memberof CommentReactionRequestSingle
     */
    'usernames': Array<string>;
}
/**
 * Reactionリスト情報
 * @export
 * @interface CommentReactions
 */
export interface CommentReactions {
    /**
     * 
     * @type {Array<CommentReaction>}
     * @memberof CommentReactions
     */
    'reactions': Array<CommentReaction>;
}
/**
 * コメント情報
 * @export
 * @interface CommentRequest
 */
export interface CommentRequest {
    /**
     * チーム名
     * @type {string}
     * @memberof CommentRequest
     */
    'teamname': string;
    /**
     * プロジェクトオーダー
     * @type {number}
     * @memberof CommentRequest
     */
    'project_order': number;
    /**
     * プロジェクト識別子
     * @type {string}
     * @memberof CommentRequest
     */
    'project_identifier': string;
    /**
     * コメント
     * @type {string}
     * @memberof CommentRequest
     */
    'comment': string;
    /**
     * 
     * @type {number}
     * @memberof CommentRequest
     */
    'editorVersion'?: number;
    /**
     * 反応用コメント
     * @type {string}
     * @memberof CommentRequest
     */
    'reaction_comment'?: string;
}
/**
 * スキミーデフォルトパーティ情報
 * @export
 * @interface DefaultParty
 */
export interface DefaultParty {
    /**
     * すでにデフォルトパーティとして設定されているかのフラグ
     * @type {boolean}
     * @memberof DefaultParty
     */
    'is_default_party': boolean;
    /**
     * パーティー番号
     * @type {number}
     * @memberof DefaultParty
     */
    'party_number': number;
}
/**
 * 
 * @export
 * @interface EncyclopediaResp
 */
export interface EncyclopediaResp {
    /**
     * 
     * @type {Array<SkimmyListByTypeResp>}
     * @memberof EncyclopediaResp
     */
    'skimmy_list_by_type': Array<SkimmyListByTypeResp>;
}
/**
 * テキミー情報
 * @export
 * @interface Enemy
 */
export interface Enemy {
    /**
     * テキミー番号
     * @type {number}
     * @memberof Enemy
     */
    'enemy_number': number;
    /**
     * テキミーサブ番号
     * @type {number}
     * @memberof Enemy
     */
    'enemy_sub_number': number;
    /**
     * レベル
     * @type {number}
     * @memberof Enemy
     */
    'enemy_level': number;
    /**
     * 現レベルで突破しているかのフラグ
     * @type {boolean}
     * @memberof Enemy
     */
    'is_after_breakthrough': boolean;
    /**
     * 属性
     * @type {string}
     * @memberof Enemy
     */
    'attribute': string;
    /**
     * 
     * @type {EnemyBattleParameters}
     * @memberof Enemy
     */
    'battle_parameters': EnemyBattleParameters;
    /**
     * 
     * @type {EnemyItem}
     * @memberof Enemy
     */
    'weapon': EnemyItem;
    /**
     * 
     * @type {string}
     * @memberof Enemy
     */
    'image': string;
    /**
     * 武器を保有しているかのフラグ
     * @type {boolean}
     * @memberof Enemy
     */
    'has_weapon': boolean;
}
/**
 * テキミーパラメーター情報
 * @export
 * @interface EnemyBattleParameters
 */
export interface EnemyBattleParameters {
    /**
     * テキミーのhp
     * @type {number}
     * @memberof EnemyBattleParameters
     */
    'hp': number;
    /**
     * テキミーの攻撃力
     * @type {number}
     * @memberof EnemyBattleParameters
     */
    'attack_strength': number;
    /**
     * テキミーの防御力
     * @type {number}
     * @memberof EnemyBattleParameters
     */
    'defense_strength': number;
    /**
     * テキミーのクリティカル値
     * @type {number}
     * @memberof EnemyBattleParameters
     */
    'critical_number': number;
}
/**
 * テキミーアイテム情報
 * @export
 * @interface EnemyItem
 */
export interface EnemyItem {
    /**
     * アイテムレア度
     * @type {number}
     * @memberof EnemyItem
     */
    'rarity': number;
    /**
     * アイテムコード
     * @type {string}
     * @memberof EnemyItem
     */
    'code': string;
    /**
     * 
     * @type {string}
     * @memberof EnemyItem
     */
    'image': string;
    /**
     * アイテム個数
     * @type {number}
     * @memberof EnemyItem
     */
    'count': number;
    /**
     * 
     * @type {EquipmentBattleParameters}
     * @memberof EnemyItem
     */
    'equipment_parameters': EquipmentBattleParameters;
}
/**
 * 装備バトルパラメーター情報
 * @export
 * @interface EquipmentBattleParameters
 */
export interface EquipmentBattleParameters {
    /**
     * 装備のレベル
     * @type {number}
     * @memberof EquipmentBattleParameters
     */
    'level': number;
    /**
     * 装備のhp
     * @type {number}
     * @memberof EquipmentBattleParameters
     */
    'hp': number;
    /**
     * 装備の攻撃力
     * @type {number}
     * @memberof EquipmentBattleParameters
     */
    'attack_strength': number;
    /**
     * 装備の防御力
     * @type {number}
     * @memberof EquipmentBattleParameters
     */
    'defense_strength': number;
    /**
     * 装備のクリティカル値
     * @type {number}
     * @memberof EquipmentBattleParameters
     */
    'critical_number': number;
}
/**
 * 装備レベルアップ情報
 * @export
 * @interface EquipmentLevelUpInfo
 */
export interface EquipmentLevelUpInfo {
    /**
     * 
     * @type {Array<UserItem>}
     * @memberof EquipmentLevelUpInfo
     */
    'equipment_reinforce_items': Array<UserItem>;
    /**
     * 進化段階
     * @type {number}
     * @memberof EquipmentLevelUpInfo
     */
    'evolutions_acquired': number;
    /**
     * 
     * @type {Array<UserItem>}
     * @memberof EquipmentLevelUpInfo
     */
    'evolution_items': Array<UserItem>;
    /**
     * 現装備レベル
     * @type {number}
     * @memberof EquipmentLevelUpInfo
     */
    'curr_equipment_level': number;
    /**
     * 現装備強化ポイント数
     * @type {number}
     * @memberof EquipmentLevelUpInfo
     */
    'curr_equipment_reinforce_points': number;
    /**
     * 次突破レベルのレベル数
     * @type {number}
     * @memberof EquipmentLevelUpInfo
     */
    'max_level_achievable': number;
    /**
     * 次突破レベルまでの強化ポイント数
     * @type {number}
     * @memberof EquipmentLevelUpInfo
     */
    'points_needed_for_max_level': number;
    /**
     * 次レベルまでの消化強化ポイント数
     * @type {number}
     * @memberof EquipmentLevelUpInfo
     */
    'next_level_curr_points': number;
    /**
     * 次レベルまでの必要強化ポイント数
     * @type {number}
     * @memberof EquipmentLevelUpInfo
     */
    'next_level_points_needed': number;
    /**
     * ユーザコイン数
     * @type {number}
     * @memberof EquipmentLevelUpInfo
     */
    'curr_coins': number;
    /**
     * 装備用の銅を使うために必要なコイン数
     * @type {number}
     * @memberof EquipmentLevelUpInfo
     */
    'coins_per_equipment_grade_bronze_use': number;
    /**
     * 装備用の銀を使うために必要なコイン数
     * @type {number}
     * @memberof EquipmentLevelUpInfo
     */
    'coins_per_equipment_grade_silver_use': number;
    /**
     * 装備用の金を使うために必要なコイン数
     * @type {number}
     * @memberof EquipmentLevelUpInfo
     */
    'coins_per_equipment_grade_gold_use': number;
    /**
     * 装備用の銅のポイント数
     * @type {number}
     * @memberof EquipmentLevelUpInfo
     */
    'points_per_equipment_grade_bronze_use': number;
    /**
     * 装備用の銀のポイント数
     * @type {number}
     * @memberof EquipmentLevelUpInfo
     */
    'points_per_equipment_grade_silver_use': number;
    /**
     * 装備用の金のポイント数
     * @type {number}
     * @memberof EquipmentLevelUpInfo
     */
    'points_per_equipment_grade_gold_use': number;
    /**
     * 
     * @type {Array<ExpsLevel>}
     * @memberof EquipmentLevelUpInfo
     */
    'exps_necessary_per_level': Array<ExpsLevel>;
    /**
     * 強化ポイントとコインの比率
     * @type {number}
     * @memberof EquipmentLevelUpInfo
     */
    'reinforce_exp_to_coin_ratio': number;
    /**
     * 次突破までの必要アイテム数
     * @type {number}
     * @memberof EquipmentLevelUpInfo
     */
    'next_evolution_items_needed': number;
    /**
     * 突破段階にあるかのフラグ
     * @type {boolean}
     * @memberof EquipmentLevelUpInfo
     */
    'is_evolution_step': boolean;
}
/**
 * 装備経験値とレベルアップ情報
 * @export
 * @interface EquipmentLevelUpReq
 */
export interface EquipmentLevelUpReq {
    /**
     * 装備ID
     * @type {number}
     * @memberof EquipmentLevelUpReq
     */
    'equipment_id': number;
    /**
     * アイテムコード
     * @type {string}
     * @memberof EquipmentLevelUpReq
     */
    'reinforce_item_code': string;
    /**
     * 強化アイテム消費数
     * @type {number}
     * @memberof EquipmentLevelUpReq
     */
    'reinforce_item_count': number;
    /**
     * コイン消費数
     * @type {number}
     * @memberof EquipmentLevelUpReq
     */
    'coin_count': number;
    /**
     * 獲得強化ポイント数
     * @type {number}
     * @memberof EquipmentLevelUpReq
     */
    'exp_count': number;
}
/**
 * 当月見積額
 * @export
 * @interface Estimate
 */
export interface Estimate {
    /**
     * 見積ID
     * @type {number}
     * @memberof Estimate
     */
    'id': number;
    /**
     * 当月見積額
     * @type {number}
     * @memberof Estimate
     */
    'amount': number;
}
/**
 * goalリスト情報
 * @export
 * @interface ExceededGoal
 */
export interface ExceededGoal {
    /**
     * 
     * @type {Team}
     * @memberof ExceededGoal
     */
    'team'?: Team;
    /**
     * 
     * @type {Array<Goal>}
     * @memberof ExceededGoal
     */
    'goals': Array<Goal>;
    /**
     * ヒット件数
     * @type {number}
     * @memberof ExceededGoal
     */
    'total_count': number;
    /**
     * オフセット値
     * @type {number}
     * @memberof ExceededGoal
     */
    'offset': number;
    /**
     * 上限値
     * @type {number}
     * @memberof ExceededGoal
     */
    'limit': number;
}
/**
 * goalリスト情報
 * @export
 * @interface ExceededGoals
 */
export interface ExceededGoals {
    /**
     * 
     * @type {Array<ExceededGoal>}
     * @memberof ExceededGoals
     */
    'goals': Array<ExceededGoal>;
}
/**
 * taskリスト情報
 * @export
 * @interface ExceededTask
 */
export interface ExceededTask {
    /**
     * 
     * @type {Team}
     * @memberof ExceededTask
     */
    'team'?: Team;
    /**
     * 
     * @type {Array<Task>}
     * @memberof ExceededTask
     */
    'tasks': Array<Task>;
    /**
     * ヒット件数
     * @type {number}
     * @memberof ExceededTask
     */
    'total_count': number;
    /**
     * オフセット値
     * @type {number}
     * @memberof ExceededTask
     */
    'offset': number;
    /**
     * 上限値
     * @type {number}
     * @memberof ExceededTask
     */
    'limit': number;
}
/**
 * taskリスト情報
 * @export
 * @interface ExceededTasks
 */
export interface ExceededTasks {
    /**
     * 
     * @type {Array<ExceededTask>}
     * @memberof ExceededTasks
     */
    'tasks': Array<ExceededTask>;
    /**
     * 
     * @type {Array<ExceededTask>}
     * @memberof ExceededTasks
     */
    'today_tasks'?: Array<ExceededTask>;
}
/**
 * チームタスク出力情報
 * @export
 * @interface ExportTeamTasksCsv
 */
export interface ExportTeamTasksCsv {
    /**
     * ファイル名
     * @type {string}
     * @memberof ExportTeamTasksCsv
     */
    'filename': string;
    /**
     * チームタスク情報のCSV
     * @type {string}
     * @memberof ExportTeamTasksCsv
     */
    'csv': string;
}
/**
 * チームタスク取得情報
 * @export
 * @interface ExportTeamTasksRequest
 */
export interface ExportTeamTasksRequest {
    /**
     * リクエスト元のホスト名
     * @type {string}
     * @memberof ExportTeamTasksRequest
     */
    'request_host': string;
    /**
     * チーム名
     * @type {string}
     * @memberof ExportTeamTasksRequest
     */
    'team_name': string;
    /**
     * プロジェクト番号
     * @type {string}
     * @memberof ExportTeamTasksRequest
     */
    'project_order': string | null;
    /**
     * タスクステータス
     * @type {string}
     * @memberof ExportTeamTasksRequest
     */
    'status_id': string | null;
    /**
     * 担当者名
     * @type {string}
     * @memberof ExportTeamTasksRequest
     */
    'assign_to_username': string | null;
    /**
     * タスクカテゴリー
     * @type {number}
     * @memberof ExportTeamTasksRequest
     */
    'category_id': number | null;
    /**
     * ソート情報
     * @type {string}
     * @memberof ExportTeamTasksRequest
     */
    'sort': string | null;
    /**
     * キーワード検索
     * @type {string}
     * @memberof ExportTeamTasksRequest
     */
    'search': string | null;
}
/**
 * 
 * @export
 * @interface ExpsLevel
 */
export interface ExpsLevel {
    /**
     * 
     * @type {number}
     * @memberof ExpsLevel
     */
    'level': number;
    /**
     * 
     * @type {number}
     * @memberof ExpsLevel
     */
    'exps_top': number;
    /**
     * 
     * @type {number}
     * @memberof ExpsLevel
     */
    'exps_bottom': number;
}
/**
 * お気に入りページ情報
 * @export
 * @interface FavoritePage
 */
export interface FavoritePage {
    /**
     * 登録名
     * @type {string}
     * @memberof FavoritePage
     */
    'name': string;
    /**
     * 登録ページへのリンク
     * @type {string}
     * @memberof FavoritePage
     */
    'value': string;
}
/**
 * お気に入りページ情報
 * @export
 * @interface FavoritePageReq
 */
export interface FavoritePageReq {
    /**
     * 登録名
     * @type {string}
     * @memberof FavoritePageReq
     */
    'name': string;
    /**
     * 登録するページへのリンク
     * @type {string}
     * @memberof FavoritePageReq
     */
    'value'?: string;
    /**
     * 順番
     * @type {number}
     * @memberof FavoritePageReq
     */
    'order'?: number;
}
/**
 * お気に入りページ一覧
 * @export
 * @interface FavoritePages
 */
export interface FavoritePages {
    /**
     * 
     * @type {Array<FavoritePage>}
     * @memberof FavoritePages
     */
    'favorite_pages': Array<FavoritePage>;
}
/**
 * お気に入りページ情報
 * @export
 * @interface FavoritePagesReq
 */
export interface FavoritePagesReq {
    /**
     * 
     * @type {Array<FavoritePageReq>}
     * @memberof FavoritePagesReq
     */
    'favs': Array<FavoritePageReq>;
}
/**
 * テーブル情報
 * @export
 * @interface FieldDefReq
 */
export interface FieldDefReq {
    /**
     * キーフラグ
     * @type {boolean}
     * @memberof FieldDefReq
     */
    'is_key': boolean;
    /**
     * 順番
     * @type {number}
     * @memberof FieldDefReq
     */
    'order': number;
    /**
     * 型
     * @type {string}
     * @memberof FieldDefReq
     */
    'type': string;
    /**
     * カラム識別子
     * @type {string}
     * @memberof FieldDefReq
     */
    'identifier'?: string;
    /**
     * カラム名
     * @type {string}
     * @memberof FieldDefReq
     */
    'name': string;
    /**
     * 説明
     * @type {string}
     * @memberof FieldDefReq
     */
    'description': string;
    /**
     * 必須フラグ
     * @type {boolean}
     * @memberof FieldDefReq
     */
    'nullable': boolean;
    /**
     * 非表示フラグ
     * @type {boolean}
     * @memberof FieldDefReq
     */
    'hide': boolean;
}
/**
 * カラム定義情報
 * @export
 * @interface FieldDefResp
 */
export interface FieldDefResp {
    /**
     * 
     * @type {boolean}
     * @memberof FieldDefResp
     */
    'is_key': boolean;
    /**
     * 順番
     * @type {number}
     * @memberof FieldDefResp
     */
    'order': number;
    /**
     * 型
     * @type {string}
     * @memberof FieldDefResp
     */
    'type': string;
    /**
     * カラム識別子
     * @type {string}
     * @memberof FieldDefResp
     */
    'identifier': string;
    /**
     * カラム名
     * @type {string}
     * @memberof FieldDefResp
     */
    'name': string;
    /**
     * 説明
     * @type {string}
     * @memberof FieldDefResp
     */
    'description': string;
    /**
     * 必須フラグ
     * @type {boolean}
     * @memberof FieldDefResp
     */
    'nullable': boolean;
    /**
     * 非表示フラグ
     * @type {boolean}
     * @memberof FieldDefResp
     */
    'hide': boolean;
}
/**
 * カラム定義情報（テーブル構築用）
 * @export
 * @interface FieldResp
 */
export interface FieldResp {
    /**
     * 
     * @type {string}
     * @memberof FieldResp
     */
    'key': string;
    /**
     * 
     * @type {string}
     * @memberof FieldResp
     */
    'label': string;
    /**
     * 
     * @type {string}
     * @memberof FieldResp
     */
    'type': string;
    /**
     * 
     * @type {boolean}
     * @memberof FieldResp
     */
    'sortable': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof FieldResp
     */
    'is_key': boolean;
    /**
     * 必須フラグ
     * @type {boolean}
     * @memberof FieldResp
     */
    'nullable': boolean;
    /**
     * 非表示フラグ
     * @type {boolean}
     * @memberof FieldResp
     */
    'hide': boolean;
}
/**
 * フィールドタイプ
 * @export
 * @interface FieldTypeResp
 */
export interface FieldTypeResp {
    /**
     * 
     * @type {string}
     * @memberof FieldTypeResp
     */
    'value': string;
    /**
     * 
     * @type {string}
     * @memberof FieldTypeResp
     */
    'text': string;
    /**
     * 
     * @type {boolean}
     * @memberof FieldTypeResp
     */
    'uniqable'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof FieldTypeResp
     */
    'sortable'?: boolean;
}
/**
 * フィールドタイプ一覧
 * @export
 * @interface FieldTypesResp
 */
export interface FieldTypesResp {
    /**
     * 
     * @type {Array<FieldTypeResp>}
     * @memberof FieldTypesResp
     */
    'items': Array<FieldTypeResp>;
}
/**
 * カラム定義情報リスト
 * @export
 * @interface FieldsResp
 */
export interface FieldsResp {
    /**
     * テーブル識別子
     * @type {number}
     * @memberof FieldsResp
     */
    'table_number'?: number;
    /**
     * 順番
     * @type {number}
     * @memberof FieldsResp
     */
    'order'?: number;
    /**
     * テーブル名
     * @type {string}
     * @memberof FieldsResp
     */
    'name'?: string;
    /**
     * 説明
     * @type {string}
     * @memberof FieldsResp
     */
    'description'?: string;
    /**
     * 
     * @type {Array<FieldResp>}
     * @memberof FieldsResp
     */
    'fields': Array<FieldResp>;
}
/**
 * ゲームスキミーレベルアップ情報
 * @export
 * @interface GameLoginBonus
 */
export interface GameLoginBonus {
    /**
     * 獲得ユーザEXP
     * @type {number}
     * @memberof GameLoginBonus
     */
    'user_exp_acquired': number;
    /**
     * 獲得ジェム
     * @type {number}
     * @memberof GameLoginBonus
     */
    'gems_acquired': number;
    /**
     * 獲得コイン
     * @type {number}
     * @memberof GameLoginBonus
     */
    'coins_acquired': number;
    /**
     * ユーザ総EXP数
     * @type {number}
     * @memberof GameLoginBonus
     */
    'user_exps': number;
    /**
     * 現レベルのスタートEXP数
     * @type {number}
     * @memberof GameLoginBonus
     */
    'user_exps_from_curr_level': number;
    /**
     * 次レベルの必要EXP数
     * @type {number}
     * @memberof GameLoginBonus
     */
    'user_exps_to_next_level': number;
    /**
     * ユーザレベル
     * @type {number}
     * @memberof GameLoginBonus
     */
    'user_level': number;
    /**
     * ユーザジェム数
     * @type {number}
     * @memberof GameLoginBonus
     */
    'user_gems': number;
    /**
     * 
     * @type {SkimmyGrowthItems}
     * @memberof GameLoginBonus
     */
    'skimmy_growth_items': SkimmyGrowthItems;
    /**
     * ユーザ操作数
     * @type {number}
     * @memberof GameLoginBonus
     */
    'accumulated_operations': number;
    /**
     * 
     * @type {BattleTickets}
     * @memberof GameLoginBonus
     */
    'battle_tickets': BattleTickets;
}
/**
 * ジェム情報
 * @export
 * @interface GemReward
 */
export interface GemReward {
    /**
     * オペレーションID
     * @type {number}
     * @memberof GemReward
     */
    'operation_id': number;
    /**
     * ジェム数
     * @type {number}
     * @memberof GemReward
     */
    'gem_count': number;
    /**
     * オペレーション種別
     * @type {number}
     * @memberof GemReward
     */
    'operation_type': number;
}
/**
 * ジェム取引情報
 * @export
 * @interface GemTransaction
 */
export interface GemTransaction {
    /**
     * 取引成否フラグ
     * @type {boolean}
     * @memberof GemTransaction
     */
    'successful_transaction': boolean;
}
/**
 * ジェム取引情報
 * @export
 * @interface GemTransactionRequest
 */
export interface GemTransactionRequest {
    /**
     * ジェム取得方法ID
     * @type {number}
     * @memberof GemTransactionRequest
     */
    'operation_id': number;
    /**
     * ジェム取得方法種別
     * @type {number}
     * @memberof GemTransactionRequest
     */
    'operation_type': number;
    /**
     * 取引ジェム数
     * @type {number}
     * @memberof GemTransactionRequest
     */
    'gem_count': number;
    /**
     * 獲得か使用かを切り分けるフラグ
     * @type {boolean}
     * @memberof GemTransactionRequest
     */
    'is_acquisition': boolean;
}
/**
 * goal情報
 * @export
 * @interface Goal
 */
export interface Goal {
    /**
     * ゴールID
     * @type {number}
     * @memberof Goal
     */
    'id': number;
    /**
     * 
     * @type {Category}
     * @memberof Goal
     */
    'category'?: Category;
    /**
     * タイトル
     * @type {string}
     * @memberof Goal
     */
    'subject': string;
    /**
     * タスク内容
     * @type {string}
     * @memberof Goal
     */
    'description': string;
    /**
     * 開始日
     * @type {string}
     * @memberof Goal
     */
    'start_date_obj': string | null;
    /**
     * 開始日
     * @type {string}
     * @memberof Goal
     */
    'start_date': string | null;
    /**
     * 終了日
     * @type {string}
     * @memberof Goal
     */
    'due_date_obj': string | null;
    /**
     * 終了日
     * @type {string}
     * @memberof Goal
     */
    'due_date': string | null;
    /**
     * 
     * @type {string}
     * @memberof Goal
     */
    'created_on': string;
    /**
     * 達成率
     * @type {number}
     * @memberof Goal
     */
    'done_ratio': number;
    /**
     * 
     * @type {Status}
     * @memberof Goal
     */
    'status': Status;
    /**
     * 
     * @type {Author}
     * @memberof Goal
     */
    'author': Author;
    /**
     * 
     * @type {number}
     * @memberof Goal
     */
    'editorVersion'?: number;
}
/**
 * ゴール情報
 * @export
 * @interface GoalRequest
 */
export interface GoalRequest {
    /**
     * ゴールID
     * @type {number}
     * @memberof GoalRequest
     */
    'id': number;
    /**
     * カテゴリID
     * @type {number}
     * @memberof GoalRequest
     */
    'category_id': number;
    /**
     * タイトル
     * @type {string}
     * @memberof GoalRequest
     */
    'subject': string;
    /**
     * タスク内容
     * @type {string}
     * @memberof GoalRequest
     */
    'description': string;
    /**
     * 開始日
     * @type {string}
     * @memberof GoalRequest
     */
    'start_date_obj': string | null;
    /**
     * 開始日
     * @type {string}
     * @memberof GoalRequest
     */
    'start_date'?: string | null;
    /**
     * 終了日
     * @type {string}
     * @memberof GoalRequest
     */
    'due_date_obj': string | null;
    /**
     * 終了日
     * @type {string}
     * @memberof GoalRequest
     */
    'due_date'?: string | null;
    /**
     * 達成率
     * @type {number}
     * @memberof GoalRequest
     */
    'done_ratio': number;
    /**
     * ステータスID
     * @type {number}
     * @memberof GoalRequest
     */
    'status_id': number;
    /**
     * 
     * @type {number}
     * @memberof GoalRequest
     */
    'editorVersion'?: number;
}
/**
 * goalリスト情報
 * @export
 * @interface Goals
 */
export interface Goals {
    /**
     * 
     * @type {Array<Goal>}
     * @memberof Goals
     */
    'issues': Array<Goal>;
    /**
     * ヒット件数
     * @type {number}
     * @memberof Goals
     */
    'total_count': number;
    /**
     * オフセット値
     * @type {number}
     * @memberof Goals
     */
    'offset': number;
    /**
     * 上限値
     * @type {number}
     * @memberof Goals
     */
    'limit': number;
}
/**
 * ハッシュタグ情報
 * @export
 * @interface Hashtag
 */
export interface Hashtag {
    /**
     * ハッシュタグ
     * @type {string}
     * @memberof Hashtag
     */
    'hashtag': string;
    /**
     * 順番
     * @type {number}
     * @memberof Hashtag
     */
    'order': number;
}
/**
 * ハッシュタグ情報
 * @export
 * @interface HashtagRequest
 */
export interface HashtagRequest {
    /**
     * ハッシュタグ
     * @type {string}
     * @memberof HashtagRequest
     */
    'hashtag': string;
    /**
     * 順番
     * @type {number}
     * @memberof HashtagRequest
     */
    'order': number;
}
/**
 * ハッシュタグサジェストリスト
 * @export
 * @interface HashtagSuggests
 */
export interface HashtagSuggests {
    /**
     * 
     * @type {Array<string>}
     * @memberof HashtagSuggests
     */
    'hashtags': Array<string>;
}
/**
 * ID(integer)とValue(string)による指定
 * @export
 * @interface IdValue
 */
export interface IdValue {
    /**
     * ID
     * @type {number}
     * @memberof IdValue
     */
    'id': number;
    /**
     * 文字列
     * @type {string}
     * @memberof IdValue
     */
    'value': string;
}
/**
 * ImageManager画像リスト情報
 * @export
 * @interface ImagemanagerImages
 */
export interface ImagemanagerImages {
    /**
     * 
     * @type {Array<string>}
     * @memberof ImagemanagerImages
     */
    'imageURLs': Array<string>;
}
/**
 * 画像取得用情報
 * @export
 * @interface ImagemanagerImagesRequest
 */
export interface ImagemanagerImagesRequest {
    /**
     * アップロードカテゴリー
     * @type {string}
     * @memberof ImagemanagerImagesRequest
     */
    'category': string;
    /**
     * アップロード区分
     * @type {string}
     * @memberof ImagemanagerImagesRequest
     */
    'sort': string;
    /**
     * チームID
     * @type {number}
     * @memberof ImagemanagerImagesRequest
     */
    'team_id'?: number;
}
/**
 * 認証状態
 * @export
 * @interface InfoAuth
 */
export interface InfoAuth {
    /**
     * 認証状態
     * @type {boolean}
     * @memberof InfoAuth
     */
    'valid': boolean;
}
/**
 * バージョン情報
 * @export
 * @interface InfoVersion
 */
export interface InfoVersion {
    /**
     * バージョン情報
     * @type {string}
     * @memberof InfoVersion
     */
    'version': string;
}
/**
 * インテグレーション設定情報
 * @export
 * @interface IntegSetting
 */
export interface IntegSetting {
    /**
     * インテグレーション設定ID
     * @type {number}
     * @memberof IntegSetting
     */
    'id': number;
    /**
     * インテグレーション種別ID
     * @type {number}
     * @memberof IntegSetting
     */
    'integration_id': number;
    /**
     * インテグレーション設定オーダー
     * @type {number}
     * @memberof IntegSetting
     */
    'order': number;
    /**
     * 設定チームID
     * @type {number}
     * @memberof IntegSetting
     */
    'team_id': number;
    /**
     * 説明
     * @type {string}
     * @memberof IntegSetting
     */
    'description': string;
    /**
     * 
     * @type {Array<IntegSettingMeta>}
     * @memberof IntegSetting
     */
    'integration_setting_metas': Array<IntegSettingMeta>;
}
/**
 * インテグレーション設定メタ情報
 * @export
 * @interface IntegSettingMeta
 */
export interface IntegSettingMeta {
    /**
     * インテグレーション設定メタID
     * @type {number}
     * @memberof IntegSettingMeta
     */
    'id': number;
    /**
     * インテグレーション種別メタID
     * @type {number}
     * @memberof IntegSettingMeta
     */
    'integration_meta_id': number;
    /**
     * インテグレーション設定ID
     * @type {number}
     * @memberof IntegSettingMeta
     */
    'integration_setting_id': number;
    /**
     * 設定値
     * @type {string}
     * @memberof IntegSettingMeta
     */
    'value': string;
    /**
     * 説明
     * @type {string}
     * @memberof IntegSettingMeta
     */
    'description': string;
}
/**
 * インテグレーション設定情報リスト
 * @export
 * @interface IntegSettings
 */
export interface IntegSettings {
    /**
     * 
     * @type {Array<IntegSetting>}
     * @memberof IntegSettings
     */
    'integration_settings': Array<IntegSetting>;
}
/**
 * インテグレーション情報
 * @export
 * @interface Integration
 */
export interface Integration {
    /**
     * インテグレーションコードID
     * @type {number}
     * @memberof Integration
     */
    'id': number;
    /**
     * インテグレーションコード
     * @type {string}
     * @memberof Integration
     */
    'code': string;
    /**
     * コード名
     * @type {string}
     * @memberof Integration
     */
    'name': string;
    /**
     * 説明
     * @type {string}
     * @memberof Integration
     */
    'description': string;
    /**
     * 
     * @type {Array<IntegrationMeta>}
     * @memberof Integration
     */
    'integration_metas': Array<IntegrationMeta>;
}
/**
 * インテグレーションメタ情報
 * @export
 * @interface IntegrationMeta
 */
export interface IntegrationMeta {
    /**
     * ID
     * @type {number}
     * @memberof IntegrationMeta
     */
    'id': number;
    /**
     * インテグレーションID
     * @type {number}
     * @memberof IntegrationMeta
     */
    'integration_id': number;
    /**
     * キー
     * @type {string}
     * @memberof IntegrationMeta
     */
    'key': string;
    /**
     * オーダー
     * @type {number}
     * @memberof IntegrationMeta
     */
    'order': number;
    /**
     * 名前
     * @type {string}
     * @memberof IntegrationMeta
     */
    'name': string;
}
/**
 * インテグレーションコードリスト情報
 * @export
 * @interface Integrations
 */
export interface Integrations {
    /**
     * 
     * @type {Array<Integration>}
     * @memberof Integrations
     */
    'integrations': Array<Integration>;
}
/**
 * イシューメタ
 * @export
 * @interface IssueMeta
 */
export interface IssueMeta {
    /**
     * イシューメタID
     * @type {number}
     * @memberof IssueMeta
     */
    'id': number;
    /**
     * イシューID
     * @type {number}
     * @memberof IssueMeta
     */
    'issue_id': number;
    /**
     * イシュータグID
     * @type {number}
     * @memberof IssueMeta
     */
    'issue_tag_id': number;
    /**
     * 値
     * @type {string}
     * @memberof IssueMeta
     */
    'value': string;
    /**
     * 有効無効
     * @type {boolean}
     * @memberof IssueMeta
     */
    'active': boolean;
}
/**
 * イシューメタ
 * @export
 * @interface IssueMetaRequest
 */
export interface IssueMetaRequest {
    /**
     * イシュータグID
     * @type {number}
     * @memberof IssueMetaRequest
     */
    'issue_tag_id': number;
    /**
     * 値
     * @type {string}
     * @memberof IssueMetaRequest
     */
    'value': string;
    /**
     * 有効無効
     * @type {boolean}
     * @memberof IssueMetaRequest
     */
    'active': boolean;
}
/**
 * アイテム情報
 * @export
 * @interface Item
 */
export interface Item {
    /**
     * コードID
     * @type {number}
     * @memberof Item
     */
    'id': number;
    /**
     * アイテムマスタコード
     * @type {string}
     * @memberof Item
     */
    'code': string;
    /**
     * 
     * @type {string}
     * @memberof Item
     */
    'image': string;
    /**
     * 
     * @type {Array<Material>}
     * @memberof Item
     */
    'materials'?: Array<Material>;
    /**
     * 
     * @type {AcquisitionPath}
     * @memberof Item
     */
    'acquisition_path'?: AcquisitionPath;
    /**
     * ユーザアイテム保有数
     * @type {number}
     * @memberof Item
     */
    'user_count'?: number;
    /**
     * 
     * @type {string}
     * @memberof Item
     */
    'resource_image': string;
    /**
     * 
     * @type {boolean}
     * @memberof Item
     */
    'is_season_item': boolean;
    /**
     * シーズンリソース数
     * @type {number}
     * @memberof Item
     */
    'season_resource_count': number;
    /**
     * シーズン番号
     * @type {number}
     * @memberof Item
     */
    'season': number;
    /**
     * アイテムレア度
     * @type {number}
     * @memberof Item
     */
    'rarity': number;
}
/**
 * アイテムリスト情報
 * @export
 * @interface Items
 */
export interface Items {
    /**
     * 
     * @type {Array<Item>}
     * @memberof Items
     */
    'items': Array<Item>;
    /**
     * 
     * @type {PageInfo}
     * @memberof Items
     */
    'page_info': PageInfo;
}
/**
 * イシュー履歴
 * @export
 * @interface Journal
 */
export interface Journal {
    /**
     * ジャーナルID
     * @type {number}
     * @memberof Journal
     */
    'id': number;
    /**
     * 値
     * @type {string}
     * @memberof Journal
     */
    'notes': string;
    /**
     * 更新有無
     * @type {boolean}
     * @memberof Journal
     */
    'notes_updated': boolean;
    /**
     * 削除有無
     * @type {boolean}
     * @memberof Journal
     */
    'notes_deleted': boolean;
    /**
     * 
     * @type {string}
     * @memberof Journal
     */
    'created_on': string;
    /**
     * 
     * @type {string}
     * @memberof Journal
     */
    'updated_on': string;
    /**
     * 
     * @type {number}
     * @memberof Journal
     */
    'editorVersion'?: number;
    /**
     * 
     * @type {Array<JournalDetail>}
     * @memberof Journal
     */
    'details'?: Array<JournalDetail>;
    /**
     * 
     * @type {TaskUser}
     * @memberof Journal
     */
    'user'?: TaskUser;
}
/**
 * イシュー履歴詳細
 * @export
 * @interface JournalDetail
 */
export interface JournalDetail {
    /**
     * 値
     * @type {string}
     * @memberof JournalDetail
     */
    'property': string;
    /**
     * 値
     * @type {string}
     * @memberof JournalDetail
     */
    'name': string;
    /**
     * 値
     * @type {string}
     * @memberof JournalDetail
     */
    'old_value': string;
    /**
     * 値
     * @type {string}
     * @memberof JournalDetail
     */
    'new_value': string;
}
/**
 * キーバリューペア
 * @export
 * @interface Keyvaluepair
 */
export interface Keyvaluepair {
    /**
     * 
     * @type {string}
     * @memberof Keyvaluepair
     */
    'key': string;
    /**
     * 
     * @type {string}
     * @memberof Keyvaluepair
     */
    'value': string;
}
/**
 * キーバリューペアリスト
 * @export
 * @interface Keyvaluepairs
 */
export interface Keyvaluepairs {
    /**
     * 
     * @type {Array<Keyvaluepair>}
     * @memberof Keyvaluepairs
     */
    'pairs': Array<Keyvaluepair>;
}
/**
 * リンクボタン情報
 * @export
 * @interface LinkButton
 */
export interface LinkButton {
    /**
     * タイトル
     * @type {string}
     * @memberof LinkButton
     */
    'title': string;
    /**
     * 順番
     * @type {number}
     * @memberof LinkButton
     */
    'order': number;
    /**
     * リンク
     * @type {string}
     * @memberof LinkButton
     */
    'link': string;
    /**
     * アイコン画像URL
     * @type {string}
     * @memberof LinkButton
     */
    'icon_image': string;
    /**
     * アイコン絵文字テキスト
     * @type {string}
     * @memberof LinkButton
     */
    'emoji_text': string;
    /**
     * 種別
     * @type {string}
     * @memberof LinkButton
     */
    'content_type': string;
    /**
     * 表示フラグ
     * @type {boolean}
     * @memberof LinkButton
     */
    'is_active': boolean;
    /**
     * 
     * @type {Array<Story>}
     * @memberof LinkButton
     */
    'stories': Array<Story>;
}
/**
 * リンクボタン情報
 * @export
 * @interface LinkButtonRequest
 */
export interface LinkButtonRequest {
    /**
     * タイトル
     * @type {string}
     * @memberof LinkButtonRequest
     */
    'title': string;
    /**
     * 順番
     * @type {number}
     * @memberof LinkButtonRequest
     */
    'order': number;
    /**
     * リンク
     * @type {string}
     * @memberof LinkButtonRequest
     */
    'link': string;
    /**
     * アイコン画像URL
     * @type {string}
     * @memberof LinkButtonRequest
     */
    'icon_image': string;
    /**
     * アイコン絵文字テキスト
     * @type {string}
     * @memberof LinkButtonRequest
     */
    'emoji_text': string;
    /**
     * 種別
     * @type {string}
     * @memberof LinkButtonRequest
     */
    'content_type': string;
    /**
     * 表示フラグ
     * @type {boolean}
     * @memberof LinkButtonRequest
     */
    'is_active': boolean;
    /**
     * 
     * @type {Array<StoryRequest>}
     * @memberof LinkButtonRequest
     */
    'stories': Array<StoryRequest>;
}
/**
 * バッジ情報
 * @export
 * @interface Material
 */
export interface Material {
    /**
     * コードID
     * @type {number}
     * @memberof Material
     */
    'id': number;
    /**
     * 
     * @type {Item}
     * @memberof Material
     */
    'item': Item;
}
/**
 * メンバー出席状況
 * @export
 * @interface MemberAttendance
 */
export interface MemberAttendance {
    /**
     * ID
     * @type {number}
     * @memberof MemberAttendance
     */
    'id'?: number;
    /**
     * 出席状況カテゴリーID
     * @type {number}
     * @memberof MemberAttendance
     */
    'member_attendance_id': number;
}
/**
 * メンバー出席状況へのインテグレーション
 * @export
 * @interface MemberAttendanceInteg
 */
export interface MemberAttendanceInteg {
    /**
     * 出席状況のインテグレーションID
     * @type {number}
     * @memberof MemberAttendanceInteg
     */
    'id': number;
    /**
     * インテグレーションの種別ID
     * @type {number}
     * @memberof MemberAttendanceInteg
     */
    'integration_id': number;
    /**
     * 出席状況カテゴリーID
     * @type {number}
     * @memberof MemberAttendanceInteg
     */
    'member_attendance_id': number;
    /**
     * 
     * @type {Array<MemberAttendanceIntegMeta>}
     * @memberof MemberAttendanceInteg
     */
    'meta': Array<MemberAttendanceIntegMeta>;
}
/**
 * メンバー出席状況へのインテグレーションメタ
 * @export
 * @interface MemberAttendanceIntegMeta
 */
export interface MemberAttendanceIntegMeta {
    /**
     * 出席状況のインテグレーションメタID
     * @type {number}
     * @memberof MemberAttendanceIntegMeta
     */
    'id': number;
    /**
     * インテグレーション種別メタ情報ID
     * @type {number}
     * @memberof MemberAttendanceIntegMeta
     */
    'integration_id': number;
    /**
     * 値
     * @type {string}
     * @memberof MemberAttendanceIntegMeta
     */
    'value': string;
}
/**
 * メンバー出席状況へのインテグレーション情報の追加
 * @export
 * @interface MemberAttendanceIntegReq
 */
export interface MemberAttendanceIntegReq {
    /**
     * インテグレーション種別ID
     * @type {number}
     * @memberof MemberAttendanceIntegReq
     */
    'integration_id': number;
    /**
     * 通知の有効/無効
     * @type {boolean}
     * @memberof MemberAttendanceIntegReq
     */
    'active': boolean;
    /**
     * 
     * @type {Array<MemberAttendanceIntegReqMetaInner>}
     * @memberof MemberAttendanceIntegReq
     */
    'meta': Array<MemberAttendanceIntegReqMetaInner>;
}
/**
 * メンバー出席状況へのインテグレーション情報のメタ情報
 * @export
 * @interface MemberAttendanceIntegReqMetaInner
 */
export interface MemberAttendanceIntegReqMetaInner {
    /**
     * インテグレーションメタ種別ID
     * @type {number}
     * @memberof MemberAttendanceIntegReqMetaInner
     */
    'integration_meta_id': number;
    /**
     * 値
     * @type {string}
     * @memberof MemberAttendanceIntegReqMetaInner
     */
    'value': string;
}
/**
 * メンバー出席状況へのインテグレーションリスト
 * @export
 * @interface MemberAttendanceIntegs
 */
export interface MemberAttendanceIntegs {
    /**
     * 
     * @type {Array<MemberAttendanceInteg>}
     * @memberof MemberAttendanceIntegs
     */
    'integs': Array<MemberAttendanceInteg>;
}
/**
 * メンバー出席状況
 * @export
 * @interface MemberAttendanceReq
 */
export interface MemberAttendanceReq {
    /**
     * 出席状況カテゴリーID
     * @type {number}
     * @memberof MemberAttendanceReq
     */
    'member_attendance_id': number;
}
/**
 * チームメンバーへのコンテンツ共有設定情報
 * @export
 * @interface MemberShareSetting
 */
export interface MemberShareSetting {
    /**
     * チームメンバーのユーザーID
     * @type {number}
     * @memberof MemberShareSetting
     */
    'member_id': number;
    /**
     * 共有設定
     * @type {boolean}
     * @memberof MemberShareSetting
     */
    'is_shared': boolean;
}
/**
 * チームメンバーへのスキル共有設定情報
 * @export
 * @interface MemberSkillsShareSetting
 */
export interface MemberSkillsShareSetting {
    /**
     * チームメンバーのユーザーID
     * @type {number}
     * @memberof MemberSkillsShareSetting
     */
    'member_id': number;
    /**
     * 共有設定
     * @type {boolean}
     * @memberof MemberSkillsShareSetting
     */
    'is_shared_software': boolean;
    /**
     * 共有設定
     * @type {boolean}
     * @memberof MemberSkillsShareSetting
     */
    'is_shared_design': boolean;
}
/**
 * メンバー作業時間情報
 * @export
 * @interface MemberTimeentrie
 */
export interface MemberTimeentrie {
    /**
     * 対象日付
     * @type {string}
     * @memberof MemberTimeentrie
     */
    'target_date': string;
    /**
     * 
     * @type {Array<MemberTimeentrieHour>}
     * @memberof MemberTimeentrie
     */
    'hours': Array<MemberTimeentrieHour>;
}
/**
 * 作業時間情報
 * @export
 * @interface MemberTimeentrieHour
 */
export interface MemberTimeentrieHour {
    /**
     * プロジェクト名
     * @type {string}
     * @memberof MemberTimeentrieHour
     */
    'name': string;
    /**
     * 作業時間
     * @type {number}
     * @memberof MemberTimeentrieHour
     */
    'amount': number;
}
/**
 * メンバー作業時間情報
 * @export
 * @interface MemberTimeentries
 */
export interface MemberTimeentries {
    /**
     * 
     * @type {Array<MemberTimeentrie>}
     * @memberof MemberTimeentries
     */
    'entries': Array<MemberTimeentrie>;
}
/**
 * メンバー情報
 * @export
 * @interface Members
 */
export interface Members {
    /**
     * 
     * @type {Array<User>}
     * @memberof Members
     */
    'members': Array<User>;
    /**
     * 
     * @type {number}
     * @memberof Members
     */
    'totalCount'?: number;
}
/**
 * メンション情報
 * @export
 * @interface Mention
 */
export interface Mention {
    /**
     * ユーザー名
     * @type {string}
     * @memberof Mention
     */
    'value': string;
    /**
     * ユーザー表示名
     * @type {string}
     * @memberof Mention
     */
    'label': string;
}
/**
 * 
 * @export
 * @interface Mentions
 */
export interface Mentions {
    /**
     * 
     * @type {Array<Mention>}
     * @memberof Mentions
     */
    'mentions': Array<Mention>;
}
/**
 * ミッションコード情報
 * @export
 * @interface Mission
 */
export interface Mission {
    /**
     * ミッションID
     * @type {number}
     * @memberof Mission
     */
    'id': number;
    /**
     * ミッションヒストリーID
     * @type {number}
     * @memberof Mission
     */
    'mission_history_id': number;
    /**
     * ミッションコード
     * @type {string}
     * @memberof Mission
     */
    'code': string;
    /**
     * ミッションコード名
     * @type {string}
     * @memberof Mission
     */
    'name': string;
    /**
     * 説明
     * @type {string}
     * @memberof Mission
     */
    'description': string;
    /**
     * ミッション期間種別
     * @type {number}
     * @memberof Mission
     */
    'period': number;
    /**
     * ミッション報酬
     * @type {number}
     * @memberof Mission
     */
    'reward'?: number;
    /**
     * ミッションの達成状況を示すフラグ
     * @type {boolean}
     * @memberof Mission
     */
    'is_cleared': boolean;
    /**
     * ミッション報酬の獲得状況を示すフラグ
     * @type {boolean}
     * @memberof Mission
     */
    'has_reward': boolean;
}
/**
 * 達成ミッション情報
 * @export
 * @interface MissionAlertRequest
 */
export interface MissionAlertRequest {
    /**
     * ミッションコード
     * @type {string}
     * @memberof MissionAlertRequest
     */
    'code': string;
    /**
     * ミッション期間
     * @type {number}
     * @memberof MissionAlertRequest
     */
    'period': number;
}
/**
 * 達成ミッション情報
 * @export
 * @interface MissionDailyAccomplishedRequest
 */
export interface MissionDailyAccomplishedRequest {
    /**
     * ミッションコード
     * @type {string}
     * @memberof MissionDailyAccomplishedRequest
     */
    'code': string;
    /**
     * ミッション種別
     * @type {number}
     * @memberof MissionDailyAccomplishedRequest
     */
    'mission_type': number;
}
/**
 * ミッション履歴情報
 * @export
 * @interface MissionRecord
 */
export interface MissionRecord {
    /**
     * ミッション数
     * @type {number}
     * @memberof MissionRecord
     */
    'num_missions': number;
    /**
     * ミッション達成数
     * @type {number}
     * @memberof MissionRecord
     */
    'num_missions_cleared': number;
    /**
     * ミッション達成割合
     * @type {number}
     * @memberof MissionRecord
     */
    'mission_clear_rate': number;
}
/**
 * ミッション完了ステータス情報
 * @export
 * @interface MissionStatus
 */
export interface MissionStatus {
    /**
     * ミッション完了ステータス
     * @type {boolean}
     * @memberof MissionStatus
     */
    'isCleared': boolean;
}
/**
 * ミッションコードリスト情報
 * @export
 * @interface Missions
 */
export interface Missions {
    /**
     * 
     * @type {Array<Mission>}
     * @memberof Missions
     */
    'missions': Array<Mission>;
}
/**
 * デイリーミッション情報
 * @export
 * @interface MissionsDaily
 */
export interface MissionsDaily {
    /**
     * 
     * @type {MissionsDailyMissions}
     * @memberof MissionsDaily
     */
    'missions': MissionsDailyMissions;
    /**
     * 
     * @type {MissionsDailyMissionRecords}
     * @memberof MissionsDaily
     */
    'mission_records': MissionsDailyMissionRecords;
    /**
     * 
     * @type {MissionsDailyMissions}
     * @memberof MissionsDaily
     */
    'personal_missions': MissionsDailyMissions;
    /**
     * 
     * @type {MissionsDailyMissionRecords}
     * @memberof MissionsDaily
     */
    'personal_mission_records': MissionsDailyMissionRecords;
}
/**
 * デイリーミッション全クリア日情報
 * @export
 * @interface MissionsDailyCalendar
 */
export interface MissionsDailyCalendar {
    /**
     * 
     * @type {Array<number>}
     * @memberof MissionsDailyCalendar
     */
    'all_missions_cleared_dates': Array<number>;
}
/**
 * 
 * @export
 * @interface MissionsDailyMissionRecords
 */
export interface MissionsDailyMissionRecords {
    /**
     * 
     * @type {MissionRecord}
     * @memberof MissionsDailyMissionRecords
     */
    'today'?: MissionRecord;
}
/**
 * 
 * @export
 * @interface MissionsDailyMissions
 */
export interface MissionsDailyMissions {
    /**
     * 
     * @type {Array<Mission>}
     * @memberof MissionsDailyMissions
     */
    'fixed_missions'?: Array<Mission>;
    /**
     * 
     * @type {Array<Mission>}
     * @memberof MissionsDailyMissions
     */
    'random_missions'?: Array<Mission>;
    /**
     * 
     * @type {Mission}
     * @memberof MissionsDailyMissions
     */
    'max_mission'?: Mission;
}
/**
 * ニュースフィード情報
 * @export
 * @interface Newsfeed
 */
export interface Newsfeed {
    /**
     * title
     * @type {string}
     * @memberof Newsfeed
     */
    'title': string;
    /**
     * url
     * @type {string}
     * @memberof Newsfeed
     */
    'url': string;
    /**
     * description
     * @type {string}
     * @memberof Newsfeed
     */
    'description': string;
    /**
     * imageUrl
     * @type {string}
     * @memberof Newsfeed
     */
    'imageUrl': string;
}
/**
 * ニュースフィードリスト
 * @export
 * @interface Newsfeeds
 */
export interface Newsfeeds {
    /**
     * 
     * @type {Array<Newsfeed>}
     * @memberof Newsfeeds
     */
    'feeds': Array<Newsfeed>;
}
/**
 * お知らせ情報
 * @export
 * @interface Notice
 */
export interface Notice {
    /**
     * コードID
     * @type {number}
     * @memberof Notice
     */
    'id': number;
    /**
     * 
     * @type {NoticeCategory}
     * @memberof Notice
     */
    'category': NoticeCategory;
    /**
     * お知らせオーダー
     * @type {number}
     * @memberof Notice
     */
    'order': number;
    /**
     * お知らせコード
     * @type {string}
     * @memberof Notice
     */
    'code': string;
    /**
     * お知らせタイトル
     * @type {string}
     * @memberof Notice
     */
    'title': string;
    /**
     * 内容
     * @type {string}
     * @memberof Notice
     */
    'content': string;
    /**
     * URL
     * @type {string}
     * @memberof Notice
     */
    'url'?: string;
    /**
     * 通知登録日時
     * @type {string}
     * @memberof Notice
     */
    'created_at': string;
    /**
     * 既読状態のフラグ
     * @type {boolean}
     * @memberof Notice
     */
    'is_read': boolean;
    /**
     * 既読時の獲得ジェム数
     * @type {number}
     * @memberof Notice
     */
    'reward': number;
}
/**
 * お知らせ情報
 * @export
 * @interface NoticeCategory
 */
export interface NoticeCategory {
    /**
     * コードID
     * @type {number}
     * @memberof NoticeCategory
     */
    'id': number;
    /**
     * お知らせオーダー
     * @type {number}
     * @memberof NoticeCategory
     */
    'order': number;
    /**
     * お知らせコード
     * @type {string}
     * @memberof NoticeCategory
     */
    'type': string;
    /**
     * お知らせタイトル
     * @type {string}
     * @memberof NoticeCategory
     */
    'title': string;
}
/**
 * 既読になった通知情報
 * @export
 * @interface NoticeReadRequest
 */
export interface NoticeReadRequest {
    /**
     * ID
     * @type {number}
     * @memberof NoticeReadRequest
     */
    'id': number;
    /**
     * オーダー番号
     * @type {number}
     * @memberof NoticeReadRequest
     */
    'order': number;
    /**
     * 通知タイプ
     * @type {string}
     * @memberof NoticeReadRequest
     */
    'type': string;
}
/**
 * お知らせリスト情報
 * @export
 * @interface Notices
 */
export interface Notices {
    /**
     * 
     * @type {Array<Notice>}
     * @memberof Notices
     */
    'notices': Array<Notice>;
    /**
     * ユーザーの通知全件数
     * @type {number}
     * @memberof Notices
     */
    'total_count': number;
}
/**
 * 
 * @export
 * @interface NoticesRead
 */
export interface NoticesRead {
    /**
     * 
     * @type {Array<GemReward>}
     * @memberof NoticesRead
     */
    'gem_rewards': Array<GemReward>;
}
/**
 * 既読になった通知の情報リスト
 * @export
 * @interface NoticesReadRequest
 */
export interface NoticesReadRequest {
    /**
     * 
     * @type {Array<NoticeReadRequest>}
     * @memberof NoticesReadRequest
     */
    'notices_read': Array<NoticeReadRequest>;
}
/**
 * 原石取引情報
 * @export
 * @interface OreTransaction
 */
export interface OreTransaction {
    /**
     * 取引成否フラグ
     * @type {boolean}
     * @memberof OreTransaction
     */
    'successful_transaction': boolean;
}
/**
 * 原石取引情報
 * @export
 * @interface OreTransactionRequest
 */
export interface OreTransactionRequest {
    /**
     * 原石取得方法ID
     * @type {number}
     * @memberof OreTransactionRequest
     */
    'operation_id': number;
    /**
     * 原石取得方法種別
     * @type {number}
     * @memberof OreTransactionRequest
     */
    'operation_type': number;
    /**
     * 取引原石数
     * @type {number}
     * @memberof OreTransactionRequest
     */
    'ore_count': number;
    /**
     * 獲得か使用かを切り分けるフラグ
     * @type {boolean}
     * @memberof OreTransactionRequest
     */
    'is_acquisition': boolean;
}
/**
 * page information
 * @export
 * @interface PageInfo
 */
export interface PageInfo {
    /**
     * ページ番号
     * @type {number}
     * @memberof PageInfo
     */
    'page': number;
    /**
     * 1ページあたりの要素数 (MAX100)
     * @type {number}
     * @memberof PageInfo
     */
    'per_page': number;
    /**
     * すべての要素数
     * @type {number}
     * @memberof PageInfo
     */
    'total_rows': number;
}
/**
 * 
 * @export
 * @interface Parties
 */
export interface Parties {
    /**
     * 
     * @type {Array<Party>}
     * @memberof Parties
     */
    'parties': Array<Party>;
}
/**
 * 
 * @export
 * @interface PartiesResp
 */
export interface PartiesResp {
    /**
     * 
     * @type {Array<PartyResp>}
     * @memberof PartiesResp
     */
    'parties': Array<PartyResp>;
}
/**
 * 
 * @export
 * @interface Party
 */
export interface Party {
    /**
     * パーティ番号
     * @type {number}
     * @memberof Party
     */
    'number': number;
    /**
     * 
     * @type {Array<PartyMember>}
     * @memberof Party
     */
    'members': Array<PartyMember>;
}
/**
 * スキミー情報
 * @export
 * @interface PartyMember
 */
export interface PartyMember {
    /**
     * 順番
     * @type {number}
     * @memberof PartyMember
     */
    'member_order'?: number;
    /**
     * スキミーID
     * @type {number}
     * @memberof PartyMember
     */
    'skimmy_id': number;
}
/**
 * スキミー情報
 * @export
 * @interface PartyMemberResp
 */
export interface PartyMemberResp {
    /**
     * 順番
     * @type {number}
     * @memberof PartyMemberResp
     */
    'member_order'?: number;
    /**
     * スキミーID
     * @type {number}
     * @memberof PartyMemberResp
     */
    'skimmy_id': number;
    /**
     * 
     * @type {SkimmyResp}
     * @memberof PartyMemberResp
     */
    'skimmy'?: SkimmyResp;
}
/**
 * 
 * @export
 * @interface PartyResp
 */
export interface PartyResp {
    /**
     * パーティ番号
     * @type {number}
     * @memberof PartyResp
     */
    'number': number;
    /**
     * 
     * @type {Array<PartyMemberResp>}
     * @memberof PartyResp
     */
    'members': Array<PartyMemberResp>;
    /**
     * デフォルトパーティフラグ
     * @type {boolean}
     * @memberof PartyResp
     */
    'is_default_party': boolean;
}
/**
 * ペルソナ情報
 * @export
 * @interface Persona
 */
export interface Persona {
    /**
     * ペルソナID
     * @type {number}
     * @memberof Persona
     */
    'id': number;
    /**
     * ユーザーID
     * @type {string}
     * @memberof Persona
     */
    'uid': string;
    /**
     * ペルソナ画像のURL
     * @type {string}
     * @memberof Persona
     */
    'image_url': string;
    /**
     * ペルソナテキスト
     * @type {string}
     * @memberof Persona
     */
    'text': string;
}
/**
 * 遊び場情報
 * @export
 * @interface PlayAreaCheck
 */
export interface PlayAreaCheck {
    /**
     * 
     * @type {BattleTickets}
     * @memberof PlayAreaCheck
     */
    'battle_tickets': BattleTickets;
    /**
     * ボーナス未獲得終了探索の有無
     * @type {boolean}
     * @memberof PlayAreaCheck
     */
    'has_finished_skimmy_search': boolean;
}
/**
 * ポートフォリオ情報
 * @export
 * @interface Portfolio
 */
export interface Portfolio {
    /**
     * ポートフォリオID
     * @type {number}
     * @memberof Portfolio
     */
    'id': number;
    /**
     * ユーザーID
     * @type {string}
     * @memberof Portfolio
     */
    'uid': string;
    /**
     * テンプレート種類
     * @type {number}
     * @memberof Portfolio
     */
    'template': number;
    /**
     * タイトル
     * @type {string}
     * @memberof Portfolio
     */
    'title': string;
    /**
     * 説明
     * @type {string}
     * @memberof Portfolio
     */
    'description': string;
    /**
     * ポートフォリオの一番最初のアートワークのURL
     * @type {string}
     * @memberof Portfolio
     */
    'thumbnail_img_url': string;
    /**
     * 承認状態
     * @type {boolean}
     * @memberof Portfolio
     */
    'approved': boolean;
    /**
     * 公開範囲
     * @type {number}
     * @memberof Portfolio
     */
    'share_scope': number;
    /**
     * 公開所属チームID
     * @type {number}
     * @memberof Portfolio
     */
    'share_team': number;
}
/**
 * 
 * @export
 * @interface Portfolios
 */
export interface Portfolios {
    /**
     * 
     * @type {Array<Portfolio>}
     * @memberof Portfolios
     */
    'portfolios': Array<Portfolio>;
}
/**
 * 優先度
 * @export
 * @interface Priority
 */
export interface Priority {
    /**
     * 優先度ID
     * @type {number}
     * @memberof Priority
     */
    'id': number;
    /**
     * 優先度名
     * @type {string}
     * @memberof Priority
     */
    'name': string;
}
/**
 * プロジェクト
 * @export
 * @interface Project
 */
export interface Project {
    /**
     * プロジェクトID
     * @type {number}
     * @memberof Project
     */
    'id': number;
    /**
     * プロジェクト番号
     * @type {number}
     * @memberof Project
     */
    'order'?: number;
    /**
     * プロジェクト識別子（パートナー用）
     * @type {string}
     * @memberof Project
     */
    'identifier'?: string;
    /**
     * プロジェクト名
     * @type {string}
     * @memberof Project
     */
    'name': string;
    /**
     * プロジェクトステータス
     * @type {number}
     * @memberof Project
     */
    'status'?: number;
    /**
     * 絵文字
     * @type {string}
     * @memberof Project
     */
    'emoji'?: string;
    /**
     * 
     * @type {Team}
     * @memberof Project
     */
    'team'?: Team;
}
/**
 * プロジェクト完了状態
 * @export
 * @interface ProjectComplete
 */
export interface ProjectComplete {
    /**
     * 
     * @type {boolean}
     * @memberof ProjectComplete
     */
    'complete': boolean;
}
/**
 * プロジェクトお気に入り設定
 * @export
 * @interface ProjectFav
 */
export interface ProjectFav {
    /**
     * 
     * @type {boolean}
     * @memberof ProjectFav
     */
    'fav': boolean;
}
/**
 * プロジェクト設定情報
 * @export
 * @interface ProjectSetting
 */
export interface ProjectSetting {
    /**
     * 設定情報ID
     * @type {number}
     * @memberof ProjectSetting
     */
    'id': number;
    /**
     * チーム名
     * @type {string}
     * @memberof ProjectSetting
     */
    'teamname'?: string;
    /**
     * プロジェクトID
     * @type {number}
     * @memberof ProjectSetting
     */
    'project_order'?: number;
    /**
     * 設定キー
     * @type {string}
     * @memberof ProjectSetting
     */
    'key': string;
    /**
     * 説明値
     * @type {string}
     * @memberof ProjectSetting
     */
    'value': string;
}
/**
 * Googleカレンダ情報
 * @export
 * @interface ProjectSettingGcal
 */
export interface ProjectSettingGcal {
    /**
     * カレンダーID
     * @type {string}
     * @memberof ProjectSettingGcal
     */
    'id': string;
    /**
     * カレンダー名
     * @type {string}
     * @memberof ProjectSettingGcal
     */
    'name': string;
}
/**
 * Googleカレンダリスト情報
 * @export
 * @interface ProjectSettingGcallist
 */
export interface ProjectSettingGcallist {
    /**
     * 
     * @type {Array<ProjectSettingGcal>}
     * @memberof ProjectSettingGcallist
     */
    'calendars': Array<ProjectSettingGcal>;
}
/**
 * プロジェクト設定情報
 * @export
 * @interface ProjectSettings
 */
export interface ProjectSettings {
    /**
     * 
     * @type {Array<ProjectSetting>}
     * @memberof ProjectSettings
     */
    'settings': Array<ProjectSetting>;
}
/**
 * プロジェクト設定情報
 * @export
 * @interface ProjectSettingsReq
 */
export interface ProjectSettingsReq {
    /**
     * 
     * @type {Array<ProjectSettingsReqSingle>}
     * @memberof ProjectSettingsReq
     */
    'settings'?: Array<ProjectSettingsReqSingle>;
}
/**
 * プロジェクト情報
 * @export
 * @interface ProjectSettingsReqSingle
 */
export interface ProjectSettingsReqSingle {
    /**
     * 
     * @type {string}
     * @memberof ProjectSettingsReqSingle
     */
    'key': string;
    /**
     * 
     * @type {string}
     * @memberof ProjectSettingsReqSingle
     */
    'value': string;
}
/**
 * プロジェクト作業時間情報
 * @export
 * @interface ProjectTimeentrie
 */
export interface ProjectTimeentrie {
    /**
     * 対象日付
     * @type {string}
     * @memberof ProjectTimeentrie
     */
    'target_date': string;
    /**
     * 
     * @type {Array<UserHour>}
     * @memberof ProjectTimeentrie
     */
    'user_hours': Array<UserHour>;
}
/**
 * プロジェクト作業時間情報
 * @export
 * @interface ProjectTimeentries
 */
export interface ProjectTimeentries {
    /**
     * 
     * @type {Array<ProjectTimeentrie>}
     * @memberof ProjectTimeentries
     */
    'entries': Array<ProjectTimeentrie>;
}
/**
 * 
 * @export
 * @interface Projects
 */
export interface Projects {
    /**
     * 
     * @type {Array<Project>}
     * @memberof Projects
     */
    'projects': Array<Project>;
    /**
     * 
     * @type {PageInfo}
     * @memberof Projects
     */
    'page_info': PageInfo;
}
/**
 * 公開プロフィール情報
 * @export
 * @interface PublicProfile
 */
export interface PublicProfile {
    /**
     * profileID
     * @type {number}
     * @memberof PublicProfile
     */
    'id': number;
    /**
     * userID
     * @type {number}
     * @memberof PublicProfile
     */
    'user_id': number;
    /**
     * teamID
     * @type {number}
     * @memberof PublicProfile
     */
    'team_id': number;
    /**
     * プロフィール種別
     * @type {number}
     * @memberof PublicProfile
     */
    'profile_type': number;
    /**
     * アバターURL
     * @type {string}
     * @memberof PublicProfile
     */
    'avatar_url': string;
    /**
     * 背景色1
     * @type {string}
     * @memberof PublicProfile
     */
    'color_1': string;
    /**
     * 背景色2
     * @type {string}
     * @memberof PublicProfile
     */
    'color_2': string;
    /**
     * プロフィール名
     * @type {string}
     * @memberof PublicProfile
     */
    'profile_name': string;
    /**
     * バートル種別
     * @type {string}
     * @memberof PublicProfile
     */
    'bartle': string;
    /**
     * ボタン種別
     * @type {string}
     * @memberof PublicProfile
     */
    'button_variant': string;
    /**
     * 
     * @type {Array<Hashtag>}
     * @memberof PublicProfile
     */
    'hashtags': Array<Hashtag>;
    /**
     * 
     * @type {Array<LinkButton>}
     * @memberof PublicProfile
     */
    'link_buttons': Array<LinkButton>;
    /**
     * 誕生日
     * @type {string}
     * @memberof PublicProfile
     */
    'birthday': string;
    /**
     * 性別
     * @type {string}
     * @memberof PublicProfile
     */
    'sex': string;
    /**
     * アバター表示フラグ
     * @type {boolean}
     * @memberof PublicProfile
     */
    'avatar_url_is_active': boolean;
    /**
     * プロフィール名表示フラグ
     * @type {boolean}
     * @memberof PublicProfile
     */
    'display_name_is_active': boolean;
    /**
     * プロフィール表示フラグ
     * @type {boolean}
     * @memberof PublicProfile
     */
    'is_active': boolean;
}
/**
 * 公開プロフィール情報
 * @export
 * @interface PublicProfileItem
 */
export interface PublicProfileItem {
    /**
     * ユーザー名
     * @type {string}
     * @memberof PublicProfileItem
     */
    'username': string;
    /**
     * チーム名
     * @type {string}
     * @memberof PublicProfileItem
     */
    'teamname': string;
    /**
     * プロフィール作成日時
     * @type {string}
     * @memberof PublicProfileItem
     */
    'created_at': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof PublicProfileItem
     */
    'hashtags': Array<string>;
    /**
     * プロフィール種別
     * @type {number}
     * @memberof PublicProfileItem
     */
    'profile_type': number;
    /**
     * アバターURL
     * @type {string}
     * @memberof PublicProfileItem
     */
    'avatar_url': string;
    /**
     * プロフィール名
     * @type {string}
     * @memberof PublicProfileItem
     */
    'profile_name': string;
    /**
     * バートル種別
     * @type {string}
     * @memberof PublicProfileItem
     */
    'bartle': string;
}
/**
 * 公開プロフィール情報
 * @export
 * @interface PublicProfileRequest
 */
export interface PublicProfileRequest {
    /**
     * ID
     * @type {number}
     * @memberof PublicProfileRequest
     */
    'id': number;
    /**
     * ユーザー名
     * @type {string}
     * @memberof PublicProfileRequest
     */
    'username': string;
    /**
     * チーム名
     * @type {string}
     * @memberof PublicProfileRequest
     */
    'teamname': string;
    /**
     * プロフィール種別
     * @type {number}
     * @memberof PublicProfileRequest
     */
    'profile_type': number;
    /**
     * アバターURL
     * @type {string}
     * @memberof PublicProfileRequest
     */
    'avatar_url': string;
    /**
     * 背景色1
     * @type {string}
     * @memberof PublicProfileRequest
     */
    'color_1': string;
    /**
     * 背景色2
     * @type {string}
     * @memberof PublicProfileRequest
     */
    'color_2': string;
    /**
     * プロフィール名
     * @type {string}
     * @memberof PublicProfileRequest
     */
    'display_name': string;
    /**
     * バートル種別
     * @type {string}
     * @memberof PublicProfileRequest
     */
    'bartle': string;
    /**
     * ボタン種別
     * @type {string}
     * @memberof PublicProfileRequest
     */
    'button_variant': string;
    /**
     * 
     * @type {Array<HashtagRequest>}
     * @memberof PublicProfileRequest
     */
    'hashtags': Array<HashtagRequest>;
    /**
     * 
     * @type {Array<LinkButtonRequest>}
     * @memberof PublicProfileRequest
     */
    'link_buttons': Array<LinkButtonRequest>;
    /**
     * 誕生日
     * @type {string}
     * @memberof PublicProfileRequest
     */
    'birthday': string;
    /**
     * 性別
     * @type {string}
     * @memberof PublicProfileRequest
     */
    'sex': string;
    /**
     * アバター表示フラグ
     * @type {boolean}
     * @memberof PublicProfileRequest
     */
    'avatar_url_is_active': boolean;
    /**
     * プロフィール名表示フラグ
     * @type {boolean}
     * @memberof PublicProfileRequest
     */
    'display_name_is_active': boolean;
    /**
     * プロフィール表示フラグ
     * @type {boolean}
     * @memberof PublicProfileRequest
     */
    'is_active': boolean;
}
/**
 * 公開プロフィール検索情報
 * @export
 * @interface PublicProfileSearchRequest
 */
export interface PublicProfileSearchRequest {
    /**
     * オフセット
     * @type {number}
     * @memberof PublicProfileSearchRequest
     */
    'offset': number;
    /**
     * リミット
     * @type {number}
     * @memberof PublicProfileSearchRequest
     */
    'limit': number;
    /**
     * 
     * @type {Array<string>}
     * @memberof PublicProfileSearchRequest
     */
    'hashtags': Array<string>;
}
/**
 * 公開プロフィール検索情報
 * @export
 * @interface PublicProfileSearches
 */
export interface PublicProfileSearches {
    /**
     * 
     * @type {Array<PublicProfileItem>}
     * @memberof PublicProfileSearches
     */
    'public_profiles': Array<PublicProfileItem>;
    /**
     * 総件数
     * @type {number}
     * @memberof PublicProfileSearches
     */
    'total_count': number;
}
/**
 * アプリ内課金情報
 * @export
 * @interface PurchaseReq
 */
export interface PurchaseReq {
    /**
     * プラットフォーム
     * @type {string}
     * @memberof PurchaseReq
     */
    'platform': PurchaseReqPlatformEnum;
    /**
     * レシート
     * @type {string}
     * @memberof PurchaseReq
     */
    'receiptData': string;
    /**
     * アイテムマスターコード
     * @type {string}
     * @memberof PurchaseReq
     */
    'itemMasterCode': string;
}

export const PurchaseReqPlatformEnum = {
    Ios: 'ios',
    Android: 'android'
} as const;

export type PurchaseReqPlatformEnum = typeof PurchaseReqPlatformEnum[keyof typeof PurchaseReqPlatformEnum];

/**
 * アプリ内課金検証結果
 * @export
 * @interface PurchaseResp
 */
export interface PurchaseResp {
    /**
     * 検証結果
     * @type {boolean}
     * @memberof PurchaseResp
     */
    'valid': boolean;
    /**
     * ステータス
     * @type {string}
     * @memberof PurchaseResp
     */
    'status': string;
}
/**
 * 資格情報
 * @export
 * @interface QualifReq
 */
export interface QualifReq {
    /**
     * 資格Id
     * @type {number}
     * @memberof QualifReq
     */
    'id'?: number;
    /**
     * 取得日
     * @type {string}
     * @memberof QualifReq
     */
    'acquisition_date': string | null;
    /**
     * 資格名
     * @type {string}
     * @memberof QualifReq
     */
    'title': string;
}
/**
 * クエスト情報
 * @export
 * @interface Quest
 */
export interface Quest {
    /**
     * クエストID
     * @type {number}
     * @memberof Quest
     */
    'id': number;
    /**
     * タイトル
     * @type {string}
     * @memberof Quest
     */
    'title': string;
    /**
     * 
     * @type {Array<QuestQuestion>}
     * @memberof Quest
     */
    'questions': Array<QuestQuestion>;
    /**
     * 正解数
     * @type {number}
     * @memberof Quest
     */
    'score': number;
    /**
     * 問題数
     * @type {number}
     * @memberof Quest
     */
    'question_count': number;
    /**
     * 正解率
     * @type {number}
     * @memberof Quest
     */
    'percentage_score': number;
    /**
     * 挑戦回数
     * @type {number}
     * @memberof Quest
     */
    'challenge_count': number;
    /**
     * クエストバージョン
     * @type {number}
     * @memberof Quest
     */
    'version': number;
    /**
     * クエストバージョンコメント
     * @type {string}
     * @memberof Quest
     */
    'version_comment': string;
    /**
     * 公開状態
     * @type {boolean}
     * @memberof Quest
     */
    'is_public': boolean;
    /**
     * クエスト種類
     * @type {number}
     * @memberof Quest
     */
    'type': number;
    /**
     * クエスト作成者
     * @type {string}
     * @memberof Quest
     */
    'creator': string;
    /**
     * いいね数
     * @type {number}
     * @memberof Quest
     */
    'like_num': number;
    /**
     * 一覧へ戻るかのフラグ
     * @type {boolean}
     * @memberof Quest
     */
    'is_reroute': boolean;
    /**
     * リルート時のurl
     * @type {string}
     * @memberof Quest
     */
    'reroute_url': string;
    /**
     * クエストアイキャッチのURL
     * @type {string}
     * @memberof Quest
     */
    'card_image_url': string;
    /**
     * アイキャッチがデフォルトでないかのフラグ
     * @type {boolean}
     * @memberof Quest
     */
    'eyecatch_is_unique': boolean;
}
/**
 * クエストリスト情報
 * @export
 * @interface QuestChallengeRequest
 */
export interface QuestChallengeRequest {
    /**
     * クエストタイプ
     * @type {number}
     * @memberof QuestChallengeRequest
     */
    'type': number;
    /**
     * チーム名
     * @type {string}
     * @memberof QuestChallengeRequest
     */
    'teamname': string;
    /**
     * ユーザー名
     * @type {string}
     * @memberof QuestChallengeRequest
     */
    'username': string;
    /**
     * 公開URL
     * @type {string}
     * @memberof QuestChallengeRequest
     */
    'public_url': string;
}
/**
 * 修正クエスト情報
 * @export
 * @interface QuestEditRequest
 */
export interface QuestEditRequest {
    /**
     * クエストタイプ
     * @type {number}
     * @memberof QuestEditRequest
     */
    'type': number;
    /**
     * チーム名
     * @type {string}
     * @memberof QuestEditRequest
     */
    'teamname': string;
}
/**
 * クエストヒストリー情報
 * @export
 * @interface QuestHistoryListRequest
 */
export interface QuestHistoryListRequest {
    /**
     * クエストタイプ
     * @type {number}
     * @memberof QuestHistoryListRequest
     */
    'type': number;
    /**
     * チーム名
     * @type {string}
     * @memberof QuestHistoryListRequest
     */
    'teamname': string;
}
/**
 * クエストヒストリー情報
 * @export
 * @interface QuestHistoryRequest
 */
export interface QuestHistoryRequest {
    /**
     * クエストタイプ
     * @type {number}
     * @memberof QuestHistoryRequest
     */
    'type': number;
    /**
     * チーム名
     * @type {string}
     * @memberof QuestHistoryRequest
     */
    'teamname': string;
}
/**
 * クエストいいね情報
 * @export
 * @interface QuestLike
 */
export interface QuestLike {
    /**
     * いいね数
     * @type {number}
     * @memberof QuestLike
     */
    'like_num': number;
}
/**
 * クエストいいね情報
 * @export
 * @interface QuestLikeRequest
 */
export interface QuestLikeRequest {
    /**
     * クエストID
     * @type {number}
     * @memberof QuestLikeRequest
     */
    'quest_id': number;
    /**
     * いいね
     * @type {boolean}
     * @memberof QuestLikeRequest
     */
    'is_liked': boolean;
}
/**
 * クエストリスト情報
 * @export
 * @interface QuestList
 */
export interface QuestList {
    /**
     * 
     * @type {Array<QuestListItem>}
     * @memberof QuestList
     */
    'quests': Array<QuestListItem>;
    /**
     * ヒット件数
     * @type {number}
     * @memberof QuestList
     */
    'total_count': number;
    /**
     * オフセット値
     * @type {number}
     * @memberof QuestList
     */
    'offset': number;
    /**
     * 上限値
     * @type {number}
     * @memberof QuestList
     */
    'limit': number;
}
/**
 * クエスト情報
 * @export
 * @interface QuestListItem
 */
export interface QuestListItem {
    /**
     * クエストID
     * @type {number}
     * @memberof QuestListItem
     */
    'id': number;
    /**
     * 修正後のクエストID
     * @type {number}
     * @memberof QuestListItem
     */
    'edit_quest_id': number;
    /**
     * タイトル
     * @type {string}
     * @memberof QuestListItem
     */
    'title': string;
    /**
     * クエスト作成者
     * @type {string}
     * @memberof QuestListItem
     */
    'creator': string;
    /**
     * バージョン1クエスト作成日
     * @type {string}
     * @memberof QuestListItem
     */
    'created_at': string;
    /**
     * 更新日
     * @type {string}
     * @memberof QuestListItem
     */
    'updated_at': string;
    /**
     * 挑戦日
     * @type {string}
     * @memberof QuestListItem
     */
    'challenged_at': string | null;
    /**
     * 正解数
     * @type {number}
     * @memberof QuestListItem
     */
    'score': number;
    /**
     * 問題数
     * @type {number}
     * @memberof QuestListItem
     */
    'question_count': number;
    /**
     * 正解率
     * @type {number}
     * @memberof QuestListItem
     */
    'percentage_score': number;
    /**
     * 挑戦回数
     * @type {number}
     * @memberof QuestListItem
     */
    'challenge_count': number;
    /**
     * 総挑戦回数
     * @type {number}
     * @memberof QuestListItem
     */
    'challenge_total_count': number;
    /**
     * クエスト挑戦履歴ID
     * @type {number}
     * @memberof QuestListItem
     */
    'quest_history_id': number;
    /**
     * クエスト挑戦履歴ご褒美ジェム数
     * @type {number}
     * @memberof QuestListItem
     */
    'reward': number;
    /**
     * クエスト挑戦履歴ご褒美取得フラグ
     * @type {boolean}
     * @memberof QuestListItem
     */
    'has_reward': boolean;
    /**
     * 全ユーザーによって挑戦された総回数
     * @type {number}
     * @memberof QuestListItem
     */
    'total_challenged_count': number;
    /**
     * クエストバージョン
     * @type {number}
     * @memberof QuestListItem
     */
    'version': number;
    /**
     * クエストバージョンコメント
     * @type {string}
     * @memberof QuestListItem
     */
    'version_comment': string;
    /**
     * 公開状態
     * @type {boolean}
     * @memberof QuestListItem
     */
    'is_public': boolean;
    /**
     * クエスト種類
     * @type {number}
     * @memberof QuestListItem
     */
    'type': number;
    /**
     * クエストカード一覧の画像URL
     * @type {string}
     * @memberof QuestListItem
     */
    'card_image_url': string;
    /**
     * クエストカード一覧に載せるクエストの情報
     * @type {string}
     * @memberof QuestListItem
     */
    'question_1_text': string;
    /**
     * 公開URL
     * @type {string}
     * @memberof QuestListItem
     */
    'public_url': string;
    /**
     * いいね数
     * @type {number}
     * @memberof QuestListItem
     */
    'like_num': number;
    /**
     * 
     * @type {QuestListItemAuthor}
     * @memberof QuestListItem
     */
    'author': QuestListItemAuthor;
    /**
     * 
     * @type {boolean}
     * @memberof QuestListItem
     */
    'made_public'?: boolean;
}
/**
 * 
 * @export
 * @interface QuestListItemAuthor
 */
export interface QuestListItemAuthor {
    /**
     * 
     * @type {boolean}
     * @memberof QuestListItemAuthor
     */
    'is_team': boolean;
    /**
     * 
     * @type {string}
     * @memberof QuestListItemAuthor
     */
    'slug': string;
    /**
     * 
     * @type {string}
     * @memberof QuestListItemAuthor
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof QuestListItemAuthor
     */
    'avatarURL': string;
    /**
     * 
     * @type {string}
     * @memberof QuestListItemAuthor
     */
    'bannerURL': string;
    /**
     * 
     * @type {boolean}
     * @memberof QuestListItemAuthor
     */
    'is_public': boolean;
    /**
     * 
     * @type {string}
     * @memberof QuestListItemAuthor
     */
    'quest_creator': string;
}
/**
 * クエストリスト情報
 * @export
 * @interface QuestListRequest
 */
export interface QuestListRequest {
    /**
     * クエストタイプ
     * @type {number}
     * @memberof QuestListRequest
     */
    'type': number;
    /**
     * チーム名
     * @type {string}
     * @memberof QuestListRequest
     */
    'teamname': string;
    /**
     * ユーザー名
     * @type {string}
     * @memberof QuestListRequest
     */
    'username': string;
}
/**
 * 公開コンテンツ情報
 * @export
 * @interface QuestMeta
 */
export interface QuestMeta {
    /**
     * クエスト種類
     * @type {number}
     * @memberof QuestMeta
     */
    'quest_type': number;
    /**
     * 
     * @type {string}
     * @memberof QuestMeta
     */
    'created_on': string;
    /**
     * 
     * @type {string}
     * @memberof QuestMeta
     */
    'updated_on': string;
    /**
     * 
     * @type {Array<QuestQuestionMeta>}
     * @memberof QuestMeta
     */
    'quest_questions_meta': Array<QuestQuestionMeta>;
    /**
     * 
     * @type {QuestListItemAuthor}
     * @memberof QuestMeta
     */
    'author': QuestListItemAuthor;
}
/**
 * クエスト公開状態情報
 * @export
 * @interface QuestPublicStatusRequest
 */
export interface QuestPublicStatusRequest {
    /**
     * クエスト公開フラグ
     * @type {boolean}
     * @memberof QuestPublicStatusRequest
     */
    'is_public': boolean;
}
/**
 * クエスト問題情報
 * @export
 * @interface QuestQuestion
 */
export interface QuestQuestion {
    /**
     * クエスト問題ID
     * @type {number}
     * @memberof QuestQuestion
     */
    'id': number;
    /**
     * 問題
     * @type {string}
     * @memberof QuestQuestion
     */
    'question': string;
    /**
     * 
     * @type {Array<QuestQuestionChoice>}
     * @memberof QuestQuestion
     */
    'choices': Array<QuestQuestionChoice>;
    /**
     * 順番
     * @type {number}
     * @memberof QuestQuestion
     */
    'order': number;
    /**
     * 答え
     * @type {number}
     * @memberof QuestQuestion
     */
    'answer'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof QuestQuestion
     */
    'is_correct': boolean;
    /**
     * 選んだ答え
     * @type {number}
     * @memberof QuestQuestion
     */
    'selected_answer'?: number;
    /**
     * 回答補足
     * @type {string}
     * @memberof QuestQuestion
     */
    'answer_explanation': string;
    /**
     * クエスト問題画像
     * @type {string}
     * @memberof QuestQuestion
     */
    'question_image_url': string;
    /**
     * クエスト回答画像
     * @type {string}
     * @memberof QuestQuestion
     */
    'answer_image_url': string;
}
/**
 * クエスト問題選択肢情報
 * @export
 * @interface QuestQuestionChoice
 */
export interface QuestQuestionChoice {
    /**
     * クエスト問題選択肢ID
     * @type {number}
     * @memberof QuestQuestionChoice
     */
    'id': number;
    /**
     * 問題
     * @type {string}
     * @memberof QuestQuestionChoice
     */
    'choice': string;
    /**
     * 順番
     * @type {number}
     * @memberof QuestQuestionChoice
     */
    'order': number;
    /**
     * クエスト問題選択肢画像
     * @type {string}
     * @memberof QuestQuestionChoice
     */
    'choice_image_url': string;
}
/**
 * クエスト問題選択肢情報
 * @export
 * @interface QuestQuestionChoicesRequest
 */
export interface QuestQuestionChoicesRequest {
    /**
     * クエスト問題選択肢ID
     * @type {number}
     * @memberof QuestQuestionChoicesRequest
     */
    'id'?: number;
    /**
     * クエスト問題の選択肢
     * @type {string}
     * @memberof QuestQuestionChoicesRequest
     */
    'choice': string;
    /**
     * 選択肢識別
     * @type {number}
     * @memberof QuestQuestionChoicesRequest
     */
    'order': number;
    /**
     * クエスト問題選択肢画像
     * @type {string}
     * @memberof QuestQuestionChoicesRequest
     */
    'choice_image_url': string;
}
/**
 * クエスト問題メタ情報
 * @export
 * @interface QuestQuestionMeta
 */
export interface QuestQuestionMeta {
    /**
     * 問題文
     * @type {string}
     * @memberof QuestQuestionMeta
     */
    'question_text': string;
    /**
     * url
     * @type {string}
     * @memberof QuestQuestionMeta
     */
    'url': string;
}
/**
 * クエスト問題情報
 * @export
 * @interface QuestQuestionsRequest
 */
export interface QuestQuestionsRequest {
    /**
     * クエスト問題ID
     * @type {number}
     * @memberof QuestQuestionsRequest
     */
    'id'?: number;
    /**
     * クエスト問題
     * @type {string}
     * @memberof QuestQuestionsRequest
     */
    'question': string;
    /**
     * 
     * @type {Array<QuestQuestionChoicesRequest>}
     * @memberof QuestQuestionsRequest
     */
    'choices': Array<QuestQuestionChoicesRequest>;
    /**
     * 問題の答え
     * @type {number}
     * @memberof QuestQuestionsRequest
     */
    'answer': number;
    /**
     * 問題識別子
     * @type {number}
     * @memberof QuestQuestionsRequest
     */
    'order': number;
    /**
     * 選んだ答え
     * @type {number}
     * @memberof QuestQuestionsRequest
     */
    'selected_answer'?: number;
    /**
     * クエスト問題回答補足
     * @type {string}
     * @memberof QuestQuestionsRequest
     */
    'answer_explanation': string;
    /**
     * クエスト問題画像
     * @type {string}
     * @memberof QuestQuestionsRequest
     */
    'question_image_url': string;
    /**
     * クエスト回答画像
     * @type {string}
     * @memberof QuestQuestionsRequest
     */
    'answer_image_url': string;
}
/**
 * クエスト情報
 * @export
 * @interface QuestRequest
 */
export interface QuestRequest {
    /**
     * クエストID
     * @type {number}
     * @memberof QuestRequest
     */
    'id'?: number;
    /**
     * クエストタイトル
     * @type {string}
     * @memberof QuestRequest
     */
    'title': string;
    /**
     * 
     * @type {Array<QuestQuestionsRequest>}
     * @memberof QuestRequest
     */
    'questions'?: Array<QuestQuestionsRequest>;
    /**
     * クエストバージョンコメント
     * @type {string}
     * @memberof QuestRequest
     */
    'version_comment': string;
    /**
     * 公開フラグ
     * @type {boolean}
     * @memberof QuestRequest
     */
    'is_public': boolean;
    /**
     * クエスト種類
     * @type {number}
     * @memberof QuestRequest
     */
    'type': number;
    /**
     * チーム名
     * @type {string}
     * @memberof QuestRequest
     */
    'teamname': string;
    /**
     * ユーザー名
     * @type {string}
     * @memberof QuestRequest
     */
    'username': string;
    /**
     * クエストアイキャッチのURL
     * @type {string}
     * @memberof QuestRequest
     */
    'card_image_url': string;
}
/**
 * リポジトリ情報
 * @export
 * @interface Repo
 */
export interface Repo {
    /**
     * リポジトリID
     * @type {number}
     * @memberof Repo
     */
    'id': number;
    /**
     * リポジトリ名
     * @type {string}
     * @memberof Repo
     */
    'name': string;
    /**
     * リポジトリ表示名
     * @type {string}
     * @memberof Repo
     */
    'display_name': string;
    /**
     * リポジトリ説明
     * @type {string}
     * @memberof Repo
     */
    'description': string;
}
/**
 * リポジトリ情報
 * @export
 * @interface RepoRequest
 */
export interface RepoRequest {
    /**
     * ユーザー名
     * @type {string}
     * @memberof RepoRequest
     */
    'username'?: string;
    /**
     * ユーザー表示名
     * @type {string}
     * @memberof RepoRequest
     */
    'display_name'?: string;
    /**
     * ユーザー概要
     * @type {string}
     * @memberof RepoRequest
     */
    'summary'?: string;
    /**
     * ユーザー詳細説明
     * @type {string}
     * @memberof RepoRequest
     */
    'description'?: string;
    /**
     * アバター画像URL
     * @type {string}
     * @memberof RepoRequest
     */
    'avatar_url'?: string;
    /**
     * ヘッダー画像URL
     * @type {string}
     * @memberof RepoRequest
     */
    'header_url'?: string;
}
/**
 * 
 * @export
 * @interface Repos
 */
export interface Repos {
    /**
     * 
     * @type {Array<Repo>}
     * @memberof Repos
     */
    'repos': Array<Repo>;
    /**
     * 
     * @type {PageInfo}
     * @memberof Repos
     */
    'page_info': PageInfo;
}
/**
 * 
 * @export
 * @interface Resources
 */
export interface Resources {
    /**
     * ジェム数
     * @type {number}
     * @memberof Resources
     */
    'gems': number;
    /**
     * 原石数
     * @type {number}
     * @memberof Resources
     */
    'ores': number;
    /**
     * 
     * @type {UserExp}
     * @memberof Resources
     */
    'user_exp': UserExp;
    /**
     * コイン数
     * @type {number}
     * @memberof Resources
     */
    'coins': number;
    /**
     * 
     * @type {BattleTickets}
     * @memberof Resources
     */
    'battle_tickets': BattleTickets;
    /**
     * ボーナス未獲得終了探索の有無
     * @type {boolean}
     * @memberof Resources
     */
    'has_finished_skimmy_search': boolean;
}
/**
 * 
 * @export
 * @interface ResourcesTradePolish
 */
export interface ResourcesTradePolish {
    /**
     * 研磨によって獲得したジェム数
     * @type {number}
     * @memberof ResourcesTradePolish
     */
    'gems': number;
}
/**
 * 
 * @export
 * @interface ResourcesTradePolishRequest
 */
export interface ResourcesTradePolishRequest {
    /**
     * 研磨する原石数
     * @type {number}
     * @memberof ResourcesTradePolishRequest
     */
    'ore_count': number;
    /**
     * 確定でジェムになる割合
     * @type {number}
     * @memberof ResourcesTradePolishRequest
     */
    'gem_rate': number;
}
/**
 * スキミー探索情報
 * @export
 * @interface SearchArea
 */
export interface SearchArea {
    /**
     * 探索エリアID
     * @type {number}
     * @memberof SearchArea
     */
    'id': number;
    /**
     * 探索エリア名称
     * @type {string}
     * @memberof SearchArea
     */
    'name': string;
    /**
     * 探索エリア画像
     * @type {string}
     * @memberof SearchArea
     */
    'image'?: string;
    /**
     * 必要ユーザーレベル
     * @type {number}
     * @memberof SearchArea
     */
    'necessaryUserLevel': number;
}
/**
 * スキミー探索情報
 * @export
 * @interface SearchAreas
 */
export interface SearchAreas {
    /**
     * 
     * @type {Array<SearchArea>}
     * @memberof SearchAreas
     */
    'areas': Array<SearchArea>;
}
/**
 * 音声ファイル
 * @export
 * @interface SendChatMessageWithVoiceRequest
 */
export interface SendChatMessageWithVoiceRequest {
    /**
     * 
     * @type {string}
     * @memberof SendChatMessageWithVoiceRequest
     */
    'voiceFile'?: string;
    /**
     * 
     * @type {number}
     * @memberof SendChatMessageWithVoiceRequest
     */
    'duration'?: number;
    /**
     * 
     * @type {number}
     * @memberof SendChatMessageWithVoiceRequest
     */
    'size'?: number;
    /**
     * 
     * @type {string}
     * @memberof SendChatMessageWithVoiceRequest
     */
    'type'?: string;
}
/**
 * ペルソナテキストのリクエスト
 * @export
 * @interface SetPersonaTextRequest
 */
export interface SetPersonaTextRequest {
    /**
     * 文字列
     * @type {string}
     * @memberof SetPersonaTextRequest
     */
    'text': string;
}
/**
 * ユーザーのインテグレーション設定情報
 * @export
 * @interface SettingsIntegrationDetail
 */
export interface SettingsIntegrationDetail {
    /**
     * 
     * @type {Array<SettingsIntegrationDetailSingle>}
     * @memberof SettingsIntegrationDetail
     */
    'settings'?: Array<SettingsIntegrationDetailSingle>;
}
/**
 * ユーザーのインテグレーション設定情報
 * @export
 * @interface SettingsIntegrationDetailReq
 */
export interface SettingsIntegrationDetailReq {
    /**
     * 
     * @type {Array<SettingsIntegrationDetailReqSingle>}
     * @memberof SettingsIntegrationDetailReq
     */
    'settings'?: Array<SettingsIntegrationDetailReqSingle>;
}
/**
 * is_valid
 * @export
 * @interface SettingsIntegrationDetailReqSingle
 */
export interface SettingsIntegrationDetailReqSingle {
    /**
     * 
     * @type {string}
     * @memberof SettingsIntegrationDetailReqSingle
     */
    'key': string;
    /**
     * 
     * @type {string}
     * @memberof SettingsIntegrationDetailReqSingle
     */
    'value': string;
}
/**
 * ユーザーのインテグレーション設定情報
 * @export
 * @interface SettingsIntegrationDetailSingle
 */
export interface SettingsIntegrationDetailSingle {
    /**
     * 
     * @type {string}
     * @memberof SettingsIntegrationDetailSingle
     */
    'key': string;
    /**
     * 
     * @type {string}
     * @memberof SettingsIntegrationDetailSingle
     */
    'value': string;
}
/**
 * アセスメントのチームメンバー共有設定情報
 * @export
 * @interface ShareAssessmentTeamMemberRequest
 */
export interface ShareAssessmentTeamMemberRequest {
    /**
     * チームID
     * @type {number}
     * @memberof ShareAssessmentTeamMemberRequest
     */
    'team_id': number;
    /**
     * チームメンバーID
     * @type {number}
     * @memberof ShareAssessmentTeamMemberRequest
     */
    'member_id': number;
}
/**
 * 個人コンテンツのチーム共有設定情報
 * @export
 * @interface ShareContentTeamRequest
 */
export interface ShareContentTeamRequest {
    /**
     * チームID
     * @type {number}
     * @memberof ShareContentTeamRequest
     */
    'team_id': number;
    /**
     * 
     * @type {Array<MemberShareSetting>}
     * @memberof ShareContentTeamRequest
     */
    'member_share_settings': Array<MemberShareSetting>;
    /**
     * 共有コンテンツタイプ
     * @type {number}
     * @memberof ShareContentTeamRequest
     */
    'share_content_type': number;
}
/**
 * チーム用ポートフォリオ共有情報
 * @export
 * @interface ShareContentTeamResp
 */
export interface ShareContentTeamResp {
    /**
     * 
     * @type {Array<ShareTeam>}
     * @memberof ShareContentTeamResp
     */
    'share_teams': Array<ShareTeam>;
}
/**
 * ユーザー情報
 * @export
 * @interface ShareMember
 */
export interface ShareMember {
    /**
     * ユーザーID
     * @type {number}
     * @memberof ShareMember
     */
    'id': number;
    /**
     * ユーザー名
     * @type {string}
     * @memberof ShareMember
     */
    'username': string;
    /**
     * ユーザー表示名
     * @type {string}
     * @memberof ShareMember
     */
    'display_name': string;
    /**
     * コンテンツを共有しているかのフラグ
     * @type {boolean}
     * @memberof ShareMember
     */
    'is_shared': boolean;
}
/**
 * ユーザー情報
 * @export
 * @interface ShareMemberSkills
 */
export interface ShareMemberSkills {
    /**
     * ユーザーID
     * @type {number}
     * @memberof ShareMemberSkills
     */
    'id': number;
    /**
     * ユーザー名
     * @type {string}
     * @memberof ShareMemberSkills
     */
    'username': string;
    /**
     * ユーザー表示名
     * @type {string}
     * @memberof ShareMemberSkills
     */
    'display_name': string;
    /**
     * ソフトウェアスキルを共有しているかのフラグ
     * @type {boolean}
     * @memberof ShareMemberSkills
     */
    'is_shared_software': boolean;
    /**
     * デザインスキルを共有しているかのフラグ
     * @type {boolean}
     * @memberof ShareMemberSkills
     */
    'is_shared_design': boolean;
}
/**
 * ポートフォリオのチームメンバー共有設定情報
 * @export
 * @interface SharePortfolioTeamMemberRequest
 */
export interface SharePortfolioTeamMemberRequest {
    /**
     * チームID
     * @type {number}
     * @memberof SharePortfolioTeamMemberRequest
     */
    'team_id': number;
    /**
     * チームメンバーID
     * @type {number}
     * @memberof SharePortfolioTeamMemberRequest
     */
    'member_id': number;
}
/**
 * スキルのチームメンバー共有設定情報
 * @export
 * @interface ShareSkillsTeamMemberRequest
 */
export interface ShareSkillsTeamMemberRequest {
    /**
     * チームID
     * @type {number}
     * @memberof ShareSkillsTeamMemberRequest
     */
    'team_id': number;
    /**
     * チームメンバーID
     * @type {number}
     * @memberof ShareSkillsTeamMemberRequest
     */
    'member_id': number;
}
/**
 * スキルのチーム共有設定情報
 * @export
 * @interface ShareSkillsTeamRequest
 */
export interface ShareSkillsTeamRequest {
    /**
     * チームID
     * @type {number}
     * @memberof ShareSkillsTeamRequest
     */
    'team_id': number;
    /**
     * 
     * @type {Array<MemberSkillsShareSetting>}
     * @memberof ShareSkillsTeamRequest
     */
    'member_skills_share_settings'?: Array<MemberSkillsShareSetting>;
}
/**
 * チーム用スキル共有情報
 * @export
 * @interface ShareSkillsTeamResp
 */
export interface ShareSkillsTeamResp {
    /**
     * 
     * @type {Array<ShareTeamSkills>}
     * @memberof ShareSkillsTeamResp
     */
    'share_teams': Array<ShareTeamSkills>;
}
/**
 * 個人コンテンツ共有対象チーム情報
 * @export
 * @interface ShareTeam
 */
export interface ShareTeam {
    /**
     * チームID
     * @type {number}
     * @memberof ShareTeam
     */
    'id': number;
    /**
     * チーム識別名
     * @type {string}
     * @memberof ShareTeam
     */
    'teamname': string;
    /**
     * チーム名
     * @type {string}
     * @memberof ShareTeam
     */
    'name': string;
    /**
     * チーム説明
     * @type {string}
     * @memberof ShareTeam
     */
    'description': string;
    /**
     * チーム開始日
     * @type {string}
     * @memberof ShareTeam
     */
    'created_at': string;
    /**
     * チーム終了日
     * @type {string}
     * @memberof ShareTeam
     */
    'deleted_at': string | null;
    /**
     * 削除されたチームかを切り分けるフラグ
     * @type {boolean}
     * @memberof ShareTeam
     */
    'is_deleted': boolean;
    /**
     * 
     * @type {Array<ShareMember>}
     * @memberof ShareTeam
     */
    'share_members': Array<ShareMember>;
}
/**
 * スキル共有対象チーム情報
 * @export
 * @interface ShareTeamSkills
 */
export interface ShareTeamSkills {
    /**
     * チームID
     * @type {number}
     * @memberof ShareTeamSkills
     */
    'id': number;
    /**
     * チーム識別名
     * @type {string}
     * @memberof ShareTeamSkills
     */
    'teamname': string;
    /**
     * チーム名
     * @type {string}
     * @memberof ShareTeamSkills
     */
    'name': string;
    /**
     * チーム説明
     * @type {string}
     * @memberof ShareTeamSkills
     */
    'description': string;
    /**
     * チーム開始日
     * @type {string}
     * @memberof ShareTeamSkills
     */
    'created_at': string;
    /**
     * チーム終了日
     * @type {string}
     * @memberof ShareTeamSkills
     */
    'deleted_at': string | null;
    /**
     * 削除されたチームかを切り分けるフラグ
     * @type {boolean}
     * @memberof ShareTeamSkills
     */
    'is_deleted': boolean;
    /**
     * 
     * @type {Array<ShareMemberSkills>}
     * @memberof ShareTeamSkills
     */
    'share_members': Array<ShareMemberSkills>;
}
/**
 * 共有アセスメント情報
 * @export
 * @interface SharedAssessment
 */
export interface SharedAssessment {
    /**
     * ゴール共有フラグ
     * @type {boolean}
     * @memberof SharedAssessment
     */
    'is_shared_goals': boolean;
    /**
     * 分析共有フラグ
     * @type {boolean}
     * @memberof SharedAssessment
     */
    'is_shared_analysis': boolean;
    /**
     * 反応共有フラグ
     * @type {boolean}
     * @memberof SharedAssessment
     */
    'is_shared_reactions': boolean;
}
/**
 * 公開ポートフォリオ情報
 * @export
 * @interface SharedPortfolio
 */
export interface SharedPortfolio {
    /**
     * portfolioId
     * @type {string}
     * @memberof SharedPortfolio
     */
    'portfolioId': string;
    /**
     * タイトル
     * @type {string}
     * @memberof SharedPortfolio
     */
    'title': string;
    /**
     * ポートフォリオ説明
     * @type {string}
     * @memberof SharedPortfolio
     */
    'description': string;
    /**
     * 
     * @type {string}
     * @memberof SharedPortfolio
     */
    'created_on': string;
    /**
     * カバー画像へのURL
     * @type {string}
     * @memberof SharedPortfolio
     */
    'cover_url': string;
    /**
     * 
     * @type {number}
     * @memberof SharedPortfolio
     */
    'template_type': number;
}
/**
 * 公開ポートフォリオID情報
 * @export
 * @interface SharedPortfolioId
 */
export interface SharedPortfolioId {
    /**
     * 公開ポートフォリオID
     * @type {number}
     * @memberof SharedPortfolioId
     */
    'portfolio_id': number;
}
/**
 * 公開ポートフォリオリスト
 * @export
 * @interface SharedPortfolios
 */
export interface SharedPortfolios {
    /**
     * 
     * @type {Array<SharedPortfolio>}
     * @memberof SharedPortfolios
     */
    'portfolios': Array<SharedPortfolio>;
    /**
     * ヒット件数
     * @type {number}
     * @memberof SharedPortfolios
     */
    'total_count': number;
    /**
     * オフセット値
     * @type {number}
     * @memberof SharedPortfolios
     */
    'offset': number;
    /**
     * 上限値
     * @type {number}
     * @memberof SharedPortfolios
     */
    'limit': number;
}
/**
 * 共有スキル情報
 * @export
 * @interface SharedSkill
 */
export interface SharedSkill {
    /**
     * 
     * @type {number}
     * @memberof SharedSkill
     */
    'id': number;
    /**
     * スキルタイプの英字名
     * @type {string}
     * @memberof SharedSkill
     */
    'skill_type_type': string;
    /**
     * スキルオーダー
     * @type {number}
     * @memberof SharedSkill
     */
    'order': number;
    /**
     * スキルタイプの和名
     * @type {string}
     * @memberof SharedSkill
     */
    'skill_type_name': string;
    /**
     * スキルのタイトル
     * @type {string}
     * @memberof SharedSkill
     */
    'title': string;
    /**
     * スキルの説明
     * @type {string}
     * @memberof SharedSkill
     */
    'description': string;
}
/**
 * 公開スキル情報
 * @export
 * @interface SharedSkills
 */
export interface SharedSkills {
    /**
     * 
     * @type {Array<SharedSkill>}
     * @memberof SharedSkills
     */
    'skills': Array<SharedSkill>;
}
/**
 * スキル情報
 * @export
 * @interface Skill
 */
export interface Skill {
    /**
     * スキルID
     * @type {number}
     * @memberof Skill
     */
    'id': number;
    /**
     * ユーザーID
     * @type {number}
     * @memberof Skill
     */
    'user_id': number;
    /**
     * 順番
     * @type {number}
     * @memberof Skill
     */
    'order': number;
    /**
     * タイトル
     * @type {string}
     * @memberof Skill
     */
    'title': string;
    /**
     * 説明
     * @type {string}
     * @memberof Skill
     */
    'description': string;
    /**
     * 
     * @type {SkillType}
     * @memberof Skill
     */
    'skill_type': SkillType;
}
/**
 * アナリティクス情報
 * @export
 * @interface SkillAnalytic
 */
export interface SkillAnalytic {
    /**
     * アナリティクスタイプ
     * @type {number}
     * @memberof SkillAnalytic
     */
    'type': number;
    /**
     * アナリティクスラベル
     * @type {string}
     * @memberof SkillAnalytic
     */
    'key': string;
    /**
     * アナリティクス値
     * @type {number}
     * @memberof SkillAnalytic
     */
    'value': number;
    /**
     * 表示可否
     * @type {boolean}
     * @memberof SkillAnalytic
     */
    'visible': boolean;
}
/**
 * 
 * @export
 * @interface SkillAnalytics
 */
export interface SkillAnalytics {
    /**
     * 
     * @type {Array<SkillAnalytic>}
     * @memberof SkillAnalytics
     */
    'analytics': Array<SkillAnalytic>;
    /**
     * 
     * @type {PageInfo}
     * @memberof SkillAnalytics
     */
    'page_info': PageInfo;
}
/**
 * スキル詳細情報
 * @export
 * @interface SkillDetail
 */
export interface SkillDetail {
    /**
     * スキル詳細ID
     * @type {number}
     * @memberof SkillDetail
     */
    'id': number;
    /**
     * スキルID
     * @type {number}
     * @memberof SkillDetail
     */
    'skill_id': number;
    /**
     * 順番
     * @type {number}
     * @memberof SkillDetail
     */
    'order': number;
    /**
     * タイトル
     * @type {string}
     * @memberof SkillDetail
     */
    'title': string;
    /**
     * 説明
     * @type {string}
     * @memberof SkillDetail
     */
    'description': string;
    /**
     * 開始日
     * @type {string}
     * @memberof SkillDetail
     */
    'start_date': string | null;
    /**
     * 終了日
     * @type {string}
     * @memberof SkillDetail
     */
    'end_date': string | null;
    /**
     * メタデータ
     * @type {Array<object>}
     * @memberof SkillDetail
     */
    'metas': Array<object>;
}
/**
 * スキル詳細情報
 * @export
 * @interface SkillDetailReq
 */
export interface SkillDetailReq {
    /**
     * スキル詳細ID
     * @type {number}
     * @memberof SkillDetailReq
     */
    'id': number;
    /**
     * スキルID
     * @type {number}
     * @memberof SkillDetailReq
     */
    'skill_id': number;
    /**
     * スキル詳細オーダー
     * @type {number}
     * @memberof SkillDetailReq
     */
    'order'?: number;
    /**
     * タイトル
     * @type {string}
     * @memberof SkillDetailReq
     */
    'title': string;
    /**
     * 説明
     * @type {string}
     * @memberof SkillDetailReq
     */
    'description': string;
    /**
     * 開始日
     * @type {string}
     * @memberof SkillDetailReq
     */
    'start_date': string | null;
    /**
     * 終了日
     * @type {string}
     * @memberof SkillDetailReq
     */
    'end_date': string | null;
    /**
     * メタデータ
     * @type {Array<object>}
     * @memberof SkillDetailReq
     */
    'metas': Array<object>;
}
/**
 * 
 * @export
 * @interface SkillDetails
 */
export interface SkillDetails {
    /**
     * 
     * @type {Array<SkillDetail>}
     * @memberof SkillDetails
     */
    'details': Array<SkillDetail>;
    /**
     * 
     * @type {PageInfo}
     * @memberof SkillDetails
     */
    'page_info': PageInfo;
}
/**
 * スキル詳細情報
 * @export
 * @interface SkillQualif
 */
export interface SkillQualif {
    /**
     * スキル詳細ID
     * @type {number}
     * @memberof SkillQualif
     */
    'id': number;
    /**
     * スキルID
     * @type {number}
     * @memberof SkillQualif
     */
    'skill_id': number;
    /**
     * 順番
     * @type {number}
     * @memberof SkillQualif
     */
    'order': number;
    /**
     * タイトル
     * @type {string}
     * @memberof SkillQualif
     */
    'title': string;
    /**
     * 取得日
     * @type {string}
     * @memberof SkillQualif
     */
    'acquisition_date'?: string | null;
}
/**
 * 
 * @export
 * @interface SkillQualifs
 */
export interface SkillQualifs {
    /**
     * 
     * @type {Array<SkillQualif>}
     * @memberof SkillQualifs
     */
    'qualifs': Array<SkillQualif>;
    /**
     * 
     * @type {PageInfo}
     * @memberof SkillQualifs
     */
    'page_info': PageInfo;
    /**
     * 全件数
     * @type {number}
     * @memberof SkillQualifs
     */
    'total_count': number;
}
/**
 * スキル種別
 * @export
 * @interface SkillType
 */
export interface SkillType {
    /**
     * スキル種別ID
     * @type {number}
     * @memberof SkillType
     */
    'id': number;
    /**
     * スキル種別
     * @type {string}
     * @memberof SkillType
     */
    'type': string;
    /**
     * スキル種別表示名
     * @type {string}
     * @memberof SkillType
     */
    'name': string;
    /**
     * スキル種別説明
     * @type {string}
     * @memberof SkillType
     */
    'description': string;
}
/**
 * スキル種別コードリスト情報
 * @export
 * @interface SkillTypes
 */
export interface SkillTypes {
    /**
     * 
     * @type {Array<SkillType>}
     * @memberof SkillTypes
     */
    'skill_types': Array<SkillType>;
}
/**
 * 
 * @export
 * @interface Skills
 */
export interface Skills {
    /**
     * 
     * @type {Array<Skill>}
     * @memberof Skills
     */
    'skills': Array<Skill>;
    /**
     * 
     * @type {PageInfo}
     * @memberof Skills
     */
    'page_info': PageInfo;
}
/**
 * 
 * @export
 * @interface Skimmies
 */
export interface Skimmies {
    /**
     * 
     * @type {Array<SkimmyResp>}
     * @memberof Skimmies
     */
    'skimmies': Array<SkimmyResp>;
    /**
     * 
     * @type {number}
     * @memberof Skimmies
     */
    'totalCount'?: number;
}
/**
 * スキミーバトルパラメーター情報
 * @export
 * @interface SkimmyBattleParameters
 */
export interface SkimmyBattleParameters {
    /**
     * スキミーのhp
     * @type {number}
     * @memberof SkimmyBattleParameters
     */
    'hp': number;
    /**
     * スキミーの攻撃力
     * @type {number}
     * @memberof SkimmyBattleParameters
     */
    'attack_strength': number;
    /**
     * スキミーの防御力
     * @type {number}
     * @memberof SkimmyBattleParameters
     */
    'defense_strength': number;
    /**
     * スキミーのクリティカル値
     * @type {number}
     * @memberof SkimmyBattleParameters
     */
    'critical_number': number;
}
/**
 * スキミー成長素材情報
 * @export
 * @interface SkimmyGrowthItems
 */
export interface SkimmyGrowthItems {
    /**
     * 獲得草葉のしずくEXP
     * @type {number}
     * @memberof SkimmyGrowthItems
     */
    'grass_drops_acquired': number;
    /**
     * 草葉のしずく保有数
     * @type {number}
     * @memberof SkimmyGrowthItems
     */
    'total_grass_drops': number;
    /**
     * 獲得樹木のしずくEXP
     * @type {number}
     * @memberof SkimmyGrowthItems
     */
    'tree_drops_acquired': number;
    /**
     * 樹木のしずく保有数
     * @type {number}
     * @memberof SkimmyGrowthItems
     */
    'total_tree_drops': number;
    /**
     * 獲得世界樹のしずくEXP
     * @type {number}
     * @memberof SkimmyGrowthItems
     */
    'world_tree_drops_acquired': number;
    /**
     * 世界樹のしずく保有数
     * @type {number}
     * @memberof SkimmyGrowthItems
     */
    'total_world_tree_drops': number;
}
/**
 * スキミーレベルアップ情報
 * @export
 * @interface SkimmyLevelUpInfo
 */
export interface SkimmyLevelUpInfo {
    /**
     * 
     * @type {Array<UserItem>}
     * @memberof SkimmyLevelUpInfo
     */
    'skimmy_growth_items': Array<UserItem>;
    /**
     * 進化段階
     * @type {number}
     * @memberof SkimmyLevelUpInfo
     */
    'evolutions_acquired': number;
    /**
     * 
     * @type {Array<UserItem>}
     * @memberof SkimmyLevelUpInfo
     */
    'evolution_items': Array<UserItem>;
    /**
     * 現スキミーレベル
     * @type {number}
     * @memberof SkimmyLevelUpInfo
     */
    'curr_skimmy_level': number;
    /**
     * 現スキミー成長ポイント数
     * @type {number}
     * @memberof SkimmyLevelUpInfo
     */
    'curr_skimmy_growth_points': number;
    /**
     * 次突破レベルのレベル数
     * @type {number}
     * @memberof SkimmyLevelUpInfo
     */
    'max_level_achievable': number;
    /**
     * 次突破レベルまでの成長ポイント数
     * @type {number}
     * @memberof SkimmyLevelUpInfo
     */
    'points_needed_for_max_level': number;
    /**
     * 次レベルまでの消化成長ポイント数
     * @type {number}
     * @memberof SkimmyLevelUpInfo
     */
    'next_level_curr_points': number;
    /**
     * 次レベルまでの必要成長ポイント数
     * @type {number}
     * @memberof SkimmyLevelUpInfo
     */
    'next_level_points_needed': number;
    /**
     * ユーザコイン数
     * @type {number}
     * @memberof SkimmyLevelUpInfo
     */
    'curr_coins': number;
    /**
     * 草葉のしずくを使うために必要なコイン数
     * @type {number}
     * @memberof SkimmyLevelUpInfo
     */
    'coins_per_grass_drop_use': number;
    /**
     * 樹木のしずくを使うために必要なコイン数
     * @type {number}
     * @memberof SkimmyLevelUpInfo
     */
    'coins_per_tree_drop_use': number;
    /**
     * 世界樹のしずくを使うために必要なコイン数
     * @type {number}
     * @memberof SkimmyLevelUpInfo
     */
    'coins_per_world_tree_drop_use': number;
    /**
     * 草葉のしずくのポイント数
     * @type {number}
     * @memberof SkimmyLevelUpInfo
     */
    'points_per_grass_drop_use': number;
    /**
     * 樹木のしずくのポイント数
     * @type {number}
     * @memberof SkimmyLevelUpInfo
     */
    'points_per_tree_drop_use': number;
    /**
     * 世界樹のしずくのポイント数
     * @type {number}
     * @memberof SkimmyLevelUpInfo
     */
    'points_per_world_tree_drop_use': number;
    /**
     * 
     * @type {Array<ExpsLevel>}
     * @memberof SkimmyLevelUpInfo
     */
    'exps_necessary_per_level': Array<ExpsLevel>;
    /**
     * 成長ポイントとコインの比率
     * @type {number}
     * @memberof SkimmyLevelUpInfo
     */
    'exp_to_coin_ratio': number;
    /**
     * 次突破までの必要アイテム数
     * @type {number}
     * @memberof SkimmyLevelUpInfo
     */
    'next_evolution_items_needed': number;
    /**
     * 突破段階にあるかのフラグ
     * @type {boolean}
     * @memberof SkimmyLevelUpInfo
     */
    'is_evolution_step': boolean;
}
/**
 * スキミー経験値とレベルアップ情報
 * @export
 * @interface SkimmyLevelUpReq
 */
export interface SkimmyLevelUpReq {
    /**
     * スキミーID
     * @type {number}
     * @memberof SkimmyLevelUpReq
     */
    'skimmy_id': number;
    /**
     * アイテムコード
     * @type {string}
     * @memberof SkimmyLevelUpReq
     */
    'growth_item_code': string;
    /**
     * 成長アイテム消費数
     * @type {number}
     * @memberof SkimmyLevelUpReq
     */
    'growth_item_count': number;
    /**
     * コイン消費数
     * @type {number}
     * @memberof SkimmyLevelUpReq
     */
    'coin_count': number;
    /**
     * 獲得成長ポイント数
     * @type {number}
     * @memberof SkimmyLevelUpReq
     */
    'exp_count': number;
}
/**
 * 
 * @export
 * @interface SkimmyListByTypeResp
 */
export interface SkimmyListByTypeResp {
    /**
     * 
     * @type {Array<SkimmyResp>}
     * @memberof SkimmyListByTypeResp
     */
    'skimmy_list': Array<SkimmyResp>;
}
/**
 * スキミーパラメーター情報
 * @export
 * @interface SkimmyParameters
 */
export interface SkimmyParameters {
    /**
     * 現スキミーレベル
     * @type {number}
     * @memberof SkimmyParameters
     */
    'curr_skimmy_level': number;
    /**
     * 
     * @type {SkimmyBattleParameters}
     * @memberof SkimmyParameters
     */
    'skimmy_parameters': SkimmyBattleParameters;
    /**
     * 装備可能武器
     * @type {string}
     * @memberof SkimmyParameters
     */
    'equippable_weapon_type': string;
    /**
     * 
     * @type {UserItem}
     * @memberof SkimmyParameters
     */
    'equipped_weapon': UserItem;
    /**
     * 武器を装備しているかのフラグ
     * @type {boolean}
     * @memberof SkimmyParameters
     */
    'has_weapon': boolean;
}
/**
 * スキミー情報
 * @export
 * @interface SkimmyResp
 */
export interface SkimmyResp {
    /**
     * ID
     * @type {number}
     * @memberof SkimmyResp
     */
    'id': number;
    /**
     * ユーザースキミーID
     * @type {number}
     * @memberof SkimmyResp
     */
    'user_skimmy_id': number;
    /**
     * スキミー番号
     * @type {number}
     * @memberof SkimmyResp
     */
    'number': number;
    /**
     * スキミー種類
     * @type {number}
     * @memberof SkimmyResp
     */
    'type': number;
    /**
     * スキミー名
     * @type {string}
     * @memberof SkimmyResp
     */
    'name': string;
    /**
     * スキミー成長段階
     * @type {number}
     * @memberof SkimmyResp
     */
    'evolution': number;
    /**
     * 
     * @type {string}
     * @memberof SkimmyResp
     */
    'skimmy_image': string;
    /**
     * 
     * @type {string}
     * @memberof SkimmyResp
     */
    'background_image': string;
    /**
     * 該当する成長段階のスタートジェム数
     * @type {number}
     * @memberof SkimmyResp
     */
    'gems_fed_from': number;
    /**
     * 該当する成長段階のエンドジェム数
     * @type {number}
     * @memberof SkimmyResp
     */
    'gems_fed_to': number;
    /**
     * スキミーに与えた総ジェム数
     * @type {number}
     * @memberof SkimmyResp
     */
    'gems_fed': number;
    /**
     * スキミーに与えられる最大ジェム数
     * @type {number}
     * @memberof SkimmyResp
     */
    'max_gems_feedable': number;
    /**
     * 成長段階が最初であるかのフラグ
     * @type {boolean}
     * @memberof SkimmyResp
     */
    'is_first': boolean;
    /**
     * 成長段階が最後であるかのフラグ
     * @type {boolean}
     * @memberof SkimmyResp
     */
    'is_max': boolean;
    /**
     * デフォルトスキミーであるかのフラグ
     * @type {boolean}
     * @memberof SkimmyResp
     */
    'is_default': boolean;
    /**
     * スキミーレア度
     * @type {number}
     * @memberof SkimmyResp
     */
    'rarity': number;
    /**
     * スキミー成長スピードの指標
     * @type {number}
     * @memberof SkimmyResp
     */
    'growth_speed': number;
    /**
     * スキミーストーリー概要タイトル
     * @type {string}
     * @memberof SkimmyResp
     */
    'short_story_title': string;
    /**
     * スキミーストーリー概要
     * @type {string}
     * @memberof SkimmyResp
     */
    'short_story': string;
    /**
     * スキミーストーリータイトル
     * @type {string}
     * @memberof SkimmyResp
     */
    'story_title': string;
    /**
     * スキミーストーリー
     * @type {string}
     * @memberof SkimmyResp
     */
    'story': string;
    /**
     * ユーザーの現スキミーであるかのフラグ
     * @type {boolean}
     * @memberof SkimmyResp
     */
    'is_current_skimmy': boolean;
    /**
     * 所持したことがあるかのフラグ
     * @type {boolean}
     * @memberof SkimmyResp
     */
    'has_this': boolean;
    /**
     * 選択できるか（”交代”ではない）のフラグ
     * @type {boolean}
     * @memberof SkimmyResp
     */
    'selectable': boolean;
    /**
     * スキミーの名前
     * @type {string}
     * @memberof SkimmyResp
     */
    'given_name': string;
    /**
     * スキミー原案作成者
     * @type {string}
     * @memberof SkimmyResp
     */
    'skimmy_art_original': string;
    /**
     * スキミーストーリー著者
     * @type {string}
     * @memberof SkimmyResp
     */
    'skimmy_story_writer': string;
    /**
     * バートルテスト属性
     * @type {string}
     * @memberof SkimmyResp
     */
    'attribute': string;
    /**
     * シーズン番号
     * @type {number}
     * @memberof SkimmyResp
     */
    'season': number;
    /**
     * スキミー誕生日
     * @type {string}
     * @memberof SkimmyResp
     */
    'skimmy_birthday': string;
    /**
     * 現スキミーレベル
     * @type {number}
     * @memberof SkimmyResp
     */
    'curr_skimmy_level': number;
    /**
     * 次レベルまでの消化成長ポイント数
     * @type {number}
     * @memberof SkimmyResp
     */
    'next_level_curr_points': number;
    /**
     * 次レベルまでの必要成長ポイント数
     * @type {number}
     * @memberof SkimmyResp
     */
    'next_level_points_needed': number;
    /**
     * 武器を装備しているかのフラグ
     * @type {boolean}
     * @memberof SkimmyResp
     */
    'has_weapon': boolean;
    /**
     * 
     * @type {UserItem}
     * @memberof SkimmyResp
     */
    'weapon': UserItem;
}
/**
 * スキミー探索情報
 * @export
 * @interface SkimmySearch
 */
export interface SkimmySearch {
    /**
     * 探索ID
     * @type {number}
     * @memberof SkimmySearch
     */
    'search_id': number;
    /**
     * 探索状態
     * @type {number}
     * @memberof SkimmySearch
     */
    'search_status': number;
    /**
     * 
     * @type {string}
     * @memberof SkimmySearch
     */
    'skimmy_image': string;
    /**
     * スキミーの名前
     * @type {string}
     * @memberof SkimmySearch
     */
    'skimmy_name': string;
    /**
     * 探索終了時刻
     * @type {string}
     * @memberof SkimmySearch
     */
    'search_end_time': string;
    /**
     * 探索エリアID
     * @type {number}
     * @memberof SkimmySearch
     */
    'search_area_id': number;
    /**
     * 探索時間
     * @type {number}
     * @memberof SkimmySearch
     */
    'search_hours': number;
    /**
     * スキミー種別
     * @type {number}
     * @memberof SkimmySearch
     */
    'skimmy_type': number;
    /**
     * スキミーID
     * @type {number}
     * @memberof SkimmySearch
     */
    'skimmy_id': number;
}
/**
 * スキミー探索ボーナス情報
 * @export
 * @interface SkimmySearchBonus
 */
export interface SkimmySearchBonus {
    /**
     * 
     * @type {Array<UserItem>}
     * @memberof SkimmySearchBonus
     */
    'search_bonus': Array<UserItem>;
    /**
     * コイン獲得数
     * @type {number}
     * @memberof SkimmySearchBonus
     */
    'coins': number;
}
/**
 * スキミー探索リスト情報
 * @export
 * @interface SkimmySearchInfo
 */
export interface SkimmySearchInfo {
    /**
     * 
     * @type {Array<SkimmySearch>}
     * @memberof SkimmySearchInfo
     */
    'skimmy_searches': Array<SkimmySearch>;
    /**
     * 現ユーザレベル
     * @type {number}
     * @memberof SkimmySearchInfo
     */
    'user_level': number;
    /**
     * ユーザジェム数
     * @type {number}
     * @memberof SkimmySearchInfo
     */
    'user_gems': number;
}
/**
 * スキミー探索情報
 * @export
 * @interface SkimmySearchReq
 */
export interface SkimmySearchReq {
    /**
     * スキミーID
     * @type {number}
     * @memberof SkimmySearchReq
     */
    'skimmy_id': number;
    /**
     * スキミータイプ
     * @type {number}
     * @memberof SkimmySearchReq
     */
    'skimmy_type': number;
    /**
     * 探索時間
     * @type {number}
     * @memberof SkimmySearchReq
     */
    'search_hours': number;
    /**
     * 探索エリアID
     * @type {number}
     * @memberof SkimmySearchReq
     */
    'search_area_id': number;
}
/**
 * ステータス
 * @export
 * @interface Status
 */
export interface Status {
    /**
     * ステータスID
     * @type {number}
     * @memberof Status
     */
    'id': number;
    /**
     * ステータス名
     * @type {string}
     * @memberof Status
     */
    'name': string;
}
/**
 * メンバー状態コードリスト情報
 * @export
 * @interface Statuses
 */
export interface Statuses {
    /**
     * 
     * @type {Array<Status>}
     * @memberof Statuses
     */
    'statuses': Array<Status>;
}
/**
 * 公開プロフィールストーリー情報
 * @export
 * @interface Story
 */
export interface Story {
    /**
     * 開始日
     * @type {string}
     * @memberof Story
     */
    'from': string | null;
    /**
     * ストーリータイトル
     * @type {string}
     * @memberof Story
     */
    'title': string;
    /**
     * ストーリー説明
     * @type {string}
     * @memberof Story
     */
    'description': string;
}
/**
 * 公開プロフィールストーリー情報
 * @export
 * @interface StoryRequest
 */
export interface StoryRequest {
    /**
     * 開始日
     * @type {string}
     * @memberof StoryRequest
     */
    'from': string | null;
    /**
     * ストーリータイトル
     * @type {string}
     * @memberof StoryRequest
     */
    'title': string;
    /**
     * ストーリー説明
     * @type {string}
     * @memberof StoryRequest
     */
    'description': string;
}
/**
 * カラム情報リスト
 * @export
 * @interface TableDefReq
 */
export interface TableDefReq {
    /**
     * テーブル名
     * @type {string}
     * @memberof TableDefReq
     */
    'name': string;
    /**
     * テーブル説明
     * @type {string}
     * @memberof TableDefReq
     */
    'description': string;
    /**
     * 
     * @type {Array<FieldDefReq>}
     * @memberof TableDefReq
     */
    'fields': Array<FieldDefReq>;
}
/**
 * テーブル定義情報
 * @export
 * @interface TableDefResp
 */
export interface TableDefResp {
    /**
     * テーブル識別子
     * @type {number}
     * @memberof TableDefResp
     */
    'table_number': number;
    /**
     * 順番
     * @type {number}
     * @memberof TableDefResp
     */
    'order': number;
    /**
     * テーブル名
     * @type {string}
     * @memberof TableDefResp
     */
    'name': string;
    /**
     * 説明
     * @type {string}
     * @memberof TableDefResp
     */
    'description': string;
    /**
     * 
     * @type {Array<FieldDefResp>}
     * @memberof TableDefResp
     */
    'fields': Array<FieldDefResp>;
}
/**
 * テーブル定義情報リスト
 * @export
 * @interface TableDefsResp
 */
export interface TableDefsResp {
    /**
     * 
     * @type {Array<TableDefResp>}
     * @memberof TableDefsResp
     */
    'tables': Array<TableDefResp>;
    /**
     * 
     * @type {PageInfo}
     * @memberof TableDefsResp
     */
    'info': PageInfo;
}
/**
 * テーブル定義情報
 * @export
 * @interface TableRowCsvUploadResp
 */
export interface TableRowCsvUploadResp {
    /**
     * 更新種別
     * @type {number}
     * @memberof TableRowCsvUploadResp
     */
    'status': number;
    /**
     * キー名
     * @type {string}
     * @memberof TableRowCsvUploadResp
     */
    'keyname': string;
}
/**
 * 
 * @export
 * @interface TableRowReq
 */
export interface TableRowReq {
    /**
     * 
     * @type {object}
     * @memberof TableRowReq
     */
    'row': object;
}
/**
 * 
 * @export
 * @interface TableRowResp
 */
export interface TableRowResp {
    /**
     * 
     * @type {object}
     * @memberof TableRowResp
     */
    'row': object;
}
/**
 * テーブル情報
 * @export
 * @interface TableRowsCsvHistoryReq
 */
export interface TableRowsCsvHistoryReq {
    /**
     * 
     * @type {boolean}
     * @memberof TableRowsCsvHistoryReq
     */
    'done'?: boolean;
}
/**
 * カラム定義情報
 * @export
 * @interface TableRowsCsvHistoryResp
 */
export interface TableRowsCsvHistoryResp {
    /**
     * 
     * @type {boolean}
     * @memberof TableRowsCsvHistoryResp
     */
    'done': boolean;
}
/**
 * 
 * @export
 * @interface TableRowsCsvReq
 */
export interface TableRowsCsvReq {
    /**
     * ファイル名
     * @type {string}
     * @memberof TableRowsCsvReq
     */
    'filename': string;
    /**
     * CSV
     * @type {string}
     * @memberof TableRowsCsvReq
     */
    'csv': string;
}
/**
 * 
 * @export
 * @interface TableRowsCsvResp
 */
export interface TableRowsCsvResp {
    /**
     * ファイル名
     * @type {string}
     * @memberof TableRowsCsvResp
     */
    'filename': string;
    /**
     * CSV
     * @type {string}
     * @memberof TableRowsCsvResp
     */
    'csv': string;
}
/**
 * 
 * @export
 * @interface TableRowsCsvUploadResp
 */
export interface TableRowsCsvUploadResp {
    /**
     * 履歴番号
     * @type {number}
     * @memberof TableRowsCsvUploadResp
     */
    'history_number': number;
    /**
     * CSV
     * @type {Array<TableRowCsvUploadResp>}
     * @memberof TableRowsCsvUploadResp
     */
    'summaries': Array<TableRowCsvUploadResp>;
}
/**
 * テーブル定義情報リスト
 * @export
 * @interface TableRowsResp
 */
export interface TableRowsResp {
    /**
     * 
     * @type {Array<TableRowResp>}
     * @memberof TableRowsResp
     */
    'rows': Array<TableRowResp>;
    /**
     * 
     * @type {PageInfo}
     * @memberof TableRowsResp
     */
    'info': PageInfo;
}
/**
 * task情報
 * @export
 * @interface Task
 */
export interface Task {
    /**
     * タスクID
     * @type {number}
     * @memberof Task
     */
    'id': number;
    /**
     * タイトル
     * @type {string}
     * @memberof Task
     */
    'subject': string;
    /**
     * タスク内容
     * @type {string}
     * @memberof Task
     */
    'description': string;
    /**
     * 開始日
     * @type {string}
     * @memberof Task
     */
    'start_date_obj': string | null;
    /**
     * 開始日
     * @type {string}
     * @memberof Task
     */
    'start_date': string | null;
    /**
     * 終了日
     * @type {string}
     * @memberof Task
     */
    'due_date_obj': string | null;
    /**
     * 終了日
     * @type {string}
     * @memberof Task
     */
    'due_date': string | null;
    /**
     * 
     * @type {string}
     * @memberof Task
     */
    'created_on': string;
    /**
     * 
     * @type {string}
     * @memberof Task
     */
    'updated_on': string;
    /**
     * 工数
     * @type {number}
     * @memberof Task
     */
    'estimated_hours': number;
    /**
     * 進捗率
     * @type {number}
     * @memberof Task
     */
    'done_ratio': number;
    /**
     * 
     * @type {Priority}
     * @memberof Task
     */
    'priority': Priority;
    /**
     * 
     * @type {Category}
     * @memberof Task
     */
    'category': Category;
    /**
     * 
     * @type {Status}
     * @memberof Task
     */
    'status': Status;
    /**
     * 
     * @type {Author}
     * @memberof Task
     */
    'author': Author;
    /**
     * 
     * @type {AssingedTo}
     * @memberof Task
     */
    'assigned_to'?: AssingedTo;
    /**
     * 
     * @type {Array<IssueMeta>}
     * @memberof Task
     */
    'issue_meta': Array<IssueMeta>;
    /**
     * 
     * @type {Array<Journal>}
     * @memberof Task
     */
    'journals'?: Array<Journal>;
    /**
     * チーム名
     * @type {string}
     * @memberof Task
     */
    'teamname'?: string;
    /**
     * 2
     * @type {number}
     * @memberof Task
     */
    'projectOrder'?: number;
    /**
     * ゴールID
     * @type {number}
     * @memberof Task
     */
    'goal_id'?: number;
    /**
     * 
     * @type {Goal}
     * @memberof Task
     */
    'goal'?: Goal;
    /**
     * 
     * @type {object}
     * @memberof Task
     */
    'timeEntries'?: object;
    /**
     * 
     * @type {number}
     * @memberof Task
     */
    'editorVersion'?: number;
}
/**
 * タスク情報
 * @export
 * @interface TaskRequest
 */
export interface TaskRequest {
    /**
     * タスクID
     * @type {number}
     * @memberof TaskRequest
     */
    'id': number;
    /**
     * タイトル
     * @type {string}
     * @memberof TaskRequest
     */
    'subject': string;
    /**
     * タスク内容
     * @type {string}
     * @memberof TaskRequest
     */
    'description': string;
    /**
     * 開始日
     * @type {string}
     * @memberof TaskRequest
     */
    'start_date_obj': string | null;
    /**
     * 開始日
     * @type {string}
     * @memberof TaskRequest
     */
    'start_date'?: string | null;
    /**
     * 終了日
     * @type {string}
     * @memberof TaskRequest
     */
    'due_date_obj': string | null;
    /**
     * 終了日
     * @type {string}
     * @memberof TaskRequest
     */
    'due_date'?: string | null;
    /**
     * 工数
     * @type {number}
     * @memberof TaskRequest
     */
    'estimated_hours': number;
    /**
     * 進捗率
     * @type {number}
     * @memberof TaskRequest
     */
    'done_ratio': number;
    /**
     * 優先度識別ID
     * @type {number}
     * @memberof TaskRequest
     */
    'priority_id': number;
    /**
     * カテゴリID
     * @type {number}
     * @memberof TaskRequest
     */
    'category_id': number;
    /**
     * ステータスID
     * @type {number}
     * @memberof TaskRequest
     */
    'status_id': number;
    /**
     * 
     * @type {Array<IssueMetaRequest>}
     * @memberof TaskRequest
     */
    'issue_meta': Array<IssueMetaRequest>;
    /**
     * チーム名
     * @type {string}
     * @memberof TaskRequest
     */
    'teamname'?: string;
    /**
     * 2
     * @type {number}
     * @memberof TaskRequest
     */
    'projectOrder'?: number;
    /**
     * ゴールID
     * @type {number}
     * @memberof TaskRequest
     */
    'goal_id'?: number;
    /**
     * 担当者ユーザーID
     * @type {number}
     * @memberof TaskRequest
     */
    'assigned_to_id'?: number;
    /**
     * 
     * @type {number}
     * @memberof TaskRequest
     */
    'editorVersion'?: number;
}
/**
 * 作業時間
 * @export
 * @interface TaskTime
 */
export interface TaskTime {
    /**
     * チーム名
     * @type {string}
     * @memberof TaskTime
     */
    'teamname': string;
    /**
     * プロジェクト番号
     * @type {number}
     * @memberof TaskTime
     */
    'project_order': number;
    /**
     * タスクID
     * @type {number}
     * @memberof TaskTime
     */
    'issue_id': number;
    /**
     * 時間
     * @type {number}
     * @memberof TaskTime
     */
    'hours': number;
}
/**
 * 作業時間
 * @export
 * @interface TaskTimeRequest
 */
export interface TaskTimeRequest {
    /**
     * チーム名
     * @type {string}
     * @memberof TaskTimeRequest
     */
    'teamname': string;
    /**
     * プロジェクト番号
     * @type {number}
     * @memberof TaskTimeRequest
     */
    'project_order': number;
    /**
     * タスクID
     * @type {number}
     * @memberof TaskTimeRequest
     */
    'issue_id': number;
    /**
     * 時間
     * @type {number}
     * @memberof TaskTimeRequest
     */
    'hours': number;
}
/**
 * Idとvalueのセットリスト
 * @export
 * @interface TaskTimes
 */
export interface TaskTimes {
    /**
     * 
     * @type {Array<TaskTime>}
     * @memberof TaskTimes
     */
    'entries': Array<TaskTime>;
}
/**
 * Idとvalueのセットリスト
 * @export
 * @interface TaskTimesRequest
 */
export interface TaskTimesRequest {
    /**
     * 
     * @type {Array<TaskTimeRequest>}
     * @memberof TaskTimesRequest
     */
    'entries': Array<TaskTimeRequest>;
}
/**
 * イシューユーザー情報
 * @export
 * @interface TaskUser
 */
export interface TaskUser {
    /**
     * 
     * @type {User}
     * @memberof TaskUser
     */
    'sk_user': User;
}
/**
 * taskリスト情報
 * @export
 * @interface Tasks
 */
export interface Tasks {
    /**
     * 
     * @type {Array<Task>}
     * @memberof Tasks
     */
    'issues': Array<Task>;
    /**
     * ヒット件数
     * @type {number}
     * @memberof Tasks
     */
    'total_count': number;
    /**
     * オフセット値
     * @type {number}
     * @memberof Tasks
     */
    'offset': number;
    /**
     * 上限値
     * @type {number}
     * @memberof Tasks
     */
    'limit': number;
}
/**
 * チーム情報
 * @export
 * @interface Team
 */
export interface Team {
    /**
     * チームID
     * @type {number}
     * @memberof Team
     */
    'id': number;
    /**
     * チーム識別名
     * @type {string}
     * @memberof Team
     */
    'teamname': string;
    /**
     * チーム名
     * @type {string}
     * @memberof Team
     */
    'name': string;
    /**
     * チーム説明
     * @type {string}
     * @memberof Team
     */
    'description': string;
    /**
     * チーム開始日
     * @type {string}
     * @memberof Team
     */
    'created_at': string;
    /**
     * チーム終了日
     * @type {string}
     * @memberof Team
     */
    'deleted_at': string | null;
    /**
     * 削除されたチームかを切り分けるフラグ
     * @type {boolean}
     * @memberof Team
     */
    'is_deleted': boolean;
    /**
     * ロゴURL
     * @type {string}
     * @memberof Team
     */
    'logoUrl': string;
}
/**
 * チーム紹介情報
 * @export
 * @interface TeamDescription
 */
export interface TeamDescription {
    /**
     * 一般公開フラグ
     * @type {boolean}
     * @memberof TeamDescription
     */
    'is_public': boolean;
    /**
     * 
     * @type {string}
     * @memberof TeamDescription
     */
    'teamname': string;
    /**
     * 
     * @type {string}
     * @memberof TeamDescription
     */
    'name': string;
    /**
     * ロゴ画像のURL
     * @type {string}
     * @memberof TeamDescription
     */
    'logo_url': string;
    /**
     * バナー画像のURL
     * @type {string}
     * @memberof TeamDescription
     */
    'banner_url': string;
    /**
     * チーム紹介内容
     * @type {string}
     * @memberof TeamDescription
     */
    'description': string;
    /**
     * 
     * @type {Array<TeamHistory>}
     * @memberof TeamDescription
     */
    'histories': Array<TeamHistory>;
    /**
     * 
     * @type {Array<TeamSkillPoints>}
     * @memberof TeamDescription
     */
    'skills': Array<TeamSkillPoints>;
}
/**
 * チーム紹介情報
 * @export
 * @interface TeamDescriptionRequest
 */
export interface TeamDescriptionRequest {
    /**
     * 一般公開フラグ
     * @type {boolean}
     * @memberof TeamDescriptionRequest
     */
    'is_public': boolean;
    /**
     * ロゴ画像のURL
     * @type {string}
     * @memberof TeamDescriptionRequest
     */
    'logo_url': string;
    /**
     * バナー画像のURL
     * @type {string}
     * @memberof TeamDescriptionRequest
     */
    'banner_url': string;
    /**
     * チーム紹介内容
     * @type {string}
     * @memberof TeamDescriptionRequest
     */
    'description': string;
    /**
     * 
     * @type {Array<TeamHistoryReq>}
     * @memberof TeamDescriptionRequest
     */
    'histories': Array<TeamHistoryReq>;
    /**
     * 
     * @type {Array<TeamSkillsReq>}
     * @memberof TeamDescriptionRequest
     */
    'skills': Array<TeamSkillsReq>;
    /**
     * 初期登録か修正かを切り分けるフラグ
     * @type {boolean}
     * @memberof TeamDescriptionRequest
     */
    'isEdit': boolean;
}
/**
 * チーム紹介情報
 * @export
 * @interface TeamDescriptions
 */
export interface TeamDescriptions {
    /**
     * 
     * @type {Array<TeamDescription>}
     * @memberof TeamDescriptions
     */
    'teams': Array<TeamDescription>;
    /**
     * 
     * @type {PageInfo}
     * @memberof TeamDescriptions
     */
    'page_info': PageInfo;
}
/**
 * 
 * @export
 * @interface TeamHistory
 */
export interface TeamHistory {
    /**
     * 開始日
     * @type {string}
     * @memberof TeamHistory
     */
    'from': string | null;
    /**
     * タイトル
     * @type {string}
     * @memberof TeamHistory
     */
    'title': string;
    /**
     * 内容
     * @type {string}
     * @memberof TeamHistory
     */
    'description': string;
}
/**
 * 
 * @export
 * @interface TeamHistoryReq
 */
export interface TeamHistoryReq {
    /**
     * 開始日
     * @type {string}
     * @memberof TeamHistoryReq
     */
    'from': string | null;
    /**
     * タイトル
     * @type {string}
     * @memberof TeamHistoryReq
     */
    'title': string;
    /**
     * 内容
     * @type {string}
     * @memberof TeamHistoryReq
     */
    'description': string;
}
/**
 * インテグレーション設定メタ情報
 * @export
 * @interface TeamIntegSettingMetaReq
 */
export interface TeamIntegSettingMetaReq {
    /**
     * インテグレーション種別メタID
     * @type {number}
     * @memberof TeamIntegSettingMetaReq
     */
    'integration_meta_id': number;
    /**
     * インテグレーション設定ID
     * @type {number}
     * @memberof TeamIntegSettingMetaReq
     */
    'integration_setting_id': number;
    /**
     * 設定値
     * @type {string}
     * @memberof TeamIntegSettingMetaReq
     */
    'value': string;
}
/**
 * インテグレーション設定情報
 * @export
 * @interface TeamIntegSettingReq
 */
export interface TeamIntegSettingReq {
    /**
     * インテグレーション設定ID
     * @type {number}
     * @memberof TeamIntegSettingReq
     */
    'id': number;
    /**
     * インテグレーション種別ID
     * @type {number}
     * @memberof TeamIntegSettingReq
     */
    'integration_id': number;
    /**
     * インテグレーション設定オーダー
     * @type {number}
     * @memberof TeamIntegSettingReq
     */
    'order': number;
    /**
     * 設定チームID
     * @type {number}
     * @memberof TeamIntegSettingReq
     */
    'team_id': number;
    /**
     * 
     * @type {Array<TeamIntegSettingMetaReq>}
     * @memberof TeamIntegSettingReq
     */
    'integration_setting_metas': Array<TeamIntegSettingMetaReq>;
}
/**
 * チーム>プロジェクト情報
 * @export
 * @interface TeamProjReq
 */
export interface TeamProjReq {
    /**
     * チーム>プロジェクトID
     * @type {number}
     * @memberof TeamProjReq
     */
    'id'?: number;
    /**
     * チームプロジェクトオーダー
     * @type {number}
     * @memberof TeamProjReq
     */
    'order'?: number;
    /**
     * 絵文字
     * @type {string}
     * @memberof TeamProjReq
     */
    'emoji'?: string;
    /**
     * プロジェクト名
     * @type {string}
     * @memberof TeamProjReq
     */
    'name': string;
    /**
     * プロジェクトの概要説明
     * @type {string}
     * @memberof TeamProjReq
     */
    'description'?: string;
}
/**
 * チームスキル情報
 * @export
 * @interface TeamSkill
 */
export interface TeamSkill {
    /**
     * スキルID
     * @type {number}
     * @memberof TeamSkill
     */
    'id': number;
    /**
     * 
     * @type {SkillAnalytic}
     * @memberof TeamSkill
     */
    'skill_analytic': SkillAnalytic;
    /**
     * 
     * @type {SkillType}
     * @memberof TeamSkill
     */
    'skill_type': SkillType;
}
/**
 * 
 * @export
 * @interface TeamSkillPoints
 */
export interface TeamSkillPoints {
    /**
     * スキルタイプ
     * @type {number}
     * @memberof TeamSkillPoints
     */
    'type': number;
    /**
     * 
     * @type {Array<TeamSkillPointsPointsInner>}
     * @memberof TeamSkillPoints
     */
    'points': Array<TeamSkillPointsPointsInner>;
}
/**
 * 
 * @export
 * @interface TeamSkillPointsPointsInner
 */
export interface TeamSkillPointsPointsInner {
    /**
     * タイトル
     * @type {string}
     * @memberof TeamSkillPointsPointsInner
     */
    'title': string;
    /**
     * 内容
     * @type {number}
     * @memberof TeamSkillPointsPointsInner
     */
    'value': number;
    /**
     * 表示可否
     * @type {boolean}
     * @memberof TeamSkillPointsPointsInner
     */
    'visible': boolean;
}
/**
 * 
 * @export
 * @interface TeamSkills
 */
export interface TeamSkills {
    /**
     * 
     * @type {Array<TeamSkill>}
     * @memberof TeamSkills
     */
    'skills': Array<TeamSkill>;
    /**
     * 
     * @type {PageInfo}
     * @memberof TeamSkills
     */
    'page_info': PageInfo;
}
/**
 * 
 * @export
 * @interface TeamSkillsReq
 */
export interface TeamSkillsReq {
    /**
     * スキルタイプ
     * @type {number}
     * @memberof TeamSkillsReq
     */
    'type': number;
    /**
     * 
     * @type {Array<TeamSkillsReqPointsInner>}
     * @memberof TeamSkillsReq
     */
    'points': Array<TeamSkillsReqPointsInner>;
}
/**
 * 
 * @export
 * @interface TeamSkillsReqPointsInner
 */
export interface TeamSkillsReqPointsInner {
    /**
     * タイトル
     * @type {string}
     * @memberof TeamSkillsReqPointsInner
     */
    'title': string;
    /**
     * 内容
     * @type {number}
     * @memberof TeamSkillsReqPointsInner
     */
    'value': number;
    /**
     * 表示可否
     * @type {boolean}
     * @memberof TeamSkillsReqPointsInner
     */
    'visible'?: boolean;
}
/**
 * 所属チームリスト
 * @export
 * @interface Teams
 */
export interface Teams {
    /**
     * 
     * @type {Array<Team>}
     * @memberof Teams
     */
    'teams': Array<Team>;
    /**
     * 
     * @type {PageInfo}
     * @memberof Teams
     */
    'page_info': PageInfo;
}
/**
 * 
 * @export
 * @interface TokenReq
 */
export interface TokenReq {
    /**
     * デバイスの識別子
     * @type {string}
     * @memberof TokenReq
     */
    'deviceIdentifier': string;
    /**
     * トークン
     * @type {string}
     * @memberof TokenReq
     */
    'token': string;
    /**
     * 
     * @type {boolean}
     * @memberof TokenReq
     */
    'isIos': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof TokenReq
     */
    'isAndroid': boolean;
    /**
     * 
     * @type {string}
     * @memberof TokenReq
     */
    'osVersion'?: string;
}
/**
 * 
 * @export
 * @interface TokenResp
 */
export interface TokenResp {
    /**
     * デバイスの識別子
     * @type {string}
     * @memberof TokenResp
     */
    'deviceIdentifier': string;
    /**
     * トークン
     * @type {string}
     * @memberof TokenResp
     */
    'token': string;
    /**
     * 
     * @type {boolean}
     * @memberof TokenResp
     */
    'isIos': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof TokenResp
     */
    'isAndroid': boolean;
    /**
     * 
     * @type {string}
     * @memberof TokenResp
     */
    'osVersion'?: string;
}
/**
 * バッジ情報
 * @export
 * @interface Tutorial
 */
export interface Tutorial {
    /**
     * 
     * @type {string}
     * @memberof Tutorial
     */
    'name': string;
    /**
     * 
     * @type {boolean}
     * @memberof Tutorial
     */
    'completed': boolean;
}
/**
 * チュートリアル実施状況
 * @export
 * @interface TutorialRequest
 */
export interface TutorialRequest {
    /**
     * 
     * @type {boolean}
     * @memberof TutorialRequest
     */
    'completedGrowSkimmy'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof TutorialRequest
     */
    'completedUserGoal'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof TutorialRequest
     */
    'completedCreatedGoal'?: boolean | null;
}
/**
 * チュートリアルリスト情報
 * @export
 * @interface Tutorials
 */
export interface Tutorials {
    /**
     * 
     * @type {Array<Tutorial>}
     * @memberof Tutorials
     */
    'tutorials': Array<Tutorial>;
}
/**
 * タイプユーザアイテムリスト情報
 * @export
 * @interface TypeUserItems
 */
export interface TypeUserItems {
    /**
     * 
     * @type {Array<UserItem>}
     * @memberof TypeUserItems
     */
    'user_items': Array<UserItem>;
    /**
     * アイテムタイプコード
     * @type {string}
     * @memberof TypeUserItems
     */
    'item_type_code': string;
}
/**
 * アップロードリスポンス
 * @export
 * @interface UploadResponse
 */
export interface UploadResponse {
    /**
     * メッセージ
     * @type {string}
     * @memberof UploadResponse
     */
    'message': string;
    /**
     * アップロードファイルへのパス
     * @type {string}
     * @memberof UploadResponse
     */
    'upload_path'?: string;
}
/**
 * ユーザー情報
 * @export
 * @interface User
 */
export interface User {
    /**
     * ユーザーID
     * @type {number}
     * @memberof User
     */
    'id': number;
    /**
     * ユーザー名
     * @type {string}
     * @memberof User
     */
    'username': string;
    /**
     * ユーザー表示名
     * @type {string}
     * @memberof User
     */
    'display_name': string;
    /**
     * ユーザー概要
     * @type {string}
     * @memberof User
     */
    'summary': string;
    /**
     * ユーザー詳細説明
     * @type {string}
     * @memberof User
     */
    'description': string;
    /**
     * アバター画像URL
     * @type {string}
     * @memberof User
     */
    'avatar_url': string;
    /**
     * ヘッダー画像URL
     * @type {string}
     * @memberof User
     */
    'header_url': string;
    /**
     * TOP画面スキミーモード
     * @type {boolean}
     * @memberof User
     */
    'is_skimmy_mode': boolean;
    /**
     * DailyMissionsウェジェットチームミッションで赤丸表示を司る
     * @type {boolean}
     * @memberof User
     */
    'is_team_mission_complete': boolean;
    /**
     * DailyMissionsウェジェット個人ミッションで赤丸表示を司る
     * @type {boolean}
     * @memberof User
     */
    'is_personal_mission_complete': boolean;
    /**
     * DailyMissionsウェジェットバッジで赤丸表示を司る
     * @type {boolean}
     * @memberof User
     */
    'is_badges_complete': boolean;
    /**
     * 出席状態
     * @type {number}
     * @memberof User
     */
    'status': number;
    /**
     * 
     * @type {boolean}
     * @memberof User
     */
    'collapsedLeftSidebar': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof User
     */
    'collapsedRightSidebar': boolean;
}
/**
 * ユーザー経験値情報
 * @export
 * @interface UserExp
 */
export interface UserExp {
    /**
     * ユーザーレベル
     * @type {number}
     * @memberof UserExp
     */
    'level': number;
    /**
     * ユーザー経験値
     * @type {number}
     * @memberof UserExp
     */
    'user_exp': number;
    /**
     * 次のレベルまでの必要経験値
     * @type {number}
     * @memberof UserExp
     */
    'exp_to': number;
    /**
     * 現レベルのスタート経験値
     * @type {number}
     * @memberof UserExp
     */
    'exp_from': number;
}
/**
 * ユーザー情報
 * @export
 * @interface UserHour
 */
export interface UserHour {
    /**
     * 
     * @type {User}
     * @memberof UserHour
     */
    'user': User;
    /**
     * 作業時間
     * @type {number}
     * @memberof UserHour
     */
    'hours': number;
}
/**
 * ユーザアイテム情報
 * @export
 * @interface UserItem
 */
export interface UserItem {
    /**
     * コードID
     * @type {number}
     * @memberof UserItem
     */
    'id': number;
    /**
     * アイテムコード
     * @type {string}
     * @memberof UserItem
     */
    'code': string;
    /**
     * 
     * @type {string}
     * @memberof UserItem
     */
    'image': string;
    /**
     * アイテム個数
     * @type {number}
     * @memberof UserItem
     */
    'count': number;
    /**
     * アイテムポイント数
     * @type {number}
     * @memberof UserItem
     */
    'points': number;
    /**
     * アイテムが装備中かのフラグ
     * @type {boolean}
     * @memberof UserItem
     */
    'is_equipped': boolean;
    /**
     * アイテム装備中スキミータイプ
     * @type {number}
     * @memberof UserItem
     */
    'equipped_skimmy_type': number;
    /**
     * アイテム装備中スキミーの名前
     * @type {string}
     * @memberof UserItem
     */
    'equipped_skimmy_name': string;
    /**
     * 
     * @type {EquipmentBattleParameters}
     * @memberof UserItem
     */
    'equipment_parameters': EquipmentBattleParameters;
    /**
     * 武器種類
     * @type {string}
     * @memberof UserItem
     */
    'weapon_type': string;
    /**
     * 開放アイテムフラグ
     * @type {boolean}
     * @memberof UserItem
     */
    'is_unlock': boolean;
    /**
     * アイテムレア度
     * @type {number}
     * @memberof UserItem
     */
    'rarity': number;
}
/**
 * ユーザアイテムリスト情報
 * @export
 * @interface UserItems
 */
export interface UserItems {
    /**
     * 
     * @type {Array<UserItem>}
     * @memberof UserItems
     */
    'user_items': Array<UserItem>;
    /**
     * 
     * @type {PageInfo}
     * @memberof UserItems
     */
    'page_info': PageInfo;
}
/**
 * ユーザー情報
 * @export
 * @interface UserRequest
 */
export interface UserRequest {
    /**
     * ユーザー名
     * @type {string}
     * @memberof UserRequest
     */
    'username'?: string;
    /**
     * ユーザー表示名
     * @type {string}
     * @memberof UserRequest
     */
    'display_name'?: string | null;
    /**
     * ユーザー概要
     * @type {string}
     * @memberof UserRequest
     */
    'summary'?: string;
    /**
     * ユーザー詳細説明
     * @type {string}
     * @memberof UserRequest
     */
    'description'?: string;
    /**
     * アバター画像URL
     * @type {string}
     * @memberof UserRequest
     */
    'avatar_url'?: string | null;
    /**
     * ヘッダー画像URL
     * @type {string}
     * @memberof UserRequest
     */
    'header_url'?: string;
    /**
     * スキミーモードフラグ
     * @type {boolean}
     * @memberof UserRequest
     */
    'is_skimmy_mode'?: boolean;
    /**
     * 出席状態
     * @type {number}
     * @memberof UserRequest
     */
    'status'?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof UserRequest
     */
    'collapsedLeftSidebar'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof UserRequest
     */
    'collapsedRightSidebar'?: boolean | null;
}
/**
 * ユーザーのインテグレーション設定情報
 * @export
 * @interface UserSettingsIntegration
 */
export interface UserSettingsIntegration {
    /**
     * 
     * @type {Array<UserSettingsIntegrationSingle>}
     * @memberof UserSettingsIntegration
     */
    'settings'?: Array<UserSettingsIntegrationSingle>;
}
/**
 * ユーザーのインテグレーション設定情報
 * @export
 * @interface UserSettingsIntegrationReq
 */
export interface UserSettingsIntegrationReq {
    /**
     * 
     * @type {Array<UserSettingsIntegrationReqSingle>}
     * @memberof UserSettingsIntegrationReq
     */
    'settings'?: Array<UserSettingsIntegrationReqSingle>;
}
/**
 * is_valid
 * @export
 * @interface UserSettingsIntegrationReqSingle
 */
export interface UserSettingsIntegrationReqSingle {
    /**
     * 
     * @type {string}
     * @memberof UserSettingsIntegrationReqSingle
     */
    'code': string;
    /**
     * 
     * @type {string}
     * @memberof UserSettingsIntegrationReqSingle
     */
    'access_token': string;
    /**
     * 
     * @type {string}
     * @memberof UserSettingsIntegrationReqSingle
     */
    'token_type': string;
    /**
     * 
     * @type {string}
     * @memberof UserSettingsIntegrationReqSingle
     */
    'authorization_code': string;
    /**
     * 
     * @type {string}
     * @memberof UserSettingsIntegrationReqSingle
     */
    'expiry': string;
}
/**
 * ユーザーのインテグレーション設定情報
 * @export
 * @interface UserSettingsIntegrationSingle
 */
export interface UserSettingsIntegrationSingle {
    /**
     * 
     * @type {string}
     * @memberof UserSettingsIntegrationSingle
     */
    'code': string;
    /**
     * 
     * @type {boolean}
     * @memberof UserSettingsIntegrationSingle
     */
    'is_valid': boolean;
}
/**
 * wiki情報
 * @export
 * @interface Wiki
 */
export interface Wiki {
    /**
     * wikiID
     * @type {number}
     * @memberof Wiki
     */
    'id': number;
    /**
     * 記事ID
     * @type {string}
     * @memberof Wiki
     */
    'articleId': string;
    /**
     * タイトル
     * @type {string}
     * @memberof Wiki
     */
    'subject': string;
    /**
     * wiki内容
     * @type {string}
     * @memberof Wiki
     */
    'description': string;
    /**
     * 
     * @type {string}
     * @memberof Wiki
     */
    'created_on': string;
    /**
     * 
     * @type {Category}
     * @memberof Wiki
     */
    'category'?: Category;
    /**
     * アイキャッチ画像へのURL
     * @type {string}
     * @memberof Wiki
     */
    'eyecatchURL': string;
    /**
     * 
     * @type {boolean}
     * @memberof Wiki
     */
    'is_private': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Wiki
     */
    'made_public'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof Wiki
     */
    'editorVersion'?: number;
    /**
     * 
     * @type {Array<Journal>}
     * @memberof Wiki
     */
    'journals'?: Array<Journal>;
    /**
     * 
     * @type {Array<WikiMeta>}
     * @memberof Wiki
     */
    'wiki_meta'?: Array<WikiMeta>;
    /**
     * 
     * @type {Author}
     * @memberof Wiki
     */
    'author': Author;
}
/**
 * wikiの【いいね】状態の指定
 * @export
 * @interface WikiLike
 */
export interface WikiLike {
    /**
     * 更新する【いいね】の状態
     * @type {boolean}
     * @memberof WikiLike
     */
    'liked': boolean;
}
/**
 * wikimeta情報
 * @export
 * @interface WikiMeta
 */
export interface WikiMeta {
    /**
     * wikimetaID
     * @type {number}
     * @memberof WikiMeta
     */
    'id': number;
    /**
     * redmine team wiki ID
     * @type {number}
     * @memberof WikiMeta
     */
    'redmine_team_wiki_id': number;
    /**
     * redmine meta category ID
     * @type {number}
     * @memberof WikiMeta
     */
    'redmine_meta_category_id': number;
    /**
     * カウント
     * @type {number}
     * @memberof WikiMeta
     */
    'count': number;
    /**
     * 
     * @type {Array<WikiMetaUser>}
     * @memberof WikiMeta
     */
    'users': Array<WikiMetaUser>;
}
/**
 * wikimeta user情報
 * @export
 * @interface WikiMetaUser
 */
export interface WikiMetaUser {
    /**
     * wikimeta userID
     * @type {number}
     * @memberof WikiMetaUser
     */
    'id': number;
    /**
     * ユーザ名
     * @type {string}
     * @memberof WikiMetaUser
     */
    'name': string;
}
/**
 * wiki情報
 * @export
 * @interface WikiRequest
 */
export interface WikiRequest {
    /**
     * タスクID
     * @type {number}
     * @memberof WikiRequest
     */
    'id': number;
    /**
     * タイトル
     * @type {string}
     * @memberof WikiRequest
     */
    'subject': string;
    /**
     * タスク内容
     * @type {string}
     * @memberof WikiRequest
     */
    'description': string;
    /**
     * カテゴリID
     * @type {number}
     * @memberof WikiRequest
     */
    'category_id': number;
    /**
     * アイキャッチ画像へのURL
     * @type {string}
     * @memberof WikiRequest
     */
    'eyecatchURL': string;
    /**
     * 
     * @type {boolean}
     * @memberof WikiRequest
     */
    'is_private': boolean;
    /**
     * 
     * @type {number}
     * @memberof WikiRequest
     */
    'editorVersion'?: number;
}
/**
 * wikiリスト情報
 * @export
 * @interface Wikis
 */
export interface Wikis {
    /**
     * 
     * @type {Array<Wiki>}
     * @memberof Wikis
     */
    'issues': Array<Wiki>;
    /**
     * ヒット件数
     * @type {number}
     * @memberof Wikis
     */
    'total_count': number;
    /**
     * オフセット値
     * @type {number}
     * @memberof Wikis
     */
    'offset': number;
    /**
     * 上限値
     * @type {number}
     * @memberof Wikis
     */
    'limit': number;
}

/**
 * AccessApi - axios parameter creator
 * @export
 */
export const AccessApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * チーム用コンテンツ共有設定を取得します。
         * @summary チーム用コンテンツ共有設定取得
         * @param {number} shareContentType 個人のチーム共有コンテンツタイプ
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getContentShareDataForTeams: async (shareContentType: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'shareContentType' is not null or undefined
            assertParamExists('getContentShareDataForTeams', 'shareContentType', shareContentType)
            const localVarPath = `/access/share/content/team/{share_content_type}`
                .replace(`{${"share_content_type"}}`, encodeURIComponent(String(shareContentType)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * チーム用スキル共有設定を取得します。
         * @summary チーム用スキル共有設定取得
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSkillsShareDataForTeams: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/access/share/skills/team`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * チーム共有アセスメントを取得します。
         * @summary チーム共有アセスメント取得
         * @param {ShareAssessmentTeamMemberRequest} shareAssessmentTeamMemberRequest アセスメント共有チームメンバー情報
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTeamSharedAssessmentForMember: async (shareAssessmentTeamMemberRequest: ShareAssessmentTeamMemberRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'shareAssessmentTeamMemberRequest' is not null or undefined
            assertParamExists('getTeamSharedAssessmentForMember', 'shareAssessmentTeamMemberRequest', shareAssessmentTeamMemberRequest)
            const localVarPath = `/access/share/assessment/team/member`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(shareAssessmentTeamMemberRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * チーム共有ポートフォリオを取得します。
         * @summary チーム共有ポートフォリオ取得
         * @param {SharePortfolioTeamMemberRequest} sharePortfolioTeamMemberRequest ポートフォリオ共有チームメンバー情報
         * @param {number} [page] ページ番号
         * @param {number} [perPage] 1ページあたりの要素数 (MAX100)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTeamSharedPortfoliosForMember: async (sharePortfolioTeamMemberRequest: SharePortfolioTeamMemberRequest, page?: number, perPage?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'sharePortfolioTeamMemberRequest' is not null or undefined
            assertParamExists('getTeamSharedPortfoliosForMember', 'sharePortfolioTeamMemberRequest', sharePortfolioTeamMemberRequest)
            const localVarPath = `/access/share/portfolio/team/member`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (perPage !== undefined) {
                localVarQueryParameter['per_page'] = perPage;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(sharePortfolioTeamMemberRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * チーム共有スキルを取得します。
         * @summary チーム共有スキル取得
         * @param {ShareSkillsTeamMemberRequest} shareSkillsTeamMemberRequest スキル共有チームメンバー情報
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTeamSharedSkillsForMember: async (shareSkillsTeamMemberRequest: ShareSkillsTeamMemberRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'shareSkillsTeamMemberRequest' is not null or undefined
            assertParamExists('getTeamSharedSkillsForMember', 'shareSkillsTeamMemberRequest', shareSkillsTeamMemberRequest)
            const localVarPath = `/access/share/skills/team/member`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(shareSkillsTeamMemberRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * チーム用コンテンツ共有の設定をします。
         * @summary チーム用コンテンツ共有の設定
         * @param {ShareContentTeamRequest} shareContentTeamRequest チーム用コンテンツ共有情報
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setContentShareDataForTeams: async (shareContentTeamRequest: ShareContentTeamRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'shareContentTeamRequest' is not null or undefined
            assertParamExists('setContentShareDataForTeams', 'shareContentTeamRequest', shareContentTeamRequest)
            const localVarPath = `/access/share/content/team/contents`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(shareContentTeamRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * チーム用スキル共有の設定をします。
         * @summary チーム用スキル共有の設定
         * @param {ShareSkillsTeamRequest} shareSkillsTeamRequest チーム用スキル共有情報
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setSkillsShareDataForTeams: async (shareSkillsTeamRequest: ShareSkillsTeamRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'shareSkillsTeamRequest' is not null or undefined
            assertParamExists('setSkillsShareDataForTeams', 'shareSkillsTeamRequest', shareSkillsTeamRequest)
            const localVarPath = `/access/share/skills/team`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(shareSkillsTeamRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AccessApi - functional programming interface
 * @export
 */
export const AccessApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AccessApiAxiosParamCreator(configuration)
    return {
        /**
         * チーム用コンテンツ共有設定を取得します。
         * @summary チーム用コンテンツ共有設定取得
         * @param {number} shareContentType 個人のチーム共有コンテンツタイプ
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getContentShareDataForTeams(shareContentType: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ShareContentTeamResp>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getContentShareDataForTeams(shareContentType, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * チーム用スキル共有設定を取得します。
         * @summary チーム用スキル共有設定取得
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSkillsShareDataForTeams(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ShareSkillsTeamResp>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSkillsShareDataForTeams(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * チーム共有アセスメントを取得します。
         * @summary チーム共有アセスメント取得
         * @param {ShareAssessmentTeamMemberRequest} shareAssessmentTeamMemberRequest アセスメント共有チームメンバー情報
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTeamSharedAssessmentForMember(shareAssessmentTeamMemberRequest: ShareAssessmentTeamMemberRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SharedAssessment>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTeamSharedAssessmentForMember(shareAssessmentTeamMemberRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * チーム共有ポートフォリオを取得します。
         * @summary チーム共有ポートフォリオ取得
         * @param {SharePortfolioTeamMemberRequest} sharePortfolioTeamMemberRequest ポートフォリオ共有チームメンバー情報
         * @param {number} [page] ページ番号
         * @param {number} [perPage] 1ページあたりの要素数 (MAX100)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTeamSharedPortfoliosForMember(sharePortfolioTeamMemberRequest: SharePortfolioTeamMemberRequest, page?: number, perPage?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SharedPortfolios>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTeamSharedPortfoliosForMember(sharePortfolioTeamMemberRequest, page, perPage, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * チーム共有スキルを取得します。
         * @summary チーム共有スキル取得
         * @param {ShareSkillsTeamMemberRequest} shareSkillsTeamMemberRequest スキル共有チームメンバー情報
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTeamSharedSkillsForMember(shareSkillsTeamMemberRequest: ShareSkillsTeamMemberRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SharedSkills>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTeamSharedSkillsForMember(shareSkillsTeamMemberRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * チーム用コンテンツ共有の設定をします。
         * @summary チーム用コンテンツ共有の設定
         * @param {ShareContentTeamRequest} shareContentTeamRequest チーム用コンテンツ共有情報
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async setContentShareDataForTeams(shareContentTeamRequest: ShareContentTeamRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.setContentShareDataForTeams(shareContentTeamRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * チーム用スキル共有の設定をします。
         * @summary チーム用スキル共有の設定
         * @param {ShareSkillsTeamRequest} shareSkillsTeamRequest チーム用スキル共有情報
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async setSkillsShareDataForTeams(shareSkillsTeamRequest: ShareSkillsTeamRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.setSkillsShareDataForTeams(shareSkillsTeamRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AccessApi - factory interface
 * @export
 */
export const AccessApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AccessApiFp(configuration)
    return {
        /**
         * チーム用コンテンツ共有設定を取得します。
         * @summary チーム用コンテンツ共有設定取得
         * @param {number} shareContentType 個人のチーム共有コンテンツタイプ
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getContentShareDataForTeams(shareContentType: number, options?: any): AxiosPromise<ShareContentTeamResp> {
            return localVarFp.getContentShareDataForTeams(shareContentType, options).then((request) => request(axios, basePath));
        },
        /**
         * チーム用スキル共有設定を取得します。
         * @summary チーム用スキル共有設定取得
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSkillsShareDataForTeams(options?: any): AxiosPromise<ShareSkillsTeamResp> {
            return localVarFp.getSkillsShareDataForTeams(options).then((request) => request(axios, basePath));
        },
        /**
         * チーム共有アセスメントを取得します。
         * @summary チーム共有アセスメント取得
         * @param {ShareAssessmentTeamMemberRequest} shareAssessmentTeamMemberRequest アセスメント共有チームメンバー情報
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTeamSharedAssessmentForMember(shareAssessmentTeamMemberRequest: ShareAssessmentTeamMemberRequest, options?: any): AxiosPromise<SharedAssessment> {
            return localVarFp.getTeamSharedAssessmentForMember(shareAssessmentTeamMemberRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * チーム共有ポートフォリオを取得します。
         * @summary チーム共有ポートフォリオ取得
         * @param {SharePortfolioTeamMemberRequest} sharePortfolioTeamMemberRequest ポートフォリオ共有チームメンバー情報
         * @param {number} [page] ページ番号
         * @param {number} [perPage] 1ページあたりの要素数 (MAX100)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTeamSharedPortfoliosForMember(sharePortfolioTeamMemberRequest: SharePortfolioTeamMemberRequest, page?: number, perPage?: number, options?: any): AxiosPromise<SharedPortfolios> {
            return localVarFp.getTeamSharedPortfoliosForMember(sharePortfolioTeamMemberRequest, page, perPage, options).then((request) => request(axios, basePath));
        },
        /**
         * チーム共有スキルを取得します。
         * @summary チーム共有スキル取得
         * @param {ShareSkillsTeamMemberRequest} shareSkillsTeamMemberRequest スキル共有チームメンバー情報
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTeamSharedSkillsForMember(shareSkillsTeamMemberRequest: ShareSkillsTeamMemberRequest, options?: any): AxiosPromise<SharedSkills> {
            return localVarFp.getTeamSharedSkillsForMember(shareSkillsTeamMemberRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * チーム用コンテンツ共有の設定をします。
         * @summary チーム用コンテンツ共有の設定
         * @param {ShareContentTeamRequest} shareContentTeamRequest チーム用コンテンツ共有情報
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setContentShareDataForTeams(shareContentTeamRequest: ShareContentTeamRequest, options?: any): AxiosPromise<void> {
            return localVarFp.setContentShareDataForTeams(shareContentTeamRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * チーム用スキル共有の設定をします。
         * @summary チーム用スキル共有の設定
         * @param {ShareSkillsTeamRequest} shareSkillsTeamRequest チーム用スキル共有情報
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setSkillsShareDataForTeams(shareSkillsTeamRequest: ShareSkillsTeamRequest, options?: any): AxiosPromise<void> {
            return localVarFp.setSkillsShareDataForTeams(shareSkillsTeamRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AccessApi - object-oriented interface
 * @export
 * @class AccessApi
 * @extends {BaseAPI}
 */
export class AccessApi extends BaseAPI {
    /**
     * チーム用コンテンツ共有設定を取得します。
     * @summary チーム用コンテンツ共有設定取得
     * @param {number} shareContentType 個人のチーム共有コンテンツタイプ
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccessApi
     */
    public async getContentShareDataForTeams(shareContentType: number, options?: AxiosRequestConfig) {
        const retry = 2;
        let resp;
        for (let i = 0; i < retry; i++) {
            resp = await AccessApiFp(this.configuration).getContentShareDataForTeams(shareContentType, options).then((request) => request(this.axios, this.basePath)).catch((error) => {
                if (error?.response) {
                    return error.response;
                } else {
                    return { status: 500 };
                }
            });
            if (resp?.status === 401) {
                await refreshIdToken();
                console.debug('Retry getContentShareDataForTeams ' + i + '/' + (retry - 1));
                continue;
            }
            break;
        }
        return resp;
    }

    /**
     * チーム用スキル共有設定を取得します。
     * @summary チーム用スキル共有設定取得
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccessApi
     */
    public async getSkillsShareDataForTeams(options?: AxiosRequestConfig) {
        const retry = 2;
        let resp;
        for (let i = 0; i < retry; i++) {
            resp = await AccessApiFp(this.configuration).getSkillsShareDataForTeams(options).then((request) => request(this.axios, this.basePath)).catch((error) => {
                if (error?.response) {
                    return error.response;
                } else {
                    return { status: 500 };
                }
            });
            if (resp?.status === 401) {
                await refreshIdToken();
                console.debug('Retry getSkillsShareDataForTeams ' + i + '/' + (retry - 1));
                continue;
            }
            break;
        }
        return resp;
    }

    /**
     * チーム共有アセスメントを取得します。
     * @summary チーム共有アセスメント取得
     * @param {ShareAssessmentTeamMemberRequest} shareAssessmentTeamMemberRequest アセスメント共有チームメンバー情報
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccessApi
     */
    public async getTeamSharedAssessmentForMember(shareAssessmentTeamMemberRequest: ShareAssessmentTeamMemberRequest, options?: AxiosRequestConfig) {
        const retry = 2;
        let resp;
        for (let i = 0; i < retry; i++) {
            resp = await AccessApiFp(this.configuration).getTeamSharedAssessmentForMember(shareAssessmentTeamMemberRequest, options).then((request) => request(this.axios, this.basePath)).catch((error) => {
                if (error?.response) {
                    return error.response;
                } else {
                    return { status: 500 };
                }
            });
            if (resp?.status === 401) {
                await refreshIdToken();
                console.debug('Retry getTeamSharedAssessmentForMember ' + i + '/' + (retry - 1));
                continue;
            }
            break;
        }
        return resp;
    }

    /**
     * チーム共有ポートフォリオを取得します。
     * @summary チーム共有ポートフォリオ取得
     * @param {SharePortfolioTeamMemberRequest} sharePortfolioTeamMemberRequest ポートフォリオ共有チームメンバー情報
     * @param {number} [page] ページ番号
     * @param {number} [perPage] 1ページあたりの要素数 (MAX100)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccessApi
     */
    public async getTeamSharedPortfoliosForMember(sharePortfolioTeamMemberRequest: SharePortfolioTeamMemberRequest, page?: number, perPage?: number, options?: AxiosRequestConfig) {
        const retry = 2;
        let resp;
        for (let i = 0; i < retry; i++) {
            resp = await AccessApiFp(this.configuration).getTeamSharedPortfoliosForMember(sharePortfolioTeamMemberRequest, page, perPage, options).then((request) => request(this.axios, this.basePath)).catch((error) => {
                if (error?.response) {
                    return error.response;
                } else {
                    return { status: 500 };
                }
            });
            if (resp?.status === 401) {
                await refreshIdToken();
                console.debug('Retry getTeamSharedPortfoliosForMember ' + i + '/' + (retry - 1));
                continue;
            }
            break;
        }
        return resp;
    }

    /**
     * チーム共有スキルを取得します。
     * @summary チーム共有スキル取得
     * @param {ShareSkillsTeamMemberRequest} shareSkillsTeamMemberRequest スキル共有チームメンバー情報
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccessApi
     */
    public async getTeamSharedSkillsForMember(shareSkillsTeamMemberRequest: ShareSkillsTeamMemberRequest, options?: AxiosRequestConfig) {
        const retry = 2;
        let resp;
        for (let i = 0; i < retry; i++) {
            resp = await AccessApiFp(this.configuration).getTeamSharedSkillsForMember(shareSkillsTeamMemberRequest, options).then((request) => request(this.axios, this.basePath)).catch((error) => {
                if (error?.response) {
                    return error.response;
                } else {
                    return { status: 500 };
                }
            });
            if (resp?.status === 401) {
                await refreshIdToken();
                console.debug('Retry getTeamSharedSkillsForMember ' + i + '/' + (retry - 1));
                continue;
            }
            break;
        }
        return resp;
    }

    /**
     * チーム用コンテンツ共有の設定をします。
     * @summary チーム用コンテンツ共有の設定
     * @param {ShareContentTeamRequest} shareContentTeamRequest チーム用コンテンツ共有情報
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccessApi
     */
    public async setContentShareDataForTeams(shareContentTeamRequest: ShareContentTeamRequest, options?: AxiosRequestConfig) {
        const retry = 2;
        let resp;
        for (let i = 0; i < retry; i++) {
            resp = await AccessApiFp(this.configuration).setContentShareDataForTeams(shareContentTeamRequest, options).then((request) => request(this.axios, this.basePath)).catch((error) => {
                if (error?.response) {
                    return error.response;
                } else {
                    return { status: 500 };
                }
            });
            if (resp?.status === 401) {
                await refreshIdToken();
                console.debug('Retry setContentShareDataForTeams ' + i + '/' + (retry - 1));
                continue;
            }
            break;
        }
        return resp;
    }

    /**
     * チーム用スキル共有の設定をします。
     * @summary チーム用スキル共有の設定
     * @param {ShareSkillsTeamRequest} shareSkillsTeamRequest チーム用スキル共有情報
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccessApi
     */
    public async setSkillsShareDataForTeams(shareSkillsTeamRequest: ShareSkillsTeamRequest, options?: AxiosRequestConfig) {
        const retry = 2;
        let resp;
        for (let i = 0; i < retry; i++) {
            resp = await AccessApiFp(this.configuration).setSkillsShareDataForTeams(shareSkillsTeamRequest, options).then((request) => request(this.axios, this.basePath)).catch((error) => {
                if (error?.response) {
                    return error.response;
                } else {
                    return { status: 500 };
                }
            });
            if (resp?.status === 401) {
                await refreshIdToken();
                console.debug('Retry setSkillsShareDataForTeams ' + i + '/' + (retry - 1));
                continue;
            }
            break;
        }
        return resp;
    }
}


/**
 * AssessmentApi - axios parameter creator
 * @export
 */
export const AssessmentApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * リアクションリストを取得します。
         * @summary リアクション取得
         * @param {string} teamname チーム名
         * @param {string} username ユーザ名
         * @param {ActivityRequest} activityRequest リアクション情報
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getActivityActions: async (teamname: string, username: string, activityRequest: ActivityRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'teamname' is not null or undefined
            assertParamExists('getActivityActions', 'teamname', teamname)
            // verify required parameter 'username' is not null or undefined
            assertParamExists('getActivityActions', 'username', username)
            // verify required parameter 'activityRequest' is not null or undefined
            assertParamExists('getActivityActions', 'activityRequest', activityRequest)
            const localVarPath = `/assessment/activity/{teamname}/{username}`
                .replace(`{${"teamname"}}`, encodeURIComponent(String(teamname)))
                .replace(`{${"username"}}`, encodeURIComponent(String(username)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(activityRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 分析フォームを取得します。
         * @summary 分析フォームの取得
         * @param {string} teamname チーム名
         * @param {string} username ユーザ名
         * @param {AnalysesFormRequest} analysesFormRequest 分析フォーム情報
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCurrentAnalysesForm: async (teamname: string, username: string, analysesFormRequest: AnalysesFormRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'teamname' is not null or undefined
            assertParamExists('getCurrentAnalysesForm', 'teamname', teamname)
            // verify required parameter 'username' is not null or undefined
            assertParamExists('getCurrentAnalysesForm', 'username', username)
            // verify required parameter 'analysesFormRequest' is not null or undefined
            assertParamExists('getCurrentAnalysesForm', 'analysesFormRequest', analysesFormRequest)
            const localVarPath = `/assessment/analyses/form/{teamname}/{username}`
                .replace(`{${"teamname"}}`, encodeURIComponent(String(teamname)))
                .replace(`{${"username"}}`, encodeURIComponent(String(username)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(analysesFormRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 過去分析フォームを取得します。
         * @summary 過去分析フォームの取得
         * @param {string} teamname チーム名
         * @param {string} username ユーザ名
         * @param {AnalysesFormPastRequest} analysesFormPastRequest 過去分析フォーム情報
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPastAnalysesForm: async (teamname: string, username: string, analysesFormPastRequest: AnalysesFormPastRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'teamname' is not null or undefined
            assertParamExists('getPastAnalysesForm', 'teamname', teamname)
            // verify required parameter 'username' is not null or undefined
            assertParamExists('getPastAnalysesForm', 'username', username)
            // verify required parameter 'analysesFormPastRequest' is not null or undefined
            assertParamExists('getPastAnalysesForm', 'analysesFormPastRequest', analysesFormPastRequest)
            const localVarPath = `/assessment/analyses/form/past/{teamname}/{username}`
                .replace(`{${"teamname"}}`, encodeURIComponent(String(teamname)))
                .replace(`{${"username"}}`, encodeURIComponent(String(username)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(analysesFormPastRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 全過去分析フォームを取得します。
         * @summary 全過去分析フォームの取得
         * @param {string} teamname チーム名
         * @param {string} username ユーザ名
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPastAnalysesForms: async (teamname: string, username: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'teamname' is not null or undefined
            assertParamExists('getPastAnalysesForms', 'teamname', teamname)
            // verify required parameter 'username' is not null or undefined
            assertParamExists('getPastAnalysesForms', 'username', username)
            const localVarPath = `/assessment/analyses/form/past/{teamname}/{username}`
                .replace(`{${"teamname"}}`, encodeURIComponent(String(teamname)))
                .replace(`{${"username"}}`, encodeURIComponent(String(username)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 分析フォームの公開状況を設定します。
         * @summary 分析フォームの公開状況設定
         * @param {string} teamname チーム名
         * @param {AssessmentAnalysesFormOpenRequest} assessmentAnalysesFormOpenRequest 分析フォーム公開情報
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setAnalysesFormOpenSetting: async (teamname: string, assessmentAnalysesFormOpenRequest: AssessmentAnalysesFormOpenRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'teamname' is not null or undefined
            assertParamExists('setAnalysesFormOpenSetting', 'teamname', teamname)
            // verify required parameter 'assessmentAnalysesFormOpenRequest' is not null or undefined
            assertParamExists('setAnalysesFormOpenSetting', 'assessmentAnalysesFormOpenRequest', assessmentAnalysesFormOpenRequest)
            const localVarPath = `/assessment/analyses/form/open/{teamname}`
                .replace(`{${"teamname"}}`, encodeURIComponent(String(teamname)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(assessmentAnalysesFormOpenRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 自己評価フォーマットの設定をします。
         * @summary 自己評価フォーマットの設定
         * @param {string} teamname チーム名
         * @param {AnalysesFormatRequest} analysesFormatRequest 自己評価フォーマット情報
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setAnalysesFormat: async (teamname: string, analysesFormatRequest: AnalysesFormatRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'teamname' is not null or undefined
            assertParamExists('setAnalysesFormat', 'teamname', teamname)
            // verify required parameter 'analysesFormatRequest' is not null or undefined
            assertParamExists('setAnalysesFormat', 'analysesFormatRequest', analysesFormatRequest)
            const localVarPath = `/assessment/analyses/format/{teamname}`
                .replace(`{${"teamname"}}`, encodeURIComponent(String(teamname)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(analysesFormatRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 分析フォーム入力値の設定をします。
         * @summary 分析フォーム入力値の設定
         * @param {string} teamname チーム名
         * @param {string} username ユーザ名
         * @param {AnalysesFormMemberRequest} analysesFormMemberRequest 分析フォーム入力値情報
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setMemberAnalyses: async (teamname: string, username: string, analysesFormMemberRequest: AnalysesFormMemberRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'teamname' is not null or undefined
            assertParamExists('setMemberAnalyses', 'teamname', teamname)
            // verify required parameter 'username' is not null or undefined
            assertParamExists('setMemberAnalyses', 'username', username)
            // verify required parameter 'analysesFormMemberRequest' is not null or undefined
            assertParamExists('setMemberAnalyses', 'analysesFormMemberRequest', analysesFormMemberRequest)
            const localVarPath = `/assessment/analyses/form/member/{teamname}/{username}`
                .replace(`{${"teamname"}}`, encodeURIComponent(String(teamname)))
                .replace(`{${"username"}}`, encodeURIComponent(String(username)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(analysesFormMemberRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AssessmentApi - functional programming interface
 * @export
 */
export const AssessmentApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AssessmentApiAxiosParamCreator(configuration)
    return {
        /**
         * リアクションリストを取得します。
         * @summary リアクション取得
         * @param {string} teamname チーム名
         * @param {string} username ユーザ名
         * @param {ActivityRequest} activityRequest リアクション情報
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getActivityActions(teamname: string, username: string, activityRequest: ActivityRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Actions>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getActivityActions(teamname, username, activityRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 分析フォームを取得します。
         * @summary 分析フォームの取得
         * @param {string} teamname チーム名
         * @param {string} username ユーザ名
         * @param {AnalysesFormRequest} analysesFormRequest 分析フォーム情報
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCurrentAnalysesForm(teamname: string, username: string, analysesFormRequest: AnalysesFormRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AnalysesForm>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCurrentAnalysesForm(teamname, username, analysesFormRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 過去分析フォームを取得します。
         * @summary 過去分析フォームの取得
         * @param {string} teamname チーム名
         * @param {string} username ユーザ名
         * @param {AnalysesFormPastRequest} analysesFormPastRequest 過去分析フォーム情報
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPastAnalysesForm(teamname: string, username: string, analysesFormPastRequest: AnalysesFormPastRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AnalysesForm>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPastAnalysesForm(teamname, username, analysesFormPastRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 全過去分析フォームを取得します。
         * @summary 全過去分析フォームの取得
         * @param {string} teamname チーム名
         * @param {string} username ユーザ名
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPastAnalysesForms(teamname: string, username: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AnalysesFormsPast>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPastAnalysesForms(teamname, username, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 分析フォームの公開状況を設定します。
         * @summary 分析フォームの公開状況設定
         * @param {string} teamname チーム名
         * @param {AssessmentAnalysesFormOpenRequest} assessmentAnalysesFormOpenRequest 分析フォーム公開情報
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async setAnalysesFormOpenSetting(teamname: string, assessmentAnalysesFormOpenRequest: AssessmentAnalysesFormOpenRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.setAnalysesFormOpenSetting(teamname, assessmentAnalysesFormOpenRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 自己評価フォーマットの設定をします。
         * @summary 自己評価フォーマットの設定
         * @param {string} teamname チーム名
         * @param {AnalysesFormatRequest} analysesFormatRequest 自己評価フォーマット情報
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async setAnalysesFormat(teamname: string, analysesFormatRequest: AnalysesFormatRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.setAnalysesFormat(teamname, analysesFormatRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 分析フォーム入力値の設定をします。
         * @summary 分析フォーム入力値の設定
         * @param {string} teamname チーム名
         * @param {string} username ユーザ名
         * @param {AnalysesFormMemberRequest} analysesFormMemberRequest 分析フォーム入力値情報
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async setMemberAnalyses(teamname: string, username: string, analysesFormMemberRequest: AnalysesFormMemberRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.setMemberAnalyses(teamname, username, analysesFormMemberRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AssessmentApi - factory interface
 * @export
 */
export const AssessmentApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AssessmentApiFp(configuration)
    return {
        /**
         * リアクションリストを取得します。
         * @summary リアクション取得
         * @param {string} teamname チーム名
         * @param {string} username ユーザ名
         * @param {ActivityRequest} activityRequest リアクション情報
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getActivityActions(teamname: string, username: string, activityRequest: ActivityRequest, options?: any): AxiosPromise<Actions> {
            return localVarFp.getActivityActions(teamname, username, activityRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 分析フォームを取得します。
         * @summary 分析フォームの取得
         * @param {string} teamname チーム名
         * @param {string} username ユーザ名
         * @param {AnalysesFormRequest} analysesFormRequest 分析フォーム情報
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCurrentAnalysesForm(teamname: string, username: string, analysesFormRequest: AnalysesFormRequest, options?: any): AxiosPromise<AnalysesForm> {
            return localVarFp.getCurrentAnalysesForm(teamname, username, analysesFormRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 過去分析フォームを取得します。
         * @summary 過去分析フォームの取得
         * @param {string} teamname チーム名
         * @param {string} username ユーザ名
         * @param {AnalysesFormPastRequest} analysesFormPastRequest 過去分析フォーム情報
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPastAnalysesForm(teamname: string, username: string, analysesFormPastRequest: AnalysesFormPastRequest, options?: any): AxiosPromise<AnalysesForm> {
            return localVarFp.getPastAnalysesForm(teamname, username, analysesFormPastRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 全過去分析フォームを取得します。
         * @summary 全過去分析フォームの取得
         * @param {string} teamname チーム名
         * @param {string} username ユーザ名
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPastAnalysesForms(teamname: string, username: string, options?: any): AxiosPromise<AnalysesFormsPast> {
            return localVarFp.getPastAnalysesForms(teamname, username, options).then((request) => request(axios, basePath));
        },
        /**
         * 分析フォームの公開状況を設定します。
         * @summary 分析フォームの公開状況設定
         * @param {string} teamname チーム名
         * @param {AssessmentAnalysesFormOpenRequest} assessmentAnalysesFormOpenRequest 分析フォーム公開情報
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setAnalysesFormOpenSetting(teamname: string, assessmentAnalysesFormOpenRequest: AssessmentAnalysesFormOpenRequest, options?: any): AxiosPromise<void> {
            return localVarFp.setAnalysesFormOpenSetting(teamname, assessmentAnalysesFormOpenRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 自己評価フォーマットの設定をします。
         * @summary 自己評価フォーマットの設定
         * @param {string} teamname チーム名
         * @param {AnalysesFormatRequest} analysesFormatRequest 自己評価フォーマット情報
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setAnalysesFormat(teamname: string, analysesFormatRequest: AnalysesFormatRequest, options?: any): AxiosPromise<void> {
            return localVarFp.setAnalysesFormat(teamname, analysesFormatRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 分析フォーム入力値の設定をします。
         * @summary 分析フォーム入力値の設定
         * @param {string} teamname チーム名
         * @param {string} username ユーザ名
         * @param {AnalysesFormMemberRequest} analysesFormMemberRequest 分析フォーム入力値情報
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setMemberAnalyses(teamname: string, username: string, analysesFormMemberRequest: AnalysesFormMemberRequest, options?: any): AxiosPromise<void> {
            return localVarFp.setMemberAnalyses(teamname, username, analysesFormMemberRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AssessmentApi - object-oriented interface
 * @export
 * @class AssessmentApi
 * @extends {BaseAPI}
 */
export class AssessmentApi extends BaseAPI {
    /**
     * リアクションリストを取得します。
     * @summary リアクション取得
     * @param {string} teamname チーム名
     * @param {string} username ユーザ名
     * @param {ActivityRequest} activityRequest リアクション情報
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AssessmentApi
     */
    public async getActivityActions(teamname: string, username: string, activityRequest: ActivityRequest, options?: AxiosRequestConfig) {
        const retry = 2;
        let resp;
        for (let i = 0; i < retry; i++) {
            resp = await AssessmentApiFp(this.configuration).getActivityActions(teamname, username, activityRequest, options).then((request) => request(this.axios, this.basePath)).catch((error) => {
                if (error?.response) {
                    return error.response;
                } else {
                    return { status: 500 };
                }
            });
            if (resp?.status === 401) {
                await refreshIdToken();
                console.debug('Retry getActivityActions ' + i + '/' + (retry - 1));
                continue;
            }
            break;
        }
        return resp;
    }

    /**
     * 分析フォームを取得します。
     * @summary 分析フォームの取得
     * @param {string} teamname チーム名
     * @param {string} username ユーザ名
     * @param {AnalysesFormRequest} analysesFormRequest 分析フォーム情報
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AssessmentApi
     */
    public async getCurrentAnalysesForm(teamname: string, username: string, analysesFormRequest: AnalysesFormRequest, options?: AxiosRequestConfig) {
        const retry = 2;
        let resp;
        for (let i = 0; i < retry; i++) {
            resp = await AssessmentApiFp(this.configuration).getCurrentAnalysesForm(teamname, username, analysesFormRequest, options).then((request) => request(this.axios, this.basePath)).catch((error) => {
                if (error?.response) {
                    return error.response;
                } else {
                    return { status: 500 };
                }
            });
            if (resp?.status === 401) {
                await refreshIdToken();
                console.debug('Retry getCurrentAnalysesForm ' + i + '/' + (retry - 1));
                continue;
            }
            break;
        }
        return resp;
    }

    /**
     * 過去分析フォームを取得します。
     * @summary 過去分析フォームの取得
     * @param {string} teamname チーム名
     * @param {string} username ユーザ名
     * @param {AnalysesFormPastRequest} analysesFormPastRequest 過去分析フォーム情報
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AssessmentApi
     */
    public async getPastAnalysesForm(teamname: string, username: string, analysesFormPastRequest: AnalysesFormPastRequest, options?: AxiosRequestConfig) {
        const retry = 2;
        let resp;
        for (let i = 0; i < retry; i++) {
            resp = await AssessmentApiFp(this.configuration).getPastAnalysesForm(teamname, username, analysesFormPastRequest, options).then((request) => request(this.axios, this.basePath)).catch((error) => {
                if (error?.response) {
                    return error.response;
                } else {
                    return { status: 500 };
                }
            });
            if (resp?.status === 401) {
                await refreshIdToken();
                console.debug('Retry getPastAnalysesForm ' + i + '/' + (retry - 1));
                continue;
            }
            break;
        }
        return resp;
    }

    /**
     * 全過去分析フォームを取得します。
     * @summary 全過去分析フォームの取得
     * @param {string} teamname チーム名
     * @param {string} username ユーザ名
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AssessmentApi
     */
    public async getPastAnalysesForms(teamname: string, username: string, options?: AxiosRequestConfig) {
        const retry = 2;
        let resp;
        for (let i = 0; i < retry; i++) {
            resp = await AssessmentApiFp(this.configuration).getPastAnalysesForms(teamname, username, options).then((request) => request(this.axios, this.basePath)).catch((error) => {
                if (error?.response) {
                    return error.response;
                } else {
                    return { status: 500 };
                }
            });
            if (resp?.status === 401) {
                await refreshIdToken();
                console.debug('Retry getPastAnalysesForms ' + i + '/' + (retry - 1));
                continue;
            }
            break;
        }
        return resp;
    }

    /**
     * 分析フォームの公開状況を設定します。
     * @summary 分析フォームの公開状況設定
     * @param {string} teamname チーム名
     * @param {AssessmentAnalysesFormOpenRequest} assessmentAnalysesFormOpenRequest 分析フォーム公開情報
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AssessmentApi
     */
    public async setAnalysesFormOpenSetting(teamname: string, assessmentAnalysesFormOpenRequest: AssessmentAnalysesFormOpenRequest, options?: AxiosRequestConfig) {
        const retry = 2;
        let resp;
        for (let i = 0; i < retry; i++) {
            resp = await AssessmentApiFp(this.configuration).setAnalysesFormOpenSetting(teamname, assessmentAnalysesFormOpenRequest, options).then((request) => request(this.axios, this.basePath)).catch((error) => {
                if (error?.response) {
                    return error.response;
                } else {
                    return { status: 500 };
                }
            });
            if (resp?.status === 401) {
                await refreshIdToken();
                console.debug('Retry setAnalysesFormOpenSetting ' + i + '/' + (retry - 1));
                continue;
            }
            break;
        }
        return resp;
    }

    /**
     * 自己評価フォーマットの設定をします。
     * @summary 自己評価フォーマットの設定
     * @param {string} teamname チーム名
     * @param {AnalysesFormatRequest} analysesFormatRequest 自己評価フォーマット情報
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AssessmentApi
     */
    public async setAnalysesFormat(teamname: string, analysesFormatRequest: AnalysesFormatRequest, options?: AxiosRequestConfig) {
        const retry = 2;
        let resp;
        for (let i = 0; i < retry; i++) {
            resp = await AssessmentApiFp(this.configuration).setAnalysesFormat(teamname, analysesFormatRequest, options).then((request) => request(this.axios, this.basePath)).catch((error) => {
                if (error?.response) {
                    return error.response;
                } else {
                    return { status: 500 };
                }
            });
            if (resp?.status === 401) {
                await refreshIdToken();
                console.debug('Retry setAnalysesFormat ' + i + '/' + (retry - 1));
                continue;
            }
            break;
        }
        return resp;
    }

    /**
     * 分析フォーム入力値の設定をします。
     * @summary 分析フォーム入力値の設定
     * @param {string} teamname チーム名
     * @param {string} username ユーザ名
     * @param {AnalysesFormMemberRequest} analysesFormMemberRequest 分析フォーム入力値情報
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AssessmentApi
     */
    public async setMemberAnalyses(teamname: string, username: string, analysesFormMemberRequest: AnalysesFormMemberRequest, options?: AxiosRequestConfig) {
        const retry = 2;
        let resp;
        for (let i = 0; i < retry; i++) {
            resp = await AssessmentApiFp(this.configuration).setMemberAnalyses(teamname, username, analysesFormMemberRequest, options).then((request) => request(this.axios, this.basePath)).catch((error) => {
                if (error?.response) {
                    return error.response;
                } else {
                    return { status: 500 };
                }
            });
            if (resp?.status === 401) {
                await refreshIdToken();
                console.debug('Retry setMemberAnalyses ' + i + '/' + (retry - 1));
                continue;
            }
            break;
        }
        return resp;
    }
}


/**
 * BadgeApi - axios parameter creator
 * @export
 */
export const BadgeApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 取得済みのバッジリストを取得します。
         * @summary 取得済みバッジリストの取得
         * @param {string} username ユーザ名
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findHavingBadges: async (username: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'username' is not null or undefined
            assertParamExists('findHavingBadges', 'username', username)
            const localVarPath = `/badges/{username}`
                .replace(`{${"username"}}`, encodeURIComponent(String(username)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * BadgeApi - functional programming interface
 * @export
 */
export const BadgeApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = BadgeApiAxiosParamCreator(configuration)
    return {
        /**
         * 取得済みのバッジリストを取得します。
         * @summary 取得済みバッジリストの取得
         * @param {string} username ユーザ名
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findHavingBadges(username: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Badges>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findHavingBadges(username, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * BadgeApi - factory interface
 * @export
 */
export const BadgeApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = BadgeApiFp(configuration)
    return {
        /**
         * 取得済みのバッジリストを取得します。
         * @summary 取得済みバッジリストの取得
         * @param {string} username ユーザ名
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findHavingBadges(username: string, options?: any): AxiosPromise<Badges> {
            return localVarFp.findHavingBadges(username, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * BadgeApi - object-oriented interface
 * @export
 * @class BadgeApi
 * @extends {BaseAPI}
 */
export class BadgeApi extends BaseAPI {
    /**
     * 取得済みのバッジリストを取得します。
     * @summary 取得済みバッジリストの取得
     * @param {string} username ユーザ名
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BadgeApi
     */
    public async findHavingBadges(username: string, options?: AxiosRequestConfig) {
        const retry = 2;
        let resp;
        for (let i = 0; i < retry; i++) {
            resp = await BadgeApiFp(this.configuration).findHavingBadges(username, options).then((request) => request(this.axios, this.basePath)).catch((error) => {
                if (error?.response) {
                    return error.response;
                } else {
                    return { status: 500 };
                }
            });
            if (resp?.status === 401) {
                await refreshIdToken();
                console.debug('Retry findHavingBadges ' + i + '/' + (retry - 1));
                continue;
            }
            break;
        }
        return resp;
    }
}


/**
 * BattleApi - axios parameter creator
 * @export
 */
export const BattleApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * バトルを実施します。
         * @summary バトル実施
         * @param {BattleInitiationReq} battleInitiationReq バトル実施情報
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        doBattle: async (battleInitiationReq: BattleInitiationReq, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'battleInitiationReq' is not null or undefined
            assertParamExists('doBattle', 'battleInitiationReq', battleInitiationReq)
            const localVarPath = `/battle/initiate`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(battleInitiationReq, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * バトルパーティ一覧を取得します。
         * @summary バトルパーティ一覧取得
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBattleParties: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/battle/parties`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * バトル種類一覧を取得します。
         * @summary バトル種類一覧取得
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBattleTypes: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/battles/types`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * バトルを取得します。
         * @summary バトル取得
         * @param {BattleReq} battleReq バトル情報
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBattles: async (battleReq: BattleReq, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'battleReq' is not null or undefined
            assertParamExists('getBattles', 'battleReq', battleReq)
            const localVarPath = `/battles/type`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(battleReq, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * バトルチケットを購入します。
         * @summary バトルチケット購入
         * @param {BattleTicketsPurchaseReq} battleTicketsPurchaseReq バトルチケット購入情報
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        purchaseBattleTickets: async (battleTicketsPurchaseReq: BattleTicketsPurchaseReq, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'battleTicketsPurchaseReq' is not null or undefined
            assertParamExists('purchaseBattleTickets', 'battleTicketsPurchaseReq', battleTicketsPurchaseReq)
            const localVarPath = `/battle/battle_tickets`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(battleTicketsPurchaseReq, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * BattleApi - functional programming interface
 * @export
 */
export const BattleApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = BattleApiAxiosParamCreator(configuration)
    return {
        /**
         * バトルを実施します。
         * @summary バトル実施
         * @param {BattleInitiationReq} battleInitiationReq バトル実施情報
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async doBattle(battleInitiationReq: BattleInitiationReq, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BattleResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.doBattle(battleInitiationReq, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * バトルパーティ一覧を取得します。
         * @summary バトルパーティ一覧取得
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getBattleParties(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PartiesResp>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getBattleParties(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * バトル種類一覧を取得します。
         * @summary バトル種類一覧取得
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getBattleTypes(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BattleTypes>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getBattleTypes(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * バトルを取得します。
         * @summary バトル取得
         * @param {BattleReq} battleReq バトル情報
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getBattles(battleReq: BattleReq, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Battles>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getBattles(battleReq, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * バトルチケットを購入します。
         * @summary バトルチケット購入
         * @param {BattleTicketsPurchaseReq} battleTicketsPurchaseReq バトルチケット購入情報
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async purchaseBattleTickets(battleTicketsPurchaseReq: BattleTicketsPurchaseReq, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Resources>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.purchaseBattleTickets(battleTicketsPurchaseReq, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * BattleApi - factory interface
 * @export
 */
export const BattleApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = BattleApiFp(configuration)
    return {
        /**
         * バトルを実施します。
         * @summary バトル実施
         * @param {BattleInitiationReq} battleInitiationReq バトル実施情報
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        doBattle(battleInitiationReq: BattleInitiationReq, options?: any): AxiosPromise<BattleResult> {
            return localVarFp.doBattle(battleInitiationReq, options).then((request) => request(axios, basePath));
        },
        /**
         * バトルパーティ一覧を取得します。
         * @summary バトルパーティ一覧取得
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBattleParties(options?: any): AxiosPromise<PartiesResp> {
            return localVarFp.getBattleParties(options).then((request) => request(axios, basePath));
        },
        /**
         * バトル種類一覧を取得します。
         * @summary バトル種類一覧取得
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBattleTypes(options?: any): AxiosPromise<BattleTypes> {
            return localVarFp.getBattleTypes(options).then((request) => request(axios, basePath));
        },
        /**
         * バトルを取得します。
         * @summary バトル取得
         * @param {BattleReq} battleReq バトル情報
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBattles(battleReq: BattleReq, options?: any): AxiosPromise<Battles> {
            return localVarFp.getBattles(battleReq, options).then((request) => request(axios, basePath));
        },
        /**
         * バトルチケットを購入します。
         * @summary バトルチケット購入
         * @param {BattleTicketsPurchaseReq} battleTicketsPurchaseReq バトルチケット購入情報
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        purchaseBattleTickets(battleTicketsPurchaseReq: BattleTicketsPurchaseReq, options?: any): AxiosPromise<Resources> {
            return localVarFp.purchaseBattleTickets(battleTicketsPurchaseReq, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * BattleApi - object-oriented interface
 * @export
 * @class BattleApi
 * @extends {BaseAPI}
 */
export class BattleApi extends BaseAPI {
    /**
     * バトルを実施します。
     * @summary バトル実施
     * @param {BattleInitiationReq} battleInitiationReq バトル実施情報
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BattleApi
     */
    public async doBattle(battleInitiationReq: BattleInitiationReq, options?: AxiosRequestConfig) {
        const retry = 2;
        let resp;
        for (let i = 0; i < retry; i++) {
            resp = await BattleApiFp(this.configuration).doBattle(battleInitiationReq, options).then((request) => request(this.axios, this.basePath)).catch((error) => {
                if (error?.response) {
                    return error.response;
                } else {
                    return { status: 500 };
                }
            });
            if (resp?.status === 401) {
                await refreshIdToken();
                console.debug('Retry doBattle ' + i + '/' + (retry - 1));
                continue;
            }
            break;
        }
        return resp;
    }

    /**
     * バトルパーティ一覧を取得します。
     * @summary バトルパーティ一覧取得
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BattleApi
     */
    public async getBattleParties(options?: AxiosRequestConfig) {
        const retry = 2;
        let resp;
        for (let i = 0; i < retry; i++) {
            resp = await BattleApiFp(this.configuration).getBattleParties(options).then((request) => request(this.axios, this.basePath)).catch((error) => {
                if (error?.response) {
                    return error.response;
                } else {
                    return { status: 500 };
                }
            });
            if (resp?.status === 401) {
                await refreshIdToken();
                console.debug('Retry getBattleParties ' + i + '/' + (retry - 1));
                continue;
            }
            break;
        }
        return resp;
    }

    /**
     * バトル種類一覧を取得します。
     * @summary バトル種類一覧取得
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BattleApi
     */
    public async getBattleTypes(options?: AxiosRequestConfig) {
        const retry = 2;
        let resp;
        for (let i = 0; i < retry; i++) {
            resp = await BattleApiFp(this.configuration).getBattleTypes(options).then((request) => request(this.axios, this.basePath)).catch((error) => {
                if (error?.response) {
                    return error.response;
                } else {
                    return { status: 500 };
                }
            });
            if (resp?.status === 401) {
                await refreshIdToken();
                console.debug('Retry getBattleTypes ' + i + '/' + (retry - 1));
                continue;
            }
            break;
        }
        return resp;
    }

    /**
     * バトルを取得します。
     * @summary バトル取得
     * @param {BattleReq} battleReq バトル情報
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BattleApi
     */
    public async getBattles(battleReq: BattleReq, options?: AxiosRequestConfig) {
        const retry = 2;
        let resp;
        for (let i = 0; i < retry; i++) {
            resp = await BattleApiFp(this.configuration).getBattles(battleReq, options).then((request) => request(this.axios, this.basePath)).catch((error) => {
                if (error?.response) {
                    return error.response;
                } else {
                    return { status: 500 };
                }
            });
            if (resp?.status === 401) {
                await refreshIdToken();
                console.debug('Retry getBattles ' + i + '/' + (retry - 1));
                continue;
            }
            break;
        }
        return resp;
    }

    /**
     * バトルチケットを購入します。
     * @summary バトルチケット購入
     * @param {BattleTicketsPurchaseReq} battleTicketsPurchaseReq バトルチケット購入情報
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BattleApi
     */
    public async purchaseBattleTickets(battleTicketsPurchaseReq: BattleTicketsPurchaseReq, options?: AxiosRequestConfig) {
        const retry = 2;
        let resp;
        for (let i = 0; i < retry; i++) {
            resp = await BattleApiFp(this.configuration).purchaseBattleTickets(battleTicketsPurchaseReq, options).then((request) => request(this.axios, this.basePath)).catch((error) => {
                if (error?.response) {
                    return error.response;
                } else {
                    return { status: 500 };
                }
            });
            if (resp?.status === 401) {
                await refreshIdToken();
                console.debug('Retry purchaseBattleTickets ' + i + '/' + (retry - 1));
                continue;
            }
            break;
        }
        return resp;
    }
}


/**
 * BillingApi - axios parameter creator
 * @export
 */
export const BillingApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * クレジットカードを削除します。
         * @summary クレジットカードを削除
         * @param {string} cardId クレジットカードID
         * @param {BillingCardRequest} billingCardRequest クレジットカード情報
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteBillingCard: async (cardId: string, billingCardRequest: BillingCardRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'cardId' is not null or undefined
            assertParamExists('deleteBillingCard', 'cardId', cardId)
            // verify required parameter 'billingCardRequest' is not null or undefined
            assertParamExists('deleteBillingCard', 'billingCardRequest', billingCardRequest)
            const localVarPath = `/billing/cards/{card_id}`
                .replace(`{${"card_id"}}`, encodeURIComponent(String(cardId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(billingCardRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * チームの請求情報を取得します。
         * @summary チームの請求情報を取得
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBillingInfo: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/billing/info`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * クレジットカードをデフォルト設定します。
         * @summary クレジットカードをデフォルト設定
         * @param {string} cardId クレジットカードID
         * @param {BillingCardRequest} billingCardRequest クレジットカード情報
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setBillingCardDefault: async (cardId: string, billingCardRequest: BillingCardRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'cardId' is not null or undefined
            assertParamExists('setBillingCardDefault', 'cardId', cardId)
            // verify required parameter 'billingCardRequest' is not null or undefined
            assertParamExists('setBillingCardDefault', 'billingCardRequest', billingCardRequest)
            const localVarPath = `/billing/cards/{card_id}/default`
                .replace(`{${"card_id"}}`, encodeURIComponent(String(cardId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(billingCardRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * BillingApi - functional programming interface
 * @export
 */
export const BillingApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = BillingApiAxiosParamCreator(configuration)
    return {
        /**
         * クレジットカードを削除します。
         * @summary クレジットカードを削除
         * @param {string} cardId クレジットカードID
         * @param {BillingCardRequest} billingCardRequest クレジットカード情報
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteBillingCard(cardId: string, billingCardRequest: BillingCardRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteBillingCard(cardId, billingCardRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * チームの請求情報を取得します。
         * @summary チームの請求情報を取得
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getBillingInfo(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BillingInfo>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getBillingInfo(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * クレジットカードをデフォルト設定します。
         * @summary クレジットカードをデフォルト設定
         * @param {string} cardId クレジットカードID
         * @param {BillingCardRequest} billingCardRequest クレジットカード情報
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async setBillingCardDefault(cardId: string, billingCardRequest: BillingCardRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BillingCards>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.setBillingCardDefault(cardId, billingCardRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * BillingApi - factory interface
 * @export
 */
export const BillingApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = BillingApiFp(configuration)
    return {
        /**
         * クレジットカードを削除します。
         * @summary クレジットカードを削除
         * @param {string} cardId クレジットカードID
         * @param {BillingCardRequest} billingCardRequest クレジットカード情報
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteBillingCard(cardId: string, billingCardRequest: BillingCardRequest, options?: any): AxiosPromise<void> {
            return localVarFp.deleteBillingCard(cardId, billingCardRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * チームの請求情報を取得します。
         * @summary チームの請求情報を取得
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBillingInfo(options?: any): AxiosPromise<BillingInfo> {
            return localVarFp.getBillingInfo(options).then((request) => request(axios, basePath));
        },
        /**
         * クレジットカードをデフォルト設定します。
         * @summary クレジットカードをデフォルト設定
         * @param {string} cardId クレジットカードID
         * @param {BillingCardRequest} billingCardRequest クレジットカード情報
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setBillingCardDefault(cardId: string, billingCardRequest: BillingCardRequest, options?: any): AxiosPromise<BillingCards> {
            return localVarFp.setBillingCardDefault(cardId, billingCardRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * BillingApi - object-oriented interface
 * @export
 * @class BillingApi
 * @extends {BaseAPI}
 */
export class BillingApi extends BaseAPI {
    /**
     * クレジットカードを削除します。
     * @summary クレジットカードを削除
     * @param {string} cardId クレジットカードID
     * @param {BillingCardRequest} billingCardRequest クレジットカード情報
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BillingApi
     */
    public async deleteBillingCard(cardId: string, billingCardRequest: BillingCardRequest, options?: AxiosRequestConfig) {
        const retry = 2;
        let resp;
        for (let i = 0; i < retry; i++) {
            resp = await BillingApiFp(this.configuration).deleteBillingCard(cardId, billingCardRequest, options).then((request) => request(this.axios, this.basePath)).catch((error) => {
                if (error?.response) {
                    return error.response;
                } else {
                    return { status: 500 };
                }
            });
            if (resp?.status === 401) {
                await refreshIdToken();
                console.debug('Retry deleteBillingCard ' + i + '/' + (retry - 1));
                continue;
            }
            break;
        }
        return resp;
    }

    /**
     * チームの請求情報を取得します。
     * @summary チームの請求情報を取得
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BillingApi
     */
    public async getBillingInfo(options?: AxiosRequestConfig) {
        const retry = 2;
        let resp;
        for (let i = 0; i < retry; i++) {
            resp = await BillingApiFp(this.configuration).getBillingInfo(options).then((request) => request(this.axios, this.basePath)).catch((error) => {
                if (error?.response) {
                    return error.response;
                } else {
                    return { status: 500 };
                }
            });
            if (resp?.status === 401) {
                await refreshIdToken();
                console.debug('Retry getBillingInfo ' + i + '/' + (retry - 1));
                continue;
            }
            break;
        }
        return resp;
    }

    /**
     * クレジットカードをデフォルト設定します。
     * @summary クレジットカードをデフォルト設定
     * @param {string} cardId クレジットカードID
     * @param {BillingCardRequest} billingCardRequest クレジットカード情報
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BillingApi
     */
    public async setBillingCardDefault(cardId: string, billingCardRequest: BillingCardRequest, options?: AxiosRequestConfig) {
        const retry = 2;
        let resp;
        for (let i = 0; i < retry; i++) {
            resp = await BillingApiFp(this.configuration).setBillingCardDefault(cardId, billingCardRequest, options).then((request) => request(this.axios, this.basePath)).catch((error) => {
                if (error?.response) {
                    return error.response;
                } else {
                    return { status: 500 };
                }
            });
            if (resp?.status === 401) {
                await refreshIdToken();
                console.debug('Retry setBillingCardDefault ' + i + '/' + (retry - 1));
                continue;
            }
            break;
        }
        return resp;
    }
}


/**
 * CategoryApi - axios parameter creator
 * @export
 */
export const CategoryApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * カテゴリーを作成します。
         * @summary 個人のカテゴリーの作成
         * @param {string} username ユーザ名
         * @param {CategoryRequest} categoryRequest カテゴリ-情報
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addCategory: async (username: string, categoryRequest: CategoryRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'username' is not null or undefined
            assertParamExists('addCategory', 'username', username)
            // verify required parameter 'categoryRequest' is not null or undefined
            assertParamExists('addCategory', 'categoryRequest', categoryRequest)
            const localVarPath = `/categories/users/{username}`
                .replace(`{${"username"}}`, encodeURIComponent(String(username)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(categoryRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * チーム内のカテゴリーを作成します。
         * @summary チーム>プロジェクトのカテゴリカテゴリーの作成
         * @param {string} teamname チーム名
         * @param {number} projectOrder プロジェクトオーダー
         * @param {CategoryRequest} categoryRequest カテゴリ-情報
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addProjectCategory: async (teamname: string, projectOrder: number, categoryRequest: CategoryRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'teamname' is not null or undefined
            assertParamExists('addProjectCategory', 'teamname', teamname)
            // verify required parameter 'projectOrder' is not null or undefined
            assertParamExists('addProjectCategory', 'projectOrder', projectOrder)
            // verify required parameter 'categoryRequest' is not null or undefined
            assertParamExists('addProjectCategory', 'categoryRequest', categoryRequest)
            const localVarPath = `/categories/teams/{teamname}/projects/{project_order}`
                .replace(`{${"teamname"}}`, encodeURIComponent(String(teamname)))
                .replace(`{${"project_order"}}`, encodeURIComponent(String(projectOrder)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(categoryRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * チーム内のカテゴリーを作成します。
         * @summary チームのカテゴリカテゴリーの作成
         * @param {string} teamname チーム名
         * @param {CategoryRequest} categoryRequest カテゴリ-情報
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addTeamCategory: async (teamname: string, categoryRequest: CategoryRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'teamname' is not null or undefined
            assertParamExists('addTeamCategory', 'teamname', teamname)
            // verify required parameter 'categoryRequest' is not null or undefined
            assertParamExists('addTeamCategory', 'categoryRequest', categoryRequest)
            const localVarPath = `/categories/teams/{teamname}`
                .replace(`{${"teamname"}}`, encodeURIComponent(String(teamname)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(categoryRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * カテゴリーを削除します。
         * @summary 個人のカテゴリー削除
         * @param {string} username ユーザ名
         * @param {CategoryRequest} categoryRequest カテゴリ-情報
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteCategory: async (username: string, categoryRequest: CategoryRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'username' is not null or undefined
            assertParamExists('deleteCategory', 'username', username)
            // verify required parameter 'categoryRequest' is not null or undefined
            assertParamExists('deleteCategory', 'categoryRequest', categoryRequest)
            const localVarPath = `/categories/users/{username}`
                .replace(`{${"username"}}`, encodeURIComponent(String(username)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(categoryRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * カテゴリーを削除します。
         * @summary チーム>プロジェクトのカテゴリーを削除
         * @param {string} teamname チーム名
         * @param {number} projectOrder プロジェクトオーダー
         * @param {CategoryRequest} categoryRequest カテゴリ-情報
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteProjctCategory: async (teamname: string, projectOrder: number, categoryRequest: CategoryRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'teamname' is not null or undefined
            assertParamExists('deleteProjctCategory', 'teamname', teamname)
            // verify required parameter 'projectOrder' is not null or undefined
            assertParamExists('deleteProjctCategory', 'projectOrder', projectOrder)
            // verify required parameter 'categoryRequest' is not null or undefined
            assertParamExists('deleteProjctCategory', 'categoryRequest', categoryRequest)
            const localVarPath = `/categories/teams/{teamname}/projects/{project_order}`
                .replace(`{${"teamname"}}`, encodeURIComponent(String(teamname)))
                .replace(`{${"project_order"}}`, encodeURIComponent(String(projectOrder)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(categoryRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * カテゴリーを削除します。
         * @summary チームのカテゴリーを削除
         * @param {string} teamname チーム名
         * @param {CategoryRequest} categoryRequest カテゴリ-情報
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteTeamCategory: async (teamname: string, categoryRequest: CategoryRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'teamname' is not null or undefined
            assertParamExists('deleteTeamCategory', 'teamname', teamname)
            // verify required parameter 'categoryRequest' is not null or undefined
            assertParamExists('deleteTeamCategory', 'categoryRequest', categoryRequest)
            const localVarPath = `/categories/teams/{teamname}`
                .replace(`{${"teamname"}}`, encodeURIComponent(String(teamname)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(categoryRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 指定したカテゴリーリストを取得します。
         * @summary 個人のカテゴリーリストの取得
         * @param {string} username ユーザ名
         * @param {string} categoryType カテゴリーの種別
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findCategories: async (username: string, categoryType: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'username' is not null or undefined
            assertParamExists('findCategories', 'username', username)
            // verify required parameter 'categoryType' is not null or undefined
            assertParamExists('findCategories', 'categoryType', categoryType)
            const localVarPath = `/categories/users/{username}`
                .replace(`{${"username"}}`, encodeURIComponent(String(username)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (categoryType !== undefined) {
                localVarQueryParameter['category_type'] = categoryType;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * チーム>プロジェクト内カテゴリから指定したタイプのカテゴリリストを取得します。
         * @summary チーム>プロジェクトのカテゴリーリストの取得
         * @param {string} teamname チーム名
         * @param {number} projectOrder プロジェクトオーダー
         * @param {string} categoryType カテゴリーの種別
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findProjectCategories: async (teamname: string, projectOrder: number, categoryType: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'teamname' is not null or undefined
            assertParamExists('findProjectCategories', 'teamname', teamname)
            // verify required parameter 'projectOrder' is not null or undefined
            assertParamExists('findProjectCategories', 'projectOrder', projectOrder)
            // verify required parameter 'categoryType' is not null or undefined
            assertParamExists('findProjectCategories', 'categoryType', categoryType)
            const localVarPath = `/categories/teams/{teamname}/projects/{project_order}`
                .replace(`{${"teamname"}}`, encodeURIComponent(String(teamname)))
                .replace(`{${"project_order"}}`, encodeURIComponent(String(projectOrder)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (categoryType !== undefined) {
                localVarQueryParameter['category_type'] = categoryType;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * チーム内カテゴリから指定したタイプのカテゴリリストを取得します。
         * @summary チームのカテゴリーリストの取得
         * @param {string} teamname チーム名
         * @param {string} categoryType カテゴリーの種別
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findTeamCategories: async (teamname: string, categoryType: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'teamname' is not null or undefined
            assertParamExists('findTeamCategories', 'teamname', teamname)
            // verify required parameter 'categoryType' is not null or undefined
            assertParamExists('findTeamCategories', 'categoryType', categoryType)
            const localVarPath = `/categories/teams/{teamname}`
                .replace(`{${"teamname"}}`, encodeURIComponent(String(teamname)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (categoryType !== undefined) {
                localVarQueryParameter['category_type'] = categoryType;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 自分自身のカテゴリーを更新します。
         * @summary 個人のカテゴリーの更新
         * @param {string} username ユーザ名
         * @param {CategoryRequest} categoryRequest カテゴリ-情報
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setCategory: async (username: string, categoryRequest: CategoryRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'username' is not null or undefined
            assertParamExists('setCategory', 'username', username)
            // verify required parameter 'categoryRequest' is not null or undefined
            assertParamExists('setCategory', 'categoryRequest', categoryRequest)
            const localVarPath = `/categories/users/{username}`
                .replace(`{${"username"}}`, encodeURIComponent(String(username)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(categoryRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * チームののカテゴリーを更新します。
         * @summary チーム>プロジェクトのカテゴリーの更新
         * @param {string} teamname チーム名
         * @param {number} projectOrder プロジェクトオーダー
         * @param {CategoryRequest} categoryRequest カテゴリ-情報
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setProjectCategory: async (teamname: string, projectOrder: number, categoryRequest: CategoryRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'teamname' is not null or undefined
            assertParamExists('setProjectCategory', 'teamname', teamname)
            // verify required parameter 'projectOrder' is not null or undefined
            assertParamExists('setProjectCategory', 'projectOrder', projectOrder)
            // verify required parameter 'categoryRequest' is not null or undefined
            assertParamExists('setProjectCategory', 'categoryRequest', categoryRequest)
            const localVarPath = `/categories/teams/{teamname}/projects/{project_order}`
                .replace(`{${"teamname"}}`, encodeURIComponent(String(teamname)))
                .replace(`{${"project_order"}}`, encodeURIComponent(String(projectOrder)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(categoryRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * チームののカテゴリーを更新します。
         * @summary チームのカテゴリーの更新
         * @param {string} teamname チーム名
         * @param {CategoryRequest} categoryRequest カテゴリ-情報
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setTeamCategory: async (teamname: string, categoryRequest: CategoryRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'teamname' is not null or undefined
            assertParamExists('setTeamCategory', 'teamname', teamname)
            // verify required parameter 'categoryRequest' is not null or undefined
            assertParamExists('setTeamCategory', 'categoryRequest', categoryRequest)
            const localVarPath = `/categories/teams/{teamname}`
                .replace(`{${"teamname"}}`, encodeURIComponent(String(teamname)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(categoryRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CategoryApi - functional programming interface
 * @export
 */
export const CategoryApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CategoryApiAxiosParamCreator(configuration)
    return {
        /**
         * カテゴリーを作成します。
         * @summary 個人のカテゴリーの作成
         * @param {string} username ユーザ名
         * @param {CategoryRequest} categoryRequest カテゴリ-情報
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addCategory(username: string, categoryRequest: CategoryRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Category>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addCategory(username, categoryRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * チーム内のカテゴリーを作成します。
         * @summary チーム>プロジェクトのカテゴリカテゴリーの作成
         * @param {string} teamname チーム名
         * @param {number} projectOrder プロジェクトオーダー
         * @param {CategoryRequest} categoryRequest カテゴリ-情報
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addProjectCategory(teamname: string, projectOrder: number, categoryRequest: CategoryRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Category>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addProjectCategory(teamname, projectOrder, categoryRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * チーム内のカテゴリーを作成します。
         * @summary チームのカテゴリカテゴリーの作成
         * @param {string} teamname チーム名
         * @param {CategoryRequest} categoryRequest カテゴリ-情報
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addTeamCategory(teamname: string, categoryRequest: CategoryRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Category>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addTeamCategory(teamname, categoryRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * カテゴリーを削除します。
         * @summary 個人のカテゴリー削除
         * @param {string} username ユーザ名
         * @param {CategoryRequest} categoryRequest カテゴリ-情報
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteCategory(username: string, categoryRequest: CategoryRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteCategory(username, categoryRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * カテゴリーを削除します。
         * @summary チーム>プロジェクトのカテゴリーを削除
         * @param {string} teamname チーム名
         * @param {number} projectOrder プロジェクトオーダー
         * @param {CategoryRequest} categoryRequest カテゴリ-情報
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteProjctCategory(teamname: string, projectOrder: number, categoryRequest: CategoryRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteProjctCategory(teamname, projectOrder, categoryRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * カテゴリーを削除します。
         * @summary チームのカテゴリーを削除
         * @param {string} teamname チーム名
         * @param {CategoryRequest} categoryRequest カテゴリ-情報
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteTeamCategory(teamname: string, categoryRequest: CategoryRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteTeamCategory(teamname, categoryRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 指定したカテゴリーリストを取得します。
         * @summary 個人のカテゴリーリストの取得
         * @param {string} username ユーザ名
         * @param {string} categoryType カテゴリーの種別
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findCategories(username: string, categoryType: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Categories>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findCategories(username, categoryType, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * チーム>プロジェクト内カテゴリから指定したタイプのカテゴリリストを取得します。
         * @summary チーム>プロジェクトのカテゴリーリストの取得
         * @param {string} teamname チーム名
         * @param {number} projectOrder プロジェクトオーダー
         * @param {string} categoryType カテゴリーの種別
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findProjectCategories(teamname: string, projectOrder: number, categoryType: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Categories>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findProjectCategories(teamname, projectOrder, categoryType, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * チーム内カテゴリから指定したタイプのカテゴリリストを取得します。
         * @summary チームのカテゴリーリストの取得
         * @param {string} teamname チーム名
         * @param {string} categoryType カテゴリーの種別
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findTeamCategories(teamname: string, categoryType: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Categories>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findTeamCategories(teamname, categoryType, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 自分自身のカテゴリーを更新します。
         * @summary 個人のカテゴリーの更新
         * @param {string} username ユーザ名
         * @param {CategoryRequest} categoryRequest カテゴリ-情報
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async setCategory(username: string, categoryRequest: CategoryRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Category>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.setCategory(username, categoryRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * チームののカテゴリーを更新します。
         * @summary チーム>プロジェクトのカテゴリーの更新
         * @param {string} teamname チーム名
         * @param {number} projectOrder プロジェクトオーダー
         * @param {CategoryRequest} categoryRequest カテゴリ-情報
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async setProjectCategory(teamname: string, projectOrder: number, categoryRequest: CategoryRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Category>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.setProjectCategory(teamname, projectOrder, categoryRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * チームののカテゴリーを更新します。
         * @summary チームのカテゴリーの更新
         * @param {string} teamname チーム名
         * @param {CategoryRequest} categoryRequest カテゴリ-情報
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async setTeamCategory(teamname: string, categoryRequest: CategoryRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Category>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.setTeamCategory(teamname, categoryRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * CategoryApi - factory interface
 * @export
 */
export const CategoryApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CategoryApiFp(configuration)
    return {
        /**
         * カテゴリーを作成します。
         * @summary 個人のカテゴリーの作成
         * @param {string} username ユーザ名
         * @param {CategoryRequest} categoryRequest カテゴリ-情報
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addCategory(username: string, categoryRequest: CategoryRequest, options?: any): AxiosPromise<Category> {
            return localVarFp.addCategory(username, categoryRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * チーム内のカテゴリーを作成します。
         * @summary チーム>プロジェクトのカテゴリカテゴリーの作成
         * @param {string} teamname チーム名
         * @param {number} projectOrder プロジェクトオーダー
         * @param {CategoryRequest} categoryRequest カテゴリ-情報
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addProjectCategory(teamname: string, projectOrder: number, categoryRequest: CategoryRequest, options?: any): AxiosPromise<Category> {
            return localVarFp.addProjectCategory(teamname, projectOrder, categoryRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * チーム内のカテゴリーを作成します。
         * @summary チームのカテゴリカテゴリーの作成
         * @param {string} teamname チーム名
         * @param {CategoryRequest} categoryRequest カテゴリ-情報
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addTeamCategory(teamname: string, categoryRequest: CategoryRequest, options?: any): AxiosPromise<Category> {
            return localVarFp.addTeamCategory(teamname, categoryRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * カテゴリーを削除します。
         * @summary 個人のカテゴリー削除
         * @param {string} username ユーザ名
         * @param {CategoryRequest} categoryRequest カテゴリ-情報
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteCategory(username: string, categoryRequest: CategoryRequest, options?: any): AxiosPromise<void> {
            return localVarFp.deleteCategory(username, categoryRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * カテゴリーを削除します。
         * @summary チーム>プロジェクトのカテゴリーを削除
         * @param {string} teamname チーム名
         * @param {number} projectOrder プロジェクトオーダー
         * @param {CategoryRequest} categoryRequest カテゴリ-情報
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteProjctCategory(teamname: string, projectOrder: number, categoryRequest: CategoryRequest, options?: any): AxiosPromise<void> {
            return localVarFp.deleteProjctCategory(teamname, projectOrder, categoryRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * カテゴリーを削除します。
         * @summary チームのカテゴリーを削除
         * @param {string} teamname チーム名
         * @param {CategoryRequest} categoryRequest カテゴリ-情報
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteTeamCategory(teamname: string, categoryRequest: CategoryRequest, options?: any): AxiosPromise<void> {
            return localVarFp.deleteTeamCategory(teamname, categoryRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 指定したカテゴリーリストを取得します。
         * @summary 個人のカテゴリーリストの取得
         * @param {string} username ユーザ名
         * @param {string} categoryType カテゴリーの種別
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findCategories(username: string, categoryType: string, options?: any): AxiosPromise<Categories> {
            return localVarFp.findCategories(username, categoryType, options).then((request) => request(axios, basePath));
        },
        /**
         * チーム>プロジェクト内カテゴリから指定したタイプのカテゴリリストを取得します。
         * @summary チーム>プロジェクトのカテゴリーリストの取得
         * @param {string} teamname チーム名
         * @param {number} projectOrder プロジェクトオーダー
         * @param {string} categoryType カテゴリーの種別
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findProjectCategories(teamname: string, projectOrder: number, categoryType: string, options?: any): AxiosPromise<Categories> {
            return localVarFp.findProjectCategories(teamname, projectOrder, categoryType, options).then((request) => request(axios, basePath));
        },
        /**
         * チーム内カテゴリから指定したタイプのカテゴリリストを取得します。
         * @summary チームのカテゴリーリストの取得
         * @param {string} teamname チーム名
         * @param {string} categoryType カテゴリーの種別
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findTeamCategories(teamname: string, categoryType: string, options?: any): AxiosPromise<Categories> {
            return localVarFp.findTeamCategories(teamname, categoryType, options).then((request) => request(axios, basePath));
        },
        /**
         * 自分自身のカテゴリーを更新します。
         * @summary 個人のカテゴリーの更新
         * @param {string} username ユーザ名
         * @param {CategoryRequest} categoryRequest カテゴリ-情報
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setCategory(username: string, categoryRequest: CategoryRequest, options?: any): AxiosPromise<Category> {
            return localVarFp.setCategory(username, categoryRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * チームののカテゴリーを更新します。
         * @summary チーム>プロジェクトのカテゴリーの更新
         * @param {string} teamname チーム名
         * @param {number} projectOrder プロジェクトオーダー
         * @param {CategoryRequest} categoryRequest カテゴリ-情報
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setProjectCategory(teamname: string, projectOrder: number, categoryRequest: CategoryRequest, options?: any): AxiosPromise<Category> {
            return localVarFp.setProjectCategory(teamname, projectOrder, categoryRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * チームののカテゴリーを更新します。
         * @summary チームのカテゴリーの更新
         * @param {string} teamname チーム名
         * @param {CategoryRequest} categoryRequest カテゴリ-情報
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setTeamCategory(teamname: string, categoryRequest: CategoryRequest, options?: any): AxiosPromise<Category> {
            return localVarFp.setTeamCategory(teamname, categoryRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CategoryApi - object-oriented interface
 * @export
 * @class CategoryApi
 * @extends {BaseAPI}
 */
export class CategoryApi extends BaseAPI {
    /**
     * カテゴリーを作成します。
     * @summary 個人のカテゴリーの作成
     * @param {string} username ユーザ名
     * @param {CategoryRequest} categoryRequest カテゴリ-情報
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CategoryApi
     */
    public async addCategory(username: string, categoryRequest: CategoryRequest, options?: AxiosRequestConfig) {
        const retry = 2;
        let resp;
        for (let i = 0; i < retry; i++) {
            resp = await CategoryApiFp(this.configuration).addCategory(username, categoryRequest, options).then((request) => request(this.axios, this.basePath)).catch((error) => {
                if (error?.response) {
                    return error.response;
                } else {
                    return { status: 500 };
                }
            });
            if (resp?.status === 401) {
                await refreshIdToken();
                console.debug('Retry addCategory ' + i + '/' + (retry - 1));
                continue;
            }
            break;
        }
        return resp;
    }

    /**
     * チーム内のカテゴリーを作成します。
     * @summary チーム>プロジェクトのカテゴリカテゴリーの作成
     * @param {string} teamname チーム名
     * @param {number} projectOrder プロジェクトオーダー
     * @param {CategoryRequest} categoryRequest カテゴリ-情報
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CategoryApi
     */
    public async addProjectCategory(teamname: string, projectOrder: number, categoryRequest: CategoryRequest, options?: AxiosRequestConfig) {
        const retry = 2;
        let resp;
        for (let i = 0; i < retry; i++) {
            resp = await CategoryApiFp(this.configuration).addProjectCategory(teamname, projectOrder, categoryRequest, options).then((request) => request(this.axios, this.basePath)).catch((error) => {
                if (error?.response) {
                    return error.response;
                } else {
                    return { status: 500 };
                }
            });
            if (resp?.status === 401) {
                await refreshIdToken();
                console.debug('Retry addProjectCategory ' + i + '/' + (retry - 1));
                continue;
            }
            break;
        }
        return resp;
    }

    /**
     * チーム内のカテゴリーを作成します。
     * @summary チームのカテゴリカテゴリーの作成
     * @param {string} teamname チーム名
     * @param {CategoryRequest} categoryRequest カテゴリ-情報
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CategoryApi
     */
    public async addTeamCategory(teamname: string, categoryRequest: CategoryRequest, options?: AxiosRequestConfig) {
        const retry = 2;
        let resp;
        for (let i = 0; i < retry; i++) {
            resp = await CategoryApiFp(this.configuration).addTeamCategory(teamname, categoryRequest, options).then((request) => request(this.axios, this.basePath)).catch((error) => {
                if (error?.response) {
                    return error.response;
                } else {
                    return { status: 500 };
                }
            });
            if (resp?.status === 401) {
                await refreshIdToken();
                console.debug('Retry addTeamCategory ' + i + '/' + (retry - 1));
                continue;
            }
            break;
        }
        return resp;
    }

    /**
     * カテゴリーを削除します。
     * @summary 個人のカテゴリー削除
     * @param {string} username ユーザ名
     * @param {CategoryRequest} categoryRequest カテゴリ-情報
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CategoryApi
     */
    public async deleteCategory(username: string, categoryRequest: CategoryRequest, options?: AxiosRequestConfig) {
        const retry = 2;
        let resp;
        for (let i = 0; i < retry; i++) {
            resp = await CategoryApiFp(this.configuration).deleteCategory(username, categoryRequest, options).then((request) => request(this.axios, this.basePath)).catch((error) => {
                if (error?.response) {
                    return error.response;
                } else {
                    return { status: 500 };
                }
            });
            if (resp?.status === 401) {
                await refreshIdToken();
                console.debug('Retry deleteCategory ' + i + '/' + (retry - 1));
                continue;
            }
            break;
        }
        return resp;
    }

    /**
     * カテゴリーを削除します。
     * @summary チーム>プロジェクトのカテゴリーを削除
     * @param {string} teamname チーム名
     * @param {number} projectOrder プロジェクトオーダー
     * @param {CategoryRequest} categoryRequest カテゴリ-情報
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CategoryApi
     */
    public async deleteProjctCategory(teamname: string, projectOrder: number, categoryRequest: CategoryRequest, options?: AxiosRequestConfig) {
        const retry = 2;
        let resp;
        for (let i = 0; i < retry; i++) {
            resp = await CategoryApiFp(this.configuration).deleteProjctCategory(teamname, projectOrder, categoryRequest, options).then((request) => request(this.axios, this.basePath)).catch((error) => {
                if (error?.response) {
                    return error.response;
                } else {
                    return { status: 500 };
                }
            });
            if (resp?.status === 401) {
                await refreshIdToken();
                console.debug('Retry deleteProjctCategory ' + i + '/' + (retry - 1));
                continue;
            }
            break;
        }
        return resp;
    }

    /**
     * カテゴリーを削除します。
     * @summary チームのカテゴリーを削除
     * @param {string} teamname チーム名
     * @param {CategoryRequest} categoryRequest カテゴリ-情報
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CategoryApi
     */
    public async deleteTeamCategory(teamname: string, categoryRequest: CategoryRequest, options?: AxiosRequestConfig) {
        const retry = 2;
        let resp;
        for (let i = 0; i < retry; i++) {
            resp = await CategoryApiFp(this.configuration).deleteTeamCategory(teamname, categoryRequest, options).then((request) => request(this.axios, this.basePath)).catch((error) => {
                if (error?.response) {
                    return error.response;
                } else {
                    return { status: 500 };
                }
            });
            if (resp?.status === 401) {
                await refreshIdToken();
                console.debug('Retry deleteTeamCategory ' + i + '/' + (retry - 1));
                continue;
            }
            break;
        }
        return resp;
    }

    /**
     * 指定したカテゴリーリストを取得します。
     * @summary 個人のカテゴリーリストの取得
     * @param {string} username ユーザ名
     * @param {string} categoryType カテゴリーの種別
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CategoryApi
     */
    public async findCategories(username: string, categoryType: string, options?: AxiosRequestConfig) {
        const retry = 2;
        let resp;
        for (let i = 0; i < retry; i++) {
            resp = await CategoryApiFp(this.configuration).findCategories(username, categoryType, options).then((request) => request(this.axios, this.basePath)).catch((error) => {
                if (error?.response) {
                    return error.response;
                } else {
                    return { status: 500 };
                }
            });
            if (resp?.status === 401) {
                await refreshIdToken();
                console.debug('Retry findCategories ' + i + '/' + (retry - 1));
                continue;
            }
            break;
        }
        return resp;
    }

    /**
     * チーム>プロジェクト内カテゴリから指定したタイプのカテゴリリストを取得します。
     * @summary チーム>プロジェクトのカテゴリーリストの取得
     * @param {string} teamname チーム名
     * @param {number} projectOrder プロジェクトオーダー
     * @param {string} categoryType カテゴリーの種別
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CategoryApi
     */
    public async findProjectCategories(teamname: string, projectOrder: number, categoryType: string, options?: AxiosRequestConfig) {
        const retry = 2;
        let resp;
        for (let i = 0; i < retry; i++) {
            resp = await CategoryApiFp(this.configuration).findProjectCategories(teamname, projectOrder, categoryType, options).then((request) => request(this.axios, this.basePath)).catch((error) => {
                if (error?.response) {
                    return error.response;
                } else {
                    return { status: 500 };
                }
            });
            if (resp?.status === 401) {
                await refreshIdToken();
                console.debug('Retry findProjectCategories ' + i + '/' + (retry - 1));
                continue;
            }
            break;
        }
        return resp;
    }

    /**
     * チーム内カテゴリから指定したタイプのカテゴリリストを取得します。
     * @summary チームのカテゴリーリストの取得
     * @param {string} teamname チーム名
     * @param {string} categoryType カテゴリーの種別
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CategoryApi
     */
    public async findTeamCategories(teamname: string, categoryType: string, options?: AxiosRequestConfig) {
        const retry = 2;
        let resp;
        for (let i = 0; i < retry; i++) {
            resp = await CategoryApiFp(this.configuration).findTeamCategories(teamname, categoryType, options).then((request) => request(this.axios, this.basePath)).catch((error) => {
                if (error?.response) {
                    return error.response;
                } else {
                    return { status: 500 };
                }
            });
            if (resp?.status === 401) {
                await refreshIdToken();
                console.debug('Retry findTeamCategories ' + i + '/' + (retry - 1));
                continue;
            }
            break;
        }
        return resp;
    }

    /**
     * 自分自身のカテゴリーを更新します。
     * @summary 個人のカテゴリーの更新
     * @param {string} username ユーザ名
     * @param {CategoryRequest} categoryRequest カテゴリ-情報
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CategoryApi
     */
    public async setCategory(username: string, categoryRequest: CategoryRequest, options?: AxiosRequestConfig) {
        const retry = 2;
        let resp;
        for (let i = 0; i < retry; i++) {
            resp = await CategoryApiFp(this.configuration).setCategory(username, categoryRequest, options).then((request) => request(this.axios, this.basePath)).catch((error) => {
                if (error?.response) {
                    return error.response;
                } else {
                    return { status: 500 };
                }
            });
            if (resp?.status === 401) {
                await refreshIdToken();
                console.debug('Retry setCategory ' + i + '/' + (retry - 1));
                continue;
            }
            break;
        }
        return resp;
    }

    /**
     * チームののカテゴリーを更新します。
     * @summary チーム>プロジェクトのカテゴリーの更新
     * @param {string} teamname チーム名
     * @param {number} projectOrder プロジェクトオーダー
     * @param {CategoryRequest} categoryRequest カテゴリ-情報
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CategoryApi
     */
    public async setProjectCategory(teamname: string, projectOrder: number, categoryRequest: CategoryRequest, options?: AxiosRequestConfig) {
        const retry = 2;
        let resp;
        for (let i = 0; i < retry; i++) {
            resp = await CategoryApiFp(this.configuration).setProjectCategory(teamname, projectOrder, categoryRequest, options).then((request) => request(this.axios, this.basePath)).catch((error) => {
                if (error?.response) {
                    return error.response;
                } else {
                    return { status: 500 };
                }
            });
            if (resp?.status === 401) {
                await refreshIdToken();
                console.debug('Retry setProjectCategory ' + i + '/' + (retry - 1));
                continue;
            }
            break;
        }
        return resp;
    }

    /**
     * チームののカテゴリーを更新します。
     * @summary チームのカテゴリーの更新
     * @param {string} teamname チーム名
     * @param {CategoryRequest} categoryRequest カテゴリ-情報
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CategoryApi
     */
    public async setTeamCategory(teamname: string, categoryRequest: CategoryRequest, options?: AxiosRequestConfig) {
        const retry = 2;
        let resp;
        for (let i = 0; i < retry; i++) {
            resp = await CategoryApiFp(this.configuration).setTeamCategory(teamname, categoryRequest, options).then((request) => request(this.axios, this.basePath)).catch((error) => {
                if (error?.response) {
                    return error.response;
                } else {
                    return { status: 500 };
                }
            });
            if (resp?.status === 401) {
                await refreshIdToken();
                console.debug('Retry setTeamCategory ' + i + '/' + (retry - 1));
                continue;
            }
            break;
        }
        return resp;
    }
}


/**
 * CodeApi - axios parameter creator
 * @export
 */
export const CodeApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 用意されているバッジコード一覧を取得します。
         * @summary バッジコード一覧の取得
         * @param {string} [category] バッジのカテゴリー
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findCodeBadges: async (category?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/codes/badges`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (category !== undefined) {
                localVarQueryParameter['category'] = category;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 用意されているプランコード一覧を取得します。
         * @summary プランコード一覧の取得
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findCodeBillingPlans: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/codes/billingplans`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * フィールドタイプ一覧を取得します。
         * @summary フィールドタイプ一覧
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findCodeFieldTypes: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/codes/tables/fieldtypes`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * インテグレーションコードの一覧を取得します。
         * @summary インテグレーションコード一覧の取得
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findCodeIntegrations: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/codes/integrations`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 用意されているメンバー状態のコード一覧を取得します。
         * @summary メンバー状態コード一覧の取得
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findCodeMemberStatus: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/codes/memberstatus`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 設定されているミッションコード一覧を取得します。
         * @summary ミッションコード一覧の取得
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findCodeMissions: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/codes/missions`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * スキル詳細のメタID毎のサジェスト一覧を取得します。
         * @summary スキル詳細のメタID毎のサジェスト一覧の取得
         * @param {number} metaId メタID
         * @param {string} [search] 検索キーワード
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findCodeSkillSuggests: async (metaId: number, search?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'metaId' is not null or undefined
            assertParamExists('findCodeSkillSuggests', 'metaId', metaId)
            const localVarPath = `/codes/skills/suggests/{meta_id}`
                .replace(`{${"meta_id"}}`, encodeURIComponent(String(metaId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 設定されているスキル種別コード一覧を取得します。
         * @summary スキル種別コード一覧の取得
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findCodeSkillTypes: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/codes/skilltypes`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CodeApi - functional programming interface
 * @export
 */
export const CodeApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CodeApiAxiosParamCreator(configuration)
    return {
        /**
         * 用意されているバッジコード一覧を取得します。
         * @summary バッジコード一覧の取得
         * @param {string} [category] バッジのカテゴリー
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findCodeBadges(category?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Badges>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findCodeBadges(category, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 用意されているプランコード一覧を取得します。
         * @summary プランコード一覧の取得
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findCodeBillingPlans(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BillingPlans>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findCodeBillingPlans(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * フィールドタイプ一覧を取得します。
         * @summary フィールドタイプ一覧
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findCodeFieldTypes(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FieldTypesResp>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findCodeFieldTypes(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * インテグレーションコードの一覧を取得します。
         * @summary インテグレーションコード一覧の取得
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findCodeIntegrations(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Integrations>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findCodeIntegrations(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 用意されているメンバー状態のコード一覧を取得します。
         * @summary メンバー状態コード一覧の取得
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findCodeMemberStatus(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Statuses>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findCodeMemberStatus(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 設定されているミッションコード一覧を取得します。
         * @summary ミッションコード一覧の取得
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findCodeMissions(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Missions>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findCodeMissions(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * スキル詳細のメタID毎のサジェスト一覧を取得します。
         * @summary スキル詳細のメタID毎のサジェスト一覧の取得
         * @param {number} metaId メタID
         * @param {string} [search] 検索キーワード
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findCodeSkillSuggests(metaId: number, search?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Keyvaluepairs>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findCodeSkillSuggests(metaId, search, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 設定されているスキル種別コード一覧を取得します。
         * @summary スキル種別コード一覧の取得
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findCodeSkillTypes(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SkillTypes>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findCodeSkillTypes(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * CodeApi - factory interface
 * @export
 */
export const CodeApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CodeApiFp(configuration)
    return {
        /**
         * 用意されているバッジコード一覧を取得します。
         * @summary バッジコード一覧の取得
         * @param {string} [category] バッジのカテゴリー
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findCodeBadges(category?: string, options?: any): AxiosPromise<Badges> {
            return localVarFp.findCodeBadges(category, options).then((request) => request(axios, basePath));
        },
        /**
         * 用意されているプランコード一覧を取得します。
         * @summary プランコード一覧の取得
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findCodeBillingPlans(options?: any): AxiosPromise<BillingPlans> {
            return localVarFp.findCodeBillingPlans(options).then((request) => request(axios, basePath));
        },
        /**
         * フィールドタイプ一覧を取得します。
         * @summary フィールドタイプ一覧
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findCodeFieldTypes(options?: any): AxiosPromise<FieldTypesResp> {
            return localVarFp.findCodeFieldTypes(options).then((request) => request(axios, basePath));
        },
        /**
         * インテグレーションコードの一覧を取得します。
         * @summary インテグレーションコード一覧の取得
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findCodeIntegrations(options?: any): AxiosPromise<Integrations> {
            return localVarFp.findCodeIntegrations(options).then((request) => request(axios, basePath));
        },
        /**
         * 用意されているメンバー状態のコード一覧を取得します。
         * @summary メンバー状態コード一覧の取得
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findCodeMemberStatus(options?: any): AxiosPromise<Statuses> {
            return localVarFp.findCodeMemberStatus(options).then((request) => request(axios, basePath));
        },
        /**
         * 設定されているミッションコード一覧を取得します。
         * @summary ミッションコード一覧の取得
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findCodeMissions(options?: any): AxiosPromise<Missions> {
            return localVarFp.findCodeMissions(options).then((request) => request(axios, basePath));
        },
        /**
         * スキル詳細のメタID毎のサジェスト一覧を取得します。
         * @summary スキル詳細のメタID毎のサジェスト一覧の取得
         * @param {number} metaId メタID
         * @param {string} [search] 検索キーワード
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findCodeSkillSuggests(metaId: number, search?: string, options?: any): AxiosPromise<Keyvaluepairs> {
            return localVarFp.findCodeSkillSuggests(metaId, search, options).then((request) => request(axios, basePath));
        },
        /**
         * 設定されているスキル種別コード一覧を取得します。
         * @summary スキル種別コード一覧の取得
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findCodeSkillTypes(options?: any): AxiosPromise<SkillTypes> {
            return localVarFp.findCodeSkillTypes(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CodeApi - object-oriented interface
 * @export
 * @class CodeApi
 * @extends {BaseAPI}
 */
export class CodeApi extends BaseAPI {
    /**
     * 用意されているバッジコード一覧を取得します。
     * @summary バッジコード一覧の取得
     * @param {string} [category] バッジのカテゴリー
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CodeApi
     */
    public async findCodeBadges(category?: string, options?: AxiosRequestConfig) {
        const retry = 2;
        let resp;
        for (let i = 0; i < retry; i++) {
            resp = await CodeApiFp(this.configuration).findCodeBadges(category, options).then((request) => request(this.axios, this.basePath)).catch((error) => {
                if (error?.response) {
                    return error.response;
                } else {
                    return { status: 500 };
                }
            });
            if (resp?.status === 401) {
                await refreshIdToken();
                console.debug('Retry findCodeBadges ' + i + '/' + (retry - 1));
                continue;
            }
            break;
        }
        return resp;
    }

    /**
     * 用意されているプランコード一覧を取得します。
     * @summary プランコード一覧の取得
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CodeApi
     */
    public async findCodeBillingPlans(options?: AxiosRequestConfig) {
        const retry = 2;
        let resp;
        for (let i = 0; i < retry; i++) {
            resp = await CodeApiFp(this.configuration).findCodeBillingPlans(options).then((request) => request(this.axios, this.basePath)).catch((error) => {
                if (error?.response) {
                    return error.response;
                } else {
                    return { status: 500 };
                }
            });
            if (resp?.status === 401) {
                await refreshIdToken();
                console.debug('Retry findCodeBillingPlans ' + i + '/' + (retry - 1));
                continue;
            }
            break;
        }
        return resp;
    }

    /**
     * フィールドタイプ一覧を取得します。
     * @summary フィールドタイプ一覧
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CodeApi
     */
    public async findCodeFieldTypes(options?: AxiosRequestConfig) {
        const retry = 2;
        let resp;
        for (let i = 0; i < retry; i++) {
            resp = await CodeApiFp(this.configuration).findCodeFieldTypes(options).then((request) => request(this.axios, this.basePath)).catch((error) => {
                if (error?.response) {
                    return error.response;
                } else {
                    return { status: 500 };
                }
            });
            if (resp?.status === 401) {
                await refreshIdToken();
                console.debug('Retry findCodeFieldTypes ' + i + '/' + (retry - 1));
                continue;
            }
            break;
        }
        return resp;
    }

    /**
     * インテグレーションコードの一覧を取得します。
     * @summary インテグレーションコード一覧の取得
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CodeApi
     */
    public async findCodeIntegrations(options?: AxiosRequestConfig) {
        const retry = 2;
        let resp;
        for (let i = 0; i < retry; i++) {
            resp = await CodeApiFp(this.configuration).findCodeIntegrations(options).then((request) => request(this.axios, this.basePath)).catch((error) => {
                if (error?.response) {
                    return error.response;
                } else {
                    return { status: 500 };
                }
            });
            if (resp?.status === 401) {
                await refreshIdToken();
                console.debug('Retry findCodeIntegrations ' + i + '/' + (retry - 1));
                continue;
            }
            break;
        }
        return resp;
    }

    /**
     * 用意されているメンバー状態のコード一覧を取得します。
     * @summary メンバー状態コード一覧の取得
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CodeApi
     */
    public async findCodeMemberStatus(options?: AxiosRequestConfig) {
        const retry = 2;
        let resp;
        for (let i = 0; i < retry; i++) {
            resp = await CodeApiFp(this.configuration).findCodeMemberStatus(options).then((request) => request(this.axios, this.basePath)).catch((error) => {
                if (error?.response) {
                    return error.response;
                } else {
                    return { status: 500 };
                }
            });
            if (resp?.status === 401) {
                await refreshIdToken();
                console.debug('Retry findCodeMemberStatus ' + i + '/' + (retry - 1));
                continue;
            }
            break;
        }
        return resp;
    }

    /**
     * 設定されているミッションコード一覧を取得します。
     * @summary ミッションコード一覧の取得
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CodeApi
     */
    public async findCodeMissions(options?: AxiosRequestConfig) {
        const retry = 2;
        let resp;
        for (let i = 0; i < retry; i++) {
            resp = await CodeApiFp(this.configuration).findCodeMissions(options).then((request) => request(this.axios, this.basePath)).catch((error) => {
                if (error?.response) {
                    return error.response;
                } else {
                    return { status: 500 };
                }
            });
            if (resp?.status === 401) {
                await refreshIdToken();
                console.debug('Retry findCodeMissions ' + i + '/' + (retry - 1));
                continue;
            }
            break;
        }
        return resp;
    }

    /**
     * スキル詳細のメタID毎のサジェスト一覧を取得します。
     * @summary スキル詳細のメタID毎のサジェスト一覧の取得
     * @param {number} metaId メタID
     * @param {string} [search] 検索キーワード
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CodeApi
     */
    public async findCodeSkillSuggests(metaId: number, search?: string, options?: AxiosRequestConfig) {
        const retry = 2;
        let resp;
        for (let i = 0; i < retry; i++) {
            resp = await CodeApiFp(this.configuration).findCodeSkillSuggests(metaId, search, options).then((request) => request(this.axios, this.basePath)).catch((error) => {
                if (error?.response) {
                    return error.response;
                } else {
                    return { status: 500 };
                }
            });
            if (resp?.status === 401) {
                await refreshIdToken();
                console.debug('Retry findCodeSkillSuggests ' + i + '/' + (retry - 1));
                continue;
            }
            break;
        }
        return resp;
    }

    /**
     * 設定されているスキル種別コード一覧を取得します。
     * @summary スキル種別コード一覧の取得
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CodeApi
     */
    public async findCodeSkillTypes(options?: AxiosRequestConfig) {
        const retry = 2;
        let resp;
        for (let i = 0; i < retry; i++) {
            resp = await CodeApiFp(this.configuration).findCodeSkillTypes(options).then((request) => request(this.axios, this.basePath)).catch((error) => {
                if (error?.response) {
                    return error.response;
                } else {
                    return { status: 500 };
                }
            });
            if (resp?.status === 401) {
                await refreshIdToken();
                console.debug('Retry findCodeSkillTypes ' + i + '/' + (retry - 1));
                continue;
            }
            break;
        }
        return resp;
    }
}


/**
 * DevicesApi - axios parameter creator
 * @export
 */
export const DevicesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * デバイスのPUSH通知用トークンを保存します。
         * @summary デバイスのPUSH通知用トークン保存
         * @param {TokenReq} tokenReq テーブル情報
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveDeviceToken: async (tokenReq: TokenReq, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tokenReq' is not null or undefined
            assertParamExists('saveDeviceToken', 'tokenReq', tokenReq)
            const localVarPath = `/devices/token`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(tokenReq, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DevicesApi - functional programming interface
 * @export
 */
export const DevicesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DevicesApiAxiosParamCreator(configuration)
    return {
        /**
         * デバイスのPUSH通知用トークンを保存します。
         * @summary デバイスのPUSH通知用トークン保存
         * @param {TokenReq} tokenReq テーブル情報
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async saveDeviceToken(tokenReq: TokenReq, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TokenResp>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.saveDeviceToken(tokenReq, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * DevicesApi - factory interface
 * @export
 */
export const DevicesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DevicesApiFp(configuration)
    return {
        /**
         * デバイスのPUSH通知用トークンを保存します。
         * @summary デバイスのPUSH通知用トークン保存
         * @param {TokenReq} tokenReq テーブル情報
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveDeviceToken(tokenReq: TokenReq, options?: any): AxiosPromise<TokenResp> {
            return localVarFp.saveDeviceToken(tokenReq, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DevicesApi - object-oriented interface
 * @export
 * @class DevicesApi
 * @extends {BaseAPI}
 */
export class DevicesApi extends BaseAPI {
    /**
     * デバイスのPUSH通知用トークンを保存します。
     * @summary デバイスのPUSH通知用トークン保存
     * @param {TokenReq} tokenReq テーブル情報
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DevicesApi
     */
    public async saveDeviceToken(tokenReq: TokenReq, options?: AxiosRequestConfig) {
        const retry = 2;
        let resp;
        for (let i = 0; i < retry; i++) {
            resp = await DevicesApiFp(this.configuration).saveDeviceToken(tokenReq, options).then((request) => request(this.axios, this.basePath)).catch((error) => {
                if (error?.response) {
                    return error.response;
                } else {
                    return { status: 500 };
                }
            });
            if (resp?.status === 401) {
                await refreshIdToken();
                console.debug('Retry saveDeviceToken ' + i + '/' + (retry - 1));
                continue;
            }
            break;
        }
        return resp;
    }
}


/**
 * ExportApi - axios parameter creator
 * @export
 */
export const ExportApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * チームのタスク情報をCSV出力します。
         * @summary チームのタスク情報をCSV出力
         * @param {ExportTeamTasksRequest} exportTeamTasksRequest チームタスク取得情報
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        exportTeamTasksToCSV: async (exportTeamTasksRequest: ExportTeamTasksRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'exportTeamTasksRequest' is not null or undefined
            assertParamExists('exportTeamTasksToCSV', 'exportTeamTasksRequest', exportTeamTasksRequest)
            const localVarPath = `/export/csv/teams/tasks`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(exportTeamTasksRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ExportApi - functional programming interface
 * @export
 */
export const ExportApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ExportApiAxiosParamCreator(configuration)
    return {
        /**
         * チームのタスク情報をCSV出力します。
         * @summary チームのタスク情報をCSV出力
         * @param {ExportTeamTasksRequest} exportTeamTasksRequest チームタスク取得情報
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async exportTeamTasksToCSV(exportTeamTasksRequest: ExportTeamTasksRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ExportTeamTasksCsv>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.exportTeamTasksToCSV(exportTeamTasksRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ExportApi - factory interface
 * @export
 */
export const ExportApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ExportApiFp(configuration)
    return {
        /**
         * チームのタスク情報をCSV出力します。
         * @summary チームのタスク情報をCSV出力
         * @param {ExportTeamTasksRequest} exportTeamTasksRequest チームタスク取得情報
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        exportTeamTasksToCSV(exportTeamTasksRequest: ExportTeamTasksRequest, options?: any): AxiosPromise<ExportTeamTasksCsv> {
            return localVarFp.exportTeamTasksToCSV(exportTeamTasksRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ExportApi - object-oriented interface
 * @export
 * @class ExportApi
 * @extends {BaseAPI}
 */
export class ExportApi extends BaseAPI {
    /**
     * チームのタスク情報をCSV出力します。
     * @summary チームのタスク情報をCSV出力
     * @param {ExportTeamTasksRequest} exportTeamTasksRequest チームタスク取得情報
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExportApi
     */
    public async exportTeamTasksToCSV(exportTeamTasksRequest: ExportTeamTasksRequest, options?: AxiosRequestConfig) {
        const retry = 2;
        let resp;
        for (let i = 0; i < retry; i++) {
            resp = await ExportApiFp(this.configuration).exportTeamTasksToCSV(exportTeamTasksRequest, options).then((request) => request(this.axios, this.basePath)).catch((error) => {
                if (error?.response) {
                    return error.response;
                } else {
                    return { status: 500 };
                }
            });
            if (resp?.status === 401) {
                await refreshIdToken();
                console.debug('Retry exportTeamTasksToCSV ' + i + '/' + (retry - 1));
                continue;
            }
            break;
        }
        return resp;
    }
}


/**
 * FavoriteApi - axios parameter creator
 * @export
 */
export const FavoriteApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * お気に入りページの登録に新しいページを登録します。
         * @summary お気に入りページに登録
         * @param {FavoritePageReq} favoritePageReq 追加するページへのリンクと登録名
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addFavoritePage: async (favoritePageReq: FavoritePageReq, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'favoritePageReq' is not null or undefined
            assertParamExists('addFavoritePage', 'favoritePageReq', favoritePageReq)
            const localVarPath = `/favorite/pages`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(favoritePageReq, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 指定したお気に入りページの登録を削除します。
         * @summary お気に入りページの削除
         * @param {FavoritePageReq} favoritePageReq 削除するページの登録名
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteFavoritePage: async (favoritePageReq: FavoritePageReq, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'favoritePageReq' is not null or undefined
            assertParamExists('deleteFavoritePage', 'favoritePageReq', favoritePageReq)
            const localVarPath = `/favorite/pages`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(favoritePageReq, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * ユーザがお気に入りに登録したページ一覧を取得します。
         * @summary お気に入り登録されたページの取得
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findFavoritePages: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/favorite/pages`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * お気に入りページの並び替えを登録します。
         * @summary お気に入りページの並び替え
         * @param {FavoritePagesReq} favoritePagesReq 追加するページへのリンクと登録名
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sortFavoritePages: async (favoritePagesReq: FavoritePagesReq, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'favoritePagesReq' is not null or undefined
            assertParamExists('sortFavoritePages', 'favoritePagesReq', favoritePagesReq)
            const localVarPath = `/favorite/pages`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(favoritePagesReq, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * FavoriteApi - functional programming interface
 * @export
 */
export const FavoriteApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = FavoriteApiAxiosParamCreator(configuration)
    return {
        /**
         * お気に入りページの登録に新しいページを登録します。
         * @summary お気に入りページに登録
         * @param {FavoritePageReq} favoritePageReq 追加するページへのリンクと登録名
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addFavoritePage(favoritePageReq: FavoritePageReq, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addFavoritePage(favoritePageReq, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 指定したお気に入りページの登録を削除します。
         * @summary お気に入りページの削除
         * @param {FavoritePageReq} favoritePageReq 削除するページの登録名
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteFavoritePage(favoritePageReq: FavoritePageReq, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteFavoritePage(favoritePageReq, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * ユーザがお気に入りに登録したページ一覧を取得します。
         * @summary お気に入り登録されたページの取得
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findFavoritePages(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FavoritePages>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findFavoritePages(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * お気に入りページの並び替えを登録します。
         * @summary お気に入りページの並び替え
         * @param {FavoritePagesReq} favoritePagesReq 追加するページへのリンクと登録名
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async sortFavoritePages(favoritePagesReq: FavoritePagesReq, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FavoritePages>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.sortFavoritePages(favoritePagesReq, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * FavoriteApi - factory interface
 * @export
 */
export const FavoriteApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = FavoriteApiFp(configuration)
    return {
        /**
         * お気に入りページの登録に新しいページを登録します。
         * @summary お気に入りページに登録
         * @param {FavoritePageReq} favoritePageReq 追加するページへのリンクと登録名
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addFavoritePage(favoritePageReq: FavoritePageReq, options?: any): AxiosPromise<void> {
            return localVarFp.addFavoritePage(favoritePageReq, options).then((request) => request(axios, basePath));
        },
        /**
         * 指定したお気に入りページの登録を削除します。
         * @summary お気に入りページの削除
         * @param {FavoritePageReq} favoritePageReq 削除するページの登録名
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteFavoritePage(favoritePageReq: FavoritePageReq, options?: any): AxiosPromise<void> {
            return localVarFp.deleteFavoritePage(favoritePageReq, options).then((request) => request(axios, basePath));
        },
        /**
         * ユーザがお気に入りに登録したページ一覧を取得します。
         * @summary お気に入り登録されたページの取得
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findFavoritePages(options?: any): AxiosPromise<FavoritePages> {
            return localVarFp.findFavoritePages(options).then((request) => request(axios, basePath));
        },
        /**
         * お気に入りページの並び替えを登録します。
         * @summary お気に入りページの並び替え
         * @param {FavoritePagesReq} favoritePagesReq 追加するページへのリンクと登録名
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sortFavoritePages(favoritePagesReq: FavoritePagesReq, options?: any): AxiosPromise<FavoritePages> {
            return localVarFp.sortFavoritePages(favoritePagesReq, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * FavoriteApi - object-oriented interface
 * @export
 * @class FavoriteApi
 * @extends {BaseAPI}
 */
export class FavoriteApi extends BaseAPI {
    /**
     * お気に入りページの登録に新しいページを登録します。
     * @summary お気に入りページに登録
     * @param {FavoritePageReq} favoritePageReq 追加するページへのリンクと登録名
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FavoriteApi
     */
    public async addFavoritePage(favoritePageReq: FavoritePageReq, options?: AxiosRequestConfig) {
        const retry = 2;
        let resp;
        for (let i = 0; i < retry; i++) {
            resp = await FavoriteApiFp(this.configuration).addFavoritePage(favoritePageReq, options).then((request) => request(this.axios, this.basePath)).catch((error) => {
                if (error?.response) {
                    return error.response;
                } else {
                    return { status: 500 };
                }
            });
            if (resp?.status === 401) {
                await refreshIdToken();
                console.debug('Retry addFavoritePage ' + i + '/' + (retry - 1));
                continue;
            }
            break;
        }
        return resp;
    }

    /**
     * 指定したお気に入りページの登録を削除します。
     * @summary お気に入りページの削除
     * @param {FavoritePageReq} favoritePageReq 削除するページの登録名
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FavoriteApi
     */
    public async deleteFavoritePage(favoritePageReq: FavoritePageReq, options?: AxiosRequestConfig) {
        const retry = 2;
        let resp;
        for (let i = 0; i < retry; i++) {
            resp = await FavoriteApiFp(this.configuration).deleteFavoritePage(favoritePageReq, options).then((request) => request(this.axios, this.basePath)).catch((error) => {
                if (error?.response) {
                    return error.response;
                } else {
                    return { status: 500 };
                }
            });
            if (resp?.status === 401) {
                await refreshIdToken();
                console.debug('Retry deleteFavoritePage ' + i + '/' + (retry - 1));
                continue;
            }
            break;
        }
        return resp;
    }

    /**
     * ユーザがお気に入りに登録したページ一覧を取得します。
     * @summary お気に入り登録されたページの取得
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FavoriteApi
     */
    public async findFavoritePages(options?: AxiosRequestConfig) {
        const retry = 2;
        let resp;
        for (let i = 0; i < retry; i++) {
            resp = await FavoriteApiFp(this.configuration).findFavoritePages(options).then((request) => request(this.axios, this.basePath)).catch((error) => {
                if (error?.response) {
                    return error.response;
                } else {
                    return { status: 500 };
                }
            });
            if (resp?.status === 401) {
                await refreshIdToken();
                console.debug('Retry findFavoritePages ' + i + '/' + (retry - 1));
                continue;
            }
            break;
        }
        return resp;
    }

    /**
     * お気に入りページの並び替えを登録します。
     * @summary お気に入りページの並び替え
     * @param {FavoritePagesReq} favoritePagesReq 追加するページへのリンクと登録名
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FavoriteApi
     */
    public async sortFavoritePages(favoritePagesReq: FavoritePagesReq, options?: AxiosRequestConfig) {
        const retry = 2;
        let resp;
        for (let i = 0; i < retry; i++) {
            resp = await FavoriteApiFp(this.configuration).sortFavoritePages(favoritePagesReq, options).then((request) => request(this.axios, this.basePath)).catch((error) => {
                if (error?.response) {
                    return error.response;
                } else {
                    return { status: 500 };
                }
            });
            if (resp?.status === 401) {
                await refreshIdToken();
                console.debug('Retry sortFavoritePages ' + i + '/' + (retry - 1));
                continue;
            }
            break;
        }
        return resp;
    }
}


/**
 * GameApi - axios parameter creator
 * @export
 */
export const GameApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * スキミー探索をキャンセルします。
         * @summary スキミー探索をキャンセル
         * @param {number} searchId 探索ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cancelSkimmySearch: async (searchId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'searchId' is not null or undefined
            assertParamExists('cancelSkimmySearch', 'searchId', searchId)
            const localVarPath = `/game/search/cancel/{search_id}`
                .replace(`{${"search_id"}}`, encodeURIComponent(String(searchId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 遊び場を確認します。
         * @summary 遊び場確認
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        checkPlayArea: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/game/play_area/check`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * スキミー探索をすぐに完了します。
         * @summary スキミー探索をすぐに完了
         * @param {number} searchId 探索ID
         * @param {number} gems ジェム数
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        endSkimmySearch: async (searchId: number, gems: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'searchId' is not null or undefined
            assertParamExists('endSkimmySearch', 'searchId', searchId)
            // verify required parameter 'gems' is not null or undefined
            assertParamExists('endSkimmySearch', 'gems', gems)
            const localVarPath = `/game/search/end/{search_id}/{gems}`
                .replace(`{${"search_id"}}`, encodeURIComponent(String(searchId)))
                .replace(`{${"gems"}}`, encodeURIComponent(String(gems)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * スキミーを突破します。
         * @summary スキミー突破
         * @param {number} skimmyId スキミーID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        evolveSkimmy: async (skimmyId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'skimmyId' is not null or undefined
            assertParamExists('evolveSkimmy', 'skimmyId', skimmyId)
            const localVarPath = `/game/level_up/evolve/{skimmy_id}`
                .replace(`{${"skimmy_id"}}`, encodeURIComponent(String(skimmyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 探索エリアを取得します。
         * @summary 探索エリア取得
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findSearchAreas: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/game/search/areas`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * ゲームログインボーナスを取得します。
         * @summary ゲームログインボーナス取得
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getGameLoginBonus: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/game/login_bonus`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * スキミーレベルアップ情報を取得します。
         * @summary スキミーレベルアップ情報取得
         * @param {number} skimmyId スキミーID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSkimmyLevelUpInfo: async (skimmyId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'skimmyId' is not null or undefined
            assertParamExists('getSkimmyLevelUpInfo', 'skimmyId', skimmyId)
            const localVarPath = `/game/level_up/{skimmy_id}`
                .replace(`{${"skimmy_id"}}`, encodeURIComponent(String(skimmyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * スキミーパラメーター情報を取得します。
         * @summary スキミーパラメーター情報取得
         * @param {number} skimmyId スキミーID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSkimmyParameters: async (skimmyId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'skimmyId' is not null or undefined
            assertParamExists('getSkimmyParameters', 'skimmyId', skimmyId)
            const localVarPath = `/game/skimmy/parameters/{skimmy_id}`
                .replace(`{${"skimmy_id"}}`, encodeURIComponent(String(skimmyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * スキミー探索情報を取得します。
         * @summary スキミー探索情報取得
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSkimmySearch: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/game/search/skimmy`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * スキミー探索ボーナス情報を取得します。
         * @summary スキミー探索ボーナス情報取得
         * @param {number} searchId 探索ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSkimmySearchBonus: async (searchId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'searchId' is not null or undefined
            assertParamExists('getSkimmySearchBonus', 'searchId', searchId)
            const localVarPath = `/game/search/bonus/{search_id}`
                .replace(`{${"search_id"}}`, encodeURIComponent(String(searchId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * スキミー経験値とレベルを更新します。
         * @summary スキミー経験値とレベルの更新
         * @param {SkimmyLevelUpReq} skimmyLevelUpReq スキミーの経験値とレベルアップ情報
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        levelUpSkimmy: async (skimmyLevelUpReq: SkimmyLevelUpReq, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'skimmyLevelUpReq' is not null or undefined
            assertParamExists('levelUpSkimmy', 'skimmyLevelUpReq', skimmyLevelUpReq)
            const localVarPath = `/game/level_up/increase_level`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(skimmyLevelUpReq, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * スキミー探索を登録します。
         * @summary スキミー探索の登録
         * @param {SkimmySearchReq} skimmySearchReq スキミー探索情報
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setSkimmySearch: async (skimmySearchReq: SkimmySearchReq, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'skimmySearchReq' is not null or undefined
            assertParamExists('setSkimmySearch', 'skimmySearchReq', skimmySearchReq)
            const localVarPath = `/game/search/skimmy`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(skimmySearchReq, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * GameApi - functional programming interface
 * @export
 */
export const GameApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = GameApiAxiosParamCreator(configuration)
    return {
        /**
         * スキミー探索をキャンセルします。
         * @summary スキミー探索をキャンセル
         * @param {number} searchId 探索ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async cancelSkimmySearch(searchId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.cancelSkimmySearch(searchId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 遊び場を確認します。
         * @summary 遊び場確認
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async checkPlayArea(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PlayAreaCheck>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.checkPlayArea(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * スキミー探索をすぐに完了します。
         * @summary スキミー探索をすぐに完了
         * @param {number} searchId 探索ID
         * @param {number} gems ジェム数
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async endSkimmySearch(searchId: number, gems: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.endSkimmySearch(searchId, gems, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * スキミーを突破します。
         * @summary スキミー突破
         * @param {number} skimmyId スキミーID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async evolveSkimmy(skimmyId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.evolveSkimmy(skimmyId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 探索エリアを取得します。
         * @summary 探索エリア取得
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findSearchAreas(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SearchAreas>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findSearchAreas(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * ゲームログインボーナスを取得します。
         * @summary ゲームログインボーナス取得
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getGameLoginBonus(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GameLoginBonus>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getGameLoginBonus(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * スキミーレベルアップ情報を取得します。
         * @summary スキミーレベルアップ情報取得
         * @param {number} skimmyId スキミーID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSkimmyLevelUpInfo(skimmyId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SkimmyLevelUpInfo>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSkimmyLevelUpInfo(skimmyId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * スキミーパラメーター情報を取得します。
         * @summary スキミーパラメーター情報取得
         * @param {number} skimmyId スキミーID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSkimmyParameters(skimmyId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SkimmyParameters>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSkimmyParameters(skimmyId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * スキミー探索情報を取得します。
         * @summary スキミー探索情報取得
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSkimmySearch(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SkimmySearchInfo>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSkimmySearch(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * スキミー探索ボーナス情報を取得します。
         * @summary スキミー探索ボーナス情報取得
         * @param {number} searchId 探索ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSkimmySearchBonus(searchId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SkimmySearchBonus>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSkimmySearchBonus(searchId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * スキミー経験値とレベルを更新します。
         * @summary スキミー経験値とレベルの更新
         * @param {SkimmyLevelUpReq} skimmyLevelUpReq スキミーの経験値とレベルアップ情報
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async levelUpSkimmy(skimmyLevelUpReq: SkimmyLevelUpReq, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.levelUpSkimmy(skimmyLevelUpReq, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * スキミー探索を登録します。
         * @summary スキミー探索の登録
         * @param {SkimmySearchReq} skimmySearchReq スキミー探索情報
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async setSkimmySearch(skimmySearchReq: SkimmySearchReq, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.setSkimmySearch(skimmySearchReq, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * GameApi - factory interface
 * @export
 */
export const GameApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = GameApiFp(configuration)
    return {
        /**
         * スキミー探索をキャンセルします。
         * @summary スキミー探索をキャンセル
         * @param {number} searchId 探索ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cancelSkimmySearch(searchId: number, options?: any): AxiosPromise<void> {
            return localVarFp.cancelSkimmySearch(searchId, options).then((request) => request(axios, basePath));
        },
        /**
         * 遊び場を確認します。
         * @summary 遊び場確認
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        checkPlayArea(options?: any): AxiosPromise<PlayAreaCheck> {
            return localVarFp.checkPlayArea(options).then((request) => request(axios, basePath));
        },
        /**
         * スキミー探索をすぐに完了します。
         * @summary スキミー探索をすぐに完了
         * @param {number} searchId 探索ID
         * @param {number} gems ジェム数
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        endSkimmySearch(searchId: number, gems: number, options?: any): AxiosPromise<void> {
            return localVarFp.endSkimmySearch(searchId, gems, options).then((request) => request(axios, basePath));
        },
        /**
         * スキミーを突破します。
         * @summary スキミー突破
         * @param {number} skimmyId スキミーID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        evolveSkimmy(skimmyId: number, options?: any): AxiosPromise<void> {
            return localVarFp.evolveSkimmy(skimmyId, options).then((request) => request(axios, basePath));
        },
        /**
         * 探索エリアを取得します。
         * @summary 探索エリア取得
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findSearchAreas(options?: any): AxiosPromise<SearchAreas> {
            return localVarFp.findSearchAreas(options).then((request) => request(axios, basePath));
        },
        /**
         * ゲームログインボーナスを取得します。
         * @summary ゲームログインボーナス取得
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getGameLoginBonus(options?: any): AxiosPromise<GameLoginBonus> {
            return localVarFp.getGameLoginBonus(options).then((request) => request(axios, basePath));
        },
        /**
         * スキミーレベルアップ情報を取得します。
         * @summary スキミーレベルアップ情報取得
         * @param {number} skimmyId スキミーID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSkimmyLevelUpInfo(skimmyId: number, options?: any): AxiosPromise<SkimmyLevelUpInfo> {
            return localVarFp.getSkimmyLevelUpInfo(skimmyId, options).then((request) => request(axios, basePath));
        },
        /**
         * スキミーパラメーター情報を取得します。
         * @summary スキミーパラメーター情報取得
         * @param {number} skimmyId スキミーID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSkimmyParameters(skimmyId: number, options?: any): AxiosPromise<SkimmyParameters> {
            return localVarFp.getSkimmyParameters(skimmyId, options).then((request) => request(axios, basePath));
        },
        /**
         * スキミー探索情報を取得します。
         * @summary スキミー探索情報取得
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSkimmySearch(options?: any): AxiosPromise<SkimmySearchInfo> {
            return localVarFp.getSkimmySearch(options).then((request) => request(axios, basePath));
        },
        /**
         * スキミー探索ボーナス情報を取得します。
         * @summary スキミー探索ボーナス情報取得
         * @param {number} searchId 探索ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSkimmySearchBonus(searchId: number, options?: any): AxiosPromise<SkimmySearchBonus> {
            return localVarFp.getSkimmySearchBonus(searchId, options).then((request) => request(axios, basePath));
        },
        /**
         * スキミー経験値とレベルを更新します。
         * @summary スキミー経験値とレベルの更新
         * @param {SkimmyLevelUpReq} skimmyLevelUpReq スキミーの経験値とレベルアップ情報
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        levelUpSkimmy(skimmyLevelUpReq: SkimmyLevelUpReq, options?: any): AxiosPromise<void> {
            return localVarFp.levelUpSkimmy(skimmyLevelUpReq, options).then((request) => request(axios, basePath));
        },
        /**
         * スキミー探索を登録します。
         * @summary スキミー探索の登録
         * @param {SkimmySearchReq} skimmySearchReq スキミー探索情報
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setSkimmySearch(skimmySearchReq: SkimmySearchReq, options?: any): AxiosPromise<void> {
            return localVarFp.setSkimmySearch(skimmySearchReq, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * GameApi - object-oriented interface
 * @export
 * @class GameApi
 * @extends {BaseAPI}
 */
export class GameApi extends BaseAPI {
    /**
     * スキミー探索をキャンセルします。
     * @summary スキミー探索をキャンセル
     * @param {number} searchId 探索ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GameApi
     */
    public async cancelSkimmySearch(searchId: number, options?: AxiosRequestConfig) {
        const retry = 2;
        let resp;
        for (let i = 0; i < retry; i++) {
            resp = await GameApiFp(this.configuration).cancelSkimmySearch(searchId, options).then((request) => request(this.axios, this.basePath)).catch((error) => {
                if (error?.response) {
                    return error.response;
                } else {
                    return { status: 500 };
                }
            });
            if (resp?.status === 401) {
                await refreshIdToken();
                console.debug('Retry cancelSkimmySearch ' + i + '/' + (retry - 1));
                continue;
            }
            break;
        }
        return resp;
    }

    /**
     * 遊び場を確認します。
     * @summary 遊び場確認
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GameApi
     */
    public async checkPlayArea(options?: AxiosRequestConfig) {
        const retry = 2;
        let resp;
        for (let i = 0; i < retry; i++) {
            resp = await GameApiFp(this.configuration).checkPlayArea(options).then((request) => request(this.axios, this.basePath)).catch((error) => {
                if (error?.response) {
                    return error.response;
                } else {
                    return { status: 500 };
                }
            });
            if (resp?.status === 401) {
                await refreshIdToken();
                console.debug('Retry checkPlayArea ' + i + '/' + (retry - 1));
                continue;
            }
            break;
        }
        return resp;
    }

    /**
     * スキミー探索をすぐに完了します。
     * @summary スキミー探索をすぐに完了
     * @param {number} searchId 探索ID
     * @param {number} gems ジェム数
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GameApi
     */
    public async endSkimmySearch(searchId: number, gems: number, options?: AxiosRequestConfig) {
        const retry = 2;
        let resp;
        for (let i = 0; i < retry; i++) {
            resp = await GameApiFp(this.configuration).endSkimmySearch(searchId, gems, options).then((request) => request(this.axios, this.basePath)).catch((error) => {
                if (error?.response) {
                    return error.response;
                } else {
                    return { status: 500 };
                }
            });
            if (resp?.status === 401) {
                await refreshIdToken();
                console.debug('Retry endSkimmySearch ' + i + '/' + (retry - 1));
                continue;
            }
            break;
        }
        return resp;
    }

    /**
     * スキミーを突破します。
     * @summary スキミー突破
     * @param {number} skimmyId スキミーID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GameApi
     */
    public async evolveSkimmy(skimmyId: number, options?: AxiosRequestConfig) {
        const retry = 2;
        let resp;
        for (let i = 0; i < retry; i++) {
            resp = await GameApiFp(this.configuration).evolveSkimmy(skimmyId, options).then((request) => request(this.axios, this.basePath)).catch((error) => {
                if (error?.response) {
                    return error.response;
                } else {
                    return { status: 500 };
                }
            });
            if (resp?.status === 401) {
                await refreshIdToken();
                console.debug('Retry evolveSkimmy ' + i + '/' + (retry - 1));
                continue;
            }
            break;
        }
        return resp;
    }

    /**
     * 探索エリアを取得します。
     * @summary 探索エリア取得
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GameApi
     */
    public async findSearchAreas(options?: AxiosRequestConfig) {
        const retry = 2;
        let resp;
        for (let i = 0; i < retry; i++) {
            resp = await GameApiFp(this.configuration).findSearchAreas(options).then((request) => request(this.axios, this.basePath)).catch((error) => {
                if (error?.response) {
                    return error.response;
                } else {
                    return { status: 500 };
                }
            });
            if (resp?.status === 401) {
                await refreshIdToken();
                console.debug('Retry findSearchAreas ' + i + '/' + (retry - 1));
                continue;
            }
            break;
        }
        return resp;
    }

    /**
     * ゲームログインボーナスを取得します。
     * @summary ゲームログインボーナス取得
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GameApi
     */
    public async getGameLoginBonus(options?: AxiosRequestConfig) {
        const retry = 2;
        let resp;
        for (let i = 0; i < retry; i++) {
            resp = await GameApiFp(this.configuration).getGameLoginBonus(options).then((request) => request(this.axios, this.basePath)).catch((error) => {
                if (error?.response) {
                    return error.response;
                } else {
                    return { status: 500 };
                }
            });
            if (resp?.status === 401) {
                await refreshIdToken();
                console.debug('Retry getGameLoginBonus ' + i + '/' + (retry - 1));
                continue;
            }
            break;
        }
        return resp;
    }

    /**
     * スキミーレベルアップ情報を取得します。
     * @summary スキミーレベルアップ情報取得
     * @param {number} skimmyId スキミーID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GameApi
     */
    public async getSkimmyLevelUpInfo(skimmyId: number, options?: AxiosRequestConfig) {
        const retry = 2;
        let resp;
        for (let i = 0; i < retry; i++) {
            resp = await GameApiFp(this.configuration).getSkimmyLevelUpInfo(skimmyId, options).then((request) => request(this.axios, this.basePath)).catch((error) => {
                if (error?.response) {
                    return error.response;
                } else {
                    return { status: 500 };
                }
            });
            if (resp?.status === 401) {
                await refreshIdToken();
                console.debug('Retry getSkimmyLevelUpInfo ' + i + '/' + (retry - 1));
                continue;
            }
            break;
        }
        return resp;
    }

    /**
     * スキミーパラメーター情報を取得します。
     * @summary スキミーパラメーター情報取得
     * @param {number} skimmyId スキミーID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GameApi
     */
    public async getSkimmyParameters(skimmyId: number, options?: AxiosRequestConfig) {
        const retry = 2;
        let resp;
        for (let i = 0; i < retry; i++) {
            resp = await GameApiFp(this.configuration).getSkimmyParameters(skimmyId, options).then((request) => request(this.axios, this.basePath)).catch((error) => {
                if (error?.response) {
                    return error.response;
                } else {
                    return { status: 500 };
                }
            });
            if (resp?.status === 401) {
                await refreshIdToken();
                console.debug('Retry getSkimmyParameters ' + i + '/' + (retry - 1));
                continue;
            }
            break;
        }
        return resp;
    }

    /**
     * スキミー探索情報を取得します。
     * @summary スキミー探索情報取得
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GameApi
     */
    public async getSkimmySearch(options?: AxiosRequestConfig) {
        const retry = 2;
        let resp;
        for (let i = 0; i < retry; i++) {
            resp = await GameApiFp(this.configuration).getSkimmySearch(options).then((request) => request(this.axios, this.basePath)).catch((error) => {
                if (error?.response) {
                    return error.response;
                } else {
                    return { status: 500 };
                }
            });
            if (resp?.status === 401) {
                await refreshIdToken();
                console.debug('Retry getSkimmySearch ' + i + '/' + (retry - 1));
                continue;
            }
            break;
        }
        return resp;
    }

    /**
     * スキミー探索ボーナス情報を取得します。
     * @summary スキミー探索ボーナス情報取得
     * @param {number} searchId 探索ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GameApi
     */
    public async getSkimmySearchBonus(searchId: number, options?: AxiosRequestConfig) {
        const retry = 2;
        let resp;
        for (let i = 0; i < retry; i++) {
            resp = await GameApiFp(this.configuration).getSkimmySearchBonus(searchId, options).then((request) => request(this.axios, this.basePath)).catch((error) => {
                if (error?.response) {
                    return error.response;
                } else {
                    return { status: 500 };
                }
            });
            if (resp?.status === 401) {
                await refreshIdToken();
                console.debug('Retry getSkimmySearchBonus ' + i + '/' + (retry - 1));
                continue;
            }
            break;
        }
        return resp;
    }

    /**
     * スキミー経験値とレベルを更新します。
     * @summary スキミー経験値とレベルの更新
     * @param {SkimmyLevelUpReq} skimmyLevelUpReq スキミーの経験値とレベルアップ情報
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GameApi
     */
    public async levelUpSkimmy(skimmyLevelUpReq: SkimmyLevelUpReq, options?: AxiosRequestConfig) {
        const retry = 2;
        let resp;
        for (let i = 0; i < retry; i++) {
            resp = await GameApiFp(this.configuration).levelUpSkimmy(skimmyLevelUpReq, options).then((request) => request(this.axios, this.basePath)).catch((error) => {
                if (error?.response) {
                    return error.response;
                } else {
                    return { status: 500 };
                }
            });
            if (resp?.status === 401) {
                await refreshIdToken();
                console.debug('Retry levelUpSkimmy ' + i + '/' + (retry - 1));
                continue;
            }
            break;
        }
        return resp;
    }

    /**
     * スキミー探索を登録します。
     * @summary スキミー探索の登録
     * @param {SkimmySearchReq} skimmySearchReq スキミー探索情報
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GameApi
     */
    public async setSkimmySearch(skimmySearchReq: SkimmySearchReq, options?: AxiosRequestConfig) {
        const retry = 2;
        let resp;
        for (let i = 0; i < retry; i++) {
            resp = await GameApiFp(this.configuration).setSkimmySearch(skimmySearchReq, options).then((request) => request(this.axios, this.basePath)).catch((error) => {
                if (error?.response) {
                    return error.response;
                } else {
                    return { status: 500 };
                }
            });
            if (resp?.status === 401) {
                await refreshIdToken();
                console.debug('Retry setSkimmySearch ' + i + '/' + (retry - 1));
                continue;
            }
            break;
        }
        return resp;
    }
}


/**
 * GemApi - axios parameter creator
 * @export
 */
export const GemApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * ジェム取引を記録します。
         * @summary ジェム取引記録
         * @param {GemTransactionRequest} gemTransactionRequest ジェム取引情報
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        recordGemTransaction: async (gemTransactionRequest: GemTransactionRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'gemTransactionRequest' is not null or undefined
            assertParamExists('recordGemTransaction', 'gemTransactionRequest', gemTransactionRequest)
            const localVarPath = `/gem/transaction`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(gemTransactionRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * GemApi - functional programming interface
 * @export
 */
export const GemApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = GemApiAxiosParamCreator(configuration)
    return {
        /**
         * ジェム取引を記録します。
         * @summary ジェム取引記録
         * @param {GemTransactionRequest} gemTransactionRequest ジェム取引情報
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async recordGemTransaction(gemTransactionRequest: GemTransactionRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GemTransaction>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.recordGemTransaction(gemTransactionRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * GemApi - factory interface
 * @export
 */
export const GemApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = GemApiFp(configuration)
    return {
        /**
         * ジェム取引を記録します。
         * @summary ジェム取引記録
         * @param {GemTransactionRequest} gemTransactionRequest ジェム取引情報
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        recordGemTransaction(gemTransactionRequest: GemTransactionRequest, options?: any): AxiosPromise<GemTransaction> {
            return localVarFp.recordGemTransaction(gemTransactionRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * GemApi - object-oriented interface
 * @export
 * @class GemApi
 * @extends {BaseAPI}
 */
export class GemApi extends BaseAPI {
    /**
     * ジェム取引を記録します。
     * @summary ジェム取引記録
     * @param {GemTransactionRequest} gemTransactionRequest ジェム取引情報
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GemApi
     */
    public async recordGemTransaction(gemTransactionRequest: GemTransactionRequest, options?: AxiosRequestConfig) {
        const retry = 2;
        let resp;
        for (let i = 0; i < retry; i++) {
            resp = await GemApiFp(this.configuration).recordGemTransaction(gemTransactionRequest, options).then((request) => request(this.axios, this.basePath)).catch((error) => {
                if (error?.response) {
                    return error.response;
                } else {
                    return { status: 500 };
                }
            });
            if (resp?.status === 401) {
                await refreshIdToken();
                console.debug('Retry recordGemTransaction ' + i + '/' + (retry - 1));
                continue;
            }
            break;
        }
        return resp;
    }
}


/**
 * GoalApi - axios parameter creator
 * @export
 */
export const GoalApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 自分自身のgoalを追加します。
         * @summary goal追加
         * @param {GoalRequest} goalRequest Goal情報
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addMyGoal: async (goalRequest: GoalRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'goalRequest' is not null or undefined
            assertParamExists('addMyGoal', 'goalRequest', goalRequest)
            const localVarPath = `/goals`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(goalRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * プロジェクトのGoalを追加します。
         * @summary goal追加
         * @param {string} teamname チーム名
         * @param {number} projectOrder プロジェクトオーダー
         * @param {GoalRequest} goalRequest Goal情報
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addTeamProjectGoal: async (teamname: string, projectOrder: number, goalRequest: GoalRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'teamname' is not null or undefined
            assertParamExists('addTeamProjectGoal', 'teamname', teamname)
            // verify required parameter 'projectOrder' is not null or undefined
            assertParamExists('addTeamProjectGoal', 'projectOrder', projectOrder)
            // verify required parameter 'goalRequest' is not null or undefined
            assertParamExists('addTeamProjectGoal', 'goalRequest', goalRequest)
            const localVarPath = `/teams/{teamname}/projects/{project_order}/goals`
                .replace(`{${"teamname"}}`, encodeURIComponent(String(teamname)))
                .replace(`{${"project_order"}}`, encodeURIComponent(String(projectOrder)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(goalRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * チームプロジェクトgoalを削除します。
         * @summary チームプロジェクトgoalの削除
         * @param {string} teamname チーム名
         * @param {number} projectOrder プロジェクトオーダー
         * @param {number} goalId ゴールID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteTeamProjectGoal: async (teamname: string, projectOrder: number, goalId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'teamname' is not null or undefined
            assertParamExists('deleteTeamProjectGoal', 'teamname', teamname)
            // verify required parameter 'projectOrder' is not null or undefined
            assertParamExists('deleteTeamProjectGoal', 'projectOrder', projectOrder)
            // verify required parameter 'goalId' is not null or undefined
            assertParamExists('deleteTeamProjectGoal', 'goalId', goalId)
            const localVarPath = `/teams/{teamname}/projects/{project_order}/goals/{goal_id}/delete`
                .replace(`{${"teamname"}}`, encodeURIComponent(String(teamname)))
                .replace(`{${"project_order"}}`, encodeURIComponent(String(projectOrder)))
                .replace(`{${"goal_id"}}`, encodeURIComponent(String(goalId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 自分自身の期限日超過ゴールのリストを取得します。
         * @summary 期限日超過ゴールリスト取得
         * @param {number} [page] ページ番号
         * @param {number} [perPage] 1ページあたりの要素数 (MAX100)
         * @param {string} [status] ステータス条件
         * @param {number} [categoryId] カテゴリーID
         * @param {string} [search] 検索キーワード
         * @param {string} [sort] ソート条件
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findExceededGoals: async (page?: number, perPage?: number, status?: string, categoryId?: number, search?: string, sort?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/goals/exceeded/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (perPage !== undefined) {
                localVarQueryParameter['per_page'] = perPage;
            }

            if (status !== undefined) {
                localVarQueryParameter['status'] = status;
            }

            if (categoryId !== undefined) {
                localVarQueryParameter['category_id'] = categoryId;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 自分自身のgoalのリストを取得します。
         * @summary goaliリスト取得
         * @param {number} [page] ページ番号
         * @param {number} [perPage] 1ページあたりの要素数 (MAX100)
         * @param {string} [status] ステータス条件
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findMyGoals: async (page?: number, perPage?: number, status?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/goals`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (perPage !== undefined) {
                localVarQueryParameter['per_page'] = perPage;
            }

            if (status !== undefined) {
                localVarQueryParameter['status'] = status;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * プロジェクトのGoalリストを取得します。
         * @summary Goalリスト取得
         * @param {string} teamname チーム名
         * @param {number} projectOrder プロジェクトオーダー
         * @param {number} [page] ページ番号
         * @param {number} [perPage] 1ページあたりの要素数 (MAX100)
         * @param {string} [status] ステータス条件
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findTeamProjectGoals: async (teamname: string, projectOrder: number, page?: number, perPage?: number, status?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'teamname' is not null or undefined
            assertParamExists('findTeamProjectGoals', 'teamname', teamname)
            // verify required parameter 'projectOrder' is not null or undefined
            assertParamExists('findTeamProjectGoals', 'projectOrder', projectOrder)
            const localVarPath = `/teams/{teamname}/projects/{project_order}/goals`
                .replace(`{${"teamname"}}`, encodeURIComponent(String(teamname)))
                .replace(`{${"project_order"}}`, encodeURIComponent(String(projectOrder)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (perPage !== undefined) {
                localVarQueryParameter['per_page'] = perPage;
            }

            if (status !== undefined) {
                localVarQueryParameter['status'] = status;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 自分自身のゴールを取得します。
         * @summary ゴールの取得
         * @param {number} goalId ゴールID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMyGoal: async (goalId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'goalId' is not null or undefined
            assertParamExists('getMyGoal', 'goalId', goalId)
            const localVarPath = `/goals/{goal_id}`
                .replace(`{${"goal_id"}}`, encodeURIComponent(String(goalId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 自分自身のgoalを取得します。
         * @summary goalの取得
         * @param {string} teamname チーム名
         * @param {number} projectOrder プロジェクトオーダー
         * @param {number} goalId ゴールID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTeamProjectGoal: async (teamname: string, projectOrder: number, goalId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'teamname' is not null or undefined
            assertParamExists('getTeamProjectGoal', 'teamname', teamname)
            // verify required parameter 'projectOrder' is not null or undefined
            assertParamExists('getTeamProjectGoal', 'projectOrder', projectOrder)
            // verify required parameter 'goalId' is not null or undefined
            assertParamExists('getTeamProjectGoal', 'goalId', goalId)
            const localVarPath = `/teams/{teamname}/projects/{project_order}/goals/{goal_id}`
                .replace(`{${"teamname"}}`, encodeURIComponent(String(teamname)))
                .replace(`{${"project_order"}}`, encodeURIComponent(String(projectOrder)))
                .replace(`{${"goal_id"}}`, encodeURIComponent(String(goalId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 自分自身のゴールを更新します。
         * @summary ゴール更新
         * @param {number} goalId ゴールID
         * @param {GoalRequest} goalRequest ゴール情報
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setMyGoal: async (goalId: number, goalRequest: GoalRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'goalId' is not null or undefined
            assertParamExists('setMyGoal', 'goalId', goalId)
            // verify required parameter 'goalRequest' is not null or undefined
            assertParamExists('setMyGoal', 'goalRequest', goalRequest)
            const localVarPath = `/goals/{goal_id}`
                .replace(`{${"goal_id"}}`, encodeURIComponent(String(goalId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(goalRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 自分自身のgoalを更新します。
         * @summary goal更新
         * @param {string} teamname チーム名
         * @param {number} projectOrder プロジェクトオーダー
         * @param {number} goalId ゴールID
         * @param {GoalRequest} goalRequest goal情報
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setTeamProjectGoal: async (teamname: string, projectOrder: number, goalId: number, goalRequest: GoalRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'teamname' is not null or undefined
            assertParamExists('setTeamProjectGoal', 'teamname', teamname)
            // verify required parameter 'projectOrder' is not null or undefined
            assertParamExists('setTeamProjectGoal', 'projectOrder', projectOrder)
            // verify required parameter 'goalId' is not null or undefined
            assertParamExists('setTeamProjectGoal', 'goalId', goalId)
            // verify required parameter 'goalRequest' is not null or undefined
            assertParamExists('setTeamProjectGoal', 'goalRequest', goalRequest)
            const localVarPath = `/teams/{teamname}/projects/{project_order}/goals/{goal_id}`
                .replace(`{${"teamname"}}`, encodeURIComponent(String(teamname)))
                .replace(`{${"project_order"}}`, encodeURIComponent(String(projectOrder)))
                .replace(`{${"goal_id"}}`, encodeURIComponent(String(goalId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(goalRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * GoalApi - functional programming interface
 * @export
 */
export const GoalApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = GoalApiAxiosParamCreator(configuration)
    return {
        /**
         * 自分自身のgoalを追加します。
         * @summary goal追加
         * @param {GoalRequest} goalRequest Goal情報
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addMyGoal(goalRequest: GoalRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Goal>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addMyGoal(goalRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * プロジェクトのGoalを追加します。
         * @summary goal追加
         * @param {string} teamname チーム名
         * @param {number} projectOrder プロジェクトオーダー
         * @param {GoalRequest} goalRequest Goal情報
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addTeamProjectGoal(teamname: string, projectOrder: number, goalRequest: GoalRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Goal>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addTeamProjectGoal(teamname, projectOrder, goalRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * チームプロジェクトgoalを削除します。
         * @summary チームプロジェクトgoalの削除
         * @param {string} teamname チーム名
         * @param {number} projectOrder プロジェクトオーダー
         * @param {number} goalId ゴールID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteTeamProjectGoal(teamname: string, projectOrder: number, goalId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteTeamProjectGoal(teamname, projectOrder, goalId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 自分自身の期限日超過ゴールのリストを取得します。
         * @summary 期限日超過ゴールリスト取得
         * @param {number} [page] ページ番号
         * @param {number} [perPage] 1ページあたりの要素数 (MAX100)
         * @param {string} [status] ステータス条件
         * @param {number} [categoryId] カテゴリーID
         * @param {string} [search] 検索キーワード
         * @param {string} [sort] ソート条件
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findExceededGoals(page?: number, perPage?: number, status?: string, categoryId?: number, search?: string, sort?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ExceededGoals>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findExceededGoals(page, perPage, status, categoryId, search, sort, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 自分自身のgoalのリストを取得します。
         * @summary goaliリスト取得
         * @param {number} [page] ページ番号
         * @param {number} [perPage] 1ページあたりの要素数 (MAX100)
         * @param {string} [status] ステータス条件
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findMyGoals(page?: number, perPage?: number, status?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Goals>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findMyGoals(page, perPage, status, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * プロジェクトのGoalリストを取得します。
         * @summary Goalリスト取得
         * @param {string} teamname チーム名
         * @param {number} projectOrder プロジェクトオーダー
         * @param {number} [page] ページ番号
         * @param {number} [perPage] 1ページあたりの要素数 (MAX100)
         * @param {string} [status] ステータス条件
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findTeamProjectGoals(teamname: string, projectOrder: number, page?: number, perPage?: number, status?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Goals>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findTeamProjectGoals(teamname, projectOrder, page, perPage, status, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 自分自身のゴールを取得します。
         * @summary ゴールの取得
         * @param {number} goalId ゴールID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMyGoal(goalId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Goal>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getMyGoal(goalId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 自分自身のgoalを取得します。
         * @summary goalの取得
         * @param {string} teamname チーム名
         * @param {number} projectOrder プロジェクトオーダー
         * @param {number} goalId ゴールID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTeamProjectGoal(teamname: string, projectOrder: number, goalId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Goal>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTeamProjectGoal(teamname, projectOrder, goalId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 自分自身のゴールを更新します。
         * @summary ゴール更新
         * @param {number} goalId ゴールID
         * @param {GoalRequest} goalRequest ゴール情報
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async setMyGoal(goalId: number, goalRequest: GoalRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Goal>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.setMyGoal(goalId, goalRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 自分自身のgoalを更新します。
         * @summary goal更新
         * @param {string} teamname チーム名
         * @param {number} projectOrder プロジェクトオーダー
         * @param {number} goalId ゴールID
         * @param {GoalRequest} goalRequest goal情報
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async setTeamProjectGoal(teamname: string, projectOrder: number, goalId: number, goalRequest: GoalRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Goal>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.setTeamProjectGoal(teamname, projectOrder, goalId, goalRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * GoalApi - factory interface
 * @export
 */
export const GoalApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = GoalApiFp(configuration)
    return {
        /**
         * 自分自身のgoalを追加します。
         * @summary goal追加
         * @param {GoalRequest} goalRequest Goal情報
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addMyGoal(goalRequest: GoalRequest, options?: any): AxiosPromise<Goal> {
            return localVarFp.addMyGoal(goalRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * プロジェクトのGoalを追加します。
         * @summary goal追加
         * @param {string} teamname チーム名
         * @param {number} projectOrder プロジェクトオーダー
         * @param {GoalRequest} goalRequest Goal情報
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addTeamProjectGoal(teamname: string, projectOrder: number, goalRequest: GoalRequest, options?: any): AxiosPromise<Goal> {
            return localVarFp.addTeamProjectGoal(teamname, projectOrder, goalRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * チームプロジェクトgoalを削除します。
         * @summary チームプロジェクトgoalの削除
         * @param {string} teamname チーム名
         * @param {number} projectOrder プロジェクトオーダー
         * @param {number} goalId ゴールID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteTeamProjectGoal(teamname: string, projectOrder: number, goalId: number, options?: any): AxiosPromise<void> {
            return localVarFp.deleteTeamProjectGoal(teamname, projectOrder, goalId, options).then((request) => request(axios, basePath));
        },
        /**
         * 自分自身の期限日超過ゴールのリストを取得します。
         * @summary 期限日超過ゴールリスト取得
         * @param {number} [page] ページ番号
         * @param {number} [perPage] 1ページあたりの要素数 (MAX100)
         * @param {string} [status] ステータス条件
         * @param {number} [categoryId] カテゴリーID
         * @param {string} [search] 検索キーワード
         * @param {string} [sort] ソート条件
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findExceededGoals(page?: number, perPage?: number, status?: string, categoryId?: number, search?: string, sort?: string, options?: any): AxiosPromise<ExceededGoals> {
            return localVarFp.findExceededGoals(page, perPage, status, categoryId, search, sort, options).then((request) => request(axios, basePath));
        },
        /**
         * 自分自身のgoalのリストを取得します。
         * @summary goaliリスト取得
         * @param {number} [page] ページ番号
         * @param {number} [perPage] 1ページあたりの要素数 (MAX100)
         * @param {string} [status] ステータス条件
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findMyGoals(page?: number, perPage?: number, status?: string, options?: any): AxiosPromise<Goals> {
            return localVarFp.findMyGoals(page, perPage, status, options).then((request) => request(axios, basePath));
        },
        /**
         * プロジェクトのGoalリストを取得します。
         * @summary Goalリスト取得
         * @param {string} teamname チーム名
         * @param {number} projectOrder プロジェクトオーダー
         * @param {number} [page] ページ番号
         * @param {number} [perPage] 1ページあたりの要素数 (MAX100)
         * @param {string} [status] ステータス条件
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findTeamProjectGoals(teamname: string, projectOrder: number, page?: number, perPage?: number, status?: string, options?: any): AxiosPromise<Goals> {
            return localVarFp.findTeamProjectGoals(teamname, projectOrder, page, perPage, status, options).then((request) => request(axios, basePath));
        },
        /**
         * 自分自身のゴールを取得します。
         * @summary ゴールの取得
         * @param {number} goalId ゴールID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMyGoal(goalId: number, options?: any): AxiosPromise<Goal> {
            return localVarFp.getMyGoal(goalId, options).then((request) => request(axios, basePath));
        },
        /**
         * 自分自身のgoalを取得します。
         * @summary goalの取得
         * @param {string} teamname チーム名
         * @param {number} projectOrder プロジェクトオーダー
         * @param {number} goalId ゴールID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTeamProjectGoal(teamname: string, projectOrder: number, goalId: number, options?: any): AxiosPromise<Goal> {
            return localVarFp.getTeamProjectGoal(teamname, projectOrder, goalId, options).then((request) => request(axios, basePath));
        },
        /**
         * 自分自身のゴールを更新します。
         * @summary ゴール更新
         * @param {number} goalId ゴールID
         * @param {GoalRequest} goalRequest ゴール情報
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setMyGoal(goalId: number, goalRequest: GoalRequest, options?: any): AxiosPromise<Goal> {
            return localVarFp.setMyGoal(goalId, goalRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 自分自身のgoalを更新します。
         * @summary goal更新
         * @param {string} teamname チーム名
         * @param {number} projectOrder プロジェクトオーダー
         * @param {number} goalId ゴールID
         * @param {GoalRequest} goalRequest goal情報
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setTeamProjectGoal(teamname: string, projectOrder: number, goalId: number, goalRequest: GoalRequest, options?: any): AxiosPromise<Goal> {
            return localVarFp.setTeamProjectGoal(teamname, projectOrder, goalId, goalRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * GoalApi - object-oriented interface
 * @export
 * @class GoalApi
 * @extends {BaseAPI}
 */
export class GoalApi extends BaseAPI {
    /**
     * 自分自身のgoalを追加します。
     * @summary goal追加
     * @param {GoalRequest} goalRequest Goal情報
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GoalApi
     */
    public async addMyGoal(goalRequest: GoalRequest, options?: AxiosRequestConfig) {
        const retry = 2;
        let resp;
        for (let i = 0; i < retry; i++) {
            resp = await GoalApiFp(this.configuration).addMyGoal(goalRequest, options).then((request) => request(this.axios, this.basePath)).catch((error) => {
                if (error?.response) {
                    return error.response;
                } else {
                    return { status: 500 };
                }
            });
            if (resp?.status === 401) {
                await refreshIdToken();
                console.debug('Retry addMyGoal ' + i + '/' + (retry - 1));
                continue;
            }
            break;
        }
        return resp;
    }

    /**
     * プロジェクトのGoalを追加します。
     * @summary goal追加
     * @param {string} teamname チーム名
     * @param {number} projectOrder プロジェクトオーダー
     * @param {GoalRequest} goalRequest Goal情報
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GoalApi
     */
    public async addTeamProjectGoal(teamname: string, projectOrder: number, goalRequest: GoalRequest, options?: AxiosRequestConfig) {
        const retry = 2;
        let resp;
        for (let i = 0; i < retry; i++) {
            resp = await GoalApiFp(this.configuration).addTeamProjectGoal(teamname, projectOrder, goalRequest, options).then((request) => request(this.axios, this.basePath)).catch((error) => {
                if (error?.response) {
                    return error.response;
                } else {
                    return { status: 500 };
                }
            });
            if (resp?.status === 401) {
                await refreshIdToken();
                console.debug('Retry addTeamProjectGoal ' + i + '/' + (retry - 1));
                continue;
            }
            break;
        }
        return resp;
    }

    /**
     * チームプロジェクトgoalを削除します。
     * @summary チームプロジェクトgoalの削除
     * @param {string} teamname チーム名
     * @param {number} projectOrder プロジェクトオーダー
     * @param {number} goalId ゴールID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GoalApi
     */
    public async deleteTeamProjectGoal(teamname: string, projectOrder: number, goalId: number, options?: AxiosRequestConfig) {
        const retry = 2;
        let resp;
        for (let i = 0; i < retry; i++) {
            resp = await GoalApiFp(this.configuration).deleteTeamProjectGoal(teamname, projectOrder, goalId, options).then((request) => request(this.axios, this.basePath)).catch((error) => {
                if (error?.response) {
                    return error.response;
                } else {
                    return { status: 500 };
                }
            });
            if (resp?.status === 401) {
                await refreshIdToken();
                console.debug('Retry deleteTeamProjectGoal ' + i + '/' + (retry - 1));
                continue;
            }
            break;
        }
        return resp;
    }

    /**
     * 自分自身の期限日超過ゴールのリストを取得します。
     * @summary 期限日超過ゴールリスト取得
     * @param {number} [page] ページ番号
     * @param {number} [perPage] 1ページあたりの要素数 (MAX100)
     * @param {string} [status] ステータス条件
     * @param {number} [categoryId] カテゴリーID
     * @param {string} [search] 検索キーワード
     * @param {string} [sort] ソート条件
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GoalApi
     */
    public async findExceededGoals(page?: number, perPage?: number, status?: string, categoryId?: number, search?: string, sort?: string, options?: AxiosRequestConfig) {
        const retry = 2;
        let resp;
        for (let i = 0; i < retry; i++) {
            resp = await GoalApiFp(this.configuration).findExceededGoals(page, perPage, status, categoryId, search, sort, options).then((request) => request(this.axios, this.basePath)).catch((error) => {
                if (error?.response) {
                    return error.response;
                } else {
                    return { status: 500 };
                }
            });
            if (resp?.status === 401) {
                await refreshIdToken();
                console.debug('Retry findExceededGoals ' + i + '/' + (retry - 1));
                continue;
            }
            break;
        }
        return resp;
    }

    /**
     * 自分自身のgoalのリストを取得します。
     * @summary goaliリスト取得
     * @param {number} [page] ページ番号
     * @param {number} [perPage] 1ページあたりの要素数 (MAX100)
     * @param {string} [status] ステータス条件
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GoalApi
     */
    public async findMyGoals(page?: number, perPage?: number, status?: string, options?: AxiosRequestConfig) {
        const retry = 2;
        let resp;
        for (let i = 0; i < retry; i++) {
            resp = await GoalApiFp(this.configuration).findMyGoals(page, perPage, status, options).then((request) => request(this.axios, this.basePath)).catch((error) => {
                if (error?.response) {
                    return error.response;
                } else {
                    return { status: 500 };
                }
            });
            if (resp?.status === 401) {
                await refreshIdToken();
                console.debug('Retry findMyGoals ' + i + '/' + (retry - 1));
                continue;
            }
            break;
        }
        return resp;
    }

    /**
     * プロジェクトのGoalリストを取得します。
     * @summary Goalリスト取得
     * @param {string} teamname チーム名
     * @param {number} projectOrder プロジェクトオーダー
     * @param {number} [page] ページ番号
     * @param {number} [perPage] 1ページあたりの要素数 (MAX100)
     * @param {string} [status] ステータス条件
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GoalApi
     */
    public async findTeamProjectGoals(teamname: string, projectOrder: number, page?: number, perPage?: number, status?: string, options?: AxiosRequestConfig) {
        const retry = 2;
        let resp;
        for (let i = 0; i < retry; i++) {
            resp = await GoalApiFp(this.configuration).findTeamProjectGoals(teamname, projectOrder, page, perPage, status, options).then((request) => request(this.axios, this.basePath)).catch((error) => {
                if (error?.response) {
                    return error.response;
                } else {
                    return { status: 500 };
                }
            });
            if (resp?.status === 401) {
                await refreshIdToken();
                console.debug('Retry findTeamProjectGoals ' + i + '/' + (retry - 1));
                continue;
            }
            break;
        }
        return resp;
    }

    /**
     * 自分自身のゴールを取得します。
     * @summary ゴールの取得
     * @param {number} goalId ゴールID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GoalApi
     */
    public async getMyGoal(goalId: number, options?: AxiosRequestConfig) {
        const retry = 2;
        let resp;
        for (let i = 0; i < retry; i++) {
            resp = await GoalApiFp(this.configuration).getMyGoal(goalId, options).then((request) => request(this.axios, this.basePath)).catch((error) => {
                if (error?.response) {
                    return error.response;
                } else {
                    return { status: 500 };
                }
            });
            if (resp?.status === 401) {
                await refreshIdToken();
                console.debug('Retry getMyGoal ' + i + '/' + (retry - 1));
                continue;
            }
            break;
        }
        return resp;
    }

    /**
     * 自分自身のgoalを取得します。
     * @summary goalの取得
     * @param {string} teamname チーム名
     * @param {number} projectOrder プロジェクトオーダー
     * @param {number} goalId ゴールID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GoalApi
     */
    public async getTeamProjectGoal(teamname: string, projectOrder: number, goalId: number, options?: AxiosRequestConfig) {
        const retry = 2;
        let resp;
        for (let i = 0; i < retry; i++) {
            resp = await GoalApiFp(this.configuration).getTeamProjectGoal(teamname, projectOrder, goalId, options).then((request) => request(this.axios, this.basePath)).catch((error) => {
                if (error?.response) {
                    return error.response;
                } else {
                    return { status: 500 };
                }
            });
            if (resp?.status === 401) {
                await refreshIdToken();
                console.debug('Retry getTeamProjectGoal ' + i + '/' + (retry - 1));
                continue;
            }
            break;
        }
        return resp;
    }

    /**
     * 自分自身のゴールを更新します。
     * @summary ゴール更新
     * @param {number} goalId ゴールID
     * @param {GoalRequest} goalRequest ゴール情報
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GoalApi
     */
    public async setMyGoal(goalId: number, goalRequest: GoalRequest, options?: AxiosRequestConfig) {
        const retry = 2;
        let resp;
        for (let i = 0; i < retry; i++) {
            resp = await GoalApiFp(this.configuration).setMyGoal(goalId, goalRequest, options).then((request) => request(this.axios, this.basePath)).catch((error) => {
                if (error?.response) {
                    return error.response;
                } else {
                    return { status: 500 };
                }
            });
            if (resp?.status === 401) {
                await refreshIdToken();
                console.debug('Retry setMyGoal ' + i + '/' + (retry - 1));
                continue;
            }
            break;
        }
        return resp;
    }

    /**
     * 自分自身のgoalを更新します。
     * @summary goal更新
     * @param {string} teamname チーム名
     * @param {number} projectOrder プロジェクトオーダー
     * @param {number} goalId ゴールID
     * @param {GoalRequest} goalRequest goal情報
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GoalApi
     */
    public async setTeamProjectGoal(teamname: string, projectOrder: number, goalId: number, goalRequest: GoalRequest, options?: AxiosRequestConfig) {
        const retry = 2;
        let resp;
        for (let i = 0; i < retry; i++) {
            resp = await GoalApiFp(this.configuration).setTeamProjectGoal(teamname, projectOrder, goalId, goalRequest, options).then((request) => request(this.axios, this.basePath)).catch((error) => {
                if (error?.response) {
                    return error.response;
                } else {
                    return { status: 500 };
                }
            });
            if (resp?.status === 401) {
                await refreshIdToken();
                console.debug('Retry setTeamProjectGoal ' + i + '/' + (retry - 1));
                continue;
            }
            break;
        }
        return resp;
    }
}


/**
 * ImageManagerApi - axios parameter creator
 * @export
 */
export const ImageManagerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * アップロード済みのImageManager画像を取得します
         * @summary ImageManagerの画像取得
         * @param {ImagemanagerImagesRequest} imagemanagerImagesRequest 画像取得用情報
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getImageManagerImages: async (imagemanagerImagesRequest: ImagemanagerImagesRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'imagemanagerImagesRequest' is not null or undefined
            assertParamExists('getImageManagerImages', 'imagemanagerImagesRequest', imagemanagerImagesRequest)
            const localVarPath = `/imagemanager/images`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(imagemanagerImagesRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * ImageManagerで使用する画像をアップロードします。
         * @summary ImageManager用の画像アップロード
         * @param {Array<File>} [files] 
         * @param {string} [category] アップロードカテゴリー
         * @param {string} [sort] アップロード区分
         * @param {number} [teamId] チームID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadFromImageManager: async (files?: Array<File>, category?: string, sort?: string, teamId?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/uploads/imagemanager`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (files) {
                files.forEach((element) => {
                    localVarFormParams.append('files', element as any);
                })
            }

    
            if (category !== undefined) { 
                localVarFormParams.append('category', category as any);
            }
    
            if (sort !== undefined) { 
                localVarFormParams.append('sort', sort as any);
            }
    
            if (teamId !== undefined) { 
                localVarFormParams.append('team_id', teamId as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ImageManagerApi - functional programming interface
 * @export
 */
export const ImageManagerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ImageManagerApiAxiosParamCreator(configuration)
    return {
        /**
         * アップロード済みのImageManager画像を取得します
         * @summary ImageManagerの画像取得
         * @param {ImagemanagerImagesRequest} imagemanagerImagesRequest 画像取得用情報
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getImageManagerImages(imagemanagerImagesRequest: ImagemanagerImagesRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ImagemanagerImages>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getImageManagerImages(imagemanagerImagesRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * ImageManagerで使用する画像をアップロードします。
         * @summary ImageManager用の画像アップロード
         * @param {Array<File>} [files] 
         * @param {string} [category] アップロードカテゴリー
         * @param {string} [sort] アップロード区分
         * @param {number} [teamId] チームID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async uploadFromImageManager(files?: Array<File>, category?: string, sort?: string, teamId?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UploadResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.uploadFromImageManager(files, category, sort, teamId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ImageManagerApi - factory interface
 * @export
 */
export const ImageManagerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ImageManagerApiFp(configuration)
    return {
        /**
         * アップロード済みのImageManager画像を取得します
         * @summary ImageManagerの画像取得
         * @param {ImagemanagerImagesRequest} imagemanagerImagesRequest 画像取得用情報
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getImageManagerImages(imagemanagerImagesRequest: ImagemanagerImagesRequest, options?: any): AxiosPromise<ImagemanagerImages> {
            return localVarFp.getImageManagerImages(imagemanagerImagesRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * ImageManagerで使用する画像をアップロードします。
         * @summary ImageManager用の画像アップロード
         * @param {Array<File>} [files] 
         * @param {string} [category] アップロードカテゴリー
         * @param {string} [sort] アップロード区分
         * @param {number} [teamId] チームID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadFromImageManager(files?: Array<File>, category?: string, sort?: string, teamId?: number, options?: any): AxiosPromise<UploadResponse> {
            return localVarFp.uploadFromImageManager(files, category, sort, teamId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ImageManagerApi - object-oriented interface
 * @export
 * @class ImageManagerApi
 * @extends {BaseAPI}
 */
export class ImageManagerApi extends BaseAPI {
    /**
     * アップロード済みのImageManager画像を取得します
     * @summary ImageManagerの画像取得
     * @param {ImagemanagerImagesRequest} imagemanagerImagesRequest 画像取得用情報
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ImageManagerApi
     */
    public async getImageManagerImages(imagemanagerImagesRequest: ImagemanagerImagesRequest, options?: AxiosRequestConfig) {
        const retry = 2;
        let resp;
        for (let i = 0; i < retry; i++) {
            resp = await ImageManagerApiFp(this.configuration).getImageManagerImages(imagemanagerImagesRequest, options).then((request) => request(this.axios, this.basePath)).catch((error) => {
                if (error?.response) {
                    return error.response;
                } else {
                    return { status: 500 };
                }
            });
            if (resp?.status === 401) {
                await refreshIdToken();
                console.debug('Retry getImageManagerImages ' + i + '/' + (retry - 1));
                continue;
            }
            break;
        }
        return resp;
    }

    /**
     * ImageManagerで使用する画像をアップロードします。
     * @summary ImageManager用の画像アップロード
     * @param {Array<File>} [files] 
     * @param {string} [category] アップロードカテゴリー
     * @param {string} [sort] アップロード区分
     * @param {number} [teamId] チームID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ImageManagerApi
     */
    public async uploadFromImageManager(files?: Array<File>, category?: string, sort?: string, teamId?: number, options?: AxiosRequestConfig) {
        const retry = 2;
        let resp;
        for (let i = 0; i < retry; i++) {
            resp = await ImageManagerApiFp(this.configuration).uploadFromImageManager(files, category, sort, teamId, options).then((request) => request(this.axios, this.basePath)).catch((error) => {
                if (error?.response) {
                    return error.response;
                } else {
                    return { status: 500 };
                }
            });
            if (resp?.status === 401) {
                await refreshIdToken();
                console.debug('Retry uploadFromImageManager ' + i + '/' + (retry - 1));
                continue;
            }
            break;
        }
        return resp;
    }
}


/**
 * InfoApi - axios parameter creator
 * @export
 */
export const InfoApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 認証状態を取得します。
         * @summary 認証状態
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInfoAuth: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/info/auth`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Skimieのバージョン情報を取得します。
         * @summary Skimieのバージョン情報
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInfoVersion: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/info/version`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * InfoApi - functional programming interface
 * @export
 */
export const InfoApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = InfoApiAxiosParamCreator(configuration)
    return {
        /**
         * 認証状態を取得します。
         * @summary 認証状態
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getInfoAuth(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InfoAuth>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getInfoAuth(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Skimieのバージョン情報を取得します。
         * @summary Skimieのバージョン情報
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getInfoVersion(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InfoVersion>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getInfoVersion(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * InfoApi - factory interface
 * @export
 */
export const InfoApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = InfoApiFp(configuration)
    return {
        /**
         * 認証状態を取得します。
         * @summary 認証状態
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInfoAuth(options?: any): AxiosPromise<InfoAuth> {
            return localVarFp.getInfoAuth(options).then((request) => request(axios, basePath));
        },
        /**
         * Skimieのバージョン情報を取得します。
         * @summary Skimieのバージョン情報
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInfoVersion(options?: any): AxiosPromise<InfoVersion> {
            return localVarFp.getInfoVersion(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * InfoApi - object-oriented interface
 * @export
 * @class InfoApi
 * @extends {BaseAPI}
 */
export class InfoApi extends BaseAPI {
    /**
     * 認証状態を取得します。
     * @summary 認証状態
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InfoApi
     */
    public async getInfoAuth(options?: AxiosRequestConfig) {
        const retry = 2;
        let resp;
        for (let i = 0; i < retry; i++) {
            resp = await InfoApiFp(this.configuration).getInfoAuth(options).then((request) => request(this.axios, this.basePath)).catch((error) => {
                if (error?.response) {
                    return error.response;
                } else {
                    return { status: 500 };
                }
            });
            if (resp?.status === 401) {
                await refreshIdToken();
                console.debug('Retry getInfoAuth ' + i + '/' + (retry - 1));
                continue;
            }
            break;
        }
        return resp;
    }

    /**
     * Skimieのバージョン情報を取得します。
     * @summary Skimieのバージョン情報
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InfoApi
     */
    public async getInfoVersion(options?: AxiosRequestConfig) {
        const retry = 2;
        let resp;
        for (let i = 0; i < retry; i++) {
            resp = await InfoApiFp(this.configuration).getInfoVersion(options).then((request) => request(this.axios, this.basePath)).catch((error) => {
                if (error?.response) {
                    return error.response;
                } else {
                    return { status: 500 };
                }
            });
            if (resp?.status === 401) {
                await refreshIdToken();
                console.debug('Retry getInfoVersion ' + i + '/' + (retry - 1));
                continue;
            }
            break;
        }
        return resp;
    }
}


/**
 * ItemApi - axios parameter creator
 * @export
 */
export const ItemApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * ショップアイテムを購入します。
         * @summary ショップアイテムの購入
         * @param {BuyShopItemReq} buyShopItemReq アイテム購入情報
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        buyShopItem: async (buyShopItemReq: BuyShopItemReq, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'buyShopItemReq' is not null or undefined
            assertParamExists('buyShopItem', 'buyShopItemReq', buyShopItemReq)
            const localVarPath = `/items/shop/buy`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(buyShopItemReq, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * イベントボス図鑑背景を交換します。
         * @summary イベントボス図鑑背景交換
         * @param {string} itemMasterCode アイテムマスターコード
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        changeSeasonBossEncyclopediaBackground: async (itemMasterCode: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'itemMasterCode' is not null or undefined
            assertParamExists('changeSeasonBossEncyclopediaBackground', 'itemMasterCode', itemMasterCode)
            const localVarPath = `/items/season/bosses/encyclopedia/background/{item_master_code}`
                .replace(`{${"item_master_code"}}`, encodeURIComponent(String(itemMasterCode)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * スキミーアンロックキー保有状態を取得します。
         * @summary スキミーアンロックキー保有状態取得
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        checkSkimmyUnlockKey: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/items/skimmy_unlock_key/check`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * ガチャを実行します。
         * @summary ガチャ実行
         * @param {number} [amount] 回数
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        doGacha: async (amount?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/items/gacha`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (amount !== undefined) {
                localVarQueryParameter['amount'] = amount;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * ユーザスキミーにアイテムを装備します。
         * @summary ユーザスキミーにアイテムを装備
         * @param {number} skimmyType スキミータイプ
         * @param {number} equipmentId 装備ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        equipSelectedEquipment: async (skimmyType: number, equipmentId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'skimmyType' is not null or undefined
            assertParamExists('equipSelectedEquipment', 'skimmyType', skimmyType)
            // verify required parameter 'equipmentId' is not null or undefined
            assertParamExists('equipSelectedEquipment', 'equipmentId', equipmentId)
            const localVarPath = `/items/user/skimmy/equipment/equip/{skimmy_type}/{equipment_id}`
                .replace(`{${"skimmy_type"}}`, encodeURIComponent(String(skimmyType)))
                .replace(`{${"equipment_id"}}`, encodeURIComponent(String(equipmentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 装備を突破します。
         * @summary 装備突破
         * @param {number} equipmentId 装備ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        evolveEquipment: async (equipmentId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'equipmentId' is not null or undefined
            assertParamExists('evolveEquipment', 'equipmentId', equipmentId)
            const localVarPath = `/items/level_up/evolve/{equipment_id}`
                .replace(`{${"equipment_id"}}`, encodeURIComponent(String(equipmentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * アイテム一覧を取得します。
         * @summary アイテム一覧取得
         * @param {string} username ユーザ名
         * @param {string} acquisitionPath アイテム入手経路
         * @param {number} [page] ページ番号
         * @param {number} [perPage] 1ページあたりの要素数 (MAX100)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findItems: async (username: string, acquisitionPath: string, page?: number, perPage?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'username' is not null or undefined
            assertParamExists('findItems', 'username', username)
            // verify required parameter 'acquisitionPath' is not null or undefined
            assertParamExists('findItems', 'acquisitionPath', acquisitionPath)
            const localVarPath = `/items/{username}/{acquisition_path}`
                .replace(`{${"username"}}`, encodeURIComponent(String(username)))
                .replace(`{${"acquisition_path"}}`, encodeURIComponent(String(acquisitionPath)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (perPage !== undefined) {
                localVarQueryParameter['per_page'] = perPage;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * ユーザアイテムカテゴリ一覧を取得します。
         * @summary ユーザアイテムカテゴリ一覧取得
         * @param {string} itemCategoryCode アイテム種類コード
         * @param {number} [page] ページ番号
         * @param {number} [perPage] 1ページあたりの要素数 (MAX100)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCategoryUserItems: async (itemCategoryCode: string, page?: number, perPage?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'itemCategoryCode' is not null or undefined
            assertParamExists('getCategoryUserItems', 'itemCategoryCode', itemCategoryCode)
            const localVarPath = `/items/user/category/{item_category_code}`
                .replace(`{${"item_category_code"}}`, encodeURIComponent(String(itemCategoryCode)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (perPage !== undefined) {
                localVarQueryParameter['per_page'] = perPage;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 装備レベルアップ情報を取得します。
         * @summary 装備レベルアップ情報取得
         * @param {number} equipmentId 装備ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEquipmentLevelUpInfo: async (equipmentId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'equipmentId' is not null or undefined
            assertParamExists('getEquipmentLevelUpInfo', 'equipmentId', equipmentId)
            const localVarPath = `/items/level_up/{equipment_id}`
                .replace(`{${"equipment_id"}}`, encodeURIComponent(String(equipmentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * ボス図鑑を取得します。
         * @summary ボス図鑑取得
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSeasonBosses: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/items/season/bosses`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * ユーザアイテム一覧を取得します。
         * @summary ユーザアイテム一覧取得
         * @param {number} [page] ページ番号
         * @param {number} [perPage] 1ページあたりの要素数 (MAX100)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserItems: async (page?: number, perPage?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/items/user`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (perPage !== undefined) {
                localVarQueryParameter['per_page'] = perPage;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * ユーザスキミー装備アイテム一覧を取得します。
         * @summary ユーザスキミー装備アイテム一覧取得
         * @param {number} skimmyType スキミータイプ
         * @param {number} [page] ページ番号
         * @param {number} [perPage] 1ページあたりの要素数 (MAX100)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserSkimmyEquipmentItems: async (skimmyType: number, page?: number, perPage?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'skimmyType' is not null or undefined
            assertParamExists('getUserSkimmyEquipmentItems', 'skimmyType', skimmyType)
            const localVarPath = `/items/user/skimmy/equipment/{skimmy_type}`
                .replace(`{${"skimmy_type"}}`, encodeURIComponent(String(skimmyType)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (perPage !== undefined) {
                localVarQueryParameter['per_page'] = perPage;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 装備経験値とレベルを更新します。
         * @summary 装備経験値とレベルの更新
         * @param {EquipmentLevelUpReq} equipmentLevelUpReq 装備の経験値とレベルアップ情報
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        levelUpEquipment: async (equipmentLevelUpReq: EquipmentLevelUpReq, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'equipmentLevelUpReq' is not null or undefined
            assertParamExists('levelUpEquipment', 'equipmentLevelUpReq', equipmentLevelUpReq)
            const localVarPath = `/items/level_up/increase_level`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(equipmentLevelUpReq, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * アイテムを使用してスキミーをアンロックします。
         * @summary アイテムを使用してスキミーアンロック
         * @param {number} skimmyType スキミータイプ
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        unlockSkimmyWithKey: async (skimmyType: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'skimmyType' is not null or undefined
            assertParamExists('unlockSkimmyWithKey', 'skimmyType', skimmyType)
            const localVarPath = `/items/skimmy_unlock_key/unlock/{skimmy_type}`
                .replace(`{${"skimmy_type"}}`, encodeURIComponent(String(skimmyType)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ItemApi - functional programming interface
 * @export
 */
export const ItemApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ItemApiAxiosParamCreator(configuration)
    return {
        /**
         * ショップアイテムを購入します。
         * @summary ショップアイテムの購入
         * @param {BuyShopItemReq} buyShopItemReq アイテム購入情報
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async buyShopItem(buyShopItemReq: BuyShopItemReq, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.buyShopItem(buyShopItemReq, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * イベントボス図鑑背景を交換します。
         * @summary イベントボス図鑑背景交換
         * @param {string} itemMasterCode アイテムマスターコード
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async changeSeasonBossEncyclopediaBackground(itemMasterCode: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.changeSeasonBossEncyclopediaBackground(itemMasterCode, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * スキミーアンロックキー保有状態を取得します。
         * @summary スキミーアンロックキー保有状態取得
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async checkSkimmyUnlockKey(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Item>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.checkSkimmyUnlockKey(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * ガチャを実行します。
         * @summary ガチャ実行
         * @param {number} [amount] 回数
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async doGacha(amount?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Items>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.doGacha(amount, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * ユーザスキミーにアイテムを装備します。
         * @summary ユーザスキミーにアイテムを装備
         * @param {number} skimmyType スキミータイプ
         * @param {number} equipmentId 装備ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async equipSelectedEquipment(skimmyType: number, equipmentId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.equipSelectedEquipment(skimmyType, equipmentId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 装備を突破します。
         * @summary 装備突破
         * @param {number} equipmentId 装備ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async evolveEquipment(equipmentId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EquipmentBattleParameters>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.evolveEquipment(equipmentId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * アイテム一覧を取得します。
         * @summary アイテム一覧取得
         * @param {string} username ユーザ名
         * @param {string} acquisitionPath アイテム入手経路
         * @param {number} [page] ページ番号
         * @param {number} [perPage] 1ページあたりの要素数 (MAX100)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findItems(username: string, acquisitionPath: string, page?: number, perPage?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Items>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findItems(username, acquisitionPath, page, perPage, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * ユーザアイテムカテゴリ一覧を取得します。
         * @summary ユーザアイテムカテゴリ一覧取得
         * @param {string} itemCategoryCode アイテム種類コード
         * @param {number} [page] ページ番号
         * @param {number} [perPage] 1ページあたりの要素数 (MAX100)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCategoryUserItems(itemCategoryCode: string, page?: number, perPage?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CategoryUserItems>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCategoryUserItems(itemCategoryCode, page, perPage, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 装備レベルアップ情報を取得します。
         * @summary 装備レベルアップ情報取得
         * @param {number} equipmentId 装備ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getEquipmentLevelUpInfo(equipmentId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EquipmentLevelUpInfo>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getEquipmentLevelUpInfo(equipmentId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * ボス図鑑を取得します。
         * @summary ボス図鑑取得
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSeasonBosses(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BossEncyclopedia>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSeasonBosses(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * ユーザアイテム一覧を取得します。
         * @summary ユーザアイテム一覧取得
         * @param {number} [page] ページ番号
         * @param {number} [perPage] 1ページあたりの要素数 (MAX100)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUserItems(page?: number, perPage?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserItems>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUserItems(page, perPage, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * ユーザスキミー装備アイテム一覧を取得します。
         * @summary ユーザスキミー装備アイテム一覧取得
         * @param {number} skimmyType スキミータイプ
         * @param {number} [page] ページ番号
         * @param {number} [perPage] 1ページあたりの要素数 (MAX100)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUserSkimmyEquipmentItems(skimmyType: number, page?: number, perPage?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CategoryUserItems>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUserSkimmyEquipmentItems(skimmyType, page, perPage, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 装備経験値とレベルを更新します。
         * @summary 装備経験値とレベルの更新
         * @param {EquipmentLevelUpReq} equipmentLevelUpReq 装備の経験値とレベルアップ情報
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async levelUpEquipment(equipmentLevelUpReq: EquipmentLevelUpReq, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EquipmentBattleParameters>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.levelUpEquipment(equipmentLevelUpReq, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * アイテムを使用してスキミーをアンロックします。
         * @summary アイテムを使用してスキミーアンロック
         * @param {number} skimmyType スキミータイプ
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async unlockSkimmyWithKey(skimmyType: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.unlockSkimmyWithKey(skimmyType, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ItemApi - factory interface
 * @export
 */
export const ItemApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ItemApiFp(configuration)
    return {
        /**
         * ショップアイテムを購入します。
         * @summary ショップアイテムの購入
         * @param {BuyShopItemReq} buyShopItemReq アイテム購入情報
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        buyShopItem(buyShopItemReq: BuyShopItemReq, options?: any): AxiosPromise<void> {
            return localVarFp.buyShopItem(buyShopItemReq, options).then((request) => request(axios, basePath));
        },
        /**
         * イベントボス図鑑背景を交換します。
         * @summary イベントボス図鑑背景交換
         * @param {string} itemMasterCode アイテムマスターコード
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        changeSeasonBossEncyclopediaBackground(itemMasterCode: string, options?: any): AxiosPromise<void> {
            return localVarFp.changeSeasonBossEncyclopediaBackground(itemMasterCode, options).then((request) => request(axios, basePath));
        },
        /**
         * スキミーアンロックキー保有状態を取得します。
         * @summary スキミーアンロックキー保有状態取得
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        checkSkimmyUnlockKey(options?: any): AxiosPromise<Item> {
            return localVarFp.checkSkimmyUnlockKey(options).then((request) => request(axios, basePath));
        },
        /**
         * ガチャを実行します。
         * @summary ガチャ実行
         * @param {number} [amount] 回数
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        doGacha(amount?: number, options?: any): AxiosPromise<Items> {
            return localVarFp.doGacha(amount, options).then((request) => request(axios, basePath));
        },
        /**
         * ユーザスキミーにアイテムを装備します。
         * @summary ユーザスキミーにアイテムを装備
         * @param {number} skimmyType スキミータイプ
         * @param {number} equipmentId 装備ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        equipSelectedEquipment(skimmyType: number, equipmentId: number, options?: any): AxiosPromise<void> {
            return localVarFp.equipSelectedEquipment(skimmyType, equipmentId, options).then((request) => request(axios, basePath));
        },
        /**
         * 装備を突破します。
         * @summary 装備突破
         * @param {number} equipmentId 装備ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        evolveEquipment(equipmentId: number, options?: any): AxiosPromise<EquipmentBattleParameters> {
            return localVarFp.evolveEquipment(equipmentId, options).then((request) => request(axios, basePath));
        },
        /**
         * アイテム一覧を取得します。
         * @summary アイテム一覧取得
         * @param {string} username ユーザ名
         * @param {string} acquisitionPath アイテム入手経路
         * @param {number} [page] ページ番号
         * @param {number} [perPage] 1ページあたりの要素数 (MAX100)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findItems(username: string, acquisitionPath: string, page?: number, perPage?: number, options?: any): AxiosPromise<Items> {
            return localVarFp.findItems(username, acquisitionPath, page, perPage, options).then((request) => request(axios, basePath));
        },
        /**
         * ユーザアイテムカテゴリ一覧を取得します。
         * @summary ユーザアイテムカテゴリ一覧取得
         * @param {string} itemCategoryCode アイテム種類コード
         * @param {number} [page] ページ番号
         * @param {number} [perPage] 1ページあたりの要素数 (MAX100)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCategoryUserItems(itemCategoryCode: string, page?: number, perPage?: number, options?: any): AxiosPromise<CategoryUserItems> {
            return localVarFp.getCategoryUserItems(itemCategoryCode, page, perPage, options).then((request) => request(axios, basePath));
        },
        /**
         * 装備レベルアップ情報を取得します。
         * @summary 装備レベルアップ情報取得
         * @param {number} equipmentId 装備ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEquipmentLevelUpInfo(equipmentId: number, options?: any): AxiosPromise<EquipmentLevelUpInfo> {
            return localVarFp.getEquipmentLevelUpInfo(equipmentId, options).then((request) => request(axios, basePath));
        },
        /**
         * ボス図鑑を取得します。
         * @summary ボス図鑑取得
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSeasonBosses(options?: any): AxiosPromise<BossEncyclopedia> {
            return localVarFp.getSeasonBosses(options).then((request) => request(axios, basePath));
        },
        /**
         * ユーザアイテム一覧を取得します。
         * @summary ユーザアイテム一覧取得
         * @param {number} [page] ページ番号
         * @param {number} [perPage] 1ページあたりの要素数 (MAX100)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserItems(page?: number, perPage?: number, options?: any): AxiosPromise<UserItems> {
            return localVarFp.getUserItems(page, perPage, options).then((request) => request(axios, basePath));
        },
        /**
         * ユーザスキミー装備アイテム一覧を取得します。
         * @summary ユーザスキミー装備アイテム一覧取得
         * @param {number} skimmyType スキミータイプ
         * @param {number} [page] ページ番号
         * @param {number} [perPage] 1ページあたりの要素数 (MAX100)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserSkimmyEquipmentItems(skimmyType: number, page?: number, perPage?: number, options?: any): AxiosPromise<CategoryUserItems> {
            return localVarFp.getUserSkimmyEquipmentItems(skimmyType, page, perPage, options).then((request) => request(axios, basePath));
        },
        /**
         * 装備経験値とレベルを更新します。
         * @summary 装備経験値とレベルの更新
         * @param {EquipmentLevelUpReq} equipmentLevelUpReq 装備の経験値とレベルアップ情報
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        levelUpEquipment(equipmentLevelUpReq: EquipmentLevelUpReq, options?: any): AxiosPromise<EquipmentBattleParameters> {
            return localVarFp.levelUpEquipment(equipmentLevelUpReq, options).then((request) => request(axios, basePath));
        },
        /**
         * アイテムを使用してスキミーをアンロックします。
         * @summary アイテムを使用してスキミーアンロック
         * @param {number} skimmyType スキミータイプ
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        unlockSkimmyWithKey(skimmyType: number, options?: any): AxiosPromise<void> {
            return localVarFp.unlockSkimmyWithKey(skimmyType, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ItemApi - object-oriented interface
 * @export
 * @class ItemApi
 * @extends {BaseAPI}
 */
export class ItemApi extends BaseAPI {
    /**
     * ショップアイテムを購入します。
     * @summary ショップアイテムの購入
     * @param {BuyShopItemReq} buyShopItemReq アイテム購入情報
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ItemApi
     */
    public async buyShopItem(buyShopItemReq: BuyShopItemReq, options?: AxiosRequestConfig) {
        const retry = 2;
        let resp;
        for (let i = 0; i < retry; i++) {
            resp = await ItemApiFp(this.configuration).buyShopItem(buyShopItemReq, options).then((request) => request(this.axios, this.basePath)).catch((error) => {
                if (error?.response) {
                    return error.response;
                } else {
                    return { status: 500 };
                }
            });
            if (resp?.status === 401) {
                await refreshIdToken();
                console.debug('Retry buyShopItem ' + i + '/' + (retry - 1));
                continue;
            }
            break;
        }
        return resp;
    }

    /**
     * イベントボス図鑑背景を交換します。
     * @summary イベントボス図鑑背景交換
     * @param {string} itemMasterCode アイテムマスターコード
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ItemApi
     */
    public async changeSeasonBossEncyclopediaBackground(itemMasterCode: string, options?: AxiosRequestConfig) {
        const retry = 2;
        let resp;
        for (let i = 0; i < retry; i++) {
            resp = await ItemApiFp(this.configuration).changeSeasonBossEncyclopediaBackground(itemMasterCode, options).then((request) => request(this.axios, this.basePath)).catch((error) => {
                if (error?.response) {
                    return error.response;
                } else {
                    return { status: 500 };
                }
            });
            if (resp?.status === 401) {
                await refreshIdToken();
                console.debug('Retry changeSeasonBossEncyclopediaBackground ' + i + '/' + (retry - 1));
                continue;
            }
            break;
        }
        return resp;
    }

    /**
     * スキミーアンロックキー保有状態を取得します。
     * @summary スキミーアンロックキー保有状態取得
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ItemApi
     */
    public async checkSkimmyUnlockKey(options?: AxiosRequestConfig) {
        const retry = 2;
        let resp;
        for (let i = 0; i < retry; i++) {
            resp = await ItemApiFp(this.configuration).checkSkimmyUnlockKey(options).then((request) => request(this.axios, this.basePath)).catch((error) => {
                if (error?.response) {
                    return error.response;
                } else {
                    return { status: 500 };
                }
            });
            if (resp?.status === 401) {
                await refreshIdToken();
                console.debug('Retry checkSkimmyUnlockKey ' + i + '/' + (retry - 1));
                continue;
            }
            break;
        }
        return resp;
    }

    /**
     * ガチャを実行します。
     * @summary ガチャ実行
     * @param {number} [amount] 回数
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ItemApi
     */
    public async doGacha(amount?: number, options?: AxiosRequestConfig) {
        const retry = 2;
        let resp;
        for (let i = 0; i < retry; i++) {
            resp = await ItemApiFp(this.configuration).doGacha(amount, options).then((request) => request(this.axios, this.basePath)).catch((error) => {
                if (error?.response) {
                    return error.response;
                } else {
                    return { status: 500 };
                }
            });
            if (resp?.status === 401) {
                await refreshIdToken();
                console.debug('Retry doGacha ' + i + '/' + (retry - 1));
                continue;
            }
            break;
        }
        return resp;
    }

    /**
     * ユーザスキミーにアイテムを装備します。
     * @summary ユーザスキミーにアイテムを装備
     * @param {number} skimmyType スキミータイプ
     * @param {number} equipmentId 装備ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ItemApi
     */
    public async equipSelectedEquipment(skimmyType: number, equipmentId: number, options?: AxiosRequestConfig) {
        const retry = 2;
        let resp;
        for (let i = 0; i < retry; i++) {
            resp = await ItemApiFp(this.configuration).equipSelectedEquipment(skimmyType, equipmentId, options).then((request) => request(this.axios, this.basePath)).catch((error) => {
                if (error?.response) {
                    return error.response;
                } else {
                    return { status: 500 };
                }
            });
            if (resp?.status === 401) {
                await refreshIdToken();
                console.debug('Retry equipSelectedEquipment ' + i + '/' + (retry - 1));
                continue;
            }
            break;
        }
        return resp;
    }

    /**
     * 装備を突破します。
     * @summary 装備突破
     * @param {number} equipmentId 装備ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ItemApi
     */
    public async evolveEquipment(equipmentId: number, options?: AxiosRequestConfig) {
        const retry = 2;
        let resp;
        for (let i = 0; i < retry; i++) {
            resp = await ItemApiFp(this.configuration).evolveEquipment(equipmentId, options).then((request) => request(this.axios, this.basePath)).catch((error) => {
                if (error?.response) {
                    return error.response;
                } else {
                    return { status: 500 };
                }
            });
            if (resp?.status === 401) {
                await refreshIdToken();
                console.debug('Retry evolveEquipment ' + i + '/' + (retry - 1));
                continue;
            }
            break;
        }
        return resp;
    }

    /**
     * アイテム一覧を取得します。
     * @summary アイテム一覧取得
     * @param {string} username ユーザ名
     * @param {string} acquisitionPath アイテム入手経路
     * @param {number} [page] ページ番号
     * @param {number} [perPage] 1ページあたりの要素数 (MAX100)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ItemApi
     */
    public async findItems(username: string, acquisitionPath: string, page?: number, perPage?: number, options?: AxiosRequestConfig) {
        const retry = 2;
        let resp;
        for (let i = 0; i < retry; i++) {
            resp = await ItemApiFp(this.configuration).findItems(username, acquisitionPath, page, perPage, options).then((request) => request(this.axios, this.basePath)).catch((error) => {
                if (error?.response) {
                    return error.response;
                } else {
                    return { status: 500 };
                }
            });
            if (resp?.status === 401) {
                await refreshIdToken();
                console.debug('Retry findItems ' + i + '/' + (retry - 1));
                continue;
            }
            break;
        }
        return resp;
    }

    /**
     * ユーザアイテムカテゴリ一覧を取得します。
     * @summary ユーザアイテムカテゴリ一覧取得
     * @param {string} itemCategoryCode アイテム種類コード
     * @param {number} [page] ページ番号
     * @param {number} [perPage] 1ページあたりの要素数 (MAX100)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ItemApi
     */
    public async getCategoryUserItems(itemCategoryCode: string, page?: number, perPage?: number, options?: AxiosRequestConfig) {
        const retry = 2;
        let resp;
        for (let i = 0; i < retry; i++) {
            resp = await ItemApiFp(this.configuration).getCategoryUserItems(itemCategoryCode, page, perPage, options).then((request) => request(this.axios, this.basePath)).catch((error) => {
                if (error?.response) {
                    return error.response;
                } else {
                    return { status: 500 };
                }
            });
            if (resp?.status === 401) {
                await refreshIdToken();
                console.debug('Retry getCategoryUserItems ' + i + '/' + (retry - 1));
                continue;
            }
            break;
        }
        return resp;
    }

    /**
     * 装備レベルアップ情報を取得します。
     * @summary 装備レベルアップ情報取得
     * @param {number} equipmentId 装備ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ItemApi
     */
    public async getEquipmentLevelUpInfo(equipmentId: number, options?: AxiosRequestConfig) {
        const retry = 2;
        let resp;
        for (let i = 0; i < retry; i++) {
            resp = await ItemApiFp(this.configuration).getEquipmentLevelUpInfo(equipmentId, options).then((request) => request(this.axios, this.basePath)).catch((error) => {
                if (error?.response) {
                    return error.response;
                } else {
                    return { status: 500 };
                }
            });
            if (resp?.status === 401) {
                await refreshIdToken();
                console.debug('Retry getEquipmentLevelUpInfo ' + i + '/' + (retry - 1));
                continue;
            }
            break;
        }
        return resp;
    }

    /**
     * ボス図鑑を取得します。
     * @summary ボス図鑑取得
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ItemApi
     */
    public async getSeasonBosses(options?: AxiosRequestConfig) {
        const retry = 2;
        let resp;
        for (let i = 0; i < retry; i++) {
            resp = await ItemApiFp(this.configuration).getSeasonBosses(options).then((request) => request(this.axios, this.basePath)).catch((error) => {
                if (error?.response) {
                    return error.response;
                } else {
                    return { status: 500 };
                }
            });
            if (resp?.status === 401) {
                await refreshIdToken();
                console.debug('Retry getSeasonBosses ' + i + '/' + (retry - 1));
                continue;
            }
            break;
        }
        return resp;
    }

    /**
     * ユーザアイテム一覧を取得します。
     * @summary ユーザアイテム一覧取得
     * @param {number} [page] ページ番号
     * @param {number} [perPage] 1ページあたりの要素数 (MAX100)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ItemApi
     */
    public async getUserItems(page?: number, perPage?: number, options?: AxiosRequestConfig) {
        const retry = 2;
        let resp;
        for (let i = 0; i < retry; i++) {
            resp = await ItemApiFp(this.configuration).getUserItems(page, perPage, options).then((request) => request(this.axios, this.basePath)).catch((error) => {
                if (error?.response) {
                    return error.response;
                } else {
                    return { status: 500 };
                }
            });
            if (resp?.status === 401) {
                await refreshIdToken();
                console.debug('Retry getUserItems ' + i + '/' + (retry - 1));
                continue;
            }
            break;
        }
        return resp;
    }

    /**
     * ユーザスキミー装備アイテム一覧を取得します。
     * @summary ユーザスキミー装備アイテム一覧取得
     * @param {number} skimmyType スキミータイプ
     * @param {number} [page] ページ番号
     * @param {number} [perPage] 1ページあたりの要素数 (MAX100)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ItemApi
     */
    public async getUserSkimmyEquipmentItems(skimmyType: number, page?: number, perPage?: number, options?: AxiosRequestConfig) {
        const retry = 2;
        let resp;
        for (let i = 0; i < retry; i++) {
            resp = await ItemApiFp(this.configuration).getUserSkimmyEquipmentItems(skimmyType, page, perPage, options).then((request) => request(this.axios, this.basePath)).catch((error) => {
                if (error?.response) {
                    return error.response;
                } else {
                    return { status: 500 };
                }
            });
            if (resp?.status === 401) {
                await refreshIdToken();
                console.debug('Retry getUserSkimmyEquipmentItems ' + i + '/' + (retry - 1));
                continue;
            }
            break;
        }
        return resp;
    }

    /**
     * 装備経験値とレベルを更新します。
     * @summary 装備経験値とレベルの更新
     * @param {EquipmentLevelUpReq} equipmentLevelUpReq 装備の経験値とレベルアップ情報
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ItemApi
     */
    public async levelUpEquipment(equipmentLevelUpReq: EquipmentLevelUpReq, options?: AxiosRequestConfig) {
        const retry = 2;
        let resp;
        for (let i = 0; i < retry; i++) {
            resp = await ItemApiFp(this.configuration).levelUpEquipment(equipmentLevelUpReq, options).then((request) => request(this.axios, this.basePath)).catch((error) => {
                if (error?.response) {
                    return error.response;
                } else {
                    return { status: 500 };
                }
            });
            if (resp?.status === 401) {
                await refreshIdToken();
                console.debug('Retry levelUpEquipment ' + i + '/' + (retry - 1));
                continue;
            }
            break;
        }
        return resp;
    }

    /**
     * アイテムを使用してスキミーをアンロックします。
     * @summary アイテムを使用してスキミーアンロック
     * @param {number} skimmyType スキミータイプ
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ItemApi
     */
    public async unlockSkimmyWithKey(skimmyType: number, options?: AxiosRequestConfig) {
        const retry = 2;
        let resp;
        for (let i = 0; i < retry; i++) {
            resp = await ItemApiFp(this.configuration).unlockSkimmyWithKey(skimmyType, options).then((request) => request(this.axios, this.basePath)).catch((error) => {
                if (error?.response) {
                    return error.response;
                } else {
                    return { status: 500 };
                }
            });
            if (resp?.status === 401) {
                await refreshIdToken();
                console.debug('Retry unlockSkimmyWithKey ' + i + '/' + (retry - 1));
                continue;
            }
            break;
        }
        return resp;
    }
}


/**
 * MissionApi - axios parameter creator
 * @export
 */
export const MissionApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * アラート関連ミッションの状態を取得します。
         * @summary アラート関連ミッションの状態取得
         * @param {string} username ユーザ名
         * @param {MissionAlertRequest} missionAlertRequest アラート関連ミッション情報
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        checkAlertMissionStatus: async (username: string, missionAlertRequest: MissionAlertRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'username' is not null or undefined
            assertParamExists('checkAlertMissionStatus', 'username', username)
            // verify required parameter 'missionAlertRequest' is not null or undefined
            assertParamExists('checkAlertMissionStatus', 'missionAlertRequest', missionAlertRequest)
            const localVarPath = `/missions/{username}/alert/check`
                .replace(`{${"username"}}`, encodeURIComponent(String(username)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(missionAlertRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * デイリーミッションを取得します。
         * @summary デイリーミッションの取得
         * @param {number} missionType 種別
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDailyMissions: async (missionType: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'missionType' is not null or undefined
            assertParamExists('getDailyMissions', 'missionType', missionType)
            const localVarPath = `/missions/daily/{mission_type}`
                .replace(`{${"mission_type"}}`, encodeURIComponent(String(missionType)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * デイリーミッションヒストリーカレンダーを取得します。
         * @summary デイリーミッションヒストリーカレンダーの取得
         * @param {number} month 月
         * @param {number} year 年
         * @param {number} missionType 種別
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDailyMissionsHistoryCalendar: async (month: number, year: number, missionType: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'month' is not null or undefined
            assertParamExists('getDailyMissionsHistoryCalendar', 'month', month)
            // verify required parameter 'year' is not null or undefined
            assertParamExists('getDailyMissionsHistoryCalendar', 'year', year)
            // verify required parameter 'missionType' is not null or undefined
            assertParamExists('getDailyMissionsHistoryCalendar', 'missionType', missionType)
            const localVarPath = `/missions/daily/calendar/{month}/{year}/{mission_type}`
                .replace(`{${"month"}}`, encodeURIComponent(String(month)))
                .replace(`{${"year"}}`, encodeURIComponent(String(year)))
                .replace(`{${"mission_type"}}`, encodeURIComponent(String(missionType)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * デイリーミッション履歴を取得します。
         * @summary デイリーミッション履歴の取得
         * @param {string} period ミッション履歴期間
         * @param {number} missionType 種別
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDailyMissionsRecord: async (period: string, missionType: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'period' is not null or undefined
            assertParamExists('getDailyMissionsRecord', 'period', period)
            // verify required parameter 'missionType' is not null or undefined
            assertParamExists('getDailyMissionsRecord', 'missionType', missionType)
            const localVarPath = `/missions/daily/record/{period}/{mission_type}`
                .replace(`{${"period"}}`, encodeURIComponent(String(period)))
                .replace(`{${"mission_type"}}`, encodeURIComponent(String(missionType)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * アラート関連ミッションクリア状態をクリアに設定します。
         * @summary アラート関連ミッションクリア状態の設定
         * @param {string} username ユーザ名
         * @param {MissionAlertRequest} missionAlertRequest アラート関連ミッション情報
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setClearedAlertMissionStatus: async (username: string, missionAlertRequest: MissionAlertRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'username' is not null or undefined
            assertParamExists('setClearedAlertMissionStatus', 'username', username)
            // verify required parameter 'missionAlertRequest' is not null or undefined
            assertParamExists('setClearedAlertMissionStatus', 'missionAlertRequest', missionAlertRequest)
            const localVarPath = `/missions/{username}/alert/cleared`
                .replace(`{${"username"}}`, encodeURIComponent(String(username)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(missionAlertRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * デイリーミッションを達成状態に更新します。
         * @summary デイリーミッションを達成にする
         * @param {MissionDailyAccomplishedRequest} missionDailyAccomplishedRequest 達成にするデイリーミッションのコード
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setDailyMissionAccomplished: async (missionDailyAccomplishedRequest: MissionDailyAccomplishedRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'missionDailyAccomplishedRequest' is not null or undefined
            assertParamExists('setDailyMissionAccomplished', 'missionDailyAccomplishedRequest', missionDailyAccomplishedRequest)
            const localVarPath = `/missions/daily`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(missionDailyAccomplishedRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * MissionApi - functional programming interface
 * @export
 */
export const MissionApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = MissionApiAxiosParamCreator(configuration)
    return {
        /**
         * アラート関連ミッションの状態を取得します。
         * @summary アラート関連ミッションの状態取得
         * @param {string} username ユーザ名
         * @param {MissionAlertRequest} missionAlertRequest アラート関連ミッション情報
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async checkAlertMissionStatus(username: string, missionAlertRequest: MissionAlertRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MissionStatus>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.checkAlertMissionStatus(username, missionAlertRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * デイリーミッションを取得します。
         * @summary デイリーミッションの取得
         * @param {number} missionType 種別
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDailyMissions(missionType: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MissionsDaily>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDailyMissions(missionType, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * デイリーミッションヒストリーカレンダーを取得します。
         * @summary デイリーミッションヒストリーカレンダーの取得
         * @param {number} month 月
         * @param {number} year 年
         * @param {number} missionType 種別
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDailyMissionsHistoryCalendar(month: number, year: number, missionType: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MissionsDailyCalendar>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDailyMissionsHistoryCalendar(month, year, missionType, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * デイリーミッション履歴を取得します。
         * @summary デイリーミッション履歴の取得
         * @param {string} period ミッション履歴期間
         * @param {number} missionType 種別
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDailyMissionsRecord(period: string, missionType: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MissionRecord>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDailyMissionsRecord(period, missionType, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * アラート関連ミッションクリア状態をクリアに設定します。
         * @summary アラート関連ミッションクリア状態の設定
         * @param {string} username ユーザ名
         * @param {MissionAlertRequest} missionAlertRequest アラート関連ミッション情報
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async setClearedAlertMissionStatus(username: string, missionAlertRequest: MissionAlertRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.setClearedAlertMissionStatus(username, missionAlertRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * デイリーミッションを達成状態に更新します。
         * @summary デイリーミッションを達成にする
         * @param {MissionDailyAccomplishedRequest} missionDailyAccomplishedRequest 達成にするデイリーミッションのコード
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async setDailyMissionAccomplished(missionDailyAccomplishedRequest: MissionDailyAccomplishedRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.setDailyMissionAccomplished(missionDailyAccomplishedRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * MissionApi - factory interface
 * @export
 */
export const MissionApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = MissionApiFp(configuration)
    return {
        /**
         * アラート関連ミッションの状態を取得します。
         * @summary アラート関連ミッションの状態取得
         * @param {string} username ユーザ名
         * @param {MissionAlertRequest} missionAlertRequest アラート関連ミッション情報
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        checkAlertMissionStatus(username: string, missionAlertRequest: MissionAlertRequest, options?: any): AxiosPromise<MissionStatus> {
            return localVarFp.checkAlertMissionStatus(username, missionAlertRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * デイリーミッションを取得します。
         * @summary デイリーミッションの取得
         * @param {number} missionType 種別
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDailyMissions(missionType: number, options?: any): AxiosPromise<MissionsDaily> {
            return localVarFp.getDailyMissions(missionType, options).then((request) => request(axios, basePath));
        },
        /**
         * デイリーミッションヒストリーカレンダーを取得します。
         * @summary デイリーミッションヒストリーカレンダーの取得
         * @param {number} month 月
         * @param {number} year 年
         * @param {number} missionType 種別
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDailyMissionsHistoryCalendar(month: number, year: number, missionType: number, options?: any): AxiosPromise<MissionsDailyCalendar> {
            return localVarFp.getDailyMissionsHistoryCalendar(month, year, missionType, options).then((request) => request(axios, basePath));
        },
        /**
         * デイリーミッション履歴を取得します。
         * @summary デイリーミッション履歴の取得
         * @param {string} period ミッション履歴期間
         * @param {number} missionType 種別
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDailyMissionsRecord(period: string, missionType: number, options?: any): AxiosPromise<MissionRecord> {
            return localVarFp.getDailyMissionsRecord(period, missionType, options).then((request) => request(axios, basePath));
        },
        /**
         * アラート関連ミッションクリア状態をクリアに設定します。
         * @summary アラート関連ミッションクリア状態の設定
         * @param {string} username ユーザ名
         * @param {MissionAlertRequest} missionAlertRequest アラート関連ミッション情報
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setClearedAlertMissionStatus(username: string, missionAlertRequest: MissionAlertRequest, options?: any): AxiosPromise<void> {
            return localVarFp.setClearedAlertMissionStatus(username, missionAlertRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * デイリーミッションを達成状態に更新します。
         * @summary デイリーミッションを達成にする
         * @param {MissionDailyAccomplishedRequest} missionDailyAccomplishedRequest 達成にするデイリーミッションのコード
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setDailyMissionAccomplished(missionDailyAccomplishedRequest: MissionDailyAccomplishedRequest, options?: any): AxiosPromise<void> {
            return localVarFp.setDailyMissionAccomplished(missionDailyAccomplishedRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * MissionApi - object-oriented interface
 * @export
 * @class MissionApi
 * @extends {BaseAPI}
 */
export class MissionApi extends BaseAPI {
    /**
     * アラート関連ミッションの状態を取得します。
     * @summary アラート関連ミッションの状態取得
     * @param {string} username ユーザ名
     * @param {MissionAlertRequest} missionAlertRequest アラート関連ミッション情報
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MissionApi
     */
    public async checkAlertMissionStatus(username: string, missionAlertRequest: MissionAlertRequest, options?: AxiosRequestConfig) {
        const retry = 2;
        let resp;
        for (let i = 0; i < retry; i++) {
            resp = await MissionApiFp(this.configuration).checkAlertMissionStatus(username, missionAlertRequest, options).then((request) => request(this.axios, this.basePath)).catch((error) => {
                if (error?.response) {
                    return error.response;
                } else {
                    return { status: 500 };
                }
            });
            if (resp?.status === 401) {
                await refreshIdToken();
                console.debug('Retry checkAlertMissionStatus ' + i + '/' + (retry - 1));
                continue;
            }
            break;
        }
        return resp;
    }

    /**
     * デイリーミッションを取得します。
     * @summary デイリーミッションの取得
     * @param {number} missionType 種別
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MissionApi
     */
    public async getDailyMissions(missionType: number, options?: AxiosRequestConfig) {
        const retry = 2;
        let resp;
        for (let i = 0; i < retry; i++) {
            resp = await MissionApiFp(this.configuration).getDailyMissions(missionType, options).then((request) => request(this.axios, this.basePath)).catch((error) => {
                if (error?.response) {
                    return error.response;
                } else {
                    return { status: 500 };
                }
            });
            if (resp?.status === 401) {
                await refreshIdToken();
                console.debug('Retry getDailyMissions ' + i + '/' + (retry - 1));
                continue;
            }
            break;
        }
        return resp;
    }

    /**
     * デイリーミッションヒストリーカレンダーを取得します。
     * @summary デイリーミッションヒストリーカレンダーの取得
     * @param {number} month 月
     * @param {number} year 年
     * @param {number} missionType 種別
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MissionApi
     */
    public async getDailyMissionsHistoryCalendar(month: number, year: number, missionType: number, options?: AxiosRequestConfig) {
        const retry = 2;
        let resp;
        for (let i = 0; i < retry; i++) {
            resp = await MissionApiFp(this.configuration).getDailyMissionsHistoryCalendar(month, year, missionType, options).then((request) => request(this.axios, this.basePath)).catch((error) => {
                if (error?.response) {
                    return error.response;
                } else {
                    return { status: 500 };
                }
            });
            if (resp?.status === 401) {
                await refreshIdToken();
                console.debug('Retry getDailyMissionsHistoryCalendar ' + i + '/' + (retry - 1));
                continue;
            }
            break;
        }
        return resp;
    }

    /**
     * デイリーミッション履歴を取得します。
     * @summary デイリーミッション履歴の取得
     * @param {string} period ミッション履歴期間
     * @param {number} missionType 種別
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MissionApi
     */
    public async getDailyMissionsRecord(period: string, missionType: number, options?: AxiosRequestConfig) {
        const retry = 2;
        let resp;
        for (let i = 0; i < retry; i++) {
            resp = await MissionApiFp(this.configuration).getDailyMissionsRecord(period, missionType, options).then((request) => request(this.axios, this.basePath)).catch((error) => {
                if (error?.response) {
                    return error.response;
                } else {
                    return { status: 500 };
                }
            });
            if (resp?.status === 401) {
                await refreshIdToken();
                console.debug('Retry getDailyMissionsRecord ' + i + '/' + (retry - 1));
                continue;
            }
            break;
        }
        return resp;
    }

    /**
     * アラート関連ミッションクリア状態をクリアに設定します。
     * @summary アラート関連ミッションクリア状態の設定
     * @param {string} username ユーザ名
     * @param {MissionAlertRequest} missionAlertRequest アラート関連ミッション情報
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MissionApi
     */
    public async setClearedAlertMissionStatus(username: string, missionAlertRequest: MissionAlertRequest, options?: AxiosRequestConfig) {
        const retry = 2;
        let resp;
        for (let i = 0; i < retry; i++) {
            resp = await MissionApiFp(this.configuration).setClearedAlertMissionStatus(username, missionAlertRequest, options).then((request) => request(this.axios, this.basePath)).catch((error) => {
                if (error?.response) {
                    return error.response;
                } else {
                    return { status: 500 };
                }
            });
            if (resp?.status === 401) {
                await refreshIdToken();
                console.debug('Retry setClearedAlertMissionStatus ' + i + '/' + (retry - 1));
                continue;
            }
            break;
        }
        return resp;
    }

    /**
     * デイリーミッションを達成状態に更新します。
     * @summary デイリーミッションを達成にする
     * @param {MissionDailyAccomplishedRequest} missionDailyAccomplishedRequest 達成にするデイリーミッションのコード
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MissionApi
     */
    public async setDailyMissionAccomplished(missionDailyAccomplishedRequest: MissionDailyAccomplishedRequest, options?: AxiosRequestConfig) {
        const retry = 2;
        let resp;
        for (let i = 0; i < retry; i++) {
            resp = await MissionApiFp(this.configuration).setDailyMissionAccomplished(missionDailyAccomplishedRequest, options).then((request) => request(this.axios, this.basePath)).catch((error) => {
                if (error?.response) {
                    return error.response;
                } else {
                    return { status: 500 };
                }
            });
            if (resp?.status === 401) {
                await refreshIdToken();
                console.debug('Retry setDailyMissionAccomplished ' + i + '/' + (retry - 1));
                continue;
            }
            break;
        }
        return resp;
    }
}


/**
 * MobileApi - axios parameter creator
 * @export
 */
export const MobileApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * アプリ内課金を処理します。
         * @summary アプリ内課金
         * @param {PurchaseReq} purchaseReq アプリ内課金情報
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        purchaseItem: async (purchaseReq: PurchaseReq, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'purchaseReq' is not null or undefined
            assertParamExists('purchaseItem', 'purchaseReq', purchaseReq)
            const localVarPath = `/mobile/purchase`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(purchaseReq, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * MobileApi - functional programming interface
 * @export
 */
export const MobileApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = MobileApiAxiosParamCreator(configuration)
    return {
        /**
         * アプリ内課金を処理します。
         * @summary アプリ内課金
         * @param {PurchaseReq} purchaseReq アプリ内課金情報
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async purchaseItem(purchaseReq: PurchaseReq, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PurchaseResp>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.purchaseItem(purchaseReq, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * MobileApi - factory interface
 * @export
 */
export const MobileApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = MobileApiFp(configuration)
    return {
        /**
         * アプリ内課金を処理します。
         * @summary アプリ内課金
         * @param {PurchaseReq} purchaseReq アプリ内課金情報
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        purchaseItem(purchaseReq: PurchaseReq, options?: any): AxiosPromise<PurchaseResp> {
            return localVarFp.purchaseItem(purchaseReq, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * MobileApi - object-oriented interface
 * @export
 * @class MobileApi
 * @extends {BaseAPI}
 */
export class MobileApi extends BaseAPI {
    /**
     * アプリ内課金を処理します。
     * @summary アプリ内課金
     * @param {PurchaseReq} purchaseReq アプリ内課金情報
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MobileApi
     */
    public async purchaseItem(purchaseReq: PurchaseReq, options?: AxiosRequestConfig) {
        const retry = 2;
        let resp;
        for (let i = 0; i < retry; i++) {
            resp = await MobileApiFp(this.configuration).purchaseItem(purchaseReq, options).then((request) => request(this.axios, this.basePath)).catch((error) => {
                if (error?.response) {
                    return error.response;
                } else {
                    return { status: 500 };
                }
            });
            if (resp?.status === 401) {
                await refreshIdToken();
                console.debug('Retry purchaseItem ' + i + '/' + (retry - 1));
                continue;
            }
            break;
        }
        return resp;
    }
}


/**
 * NoticeApi - axios parameter creator
 * @export
 */
export const NoticeApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * ユーザへのお知らせ一覧を取得します。
         * @summary ユーザへのお知られ一覧取得
         * @param {string} username ユーザ名
         * @param {number} [page] ページ番号
         * @param {number} [perPage] 1ページあたりの要素数 (MAX100)
         * @param {number} [readStatus] 既読状態
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findUserNotices: async (username: string, page?: number, perPage?: number, readStatus?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'username' is not null or undefined
            assertParamExists('findUserNotices', 'username', username)
            const localVarPath = `/notices/users/{username}`
                .replace(`{${"username"}}`, encodeURIComponent(String(username)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (perPage !== undefined) {
                localVarQueryParameter['per_page'] = perPage;
            }

            if (readStatus !== undefined) {
                localVarQueryParameter['read_status'] = readStatus;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 指定したお知らせを既読状態に更新します。
         * @summary お知らせに既読にする
         * @param {string} username ユーザ名
         * @param {NoticesReadRequest} noticesReadRequest 指定するお知らせ
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setUserNoticeRead: async (username: string, noticesReadRequest: NoticesReadRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'username' is not null or undefined
            assertParamExists('setUserNoticeRead', 'username', username)
            // verify required parameter 'noticesReadRequest' is not null or undefined
            assertParamExists('setUserNoticeRead', 'noticesReadRequest', noticesReadRequest)
            const localVarPath = `/notices/users/{username}`
                .replace(`{${"username"}}`, encodeURIComponent(String(username)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(noticesReadRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * NoticeApi - functional programming interface
 * @export
 */
export const NoticeApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = NoticeApiAxiosParamCreator(configuration)
    return {
        /**
         * ユーザへのお知らせ一覧を取得します。
         * @summary ユーザへのお知られ一覧取得
         * @param {string} username ユーザ名
         * @param {number} [page] ページ番号
         * @param {number} [perPage] 1ページあたりの要素数 (MAX100)
         * @param {number} [readStatus] 既読状態
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findUserNotices(username: string, page?: number, perPage?: number, readStatus?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Notices>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findUserNotices(username, page, perPage, readStatus, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 指定したお知らせを既読状態に更新します。
         * @summary お知らせに既読にする
         * @param {string} username ユーザ名
         * @param {NoticesReadRequest} noticesReadRequest 指定するお知らせ
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async setUserNoticeRead(username: string, noticesReadRequest: NoticesReadRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<NoticesRead>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.setUserNoticeRead(username, noticesReadRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * NoticeApi - factory interface
 * @export
 */
export const NoticeApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = NoticeApiFp(configuration)
    return {
        /**
         * ユーザへのお知らせ一覧を取得します。
         * @summary ユーザへのお知られ一覧取得
         * @param {string} username ユーザ名
         * @param {number} [page] ページ番号
         * @param {number} [perPage] 1ページあたりの要素数 (MAX100)
         * @param {number} [readStatus] 既読状態
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findUserNotices(username: string, page?: number, perPage?: number, readStatus?: number, options?: any): AxiosPromise<Notices> {
            return localVarFp.findUserNotices(username, page, perPage, readStatus, options).then((request) => request(axios, basePath));
        },
        /**
         * 指定したお知らせを既読状態に更新します。
         * @summary お知らせに既読にする
         * @param {string} username ユーザ名
         * @param {NoticesReadRequest} noticesReadRequest 指定するお知らせ
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setUserNoticeRead(username: string, noticesReadRequest: NoticesReadRequest, options?: any): AxiosPromise<NoticesRead> {
            return localVarFp.setUserNoticeRead(username, noticesReadRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * NoticeApi - object-oriented interface
 * @export
 * @class NoticeApi
 * @extends {BaseAPI}
 */
export class NoticeApi extends BaseAPI {
    /**
     * ユーザへのお知らせ一覧を取得します。
     * @summary ユーザへのお知られ一覧取得
     * @param {string} username ユーザ名
     * @param {number} [page] ページ番号
     * @param {number} [perPage] 1ページあたりの要素数 (MAX100)
     * @param {number} [readStatus] 既読状態
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NoticeApi
     */
    public async findUserNotices(username: string, page?: number, perPage?: number, readStatus?: number, options?: AxiosRequestConfig) {
        const retry = 2;
        let resp;
        for (let i = 0; i < retry; i++) {
            resp = await NoticeApiFp(this.configuration).findUserNotices(username, page, perPage, readStatus, options).then((request) => request(this.axios, this.basePath)).catch((error) => {
                if (error?.response) {
                    return error.response;
                } else {
                    return { status: 500 };
                }
            });
            if (resp?.status === 401) {
                await refreshIdToken();
                console.debug('Retry findUserNotices ' + i + '/' + (retry - 1));
                continue;
            }
            break;
        }
        return resp;
    }

    /**
     * 指定したお知らせを既読状態に更新します。
     * @summary お知らせに既読にする
     * @param {string} username ユーザ名
     * @param {NoticesReadRequest} noticesReadRequest 指定するお知らせ
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NoticeApi
     */
    public async setUserNoticeRead(username: string, noticesReadRequest: NoticesReadRequest, options?: AxiosRequestConfig) {
        const retry = 2;
        let resp;
        for (let i = 0; i < retry; i++) {
            resp = await NoticeApiFp(this.configuration).setUserNoticeRead(username, noticesReadRequest, options).then((request) => request(this.axios, this.basePath)).catch((error) => {
                if (error?.response) {
                    return error.response;
                } else {
                    return { status: 500 };
                }
            });
            if (resp?.status === 401) {
                await refreshIdToken();
                console.debug('Retry setUserNoticeRead ' + i + '/' + (retry - 1));
                continue;
            }
            break;
        }
        return resp;
    }
}


/**
 * OreApi - axios parameter creator
 * @export
 */
export const OreApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 原石取引を記録します。
         * @summary 原石取引記録
         * @param {OreTransactionRequest} oreTransactionRequest 原石取引情報
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        recordOreTransaction: async (oreTransactionRequest: OreTransactionRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'oreTransactionRequest' is not null or undefined
            assertParamExists('recordOreTransaction', 'oreTransactionRequest', oreTransactionRequest)
            const localVarPath = `/ore/transaction`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(oreTransactionRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * OreApi - functional programming interface
 * @export
 */
export const OreApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = OreApiAxiosParamCreator(configuration)
    return {
        /**
         * 原石取引を記録します。
         * @summary 原石取引記録
         * @param {OreTransactionRequest} oreTransactionRequest 原石取引情報
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async recordOreTransaction(oreTransactionRequest: OreTransactionRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OreTransaction>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.recordOreTransaction(oreTransactionRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * OreApi - factory interface
 * @export
 */
export const OreApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = OreApiFp(configuration)
    return {
        /**
         * 原石取引を記録します。
         * @summary 原石取引記録
         * @param {OreTransactionRequest} oreTransactionRequest 原石取引情報
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        recordOreTransaction(oreTransactionRequest: OreTransactionRequest, options?: any): AxiosPromise<OreTransaction> {
            return localVarFp.recordOreTransaction(oreTransactionRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * OreApi - object-oriented interface
 * @export
 * @class OreApi
 * @extends {BaseAPI}
 */
export class OreApi extends BaseAPI {
    /**
     * 原石取引を記録します。
     * @summary 原石取引記録
     * @param {OreTransactionRequest} oreTransactionRequest 原石取引情報
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OreApi
     */
    public async recordOreTransaction(oreTransactionRequest: OreTransactionRequest, options?: AxiosRequestConfig) {
        const retry = 2;
        let resp;
        for (let i = 0; i < retry; i++) {
            resp = await OreApiFp(this.configuration).recordOreTransaction(oreTransactionRequest, options).then((request) => request(this.axios, this.basePath)).catch((error) => {
                if (error?.response) {
                    return error.response;
                } else {
                    return { status: 500 };
                }
            });
            if (resp?.status === 401) {
                await refreshIdToken();
                console.debug('Retry recordOreTransaction ' + i + '/' + (retry - 1));
                continue;
            }
            break;
        }
        return resp;
    }
}


/**
 * PersonaApi - axios parameter creator
 * @export
 */
export const PersonaApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * ペルソナを取得します。
         * @summary ペルソナ取得
         * @param {string} username ユーザ名
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPersona: async (username: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'username' is not null or undefined
            assertParamExists('getPersona', 'username', username)
            const localVarPath = `/personas/{username}`
                .replace(`{${"username"}}`, encodeURIComponent(String(username)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * ペルソナのテキストを設定します。
         * @summary ペルソナテキストの設定
         * @param {string} username ユーザ名
         * @param {SetPersonaTextRequest} setPersonaTextRequest ペルソナテキスト情報
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setPersonaText: async (username: string, setPersonaTextRequest: SetPersonaTextRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'username' is not null or undefined
            assertParamExists('setPersonaText', 'username', username)
            // verify required parameter 'setPersonaTextRequest' is not null or undefined
            assertParamExists('setPersonaText', 'setPersonaTextRequest', setPersonaTextRequest)
            const localVarPath = `/personas/{username}`
                .replace(`{${"username"}}`, encodeURIComponent(String(username)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(setPersonaTextRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 自身のペルソナ画像ファイルをアップロードします。
         * @summary ユーザのペルソナ画像ファイルをアップロード
         * @param {string} username ユーザ名
         * @param {File} [personaImage] ユーザがアップロードしたペルソナ画像
         * @param {string} [fileName] ファイル名
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadPersonaImage: async (username: string, personaImage?: File, fileName?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'username' is not null or undefined
            assertParamExists('uploadPersonaImage', 'username', username)
            const localVarPath = `/uploads/personas/image/{username}`
                .replace(`{${"username"}}`, encodeURIComponent(String(username)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


            if (personaImage !== undefined) { 
                localVarFormParams.append('personaImage', personaImage as any);
            }
    
            if (fileName !== undefined) { 
                localVarFormParams.append('fileName', fileName as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PersonaApi - functional programming interface
 * @export
 */
export const PersonaApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PersonaApiAxiosParamCreator(configuration)
    return {
        /**
         * ペルソナを取得します。
         * @summary ペルソナ取得
         * @param {string} username ユーザ名
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPersona(username: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Persona>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPersona(username, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * ペルソナのテキストを設定します。
         * @summary ペルソナテキストの設定
         * @param {string} username ユーザ名
         * @param {SetPersonaTextRequest} setPersonaTextRequest ペルソナテキスト情報
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async setPersonaText(username: string, setPersonaTextRequest: SetPersonaTextRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Persona>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.setPersonaText(username, setPersonaTextRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 自身のペルソナ画像ファイルをアップロードします。
         * @summary ユーザのペルソナ画像ファイルをアップロード
         * @param {string} username ユーザ名
         * @param {File} [personaImage] ユーザがアップロードしたペルソナ画像
         * @param {string} [fileName] ファイル名
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async uploadPersonaImage(username: string, personaImage?: File, fileName?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UploadResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.uploadPersonaImage(username, personaImage, fileName, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * PersonaApi - factory interface
 * @export
 */
export const PersonaApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PersonaApiFp(configuration)
    return {
        /**
         * ペルソナを取得します。
         * @summary ペルソナ取得
         * @param {string} username ユーザ名
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPersona(username: string, options?: any): AxiosPromise<Persona> {
            return localVarFp.getPersona(username, options).then((request) => request(axios, basePath));
        },
        /**
         * ペルソナのテキストを設定します。
         * @summary ペルソナテキストの設定
         * @param {string} username ユーザ名
         * @param {SetPersonaTextRequest} setPersonaTextRequest ペルソナテキスト情報
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setPersonaText(username: string, setPersonaTextRequest: SetPersonaTextRequest, options?: any): AxiosPromise<Persona> {
            return localVarFp.setPersonaText(username, setPersonaTextRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 自身のペルソナ画像ファイルをアップロードします。
         * @summary ユーザのペルソナ画像ファイルをアップロード
         * @param {string} username ユーザ名
         * @param {File} [personaImage] ユーザがアップロードしたペルソナ画像
         * @param {string} [fileName] ファイル名
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadPersonaImage(username: string, personaImage?: File, fileName?: string, options?: any): AxiosPromise<UploadResponse> {
            return localVarFp.uploadPersonaImage(username, personaImage, fileName, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PersonaApi - object-oriented interface
 * @export
 * @class PersonaApi
 * @extends {BaseAPI}
 */
export class PersonaApi extends BaseAPI {
    /**
     * ペルソナを取得します。
     * @summary ペルソナ取得
     * @param {string} username ユーザ名
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PersonaApi
     */
    public async getPersona(username: string, options?: AxiosRequestConfig) {
        const retry = 2;
        let resp;
        for (let i = 0; i < retry; i++) {
            resp = await PersonaApiFp(this.configuration).getPersona(username, options).then((request) => request(this.axios, this.basePath)).catch((error) => {
                if (error?.response) {
                    return error.response;
                } else {
                    return { status: 500 };
                }
            });
            if (resp?.status === 401) {
                await refreshIdToken();
                console.debug('Retry getPersona ' + i + '/' + (retry - 1));
                continue;
            }
            break;
        }
        return resp;
    }

    /**
     * ペルソナのテキストを設定します。
     * @summary ペルソナテキストの設定
     * @param {string} username ユーザ名
     * @param {SetPersonaTextRequest} setPersonaTextRequest ペルソナテキスト情報
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PersonaApi
     */
    public async setPersonaText(username: string, setPersonaTextRequest: SetPersonaTextRequest, options?: AxiosRequestConfig) {
        const retry = 2;
        let resp;
        for (let i = 0; i < retry; i++) {
            resp = await PersonaApiFp(this.configuration).setPersonaText(username, setPersonaTextRequest, options).then((request) => request(this.axios, this.basePath)).catch((error) => {
                if (error?.response) {
                    return error.response;
                } else {
                    return { status: 500 };
                }
            });
            if (resp?.status === 401) {
                await refreshIdToken();
                console.debug('Retry setPersonaText ' + i + '/' + (retry - 1));
                continue;
            }
            break;
        }
        return resp;
    }

    /**
     * 自身のペルソナ画像ファイルをアップロードします。
     * @summary ユーザのペルソナ画像ファイルをアップロード
     * @param {string} username ユーザ名
     * @param {File} [personaImage] ユーザがアップロードしたペルソナ画像
     * @param {string} [fileName] ファイル名
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PersonaApi
     */
    public async uploadPersonaImage(username: string, personaImage?: File, fileName?: string, options?: AxiosRequestConfig) {
        const retry = 2;
        let resp;
        for (let i = 0; i < retry; i++) {
            resp = await PersonaApiFp(this.configuration).uploadPersonaImage(username, personaImage, fileName, options).then((request) => request(this.axios, this.basePath)).catch((error) => {
                if (error?.response) {
                    return error.response;
                } else {
                    return { status: 500 };
                }
            });
            if (resp?.status === 401) {
                await refreshIdToken();
                console.debug('Retry uploadPersonaImage ' + i + '/' + (retry - 1));
                continue;
            }
            break;
        }
        return resp;
    }
}


/**
 * ProjApi - axios parameter creator
 * @export
 */
export const ProjApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * プロジェクトのタスクを追加します。
         * @summary タスク追加
         * @param {string} projectIdentifier プロジェクト識別子
         * @param {TaskRequest} taskRequest タスク情報
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addProjectTask: async (projectIdentifier: string, taskRequest: TaskRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectIdentifier' is not null or undefined
            assertParamExists('addProjectTask', 'projectIdentifier', projectIdentifier)
            // verify required parameter 'taskRequest' is not null or undefined
            assertParamExists('addProjectTask', 'taskRequest', taskRequest)
            const localVarPath = `/projs/{project_identifier}/tasks`
                .replace(`{${"project_identifier"}}`, encodeURIComponent(String(projectIdentifier)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(taskRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * プロジェクトのテーブルを追加します。
         * @summary テーブル追加
         * @param {string} teamname チーム名
         * @param {number} projectOrder プロジェクトオーダー
         * @param {TableDefReq} tableDefReq テーブル情報
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addTableDef: async (teamname: string, projectOrder: number, tableDefReq: TableDefReq, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'teamname' is not null or undefined
            assertParamExists('addTableDef', 'teamname', teamname)
            // verify required parameter 'projectOrder' is not null or undefined
            assertParamExists('addTableDef', 'projectOrder', projectOrder)
            // verify required parameter 'tableDefReq' is not null or undefined
            assertParamExists('addTableDef', 'tableDefReq', tableDefReq)
            const localVarPath = `/teams/{teamname}/projs/{project_order}/tabledefs`
                .replace(`{${"teamname"}}`, encodeURIComponent(String(teamname)))
                .replace(`{${"project_order"}}`, encodeURIComponent(String(projectOrder)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(tableDefReq, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * レコードを追加します。
         * @summary レコード追加
         * @param {string} teamname チーム名
         * @param {number} projectOrder プロジェクトオーダー
         * @param {number} tableNumber テーブル番号
         * @param {TableRowReq} tableRowReq レコード情報
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addTableRow: async (teamname: string, projectOrder: number, tableNumber: number, tableRowReq: TableRowReq, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'teamname' is not null or undefined
            assertParamExists('addTableRow', 'teamname', teamname)
            // verify required parameter 'projectOrder' is not null or undefined
            assertParamExists('addTableRow', 'projectOrder', projectOrder)
            // verify required parameter 'tableNumber' is not null or undefined
            assertParamExists('addTableRow', 'tableNumber', tableNumber)
            // verify required parameter 'tableRowReq' is not null or undefined
            assertParamExists('addTableRow', 'tableRowReq', tableRowReq)
            const localVarPath = `/teams/{teamname}/projs/{project_order}/tables/{table_number}/rows`
                .replace(`{${"teamname"}}`, encodeURIComponent(String(teamname)))
                .replace(`{${"project_order"}}`, encodeURIComponent(String(projectOrder)))
                .replace(`{${"table_number"}}`, encodeURIComponent(String(tableNumber)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(tableRowReq, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 新しいチーム>プロジェクトを追加します。
         * @summary 新しいチーム>プロジェクトを追加
         * @param {string} teamname チーム名
         * @param {TeamProjReq} teamProjReq チーム名
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addTeamProject: async (teamname: string, teamProjReq: TeamProjReq, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'teamname' is not null or undefined
            assertParamExists('addTeamProject', 'teamname', teamname)
            // verify required parameter 'teamProjReq' is not null or undefined
            assertParamExists('addTeamProject', 'teamProjReq', teamProjReq)
            const localVarPath = `/teams/{teamname}/projs`
                .replace(`{${"teamname"}}`, encodeURIComponent(String(teamname)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(teamProjReq, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * プロジェクトのテーブルを削除します。
         * @summary テーブル削除
         * @param {string} teamname チーム名
         * @param {number} projectOrder プロジェクトオーダー
         * @param {number} tableNumber テーブル番号
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteTableDef: async (teamname: string, projectOrder: number, tableNumber: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'teamname' is not null or undefined
            assertParamExists('deleteTableDef', 'teamname', teamname)
            // verify required parameter 'projectOrder' is not null or undefined
            assertParamExists('deleteTableDef', 'projectOrder', projectOrder)
            // verify required parameter 'tableNumber' is not null or undefined
            assertParamExists('deleteTableDef', 'tableNumber', tableNumber)
            const localVarPath = `/teams/{teamname}/projs/{project_order}/tabledefs/{table_number}/delete`
                .replace(`{${"teamname"}}`, encodeURIComponent(String(teamname)))
                .replace(`{${"project_order"}}`, encodeURIComponent(String(projectOrder)))
                .replace(`{${"table_number"}}`, encodeURIComponent(String(tableNumber)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * レコードを削除します。
         * @summary レコード削除
         * @param {string} teamname チーム名
         * @param {number} projectOrder プロジェクトオーダー
         * @param {number} tableNumber テーブル番号
         * @param {string} tableRowKey レコードキー
         * @param {TableRowReq} tableRowReq レコード情報
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteTableRow: async (teamname: string, projectOrder: number, tableNumber: number, tableRowKey: string, tableRowReq: TableRowReq, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'teamname' is not null or undefined
            assertParamExists('deleteTableRow', 'teamname', teamname)
            // verify required parameter 'projectOrder' is not null or undefined
            assertParamExists('deleteTableRow', 'projectOrder', projectOrder)
            // verify required parameter 'tableNumber' is not null or undefined
            assertParamExists('deleteTableRow', 'tableNumber', tableNumber)
            // verify required parameter 'tableRowKey' is not null or undefined
            assertParamExists('deleteTableRow', 'tableRowKey', tableRowKey)
            // verify required parameter 'tableRowReq' is not null or undefined
            assertParamExists('deleteTableRow', 'tableRowReq', tableRowReq)
            const localVarPath = `/teams/{teamname}/projs/{project_order}/tables/{table_number}/rows/{table_key}`
                .replace(`{${"teamname"}}`, encodeURIComponent(String(teamname)))
                .replace(`{${"project_order"}}`, encodeURIComponent(String(projectOrder)))
                .replace(`{${"table_number"}}`, encodeURIComponent(String(tableNumber)))
                .replace(`{${"table_row_key"}}`, encodeURIComponent(String(tableRowKey)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(tableRowReq, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 指定されたチーム>プロジェクトを削除します。
         * @summary チーム>プロジェクトの削除
         * @param {string} teamname チーム名
         * @param {number} projectOrder プロジェクトオーダー
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteTeamProject: async (teamname: string, projectOrder: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'teamname' is not null or undefined
            assertParamExists('deleteTeamProject', 'teamname', teamname)
            // verify required parameter 'projectOrder' is not null or undefined
            assertParamExists('deleteTeamProject', 'projectOrder', projectOrder)
            const localVarPath = `/teams/{teamname}/projs/{project_order}`
                .replace(`{${"teamname"}}`, encodeURIComponent(String(teamname)))
                .replace(`{${"project_order"}}`, encodeURIComponent(String(projectOrder)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * プロジェクトメンバーを削除します。
         * @summary プロジェクトメンバーの削除
         * @param {string} teamname チーム名
         * @param {number} projectOrder プロジェクトオーダー
         * @param {string} username ユーザ名
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteTeamProjectMembers: async (teamname: string, projectOrder: number, username: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'teamname' is not null or undefined
            assertParamExists('deleteTeamProjectMembers', 'teamname', teamname)
            // verify required parameter 'projectOrder' is not null or undefined
            assertParamExists('deleteTeamProjectMembers', 'projectOrder', projectOrder)
            // verify required parameter 'username' is not null or undefined
            assertParamExists('deleteTeamProjectMembers', 'username', username)
            const localVarPath = `/teams/{teamname}/projs/{project_order}/members/{username}`
                .replace(`{${"teamname"}}`, encodeURIComponent(String(teamname)))
                .replace(`{${"project_order"}}`, encodeURIComponent(String(projectOrder)))
                .replace(`{${"username"}}`, encodeURIComponent(String(username)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * テーブルCSVを取得します。
         * @summary テーブルCSV取得
         * @param {string} teamname チーム名
         * @param {number} projectOrder プロジェクトオーダー
         * @param {number} tableNumber テーブル番号
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadTableCsv: async (teamname: string, projectOrder: number, tableNumber: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'teamname' is not null or undefined
            assertParamExists('downloadTableCsv', 'teamname', teamname)
            // verify required parameter 'projectOrder' is not null or undefined
            assertParamExists('downloadTableCsv', 'projectOrder', projectOrder)
            // verify required parameter 'tableNumber' is not null or undefined
            assertParamExists('downloadTableCsv', 'tableNumber', tableNumber)
            const localVarPath = `/teams/{teamname}/projs/{project_order}/tables/{table_number}/csv`
                .replace(`{${"teamname"}}`, encodeURIComponent(String(teamname)))
                .replace(`{${"project_order"}}`, encodeURIComponent(String(projectOrder)))
                .replace(`{${"table_number"}}`, encodeURIComponent(String(tableNumber)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * フィールド定義リストを取得します。
         * @summary フィールド定義リスト取得
         * @param {string} teamname チーム名
         * @param {number} projectOrder プロジェクトオーダー
         * @param {number} tableNumber テーブル番号
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findFieldDefs: async (teamname: string, projectOrder: number, tableNumber: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'teamname' is not null or undefined
            assertParamExists('findFieldDefs', 'teamname', teamname)
            // verify required parameter 'projectOrder' is not null or undefined
            assertParamExists('findFieldDefs', 'projectOrder', projectOrder)
            // verify required parameter 'tableNumber' is not null or undefined
            assertParamExists('findFieldDefs', 'tableNumber', tableNumber)
            const localVarPath = `/teams/{teamname}/projs/{project_order}/tabledefs/{table_number}`
                .replace(`{${"teamname"}}`, encodeURIComponent(String(teamname)))
                .replace(`{${"project_order"}}`, encodeURIComponent(String(projectOrder)))
                .replace(`{${"table_number"}}`, encodeURIComponent(String(tableNumber)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * プロジェクトのタスクのリストを取得します。
         * @summary タスクリスト取得
         * @param {string} projectIdentifier プロジェクト識別子
         * @param {number} [page] ページ番号
         * @param {number} [perPage] 1ページあたりの要素数 (MAX100)
         * @param {string} [status] ステータス条件
         * @param {number} [categoryId] カテゴリーID
         * @param {string} [search] 検索キーワード
         * @param {string} [sort] ソート条件
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findProjectTasks: async (projectIdentifier: string, page?: number, perPage?: number, status?: string, categoryId?: number, search?: string, sort?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectIdentifier' is not null or undefined
            assertParamExists('findProjectTasks', 'projectIdentifier', projectIdentifier)
            const localVarPath = `/projs/{project_identifier}/tasks`
                .replace(`{${"project_identifier"}}`, encodeURIComponent(String(projectIdentifier)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (perPage !== undefined) {
                localVarQueryParameter['per_page'] = perPage;
            }

            if (status !== undefined) {
                localVarQueryParameter['status'] = status;
            }

            if (categoryId !== undefined) {
                localVarQueryParameter['category_id'] = categoryId;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * プロジェクトリストを取得します。
         * @summary プロジェクトリストの取得
         * @param {number} [page] ページ番号
         * @param {number} [perPage] 1ページあたりの要素数 (MAX100)
         * @param {string} [status] ステータス条件
         * @param {boolean} [fav] お気に入り
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findProjects: async (page?: number, perPage?: number, status?: string, fav?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/projs`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (perPage !== undefined) {
                localVarQueryParameter['per_page'] = perPage;
            }

            if (status !== undefined) {
                localVarQueryParameter['status'] = status;
            }

            if (fav !== undefined) {
                localVarQueryParameter['fav'] = fav;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * プロジェクトのテーブルリストを取得します。
         * @summary プロジェクトのテーブルリスト取得
         * @param {string} teamname チーム名
         * @param {number} projectOrder プロジェクトオーダー
         * @param {number} [page] ページ番号
         * @param {number} [perPage] 1ページあたりの要素数 (MAX100)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findTableDef: async (teamname: string, projectOrder: number, page?: number, perPage?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'teamname' is not null or undefined
            assertParamExists('findTableDef', 'teamname', teamname)
            // verify required parameter 'projectOrder' is not null or undefined
            assertParamExists('findTableDef', 'projectOrder', projectOrder)
            const localVarPath = `/teams/{teamname}/projs/{project_order}/tabledefs`
                .replace(`{${"teamname"}}`, encodeURIComponent(String(teamname)))
                .replace(`{${"project_order"}}`, encodeURIComponent(String(projectOrder)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (perPage !== undefined) {
                localVarQueryParameter['per_page'] = perPage;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * レコードリストを取得します。
         * @summary レコードリスト取得
         * @param {string} teamname チーム名
         * @param {number} projectOrder プロジェクトオーダー
         * @param {number} tableNumber テーブル番号
         * @param {number} [page] ページ番号
         * @param {number} [perPage] 1ページあたりの要素数 (MAX100)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findTableRows: async (teamname: string, projectOrder: number, tableNumber: number, page?: number, perPage?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'teamname' is not null or undefined
            assertParamExists('findTableRows', 'teamname', teamname)
            // verify required parameter 'projectOrder' is not null or undefined
            assertParamExists('findTableRows', 'projectOrder', projectOrder)
            // verify required parameter 'tableNumber' is not null or undefined
            assertParamExists('findTableRows', 'tableNumber', tableNumber)
            const localVarPath = `/teams/{teamname}/projs/{project_order}/tables/{table_number}/rows`
                .replace(`{${"teamname"}}`, encodeURIComponent(String(teamname)))
                .replace(`{${"project_order"}}`, encodeURIComponent(String(projectOrder)))
                .replace(`{${"table_number"}}`, encodeURIComponent(String(tableNumber)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (perPage !== undefined) {
                localVarQueryParameter['per_page'] = perPage;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * メンションリストを取得します。
         * @summary メンションリスト取得
         * @param {string} teamname チーム名
         * @param {number} projectOrder プロジェクトオーダー
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findTeamProjectMentions: async (teamname: string, projectOrder: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'teamname' is not null or undefined
            assertParamExists('findTeamProjectMentions', 'teamname', teamname)
            // verify required parameter 'projectOrder' is not null or undefined
            assertParamExists('findTeamProjectMentions', 'projectOrder', projectOrder)
            const localVarPath = `/teams/{teamname}/projs/{project_order}/mentions`
                .replace(`{${"teamname"}}`, encodeURIComponent(String(teamname)))
                .replace(`{${"project_order"}}`, encodeURIComponent(String(projectOrder)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * プロジェクト設定を取得します。
         * @summary プロジェクト設定の取得
         * @param {string} teamname チーム名
         * @param {number} projectOrder プロジェクトオーダー
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findTeamProjectSettings: async (teamname: string, projectOrder: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'teamname' is not null or undefined
            assertParamExists('findTeamProjectSettings', 'teamname', teamname)
            // verify required parameter 'projectOrder' is not null or undefined
            assertParamExists('findTeamProjectSettings', 'projectOrder', projectOrder)
            const localVarPath = `/teams/{teamname}/projs/{project_order}/settings`
                .replace(`{${"teamname"}}`, encodeURIComponent(String(teamname)))
                .replace(`{${"project_order"}}`, encodeURIComponent(String(projectOrder)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Googleカレンダリストを取得します。
         * @summary Googleカレンダリストの取得
         * @param {string} teamname チーム名
         * @param {number} projectOrder プロジェクトオーダー
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findTeamProjectSettingsGcalList: async (teamname: string, projectOrder: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'teamname' is not null or undefined
            assertParamExists('findTeamProjectSettingsGcalList', 'teamname', teamname)
            // verify required parameter 'projectOrder' is not null or undefined
            assertParamExists('findTeamProjectSettingsGcalList', 'projectOrder', projectOrder)
            const localVarPath = `/teams/{teamname}/projs/{project_order}/settings/gcallist`
                .replace(`{${"teamname"}}`, encodeURIComponent(String(teamname)))
                .replace(`{${"project_order"}}`, encodeURIComponent(String(projectOrder)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 作業時間リストを取得します。
         * @summary 作業時間リスト取得
         * @param {string} teamname チーム名
         * @param {number} projectOrder プロジェクトオーダー
         * @param {number} [year] 年
         * @param {number} [month] 月
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findTeamProjectTimeEntries: async (teamname: string, projectOrder: number, year?: number, month?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'teamname' is not null or undefined
            assertParamExists('findTeamProjectTimeEntries', 'teamname', teamname)
            // verify required parameter 'projectOrder' is not null or undefined
            assertParamExists('findTeamProjectTimeEntries', 'projectOrder', projectOrder)
            const localVarPath = `/teams/{teamname}/projs/{project_order}/timeentries`
                .replace(`{${"teamname"}}`, encodeURIComponent(String(teamname)))
                .replace(`{${"project_order"}}`, encodeURIComponent(String(projectOrder)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (year !== undefined) {
                localVarQueryParameter['year'] = year;
            }

            if (month !== undefined) {
                localVarQueryParameter['month'] = month;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 作業時間リストを取得します。
         * @summary 作業時間リスト取得
         * @param {string} teamname チーム名
         * @param {number} projectOrder プロジェクトオーダー
         * @param {number} taskId タスクID
         * @param {number} [year] 年
         * @param {number} [month] 月
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findTeamProjectTimeEntriesTask: async (teamname: string, projectOrder: number, taskId: number, year?: number, month?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'teamname' is not null or undefined
            assertParamExists('findTeamProjectTimeEntriesTask', 'teamname', teamname)
            // verify required parameter 'projectOrder' is not null or undefined
            assertParamExists('findTeamProjectTimeEntriesTask', 'projectOrder', projectOrder)
            // verify required parameter 'taskId' is not null or undefined
            assertParamExists('findTeamProjectTimeEntriesTask', 'taskId', taskId)
            const localVarPath = `/teams/{teamname}/projs/{project_order}/timeentries/{task_id}`
                .replace(`{${"teamname"}}`, encodeURIComponent(String(teamname)))
                .replace(`{${"project_order"}}`, encodeURIComponent(String(projectOrder)))
                .replace(`{${"task_id"}}`, encodeURIComponent(String(taskId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (year !== undefined) {
                localVarQueryParameter['year'] = year;
            }

            if (month !== undefined) {
                localVarQueryParameter['month'] = month;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * チーム>プロジェクトリストを取得します。
         * @summary チーム>プロジェクトリストの取得
         * @param {string} teamname チーム名
         * @param {number} [page] ページ番号
         * @param {number} [perPage] 1ページあたりの要素数 (MAX100)
         * @param {string} [status] ステータス条件
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findTeamProjects: async (teamname: string, page?: number, perPage?: number, status?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'teamname' is not null or undefined
            assertParamExists('findTeamProjects', 'teamname', teamname)
            const localVarPath = `/teams/{teamname}/projs`
                .replace(`{${"teamname"}}`, encodeURIComponent(String(teamname)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (perPage !== undefined) {
                localVarQueryParameter['per_page'] = perPage;
            }

            if (status !== undefined) {
                localVarQueryParameter['status'] = status;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * プロジェクト情報を取得します。
         * @summary プロジェクト情報取得
         * @param {string} projectIdentifier プロジェクト識別子
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProject: async (projectIdentifier: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectIdentifier' is not null or undefined
            assertParamExists('getProject', 'projectIdentifier', projectIdentifier)
            const localVarPath = `/projs/{project_identifier}`
                .replace(`{${"project_identifier"}}`, encodeURIComponent(String(projectIdentifier)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 自分自身のタスクを取得します。
         * @summary タスクの取得
         * @param {string} projectIdentifier プロジェクト識別子
         * @param {number} taskId タスクID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProjectTask: async (projectIdentifier: string, taskId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectIdentifier' is not null or undefined
            assertParamExists('getProjectTask', 'projectIdentifier', projectIdentifier)
            // verify required parameter 'taskId' is not null or undefined
            assertParamExists('getProjectTask', 'taskId', taskId)
            const localVarPath = `/projs/{project_identifier}/tasks/{task_id}`
                .replace(`{${"project_identifier"}}`, encodeURIComponent(String(projectIdentifier)))
                .replace(`{${"task_id"}}`, encodeURIComponent(String(taskId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * プロジェクト情報を取得します。
         * @summary プロジェクトの取得
         * @param {string} teamname チーム名
         * @param {number} projectOrder プロジェクトオーダー
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTeamProject: async (teamname: string, projectOrder: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'teamname' is not null or undefined
            assertParamExists('getTeamProject', 'teamname', teamname)
            // verify required parameter 'projectOrder' is not null or undefined
            assertParamExists('getTeamProject', 'projectOrder', projectOrder)
            const localVarPath = `/teams/{teamname}/projs/{project_order}`
                .replace(`{${"teamname"}}`, encodeURIComponent(String(teamname)))
                .replace(`{${"project_order"}}`, encodeURIComponent(String(projectOrder)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * プロジェクトをお気に入り設定します。
         * @summary プロジェクトお気に入り設定
         * @param {string} projectIdentifier プロジェクト識別子
         * @param {ProjectFav} projectFav プロジェクトお気に入り設定情報
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setProjectFav: async (projectIdentifier: string, projectFav: ProjectFav, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectIdentifier' is not null or undefined
            assertParamExists('setProjectFav', 'projectIdentifier', projectIdentifier)
            // verify required parameter 'projectFav' is not null or undefined
            assertParamExists('setProjectFav', 'projectFav', projectFav)
            const localVarPath = `/projs/{project_identifier}/fav`
                .replace(`{${"project_identifier"}}`, encodeURIComponent(String(projectIdentifier)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(projectFav, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 自分自身のタスクを更新します。
         * @summary タスク更新
         * @param {string} projectIdentifier プロジェクト識別子
         * @param {number} taskId タスクID
         * @param {TaskRequest} taskRequest タスク情報
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setProjectTask: async (projectIdentifier: string, taskId: number, taskRequest: TaskRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectIdentifier' is not null or undefined
            assertParamExists('setProjectTask', 'projectIdentifier', projectIdentifier)
            // verify required parameter 'taskId' is not null or undefined
            assertParamExists('setProjectTask', 'taskId', taskId)
            // verify required parameter 'taskRequest' is not null or undefined
            assertParamExists('setProjectTask', 'taskRequest', taskRequest)
            const localVarPath = `/projs/{project_identifier}/tasks/{task_id}`
                .replace(`{${"project_identifier"}}`, encodeURIComponent(String(projectIdentifier)))
                .replace(`{${"task_id"}}`, encodeURIComponent(String(taskId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(taskRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * プロジェクトのテーブルを更新します。
         * @summary テーブル更新
         * @param {string} teamname チーム名
         * @param {number} projectOrder プロジェクトオーダー
         * @param {number} tableNumber テーブル番号
         * @param {TableDefReq} tableDefReq テーブル情報
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setTableDef: async (teamname: string, projectOrder: number, tableNumber: number, tableDefReq: TableDefReq, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'teamname' is not null or undefined
            assertParamExists('setTableDef', 'teamname', teamname)
            // verify required parameter 'projectOrder' is not null or undefined
            assertParamExists('setTableDef', 'projectOrder', projectOrder)
            // verify required parameter 'tableNumber' is not null or undefined
            assertParamExists('setTableDef', 'tableNumber', tableNumber)
            // verify required parameter 'tableDefReq' is not null or undefined
            assertParamExists('setTableDef', 'tableDefReq', tableDefReq)
            const localVarPath = `/teams/{teamname}/projs/{project_order}/tabledefs/{table_number}`
                .replace(`{${"teamname"}}`, encodeURIComponent(String(teamname)))
                .replace(`{${"project_order"}}`, encodeURIComponent(String(projectOrder)))
                .replace(`{${"table_number"}}`, encodeURIComponent(String(tableNumber)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(tableDefReq, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * レコードを更新します。
         * @summary レコード更新
         * @param {string} teamname チーム名
         * @param {number} projectOrder プロジェクトオーダー
         * @param {number} tableNumber テーブル番号
         * @param {string} tableRowKey レコードキー
         * @param {TableRowReq} tableRowReq レコード情報
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setTableRow: async (teamname: string, projectOrder: number, tableNumber: number, tableRowKey: string, tableRowReq: TableRowReq, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'teamname' is not null or undefined
            assertParamExists('setTableRow', 'teamname', teamname)
            // verify required parameter 'projectOrder' is not null or undefined
            assertParamExists('setTableRow', 'projectOrder', projectOrder)
            // verify required parameter 'tableNumber' is not null or undefined
            assertParamExists('setTableRow', 'tableNumber', tableNumber)
            // verify required parameter 'tableRowKey' is not null or undefined
            assertParamExists('setTableRow', 'tableRowKey', tableRowKey)
            // verify required parameter 'tableRowReq' is not null or undefined
            assertParamExists('setTableRow', 'tableRowReq', tableRowReq)
            const localVarPath = `/teams/{teamname}/projs/{project_order}/tables/{table_number}/rows/{table_key}`
                .replace(`{${"teamname"}}`, encodeURIComponent(String(teamname)))
                .replace(`{${"project_order"}}`, encodeURIComponent(String(projectOrder)))
                .replace(`{${"table_number"}}`, encodeURIComponent(String(tableNumber)))
                .replace(`{${"table_row_key"}}`, encodeURIComponent(String(tableRowKey)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(tableRowReq, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * チーム>プロジェクト情報を更新します。
         * @summary チーム>プロジェクト情報の更新
         * @param {string} teamname チーム名
         * @param {number} projectOrder プロジェクトオーダー
         * @param {TeamProjReq} teamProjReq チーム&gt;プロジェクト情報
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setTeamProject: async (teamname: string, projectOrder: number, teamProjReq: TeamProjReq, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'teamname' is not null or undefined
            assertParamExists('setTeamProject', 'teamname', teamname)
            // verify required parameter 'projectOrder' is not null or undefined
            assertParamExists('setTeamProject', 'projectOrder', projectOrder)
            // verify required parameter 'teamProjReq' is not null or undefined
            assertParamExists('setTeamProject', 'teamProjReq', teamProjReq)
            const localVarPath = `/teams/{teamname}/projs/{project_order}`
                .replace(`{${"teamname"}}`, encodeURIComponent(String(teamname)))
                .replace(`{${"project_order"}}`, encodeURIComponent(String(projectOrder)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(teamProjReq, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * プロジェクト完了状態を更新します。
         * @summary プロジェクト完了状態の更新
         * @param {string} teamname チーム名
         * @param {number} projectOrder プロジェクトオーダー
         * @param {ProjectComplete} projectComplete チーム&gt;プロジェクト情報
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setTeamProjectComplete: async (teamname: string, projectOrder: number, projectComplete: ProjectComplete, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'teamname' is not null or undefined
            assertParamExists('setTeamProjectComplete', 'teamname', teamname)
            // verify required parameter 'projectOrder' is not null or undefined
            assertParamExists('setTeamProjectComplete', 'projectOrder', projectOrder)
            // verify required parameter 'projectComplete' is not null or undefined
            assertParamExists('setTeamProjectComplete', 'projectComplete', projectComplete)
            const localVarPath = `/teams/{teamname}/projs/{project_order}/complete`
                .replace(`{${"teamname"}}`, encodeURIComponent(String(teamname)))
                .replace(`{${"project_order"}}`, encodeURIComponent(String(projectOrder)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(projectComplete, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * プロジェクトをお気に入り設定します。
         * @summary プロジェクトお気に入り設定
         * @param {string} teamname チーム名
         * @param {number} projectOrder プロジェクトオーダー
         * @param {ProjectFav} projectFav プロジェクトお気に入り設定情報
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setTeamProjectFav: async (teamname: string, projectOrder: number, projectFav: ProjectFav, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'teamname' is not null or undefined
            assertParamExists('setTeamProjectFav', 'teamname', teamname)
            // verify required parameter 'projectOrder' is not null or undefined
            assertParamExists('setTeamProjectFav', 'projectOrder', projectOrder)
            // verify required parameter 'projectFav' is not null or undefined
            assertParamExists('setTeamProjectFav', 'projectFav', projectFav)
            const localVarPath = `/teams/{teamname}/projs/{project_order}/fav`
                .replace(`{${"teamname"}}`, encodeURIComponent(String(teamname)))
                .replace(`{${"project_order"}}`, encodeURIComponent(String(projectOrder)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(projectFav, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * プロジェクト設定を更新します。
         * @summary プロジェクト設定の更新
         * @param {string} teamname チーム名
         * @param {number} projectOrder プロジェクトオーダー
         * @param {ProjectSettingsReq} projectSettingsReq チーム&gt;プロジェクト情報
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setTeamProjectSettings: async (teamname: string, projectOrder: number, projectSettingsReq: ProjectSettingsReq, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'teamname' is not null or undefined
            assertParamExists('setTeamProjectSettings', 'teamname', teamname)
            // verify required parameter 'projectOrder' is not null or undefined
            assertParamExists('setTeamProjectSettings', 'projectOrder', projectOrder)
            // verify required parameter 'projectSettingsReq' is not null or undefined
            assertParamExists('setTeamProjectSettings', 'projectSettingsReq', projectSettingsReq)
            const localVarPath = `/teams/{teamname}/projs/{project_order}/settings`
                .replace(`{${"teamname"}}`, encodeURIComponent(String(teamname)))
                .replace(`{${"project_order"}}`, encodeURIComponent(String(projectOrder)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(projectSettingsReq, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * テーブルCSVをアップロードします。
         * @summary テーブルCSVアップロード
         * @param {string} teamname チーム名
         * @param {number} projectOrder プロジェクトオーダー
         * @param {number} tableNumber テーブル番号
         * @param {TableRowsCsvReq} tableRowsCsvReq レコード情報
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadTableCsv: async (teamname: string, projectOrder: number, tableNumber: number, tableRowsCsvReq: TableRowsCsvReq, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'teamname' is not null or undefined
            assertParamExists('uploadTableCsv', 'teamname', teamname)
            // verify required parameter 'projectOrder' is not null or undefined
            assertParamExists('uploadTableCsv', 'projectOrder', projectOrder)
            // verify required parameter 'tableNumber' is not null or undefined
            assertParamExists('uploadTableCsv', 'tableNumber', tableNumber)
            // verify required parameter 'tableRowsCsvReq' is not null or undefined
            assertParamExists('uploadTableCsv', 'tableRowsCsvReq', tableRowsCsvReq)
            const localVarPath = `/teams/{teamname}/projs/{project_order}/tables/{table_number}/csv`
                .replace(`{${"teamname"}}`, encodeURIComponent(String(teamname)))
                .replace(`{${"project_order"}}`, encodeURIComponent(String(projectOrder)))
                .replace(`{${"table_number"}}`, encodeURIComponent(String(tableNumber)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'text/csv';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(tableRowsCsvReq, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * テーブルCSVをアップロード確定します。
         * @summary テーブルCSVアップロード確定
         * @param {string} teamname チーム名
         * @param {number} projectOrder プロジェクトオーダー
         * @param {number} tableNumber テーブル番号
         * @param {number} historyNumber テーブル更新番号
         * @param {TableRowsCsvHistoryReq} tableRowsCsvHistoryReq レコード情報
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadTableCsvDone: async (teamname: string, projectOrder: number, tableNumber: number, historyNumber: number, tableRowsCsvHistoryReq: TableRowsCsvHistoryReq, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'teamname' is not null or undefined
            assertParamExists('uploadTableCsvDone', 'teamname', teamname)
            // verify required parameter 'projectOrder' is not null or undefined
            assertParamExists('uploadTableCsvDone', 'projectOrder', projectOrder)
            // verify required parameter 'tableNumber' is not null or undefined
            assertParamExists('uploadTableCsvDone', 'tableNumber', tableNumber)
            // verify required parameter 'historyNumber' is not null or undefined
            assertParamExists('uploadTableCsvDone', 'historyNumber', historyNumber)
            // verify required parameter 'tableRowsCsvHistoryReq' is not null or undefined
            assertParamExists('uploadTableCsvDone', 'tableRowsCsvHistoryReq', tableRowsCsvHistoryReq)
            const localVarPath = `/teams/{teamname}/projs/{project_order}/tables/{table_number}/csv/{history_number}`
                .replace(`{${"teamname"}}`, encodeURIComponent(String(teamname)))
                .replace(`{${"project_order"}}`, encodeURIComponent(String(projectOrder)))
                .replace(`{${"table_number"}}`, encodeURIComponent(String(tableNumber)))
                .replace(`{${"history_number"}}`, encodeURIComponent(String(historyNumber)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(tableRowsCsvHistoryReq, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ProjApi - functional programming interface
 * @export
 */
export const ProjApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ProjApiAxiosParamCreator(configuration)
    return {
        /**
         * プロジェクトのタスクを追加します。
         * @summary タスク追加
         * @param {string} projectIdentifier プロジェクト識別子
         * @param {TaskRequest} taskRequest タスク情報
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addProjectTask(projectIdentifier: string, taskRequest: TaskRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Task>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addProjectTask(projectIdentifier, taskRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * プロジェクトのテーブルを追加します。
         * @summary テーブル追加
         * @param {string} teamname チーム名
         * @param {number} projectOrder プロジェクトオーダー
         * @param {TableDefReq} tableDefReq テーブル情報
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addTableDef(teamname: string, projectOrder: number, tableDefReq: TableDefReq, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TableDefResp>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addTableDef(teamname, projectOrder, tableDefReq, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * レコードを追加します。
         * @summary レコード追加
         * @param {string} teamname チーム名
         * @param {number} projectOrder プロジェクトオーダー
         * @param {number} tableNumber テーブル番号
         * @param {TableRowReq} tableRowReq レコード情報
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addTableRow(teamname: string, projectOrder: number, tableNumber: number, tableRowReq: TableRowReq, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TableRowResp>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addTableRow(teamname, projectOrder, tableNumber, tableRowReq, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 新しいチーム>プロジェクトを追加します。
         * @summary 新しいチーム>プロジェクトを追加
         * @param {string} teamname チーム名
         * @param {TeamProjReq} teamProjReq チーム名
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addTeamProject(teamname: string, teamProjReq: TeamProjReq, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addTeamProject(teamname, teamProjReq, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * プロジェクトのテーブルを削除します。
         * @summary テーブル削除
         * @param {string} teamname チーム名
         * @param {number} projectOrder プロジェクトオーダー
         * @param {number} tableNumber テーブル番号
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteTableDef(teamname: string, projectOrder: number, tableNumber: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteTableDef(teamname, projectOrder, tableNumber, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * レコードを削除します。
         * @summary レコード削除
         * @param {string} teamname チーム名
         * @param {number} projectOrder プロジェクトオーダー
         * @param {number} tableNumber テーブル番号
         * @param {string} tableRowKey レコードキー
         * @param {TableRowReq} tableRowReq レコード情報
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteTableRow(teamname: string, projectOrder: number, tableNumber: number, tableRowKey: string, tableRowReq: TableRowReq, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteTableRow(teamname, projectOrder, tableNumber, tableRowKey, tableRowReq, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 指定されたチーム>プロジェクトを削除します。
         * @summary チーム>プロジェクトの削除
         * @param {string} teamname チーム名
         * @param {number} projectOrder プロジェクトオーダー
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteTeamProject(teamname: string, projectOrder: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteTeamProject(teamname, projectOrder, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * プロジェクトメンバーを削除します。
         * @summary プロジェクトメンバーの削除
         * @param {string} teamname チーム名
         * @param {number} projectOrder プロジェクトオーダー
         * @param {string} username ユーザ名
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteTeamProjectMembers(teamname: string, projectOrder: number, username: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteTeamProjectMembers(teamname, projectOrder, username, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * テーブルCSVを取得します。
         * @summary テーブルCSV取得
         * @param {string} teamname チーム名
         * @param {number} projectOrder プロジェクトオーダー
         * @param {number} tableNumber テーブル番号
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async downloadTableCsv(teamname: string, projectOrder: number, tableNumber: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TableRowsCsvResp>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.downloadTableCsv(teamname, projectOrder, tableNumber, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * フィールド定義リストを取得します。
         * @summary フィールド定義リスト取得
         * @param {string} teamname チーム名
         * @param {number} projectOrder プロジェクトオーダー
         * @param {number} tableNumber テーブル番号
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findFieldDefs(teamname: string, projectOrder: number, tableNumber: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FieldsResp>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findFieldDefs(teamname, projectOrder, tableNumber, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * プロジェクトのタスクのリストを取得します。
         * @summary タスクリスト取得
         * @param {string} projectIdentifier プロジェクト識別子
         * @param {number} [page] ページ番号
         * @param {number} [perPage] 1ページあたりの要素数 (MAX100)
         * @param {string} [status] ステータス条件
         * @param {number} [categoryId] カテゴリーID
         * @param {string} [search] 検索キーワード
         * @param {string} [sort] ソート条件
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findProjectTasks(projectIdentifier: string, page?: number, perPage?: number, status?: string, categoryId?: number, search?: string, sort?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Tasks>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findProjectTasks(projectIdentifier, page, perPage, status, categoryId, search, sort, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * プロジェクトリストを取得します。
         * @summary プロジェクトリストの取得
         * @param {number} [page] ページ番号
         * @param {number} [perPage] 1ページあたりの要素数 (MAX100)
         * @param {string} [status] ステータス条件
         * @param {boolean} [fav] お気に入り
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findProjects(page?: number, perPage?: number, status?: string, fav?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Projects>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findProjects(page, perPage, status, fav, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * プロジェクトのテーブルリストを取得します。
         * @summary プロジェクトのテーブルリスト取得
         * @param {string} teamname チーム名
         * @param {number} projectOrder プロジェクトオーダー
         * @param {number} [page] ページ番号
         * @param {number} [perPage] 1ページあたりの要素数 (MAX100)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findTableDef(teamname: string, projectOrder: number, page?: number, perPage?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TableDefsResp>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findTableDef(teamname, projectOrder, page, perPage, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * レコードリストを取得します。
         * @summary レコードリスト取得
         * @param {string} teamname チーム名
         * @param {number} projectOrder プロジェクトオーダー
         * @param {number} tableNumber テーブル番号
         * @param {number} [page] ページ番号
         * @param {number} [perPage] 1ページあたりの要素数 (MAX100)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findTableRows(teamname: string, projectOrder: number, tableNumber: number, page?: number, perPage?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TableRowsResp>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findTableRows(teamname, projectOrder, tableNumber, page, perPage, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * メンションリストを取得します。
         * @summary メンションリスト取得
         * @param {string} teamname チーム名
         * @param {number} projectOrder プロジェクトオーダー
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findTeamProjectMentions(teamname: string, projectOrder: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Mentions>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findTeamProjectMentions(teamname, projectOrder, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * プロジェクト設定を取得します。
         * @summary プロジェクト設定の取得
         * @param {string} teamname チーム名
         * @param {number} projectOrder プロジェクトオーダー
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findTeamProjectSettings(teamname: string, projectOrder: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProjectSettings>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findTeamProjectSettings(teamname, projectOrder, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Googleカレンダリストを取得します。
         * @summary Googleカレンダリストの取得
         * @param {string} teamname チーム名
         * @param {number} projectOrder プロジェクトオーダー
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findTeamProjectSettingsGcalList(teamname: string, projectOrder: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProjectSettingGcallist>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findTeamProjectSettingsGcalList(teamname, projectOrder, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 作業時間リストを取得します。
         * @summary 作業時間リスト取得
         * @param {string} teamname チーム名
         * @param {number} projectOrder プロジェクトオーダー
         * @param {number} [year] 年
         * @param {number} [month] 月
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findTeamProjectTimeEntries(teamname: string, projectOrder: number, year?: number, month?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProjectTimeentries>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findTeamProjectTimeEntries(teamname, projectOrder, year, month, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 作業時間リストを取得します。
         * @summary 作業時間リスト取得
         * @param {string} teamname チーム名
         * @param {number} projectOrder プロジェクトオーダー
         * @param {number} taskId タスクID
         * @param {number} [year] 年
         * @param {number} [month] 月
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findTeamProjectTimeEntriesTask(teamname: string, projectOrder: number, taskId: number, year?: number, month?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProjectTimeentries>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findTeamProjectTimeEntriesTask(teamname, projectOrder, taskId, year, month, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * チーム>プロジェクトリストを取得します。
         * @summary チーム>プロジェクトリストの取得
         * @param {string} teamname チーム名
         * @param {number} [page] ページ番号
         * @param {number} [perPage] 1ページあたりの要素数 (MAX100)
         * @param {string} [status] ステータス条件
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findTeamProjects(teamname: string, page?: number, perPage?: number, status?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Projects>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findTeamProjects(teamname, page, perPage, status, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * プロジェクト情報を取得します。
         * @summary プロジェクト情報取得
         * @param {string} projectIdentifier プロジェクト識別子
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getProject(projectIdentifier: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Project>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getProject(projectIdentifier, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 自分自身のタスクを取得します。
         * @summary タスクの取得
         * @param {string} projectIdentifier プロジェクト識別子
         * @param {number} taskId タスクID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getProjectTask(projectIdentifier: string, taskId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Task>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getProjectTask(projectIdentifier, taskId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * プロジェクト情報を取得します。
         * @summary プロジェクトの取得
         * @param {string} teamname チーム名
         * @param {number} projectOrder プロジェクトオーダー
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTeamProject(teamname: string, projectOrder: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Project>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTeamProject(teamname, projectOrder, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * プロジェクトをお気に入り設定します。
         * @summary プロジェクトお気に入り設定
         * @param {string} projectIdentifier プロジェクト識別子
         * @param {ProjectFav} projectFav プロジェクトお気に入り設定情報
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async setProjectFav(projectIdentifier: string, projectFav: ProjectFav, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.setProjectFav(projectIdentifier, projectFav, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 自分自身のタスクを更新します。
         * @summary タスク更新
         * @param {string} projectIdentifier プロジェクト識別子
         * @param {number} taskId タスクID
         * @param {TaskRequest} taskRequest タスク情報
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async setProjectTask(projectIdentifier: string, taskId: number, taskRequest: TaskRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Task>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.setProjectTask(projectIdentifier, taskId, taskRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * プロジェクトのテーブルを更新します。
         * @summary テーブル更新
         * @param {string} teamname チーム名
         * @param {number} projectOrder プロジェクトオーダー
         * @param {number} tableNumber テーブル番号
         * @param {TableDefReq} tableDefReq テーブル情報
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async setTableDef(teamname: string, projectOrder: number, tableNumber: number, tableDefReq: TableDefReq, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TableDefResp>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.setTableDef(teamname, projectOrder, tableNumber, tableDefReq, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * レコードを更新します。
         * @summary レコード更新
         * @param {string} teamname チーム名
         * @param {number} projectOrder プロジェクトオーダー
         * @param {number} tableNumber テーブル番号
         * @param {string} tableRowKey レコードキー
         * @param {TableRowReq} tableRowReq レコード情報
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async setTableRow(teamname: string, projectOrder: number, tableNumber: number, tableRowKey: string, tableRowReq: TableRowReq, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TableRowResp>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.setTableRow(teamname, projectOrder, tableNumber, tableRowKey, tableRowReq, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * チーム>プロジェクト情報を更新します。
         * @summary チーム>プロジェクト情報の更新
         * @param {string} teamname チーム名
         * @param {number} projectOrder プロジェクトオーダー
         * @param {TeamProjReq} teamProjReq チーム&gt;プロジェクト情報
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async setTeamProject(teamname: string, projectOrder: number, teamProjReq: TeamProjReq, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.setTeamProject(teamname, projectOrder, teamProjReq, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * プロジェクト完了状態を更新します。
         * @summary プロジェクト完了状態の更新
         * @param {string} teamname チーム名
         * @param {number} projectOrder プロジェクトオーダー
         * @param {ProjectComplete} projectComplete チーム&gt;プロジェクト情報
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async setTeamProjectComplete(teamname: string, projectOrder: number, projectComplete: ProjectComplete, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.setTeamProjectComplete(teamname, projectOrder, projectComplete, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * プロジェクトをお気に入り設定します。
         * @summary プロジェクトお気に入り設定
         * @param {string} teamname チーム名
         * @param {number} projectOrder プロジェクトオーダー
         * @param {ProjectFav} projectFav プロジェクトお気に入り設定情報
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async setTeamProjectFav(teamname: string, projectOrder: number, projectFav: ProjectFav, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.setTeamProjectFav(teamname, projectOrder, projectFav, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * プロジェクト設定を更新します。
         * @summary プロジェクト設定の更新
         * @param {string} teamname チーム名
         * @param {number} projectOrder プロジェクトオーダー
         * @param {ProjectSettingsReq} projectSettingsReq チーム&gt;プロジェクト情報
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async setTeamProjectSettings(teamname: string, projectOrder: number, projectSettingsReq: ProjectSettingsReq, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.setTeamProjectSettings(teamname, projectOrder, projectSettingsReq, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * テーブルCSVをアップロードします。
         * @summary テーブルCSVアップロード
         * @param {string} teamname チーム名
         * @param {number} projectOrder プロジェクトオーダー
         * @param {number} tableNumber テーブル番号
         * @param {TableRowsCsvReq} tableRowsCsvReq レコード情報
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async uploadTableCsv(teamname: string, projectOrder: number, tableNumber: number, tableRowsCsvReq: TableRowsCsvReq, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TableRowsCsvUploadResp>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.uploadTableCsv(teamname, projectOrder, tableNumber, tableRowsCsvReq, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * テーブルCSVをアップロード確定します。
         * @summary テーブルCSVアップロード確定
         * @param {string} teamname チーム名
         * @param {number} projectOrder プロジェクトオーダー
         * @param {number} tableNumber テーブル番号
         * @param {number} historyNumber テーブル更新番号
         * @param {TableRowsCsvHistoryReq} tableRowsCsvHistoryReq レコード情報
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async uploadTableCsvDone(teamname: string, projectOrder: number, tableNumber: number, historyNumber: number, tableRowsCsvHistoryReq: TableRowsCsvHistoryReq, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TableRowsCsvHistoryResp>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.uploadTableCsvDone(teamname, projectOrder, tableNumber, historyNumber, tableRowsCsvHistoryReq, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ProjApi - factory interface
 * @export
 */
export const ProjApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ProjApiFp(configuration)
    return {
        /**
         * プロジェクトのタスクを追加します。
         * @summary タスク追加
         * @param {string} projectIdentifier プロジェクト識別子
         * @param {TaskRequest} taskRequest タスク情報
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addProjectTask(projectIdentifier: string, taskRequest: TaskRequest, options?: any): AxiosPromise<Task> {
            return localVarFp.addProjectTask(projectIdentifier, taskRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * プロジェクトのテーブルを追加します。
         * @summary テーブル追加
         * @param {string} teamname チーム名
         * @param {number} projectOrder プロジェクトオーダー
         * @param {TableDefReq} tableDefReq テーブル情報
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addTableDef(teamname: string, projectOrder: number, tableDefReq: TableDefReq, options?: any): AxiosPromise<TableDefResp> {
            return localVarFp.addTableDef(teamname, projectOrder, tableDefReq, options).then((request) => request(axios, basePath));
        },
        /**
         * レコードを追加します。
         * @summary レコード追加
         * @param {string} teamname チーム名
         * @param {number} projectOrder プロジェクトオーダー
         * @param {number} tableNumber テーブル番号
         * @param {TableRowReq} tableRowReq レコード情報
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addTableRow(teamname: string, projectOrder: number, tableNumber: number, tableRowReq: TableRowReq, options?: any): AxiosPromise<TableRowResp> {
            return localVarFp.addTableRow(teamname, projectOrder, tableNumber, tableRowReq, options).then((request) => request(axios, basePath));
        },
        /**
         * 新しいチーム>プロジェクトを追加します。
         * @summary 新しいチーム>プロジェクトを追加
         * @param {string} teamname チーム名
         * @param {TeamProjReq} teamProjReq チーム名
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addTeamProject(teamname: string, teamProjReq: TeamProjReq, options?: any): AxiosPromise<void> {
            return localVarFp.addTeamProject(teamname, teamProjReq, options).then((request) => request(axios, basePath));
        },
        /**
         * プロジェクトのテーブルを削除します。
         * @summary テーブル削除
         * @param {string} teamname チーム名
         * @param {number} projectOrder プロジェクトオーダー
         * @param {number} tableNumber テーブル番号
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteTableDef(teamname: string, projectOrder: number, tableNumber: number, options?: any): AxiosPromise<void> {
            return localVarFp.deleteTableDef(teamname, projectOrder, tableNumber, options).then((request) => request(axios, basePath));
        },
        /**
         * レコードを削除します。
         * @summary レコード削除
         * @param {string} teamname チーム名
         * @param {number} projectOrder プロジェクトオーダー
         * @param {number} tableNumber テーブル番号
         * @param {string} tableRowKey レコードキー
         * @param {TableRowReq} tableRowReq レコード情報
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteTableRow(teamname: string, projectOrder: number, tableNumber: number, tableRowKey: string, tableRowReq: TableRowReq, options?: any): AxiosPromise<void> {
            return localVarFp.deleteTableRow(teamname, projectOrder, tableNumber, tableRowKey, tableRowReq, options).then((request) => request(axios, basePath));
        },
        /**
         * 指定されたチーム>プロジェクトを削除します。
         * @summary チーム>プロジェクトの削除
         * @param {string} teamname チーム名
         * @param {number} projectOrder プロジェクトオーダー
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteTeamProject(teamname: string, projectOrder: number, options?: any): AxiosPromise<void> {
            return localVarFp.deleteTeamProject(teamname, projectOrder, options).then((request) => request(axios, basePath));
        },
        /**
         * プロジェクトメンバーを削除します。
         * @summary プロジェクトメンバーの削除
         * @param {string} teamname チーム名
         * @param {number} projectOrder プロジェクトオーダー
         * @param {string} username ユーザ名
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteTeamProjectMembers(teamname: string, projectOrder: number, username: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteTeamProjectMembers(teamname, projectOrder, username, options).then((request) => request(axios, basePath));
        },
        /**
         * テーブルCSVを取得します。
         * @summary テーブルCSV取得
         * @param {string} teamname チーム名
         * @param {number} projectOrder プロジェクトオーダー
         * @param {number} tableNumber テーブル番号
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadTableCsv(teamname: string, projectOrder: number, tableNumber: number, options?: any): AxiosPromise<TableRowsCsvResp> {
            return localVarFp.downloadTableCsv(teamname, projectOrder, tableNumber, options).then((request) => request(axios, basePath));
        },
        /**
         * フィールド定義リストを取得します。
         * @summary フィールド定義リスト取得
         * @param {string} teamname チーム名
         * @param {number} projectOrder プロジェクトオーダー
         * @param {number} tableNumber テーブル番号
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findFieldDefs(teamname: string, projectOrder: number, tableNumber: number, options?: any): AxiosPromise<FieldsResp> {
            return localVarFp.findFieldDefs(teamname, projectOrder, tableNumber, options).then((request) => request(axios, basePath));
        },
        /**
         * プロジェクトのタスクのリストを取得します。
         * @summary タスクリスト取得
         * @param {string} projectIdentifier プロジェクト識別子
         * @param {number} [page] ページ番号
         * @param {number} [perPage] 1ページあたりの要素数 (MAX100)
         * @param {string} [status] ステータス条件
         * @param {number} [categoryId] カテゴリーID
         * @param {string} [search] 検索キーワード
         * @param {string} [sort] ソート条件
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findProjectTasks(projectIdentifier: string, page?: number, perPage?: number, status?: string, categoryId?: number, search?: string, sort?: string, options?: any): AxiosPromise<Tasks> {
            return localVarFp.findProjectTasks(projectIdentifier, page, perPage, status, categoryId, search, sort, options).then((request) => request(axios, basePath));
        },
        /**
         * プロジェクトリストを取得します。
         * @summary プロジェクトリストの取得
         * @param {number} [page] ページ番号
         * @param {number} [perPage] 1ページあたりの要素数 (MAX100)
         * @param {string} [status] ステータス条件
         * @param {boolean} [fav] お気に入り
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findProjects(page?: number, perPage?: number, status?: string, fav?: boolean, options?: any): AxiosPromise<Projects> {
            return localVarFp.findProjects(page, perPage, status, fav, options).then((request) => request(axios, basePath));
        },
        /**
         * プロジェクトのテーブルリストを取得します。
         * @summary プロジェクトのテーブルリスト取得
         * @param {string} teamname チーム名
         * @param {number} projectOrder プロジェクトオーダー
         * @param {number} [page] ページ番号
         * @param {number} [perPage] 1ページあたりの要素数 (MAX100)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findTableDef(teamname: string, projectOrder: number, page?: number, perPage?: number, options?: any): AxiosPromise<TableDefsResp> {
            return localVarFp.findTableDef(teamname, projectOrder, page, perPage, options).then((request) => request(axios, basePath));
        },
        /**
         * レコードリストを取得します。
         * @summary レコードリスト取得
         * @param {string} teamname チーム名
         * @param {number} projectOrder プロジェクトオーダー
         * @param {number} tableNumber テーブル番号
         * @param {number} [page] ページ番号
         * @param {number} [perPage] 1ページあたりの要素数 (MAX100)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findTableRows(teamname: string, projectOrder: number, tableNumber: number, page?: number, perPage?: number, options?: any): AxiosPromise<TableRowsResp> {
            return localVarFp.findTableRows(teamname, projectOrder, tableNumber, page, perPage, options).then((request) => request(axios, basePath));
        },
        /**
         * メンションリストを取得します。
         * @summary メンションリスト取得
         * @param {string} teamname チーム名
         * @param {number} projectOrder プロジェクトオーダー
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findTeamProjectMentions(teamname: string, projectOrder: number, options?: any): AxiosPromise<Mentions> {
            return localVarFp.findTeamProjectMentions(teamname, projectOrder, options).then((request) => request(axios, basePath));
        },
        /**
         * プロジェクト設定を取得します。
         * @summary プロジェクト設定の取得
         * @param {string} teamname チーム名
         * @param {number} projectOrder プロジェクトオーダー
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findTeamProjectSettings(teamname: string, projectOrder: number, options?: any): AxiosPromise<ProjectSettings> {
            return localVarFp.findTeamProjectSettings(teamname, projectOrder, options).then((request) => request(axios, basePath));
        },
        /**
         * Googleカレンダリストを取得します。
         * @summary Googleカレンダリストの取得
         * @param {string} teamname チーム名
         * @param {number} projectOrder プロジェクトオーダー
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findTeamProjectSettingsGcalList(teamname: string, projectOrder: number, options?: any): AxiosPromise<ProjectSettingGcallist> {
            return localVarFp.findTeamProjectSettingsGcalList(teamname, projectOrder, options).then((request) => request(axios, basePath));
        },
        /**
         * 作業時間リストを取得します。
         * @summary 作業時間リスト取得
         * @param {string} teamname チーム名
         * @param {number} projectOrder プロジェクトオーダー
         * @param {number} [year] 年
         * @param {number} [month] 月
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findTeamProjectTimeEntries(teamname: string, projectOrder: number, year?: number, month?: number, options?: any): AxiosPromise<ProjectTimeentries> {
            return localVarFp.findTeamProjectTimeEntries(teamname, projectOrder, year, month, options).then((request) => request(axios, basePath));
        },
        /**
         * 作業時間リストを取得します。
         * @summary 作業時間リスト取得
         * @param {string} teamname チーム名
         * @param {number} projectOrder プロジェクトオーダー
         * @param {number} taskId タスクID
         * @param {number} [year] 年
         * @param {number} [month] 月
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findTeamProjectTimeEntriesTask(teamname: string, projectOrder: number, taskId: number, year?: number, month?: number, options?: any): AxiosPromise<ProjectTimeentries> {
            return localVarFp.findTeamProjectTimeEntriesTask(teamname, projectOrder, taskId, year, month, options).then((request) => request(axios, basePath));
        },
        /**
         * チーム>プロジェクトリストを取得します。
         * @summary チーム>プロジェクトリストの取得
         * @param {string} teamname チーム名
         * @param {number} [page] ページ番号
         * @param {number} [perPage] 1ページあたりの要素数 (MAX100)
         * @param {string} [status] ステータス条件
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findTeamProjects(teamname: string, page?: number, perPage?: number, status?: string, options?: any): AxiosPromise<Projects> {
            return localVarFp.findTeamProjects(teamname, page, perPage, status, options).then((request) => request(axios, basePath));
        },
        /**
         * プロジェクト情報を取得します。
         * @summary プロジェクト情報取得
         * @param {string} projectIdentifier プロジェクト識別子
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProject(projectIdentifier: string, options?: any): AxiosPromise<Project> {
            return localVarFp.getProject(projectIdentifier, options).then((request) => request(axios, basePath));
        },
        /**
         * 自分自身のタスクを取得します。
         * @summary タスクの取得
         * @param {string} projectIdentifier プロジェクト識別子
         * @param {number} taskId タスクID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProjectTask(projectIdentifier: string, taskId: number, options?: any): AxiosPromise<Task> {
            return localVarFp.getProjectTask(projectIdentifier, taskId, options).then((request) => request(axios, basePath));
        },
        /**
         * プロジェクト情報を取得します。
         * @summary プロジェクトの取得
         * @param {string} teamname チーム名
         * @param {number} projectOrder プロジェクトオーダー
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTeamProject(teamname: string, projectOrder: number, options?: any): AxiosPromise<Project> {
            return localVarFp.getTeamProject(teamname, projectOrder, options).then((request) => request(axios, basePath));
        },
        /**
         * プロジェクトをお気に入り設定します。
         * @summary プロジェクトお気に入り設定
         * @param {string} projectIdentifier プロジェクト識別子
         * @param {ProjectFav} projectFav プロジェクトお気に入り設定情報
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setProjectFav(projectIdentifier: string, projectFav: ProjectFav, options?: any): AxiosPromise<void> {
            return localVarFp.setProjectFav(projectIdentifier, projectFav, options).then((request) => request(axios, basePath));
        },
        /**
         * 自分自身のタスクを更新します。
         * @summary タスク更新
         * @param {string} projectIdentifier プロジェクト識別子
         * @param {number} taskId タスクID
         * @param {TaskRequest} taskRequest タスク情報
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setProjectTask(projectIdentifier: string, taskId: number, taskRequest: TaskRequest, options?: any): AxiosPromise<Task> {
            return localVarFp.setProjectTask(projectIdentifier, taskId, taskRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * プロジェクトのテーブルを更新します。
         * @summary テーブル更新
         * @param {string} teamname チーム名
         * @param {number} projectOrder プロジェクトオーダー
         * @param {number} tableNumber テーブル番号
         * @param {TableDefReq} tableDefReq テーブル情報
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setTableDef(teamname: string, projectOrder: number, tableNumber: number, tableDefReq: TableDefReq, options?: any): AxiosPromise<TableDefResp> {
            return localVarFp.setTableDef(teamname, projectOrder, tableNumber, tableDefReq, options).then((request) => request(axios, basePath));
        },
        /**
         * レコードを更新します。
         * @summary レコード更新
         * @param {string} teamname チーム名
         * @param {number} projectOrder プロジェクトオーダー
         * @param {number} tableNumber テーブル番号
         * @param {string} tableRowKey レコードキー
         * @param {TableRowReq} tableRowReq レコード情報
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setTableRow(teamname: string, projectOrder: number, tableNumber: number, tableRowKey: string, tableRowReq: TableRowReq, options?: any): AxiosPromise<TableRowResp> {
            return localVarFp.setTableRow(teamname, projectOrder, tableNumber, tableRowKey, tableRowReq, options).then((request) => request(axios, basePath));
        },
        /**
         * チーム>プロジェクト情報を更新します。
         * @summary チーム>プロジェクト情報の更新
         * @param {string} teamname チーム名
         * @param {number} projectOrder プロジェクトオーダー
         * @param {TeamProjReq} teamProjReq チーム&gt;プロジェクト情報
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setTeamProject(teamname: string, projectOrder: number, teamProjReq: TeamProjReq, options?: any): AxiosPromise<void> {
            return localVarFp.setTeamProject(teamname, projectOrder, teamProjReq, options).then((request) => request(axios, basePath));
        },
        /**
         * プロジェクト完了状態を更新します。
         * @summary プロジェクト完了状態の更新
         * @param {string} teamname チーム名
         * @param {number} projectOrder プロジェクトオーダー
         * @param {ProjectComplete} projectComplete チーム&gt;プロジェクト情報
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setTeamProjectComplete(teamname: string, projectOrder: number, projectComplete: ProjectComplete, options?: any): AxiosPromise<void> {
            return localVarFp.setTeamProjectComplete(teamname, projectOrder, projectComplete, options).then((request) => request(axios, basePath));
        },
        /**
         * プロジェクトをお気に入り設定します。
         * @summary プロジェクトお気に入り設定
         * @param {string} teamname チーム名
         * @param {number} projectOrder プロジェクトオーダー
         * @param {ProjectFav} projectFav プロジェクトお気に入り設定情報
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setTeamProjectFav(teamname: string, projectOrder: number, projectFav: ProjectFav, options?: any): AxiosPromise<void> {
            return localVarFp.setTeamProjectFav(teamname, projectOrder, projectFav, options).then((request) => request(axios, basePath));
        },
        /**
         * プロジェクト設定を更新します。
         * @summary プロジェクト設定の更新
         * @param {string} teamname チーム名
         * @param {number} projectOrder プロジェクトオーダー
         * @param {ProjectSettingsReq} projectSettingsReq チーム&gt;プロジェクト情報
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setTeamProjectSettings(teamname: string, projectOrder: number, projectSettingsReq: ProjectSettingsReq, options?: any): AxiosPromise<void> {
            return localVarFp.setTeamProjectSettings(teamname, projectOrder, projectSettingsReq, options).then((request) => request(axios, basePath));
        },
        /**
         * テーブルCSVをアップロードします。
         * @summary テーブルCSVアップロード
         * @param {string} teamname チーム名
         * @param {number} projectOrder プロジェクトオーダー
         * @param {number} tableNumber テーブル番号
         * @param {TableRowsCsvReq} tableRowsCsvReq レコード情報
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadTableCsv(teamname: string, projectOrder: number, tableNumber: number, tableRowsCsvReq: TableRowsCsvReq, options?: any): AxiosPromise<TableRowsCsvUploadResp> {
            return localVarFp.uploadTableCsv(teamname, projectOrder, tableNumber, tableRowsCsvReq, options).then((request) => request(axios, basePath));
        },
        /**
         * テーブルCSVをアップロード確定します。
         * @summary テーブルCSVアップロード確定
         * @param {string} teamname チーム名
         * @param {number} projectOrder プロジェクトオーダー
         * @param {number} tableNumber テーブル番号
         * @param {number} historyNumber テーブル更新番号
         * @param {TableRowsCsvHistoryReq} tableRowsCsvHistoryReq レコード情報
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadTableCsvDone(teamname: string, projectOrder: number, tableNumber: number, historyNumber: number, tableRowsCsvHistoryReq: TableRowsCsvHistoryReq, options?: any): AxiosPromise<TableRowsCsvHistoryResp> {
            return localVarFp.uploadTableCsvDone(teamname, projectOrder, tableNumber, historyNumber, tableRowsCsvHistoryReq, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ProjApi - object-oriented interface
 * @export
 * @class ProjApi
 * @extends {BaseAPI}
 */
export class ProjApi extends BaseAPI {
    /**
     * プロジェクトのタスクを追加します。
     * @summary タスク追加
     * @param {string} projectIdentifier プロジェクト識別子
     * @param {TaskRequest} taskRequest タスク情報
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjApi
     */
    public async addProjectTask(projectIdentifier: string, taskRequest: TaskRequest, options?: AxiosRequestConfig) {
        const retry = 2;
        let resp;
        for (let i = 0; i < retry; i++) {
            resp = await ProjApiFp(this.configuration).addProjectTask(projectIdentifier, taskRequest, options).then((request) => request(this.axios, this.basePath)).catch((error) => {
                if (error?.response) {
                    return error.response;
                } else {
                    return { status: 500 };
                }
            });
            if (resp?.status === 401) {
                await refreshIdToken();
                console.debug('Retry addProjectTask ' + i + '/' + (retry - 1));
                continue;
            }
            break;
        }
        return resp;
    }

    /**
     * プロジェクトのテーブルを追加します。
     * @summary テーブル追加
     * @param {string} teamname チーム名
     * @param {number} projectOrder プロジェクトオーダー
     * @param {TableDefReq} tableDefReq テーブル情報
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjApi
     */
    public async addTableDef(teamname: string, projectOrder: number, tableDefReq: TableDefReq, options?: AxiosRequestConfig) {
        const retry = 2;
        let resp;
        for (let i = 0; i < retry; i++) {
            resp = await ProjApiFp(this.configuration).addTableDef(teamname, projectOrder, tableDefReq, options).then((request) => request(this.axios, this.basePath)).catch((error) => {
                if (error?.response) {
                    return error.response;
                } else {
                    return { status: 500 };
                }
            });
            if (resp?.status === 401) {
                await refreshIdToken();
                console.debug('Retry addTableDef ' + i + '/' + (retry - 1));
                continue;
            }
            break;
        }
        return resp;
    }

    /**
     * レコードを追加します。
     * @summary レコード追加
     * @param {string} teamname チーム名
     * @param {number} projectOrder プロジェクトオーダー
     * @param {number} tableNumber テーブル番号
     * @param {TableRowReq} tableRowReq レコード情報
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjApi
     */
    public async addTableRow(teamname: string, projectOrder: number, tableNumber: number, tableRowReq: TableRowReq, options?: AxiosRequestConfig) {
        const retry = 2;
        let resp;
        for (let i = 0; i < retry; i++) {
            resp = await ProjApiFp(this.configuration).addTableRow(teamname, projectOrder, tableNumber, tableRowReq, options).then((request) => request(this.axios, this.basePath)).catch((error) => {
                if (error?.response) {
                    return error.response;
                } else {
                    return { status: 500 };
                }
            });
            if (resp?.status === 401) {
                await refreshIdToken();
                console.debug('Retry addTableRow ' + i + '/' + (retry - 1));
                continue;
            }
            break;
        }
        return resp;
    }

    /**
     * 新しいチーム>プロジェクトを追加します。
     * @summary 新しいチーム>プロジェクトを追加
     * @param {string} teamname チーム名
     * @param {TeamProjReq} teamProjReq チーム名
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjApi
     */
    public async addTeamProject(teamname: string, teamProjReq: TeamProjReq, options?: AxiosRequestConfig) {
        const retry = 2;
        let resp;
        for (let i = 0; i < retry; i++) {
            resp = await ProjApiFp(this.configuration).addTeamProject(teamname, teamProjReq, options).then((request) => request(this.axios, this.basePath)).catch((error) => {
                if (error?.response) {
                    return error.response;
                } else {
                    return { status: 500 };
                }
            });
            if (resp?.status === 401) {
                await refreshIdToken();
                console.debug('Retry addTeamProject ' + i + '/' + (retry - 1));
                continue;
            }
            break;
        }
        return resp;
    }

    /**
     * プロジェクトのテーブルを削除します。
     * @summary テーブル削除
     * @param {string} teamname チーム名
     * @param {number} projectOrder プロジェクトオーダー
     * @param {number} tableNumber テーブル番号
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjApi
     */
    public async deleteTableDef(teamname: string, projectOrder: number, tableNumber: number, options?: AxiosRequestConfig) {
        const retry = 2;
        let resp;
        for (let i = 0; i < retry; i++) {
            resp = await ProjApiFp(this.configuration).deleteTableDef(teamname, projectOrder, tableNumber, options).then((request) => request(this.axios, this.basePath)).catch((error) => {
                if (error?.response) {
                    return error.response;
                } else {
                    return { status: 500 };
                }
            });
            if (resp?.status === 401) {
                await refreshIdToken();
                console.debug('Retry deleteTableDef ' + i + '/' + (retry - 1));
                continue;
            }
            break;
        }
        return resp;
    }

    /**
     * レコードを削除します。
     * @summary レコード削除
     * @param {string} teamname チーム名
     * @param {number} projectOrder プロジェクトオーダー
     * @param {number} tableNumber テーブル番号
     * @param {string} tableRowKey レコードキー
     * @param {TableRowReq} tableRowReq レコード情報
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjApi
     */
    public async deleteTableRow(teamname: string, projectOrder: number, tableNumber: number, tableRowKey: string, tableRowReq: TableRowReq, options?: AxiosRequestConfig) {
        const retry = 2;
        let resp;
        for (let i = 0; i < retry; i++) {
            resp = await ProjApiFp(this.configuration).deleteTableRow(teamname, projectOrder, tableNumber, tableRowKey, tableRowReq, options).then((request) => request(this.axios, this.basePath)).catch((error) => {
                if (error?.response) {
                    return error.response;
                } else {
                    return { status: 500 };
                }
            });
            if (resp?.status === 401) {
                await refreshIdToken();
                console.debug('Retry deleteTableRow ' + i + '/' + (retry - 1));
                continue;
            }
            break;
        }
        return resp;
    }

    /**
     * 指定されたチーム>プロジェクトを削除します。
     * @summary チーム>プロジェクトの削除
     * @param {string} teamname チーム名
     * @param {number} projectOrder プロジェクトオーダー
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjApi
     */
    public async deleteTeamProject(teamname: string, projectOrder: number, options?: AxiosRequestConfig) {
        const retry = 2;
        let resp;
        for (let i = 0; i < retry; i++) {
            resp = await ProjApiFp(this.configuration).deleteTeamProject(teamname, projectOrder, options).then((request) => request(this.axios, this.basePath)).catch((error) => {
                if (error?.response) {
                    return error.response;
                } else {
                    return { status: 500 };
                }
            });
            if (resp?.status === 401) {
                await refreshIdToken();
                console.debug('Retry deleteTeamProject ' + i + '/' + (retry - 1));
                continue;
            }
            break;
        }
        return resp;
    }

    /**
     * プロジェクトメンバーを削除します。
     * @summary プロジェクトメンバーの削除
     * @param {string} teamname チーム名
     * @param {number} projectOrder プロジェクトオーダー
     * @param {string} username ユーザ名
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjApi
     */
    public async deleteTeamProjectMembers(teamname: string, projectOrder: number, username: string, options?: AxiosRequestConfig) {
        const retry = 2;
        let resp;
        for (let i = 0; i < retry; i++) {
            resp = await ProjApiFp(this.configuration).deleteTeamProjectMembers(teamname, projectOrder, username, options).then((request) => request(this.axios, this.basePath)).catch((error) => {
                if (error?.response) {
                    return error.response;
                } else {
                    return { status: 500 };
                }
            });
            if (resp?.status === 401) {
                await refreshIdToken();
                console.debug('Retry deleteTeamProjectMembers ' + i + '/' + (retry - 1));
                continue;
            }
            break;
        }
        return resp;
    }

    /**
     * テーブルCSVを取得します。
     * @summary テーブルCSV取得
     * @param {string} teamname チーム名
     * @param {number} projectOrder プロジェクトオーダー
     * @param {number} tableNumber テーブル番号
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjApi
     */
    public async downloadTableCsv(teamname: string, projectOrder: number, tableNumber: number, options?: AxiosRequestConfig) {
        const retry = 2;
        let resp;
        for (let i = 0; i < retry; i++) {
            resp = await ProjApiFp(this.configuration).downloadTableCsv(teamname, projectOrder, tableNumber, options).then((request) => request(this.axios, this.basePath)).catch((error) => {
                if (error?.response) {
                    return error.response;
                } else {
                    return { status: 500 };
                }
            });
            if (resp?.status === 401) {
                await refreshIdToken();
                console.debug('Retry downloadTableCsv ' + i + '/' + (retry - 1));
                continue;
            }
            break;
        }
        return resp;
    }

    /**
     * フィールド定義リストを取得します。
     * @summary フィールド定義リスト取得
     * @param {string} teamname チーム名
     * @param {number} projectOrder プロジェクトオーダー
     * @param {number} tableNumber テーブル番号
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjApi
     */
    public async findFieldDefs(teamname: string, projectOrder: number, tableNumber: number, options?: AxiosRequestConfig) {
        const retry = 2;
        let resp;
        for (let i = 0; i < retry; i++) {
            resp = await ProjApiFp(this.configuration).findFieldDefs(teamname, projectOrder, tableNumber, options).then((request) => request(this.axios, this.basePath)).catch((error) => {
                if (error?.response) {
                    return error.response;
                } else {
                    return { status: 500 };
                }
            });
            if (resp?.status === 401) {
                await refreshIdToken();
                console.debug('Retry findFieldDefs ' + i + '/' + (retry - 1));
                continue;
            }
            break;
        }
        return resp;
    }

    /**
     * プロジェクトのタスクのリストを取得します。
     * @summary タスクリスト取得
     * @param {string} projectIdentifier プロジェクト識別子
     * @param {number} [page] ページ番号
     * @param {number} [perPage] 1ページあたりの要素数 (MAX100)
     * @param {string} [status] ステータス条件
     * @param {number} [categoryId] カテゴリーID
     * @param {string} [search] 検索キーワード
     * @param {string} [sort] ソート条件
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjApi
     */
    public async findProjectTasks(projectIdentifier: string, page?: number, perPage?: number, status?: string, categoryId?: number, search?: string, sort?: string, options?: AxiosRequestConfig) {
        const retry = 2;
        let resp;
        for (let i = 0; i < retry; i++) {
            resp = await ProjApiFp(this.configuration).findProjectTasks(projectIdentifier, page, perPage, status, categoryId, search, sort, options).then((request) => request(this.axios, this.basePath)).catch((error) => {
                if (error?.response) {
                    return error.response;
                } else {
                    return { status: 500 };
                }
            });
            if (resp?.status === 401) {
                await refreshIdToken();
                console.debug('Retry findProjectTasks ' + i + '/' + (retry - 1));
                continue;
            }
            break;
        }
        return resp;
    }

    /**
     * プロジェクトリストを取得します。
     * @summary プロジェクトリストの取得
     * @param {number} [page] ページ番号
     * @param {number} [perPage] 1ページあたりの要素数 (MAX100)
     * @param {string} [status] ステータス条件
     * @param {boolean} [fav] お気に入り
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjApi
     */
    public async findProjects(page?: number, perPage?: number, status?: string, fav?: boolean, options?: AxiosRequestConfig) {
        const retry = 2;
        let resp;
        for (let i = 0; i < retry; i++) {
            resp = await ProjApiFp(this.configuration).findProjects(page, perPage, status, fav, options).then((request) => request(this.axios, this.basePath)).catch((error) => {
                if (error?.response) {
                    return error.response;
                } else {
                    return { status: 500 };
                }
            });
            if (resp?.status === 401) {
                await refreshIdToken();
                console.debug('Retry findProjects ' + i + '/' + (retry - 1));
                continue;
            }
            break;
        }
        return resp;
    }

    /**
     * プロジェクトのテーブルリストを取得します。
     * @summary プロジェクトのテーブルリスト取得
     * @param {string} teamname チーム名
     * @param {number} projectOrder プロジェクトオーダー
     * @param {number} [page] ページ番号
     * @param {number} [perPage] 1ページあたりの要素数 (MAX100)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjApi
     */
    public async findTableDef(teamname: string, projectOrder: number, page?: number, perPage?: number, options?: AxiosRequestConfig) {
        const retry = 2;
        let resp;
        for (let i = 0; i < retry; i++) {
            resp = await ProjApiFp(this.configuration).findTableDef(teamname, projectOrder, page, perPage, options).then((request) => request(this.axios, this.basePath)).catch((error) => {
                if (error?.response) {
                    return error.response;
                } else {
                    return { status: 500 };
                }
            });
            if (resp?.status === 401) {
                await refreshIdToken();
                console.debug('Retry findTableDef ' + i + '/' + (retry - 1));
                continue;
            }
            break;
        }
        return resp;
    }

    /**
     * レコードリストを取得します。
     * @summary レコードリスト取得
     * @param {string} teamname チーム名
     * @param {number} projectOrder プロジェクトオーダー
     * @param {number} tableNumber テーブル番号
     * @param {number} [page] ページ番号
     * @param {number} [perPage] 1ページあたりの要素数 (MAX100)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjApi
     */
    public async findTableRows(teamname: string, projectOrder: number, tableNumber: number, page?: number, perPage?: number, options?: AxiosRequestConfig) {
        const retry = 2;
        let resp;
        for (let i = 0; i < retry; i++) {
            resp = await ProjApiFp(this.configuration).findTableRows(teamname, projectOrder, tableNumber, page, perPage, options).then((request) => request(this.axios, this.basePath)).catch((error) => {
                if (error?.response) {
                    return error.response;
                } else {
                    return { status: 500 };
                }
            });
            if (resp?.status === 401) {
                await refreshIdToken();
                console.debug('Retry findTableRows ' + i + '/' + (retry - 1));
                continue;
            }
            break;
        }
        return resp;
    }

    /**
     * メンションリストを取得します。
     * @summary メンションリスト取得
     * @param {string} teamname チーム名
     * @param {number} projectOrder プロジェクトオーダー
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjApi
     */
    public async findTeamProjectMentions(teamname: string, projectOrder: number, options?: AxiosRequestConfig) {
        const retry = 2;
        let resp;
        for (let i = 0; i < retry; i++) {
            resp = await ProjApiFp(this.configuration).findTeamProjectMentions(teamname, projectOrder, options).then((request) => request(this.axios, this.basePath)).catch((error) => {
                if (error?.response) {
                    return error.response;
                } else {
                    return { status: 500 };
                }
            });
            if (resp?.status === 401) {
                await refreshIdToken();
                console.debug('Retry findTeamProjectMentions ' + i + '/' + (retry - 1));
                continue;
            }
            break;
        }
        return resp;
    }

    /**
     * プロジェクト設定を取得します。
     * @summary プロジェクト設定の取得
     * @param {string} teamname チーム名
     * @param {number} projectOrder プロジェクトオーダー
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjApi
     */
    public async findTeamProjectSettings(teamname: string, projectOrder: number, options?: AxiosRequestConfig) {
        const retry = 2;
        let resp;
        for (let i = 0; i < retry; i++) {
            resp = await ProjApiFp(this.configuration).findTeamProjectSettings(teamname, projectOrder, options).then((request) => request(this.axios, this.basePath)).catch((error) => {
                if (error?.response) {
                    return error.response;
                } else {
                    return { status: 500 };
                }
            });
            if (resp?.status === 401) {
                await refreshIdToken();
                console.debug('Retry findTeamProjectSettings ' + i + '/' + (retry - 1));
                continue;
            }
            break;
        }
        return resp;
    }

    /**
     * Googleカレンダリストを取得します。
     * @summary Googleカレンダリストの取得
     * @param {string} teamname チーム名
     * @param {number} projectOrder プロジェクトオーダー
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjApi
     */
    public async findTeamProjectSettingsGcalList(teamname: string, projectOrder: number, options?: AxiosRequestConfig) {
        const retry = 2;
        let resp;
        for (let i = 0; i < retry; i++) {
            resp = await ProjApiFp(this.configuration).findTeamProjectSettingsGcalList(teamname, projectOrder, options).then((request) => request(this.axios, this.basePath)).catch((error) => {
                if (error?.response) {
                    return error.response;
                } else {
                    return { status: 500 };
                }
            });
            if (resp?.status === 401) {
                await refreshIdToken();
                console.debug('Retry findTeamProjectSettingsGcalList ' + i + '/' + (retry - 1));
                continue;
            }
            break;
        }
        return resp;
    }

    /**
     * 作業時間リストを取得します。
     * @summary 作業時間リスト取得
     * @param {string} teamname チーム名
     * @param {number} projectOrder プロジェクトオーダー
     * @param {number} [year] 年
     * @param {number} [month] 月
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjApi
     */
    public async findTeamProjectTimeEntries(teamname: string, projectOrder: number, year?: number, month?: number, options?: AxiosRequestConfig) {
        const retry = 2;
        let resp;
        for (let i = 0; i < retry; i++) {
            resp = await ProjApiFp(this.configuration).findTeamProjectTimeEntries(teamname, projectOrder, year, month, options).then((request) => request(this.axios, this.basePath)).catch((error) => {
                if (error?.response) {
                    return error.response;
                } else {
                    return { status: 500 };
                }
            });
            if (resp?.status === 401) {
                await refreshIdToken();
                console.debug('Retry findTeamProjectTimeEntries ' + i + '/' + (retry - 1));
                continue;
            }
            break;
        }
        return resp;
    }

    /**
     * 作業時間リストを取得します。
     * @summary 作業時間リスト取得
     * @param {string} teamname チーム名
     * @param {number} projectOrder プロジェクトオーダー
     * @param {number} taskId タスクID
     * @param {number} [year] 年
     * @param {number} [month] 月
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjApi
     */
    public async findTeamProjectTimeEntriesTask(teamname: string, projectOrder: number, taskId: number, year?: number, month?: number, options?: AxiosRequestConfig) {
        const retry = 2;
        let resp;
        for (let i = 0; i < retry; i++) {
            resp = await ProjApiFp(this.configuration).findTeamProjectTimeEntriesTask(teamname, projectOrder, taskId, year, month, options).then((request) => request(this.axios, this.basePath)).catch((error) => {
                if (error?.response) {
                    return error.response;
                } else {
                    return { status: 500 };
                }
            });
            if (resp?.status === 401) {
                await refreshIdToken();
                console.debug('Retry findTeamProjectTimeEntriesTask ' + i + '/' + (retry - 1));
                continue;
            }
            break;
        }
        return resp;
    }

    /**
     * チーム>プロジェクトリストを取得します。
     * @summary チーム>プロジェクトリストの取得
     * @param {string} teamname チーム名
     * @param {number} [page] ページ番号
     * @param {number} [perPage] 1ページあたりの要素数 (MAX100)
     * @param {string} [status] ステータス条件
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjApi
     */
    public async findTeamProjects(teamname: string, page?: number, perPage?: number, status?: string, options?: AxiosRequestConfig) {
        const retry = 2;
        let resp;
        for (let i = 0; i < retry; i++) {
            resp = await ProjApiFp(this.configuration).findTeamProjects(teamname, page, perPage, status, options).then((request) => request(this.axios, this.basePath)).catch((error) => {
                if (error?.response) {
                    return error.response;
                } else {
                    return { status: 500 };
                }
            });
            if (resp?.status === 401) {
                await refreshIdToken();
                console.debug('Retry findTeamProjects ' + i + '/' + (retry - 1));
                continue;
            }
            break;
        }
        return resp;
    }

    /**
     * プロジェクト情報を取得します。
     * @summary プロジェクト情報取得
     * @param {string} projectIdentifier プロジェクト識別子
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjApi
     */
    public async getProject(projectIdentifier: string, options?: AxiosRequestConfig) {
        const retry = 2;
        let resp;
        for (let i = 0; i < retry; i++) {
            resp = await ProjApiFp(this.configuration).getProject(projectIdentifier, options).then((request) => request(this.axios, this.basePath)).catch((error) => {
                if (error?.response) {
                    return error.response;
                } else {
                    return { status: 500 };
                }
            });
            if (resp?.status === 401) {
                await refreshIdToken();
                console.debug('Retry getProject ' + i + '/' + (retry - 1));
                continue;
            }
            break;
        }
        return resp;
    }

    /**
     * 自分自身のタスクを取得します。
     * @summary タスクの取得
     * @param {string} projectIdentifier プロジェクト識別子
     * @param {number} taskId タスクID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjApi
     */
    public async getProjectTask(projectIdentifier: string, taskId: number, options?: AxiosRequestConfig) {
        const retry = 2;
        let resp;
        for (let i = 0; i < retry; i++) {
            resp = await ProjApiFp(this.configuration).getProjectTask(projectIdentifier, taskId, options).then((request) => request(this.axios, this.basePath)).catch((error) => {
                if (error?.response) {
                    return error.response;
                } else {
                    return { status: 500 };
                }
            });
            if (resp?.status === 401) {
                await refreshIdToken();
                console.debug('Retry getProjectTask ' + i + '/' + (retry - 1));
                continue;
            }
            break;
        }
        return resp;
    }

    /**
     * プロジェクト情報を取得します。
     * @summary プロジェクトの取得
     * @param {string} teamname チーム名
     * @param {number} projectOrder プロジェクトオーダー
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjApi
     */
    public async getTeamProject(teamname: string, projectOrder: number, options?: AxiosRequestConfig) {
        const retry = 2;
        let resp;
        for (let i = 0; i < retry; i++) {
            resp = await ProjApiFp(this.configuration).getTeamProject(teamname, projectOrder, options).then((request) => request(this.axios, this.basePath)).catch((error) => {
                if (error?.response) {
                    return error.response;
                } else {
                    return { status: 500 };
                }
            });
            if (resp?.status === 401) {
                await refreshIdToken();
                console.debug('Retry getTeamProject ' + i + '/' + (retry - 1));
                continue;
            }
            break;
        }
        return resp;
    }

    /**
     * プロジェクトをお気に入り設定します。
     * @summary プロジェクトお気に入り設定
     * @param {string} projectIdentifier プロジェクト識別子
     * @param {ProjectFav} projectFav プロジェクトお気に入り設定情報
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjApi
     */
    public async setProjectFav(projectIdentifier: string, projectFav: ProjectFav, options?: AxiosRequestConfig) {
        const retry = 2;
        let resp;
        for (let i = 0; i < retry; i++) {
            resp = await ProjApiFp(this.configuration).setProjectFav(projectIdentifier, projectFav, options).then((request) => request(this.axios, this.basePath)).catch((error) => {
                if (error?.response) {
                    return error.response;
                } else {
                    return { status: 500 };
                }
            });
            if (resp?.status === 401) {
                await refreshIdToken();
                console.debug('Retry setProjectFav ' + i + '/' + (retry - 1));
                continue;
            }
            break;
        }
        return resp;
    }

    /**
     * 自分自身のタスクを更新します。
     * @summary タスク更新
     * @param {string} projectIdentifier プロジェクト識別子
     * @param {number} taskId タスクID
     * @param {TaskRequest} taskRequest タスク情報
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjApi
     */
    public async setProjectTask(projectIdentifier: string, taskId: number, taskRequest: TaskRequest, options?: AxiosRequestConfig) {
        const retry = 2;
        let resp;
        for (let i = 0; i < retry; i++) {
            resp = await ProjApiFp(this.configuration).setProjectTask(projectIdentifier, taskId, taskRequest, options).then((request) => request(this.axios, this.basePath)).catch((error) => {
                if (error?.response) {
                    return error.response;
                } else {
                    return { status: 500 };
                }
            });
            if (resp?.status === 401) {
                await refreshIdToken();
                console.debug('Retry setProjectTask ' + i + '/' + (retry - 1));
                continue;
            }
            break;
        }
        return resp;
    }

    /**
     * プロジェクトのテーブルを更新します。
     * @summary テーブル更新
     * @param {string} teamname チーム名
     * @param {number} projectOrder プロジェクトオーダー
     * @param {number} tableNumber テーブル番号
     * @param {TableDefReq} tableDefReq テーブル情報
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjApi
     */
    public async setTableDef(teamname: string, projectOrder: number, tableNumber: number, tableDefReq: TableDefReq, options?: AxiosRequestConfig) {
        const retry = 2;
        let resp;
        for (let i = 0; i < retry; i++) {
            resp = await ProjApiFp(this.configuration).setTableDef(teamname, projectOrder, tableNumber, tableDefReq, options).then((request) => request(this.axios, this.basePath)).catch((error) => {
                if (error?.response) {
                    return error.response;
                } else {
                    return { status: 500 };
                }
            });
            if (resp?.status === 401) {
                await refreshIdToken();
                console.debug('Retry setTableDef ' + i + '/' + (retry - 1));
                continue;
            }
            break;
        }
        return resp;
    }

    /**
     * レコードを更新します。
     * @summary レコード更新
     * @param {string} teamname チーム名
     * @param {number} projectOrder プロジェクトオーダー
     * @param {number} tableNumber テーブル番号
     * @param {string} tableRowKey レコードキー
     * @param {TableRowReq} tableRowReq レコード情報
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjApi
     */
    public async setTableRow(teamname: string, projectOrder: number, tableNumber: number, tableRowKey: string, tableRowReq: TableRowReq, options?: AxiosRequestConfig) {
        const retry = 2;
        let resp;
        for (let i = 0; i < retry; i++) {
            resp = await ProjApiFp(this.configuration).setTableRow(teamname, projectOrder, tableNumber, tableRowKey, tableRowReq, options).then((request) => request(this.axios, this.basePath)).catch((error) => {
                if (error?.response) {
                    return error.response;
                } else {
                    return { status: 500 };
                }
            });
            if (resp?.status === 401) {
                await refreshIdToken();
                console.debug('Retry setTableRow ' + i + '/' + (retry - 1));
                continue;
            }
            break;
        }
        return resp;
    }

    /**
     * チーム>プロジェクト情報を更新します。
     * @summary チーム>プロジェクト情報の更新
     * @param {string} teamname チーム名
     * @param {number} projectOrder プロジェクトオーダー
     * @param {TeamProjReq} teamProjReq チーム&gt;プロジェクト情報
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjApi
     */
    public async setTeamProject(teamname: string, projectOrder: number, teamProjReq: TeamProjReq, options?: AxiosRequestConfig) {
        const retry = 2;
        let resp;
        for (let i = 0; i < retry; i++) {
            resp = await ProjApiFp(this.configuration).setTeamProject(teamname, projectOrder, teamProjReq, options).then((request) => request(this.axios, this.basePath)).catch((error) => {
                if (error?.response) {
                    return error.response;
                } else {
                    return { status: 500 };
                }
            });
            if (resp?.status === 401) {
                await refreshIdToken();
                console.debug('Retry setTeamProject ' + i + '/' + (retry - 1));
                continue;
            }
            break;
        }
        return resp;
    }

    /**
     * プロジェクト完了状態を更新します。
     * @summary プロジェクト完了状態の更新
     * @param {string} teamname チーム名
     * @param {number} projectOrder プロジェクトオーダー
     * @param {ProjectComplete} projectComplete チーム&gt;プロジェクト情報
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjApi
     */
    public async setTeamProjectComplete(teamname: string, projectOrder: number, projectComplete: ProjectComplete, options?: AxiosRequestConfig) {
        const retry = 2;
        let resp;
        for (let i = 0; i < retry; i++) {
            resp = await ProjApiFp(this.configuration).setTeamProjectComplete(teamname, projectOrder, projectComplete, options).then((request) => request(this.axios, this.basePath)).catch((error) => {
                if (error?.response) {
                    return error.response;
                } else {
                    return { status: 500 };
                }
            });
            if (resp?.status === 401) {
                await refreshIdToken();
                console.debug('Retry setTeamProjectComplete ' + i + '/' + (retry - 1));
                continue;
            }
            break;
        }
        return resp;
    }

    /**
     * プロジェクトをお気に入り設定します。
     * @summary プロジェクトお気に入り設定
     * @param {string} teamname チーム名
     * @param {number} projectOrder プロジェクトオーダー
     * @param {ProjectFav} projectFav プロジェクトお気に入り設定情報
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjApi
     */
    public async setTeamProjectFav(teamname: string, projectOrder: number, projectFav: ProjectFav, options?: AxiosRequestConfig) {
        const retry = 2;
        let resp;
        for (let i = 0; i < retry; i++) {
            resp = await ProjApiFp(this.configuration).setTeamProjectFav(teamname, projectOrder, projectFav, options).then((request) => request(this.axios, this.basePath)).catch((error) => {
                if (error?.response) {
                    return error.response;
                } else {
                    return { status: 500 };
                }
            });
            if (resp?.status === 401) {
                await refreshIdToken();
                console.debug('Retry setTeamProjectFav ' + i + '/' + (retry - 1));
                continue;
            }
            break;
        }
        return resp;
    }

    /**
     * プロジェクト設定を更新します。
     * @summary プロジェクト設定の更新
     * @param {string} teamname チーム名
     * @param {number} projectOrder プロジェクトオーダー
     * @param {ProjectSettingsReq} projectSettingsReq チーム&gt;プロジェクト情報
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjApi
     */
    public async setTeamProjectSettings(teamname: string, projectOrder: number, projectSettingsReq: ProjectSettingsReq, options?: AxiosRequestConfig) {
        const retry = 2;
        let resp;
        for (let i = 0; i < retry; i++) {
            resp = await ProjApiFp(this.configuration).setTeamProjectSettings(teamname, projectOrder, projectSettingsReq, options).then((request) => request(this.axios, this.basePath)).catch((error) => {
                if (error?.response) {
                    return error.response;
                } else {
                    return { status: 500 };
                }
            });
            if (resp?.status === 401) {
                await refreshIdToken();
                console.debug('Retry setTeamProjectSettings ' + i + '/' + (retry - 1));
                continue;
            }
            break;
        }
        return resp;
    }

    /**
     * テーブルCSVをアップロードします。
     * @summary テーブルCSVアップロード
     * @param {string} teamname チーム名
     * @param {number} projectOrder プロジェクトオーダー
     * @param {number} tableNumber テーブル番号
     * @param {TableRowsCsvReq} tableRowsCsvReq レコード情報
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjApi
     */
    public async uploadTableCsv(teamname: string, projectOrder: number, tableNumber: number, tableRowsCsvReq: TableRowsCsvReq, options?: AxiosRequestConfig) {
        const retry = 2;
        let resp;
        for (let i = 0; i < retry; i++) {
            resp = await ProjApiFp(this.configuration).uploadTableCsv(teamname, projectOrder, tableNumber, tableRowsCsvReq, options).then((request) => request(this.axios, this.basePath)).catch((error) => {
                if (error?.response) {
                    return error.response;
                } else {
                    return { status: 500 };
                }
            });
            if (resp?.status === 401) {
                await refreshIdToken();
                console.debug('Retry uploadTableCsv ' + i + '/' + (retry - 1));
                continue;
            }
            break;
        }
        return resp;
    }

    /**
     * テーブルCSVをアップロード確定します。
     * @summary テーブルCSVアップロード確定
     * @param {string} teamname チーム名
     * @param {number} projectOrder プロジェクトオーダー
     * @param {number} tableNumber テーブル番号
     * @param {number} historyNumber テーブル更新番号
     * @param {TableRowsCsvHistoryReq} tableRowsCsvHistoryReq レコード情報
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjApi
     */
    public async uploadTableCsvDone(teamname: string, projectOrder: number, tableNumber: number, historyNumber: number, tableRowsCsvHistoryReq: TableRowsCsvHistoryReq, options?: AxiosRequestConfig) {
        const retry = 2;
        let resp;
        for (let i = 0; i < retry; i++) {
            resp = await ProjApiFp(this.configuration).uploadTableCsvDone(teamname, projectOrder, tableNumber, historyNumber, tableRowsCsvHistoryReq, options).then((request) => request(this.axios, this.basePath)).catch((error) => {
                if (error?.response) {
                    return error.response;
                } else {
                    return { status: 500 };
                }
            });
            if (resp?.status === 401) {
                await refreshIdToken();
                console.debug('Retry uploadTableCsvDone ' + i + '/' + (retry - 1));
                continue;
            }
            break;
        }
        return resp;
    }
}


/**
 * PublicApi - axios parameter creator
 * @export
 */
export const PublicApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 公開ナレッジコメントを設定します。
         * @summary 公開ナレッジコメントの設定
         * @param {string} articleId 公開コンテンツID
         * @param {ArticleCommentReq} articleCommentReq 公開ナレッジコメント情報
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addArticleComment: async (articleId: string, articleCommentReq: ArticleCommentReq, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'articleId' is not null or undefined
            assertParamExists('addArticleComment', 'articleId', articleId)
            // verify required parameter 'articleCommentReq' is not null or undefined
            assertParamExists('addArticleComment', 'articleCommentReq', articleCommentReq)
            const localVarPath = `/public/articles/{article_id}/comments`
                .replace(`{${"article_id"}}`, encodeURIComponent(String(articleId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(articleCommentReq, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 公開ナレッジコメントを設定します。
         * @summary 公開ナレッジコメントの設定
         * @param {string} articleId 公開コンテンツID
         * @param {number} articleCommentId 公開ナレッジコメントID
         * @param {ArticleCommentReq} articleCommentReq 公開ナレッジコメント情報
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        delArticleComment: async (articleId: string, articleCommentId: number, articleCommentReq: ArticleCommentReq, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'articleId' is not null or undefined
            assertParamExists('delArticleComment', 'articleId', articleId)
            // verify required parameter 'articleCommentId' is not null or undefined
            assertParamExists('delArticleComment', 'articleCommentId', articleCommentId)
            // verify required parameter 'articleCommentReq' is not null or undefined
            assertParamExists('delArticleComment', 'articleCommentReq', articleCommentReq)
            const localVarPath = `/public/articles/{article_id}/comments/{article_comment_id}`
                .replace(`{${"article_id"}}`, encodeURIComponent(String(articleId)))
                .replace(`{${"article_comment_id"}}`, encodeURIComponent(String(articleCommentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(articleCommentReq, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 公開ナレッジコメントを取得します。
         * @summary 公開ナレッジコメントの取得
         * @param {string} articleId 公開コンテンツID
         * @param {number} [articleCommentId] 返信元コメントID
         * @param {number} [page] ページ番号
         * @param {number} [perPage] 1ページあたりの要素数 (MAX100)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findArticleComments: async (articleId: string, articleCommentId?: number, page?: number, perPage?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'articleId' is not null or undefined
            assertParamExists('findArticleComments', 'articleId', articleId)
            const localVarPath = `/public/articles/{article_id}/comments`
                .replace(`{${"article_id"}}`, encodeURIComponent(String(articleId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (articleCommentId !== undefined) {
                localVarQueryParameter['articleCommentId'] = articleCommentId;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (perPage !== undefined) {
                localVarQueryParameter['per_page'] = perPage;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * ニュースフィードを取得します。
         * @summary ニュースフィードの取得
         * @param {string} skillType スキル種別
         * @param {number} [page] ページ番号
         * @param {number} [perPage] 1ページあたりの要素数 (MAX100)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findNewsFeeds: async (skillType: string, page?: number, perPage?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'skillType' is not null or undefined
            assertParamExists('findNewsFeeds', 'skillType', skillType)
            const localVarPath = `/public/newsfeed/{skill_type}`
                .replace(`{${"skill_type"}}`, encodeURIComponent(String(skillType)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (perPage !== undefined) {
                localVarQueryParameter['per_page'] = perPage;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 公開コンテンツリストを取得します。
         * @summary 公開コンテンツリスト取得
         * @param {number} [page] ページ番号
         * @param {number} [perPage] 1ページあたりの要素数 (MAX100)
         * @param {string} [search] 検索キーワード
         * @param {string} [category] カテゴリー
         * @param {string} [teamname] チーム名
         * @param {string} [sort] ソート条件
         * @param {string} [username] ユーザ名
         * @param {boolean} [isPersonal] パーソナルとチームを切り分けるフラグ
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findPublicArticles: async (page?: number, perPage?: number, search?: string, category?: string, teamname?: string, sort?: string, username?: string, isPersonal?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/public/articles`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (perPage !== undefined) {
                localVarQueryParameter['per_page'] = perPage;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (category !== undefined) {
                localVarQueryParameter['category'] = category;
            }

            if (teamname !== undefined) {
                localVarQueryParameter['teamname'] = teamname;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            if (username !== undefined) {
                localVarQueryParameter['username'] = username;
            }

            if (isPersonal !== undefined) {
                localVarQueryParameter['is_personal'] = isPersonal;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 一般公開myWikiのリストを取得します。
         * @summary 一般公開myWikiリスト取得
         * @param {number} [page] ページ番号
         * @param {number} [perPage] 1ページあたりの要素数 (MAX100)
         * @param {string} [search] 検索キーワード
         * @param {number} [categoryId] カテゴリーID
         * @param {string} [sort] ソート条件
         * @param {string} [username] ユーザ名
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findPublicMyWikis: async (page?: number, perPage?: number, search?: string, categoryId?: number, sort?: string, username?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/public/wikis/my`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (perPage !== undefined) {
                localVarQueryParameter['per_page'] = perPage;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (categoryId !== undefined) {
                localVarQueryParameter['category_id'] = categoryId;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            if (username !== undefined) {
                localVarQueryParameter['username'] = username;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 公開ポートフォリオIdを取得します。
         * @summary 公開ポートフォリオId取得
         * @param {string} publicUrl 公開URL
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findPublicPortfolioId: async (publicUrl: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'publicUrl' is not null or undefined
            assertParamExists('findPublicPortfolioId', 'publicUrl', publicUrl)
            const localVarPath = `/public/portfolio/{public_url}`
                .replace(`{${"public_url"}}`, encodeURIComponent(String(publicUrl)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 公開ポートフォリオリストを取得します。
         * @summary 公開ポートフォリオリスト取得
         * @param {number} [page] ページ番号
         * @param {number} [perPage] 1ページあたりの要素数 (MAX100)
         * @param {string} [username] ユーザ名
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findPublicPortfolios: async (page?: number, perPage?: number, username?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/public/portfolios`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (perPage !== undefined) {
                localVarQueryParameter['per_page'] = perPage;
            }

            if (username !== undefined) {
                localVarQueryParameter['username'] = username;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 公開しているチームのリストを取得します。
         * @summary 公開チームリストの取得
         * @param {number} [page] ページ番号
         * @param {number} [perPage] 1ページあたりの要素数 (MAX100)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findPublicTeams: async (page?: number, perPage?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/public/teams`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (perPage !== undefined) {
                localVarQueryParameter['per_page'] = perPage;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 公開コンテンツを取得します。
         * @summary 公開コンテンツの取得
         * @param {string} articleId 公開コンテンツID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPublicArticle: async (articleId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'articleId' is not null or undefined
            assertParamExists('getPublicArticle', 'articleId', articleId)
            const localVarPath = `/public/articles/{article_id}`
                .replace(`{${"article_id"}}`, encodeURIComponent(String(articleId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 公開コンテンツをカテゴリ取得します。
         * @summary 公開コンテンツのカテゴリ取得
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPublicArticleCategories: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/public/article_categories`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 公開コンテンツを取得します。
         * @summary 公開コンテンツの取得（ログイン済）
         * @param {string} articleId 公開コンテンツID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPublicArticleLoggedIn: async (articleId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'articleId' is not null or undefined
            assertParamExists('getPublicArticleLoggedIn', 'articleId', articleId)
            const localVarPath = `/public/articles/{article_id}/loggedin`
                .replace(`{${"article_id"}}`, encodeURIComponent(String(articleId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 公開しているチームを取得します。
         * @summary 公開チームの取得
         * @param {string} teamname チーム名
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPublicTeam: async (teamname: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'teamname' is not null or undefined
            assertParamExists('getPublicTeam', 'teamname', teamname)
            const localVarPath = `/public/teams/{teamname}`
                .replace(`{${"teamname"}}`, encodeURIComponent(String(teamname)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * ユーザー公開コンテンツをカテゴリ取得します。
         * @summary ユーザー公開コンテンツのカテゴリ取得
         * @param {string} [username] ユーザ名
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserPublicArticleCategories: async (username?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/public/user/article_categories.yml`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (username !== undefined) {
                localVarQueryParameter['username'] = username;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 公開ナレッジコメントいいねを設定します。
         * @summary 公開ナレッジコメントいいねいいねの設定
         * @param {string} articleId 公開コンテンツID
         * @param {number} articleCommentId 公開ナレッジコメントID
         * @param {ArticleCommentLikeReq} articleCommentLikeReq 公開ナレッジコメントいいね情報
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        likeArticleComment: async (articleId: string, articleCommentId: number, articleCommentLikeReq: ArticleCommentLikeReq, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'articleId' is not null or undefined
            assertParamExists('likeArticleComment', 'articleId', articleId)
            // verify required parameter 'articleCommentId' is not null or undefined
            assertParamExists('likeArticleComment', 'articleCommentId', articleCommentId)
            // verify required parameter 'articleCommentLikeReq' is not null or undefined
            assertParamExists('likeArticleComment', 'articleCommentLikeReq', articleCommentLikeReq)
            const localVarPath = `/public/articles/{article_id}/comments/{article_comment_id}/like`
                .replace(`{${"article_id"}}`, encodeURIComponent(String(articleId)))
                .replace(`{${"article_comment_id"}}`, encodeURIComponent(String(articleCommentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(articleCommentLikeReq, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * クエストのいいねを設定します。
         * @summary クエストいいねの設定
         * @param {ArticleLikeRequest} articleLikeRequest クエストいいね情報
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setArticleLike: async (articleLikeRequest: ArticleLikeRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'articleLikeRequest' is not null or undefined
            assertParamExists('setArticleLike', 'articleLikeRequest', articleLikeRequest)
            const localVarPath = `/public/articles/like`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(articleLikeRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 共有用のスキミーパーティ画像アップロードします。
         * @summary 共有用のスキミーパーティ画像アップロード
         * @param {Array<File>} [files] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadSkimmyPartyImage: async (files?: Array<File>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/uploads/skimmy/party`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (files) {
                files.forEach((element) => {
                    localVarFormParams.append('files', element as any);
                })
            }

    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PublicApi - functional programming interface
 * @export
 */
export const PublicApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PublicApiAxiosParamCreator(configuration)
    return {
        /**
         * 公開ナレッジコメントを設定します。
         * @summary 公開ナレッジコメントの設定
         * @param {string} articleId 公開コンテンツID
         * @param {ArticleCommentReq} articleCommentReq 公開ナレッジコメント情報
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addArticleComment(articleId: string, articleCommentReq: ArticleCommentReq, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ArticleComment>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addArticleComment(articleId, articleCommentReq, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 公開ナレッジコメントを設定します。
         * @summary 公開ナレッジコメントの設定
         * @param {string} articleId 公開コンテンツID
         * @param {number} articleCommentId 公開ナレッジコメントID
         * @param {ArticleCommentReq} articleCommentReq 公開ナレッジコメント情報
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async delArticleComment(articleId: string, articleCommentId: number, articleCommentReq: ArticleCommentReq, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.delArticleComment(articleId, articleCommentId, articleCommentReq, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 公開ナレッジコメントを取得します。
         * @summary 公開ナレッジコメントの取得
         * @param {string} articleId 公開コンテンツID
         * @param {number} [articleCommentId] 返信元コメントID
         * @param {number} [page] ページ番号
         * @param {number} [perPage] 1ページあたりの要素数 (MAX100)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findArticleComments(articleId: string, articleCommentId?: number, page?: number, perPage?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ArticleComments>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findArticleComments(articleId, articleCommentId, page, perPage, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * ニュースフィードを取得します。
         * @summary ニュースフィードの取得
         * @param {string} skillType スキル種別
         * @param {number} [page] ページ番号
         * @param {number} [perPage] 1ページあたりの要素数 (MAX100)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findNewsFeeds(skillType: string, page?: number, perPage?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Newsfeeds>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findNewsFeeds(skillType, page, perPage, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 公開コンテンツリストを取得します。
         * @summary 公開コンテンツリスト取得
         * @param {number} [page] ページ番号
         * @param {number} [perPage] 1ページあたりの要素数 (MAX100)
         * @param {string} [search] 検索キーワード
         * @param {string} [category] カテゴリー
         * @param {string} [teamname] チーム名
         * @param {string} [sort] ソート条件
         * @param {string} [username] ユーザ名
         * @param {boolean} [isPersonal] パーソナルとチームを切り分けるフラグ
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findPublicArticles(page?: number, perPage?: number, search?: string, category?: string, teamname?: string, sort?: string, username?: string, isPersonal?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Articles>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findPublicArticles(page, perPage, search, category, teamname, sort, username, isPersonal, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 一般公開myWikiのリストを取得します。
         * @summary 一般公開myWikiリスト取得
         * @param {number} [page] ページ番号
         * @param {number} [perPage] 1ページあたりの要素数 (MAX100)
         * @param {string} [search] 検索キーワード
         * @param {number} [categoryId] カテゴリーID
         * @param {string} [sort] ソート条件
         * @param {string} [username] ユーザ名
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findPublicMyWikis(page?: number, perPage?: number, search?: string, categoryId?: number, sort?: string, username?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Wikis>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findPublicMyWikis(page, perPage, search, categoryId, sort, username, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 公開ポートフォリオIdを取得します。
         * @summary 公開ポートフォリオId取得
         * @param {string} publicUrl 公開URL
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findPublicPortfolioId(publicUrl: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SharedPortfolioId>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findPublicPortfolioId(publicUrl, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 公開ポートフォリオリストを取得します。
         * @summary 公開ポートフォリオリスト取得
         * @param {number} [page] ページ番号
         * @param {number} [perPage] 1ページあたりの要素数 (MAX100)
         * @param {string} [username] ユーザ名
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findPublicPortfolios(page?: number, perPage?: number, username?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SharedPortfolios>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findPublicPortfolios(page, perPage, username, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 公開しているチームのリストを取得します。
         * @summary 公開チームリストの取得
         * @param {number} [page] ページ番号
         * @param {number} [perPage] 1ページあたりの要素数 (MAX100)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findPublicTeams(page?: number, perPage?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TeamDescriptions>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findPublicTeams(page, perPage, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 公開コンテンツを取得します。
         * @summary 公開コンテンツの取得
         * @param {string} articleId 公開コンテンツID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPublicArticle(articleId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Article>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPublicArticle(articleId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 公開コンテンツをカテゴリ取得します。
         * @summary 公開コンテンツのカテゴリ取得
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPublicArticleCategories(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Categories>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPublicArticleCategories(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 公開コンテンツを取得します。
         * @summary 公開コンテンツの取得（ログイン済）
         * @param {string} articleId 公開コンテンツID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPublicArticleLoggedIn(articleId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Article>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPublicArticleLoggedIn(articleId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 公開しているチームを取得します。
         * @summary 公開チームの取得
         * @param {string} teamname チーム名
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPublicTeam(teamname: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TeamDescription>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPublicTeam(teamname, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * ユーザー公開コンテンツをカテゴリ取得します。
         * @summary ユーザー公開コンテンツのカテゴリ取得
         * @param {string} [username] ユーザ名
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUserPublicArticleCategories(username?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Categories>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUserPublicArticleCategories(username, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 公開ナレッジコメントいいねを設定します。
         * @summary 公開ナレッジコメントいいねいいねの設定
         * @param {string} articleId 公開コンテンツID
         * @param {number} articleCommentId 公開ナレッジコメントID
         * @param {ArticleCommentLikeReq} articleCommentLikeReq 公開ナレッジコメントいいね情報
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async likeArticleComment(articleId: string, articleCommentId: number, articleCommentLikeReq: ArticleCommentLikeReq, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ArticleComment>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.likeArticleComment(articleId, articleCommentId, articleCommentLikeReq, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * クエストのいいねを設定します。
         * @summary クエストいいねの設定
         * @param {ArticleLikeRequest} articleLikeRequest クエストいいね情報
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async setArticleLike(articleLikeRequest: ArticleLikeRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ArticleLike>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.setArticleLike(articleLikeRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 共有用のスキミーパーティ画像アップロードします。
         * @summary 共有用のスキミーパーティ画像アップロード
         * @param {Array<File>} [files] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async uploadSkimmyPartyImage(files?: Array<File>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UploadResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.uploadSkimmyPartyImage(files, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * PublicApi - factory interface
 * @export
 */
export const PublicApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PublicApiFp(configuration)
    return {
        /**
         * 公開ナレッジコメントを設定します。
         * @summary 公開ナレッジコメントの設定
         * @param {string} articleId 公開コンテンツID
         * @param {ArticleCommentReq} articleCommentReq 公開ナレッジコメント情報
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addArticleComment(articleId: string, articleCommentReq: ArticleCommentReq, options?: any): AxiosPromise<ArticleComment> {
            return localVarFp.addArticleComment(articleId, articleCommentReq, options).then((request) => request(axios, basePath));
        },
        /**
         * 公開ナレッジコメントを設定します。
         * @summary 公開ナレッジコメントの設定
         * @param {string} articleId 公開コンテンツID
         * @param {number} articleCommentId 公開ナレッジコメントID
         * @param {ArticleCommentReq} articleCommentReq 公開ナレッジコメント情報
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        delArticleComment(articleId: string, articleCommentId: number, articleCommentReq: ArticleCommentReq, options?: any): AxiosPromise<void> {
            return localVarFp.delArticleComment(articleId, articleCommentId, articleCommentReq, options).then((request) => request(axios, basePath));
        },
        /**
         * 公開ナレッジコメントを取得します。
         * @summary 公開ナレッジコメントの取得
         * @param {string} articleId 公開コンテンツID
         * @param {number} [articleCommentId] 返信元コメントID
         * @param {number} [page] ページ番号
         * @param {number} [perPage] 1ページあたりの要素数 (MAX100)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findArticleComments(articleId: string, articleCommentId?: number, page?: number, perPage?: number, options?: any): AxiosPromise<ArticleComments> {
            return localVarFp.findArticleComments(articleId, articleCommentId, page, perPage, options).then((request) => request(axios, basePath));
        },
        /**
         * ニュースフィードを取得します。
         * @summary ニュースフィードの取得
         * @param {string} skillType スキル種別
         * @param {number} [page] ページ番号
         * @param {number} [perPage] 1ページあたりの要素数 (MAX100)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findNewsFeeds(skillType: string, page?: number, perPage?: number, options?: any): AxiosPromise<Newsfeeds> {
            return localVarFp.findNewsFeeds(skillType, page, perPage, options).then((request) => request(axios, basePath));
        },
        /**
         * 公開コンテンツリストを取得します。
         * @summary 公開コンテンツリスト取得
         * @param {number} [page] ページ番号
         * @param {number} [perPage] 1ページあたりの要素数 (MAX100)
         * @param {string} [search] 検索キーワード
         * @param {string} [category] カテゴリー
         * @param {string} [teamname] チーム名
         * @param {string} [sort] ソート条件
         * @param {string} [username] ユーザ名
         * @param {boolean} [isPersonal] パーソナルとチームを切り分けるフラグ
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findPublicArticles(page?: number, perPage?: number, search?: string, category?: string, teamname?: string, sort?: string, username?: string, isPersonal?: boolean, options?: any): AxiosPromise<Articles> {
            return localVarFp.findPublicArticles(page, perPage, search, category, teamname, sort, username, isPersonal, options).then((request) => request(axios, basePath));
        },
        /**
         * 一般公開myWikiのリストを取得します。
         * @summary 一般公開myWikiリスト取得
         * @param {number} [page] ページ番号
         * @param {number} [perPage] 1ページあたりの要素数 (MAX100)
         * @param {string} [search] 検索キーワード
         * @param {number} [categoryId] カテゴリーID
         * @param {string} [sort] ソート条件
         * @param {string} [username] ユーザ名
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findPublicMyWikis(page?: number, perPage?: number, search?: string, categoryId?: number, sort?: string, username?: string, options?: any): AxiosPromise<Wikis> {
            return localVarFp.findPublicMyWikis(page, perPage, search, categoryId, sort, username, options).then((request) => request(axios, basePath));
        },
        /**
         * 公開ポートフォリオIdを取得します。
         * @summary 公開ポートフォリオId取得
         * @param {string} publicUrl 公開URL
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findPublicPortfolioId(publicUrl: string, options?: any): AxiosPromise<SharedPortfolioId> {
            return localVarFp.findPublicPortfolioId(publicUrl, options).then((request) => request(axios, basePath));
        },
        /**
         * 公開ポートフォリオリストを取得します。
         * @summary 公開ポートフォリオリスト取得
         * @param {number} [page] ページ番号
         * @param {number} [perPage] 1ページあたりの要素数 (MAX100)
         * @param {string} [username] ユーザ名
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findPublicPortfolios(page?: number, perPage?: number, username?: string, options?: any): AxiosPromise<SharedPortfolios> {
            return localVarFp.findPublicPortfolios(page, perPage, username, options).then((request) => request(axios, basePath));
        },
        /**
         * 公開しているチームのリストを取得します。
         * @summary 公開チームリストの取得
         * @param {number} [page] ページ番号
         * @param {number} [perPage] 1ページあたりの要素数 (MAX100)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findPublicTeams(page?: number, perPage?: number, options?: any): AxiosPromise<TeamDescriptions> {
            return localVarFp.findPublicTeams(page, perPage, options).then((request) => request(axios, basePath));
        },
        /**
         * 公開コンテンツを取得します。
         * @summary 公開コンテンツの取得
         * @param {string} articleId 公開コンテンツID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPublicArticle(articleId: string, options?: any): AxiosPromise<Article> {
            return localVarFp.getPublicArticle(articleId, options).then((request) => request(axios, basePath));
        },
        /**
         * 公開コンテンツをカテゴリ取得します。
         * @summary 公開コンテンツのカテゴリ取得
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPublicArticleCategories(options?: any): AxiosPromise<Categories> {
            return localVarFp.getPublicArticleCategories(options).then((request) => request(axios, basePath));
        },
        /**
         * 公開コンテンツを取得します。
         * @summary 公開コンテンツの取得（ログイン済）
         * @param {string} articleId 公開コンテンツID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPublicArticleLoggedIn(articleId: string, options?: any): AxiosPromise<Article> {
            return localVarFp.getPublicArticleLoggedIn(articleId, options).then((request) => request(axios, basePath));
        },
        /**
         * 公開しているチームを取得します。
         * @summary 公開チームの取得
         * @param {string} teamname チーム名
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPublicTeam(teamname: string, options?: any): AxiosPromise<TeamDescription> {
            return localVarFp.getPublicTeam(teamname, options).then((request) => request(axios, basePath));
        },
        /**
         * ユーザー公開コンテンツをカテゴリ取得します。
         * @summary ユーザー公開コンテンツのカテゴリ取得
         * @param {string} [username] ユーザ名
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserPublicArticleCategories(username?: string, options?: any): AxiosPromise<Categories> {
            return localVarFp.getUserPublicArticleCategories(username, options).then((request) => request(axios, basePath));
        },
        /**
         * 公開ナレッジコメントいいねを設定します。
         * @summary 公開ナレッジコメントいいねいいねの設定
         * @param {string} articleId 公開コンテンツID
         * @param {number} articleCommentId 公開ナレッジコメントID
         * @param {ArticleCommentLikeReq} articleCommentLikeReq 公開ナレッジコメントいいね情報
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        likeArticleComment(articleId: string, articleCommentId: number, articleCommentLikeReq: ArticleCommentLikeReq, options?: any): AxiosPromise<ArticleComment> {
            return localVarFp.likeArticleComment(articleId, articleCommentId, articleCommentLikeReq, options).then((request) => request(axios, basePath));
        },
        /**
         * クエストのいいねを設定します。
         * @summary クエストいいねの設定
         * @param {ArticleLikeRequest} articleLikeRequest クエストいいね情報
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setArticleLike(articleLikeRequest: ArticleLikeRequest, options?: any): AxiosPromise<ArticleLike> {
            return localVarFp.setArticleLike(articleLikeRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 共有用のスキミーパーティ画像アップロードします。
         * @summary 共有用のスキミーパーティ画像アップロード
         * @param {Array<File>} [files] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadSkimmyPartyImage(files?: Array<File>, options?: any): AxiosPromise<UploadResponse> {
            return localVarFp.uploadSkimmyPartyImage(files, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PublicApi - object-oriented interface
 * @export
 * @class PublicApi
 * @extends {BaseAPI}
 */
export class PublicApi extends BaseAPI {
    /**
     * 公開ナレッジコメントを設定します。
     * @summary 公開ナレッジコメントの設定
     * @param {string} articleId 公開コンテンツID
     * @param {ArticleCommentReq} articleCommentReq 公開ナレッジコメント情報
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PublicApi
     */
    public async addArticleComment(articleId: string, articleCommentReq: ArticleCommentReq, options?: AxiosRequestConfig) {
        const retry = 2;
        let resp;
        for (let i = 0; i < retry; i++) {
            resp = await PublicApiFp(this.configuration).addArticleComment(articleId, articleCommentReq, options).then((request) => request(this.axios, this.basePath)).catch((error) => {
                if (error?.response) {
                    return error.response;
                } else {
                    return { status: 500 };
                }
            });
            if (resp?.status === 401) {
                await refreshIdToken();
                console.debug('Retry addArticleComment ' + i + '/' + (retry - 1));
                continue;
            }
            break;
        }
        return resp;
    }

    /**
     * 公開ナレッジコメントを設定します。
     * @summary 公開ナレッジコメントの設定
     * @param {string} articleId 公開コンテンツID
     * @param {number} articleCommentId 公開ナレッジコメントID
     * @param {ArticleCommentReq} articleCommentReq 公開ナレッジコメント情報
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PublicApi
     */
    public async delArticleComment(articleId: string, articleCommentId: number, articleCommentReq: ArticleCommentReq, options?: AxiosRequestConfig) {
        const retry = 2;
        let resp;
        for (let i = 0; i < retry; i++) {
            resp = await PublicApiFp(this.configuration).delArticleComment(articleId, articleCommentId, articleCommentReq, options).then((request) => request(this.axios, this.basePath)).catch((error) => {
                if (error?.response) {
                    return error.response;
                } else {
                    return { status: 500 };
                }
            });
            if (resp?.status === 401) {
                await refreshIdToken();
                console.debug('Retry delArticleComment ' + i + '/' + (retry - 1));
                continue;
            }
            break;
        }
        return resp;
    }

    /**
     * 公開ナレッジコメントを取得します。
     * @summary 公開ナレッジコメントの取得
     * @param {string} articleId 公開コンテンツID
     * @param {number} [articleCommentId] 返信元コメントID
     * @param {number} [page] ページ番号
     * @param {number} [perPage] 1ページあたりの要素数 (MAX100)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PublicApi
     */
    public async findArticleComments(articleId: string, articleCommentId?: number, page?: number, perPage?: number, options?: AxiosRequestConfig) {
        const retry = 2;
        let resp;
        for (let i = 0; i < retry; i++) {
            resp = await PublicApiFp(this.configuration).findArticleComments(articleId, articleCommentId, page, perPage, options).then((request) => request(this.axios, this.basePath)).catch((error) => {
                if (error?.response) {
                    return error.response;
                } else {
                    return { status: 500 };
                }
            });
            if (resp?.status === 401) {
                await refreshIdToken();
                console.debug('Retry findArticleComments ' + i + '/' + (retry - 1));
                continue;
            }
            break;
        }
        return resp;
    }

    /**
     * ニュースフィードを取得します。
     * @summary ニュースフィードの取得
     * @param {string} skillType スキル種別
     * @param {number} [page] ページ番号
     * @param {number} [perPage] 1ページあたりの要素数 (MAX100)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PublicApi
     */
    public async findNewsFeeds(skillType: string, page?: number, perPage?: number, options?: AxiosRequestConfig) {
        const retry = 2;
        let resp;
        for (let i = 0; i < retry; i++) {
            resp = await PublicApiFp(this.configuration).findNewsFeeds(skillType, page, perPage, options).then((request) => request(this.axios, this.basePath)).catch((error) => {
                if (error?.response) {
                    return error.response;
                } else {
                    return { status: 500 };
                }
            });
            if (resp?.status === 401) {
                await refreshIdToken();
                console.debug('Retry findNewsFeeds ' + i + '/' + (retry - 1));
                continue;
            }
            break;
        }
        return resp;
    }

    /**
     * 公開コンテンツリストを取得します。
     * @summary 公開コンテンツリスト取得
     * @param {number} [page] ページ番号
     * @param {number} [perPage] 1ページあたりの要素数 (MAX100)
     * @param {string} [search] 検索キーワード
     * @param {string} [category] カテゴリー
     * @param {string} [teamname] チーム名
     * @param {string} [sort] ソート条件
     * @param {string} [username] ユーザ名
     * @param {boolean} [isPersonal] パーソナルとチームを切り分けるフラグ
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PublicApi
     */
    public async findPublicArticles(page?: number, perPage?: number, search?: string, category?: string, teamname?: string, sort?: string, username?: string, isPersonal?: boolean, options?: AxiosRequestConfig) {
        const retry = 2;
        let resp;
        for (let i = 0; i < retry; i++) {
            resp = await PublicApiFp(this.configuration).findPublicArticles(page, perPage, search, category, teamname, sort, username, isPersonal, options).then((request) => request(this.axios, this.basePath)).catch((error) => {
                if (error?.response) {
                    return error.response;
                } else {
                    return { status: 500 };
                }
            });
            if (resp?.status === 401) {
                await refreshIdToken();
                console.debug('Retry findPublicArticles ' + i + '/' + (retry - 1));
                continue;
            }
            break;
        }
        return resp;
    }

    /**
     * 一般公開myWikiのリストを取得します。
     * @summary 一般公開myWikiリスト取得
     * @param {number} [page] ページ番号
     * @param {number} [perPage] 1ページあたりの要素数 (MAX100)
     * @param {string} [search] 検索キーワード
     * @param {number} [categoryId] カテゴリーID
     * @param {string} [sort] ソート条件
     * @param {string} [username] ユーザ名
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PublicApi
     */
    public async findPublicMyWikis(page?: number, perPage?: number, search?: string, categoryId?: number, sort?: string, username?: string, options?: AxiosRequestConfig) {
        const retry = 2;
        let resp;
        for (let i = 0; i < retry; i++) {
            resp = await PublicApiFp(this.configuration).findPublicMyWikis(page, perPage, search, categoryId, sort, username, options).then((request) => request(this.axios, this.basePath)).catch((error) => {
                if (error?.response) {
                    return error.response;
                } else {
                    return { status: 500 };
                }
            });
            if (resp?.status === 401) {
                await refreshIdToken();
                console.debug('Retry findPublicMyWikis ' + i + '/' + (retry - 1));
                continue;
            }
            break;
        }
        return resp;
    }

    /**
     * 公開ポートフォリオIdを取得します。
     * @summary 公開ポートフォリオId取得
     * @param {string} publicUrl 公開URL
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PublicApi
     */
    public async findPublicPortfolioId(publicUrl: string, options?: AxiosRequestConfig) {
        const retry = 2;
        let resp;
        for (let i = 0; i < retry; i++) {
            resp = await PublicApiFp(this.configuration).findPublicPortfolioId(publicUrl, options).then((request) => request(this.axios, this.basePath)).catch((error) => {
                if (error?.response) {
                    return error.response;
                } else {
                    return { status: 500 };
                }
            });
            if (resp?.status === 401) {
                await refreshIdToken();
                console.debug('Retry findPublicPortfolioId ' + i + '/' + (retry - 1));
                continue;
            }
            break;
        }
        return resp;
    }

    /**
     * 公開ポートフォリオリストを取得します。
     * @summary 公開ポートフォリオリスト取得
     * @param {number} [page] ページ番号
     * @param {number} [perPage] 1ページあたりの要素数 (MAX100)
     * @param {string} [username] ユーザ名
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PublicApi
     */
    public async findPublicPortfolios(page?: number, perPage?: number, username?: string, options?: AxiosRequestConfig) {
        const retry = 2;
        let resp;
        for (let i = 0; i < retry; i++) {
            resp = await PublicApiFp(this.configuration).findPublicPortfolios(page, perPage, username, options).then((request) => request(this.axios, this.basePath)).catch((error) => {
                if (error?.response) {
                    return error.response;
                } else {
                    return { status: 500 };
                }
            });
            if (resp?.status === 401) {
                await refreshIdToken();
                console.debug('Retry findPublicPortfolios ' + i + '/' + (retry - 1));
                continue;
            }
            break;
        }
        return resp;
    }

    /**
     * 公開しているチームのリストを取得します。
     * @summary 公開チームリストの取得
     * @param {number} [page] ページ番号
     * @param {number} [perPage] 1ページあたりの要素数 (MAX100)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PublicApi
     */
    public async findPublicTeams(page?: number, perPage?: number, options?: AxiosRequestConfig) {
        const retry = 2;
        let resp;
        for (let i = 0; i < retry; i++) {
            resp = await PublicApiFp(this.configuration).findPublicTeams(page, perPage, options).then((request) => request(this.axios, this.basePath)).catch((error) => {
                if (error?.response) {
                    return error.response;
                } else {
                    return { status: 500 };
                }
            });
            if (resp?.status === 401) {
                await refreshIdToken();
                console.debug('Retry findPublicTeams ' + i + '/' + (retry - 1));
                continue;
            }
            break;
        }
        return resp;
    }

    /**
     * 公開コンテンツを取得します。
     * @summary 公開コンテンツの取得
     * @param {string} articleId 公開コンテンツID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PublicApi
     */
    public async getPublicArticle(articleId: string, options?: AxiosRequestConfig) {
        const retry = 2;
        let resp;
        for (let i = 0; i < retry; i++) {
            resp = await PublicApiFp(this.configuration).getPublicArticle(articleId, options).then((request) => request(this.axios, this.basePath)).catch((error) => {
                if (error?.response) {
                    return error.response;
                } else {
                    return { status: 500 };
                }
            });
            if (resp?.status === 401) {
                await refreshIdToken();
                console.debug('Retry getPublicArticle ' + i + '/' + (retry - 1));
                continue;
            }
            break;
        }
        return resp;
    }

    /**
     * 公開コンテンツをカテゴリ取得します。
     * @summary 公開コンテンツのカテゴリ取得
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PublicApi
     */
    public async getPublicArticleCategories(options?: AxiosRequestConfig) {
        const retry = 2;
        let resp;
        for (let i = 0; i < retry; i++) {
            resp = await PublicApiFp(this.configuration).getPublicArticleCategories(options).then((request) => request(this.axios, this.basePath)).catch((error) => {
                if (error?.response) {
                    return error.response;
                } else {
                    return { status: 500 };
                }
            });
            if (resp?.status === 401) {
                await refreshIdToken();
                console.debug('Retry getPublicArticleCategories ' + i + '/' + (retry - 1));
                continue;
            }
            break;
        }
        return resp;
    }

    /**
     * 公開コンテンツを取得します。
     * @summary 公開コンテンツの取得（ログイン済）
     * @param {string} articleId 公開コンテンツID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PublicApi
     */
    public async getPublicArticleLoggedIn(articleId: string, options?: AxiosRequestConfig) {
        const retry = 2;
        let resp;
        for (let i = 0; i < retry; i++) {
            resp = await PublicApiFp(this.configuration).getPublicArticleLoggedIn(articleId, options).then((request) => request(this.axios, this.basePath)).catch((error) => {
                if (error?.response) {
                    return error.response;
                } else {
                    return { status: 500 };
                }
            });
            if (resp?.status === 401) {
                await refreshIdToken();
                console.debug('Retry getPublicArticleLoggedIn ' + i + '/' + (retry - 1));
                continue;
            }
            break;
        }
        return resp;
    }

    /**
     * 公開しているチームを取得します。
     * @summary 公開チームの取得
     * @param {string} teamname チーム名
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PublicApi
     */
    public async getPublicTeam(teamname: string, options?: AxiosRequestConfig) {
        const retry = 2;
        let resp;
        for (let i = 0; i < retry; i++) {
            resp = await PublicApiFp(this.configuration).getPublicTeam(teamname, options).then((request) => request(this.axios, this.basePath)).catch((error) => {
                if (error?.response) {
                    return error.response;
                } else {
                    return { status: 500 };
                }
            });
            if (resp?.status === 401) {
                await refreshIdToken();
                console.debug('Retry getPublicTeam ' + i + '/' + (retry - 1));
                continue;
            }
            break;
        }
        return resp;
    }

    /**
     * ユーザー公開コンテンツをカテゴリ取得します。
     * @summary ユーザー公開コンテンツのカテゴリ取得
     * @param {string} [username] ユーザ名
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PublicApi
     */
    public async getUserPublicArticleCategories(username?: string, options?: AxiosRequestConfig) {
        const retry = 2;
        let resp;
        for (let i = 0; i < retry; i++) {
            resp = await PublicApiFp(this.configuration).getUserPublicArticleCategories(username, options).then((request) => request(this.axios, this.basePath)).catch((error) => {
                if (error?.response) {
                    return error.response;
                } else {
                    return { status: 500 };
                }
            });
            if (resp?.status === 401) {
                await refreshIdToken();
                console.debug('Retry getUserPublicArticleCategories ' + i + '/' + (retry - 1));
                continue;
            }
            break;
        }
        return resp;
    }

    /**
     * 公開ナレッジコメントいいねを設定します。
     * @summary 公開ナレッジコメントいいねいいねの設定
     * @param {string} articleId 公開コンテンツID
     * @param {number} articleCommentId 公開ナレッジコメントID
     * @param {ArticleCommentLikeReq} articleCommentLikeReq 公開ナレッジコメントいいね情報
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PublicApi
     */
    public async likeArticleComment(articleId: string, articleCommentId: number, articleCommentLikeReq: ArticleCommentLikeReq, options?: AxiosRequestConfig) {
        const retry = 2;
        let resp;
        for (let i = 0; i < retry; i++) {
            resp = await PublicApiFp(this.configuration).likeArticleComment(articleId, articleCommentId, articleCommentLikeReq, options).then((request) => request(this.axios, this.basePath)).catch((error) => {
                if (error?.response) {
                    return error.response;
                } else {
                    return { status: 500 };
                }
            });
            if (resp?.status === 401) {
                await refreshIdToken();
                console.debug('Retry likeArticleComment ' + i + '/' + (retry - 1));
                continue;
            }
            break;
        }
        return resp;
    }

    /**
     * クエストのいいねを設定します。
     * @summary クエストいいねの設定
     * @param {ArticleLikeRequest} articleLikeRequest クエストいいね情報
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PublicApi
     */
    public async setArticleLike(articleLikeRequest: ArticleLikeRequest, options?: AxiosRequestConfig) {
        const retry = 2;
        let resp;
        for (let i = 0; i < retry; i++) {
            resp = await PublicApiFp(this.configuration).setArticleLike(articleLikeRequest, options).then((request) => request(this.axios, this.basePath)).catch((error) => {
                if (error?.response) {
                    return error.response;
                } else {
                    return { status: 500 };
                }
            });
            if (resp?.status === 401) {
                await refreshIdToken();
                console.debug('Retry setArticleLike ' + i + '/' + (retry - 1));
                continue;
            }
            break;
        }
        return resp;
    }

    /**
     * 共有用のスキミーパーティ画像アップロードします。
     * @summary 共有用のスキミーパーティ画像アップロード
     * @param {Array<File>} [files] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PublicApi
     */
    public async uploadSkimmyPartyImage(files?: Array<File>, options?: AxiosRequestConfig) {
        const retry = 2;
        let resp;
        for (let i = 0; i < retry; i++) {
            resp = await PublicApiFp(this.configuration).uploadSkimmyPartyImage(files, options).then((request) => request(this.axios, this.basePath)).catch((error) => {
                if (error?.response) {
                    return error.response;
                } else {
                    return { status: 500 };
                }
            });
            if (resp?.status === 401) {
                await refreshIdToken();
                console.debug('Retry uploadSkimmyPartyImage ' + i + '/' + (retry - 1));
                continue;
            }
            break;
        }
        return resp;
    }
}


/**
 * PublicProfileApi - axios parameter creator
 * @export
 */
export const PublicProfileApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 公開プロフィールのハッシュタグサジェスト一覧を取得します。
         * @summary 公開プロフィールのハッシュタグサジェスト一覧の取得
         * @param {string} [search] 検索キーワード
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findHashtagSuggests: async (search?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/publicprofile/hashtag/suggests`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 公開プロフィールを取得します。
         * @summary 公開プロフィールの取得
         * @param {string} publicProfileUsername 公開プロフィールのユーザ名
         * @param {number} publicProfileType 公開プロフィール種別
         * @param {string} [username] ユーザ名
         * @param {string} [teamname] チーム名
         * @param {boolean} [isEdit] 編集か取得を切り分けるフラグ
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPublicProfile: async (publicProfileUsername: string, publicProfileType: number, username?: string, teamname?: string, isEdit?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'publicProfileUsername' is not null or undefined
            assertParamExists('getPublicProfile', 'publicProfileUsername', publicProfileUsername)
            // verify required parameter 'publicProfileType' is not null or undefined
            assertParamExists('getPublicProfile', 'publicProfileType', publicProfileType)
            const localVarPath = `/publicprofile/profile/get/{public_profile_username}`
                .replace(`{${"public_profile_username"}}`, encodeURIComponent(String(publicProfileUsername)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (username !== undefined) {
                localVarQueryParameter['username'] = username;
            }

            if (teamname !== undefined) {
                localVarQueryParameter['teamname'] = teamname;
            }

            if (isEdit !== undefined) {
                localVarQueryParameter['is_edit'] = isEdit;
            }

            if (publicProfileType !== undefined) {
                localVarQueryParameter['public_profile_type'] = publicProfileType;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 公開プロフィールを検索します。
         * @summary 公開プロフィール検索
         * @param {PublicProfileSearchRequest} publicProfileSearchRequest 公開プロフィール検索情報
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchPublicProfiles: async (publicProfileSearchRequest: PublicProfileSearchRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'publicProfileSearchRequest' is not null or undefined
            assertParamExists('searchPublicProfiles', 'publicProfileSearchRequest', publicProfileSearchRequest)
            const localVarPath = `/publicprofile/search`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(publicProfileSearchRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 公開プロフィールを更新します。
         * @summary 公開プロフィール更新
         * @param {PublicProfileRequest} publicProfileRequest 公開プロフィール情報
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setPublicProfile: async (publicProfileRequest: PublicProfileRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'publicProfileRequest' is not null or undefined
            assertParamExists('setPublicProfile', 'publicProfileRequest', publicProfileRequest)
            const localVarPath = `/publicprofile/profile`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(publicProfileRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PublicProfileApi - functional programming interface
 * @export
 */
export const PublicProfileApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PublicProfileApiAxiosParamCreator(configuration)
    return {
        /**
         * 公開プロフィールのハッシュタグサジェスト一覧を取得します。
         * @summary 公開プロフィールのハッシュタグサジェスト一覧の取得
         * @param {string} [search] 検索キーワード
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findHashtagSuggests(search?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<HashtagSuggests>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findHashtagSuggests(search, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 公開プロフィールを取得します。
         * @summary 公開プロフィールの取得
         * @param {string} publicProfileUsername 公開プロフィールのユーザ名
         * @param {number} publicProfileType 公開プロフィール種別
         * @param {string} [username] ユーザ名
         * @param {string} [teamname] チーム名
         * @param {boolean} [isEdit] 編集か取得を切り分けるフラグ
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPublicProfile(publicProfileUsername: string, publicProfileType: number, username?: string, teamname?: string, isEdit?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PublicProfile>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPublicProfile(publicProfileUsername, publicProfileType, username, teamname, isEdit, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 公開プロフィールを検索します。
         * @summary 公開プロフィール検索
         * @param {PublicProfileSearchRequest} publicProfileSearchRequest 公開プロフィール検索情報
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async searchPublicProfiles(publicProfileSearchRequest: PublicProfileSearchRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PublicProfileSearches>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.searchPublicProfiles(publicProfileSearchRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 公開プロフィールを更新します。
         * @summary 公開プロフィール更新
         * @param {PublicProfileRequest} publicProfileRequest 公開プロフィール情報
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async setPublicProfile(publicProfileRequest: PublicProfileRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.setPublicProfile(publicProfileRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * PublicProfileApi - factory interface
 * @export
 */
export const PublicProfileApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PublicProfileApiFp(configuration)
    return {
        /**
         * 公開プロフィールのハッシュタグサジェスト一覧を取得します。
         * @summary 公開プロフィールのハッシュタグサジェスト一覧の取得
         * @param {string} [search] 検索キーワード
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findHashtagSuggests(search?: string, options?: any): AxiosPromise<HashtagSuggests> {
            return localVarFp.findHashtagSuggests(search, options).then((request) => request(axios, basePath));
        },
        /**
         * 公開プロフィールを取得します。
         * @summary 公開プロフィールの取得
         * @param {string} publicProfileUsername 公開プロフィールのユーザ名
         * @param {number} publicProfileType 公開プロフィール種別
         * @param {string} [username] ユーザ名
         * @param {string} [teamname] チーム名
         * @param {boolean} [isEdit] 編集か取得を切り分けるフラグ
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPublicProfile(publicProfileUsername: string, publicProfileType: number, username?: string, teamname?: string, isEdit?: boolean, options?: any): AxiosPromise<PublicProfile> {
            return localVarFp.getPublicProfile(publicProfileUsername, publicProfileType, username, teamname, isEdit, options).then((request) => request(axios, basePath));
        },
        /**
         * 公開プロフィールを検索します。
         * @summary 公開プロフィール検索
         * @param {PublicProfileSearchRequest} publicProfileSearchRequest 公開プロフィール検索情報
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchPublicProfiles(publicProfileSearchRequest: PublicProfileSearchRequest, options?: any): AxiosPromise<PublicProfileSearches> {
            return localVarFp.searchPublicProfiles(publicProfileSearchRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 公開プロフィールを更新します。
         * @summary 公開プロフィール更新
         * @param {PublicProfileRequest} publicProfileRequest 公開プロフィール情報
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setPublicProfile(publicProfileRequest: PublicProfileRequest, options?: any): AxiosPromise<void> {
            return localVarFp.setPublicProfile(publicProfileRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PublicProfileApi - object-oriented interface
 * @export
 * @class PublicProfileApi
 * @extends {BaseAPI}
 */
export class PublicProfileApi extends BaseAPI {
    /**
     * 公開プロフィールのハッシュタグサジェスト一覧を取得します。
     * @summary 公開プロフィールのハッシュタグサジェスト一覧の取得
     * @param {string} [search] 検索キーワード
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PublicProfileApi
     */
    public async findHashtagSuggests(search?: string, options?: AxiosRequestConfig) {
        const retry = 2;
        let resp;
        for (let i = 0; i < retry; i++) {
            resp = await PublicProfileApiFp(this.configuration).findHashtagSuggests(search, options).then((request) => request(this.axios, this.basePath)).catch((error) => {
                if (error?.response) {
                    return error.response;
                } else {
                    return { status: 500 };
                }
            });
            if (resp?.status === 401) {
                await refreshIdToken();
                console.debug('Retry findHashtagSuggests ' + i + '/' + (retry - 1));
                continue;
            }
            break;
        }
        return resp;
    }

    /**
     * 公開プロフィールを取得します。
     * @summary 公開プロフィールの取得
     * @param {string} publicProfileUsername 公開プロフィールのユーザ名
     * @param {number} publicProfileType 公開プロフィール種別
     * @param {string} [username] ユーザ名
     * @param {string} [teamname] チーム名
     * @param {boolean} [isEdit] 編集か取得を切り分けるフラグ
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PublicProfileApi
     */
    public async getPublicProfile(publicProfileUsername: string, publicProfileType: number, username?: string, teamname?: string, isEdit?: boolean, options?: AxiosRequestConfig) {
        const retry = 2;
        let resp;
        for (let i = 0; i < retry; i++) {
            resp = await PublicProfileApiFp(this.configuration).getPublicProfile(publicProfileUsername, publicProfileType, username, teamname, isEdit, options).then((request) => request(this.axios, this.basePath)).catch((error) => {
                if (error?.response) {
                    return error.response;
                } else {
                    return { status: 500 };
                }
            });
            if (resp?.status === 401) {
                await refreshIdToken();
                console.debug('Retry getPublicProfile ' + i + '/' + (retry - 1));
                continue;
            }
            break;
        }
        return resp;
    }

    /**
     * 公開プロフィールを検索します。
     * @summary 公開プロフィール検索
     * @param {PublicProfileSearchRequest} publicProfileSearchRequest 公開プロフィール検索情報
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PublicProfileApi
     */
    public async searchPublicProfiles(publicProfileSearchRequest: PublicProfileSearchRequest, options?: AxiosRequestConfig) {
        const retry = 2;
        let resp;
        for (let i = 0; i < retry; i++) {
            resp = await PublicProfileApiFp(this.configuration).searchPublicProfiles(publicProfileSearchRequest, options).then((request) => request(this.axios, this.basePath)).catch((error) => {
                if (error?.response) {
                    return error.response;
                } else {
                    return { status: 500 };
                }
            });
            if (resp?.status === 401) {
                await refreshIdToken();
                console.debug('Retry searchPublicProfiles ' + i + '/' + (retry - 1));
                continue;
            }
            break;
        }
        return resp;
    }

    /**
     * 公開プロフィールを更新します。
     * @summary 公開プロフィール更新
     * @param {PublicProfileRequest} publicProfileRequest 公開プロフィール情報
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PublicProfileApi
     */
    public async setPublicProfile(publicProfileRequest: PublicProfileRequest, options?: AxiosRequestConfig) {
        const retry = 2;
        let resp;
        for (let i = 0; i < retry; i++) {
            resp = await PublicProfileApiFp(this.configuration).setPublicProfile(publicProfileRequest, options).then((request) => request(this.axios, this.basePath)).catch((error) => {
                if (error?.response) {
                    return error.response;
                } else {
                    return { status: 500 };
                }
            });
            if (resp?.status === 401) {
                await refreshIdToken();
                console.debug('Retry setPublicProfile ' + i + '/' + (retry - 1));
                continue;
            }
            break;
        }
        return resp;
    }
}


/**
 * QuestApi - axios parameter creator
 * @export
 */
export const QuestApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * クエストを削除します。
         * @summary クエスト削除
         * @param {number} questId クエストID
         * @param {QuestEditRequest} questEditRequest 削除クエスト情報
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteQuest: async (questId: number, questEditRequest: QuestEditRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'questId' is not null or undefined
            assertParamExists('deleteQuest', 'questId', questId)
            // verify required parameter 'questEditRequest' is not null or undefined
            assertParamExists('deleteQuest', 'questEditRequest', questEditRequest)
            const localVarPath = `/quests/delete/{quest_id}`
                .replace(`{${"quest_id"}}`, encodeURIComponent(String(questId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(questEditRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 挑戦するクエストを取得します。
         * @summary 挑戦クエストの取得
         * @param {number} questId クエストID
         * @param {QuestChallengeRequest} questChallengeRequest クエスト挑戦情報
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getChallengeQuest: async (questId: number, questChallengeRequest: QuestChallengeRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'questId' is not null or undefined
            assertParamExists('getChallengeQuest', 'questId', questId)
            // verify required parameter 'questChallengeRequest' is not null or undefined
            assertParamExists('getChallengeQuest', 'questChallengeRequest', questChallengeRequest)
            const localVarPath = `/quests/challenge/{quest_id}`
                .replace(`{${"quest_id"}}`, encodeURIComponent(String(questId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(questChallengeRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 修正するクエストを取得します。
         * @summary 修正クエストの取得
         * @param {number} questId クエストID
         * @param {QuestEditRequest} questEditRequest 修正クエスト情報
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEditQuest: async (questId: number, questEditRequest: QuestEditRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'questId' is not null or undefined
            assertParamExists('getEditQuest', 'questId', questId)
            // verify required parameter 'questEditRequest' is not null or undefined
            assertParamExists('getEditQuest', 'questEditRequest', questEditRequest)
            const localVarPath = `/quests/edit/{quest_id}`
                .replace(`{${"quest_id"}}`, encodeURIComponent(String(questId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(questEditRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 公開クエストメタ情報を取得します。
         * @summary 公開クエストメタ情報の取得
         * @param {string} articleId 公開コンテンツID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPublicQuestMeta: async (articleId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'articleId' is not null or undefined
            assertParamExists('getPublicQuestMeta', 'articleId', articleId)
            const localVarPath = `/quests/public/{article_id}`
                .replace(`{${"article_id"}}`, encodeURIComponent(String(articleId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * クエスト挑戦履歴を取得します。
         * @summary クエスト挑戦履歴の取得
         * @param {number} questHistoryId クエスト挑戦履歴ID
         * @param {QuestHistoryRequest} questHistoryRequest クエストヒストリー情報
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getQuestChallengeHistory: async (questHistoryId: number, questHistoryRequest: QuestHistoryRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'questHistoryId' is not null or undefined
            assertParamExists('getQuestChallengeHistory', 'questHistoryId', questHistoryId)
            // verify required parameter 'questHistoryRequest' is not null or undefined
            assertParamExists('getQuestChallengeHistory', 'questHistoryRequest', questHistoryRequest)
            const localVarPath = `/quests/history/{quest_history_id}`
                .replace(`{${"quest_history_id"}}`, encodeURIComponent(String(questHistoryId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(questHistoryRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * チームのクエスト一覧を取得します。
         * @summary クエスト一覧の取得
         * @param {QuestListRequest} questListRequest クエストリスト情報
         * @param {number} [offset] オフセット値
         * @param {number} [limit] 上限値
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTeamQuestsList: async (questListRequest: QuestListRequest, offset?: number, limit?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'questListRequest' is not null or undefined
            assertParamExists('getTeamQuestsList', 'questListRequest', questListRequest)
            const localVarPath = `/quests/list`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(questListRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * ユーザーのチームにおけるクエスト挑戦履歴一覧を取得します。
         * @summary クエスト挑戦履歴一覧の取得
         * @param {QuestHistoryListRequest} questHistoryListRequest クエストヒストリー情報
         * @param {number} [offset] オフセット値
         * @param {number} [limit] 上限値
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserTeamQuestHistoriesList: async (questHistoryListRequest: QuestHistoryListRequest, offset?: number, limit?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'questHistoryListRequest' is not null or undefined
            assertParamExists('getUserTeamQuestHistoriesList', 'questHistoryListRequest', questHistoryListRequest)
            const localVarPath = `/quests/histories`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(questHistoryListRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * クエストのいいねを設定します。
         * @summary クエストいいねの設定
         * @param {QuestLikeRequest} questLikeRequest クエストいいね情報
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setQuestLike: async (questLikeRequest: QuestLikeRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'questLikeRequest' is not null or undefined
            assertParamExists('setQuestLike', 'questLikeRequest', questLikeRequest)
            const localVarPath = `/quests/like`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(questLikeRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * チームのクエストを登録します。
         * @summary クエスト登録
         * @param {QuestRequest} questRequest クエスト情報
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setTeamQuest: async (questRequest: QuestRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'questRequest' is not null or undefined
            assertParamExists('setTeamQuest', 'questRequest', questRequest)
            const localVarPath = `/quests`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(questRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 挑戦クエストを提出します。
         * @summary 挑戦クエスト提出
         * @param {number} questId クエストID
         * @param {QuestRequest} questRequest 挑戦クエスト情報
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        submitChallengeQuest: async (questId: number, questRequest: QuestRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'questId' is not null or undefined
            assertParamExists('submitChallengeQuest', 'questId', questId)
            // verify required parameter 'questRequest' is not null or undefined
            assertParamExists('submitChallengeQuest', 'questRequest', questRequest)
            const localVarPath = `/quests/challenge/submit/{quest_id}`
                .replace(`{${"quest_id"}}`, encodeURIComponent(String(questId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(questRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * クエスト公開状態を変更します。
         * @summary クエスト公開状態変更
         * @param {number} questId クエストID
         * @param {QuestPublicStatusRequest} questPublicStatusRequest クエスト公開状態情報
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        switchQuestPublicStatus: async (questId: number, questPublicStatusRequest: QuestPublicStatusRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'questId' is not null or undefined
            assertParamExists('switchQuestPublicStatus', 'questId', questId)
            // verify required parameter 'questPublicStatusRequest' is not null or undefined
            assertParamExists('switchQuestPublicStatus', 'questPublicStatusRequest', questPublicStatusRequest)
            const localVarPath = `/quests/public/status/{quest_id}`
                .replace(`{${"quest_id"}}`, encodeURIComponent(String(questId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(questPublicStatusRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * QuestApi - functional programming interface
 * @export
 */
export const QuestApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = QuestApiAxiosParamCreator(configuration)
    return {
        /**
         * クエストを削除します。
         * @summary クエスト削除
         * @param {number} questId クエストID
         * @param {QuestEditRequest} questEditRequest 削除クエスト情報
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteQuest(questId: number, questEditRequest: QuestEditRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteQuest(questId, questEditRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 挑戦するクエストを取得します。
         * @summary 挑戦クエストの取得
         * @param {number} questId クエストID
         * @param {QuestChallengeRequest} questChallengeRequest クエスト挑戦情報
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getChallengeQuest(questId: number, questChallengeRequest: QuestChallengeRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Quest>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getChallengeQuest(questId, questChallengeRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 修正するクエストを取得します。
         * @summary 修正クエストの取得
         * @param {number} questId クエストID
         * @param {QuestEditRequest} questEditRequest 修正クエスト情報
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getEditQuest(questId: number, questEditRequest: QuestEditRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Quest>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getEditQuest(questId, questEditRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 公開クエストメタ情報を取得します。
         * @summary 公開クエストメタ情報の取得
         * @param {string} articleId 公開コンテンツID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPublicQuestMeta(articleId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<QuestMeta>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPublicQuestMeta(articleId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * クエスト挑戦履歴を取得します。
         * @summary クエスト挑戦履歴の取得
         * @param {number} questHistoryId クエスト挑戦履歴ID
         * @param {QuestHistoryRequest} questHistoryRequest クエストヒストリー情報
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getQuestChallengeHistory(questHistoryId: number, questHistoryRequest: QuestHistoryRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Quest>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getQuestChallengeHistory(questHistoryId, questHistoryRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * チームのクエスト一覧を取得します。
         * @summary クエスト一覧の取得
         * @param {QuestListRequest} questListRequest クエストリスト情報
         * @param {number} [offset] オフセット値
         * @param {number} [limit] 上限値
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTeamQuestsList(questListRequest: QuestListRequest, offset?: number, limit?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<QuestList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTeamQuestsList(questListRequest, offset, limit, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * ユーザーのチームにおけるクエスト挑戦履歴一覧を取得します。
         * @summary クエスト挑戦履歴一覧の取得
         * @param {QuestHistoryListRequest} questHistoryListRequest クエストヒストリー情報
         * @param {number} [offset] オフセット値
         * @param {number} [limit] 上限値
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUserTeamQuestHistoriesList(questHistoryListRequest: QuestHistoryListRequest, offset?: number, limit?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<QuestList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUserTeamQuestHistoriesList(questHistoryListRequest, offset, limit, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * クエストのいいねを設定します。
         * @summary クエストいいねの設定
         * @param {QuestLikeRequest} questLikeRequest クエストいいね情報
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async setQuestLike(questLikeRequest: QuestLikeRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<QuestLike>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.setQuestLike(questLikeRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * チームのクエストを登録します。
         * @summary クエスト登録
         * @param {QuestRequest} questRequest クエスト情報
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async setTeamQuest(questRequest: QuestRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<QuestListItem>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.setTeamQuest(questRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 挑戦クエストを提出します。
         * @summary 挑戦クエスト提出
         * @param {number} questId クエストID
         * @param {QuestRequest} questRequest 挑戦クエスト情報
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async submitChallengeQuest(questId: number, questRequest: QuestRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Quest>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.submitChallengeQuest(questId, questRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * クエスト公開状態を変更します。
         * @summary クエスト公開状態変更
         * @param {number} questId クエストID
         * @param {QuestPublicStatusRequest} questPublicStatusRequest クエスト公開状態情報
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async switchQuestPublicStatus(questId: number, questPublicStatusRequest: QuestPublicStatusRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.switchQuestPublicStatus(questId, questPublicStatusRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * QuestApi - factory interface
 * @export
 */
export const QuestApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = QuestApiFp(configuration)
    return {
        /**
         * クエストを削除します。
         * @summary クエスト削除
         * @param {number} questId クエストID
         * @param {QuestEditRequest} questEditRequest 削除クエスト情報
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteQuest(questId: number, questEditRequest: QuestEditRequest, options?: any): AxiosPromise<void> {
            return localVarFp.deleteQuest(questId, questEditRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 挑戦するクエストを取得します。
         * @summary 挑戦クエストの取得
         * @param {number} questId クエストID
         * @param {QuestChallengeRequest} questChallengeRequest クエスト挑戦情報
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getChallengeQuest(questId: number, questChallengeRequest: QuestChallengeRequest, options?: any): AxiosPromise<Quest> {
            return localVarFp.getChallengeQuest(questId, questChallengeRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 修正するクエストを取得します。
         * @summary 修正クエストの取得
         * @param {number} questId クエストID
         * @param {QuestEditRequest} questEditRequest 修正クエスト情報
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEditQuest(questId: number, questEditRequest: QuestEditRequest, options?: any): AxiosPromise<Quest> {
            return localVarFp.getEditQuest(questId, questEditRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 公開クエストメタ情報を取得します。
         * @summary 公開クエストメタ情報の取得
         * @param {string} articleId 公開コンテンツID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPublicQuestMeta(articleId: string, options?: any): AxiosPromise<QuestMeta> {
            return localVarFp.getPublicQuestMeta(articleId, options).then((request) => request(axios, basePath));
        },
        /**
         * クエスト挑戦履歴を取得します。
         * @summary クエスト挑戦履歴の取得
         * @param {number} questHistoryId クエスト挑戦履歴ID
         * @param {QuestHistoryRequest} questHistoryRequest クエストヒストリー情報
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getQuestChallengeHistory(questHistoryId: number, questHistoryRequest: QuestHistoryRequest, options?: any): AxiosPromise<Quest> {
            return localVarFp.getQuestChallengeHistory(questHistoryId, questHistoryRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * チームのクエスト一覧を取得します。
         * @summary クエスト一覧の取得
         * @param {QuestListRequest} questListRequest クエストリスト情報
         * @param {number} [offset] オフセット値
         * @param {number} [limit] 上限値
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTeamQuestsList(questListRequest: QuestListRequest, offset?: number, limit?: number, options?: any): AxiosPromise<QuestList> {
            return localVarFp.getTeamQuestsList(questListRequest, offset, limit, options).then((request) => request(axios, basePath));
        },
        /**
         * ユーザーのチームにおけるクエスト挑戦履歴一覧を取得します。
         * @summary クエスト挑戦履歴一覧の取得
         * @param {QuestHistoryListRequest} questHistoryListRequest クエストヒストリー情報
         * @param {number} [offset] オフセット値
         * @param {number} [limit] 上限値
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserTeamQuestHistoriesList(questHistoryListRequest: QuestHistoryListRequest, offset?: number, limit?: number, options?: any): AxiosPromise<QuestList> {
            return localVarFp.getUserTeamQuestHistoriesList(questHistoryListRequest, offset, limit, options).then((request) => request(axios, basePath));
        },
        /**
         * クエストのいいねを設定します。
         * @summary クエストいいねの設定
         * @param {QuestLikeRequest} questLikeRequest クエストいいね情報
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setQuestLike(questLikeRequest: QuestLikeRequest, options?: any): AxiosPromise<QuestLike> {
            return localVarFp.setQuestLike(questLikeRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * チームのクエストを登録します。
         * @summary クエスト登録
         * @param {QuestRequest} questRequest クエスト情報
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setTeamQuest(questRequest: QuestRequest, options?: any): AxiosPromise<QuestListItem> {
            return localVarFp.setTeamQuest(questRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 挑戦クエストを提出します。
         * @summary 挑戦クエスト提出
         * @param {number} questId クエストID
         * @param {QuestRequest} questRequest 挑戦クエスト情報
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        submitChallengeQuest(questId: number, questRequest: QuestRequest, options?: any): AxiosPromise<Quest> {
            return localVarFp.submitChallengeQuest(questId, questRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * クエスト公開状態を変更します。
         * @summary クエスト公開状態変更
         * @param {number} questId クエストID
         * @param {QuestPublicStatusRequest} questPublicStatusRequest クエスト公開状態情報
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        switchQuestPublicStatus(questId: number, questPublicStatusRequest: QuestPublicStatusRequest, options?: any): AxiosPromise<void> {
            return localVarFp.switchQuestPublicStatus(questId, questPublicStatusRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * QuestApi - object-oriented interface
 * @export
 * @class QuestApi
 * @extends {BaseAPI}
 */
export class QuestApi extends BaseAPI {
    /**
     * クエストを削除します。
     * @summary クエスト削除
     * @param {number} questId クエストID
     * @param {QuestEditRequest} questEditRequest 削除クエスト情報
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof QuestApi
     */
    public async deleteQuest(questId: number, questEditRequest: QuestEditRequest, options?: AxiosRequestConfig) {
        const retry = 2;
        let resp;
        for (let i = 0; i < retry; i++) {
            resp = await QuestApiFp(this.configuration).deleteQuest(questId, questEditRequest, options).then((request) => request(this.axios, this.basePath)).catch((error) => {
                if (error?.response) {
                    return error.response;
                } else {
                    return { status: 500 };
                }
            });
            if (resp?.status === 401) {
                await refreshIdToken();
                console.debug('Retry deleteQuest ' + i + '/' + (retry - 1));
                continue;
            }
            break;
        }
        return resp;
    }

    /**
     * 挑戦するクエストを取得します。
     * @summary 挑戦クエストの取得
     * @param {number} questId クエストID
     * @param {QuestChallengeRequest} questChallengeRequest クエスト挑戦情報
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof QuestApi
     */
    public async getChallengeQuest(questId: number, questChallengeRequest: QuestChallengeRequest, options?: AxiosRequestConfig) {
        const retry = 2;
        let resp;
        for (let i = 0; i < retry; i++) {
            resp = await QuestApiFp(this.configuration).getChallengeQuest(questId, questChallengeRequest, options).then((request) => request(this.axios, this.basePath)).catch((error) => {
                if (error?.response) {
                    return error.response;
                } else {
                    return { status: 500 };
                }
            });
            if (resp?.status === 401) {
                await refreshIdToken();
                console.debug('Retry getChallengeQuest ' + i + '/' + (retry - 1));
                continue;
            }
            break;
        }
        return resp;
    }

    /**
     * 修正するクエストを取得します。
     * @summary 修正クエストの取得
     * @param {number} questId クエストID
     * @param {QuestEditRequest} questEditRequest 修正クエスト情報
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof QuestApi
     */
    public async getEditQuest(questId: number, questEditRequest: QuestEditRequest, options?: AxiosRequestConfig) {
        const retry = 2;
        let resp;
        for (let i = 0; i < retry; i++) {
            resp = await QuestApiFp(this.configuration).getEditQuest(questId, questEditRequest, options).then((request) => request(this.axios, this.basePath)).catch((error) => {
                if (error?.response) {
                    return error.response;
                } else {
                    return { status: 500 };
                }
            });
            if (resp?.status === 401) {
                await refreshIdToken();
                console.debug('Retry getEditQuest ' + i + '/' + (retry - 1));
                continue;
            }
            break;
        }
        return resp;
    }

    /**
     * 公開クエストメタ情報を取得します。
     * @summary 公開クエストメタ情報の取得
     * @param {string} articleId 公開コンテンツID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof QuestApi
     */
    public async getPublicQuestMeta(articleId: string, options?: AxiosRequestConfig) {
        const retry = 2;
        let resp;
        for (let i = 0; i < retry; i++) {
            resp = await QuestApiFp(this.configuration).getPublicQuestMeta(articleId, options).then((request) => request(this.axios, this.basePath)).catch((error) => {
                if (error?.response) {
                    return error.response;
                } else {
                    return { status: 500 };
                }
            });
            if (resp?.status === 401) {
                await refreshIdToken();
                console.debug('Retry getPublicQuestMeta ' + i + '/' + (retry - 1));
                continue;
            }
            break;
        }
        return resp;
    }

    /**
     * クエスト挑戦履歴を取得します。
     * @summary クエスト挑戦履歴の取得
     * @param {number} questHistoryId クエスト挑戦履歴ID
     * @param {QuestHistoryRequest} questHistoryRequest クエストヒストリー情報
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof QuestApi
     */
    public async getQuestChallengeHistory(questHistoryId: number, questHistoryRequest: QuestHistoryRequest, options?: AxiosRequestConfig) {
        const retry = 2;
        let resp;
        for (let i = 0; i < retry; i++) {
            resp = await QuestApiFp(this.configuration).getQuestChallengeHistory(questHistoryId, questHistoryRequest, options).then((request) => request(this.axios, this.basePath)).catch((error) => {
                if (error?.response) {
                    return error.response;
                } else {
                    return { status: 500 };
                }
            });
            if (resp?.status === 401) {
                await refreshIdToken();
                console.debug('Retry getQuestChallengeHistory ' + i + '/' + (retry - 1));
                continue;
            }
            break;
        }
        return resp;
    }

    /**
     * チームのクエスト一覧を取得します。
     * @summary クエスト一覧の取得
     * @param {QuestListRequest} questListRequest クエストリスト情報
     * @param {number} [offset] オフセット値
     * @param {number} [limit] 上限値
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof QuestApi
     */
    public async getTeamQuestsList(questListRequest: QuestListRequest, offset?: number, limit?: number, options?: AxiosRequestConfig) {
        const retry = 2;
        let resp;
        for (let i = 0; i < retry; i++) {
            resp = await QuestApiFp(this.configuration).getTeamQuestsList(questListRequest, offset, limit, options).then((request) => request(this.axios, this.basePath)).catch((error) => {
                if (error?.response) {
                    return error.response;
                } else {
                    return { status: 500 };
                }
            });
            if (resp?.status === 401) {
                await refreshIdToken();
                console.debug('Retry getTeamQuestsList ' + i + '/' + (retry - 1));
                continue;
            }
            break;
        }
        return resp;
    }

    /**
     * ユーザーのチームにおけるクエスト挑戦履歴一覧を取得します。
     * @summary クエスト挑戦履歴一覧の取得
     * @param {QuestHistoryListRequest} questHistoryListRequest クエストヒストリー情報
     * @param {number} [offset] オフセット値
     * @param {number} [limit] 上限値
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof QuestApi
     */
    public async getUserTeamQuestHistoriesList(questHistoryListRequest: QuestHistoryListRequest, offset?: number, limit?: number, options?: AxiosRequestConfig) {
        const retry = 2;
        let resp;
        for (let i = 0; i < retry; i++) {
            resp = await QuestApiFp(this.configuration).getUserTeamQuestHistoriesList(questHistoryListRequest, offset, limit, options).then((request) => request(this.axios, this.basePath)).catch((error) => {
                if (error?.response) {
                    return error.response;
                } else {
                    return { status: 500 };
                }
            });
            if (resp?.status === 401) {
                await refreshIdToken();
                console.debug('Retry getUserTeamQuestHistoriesList ' + i + '/' + (retry - 1));
                continue;
            }
            break;
        }
        return resp;
    }

    /**
     * クエストのいいねを設定します。
     * @summary クエストいいねの設定
     * @param {QuestLikeRequest} questLikeRequest クエストいいね情報
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof QuestApi
     */
    public async setQuestLike(questLikeRequest: QuestLikeRequest, options?: AxiosRequestConfig) {
        const retry = 2;
        let resp;
        for (let i = 0; i < retry; i++) {
            resp = await QuestApiFp(this.configuration).setQuestLike(questLikeRequest, options).then((request) => request(this.axios, this.basePath)).catch((error) => {
                if (error?.response) {
                    return error.response;
                } else {
                    return { status: 500 };
                }
            });
            if (resp?.status === 401) {
                await refreshIdToken();
                console.debug('Retry setQuestLike ' + i + '/' + (retry - 1));
                continue;
            }
            break;
        }
        return resp;
    }

    /**
     * チームのクエストを登録します。
     * @summary クエスト登録
     * @param {QuestRequest} questRequest クエスト情報
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof QuestApi
     */
    public async setTeamQuest(questRequest: QuestRequest, options?: AxiosRequestConfig) {
        const retry = 2;
        let resp;
        for (let i = 0; i < retry; i++) {
            resp = await QuestApiFp(this.configuration).setTeamQuest(questRequest, options).then((request) => request(this.axios, this.basePath)).catch((error) => {
                if (error?.response) {
                    return error.response;
                } else {
                    return { status: 500 };
                }
            });
            if (resp?.status === 401) {
                await refreshIdToken();
                console.debug('Retry setTeamQuest ' + i + '/' + (retry - 1));
                continue;
            }
            break;
        }
        return resp;
    }

    /**
     * 挑戦クエストを提出します。
     * @summary 挑戦クエスト提出
     * @param {number} questId クエストID
     * @param {QuestRequest} questRequest 挑戦クエスト情報
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof QuestApi
     */
    public async submitChallengeQuest(questId: number, questRequest: QuestRequest, options?: AxiosRequestConfig) {
        const retry = 2;
        let resp;
        for (let i = 0; i < retry; i++) {
            resp = await QuestApiFp(this.configuration).submitChallengeQuest(questId, questRequest, options).then((request) => request(this.axios, this.basePath)).catch((error) => {
                if (error?.response) {
                    return error.response;
                } else {
                    return { status: 500 };
                }
            });
            if (resp?.status === 401) {
                await refreshIdToken();
                console.debug('Retry submitChallengeQuest ' + i + '/' + (retry - 1));
                continue;
            }
            break;
        }
        return resp;
    }

    /**
     * クエスト公開状態を変更します。
     * @summary クエスト公開状態変更
     * @param {number} questId クエストID
     * @param {QuestPublicStatusRequest} questPublicStatusRequest クエスト公開状態情報
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof QuestApi
     */
    public async switchQuestPublicStatus(questId: number, questPublicStatusRequest: QuestPublicStatusRequest, options?: AxiosRequestConfig) {
        const retry = 2;
        let resp;
        for (let i = 0; i < retry; i++) {
            resp = await QuestApiFp(this.configuration).switchQuestPublicStatus(questId, questPublicStatusRequest, options).then((request) => request(this.axios, this.basePath)).catch((error) => {
                if (error?.response) {
                    return error.response;
                } else {
                    return { status: 500 };
                }
            });
            if (resp?.status === 401) {
                await refreshIdToken();
                console.debug('Retry switchQuestPublicStatus ' + i + '/' + (retry - 1));
                continue;
            }
            break;
        }
        return resp;
    }
}


/**
 * ResourceApi - axios parameter creator
 * @export
 */
export const ResourceApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * オペレーションに対するコイン値を取得します。
         * @summary オペレーションに対するコイン値取得
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCoinOperationValues: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/resources/coins/operations`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * リソースを取得します。
         * @summary リソース取得
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getResources: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/resources`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 原石を研磨してジェムにします。
         * @summary 原石を研磨してジェムにする
         * @param {ResourcesTradePolishRequest} resourcesTradePolishRequest 研磨情報
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        polishOre: async (resourcesTradePolishRequest: ResourcesTradePolishRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'resourcesTradePolishRequest' is not null or undefined
            assertParamExists('polishOre', 'resourcesTradePolishRequest', resourcesTradePolishRequest)
            const localVarPath = `/resources/trade/polish`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(resourcesTradePolishRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ResourceApi - functional programming interface
 * @export
 */
export const ResourceApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ResourceApiAxiosParamCreator(configuration)
    return {
        /**
         * オペレーションに対するコイン値を取得します。
         * @summary オペレーションに対するコイン値取得
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCoinOperationValues(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CoinOperationValues>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCoinOperationValues(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * リソースを取得します。
         * @summary リソース取得
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getResources(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Resources>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getResources(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 原石を研磨してジェムにします。
         * @summary 原石を研磨してジェムにする
         * @param {ResourcesTradePolishRequest} resourcesTradePolishRequest 研磨情報
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async polishOre(resourcesTradePolishRequest: ResourcesTradePolishRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResourcesTradePolish>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.polishOre(resourcesTradePolishRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ResourceApi - factory interface
 * @export
 */
export const ResourceApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ResourceApiFp(configuration)
    return {
        /**
         * オペレーションに対するコイン値を取得します。
         * @summary オペレーションに対するコイン値取得
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCoinOperationValues(options?: any): AxiosPromise<CoinOperationValues> {
            return localVarFp.getCoinOperationValues(options).then((request) => request(axios, basePath));
        },
        /**
         * リソースを取得します。
         * @summary リソース取得
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getResources(options?: any): AxiosPromise<Resources> {
            return localVarFp.getResources(options).then((request) => request(axios, basePath));
        },
        /**
         * 原石を研磨してジェムにします。
         * @summary 原石を研磨してジェムにする
         * @param {ResourcesTradePolishRequest} resourcesTradePolishRequest 研磨情報
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        polishOre(resourcesTradePolishRequest: ResourcesTradePolishRequest, options?: any): AxiosPromise<ResourcesTradePolish> {
            return localVarFp.polishOre(resourcesTradePolishRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ResourceApi - object-oriented interface
 * @export
 * @class ResourceApi
 * @extends {BaseAPI}
 */
export class ResourceApi extends BaseAPI {
    /**
     * オペレーションに対するコイン値を取得します。
     * @summary オペレーションに対するコイン値取得
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ResourceApi
     */
    public async getCoinOperationValues(options?: AxiosRequestConfig) {
        const retry = 2;
        let resp;
        for (let i = 0; i < retry; i++) {
            resp = await ResourceApiFp(this.configuration).getCoinOperationValues(options).then((request) => request(this.axios, this.basePath)).catch((error) => {
                if (error?.response) {
                    return error.response;
                } else {
                    return { status: 500 };
                }
            });
            if (resp?.status === 401) {
                await refreshIdToken();
                console.debug('Retry getCoinOperationValues ' + i + '/' + (retry - 1));
                continue;
            }
            break;
        }
        return resp;
    }

    /**
     * リソースを取得します。
     * @summary リソース取得
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ResourceApi
     */
    public async getResources(options?: AxiosRequestConfig) {
        const retry = 2;
        let resp;
        for (let i = 0; i < retry; i++) {
            resp = await ResourceApiFp(this.configuration).getResources(options).then((request) => request(this.axios, this.basePath)).catch((error) => {
                if (error?.response) {
                    return error.response;
                } else {
                    return { status: 500 };
                }
            });
            if (resp?.status === 401) {
                await refreshIdToken();
                console.debug('Retry getResources ' + i + '/' + (retry - 1));
                continue;
            }
            break;
        }
        return resp;
    }

    /**
     * 原石を研磨してジェムにします。
     * @summary 原石を研磨してジェムにする
     * @param {ResourcesTradePolishRequest} resourcesTradePolishRequest 研磨情報
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ResourceApi
     */
    public async polishOre(resourcesTradePolishRequest: ResourcesTradePolishRequest, options?: AxiosRequestConfig) {
        const retry = 2;
        let resp;
        for (let i = 0; i < retry; i++) {
            resp = await ResourceApiFp(this.configuration).polishOre(resourcesTradePolishRequest, options).then((request) => request(this.axios, this.basePath)).catch((error) => {
                if (error?.response) {
                    return error.response;
                } else {
                    return { status: 500 };
                }
            });
            if (resp?.status === 401) {
                await refreshIdToken();
                console.debug('Retry polishOre ' + i + '/' + (retry - 1));
                continue;
            }
            break;
        }
        return resp;
    }
}


/**
 * SkillApi - axios parameter creator
 * @export
 */
export const SkillApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * スキルを追加します。
         * @summary スキル追加(未)
         * @param {string} username ユーザ名
         * @param {number} [page] ページ番号
         * @param {number} [perPage] 1ページあたりの要素数 (MAX100)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addSkill: async (username: string, page?: number, perPage?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'username' is not null or undefined
            assertParamExists('addSkill', 'username', username)
            const localVarPath = `/users/{username}/skills`
                .replace(`{${"username"}}`, encodeURIComponent(String(username)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (perPage !== undefined) {
                localVarQueryParameter['per_page'] = perPage;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * スキル詳細を追加します。
         * @summary スキル詳細の追加
         * @param {string} username ユーザ名
         * @param {string} skillType スキル種別
         * @param {number} skillOrder スキルオーダー
         * @param {SkillDetailReq} skillDetailReq スキル詳細情報
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addSkillDetail: async (username: string, skillType: string, skillOrder: number, skillDetailReq: SkillDetailReq, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'username' is not null or undefined
            assertParamExists('addSkillDetail', 'username', username)
            // verify required parameter 'skillType' is not null or undefined
            assertParamExists('addSkillDetail', 'skillType', skillType)
            // verify required parameter 'skillOrder' is not null or undefined
            assertParamExists('addSkillDetail', 'skillOrder', skillOrder)
            // verify required parameter 'skillDetailReq' is not null or undefined
            assertParamExists('addSkillDetail', 'skillDetailReq', skillDetailReq)
            const localVarPath = `/users/{username}/skills/{skill_type}/{skill_order}/details`
                .replace(`{${"username"}}`, encodeURIComponent(String(username)))
                .replace(`{${"skill_type"}}`, encodeURIComponent(String(skillType)))
                .replace(`{${"skill_order"}}`, encodeURIComponent(String(skillOrder)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(skillDetailReq, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 資格を追加します。
         * @summary 資格の追加
         * @param {string} username ユーザ名
         * @param {string} skillType スキル種別
         * @param {number} skillOrder スキルオーダー
         * @param {QualifReq} qualifReq 資格情報
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addSkillQualif: async (username: string, skillType: string, skillOrder: number, qualifReq: QualifReq, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'username' is not null or undefined
            assertParamExists('addSkillQualif', 'username', username)
            // verify required parameter 'skillType' is not null or undefined
            assertParamExists('addSkillQualif', 'skillType', skillType)
            // verify required parameter 'skillOrder' is not null or undefined
            assertParamExists('addSkillQualif', 'skillOrder', skillOrder)
            // verify required parameter 'qualifReq' is not null or undefined
            assertParamExists('addSkillQualif', 'qualifReq', qualifReq)
            const localVarPath = `/users/{username}/skills/{skill_type}/{skill_order}/qualifs`
                .replace(`{${"username"}}`, encodeURIComponent(String(username)))
                .replace(`{${"skill_type"}}`, encodeURIComponent(String(skillType)))
                .replace(`{${"skill_order"}}`, encodeURIComponent(String(skillOrder)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(qualifReq, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * スキル情報を削除します。
         * @summary スキル情報削除(未)
         * @param {string} username ユーザ名
         * @param {string} skillType スキル種別
         * @param {number} skillOrder スキルオーダー
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteSkill: async (username: string, skillType: string, skillOrder: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'username' is not null or undefined
            assertParamExists('deleteSkill', 'username', username)
            // verify required parameter 'skillType' is not null or undefined
            assertParamExists('deleteSkill', 'skillType', skillType)
            // verify required parameter 'skillOrder' is not null or undefined
            assertParamExists('deleteSkill', 'skillOrder', skillOrder)
            const localVarPath = `/users/{username}/skills/{skill_type}/{skill_order}`
                .replace(`{${"username"}}`, encodeURIComponent(String(username)))
                .replace(`{${"skill_type"}}`, encodeURIComponent(String(skillType)))
                .replace(`{${"skill_order"}}`, encodeURIComponent(String(skillOrder)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * スキル詳細を削除します。
         * @summary スキル詳細の削除
         * @param {string} username ユーザ名
         * @param {string} skillType スキル種別
         * @param {number} skillOrder スキルオーダー
         * @param {number} detailOrder スキル詳細オーダー
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteSkillDetail: async (username: string, skillType: string, skillOrder: number, detailOrder: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'username' is not null or undefined
            assertParamExists('deleteSkillDetail', 'username', username)
            // verify required parameter 'skillType' is not null or undefined
            assertParamExists('deleteSkillDetail', 'skillType', skillType)
            // verify required parameter 'skillOrder' is not null or undefined
            assertParamExists('deleteSkillDetail', 'skillOrder', skillOrder)
            // verify required parameter 'detailOrder' is not null or undefined
            assertParamExists('deleteSkillDetail', 'detailOrder', detailOrder)
            const localVarPath = `/users/{username}/skills/{skill_type}/{skill_order}/details/{detail_order}`
                .replace(`{${"username"}}`, encodeURIComponent(String(username)))
                .replace(`{${"skill_type"}}`, encodeURIComponent(String(skillType)))
                .replace(`{${"skill_order"}}`, encodeURIComponent(String(skillOrder)))
                .replace(`{${"detail_order"}}`, encodeURIComponent(String(detailOrder)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * スキル詳細の資格情報を削除します。
         * @summary スキル詳細の資格情報を削除
         * @param {string} username ユーザ名
         * @param {string} skillType スキル種別
         * @param {number} skillOrder スキルオーダー
         * @param {number} qualifOrder 資格オーダー
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteSkillQualif: async (username: string, skillType: string, skillOrder: number, qualifOrder: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'username' is not null or undefined
            assertParamExists('deleteSkillQualif', 'username', username)
            // verify required parameter 'skillType' is not null or undefined
            assertParamExists('deleteSkillQualif', 'skillType', skillType)
            // verify required parameter 'skillOrder' is not null or undefined
            assertParamExists('deleteSkillQualif', 'skillOrder', skillOrder)
            // verify required parameter 'qualifOrder' is not null or undefined
            assertParamExists('deleteSkillQualif', 'qualifOrder', qualifOrder)
            const localVarPath = `/users/{username}/skills/{skill_type}/{skill_order}/qualifs/{qualif_order}`
                .replace(`{${"username"}}`, encodeURIComponent(String(username)))
                .replace(`{${"skill_type"}}`, encodeURIComponent(String(skillType)))
                .replace(`{${"skill_order"}}`, encodeURIComponent(String(skillOrder)))
                .replace(`{${"qualif_order"}}`, encodeURIComponent(String(qualifOrder)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * スキル詳細のリストを取得します。
         * @summary スキル詳細取得
         * @param {string} username ユーザ名
         * @param {string} skillType スキル種別
         * @param {number} skillOrder スキルオーダー
         * @param {number} [page] ページ番号
         * @param {number} [perPage] 1ページあたりの要素数 (MAX100)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findSkillAnalytics: async (username: string, skillType: string, skillOrder: number, page?: number, perPage?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'username' is not null or undefined
            assertParamExists('findSkillAnalytics', 'username', username)
            // verify required parameter 'skillType' is not null or undefined
            assertParamExists('findSkillAnalytics', 'skillType', skillType)
            // verify required parameter 'skillOrder' is not null or undefined
            assertParamExists('findSkillAnalytics', 'skillOrder', skillOrder)
            const localVarPath = `/users/{username}/skills/{skill_type}/{skill_order}/analytics`
                .replace(`{${"username"}}`, encodeURIComponent(String(username)))
                .replace(`{${"skill_type"}}`, encodeURIComponent(String(skillType)))
                .replace(`{${"skill_order"}}`, encodeURIComponent(String(skillOrder)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (perPage !== undefined) {
                localVarQueryParameter['per_page'] = perPage;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * スキル詳細のリストを取得します。
         * @summary スキル詳細取得
         * @param {string} username ユーザ名
         * @param {string} skillType スキル種別
         * @param {number} skillOrder スキルオーダー
         * @param {number} [page] ページ番号
         * @param {number} [perPage] 1ページあたりの要素数 (MAX100)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findSkillDetails: async (username: string, skillType: string, skillOrder: number, page?: number, perPage?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'username' is not null or undefined
            assertParamExists('findSkillDetails', 'username', username)
            // verify required parameter 'skillType' is not null or undefined
            assertParamExists('findSkillDetails', 'skillType', skillType)
            // verify required parameter 'skillOrder' is not null or undefined
            assertParamExists('findSkillDetails', 'skillOrder', skillOrder)
            const localVarPath = `/users/{username}/skills/{skill_type}/{skill_order}/details`
                .replace(`{${"username"}}`, encodeURIComponent(String(username)))
                .replace(`{${"skill_type"}}`, encodeURIComponent(String(skillType)))
                .replace(`{${"skill_order"}}`, encodeURIComponent(String(skillOrder)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (perPage !== undefined) {
                localVarQueryParameter['per_page'] = perPage;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 資格のリストを取得します。
         * @summary 資格リスト取得
         * @param {string} username ユーザ名
         * @param {string} skillType スキル種別
         * @param {number} skillOrder スキルオーダー
         * @param {number} [page] ページ番号
         * @param {number} [perPage] 1ページあたりの要素数 (MAX100)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findSkillQualifs: async (username: string, skillType: string, skillOrder: number, page?: number, perPage?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'username' is not null or undefined
            assertParamExists('findSkillQualifs', 'username', username)
            // verify required parameter 'skillType' is not null or undefined
            assertParamExists('findSkillQualifs', 'skillType', skillType)
            // verify required parameter 'skillOrder' is not null or undefined
            assertParamExists('findSkillQualifs', 'skillOrder', skillOrder)
            const localVarPath = `/users/{username}/skills/{skill_type}/{skill_order}/qualifs`
                .replace(`{${"username"}}`, encodeURIComponent(String(username)))
                .replace(`{${"skill_type"}}`, encodeURIComponent(String(skillType)))
                .replace(`{${"skill_order"}}`, encodeURIComponent(String(skillOrder)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (perPage !== undefined) {
                localVarQueryParameter['per_page'] = perPage;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * スキルのリストを取得します。
         * @summary スキルリスト取得
         * @param {string} username ユーザ名
         * @param {number} [page] ページ番号
         * @param {number} [perPage] 1ページあたりの要素数 (MAX100)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findSkills: async (username: string, page?: number, perPage?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'username' is not null or undefined
            assertParamExists('findSkills', 'username', username)
            const localVarPath = `/users/{username}/skills`
                .replace(`{${"username"}}`, encodeURIComponent(String(username)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (perPage !== undefined) {
                localVarQueryParameter['per_page'] = perPage;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * スキル情報を取得します。
         * @summary スキル情報取得(未)
         * @param {string} username ユーザ名
         * @param {string} skillType スキル種別
         * @param {number} skillOrder スキルオーダー
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSkill: async (username: string, skillType: string, skillOrder: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'username' is not null or undefined
            assertParamExists('getSkill', 'username', username)
            // verify required parameter 'skillType' is not null or undefined
            assertParamExists('getSkill', 'skillType', skillType)
            // verify required parameter 'skillOrder' is not null or undefined
            assertParamExists('getSkill', 'skillOrder', skillOrder)
            const localVarPath = `/users/{username}/skills/{skill_type}/{skill_order}`
                .replace(`{${"username"}}`, encodeURIComponent(String(username)))
                .replace(`{${"skill_type"}}`, encodeURIComponent(String(skillType)))
                .replace(`{${"skill_order"}}`, encodeURIComponent(String(skillOrder)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * スキル詳細を取得します。
         * @summary スキル詳細取得
         * @param {string} username ユーザ名
         * @param {string} skillType スキル種別
         * @param {number} skillOrder スキルオーダー
         * @param {number} detailOrder スキル詳細オーダー
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSkillDetail: async (username: string, skillType: string, skillOrder: number, detailOrder: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'username' is not null or undefined
            assertParamExists('getSkillDetail', 'username', username)
            // verify required parameter 'skillType' is not null or undefined
            assertParamExists('getSkillDetail', 'skillType', skillType)
            // verify required parameter 'skillOrder' is not null or undefined
            assertParamExists('getSkillDetail', 'skillOrder', skillOrder)
            // verify required parameter 'detailOrder' is not null or undefined
            assertParamExists('getSkillDetail', 'detailOrder', detailOrder)
            const localVarPath = `/users/{username}/skills/{skill_type}/{skill_order}/details/{detail_order}`
                .replace(`{${"username"}}`, encodeURIComponent(String(username)))
                .replace(`{${"skill_type"}}`, encodeURIComponent(String(skillType)))
                .replace(`{${"skill_order"}}`, encodeURIComponent(String(skillOrder)))
                .replace(`{${"detail_order"}}`, encodeURIComponent(String(detailOrder)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * スキル詳細の資格情報を取得します。
         * @summary スキル詳細の資格情報の取得
         * @param {string} username ユーザ名
         * @param {string} skillType スキル種別
         * @param {number} skillOrder スキルオーダー
         * @param {number} qualifOrder 資格オーダー
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSkillQualif: async (username: string, skillType: string, skillOrder: number, qualifOrder: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'username' is not null or undefined
            assertParamExists('getSkillQualif', 'username', username)
            // verify required parameter 'skillType' is not null or undefined
            assertParamExists('getSkillQualif', 'skillType', skillType)
            // verify required parameter 'skillOrder' is not null or undefined
            assertParamExists('getSkillQualif', 'skillOrder', skillOrder)
            // verify required parameter 'qualifOrder' is not null or undefined
            assertParamExists('getSkillQualif', 'qualifOrder', qualifOrder)
            const localVarPath = `/users/{username}/skills/{skill_type}/{skill_order}/qualifs/{qualif_order}`
                .replace(`{${"username"}}`, encodeURIComponent(String(username)))
                .replace(`{${"skill_type"}}`, encodeURIComponent(String(skillType)))
                .replace(`{${"skill_order"}}`, encodeURIComponent(String(skillOrder)))
                .replace(`{${"qualif_order"}}`, encodeURIComponent(String(qualifOrder)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * スキル詳細を更新します。
         * @summary スキル詳細を更新
         * @param {string} username ユーザ名
         * @param {string} skillType スキル種別
         * @param {number} skillOrder スキルオーダー
         * @param {number} detailOrder スキル詳細オーダー
         * @param {SkillDetailReq} skillDetailReq スキル詳細情報
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setSkillDetail: async (username: string, skillType: string, skillOrder: number, detailOrder: number, skillDetailReq: SkillDetailReq, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'username' is not null or undefined
            assertParamExists('setSkillDetail', 'username', username)
            // verify required parameter 'skillType' is not null or undefined
            assertParamExists('setSkillDetail', 'skillType', skillType)
            // verify required parameter 'skillOrder' is not null or undefined
            assertParamExists('setSkillDetail', 'skillOrder', skillOrder)
            // verify required parameter 'detailOrder' is not null or undefined
            assertParamExists('setSkillDetail', 'detailOrder', detailOrder)
            // verify required parameter 'skillDetailReq' is not null or undefined
            assertParamExists('setSkillDetail', 'skillDetailReq', skillDetailReq)
            const localVarPath = `/users/{username}/skills/{skill_type}/{skill_order}/details/{detail_order}`
                .replace(`{${"username"}}`, encodeURIComponent(String(username)))
                .replace(`{${"skill_type"}}`, encodeURIComponent(String(skillType)))
                .replace(`{${"skill_order"}}`, encodeURIComponent(String(skillOrder)))
                .replace(`{${"detail_order"}}`, encodeURIComponent(String(detailOrder)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(skillDetailReq, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * スキル詳細の資格情報を更新します。
         * @summary スキル詳細の資格情報の更新
         * @param {string} username ユーザ名
         * @param {string} skillType スキル種別
         * @param {number} skillOrder スキルオーダー
         * @param {number} qualifOrder 資格オーダー
         * @param {QualifReq} qualifReq 資格情報
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setSkillQualif: async (username: string, skillType: string, skillOrder: number, qualifOrder: number, qualifReq: QualifReq, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'username' is not null or undefined
            assertParamExists('setSkillQualif', 'username', username)
            // verify required parameter 'skillType' is not null or undefined
            assertParamExists('setSkillQualif', 'skillType', skillType)
            // verify required parameter 'skillOrder' is not null or undefined
            assertParamExists('setSkillQualif', 'skillOrder', skillOrder)
            // verify required parameter 'qualifOrder' is not null or undefined
            assertParamExists('setSkillQualif', 'qualifOrder', qualifOrder)
            // verify required parameter 'qualifReq' is not null or undefined
            assertParamExists('setSkillQualif', 'qualifReq', qualifReq)
            const localVarPath = `/users/{username}/skills/{skill_type}/{skill_order}/qualifs/{qualif_order}`
                .replace(`{${"username"}}`, encodeURIComponent(String(username)))
                .replace(`{${"skill_type"}}`, encodeURIComponent(String(skillType)))
                .replace(`{${"skill_order"}}`, encodeURIComponent(String(skillOrder)))
                .replace(`{${"qualif_order"}}`, encodeURIComponent(String(qualifOrder)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(qualifReq, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * スキル情報を更新します。
         * @summary スキル情報更新(未)
         * @param {string} username ユーザ名
         * @param {string} skillType スキル種別
         * @param {number} skillOrder スキルオーダー
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateSkill: async (username: string, skillType: string, skillOrder: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'username' is not null or undefined
            assertParamExists('updateSkill', 'username', username)
            // verify required parameter 'skillType' is not null or undefined
            assertParamExists('updateSkill', 'skillType', skillType)
            // verify required parameter 'skillOrder' is not null or undefined
            assertParamExists('updateSkill', 'skillOrder', skillOrder)
            const localVarPath = `/users/{username}/skills/{skill_type}/{skill_order}`
                .replace(`{${"username"}}`, encodeURIComponent(String(username)))
                .replace(`{${"skill_type"}}`, encodeURIComponent(String(skillType)))
                .replace(`{${"skill_order"}}`, encodeURIComponent(String(skillOrder)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SkillApi - functional programming interface
 * @export
 */
export const SkillApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = SkillApiAxiosParamCreator(configuration)
    return {
        /**
         * スキルを追加します。
         * @summary スキル追加(未)
         * @param {string} username ユーザ名
         * @param {number} [page] ページ番号
         * @param {number} [perPage] 1ページあたりの要素数 (MAX100)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addSkill(username: string, page?: number, perPage?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Skill>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addSkill(username, page, perPage, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * スキル詳細を追加します。
         * @summary スキル詳細の追加
         * @param {string} username ユーザ名
         * @param {string} skillType スキル種別
         * @param {number} skillOrder スキルオーダー
         * @param {SkillDetailReq} skillDetailReq スキル詳細情報
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addSkillDetail(username: string, skillType: string, skillOrder: number, skillDetailReq: SkillDetailReq, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addSkillDetail(username, skillType, skillOrder, skillDetailReq, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 資格を追加します。
         * @summary 資格の追加
         * @param {string} username ユーザ名
         * @param {string} skillType スキル種別
         * @param {number} skillOrder スキルオーダー
         * @param {QualifReq} qualifReq 資格情報
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addSkillQualif(username: string, skillType: string, skillOrder: number, qualifReq: QualifReq, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addSkillQualif(username, skillType, skillOrder, qualifReq, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * スキル情報を削除します。
         * @summary スキル情報削除(未)
         * @param {string} username ユーザ名
         * @param {string} skillType スキル種別
         * @param {number} skillOrder スキルオーダー
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteSkill(username: string, skillType: string, skillOrder: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteSkill(username, skillType, skillOrder, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * スキル詳細を削除します。
         * @summary スキル詳細の削除
         * @param {string} username ユーザ名
         * @param {string} skillType スキル種別
         * @param {number} skillOrder スキルオーダー
         * @param {number} detailOrder スキル詳細オーダー
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteSkillDetail(username: string, skillType: string, skillOrder: number, detailOrder: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteSkillDetail(username, skillType, skillOrder, detailOrder, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * スキル詳細の資格情報を削除します。
         * @summary スキル詳細の資格情報を削除
         * @param {string} username ユーザ名
         * @param {string} skillType スキル種別
         * @param {number} skillOrder スキルオーダー
         * @param {number} qualifOrder 資格オーダー
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteSkillQualif(username: string, skillType: string, skillOrder: number, qualifOrder: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteSkillQualif(username, skillType, skillOrder, qualifOrder, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * スキル詳細のリストを取得します。
         * @summary スキル詳細取得
         * @param {string} username ユーザ名
         * @param {string} skillType スキル種別
         * @param {number} skillOrder スキルオーダー
         * @param {number} [page] ページ番号
         * @param {number} [perPage] 1ページあたりの要素数 (MAX100)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findSkillAnalytics(username: string, skillType: string, skillOrder: number, page?: number, perPage?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SkillAnalytics>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findSkillAnalytics(username, skillType, skillOrder, page, perPage, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * スキル詳細のリストを取得します。
         * @summary スキル詳細取得
         * @param {string} username ユーザ名
         * @param {string} skillType スキル種別
         * @param {number} skillOrder スキルオーダー
         * @param {number} [page] ページ番号
         * @param {number} [perPage] 1ページあたりの要素数 (MAX100)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findSkillDetails(username: string, skillType: string, skillOrder: number, page?: number, perPage?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SkillDetails>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findSkillDetails(username, skillType, skillOrder, page, perPage, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 資格のリストを取得します。
         * @summary 資格リスト取得
         * @param {string} username ユーザ名
         * @param {string} skillType スキル種別
         * @param {number} skillOrder スキルオーダー
         * @param {number} [page] ページ番号
         * @param {number} [perPage] 1ページあたりの要素数 (MAX100)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findSkillQualifs(username: string, skillType: string, skillOrder: number, page?: number, perPage?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SkillQualifs>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findSkillQualifs(username, skillType, skillOrder, page, perPage, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * スキルのリストを取得します。
         * @summary スキルリスト取得
         * @param {string} username ユーザ名
         * @param {number} [page] ページ番号
         * @param {number} [perPage] 1ページあたりの要素数 (MAX100)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findSkills(username: string, page?: number, perPage?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Skills>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findSkills(username, page, perPage, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * スキル情報を取得します。
         * @summary スキル情報取得(未)
         * @param {string} username ユーザ名
         * @param {string} skillType スキル種別
         * @param {number} skillOrder スキルオーダー
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSkill(username: string, skillType: string, skillOrder: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Skill>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSkill(username, skillType, skillOrder, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * スキル詳細を取得します。
         * @summary スキル詳細取得
         * @param {string} username ユーザ名
         * @param {string} skillType スキル種別
         * @param {number} skillOrder スキルオーダー
         * @param {number} detailOrder スキル詳細オーダー
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSkillDetail(username: string, skillType: string, skillOrder: number, detailOrder: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SkillDetail>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSkillDetail(username, skillType, skillOrder, detailOrder, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * スキル詳細の資格情報を取得します。
         * @summary スキル詳細の資格情報の取得
         * @param {string} username ユーザ名
         * @param {string} skillType スキル種別
         * @param {number} skillOrder スキルオーダー
         * @param {number} qualifOrder 資格オーダー
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSkillQualif(username: string, skillType: string, skillOrder: number, qualifOrder: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SkillQualif>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSkillQualif(username, skillType, skillOrder, qualifOrder, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * スキル詳細を更新します。
         * @summary スキル詳細を更新
         * @param {string} username ユーザ名
         * @param {string} skillType スキル種別
         * @param {number} skillOrder スキルオーダー
         * @param {number} detailOrder スキル詳細オーダー
         * @param {SkillDetailReq} skillDetailReq スキル詳細情報
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async setSkillDetail(username: string, skillType: string, skillOrder: number, detailOrder: number, skillDetailReq: SkillDetailReq, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.setSkillDetail(username, skillType, skillOrder, detailOrder, skillDetailReq, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * スキル詳細の資格情報を更新します。
         * @summary スキル詳細の資格情報の更新
         * @param {string} username ユーザ名
         * @param {string} skillType スキル種別
         * @param {number} skillOrder スキルオーダー
         * @param {number} qualifOrder 資格オーダー
         * @param {QualifReq} qualifReq 資格情報
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async setSkillQualif(username: string, skillType: string, skillOrder: number, qualifOrder: number, qualifReq: QualifReq, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.setSkillQualif(username, skillType, skillOrder, qualifOrder, qualifReq, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * スキル情報を更新します。
         * @summary スキル情報更新(未)
         * @param {string} username ユーザ名
         * @param {string} skillType スキル種別
         * @param {number} skillOrder スキルオーダー
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateSkill(username: string, skillType: string, skillOrder: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Skill>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateSkill(username, skillType, skillOrder, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * SkillApi - factory interface
 * @export
 */
export const SkillApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = SkillApiFp(configuration)
    return {
        /**
         * スキルを追加します。
         * @summary スキル追加(未)
         * @param {string} username ユーザ名
         * @param {number} [page] ページ番号
         * @param {number} [perPage] 1ページあたりの要素数 (MAX100)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addSkill(username: string, page?: number, perPage?: number, options?: any): AxiosPromise<Skill> {
            return localVarFp.addSkill(username, page, perPage, options).then((request) => request(axios, basePath));
        },
        /**
         * スキル詳細を追加します。
         * @summary スキル詳細の追加
         * @param {string} username ユーザ名
         * @param {string} skillType スキル種別
         * @param {number} skillOrder スキルオーダー
         * @param {SkillDetailReq} skillDetailReq スキル詳細情報
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addSkillDetail(username: string, skillType: string, skillOrder: number, skillDetailReq: SkillDetailReq, options?: any): AxiosPromise<void> {
            return localVarFp.addSkillDetail(username, skillType, skillOrder, skillDetailReq, options).then((request) => request(axios, basePath));
        },
        /**
         * 資格を追加します。
         * @summary 資格の追加
         * @param {string} username ユーザ名
         * @param {string} skillType スキル種別
         * @param {number} skillOrder スキルオーダー
         * @param {QualifReq} qualifReq 資格情報
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addSkillQualif(username: string, skillType: string, skillOrder: number, qualifReq: QualifReq, options?: any): AxiosPromise<void> {
            return localVarFp.addSkillQualif(username, skillType, skillOrder, qualifReq, options).then((request) => request(axios, basePath));
        },
        /**
         * スキル情報を削除します。
         * @summary スキル情報削除(未)
         * @param {string} username ユーザ名
         * @param {string} skillType スキル種別
         * @param {number} skillOrder スキルオーダー
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteSkill(username: string, skillType: string, skillOrder: number, options?: any): AxiosPromise<void> {
            return localVarFp.deleteSkill(username, skillType, skillOrder, options).then((request) => request(axios, basePath));
        },
        /**
         * スキル詳細を削除します。
         * @summary スキル詳細の削除
         * @param {string} username ユーザ名
         * @param {string} skillType スキル種別
         * @param {number} skillOrder スキルオーダー
         * @param {number} detailOrder スキル詳細オーダー
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteSkillDetail(username: string, skillType: string, skillOrder: number, detailOrder: number, options?: any): AxiosPromise<void> {
            return localVarFp.deleteSkillDetail(username, skillType, skillOrder, detailOrder, options).then((request) => request(axios, basePath));
        },
        /**
         * スキル詳細の資格情報を削除します。
         * @summary スキル詳細の資格情報を削除
         * @param {string} username ユーザ名
         * @param {string} skillType スキル種別
         * @param {number} skillOrder スキルオーダー
         * @param {number} qualifOrder 資格オーダー
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteSkillQualif(username: string, skillType: string, skillOrder: number, qualifOrder: number, options?: any): AxiosPromise<void> {
            return localVarFp.deleteSkillQualif(username, skillType, skillOrder, qualifOrder, options).then((request) => request(axios, basePath));
        },
        /**
         * スキル詳細のリストを取得します。
         * @summary スキル詳細取得
         * @param {string} username ユーザ名
         * @param {string} skillType スキル種別
         * @param {number} skillOrder スキルオーダー
         * @param {number} [page] ページ番号
         * @param {number} [perPage] 1ページあたりの要素数 (MAX100)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findSkillAnalytics(username: string, skillType: string, skillOrder: number, page?: number, perPage?: number, options?: any): AxiosPromise<SkillAnalytics> {
            return localVarFp.findSkillAnalytics(username, skillType, skillOrder, page, perPage, options).then((request) => request(axios, basePath));
        },
        /**
         * スキル詳細のリストを取得します。
         * @summary スキル詳細取得
         * @param {string} username ユーザ名
         * @param {string} skillType スキル種別
         * @param {number} skillOrder スキルオーダー
         * @param {number} [page] ページ番号
         * @param {number} [perPage] 1ページあたりの要素数 (MAX100)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findSkillDetails(username: string, skillType: string, skillOrder: number, page?: number, perPage?: number, options?: any): AxiosPromise<SkillDetails> {
            return localVarFp.findSkillDetails(username, skillType, skillOrder, page, perPage, options).then((request) => request(axios, basePath));
        },
        /**
         * 資格のリストを取得します。
         * @summary 資格リスト取得
         * @param {string} username ユーザ名
         * @param {string} skillType スキル種別
         * @param {number} skillOrder スキルオーダー
         * @param {number} [page] ページ番号
         * @param {number} [perPage] 1ページあたりの要素数 (MAX100)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findSkillQualifs(username: string, skillType: string, skillOrder: number, page?: number, perPage?: number, options?: any): AxiosPromise<SkillQualifs> {
            return localVarFp.findSkillQualifs(username, skillType, skillOrder, page, perPage, options).then((request) => request(axios, basePath));
        },
        /**
         * スキルのリストを取得します。
         * @summary スキルリスト取得
         * @param {string} username ユーザ名
         * @param {number} [page] ページ番号
         * @param {number} [perPage] 1ページあたりの要素数 (MAX100)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findSkills(username: string, page?: number, perPage?: number, options?: any): AxiosPromise<Skills> {
            return localVarFp.findSkills(username, page, perPage, options).then((request) => request(axios, basePath));
        },
        /**
         * スキル情報を取得します。
         * @summary スキル情報取得(未)
         * @param {string} username ユーザ名
         * @param {string} skillType スキル種別
         * @param {number} skillOrder スキルオーダー
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSkill(username: string, skillType: string, skillOrder: number, options?: any): AxiosPromise<Skill> {
            return localVarFp.getSkill(username, skillType, skillOrder, options).then((request) => request(axios, basePath));
        },
        /**
         * スキル詳細を取得します。
         * @summary スキル詳細取得
         * @param {string} username ユーザ名
         * @param {string} skillType スキル種別
         * @param {number} skillOrder スキルオーダー
         * @param {number} detailOrder スキル詳細オーダー
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSkillDetail(username: string, skillType: string, skillOrder: number, detailOrder: number, options?: any): AxiosPromise<SkillDetail> {
            return localVarFp.getSkillDetail(username, skillType, skillOrder, detailOrder, options).then((request) => request(axios, basePath));
        },
        /**
         * スキル詳細の資格情報を取得します。
         * @summary スキル詳細の資格情報の取得
         * @param {string} username ユーザ名
         * @param {string} skillType スキル種別
         * @param {number} skillOrder スキルオーダー
         * @param {number} qualifOrder 資格オーダー
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSkillQualif(username: string, skillType: string, skillOrder: number, qualifOrder: number, options?: any): AxiosPromise<SkillQualif> {
            return localVarFp.getSkillQualif(username, skillType, skillOrder, qualifOrder, options).then((request) => request(axios, basePath));
        },
        /**
         * スキル詳細を更新します。
         * @summary スキル詳細を更新
         * @param {string} username ユーザ名
         * @param {string} skillType スキル種別
         * @param {number} skillOrder スキルオーダー
         * @param {number} detailOrder スキル詳細オーダー
         * @param {SkillDetailReq} skillDetailReq スキル詳細情報
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setSkillDetail(username: string, skillType: string, skillOrder: number, detailOrder: number, skillDetailReq: SkillDetailReq, options?: any): AxiosPromise<void> {
            return localVarFp.setSkillDetail(username, skillType, skillOrder, detailOrder, skillDetailReq, options).then((request) => request(axios, basePath));
        },
        /**
         * スキル詳細の資格情報を更新します。
         * @summary スキル詳細の資格情報の更新
         * @param {string} username ユーザ名
         * @param {string} skillType スキル種別
         * @param {number} skillOrder スキルオーダー
         * @param {number} qualifOrder 資格オーダー
         * @param {QualifReq} qualifReq 資格情報
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setSkillQualif(username: string, skillType: string, skillOrder: number, qualifOrder: number, qualifReq: QualifReq, options?: any): AxiosPromise<void> {
            return localVarFp.setSkillQualif(username, skillType, skillOrder, qualifOrder, qualifReq, options).then((request) => request(axios, basePath));
        },
        /**
         * スキル情報を更新します。
         * @summary スキル情報更新(未)
         * @param {string} username ユーザ名
         * @param {string} skillType スキル種別
         * @param {number} skillOrder スキルオーダー
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateSkill(username: string, skillType: string, skillOrder: number, options?: any): AxiosPromise<Skill> {
            return localVarFp.updateSkill(username, skillType, skillOrder, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * SkillApi - object-oriented interface
 * @export
 * @class SkillApi
 * @extends {BaseAPI}
 */
export class SkillApi extends BaseAPI {
    /**
     * スキルを追加します。
     * @summary スキル追加(未)
     * @param {string} username ユーザ名
     * @param {number} [page] ページ番号
     * @param {number} [perPage] 1ページあたりの要素数 (MAX100)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SkillApi
     */
    public async addSkill(username: string, page?: number, perPage?: number, options?: AxiosRequestConfig) {
        const retry = 2;
        let resp;
        for (let i = 0; i < retry; i++) {
            resp = await SkillApiFp(this.configuration).addSkill(username, page, perPage, options).then((request) => request(this.axios, this.basePath)).catch((error) => {
                if (error?.response) {
                    return error.response;
                } else {
                    return { status: 500 };
                }
            });
            if (resp?.status === 401) {
                await refreshIdToken();
                console.debug('Retry addSkill ' + i + '/' + (retry - 1));
                continue;
            }
            break;
        }
        return resp;
    }

    /**
     * スキル詳細を追加します。
     * @summary スキル詳細の追加
     * @param {string} username ユーザ名
     * @param {string} skillType スキル種別
     * @param {number} skillOrder スキルオーダー
     * @param {SkillDetailReq} skillDetailReq スキル詳細情報
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SkillApi
     */
    public async addSkillDetail(username: string, skillType: string, skillOrder: number, skillDetailReq: SkillDetailReq, options?: AxiosRequestConfig) {
        const retry = 2;
        let resp;
        for (let i = 0; i < retry; i++) {
            resp = await SkillApiFp(this.configuration).addSkillDetail(username, skillType, skillOrder, skillDetailReq, options).then((request) => request(this.axios, this.basePath)).catch((error) => {
                if (error?.response) {
                    return error.response;
                } else {
                    return { status: 500 };
                }
            });
            if (resp?.status === 401) {
                await refreshIdToken();
                console.debug('Retry addSkillDetail ' + i + '/' + (retry - 1));
                continue;
            }
            break;
        }
        return resp;
    }

    /**
     * 資格を追加します。
     * @summary 資格の追加
     * @param {string} username ユーザ名
     * @param {string} skillType スキル種別
     * @param {number} skillOrder スキルオーダー
     * @param {QualifReq} qualifReq 資格情報
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SkillApi
     */
    public async addSkillQualif(username: string, skillType: string, skillOrder: number, qualifReq: QualifReq, options?: AxiosRequestConfig) {
        const retry = 2;
        let resp;
        for (let i = 0; i < retry; i++) {
            resp = await SkillApiFp(this.configuration).addSkillQualif(username, skillType, skillOrder, qualifReq, options).then((request) => request(this.axios, this.basePath)).catch((error) => {
                if (error?.response) {
                    return error.response;
                } else {
                    return { status: 500 };
                }
            });
            if (resp?.status === 401) {
                await refreshIdToken();
                console.debug('Retry addSkillQualif ' + i + '/' + (retry - 1));
                continue;
            }
            break;
        }
        return resp;
    }

    /**
     * スキル情報を削除します。
     * @summary スキル情報削除(未)
     * @param {string} username ユーザ名
     * @param {string} skillType スキル種別
     * @param {number} skillOrder スキルオーダー
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SkillApi
     */
    public async deleteSkill(username: string, skillType: string, skillOrder: number, options?: AxiosRequestConfig) {
        const retry = 2;
        let resp;
        for (let i = 0; i < retry; i++) {
            resp = await SkillApiFp(this.configuration).deleteSkill(username, skillType, skillOrder, options).then((request) => request(this.axios, this.basePath)).catch((error) => {
                if (error?.response) {
                    return error.response;
                } else {
                    return { status: 500 };
                }
            });
            if (resp?.status === 401) {
                await refreshIdToken();
                console.debug('Retry deleteSkill ' + i + '/' + (retry - 1));
                continue;
            }
            break;
        }
        return resp;
    }

    /**
     * スキル詳細を削除します。
     * @summary スキル詳細の削除
     * @param {string} username ユーザ名
     * @param {string} skillType スキル種別
     * @param {number} skillOrder スキルオーダー
     * @param {number} detailOrder スキル詳細オーダー
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SkillApi
     */
    public async deleteSkillDetail(username: string, skillType: string, skillOrder: number, detailOrder: number, options?: AxiosRequestConfig) {
        const retry = 2;
        let resp;
        for (let i = 0; i < retry; i++) {
            resp = await SkillApiFp(this.configuration).deleteSkillDetail(username, skillType, skillOrder, detailOrder, options).then((request) => request(this.axios, this.basePath)).catch((error) => {
                if (error?.response) {
                    return error.response;
                } else {
                    return { status: 500 };
                }
            });
            if (resp?.status === 401) {
                await refreshIdToken();
                console.debug('Retry deleteSkillDetail ' + i + '/' + (retry - 1));
                continue;
            }
            break;
        }
        return resp;
    }

    /**
     * スキル詳細の資格情報を削除します。
     * @summary スキル詳細の資格情報を削除
     * @param {string} username ユーザ名
     * @param {string} skillType スキル種別
     * @param {number} skillOrder スキルオーダー
     * @param {number} qualifOrder 資格オーダー
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SkillApi
     */
    public async deleteSkillQualif(username: string, skillType: string, skillOrder: number, qualifOrder: number, options?: AxiosRequestConfig) {
        const retry = 2;
        let resp;
        for (let i = 0; i < retry; i++) {
            resp = await SkillApiFp(this.configuration).deleteSkillQualif(username, skillType, skillOrder, qualifOrder, options).then((request) => request(this.axios, this.basePath)).catch((error) => {
                if (error?.response) {
                    return error.response;
                } else {
                    return { status: 500 };
                }
            });
            if (resp?.status === 401) {
                await refreshIdToken();
                console.debug('Retry deleteSkillQualif ' + i + '/' + (retry - 1));
                continue;
            }
            break;
        }
        return resp;
    }

    /**
     * スキル詳細のリストを取得します。
     * @summary スキル詳細取得
     * @param {string} username ユーザ名
     * @param {string} skillType スキル種別
     * @param {number} skillOrder スキルオーダー
     * @param {number} [page] ページ番号
     * @param {number} [perPage] 1ページあたりの要素数 (MAX100)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SkillApi
     */
    public async findSkillAnalytics(username: string, skillType: string, skillOrder: number, page?: number, perPage?: number, options?: AxiosRequestConfig) {
        const retry = 2;
        let resp;
        for (let i = 0; i < retry; i++) {
            resp = await SkillApiFp(this.configuration).findSkillAnalytics(username, skillType, skillOrder, page, perPage, options).then((request) => request(this.axios, this.basePath)).catch((error) => {
                if (error?.response) {
                    return error.response;
                } else {
                    return { status: 500 };
                }
            });
            if (resp?.status === 401) {
                await refreshIdToken();
                console.debug('Retry findSkillAnalytics ' + i + '/' + (retry - 1));
                continue;
            }
            break;
        }
        return resp;
    }

    /**
     * スキル詳細のリストを取得します。
     * @summary スキル詳細取得
     * @param {string} username ユーザ名
     * @param {string} skillType スキル種別
     * @param {number} skillOrder スキルオーダー
     * @param {number} [page] ページ番号
     * @param {number} [perPage] 1ページあたりの要素数 (MAX100)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SkillApi
     */
    public async findSkillDetails(username: string, skillType: string, skillOrder: number, page?: number, perPage?: number, options?: AxiosRequestConfig) {
        const retry = 2;
        let resp;
        for (let i = 0; i < retry; i++) {
            resp = await SkillApiFp(this.configuration).findSkillDetails(username, skillType, skillOrder, page, perPage, options).then((request) => request(this.axios, this.basePath)).catch((error) => {
                if (error?.response) {
                    return error.response;
                } else {
                    return { status: 500 };
                }
            });
            if (resp?.status === 401) {
                await refreshIdToken();
                console.debug('Retry findSkillDetails ' + i + '/' + (retry - 1));
                continue;
            }
            break;
        }
        return resp;
    }

    /**
     * 資格のリストを取得します。
     * @summary 資格リスト取得
     * @param {string} username ユーザ名
     * @param {string} skillType スキル種別
     * @param {number} skillOrder スキルオーダー
     * @param {number} [page] ページ番号
     * @param {number} [perPage] 1ページあたりの要素数 (MAX100)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SkillApi
     */
    public async findSkillQualifs(username: string, skillType: string, skillOrder: number, page?: number, perPage?: number, options?: AxiosRequestConfig) {
        const retry = 2;
        let resp;
        for (let i = 0; i < retry; i++) {
            resp = await SkillApiFp(this.configuration).findSkillQualifs(username, skillType, skillOrder, page, perPage, options).then((request) => request(this.axios, this.basePath)).catch((error) => {
                if (error?.response) {
                    return error.response;
                } else {
                    return { status: 500 };
                }
            });
            if (resp?.status === 401) {
                await refreshIdToken();
                console.debug('Retry findSkillQualifs ' + i + '/' + (retry - 1));
                continue;
            }
            break;
        }
        return resp;
    }

    /**
     * スキルのリストを取得します。
     * @summary スキルリスト取得
     * @param {string} username ユーザ名
     * @param {number} [page] ページ番号
     * @param {number} [perPage] 1ページあたりの要素数 (MAX100)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SkillApi
     */
    public async findSkills(username: string, page?: number, perPage?: number, options?: AxiosRequestConfig) {
        const retry = 2;
        let resp;
        for (let i = 0; i < retry; i++) {
            resp = await SkillApiFp(this.configuration).findSkills(username, page, perPage, options).then((request) => request(this.axios, this.basePath)).catch((error) => {
                if (error?.response) {
                    return error.response;
                } else {
                    return { status: 500 };
                }
            });
            if (resp?.status === 401) {
                await refreshIdToken();
                console.debug('Retry findSkills ' + i + '/' + (retry - 1));
                continue;
            }
            break;
        }
        return resp;
    }

    /**
     * スキル情報を取得します。
     * @summary スキル情報取得(未)
     * @param {string} username ユーザ名
     * @param {string} skillType スキル種別
     * @param {number} skillOrder スキルオーダー
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SkillApi
     */
    public async getSkill(username: string, skillType: string, skillOrder: number, options?: AxiosRequestConfig) {
        const retry = 2;
        let resp;
        for (let i = 0; i < retry; i++) {
            resp = await SkillApiFp(this.configuration).getSkill(username, skillType, skillOrder, options).then((request) => request(this.axios, this.basePath)).catch((error) => {
                if (error?.response) {
                    return error.response;
                } else {
                    return { status: 500 };
                }
            });
            if (resp?.status === 401) {
                await refreshIdToken();
                console.debug('Retry getSkill ' + i + '/' + (retry - 1));
                continue;
            }
            break;
        }
        return resp;
    }

    /**
     * スキル詳細を取得します。
     * @summary スキル詳細取得
     * @param {string} username ユーザ名
     * @param {string} skillType スキル種別
     * @param {number} skillOrder スキルオーダー
     * @param {number} detailOrder スキル詳細オーダー
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SkillApi
     */
    public async getSkillDetail(username: string, skillType: string, skillOrder: number, detailOrder: number, options?: AxiosRequestConfig) {
        const retry = 2;
        let resp;
        for (let i = 0; i < retry; i++) {
            resp = await SkillApiFp(this.configuration).getSkillDetail(username, skillType, skillOrder, detailOrder, options).then((request) => request(this.axios, this.basePath)).catch((error) => {
                if (error?.response) {
                    return error.response;
                } else {
                    return { status: 500 };
                }
            });
            if (resp?.status === 401) {
                await refreshIdToken();
                console.debug('Retry getSkillDetail ' + i + '/' + (retry - 1));
                continue;
            }
            break;
        }
        return resp;
    }

    /**
     * スキル詳細の資格情報を取得します。
     * @summary スキル詳細の資格情報の取得
     * @param {string} username ユーザ名
     * @param {string} skillType スキル種別
     * @param {number} skillOrder スキルオーダー
     * @param {number} qualifOrder 資格オーダー
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SkillApi
     */
    public async getSkillQualif(username: string, skillType: string, skillOrder: number, qualifOrder: number, options?: AxiosRequestConfig) {
        const retry = 2;
        let resp;
        for (let i = 0; i < retry; i++) {
            resp = await SkillApiFp(this.configuration).getSkillQualif(username, skillType, skillOrder, qualifOrder, options).then((request) => request(this.axios, this.basePath)).catch((error) => {
                if (error?.response) {
                    return error.response;
                } else {
                    return { status: 500 };
                }
            });
            if (resp?.status === 401) {
                await refreshIdToken();
                console.debug('Retry getSkillQualif ' + i + '/' + (retry - 1));
                continue;
            }
            break;
        }
        return resp;
    }

    /**
     * スキル詳細を更新します。
     * @summary スキル詳細を更新
     * @param {string} username ユーザ名
     * @param {string} skillType スキル種別
     * @param {number} skillOrder スキルオーダー
     * @param {number} detailOrder スキル詳細オーダー
     * @param {SkillDetailReq} skillDetailReq スキル詳細情報
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SkillApi
     */
    public async setSkillDetail(username: string, skillType: string, skillOrder: number, detailOrder: number, skillDetailReq: SkillDetailReq, options?: AxiosRequestConfig) {
        const retry = 2;
        let resp;
        for (let i = 0; i < retry; i++) {
            resp = await SkillApiFp(this.configuration).setSkillDetail(username, skillType, skillOrder, detailOrder, skillDetailReq, options).then((request) => request(this.axios, this.basePath)).catch((error) => {
                if (error?.response) {
                    return error.response;
                } else {
                    return { status: 500 };
                }
            });
            if (resp?.status === 401) {
                await refreshIdToken();
                console.debug('Retry setSkillDetail ' + i + '/' + (retry - 1));
                continue;
            }
            break;
        }
        return resp;
    }

    /**
     * スキル詳細の資格情報を更新します。
     * @summary スキル詳細の資格情報の更新
     * @param {string} username ユーザ名
     * @param {string} skillType スキル種別
     * @param {number} skillOrder スキルオーダー
     * @param {number} qualifOrder 資格オーダー
     * @param {QualifReq} qualifReq 資格情報
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SkillApi
     */
    public async setSkillQualif(username: string, skillType: string, skillOrder: number, qualifOrder: number, qualifReq: QualifReq, options?: AxiosRequestConfig) {
        const retry = 2;
        let resp;
        for (let i = 0; i < retry; i++) {
            resp = await SkillApiFp(this.configuration).setSkillQualif(username, skillType, skillOrder, qualifOrder, qualifReq, options).then((request) => request(this.axios, this.basePath)).catch((error) => {
                if (error?.response) {
                    return error.response;
                } else {
                    return { status: 500 };
                }
            });
            if (resp?.status === 401) {
                await refreshIdToken();
                console.debug('Retry setSkillQualif ' + i + '/' + (retry - 1));
                continue;
            }
            break;
        }
        return resp;
    }

    /**
     * スキル情報を更新します。
     * @summary スキル情報更新(未)
     * @param {string} username ユーザ名
     * @param {string} skillType スキル種別
     * @param {number} skillOrder スキルオーダー
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SkillApi
     */
    public async updateSkill(username: string, skillType: string, skillOrder: number, options?: AxiosRequestConfig) {
        const retry = 2;
        let resp;
        for (let i = 0; i < retry; i++) {
            resp = await SkillApiFp(this.configuration).updateSkill(username, skillType, skillOrder, options).then((request) => request(this.axios, this.basePath)).catch((error) => {
                if (error?.response) {
                    return error.response;
                } else {
                    return { status: 500 };
                }
            });
            if (resp?.status === 401) {
                await refreshIdToken();
                console.debug('Retry updateSkill ' + i + '/' + (retry - 1));
                continue;
            }
            break;
        }
        return resp;
    }
}


/**
 * SkimieApi - axios parameter creator
 * @export
 */
export const SkimieApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * バートルテスト結果を計算します。
         * @summary バートルテスト結果計算
         * @param {BartleResultRequest} bartleResultRequest バートルテスト情報
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        calculateBartleResults: async (bartleResultRequest: BartleResultRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'bartleResultRequest' is not null or undefined
            assertParamExists('calculateBartleResults', 'bartleResultRequest', bartleResultRequest)
            const localVarPath = `/skimie/bartle/answers`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(bartleResultRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 自分のスキミーを交代します。
         * @summary スキミー交代
         * @param {string} username ユーザ名
         * @param {number} skimmyId スキミーID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        changeMySkimmy: async (username: string, skimmyId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'username' is not null or undefined
            assertParamExists('changeMySkimmy', 'username', username)
            // verify required parameter 'skimmyId' is not null or undefined
            assertParamExists('changeMySkimmy', 'skimmyId', skimmyId)
            const localVarPath = `/skimie/{username}/skimmy/encyclopedia/{skimmy_id}`
                .replace(`{${"username"}}`, encodeURIComponent(String(username)))
                .replace(`{${"skimmy_id"}}`, encodeURIComponent(String(skimmyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 自分のスキミーを選択します。
         * @summary スキミー選択
         * @param {string} username ユーザ名
         * @param {number} skimmyId スキミーID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        chooseMySkimmy: async (username: string, skimmyId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'username' is not null or undefined
            assertParamExists('chooseMySkimmy', 'username', username)
            // verify required parameter 'skimmyId' is not null or undefined
            assertParamExists('chooseMySkimmy', 'skimmyId', skimmyId)
            const localVarPath = `/skimie/{username}/choose/{skimmy_id}`
                .replace(`{${"username"}}`, encodeURIComponent(String(username)))
                .replace(`{${"skimmy_id"}}`, encodeURIComponent(String(skimmyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * チャットメッセージ取得します。
         * @summary チャットメッセージ取得
         * @param {string} username ユーザ名
         * @param {number} skimmyType スキミータイプ
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findChatMessages: async (username: string, skimmyType: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'username' is not null or undefined
            assertParamExists('findChatMessages', 'username', username)
            // verify required parameter 'skimmyType' is not null or undefined
            assertParamExists('findChatMessages', 'skimmyType', skimmyType)
            const localVarPath = `/skimie/{username}/chat/rooms/{skimmy_type}`
                .replace(`{${"username"}}`, encodeURIComponent(String(username)))
                .replace(`{${"skimmy_type"}}`, encodeURIComponent(String(skimmyType)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * スキミーパーティを取得します。
         * @summary スキミーパーティ取得
         * @param {string} username ユーザ名
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findSkimmyParties: async (username: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'username' is not null or undefined
            assertParamExists('findSkimmyParties', 'username', username)
            const localVarPath = `/skimie/{username}/parties`
                .replace(`{${"username"}}`, encodeURIComponent(String(username)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 保有しているスキミー一覧を取得します。
         * @summary 保有しているスキミー一覧取得
         * @param {string} username ユーザ名
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findUserSkimmies: async (username: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'username' is not null or undefined
            assertParamExists('findUserSkimmies', 'username', username)
            const localVarPath = `/skimie/{username}`
                .replace(`{${"username"}}`, encodeURIComponent(String(username)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * バートル質問を取得します。
         * @summary バートル質問取得
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBartleQuestions: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/skimie/bartle/questions`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * チャットルーム一覧を取得します。
         * @summary チャットルーム一覧取得
         * @param {string} username ユーザ名
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getChatRooms: async (username: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'username' is not null or undefined
            assertParamExists('getChatRooms', 'username', username)
            const localVarPath = `/skimie/{username}/chat/rooms`
                .replace(`{${"username"}}`, encodeURIComponent(String(username)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * スキミー選択リストを取得します。
         * @summary スキミー選択リスト取得
         * @param {string} username ユーザ名
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getChooseSkimmyList: async (username: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'username' is not null or undefined
            assertParamExists('getChooseSkimmyList', 'username', username)
            const localVarPath = `/skimie/{username}/choose`
                .replace(`{${"username"}}`, encodeURIComponent(String(username)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * スキミーを取得します。
         * @summary スキミー取得
         * @param {string} username ユーザ名
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCurrentSkimmy: async (username: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'username' is not null or undefined
            assertParamExists('getCurrentSkimmy', 'username', username)
            const localVarPath = `/skimie/{username}/skimmy/current`
                .replace(`{${"username"}}`, encodeURIComponent(String(username)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * スキミー図鑑を取得します。
         * @summary スキミー図鑑取得
         * @param {string} username ユーザ名
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMySkimmyEncyclopedia: async (username: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'username' is not null or undefined
            assertParamExists('getMySkimmyEncyclopedia', 'username', username)
            const localVarPath = `/skimie/{username}/skimmy/encyclopedia`
                .replace(`{${"username"}}`, encodeURIComponent(String(username)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * アバター画像を取得します。
         * @summary アバター画像取得
         * @param {string} username ユーザ名
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSkimieAvatar: async (username: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'username' is not null or undefined
            assertParamExists('getSkimieAvatar', 'username', username)
            const localVarPath = `/skimie/{username}/avatars/current`
                .replace(`{${"username"}}`, encodeURIComponent(String(username)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 自分のスキミーを成長させます。
         * @summary スキミー成長
         * @param {string} username ユーザ名
         * @param {number} userSkimmyId ユーザースキミーID
         * @param {number} gems ジェム数
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        growCurrentSkimmy: async (username: string, userSkimmyId: number, gems: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'username' is not null or undefined
            assertParamExists('growCurrentSkimmy', 'username', username)
            // verify required parameter 'userSkimmyId' is not null or undefined
            assertParamExists('growCurrentSkimmy', 'userSkimmyId', userSkimmyId)
            // verify required parameter 'gems' is not null or undefined
            assertParamExists('growCurrentSkimmy', 'gems', gems)
            const localVarPath = `/skimie/{username}/grow/{user_skimmy_id}/{gems}`
                .replace(`{${"username"}}`, encodeURIComponent(String(username)))
                .replace(`{${"user_skimmy_id"}}`, encodeURIComponent(String(userSkimmyId)))
                .replace(`{${"gems"}}`, encodeURIComponent(String(gems)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * チャットメッセージ送信します。
         * @summary チャットメッセージ送信
         * @param {string} username ユーザ名
         * @param {number} skimmyType スキミータイプ
         * @param {ChatRoomsSkimmyTypeRep} chatRoomsSkimmyTypeRep チャットメッセージ
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendChatMessage: async (username: string, skimmyType: number, chatRoomsSkimmyTypeRep: ChatRoomsSkimmyTypeRep, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'username' is not null or undefined
            assertParamExists('sendChatMessage', 'username', username)
            // verify required parameter 'skimmyType' is not null or undefined
            assertParamExists('sendChatMessage', 'skimmyType', skimmyType)
            // verify required parameter 'chatRoomsSkimmyTypeRep' is not null or undefined
            assertParamExists('sendChatMessage', 'chatRoomsSkimmyTypeRep', chatRoomsSkimmyTypeRep)
            const localVarPath = `/skimie/{username}/chat/rooms/{skimmy_type}`
                .replace(`{${"username"}}`, encodeURIComponent(String(username)))
                .replace(`{${"skimmy_type"}}`, encodeURIComponent(String(skimmyType)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(chatRoomsSkimmyTypeRep, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * チャットメッセージ送信します。
         * @summary チャットメッセージ送信
         * @param {string} username ユーザ名
         * @param {number} skimmyType スキミータイプ
         * @param {SendChatMessageWithVoiceRequest} [sendChatMessageWithVoiceRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendChatMessageWithVoice: async (username: string, skimmyType: number, sendChatMessageWithVoiceRequest?: SendChatMessageWithVoiceRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'username' is not null or undefined
            assertParamExists('sendChatMessageWithVoice', 'username', username)
            // verify required parameter 'skimmyType' is not null or undefined
            assertParamExists('sendChatMessageWithVoice', 'skimmyType', skimmyType)
            const localVarPath = `/skimie/{username}/chat/rooms/{skimmy_type}/voice`
                .replace(`{${"username"}}`, encodeURIComponent(String(username)))
                .replace(`{${"skimmy_type"}}`, encodeURIComponent(String(skimmyType)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(sendChatMessageWithVoiceRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * バートルテスト結果を設定します。
         * @summary バートルテスト結果設定
         * @param {BartleSetRequest} bartleSetRequest バートルテスト設定情報
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setBartleResult: async (bartleSetRequest: BartleSetRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'bartleSetRequest' is not null or undefined
            assertParamExists('setBartleResult', 'bartleSetRequest', bartleSetRequest)
            const localVarPath = `/skimie/bartle/set`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(bartleSetRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * スキミーデフォルトパーティを設定します。
         * @summary スキミーデフォルトパーティ設定
         * @param {string} username ユーザ名
         * @param {DefaultParty} defaultParty デフォルトパーティ情報
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setDefaultParty: async (username: string, defaultParty: DefaultParty, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'username' is not null or undefined
            assertParamExists('setDefaultParty', 'username', username)
            // verify required parameter 'defaultParty' is not null or undefined
            assertParamExists('setDefaultParty', 'defaultParty', defaultParty)
            const localVarPath = `/skimie/{username}/party/default`
                .replace(`{${"username"}}`, encodeURIComponent(String(username)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(defaultParty, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * スキミーパーティを設定します。
         * @summary スキミーパーティ設定
         * @param {string} username ユーザ名
         * @param {Parties} parties パーティ一覧
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setSkimmyParties: async (username: string, parties: Parties, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'username' is not null or undefined
            assertParamExists('setSkimmyParties', 'username', username)
            // verify required parameter 'parties' is not null or undefined
            assertParamExists('setSkimmyParties', 'parties', parties)
            const localVarPath = `/skimie/{username}/parties`
                .replace(`{${"username"}}`, encodeURIComponent(String(username)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(parties, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SkimieApi - functional programming interface
 * @export
 */
export const SkimieApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = SkimieApiAxiosParamCreator(configuration)
    return {
        /**
         * バートルテスト結果を計算します。
         * @summary バートルテスト結果計算
         * @param {BartleResultRequest} bartleResultRequest バートルテスト情報
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async calculateBartleResults(bartleResultRequest: BartleResultRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BartleResultResp>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.calculateBartleResults(bartleResultRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 自分のスキミーを交代します。
         * @summary スキミー交代
         * @param {string} username ユーザ名
         * @param {number} skimmyId スキミーID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async changeMySkimmy(username: string, skimmyId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.changeMySkimmy(username, skimmyId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 自分のスキミーを選択します。
         * @summary スキミー選択
         * @param {string} username ユーザ名
         * @param {number} skimmyId スキミーID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async chooseMySkimmy(username: string, skimmyId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.chooseMySkimmy(username, skimmyId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * チャットメッセージ取得します。
         * @summary チャットメッセージ取得
         * @param {string} username ユーザ名
         * @param {number} skimmyType スキミータイプ
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findChatMessages(username: string, skimmyType: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ChatRoomMessages>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findChatMessages(username, skimmyType, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * スキミーパーティを取得します。
         * @summary スキミーパーティ取得
         * @param {string} username ユーザ名
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findSkimmyParties(username: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PartiesResp>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findSkimmyParties(username, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 保有しているスキミー一覧を取得します。
         * @summary 保有しているスキミー一覧取得
         * @param {string} username ユーザ名
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findUserSkimmies(username: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Skimmies>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findUserSkimmies(username, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * バートル質問を取得します。
         * @summary バートル質問取得
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getBartleQuestions(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BartleQuestionsResp>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getBartleQuestions(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * チャットルーム一覧を取得します。
         * @summary チャットルーム一覧取得
         * @param {string} username ユーザ名
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getChatRooms(username: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ChatRooms>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getChatRooms(username, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * スキミー選択リストを取得します。
         * @summary スキミー選択リスト取得
         * @param {string} username ユーザ名
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getChooseSkimmyList(username: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ChooseSkimmyListResp>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getChooseSkimmyList(username, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * スキミーを取得します。
         * @summary スキミー取得
         * @param {string} username ユーザ名
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCurrentSkimmy(username: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SkimmyResp>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCurrentSkimmy(username, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * スキミー図鑑を取得します。
         * @summary スキミー図鑑取得
         * @param {string} username ユーザ名
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMySkimmyEncyclopedia(username: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EncyclopediaResp>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getMySkimmyEncyclopedia(username, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * アバター画像を取得します。
         * @summary アバター画像取得
         * @param {string} username ユーザ名
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSkimieAvatar(username: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AvatarResp>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSkimieAvatar(username, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 自分のスキミーを成長させます。
         * @summary スキミー成長
         * @param {string} username ユーザ名
         * @param {number} userSkimmyId ユーザースキミーID
         * @param {number} gems ジェム数
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async growCurrentSkimmy(username: string, userSkimmyId: number, gems: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.growCurrentSkimmy(username, userSkimmyId, gems, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * チャットメッセージ送信します。
         * @summary チャットメッセージ送信
         * @param {string} username ユーザ名
         * @param {number} skimmyType スキミータイプ
         * @param {ChatRoomsSkimmyTypeRep} chatRoomsSkimmyTypeRep チャットメッセージ
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async sendChatMessage(username: string, skimmyType: number, chatRoomsSkimmyTypeRep: ChatRoomsSkimmyTypeRep, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ChatRoomsSkimmyTypeResp>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.sendChatMessage(username, skimmyType, chatRoomsSkimmyTypeRep, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * チャットメッセージ送信します。
         * @summary チャットメッセージ送信
         * @param {string} username ユーザ名
         * @param {number} skimmyType スキミータイプ
         * @param {SendChatMessageWithVoiceRequest} [sendChatMessageWithVoiceRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async sendChatMessageWithVoice(username: string, skimmyType: number, sendChatMessageWithVoiceRequest?: SendChatMessageWithVoiceRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ChatRoomsSkimmyTypeResp>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.sendChatMessageWithVoice(username, skimmyType, sendChatMessageWithVoiceRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * バートルテスト結果を設定します。
         * @summary バートルテスト結果設定
         * @param {BartleSetRequest} bartleSetRequest バートルテスト設定情報
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async setBartleResult(bartleSetRequest: BartleSetRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.setBartleResult(bartleSetRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * スキミーデフォルトパーティを設定します。
         * @summary スキミーデフォルトパーティ設定
         * @param {string} username ユーザ名
         * @param {DefaultParty} defaultParty デフォルトパーティ情報
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async setDefaultParty(username: string, defaultParty: DefaultParty, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.setDefaultParty(username, defaultParty, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * スキミーパーティを設定します。
         * @summary スキミーパーティ設定
         * @param {string} username ユーザ名
         * @param {Parties} parties パーティ一覧
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async setSkimmyParties(username: string, parties: Parties, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PartiesResp>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.setSkimmyParties(username, parties, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * SkimieApi - factory interface
 * @export
 */
export const SkimieApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = SkimieApiFp(configuration)
    return {
        /**
         * バートルテスト結果を計算します。
         * @summary バートルテスト結果計算
         * @param {BartleResultRequest} bartleResultRequest バートルテスト情報
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        calculateBartleResults(bartleResultRequest: BartleResultRequest, options?: any): AxiosPromise<BartleResultResp> {
            return localVarFp.calculateBartleResults(bartleResultRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 自分のスキミーを交代します。
         * @summary スキミー交代
         * @param {string} username ユーザ名
         * @param {number} skimmyId スキミーID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        changeMySkimmy(username: string, skimmyId: number, options?: any): AxiosPromise<void> {
            return localVarFp.changeMySkimmy(username, skimmyId, options).then((request) => request(axios, basePath));
        },
        /**
         * 自分のスキミーを選択します。
         * @summary スキミー選択
         * @param {string} username ユーザ名
         * @param {number} skimmyId スキミーID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        chooseMySkimmy(username: string, skimmyId: number, options?: any): AxiosPromise<void> {
            return localVarFp.chooseMySkimmy(username, skimmyId, options).then((request) => request(axios, basePath));
        },
        /**
         * チャットメッセージ取得します。
         * @summary チャットメッセージ取得
         * @param {string} username ユーザ名
         * @param {number} skimmyType スキミータイプ
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findChatMessages(username: string, skimmyType: number, options?: any): AxiosPromise<ChatRoomMessages> {
            return localVarFp.findChatMessages(username, skimmyType, options).then((request) => request(axios, basePath));
        },
        /**
         * スキミーパーティを取得します。
         * @summary スキミーパーティ取得
         * @param {string} username ユーザ名
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findSkimmyParties(username: string, options?: any): AxiosPromise<PartiesResp> {
            return localVarFp.findSkimmyParties(username, options).then((request) => request(axios, basePath));
        },
        /**
         * 保有しているスキミー一覧を取得します。
         * @summary 保有しているスキミー一覧取得
         * @param {string} username ユーザ名
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findUserSkimmies(username: string, options?: any): AxiosPromise<Skimmies> {
            return localVarFp.findUserSkimmies(username, options).then((request) => request(axios, basePath));
        },
        /**
         * バートル質問を取得します。
         * @summary バートル質問取得
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBartleQuestions(options?: any): AxiosPromise<BartleQuestionsResp> {
            return localVarFp.getBartleQuestions(options).then((request) => request(axios, basePath));
        },
        /**
         * チャットルーム一覧を取得します。
         * @summary チャットルーム一覧取得
         * @param {string} username ユーザ名
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getChatRooms(username: string, options?: any): AxiosPromise<ChatRooms> {
            return localVarFp.getChatRooms(username, options).then((request) => request(axios, basePath));
        },
        /**
         * スキミー選択リストを取得します。
         * @summary スキミー選択リスト取得
         * @param {string} username ユーザ名
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getChooseSkimmyList(username: string, options?: any): AxiosPromise<ChooseSkimmyListResp> {
            return localVarFp.getChooseSkimmyList(username, options).then((request) => request(axios, basePath));
        },
        /**
         * スキミーを取得します。
         * @summary スキミー取得
         * @param {string} username ユーザ名
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCurrentSkimmy(username: string, options?: any): AxiosPromise<SkimmyResp> {
            return localVarFp.getCurrentSkimmy(username, options).then((request) => request(axios, basePath));
        },
        /**
         * スキミー図鑑を取得します。
         * @summary スキミー図鑑取得
         * @param {string} username ユーザ名
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMySkimmyEncyclopedia(username: string, options?: any): AxiosPromise<EncyclopediaResp> {
            return localVarFp.getMySkimmyEncyclopedia(username, options).then((request) => request(axios, basePath));
        },
        /**
         * アバター画像を取得します。
         * @summary アバター画像取得
         * @param {string} username ユーザ名
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSkimieAvatar(username: string, options?: any): AxiosPromise<AvatarResp> {
            return localVarFp.getSkimieAvatar(username, options).then((request) => request(axios, basePath));
        },
        /**
         * 自分のスキミーを成長させます。
         * @summary スキミー成長
         * @param {string} username ユーザ名
         * @param {number} userSkimmyId ユーザースキミーID
         * @param {number} gems ジェム数
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        growCurrentSkimmy(username: string, userSkimmyId: number, gems: number, options?: any): AxiosPromise<void> {
            return localVarFp.growCurrentSkimmy(username, userSkimmyId, gems, options).then((request) => request(axios, basePath));
        },
        /**
         * チャットメッセージ送信します。
         * @summary チャットメッセージ送信
         * @param {string} username ユーザ名
         * @param {number} skimmyType スキミータイプ
         * @param {ChatRoomsSkimmyTypeRep} chatRoomsSkimmyTypeRep チャットメッセージ
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendChatMessage(username: string, skimmyType: number, chatRoomsSkimmyTypeRep: ChatRoomsSkimmyTypeRep, options?: any): AxiosPromise<ChatRoomsSkimmyTypeResp> {
            return localVarFp.sendChatMessage(username, skimmyType, chatRoomsSkimmyTypeRep, options).then((request) => request(axios, basePath));
        },
        /**
         * チャットメッセージ送信します。
         * @summary チャットメッセージ送信
         * @param {string} username ユーザ名
         * @param {number} skimmyType スキミータイプ
         * @param {SendChatMessageWithVoiceRequest} [sendChatMessageWithVoiceRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendChatMessageWithVoice(username: string, skimmyType: number, sendChatMessageWithVoiceRequest?: SendChatMessageWithVoiceRequest, options?: any): AxiosPromise<ChatRoomsSkimmyTypeResp> {
            return localVarFp.sendChatMessageWithVoice(username, skimmyType, sendChatMessageWithVoiceRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * バートルテスト結果を設定します。
         * @summary バートルテスト結果設定
         * @param {BartleSetRequest} bartleSetRequest バートルテスト設定情報
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setBartleResult(bartleSetRequest: BartleSetRequest, options?: any): AxiosPromise<void> {
            return localVarFp.setBartleResult(bartleSetRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * スキミーデフォルトパーティを設定します。
         * @summary スキミーデフォルトパーティ設定
         * @param {string} username ユーザ名
         * @param {DefaultParty} defaultParty デフォルトパーティ情報
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setDefaultParty(username: string, defaultParty: DefaultParty, options?: any): AxiosPromise<void> {
            return localVarFp.setDefaultParty(username, defaultParty, options).then((request) => request(axios, basePath));
        },
        /**
         * スキミーパーティを設定します。
         * @summary スキミーパーティ設定
         * @param {string} username ユーザ名
         * @param {Parties} parties パーティ一覧
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setSkimmyParties(username: string, parties: Parties, options?: any): AxiosPromise<PartiesResp> {
            return localVarFp.setSkimmyParties(username, parties, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * SkimieApi - object-oriented interface
 * @export
 * @class SkimieApi
 * @extends {BaseAPI}
 */
export class SkimieApi extends BaseAPI {
    /**
     * バートルテスト結果を計算します。
     * @summary バートルテスト結果計算
     * @param {BartleResultRequest} bartleResultRequest バートルテスト情報
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SkimieApi
     */
    public async calculateBartleResults(bartleResultRequest: BartleResultRequest, options?: AxiosRequestConfig) {
        const retry = 2;
        let resp;
        for (let i = 0; i < retry; i++) {
            resp = await SkimieApiFp(this.configuration).calculateBartleResults(bartleResultRequest, options).then((request) => request(this.axios, this.basePath)).catch((error) => {
                if (error?.response) {
                    return error.response;
                } else {
                    return { status: 500 };
                }
            });
            if (resp?.status === 401) {
                await refreshIdToken();
                console.debug('Retry calculateBartleResults ' + i + '/' + (retry - 1));
                continue;
            }
            break;
        }
        return resp;
    }

    /**
     * 自分のスキミーを交代します。
     * @summary スキミー交代
     * @param {string} username ユーザ名
     * @param {number} skimmyId スキミーID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SkimieApi
     */
    public async changeMySkimmy(username: string, skimmyId: number, options?: AxiosRequestConfig) {
        const retry = 2;
        let resp;
        for (let i = 0; i < retry; i++) {
            resp = await SkimieApiFp(this.configuration).changeMySkimmy(username, skimmyId, options).then((request) => request(this.axios, this.basePath)).catch((error) => {
                if (error?.response) {
                    return error.response;
                } else {
                    return { status: 500 };
                }
            });
            if (resp?.status === 401) {
                await refreshIdToken();
                console.debug('Retry changeMySkimmy ' + i + '/' + (retry - 1));
                continue;
            }
            break;
        }
        return resp;
    }

    /**
     * 自分のスキミーを選択します。
     * @summary スキミー選択
     * @param {string} username ユーザ名
     * @param {number} skimmyId スキミーID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SkimieApi
     */
    public async chooseMySkimmy(username: string, skimmyId: number, options?: AxiosRequestConfig) {
        const retry = 2;
        let resp;
        for (let i = 0; i < retry; i++) {
            resp = await SkimieApiFp(this.configuration).chooseMySkimmy(username, skimmyId, options).then((request) => request(this.axios, this.basePath)).catch((error) => {
                if (error?.response) {
                    return error.response;
                } else {
                    return { status: 500 };
                }
            });
            if (resp?.status === 401) {
                await refreshIdToken();
                console.debug('Retry chooseMySkimmy ' + i + '/' + (retry - 1));
                continue;
            }
            break;
        }
        return resp;
    }

    /**
     * チャットメッセージ取得します。
     * @summary チャットメッセージ取得
     * @param {string} username ユーザ名
     * @param {number} skimmyType スキミータイプ
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SkimieApi
     */
    public async findChatMessages(username: string, skimmyType: number, options?: AxiosRequestConfig) {
        const retry = 2;
        let resp;
        for (let i = 0; i < retry; i++) {
            resp = await SkimieApiFp(this.configuration).findChatMessages(username, skimmyType, options).then((request) => request(this.axios, this.basePath)).catch((error) => {
                if (error?.response) {
                    return error.response;
                } else {
                    return { status: 500 };
                }
            });
            if (resp?.status === 401) {
                await refreshIdToken();
                console.debug('Retry findChatMessages ' + i + '/' + (retry - 1));
                continue;
            }
            break;
        }
        return resp;
    }

    /**
     * スキミーパーティを取得します。
     * @summary スキミーパーティ取得
     * @param {string} username ユーザ名
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SkimieApi
     */
    public async findSkimmyParties(username: string, options?: AxiosRequestConfig) {
        const retry = 2;
        let resp;
        for (let i = 0; i < retry; i++) {
            resp = await SkimieApiFp(this.configuration).findSkimmyParties(username, options).then((request) => request(this.axios, this.basePath)).catch((error) => {
                if (error?.response) {
                    return error.response;
                } else {
                    return { status: 500 };
                }
            });
            if (resp?.status === 401) {
                await refreshIdToken();
                console.debug('Retry findSkimmyParties ' + i + '/' + (retry - 1));
                continue;
            }
            break;
        }
        return resp;
    }

    /**
     * 保有しているスキミー一覧を取得します。
     * @summary 保有しているスキミー一覧取得
     * @param {string} username ユーザ名
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SkimieApi
     */
    public async findUserSkimmies(username: string, options?: AxiosRequestConfig) {
        const retry = 2;
        let resp;
        for (let i = 0; i < retry; i++) {
            resp = await SkimieApiFp(this.configuration).findUserSkimmies(username, options).then((request) => request(this.axios, this.basePath)).catch((error) => {
                if (error?.response) {
                    return error.response;
                } else {
                    return { status: 500 };
                }
            });
            if (resp?.status === 401) {
                await refreshIdToken();
                console.debug('Retry findUserSkimmies ' + i + '/' + (retry - 1));
                continue;
            }
            break;
        }
        return resp;
    }

    /**
     * バートル質問を取得します。
     * @summary バートル質問取得
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SkimieApi
     */
    public async getBartleQuestions(options?: AxiosRequestConfig) {
        const retry = 2;
        let resp;
        for (let i = 0; i < retry; i++) {
            resp = await SkimieApiFp(this.configuration).getBartleQuestions(options).then((request) => request(this.axios, this.basePath)).catch((error) => {
                if (error?.response) {
                    return error.response;
                } else {
                    return { status: 500 };
                }
            });
            if (resp?.status === 401) {
                await refreshIdToken();
                console.debug('Retry getBartleQuestions ' + i + '/' + (retry - 1));
                continue;
            }
            break;
        }
        return resp;
    }

    /**
     * チャットルーム一覧を取得します。
     * @summary チャットルーム一覧取得
     * @param {string} username ユーザ名
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SkimieApi
     */
    public async getChatRooms(username: string, options?: AxiosRequestConfig) {
        const retry = 2;
        let resp;
        for (let i = 0; i < retry; i++) {
            resp = await SkimieApiFp(this.configuration).getChatRooms(username, options).then((request) => request(this.axios, this.basePath)).catch((error) => {
                if (error?.response) {
                    return error.response;
                } else {
                    return { status: 500 };
                }
            });
            if (resp?.status === 401) {
                await refreshIdToken();
                console.debug('Retry getChatRooms ' + i + '/' + (retry - 1));
                continue;
            }
            break;
        }
        return resp;
    }

    /**
     * スキミー選択リストを取得します。
     * @summary スキミー選択リスト取得
     * @param {string} username ユーザ名
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SkimieApi
     */
    public async getChooseSkimmyList(username: string, options?: AxiosRequestConfig) {
        const retry = 2;
        let resp;
        for (let i = 0; i < retry; i++) {
            resp = await SkimieApiFp(this.configuration).getChooseSkimmyList(username, options).then((request) => request(this.axios, this.basePath)).catch((error) => {
                if (error?.response) {
                    return error.response;
                } else {
                    return { status: 500 };
                }
            });
            if (resp?.status === 401) {
                await refreshIdToken();
                console.debug('Retry getChooseSkimmyList ' + i + '/' + (retry - 1));
                continue;
            }
            break;
        }
        return resp;
    }

    /**
     * スキミーを取得します。
     * @summary スキミー取得
     * @param {string} username ユーザ名
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SkimieApi
     */
    public async getCurrentSkimmy(username: string, options?: AxiosRequestConfig) {
        const retry = 2;
        let resp;
        for (let i = 0; i < retry; i++) {
            resp = await SkimieApiFp(this.configuration).getCurrentSkimmy(username, options).then((request) => request(this.axios, this.basePath)).catch((error) => {
                if (error?.response) {
                    return error.response;
                } else {
                    return { status: 500 };
                }
            });
            if (resp?.status === 401) {
                await refreshIdToken();
                console.debug('Retry getCurrentSkimmy ' + i + '/' + (retry - 1));
                continue;
            }
            break;
        }
        return resp;
    }

    /**
     * スキミー図鑑を取得します。
     * @summary スキミー図鑑取得
     * @param {string} username ユーザ名
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SkimieApi
     */
    public async getMySkimmyEncyclopedia(username: string, options?: AxiosRequestConfig) {
        const retry = 2;
        let resp;
        for (let i = 0; i < retry; i++) {
            resp = await SkimieApiFp(this.configuration).getMySkimmyEncyclopedia(username, options).then((request) => request(this.axios, this.basePath)).catch((error) => {
                if (error?.response) {
                    return error.response;
                } else {
                    return { status: 500 };
                }
            });
            if (resp?.status === 401) {
                await refreshIdToken();
                console.debug('Retry getMySkimmyEncyclopedia ' + i + '/' + (retry - 1));
                continue;
            }
            break;
        }
        return resp;
    }

    /**
     * アバター画像を取得します。
     * @summary アバター画像取得
     * @param {string} username ユーザ名
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SkimieApi
     */
    public async getSkimieAvatar(username: string, options?: AxiosRequestConfig) {
        const retry = 2;
        let resp;
        for (let i = 0; i < retry; i++) {
            resp = await SkimieApiFp(this.configuration).getSkimieAvatar(username, options).then((request) => request(this.axios, this.basePath)).catch((error) => {
                if (error?.response) {
                    return error.response;
                } else {
                    return { status: 500 };
                }
            });
            if (resp?.status === 401) {
                await refreshIdToken();
                console.debug('Retry getSkimieAvatar ' + i + '/' + (retry - 1));
                continue;
            }
            break;
        }
        return resp;
    }

    /**
     * 自分のスキミーを成長させます。
     * @summary スキミー成長
     * @param {string} username ユーザ名
     * @param {number} userSkimmyId ユーザースキミーID
     * @param {number} gems ジェム数
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SkimieApi
     */
    public async growCurrentSkimmy(username: string, userSkimmyId: number, gems: number, options?: AxiosRequestConfig) {
        const retry = 2;
        let resp;
        for (let i = 0; i < retry; i++) {
            resp = await SkimieApiFp(this.configuration).growCurrentSkimmy(username, userSkimmyId, gems, options).then((request) => request(this.axios, this.basePath)).catch((error) => {
                if (error?.response) {
                    return error.response;
                } else {
                    return { status: 500 };
                }
            });
            if (resp?.status === 401) {
                await refreshIdToken();
                console.debug('Retry growCurrentSkimmy ' + i + '/' + (retry - 1));
                continue;
            }
            break;
        }
        return resp;
    }

    /**
     * チャットメッセージ送信します。
     * @summary チャットメッセージ送信
     * @param {string} username ユーザ名
     * @param {number} skimmyType スキミータイプ
     * @param {ChatRoomsSkimmyTypeRep} chatRoomsSkimmyTypeRep チャットメッセージ
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SkimieApi
     */
    public async sendChatMessage(username: string, skimmyType: number, chatRoomsSkimmyTypeRep: ChatRoomsSkimmyTypeRep, options?: AxiosRequestConfig) {
        const retry = 2;
        let resp;
        for (let i = 0; i < retry; i++) {
            resp = await SkimieApiFp(this.configuration).sendChatMessage(username, skimmyType, chatRoomsSkimmyTypeRep, options).then((request) => request(this.axios, this.basePath)).catch((error) => {
                if (error?.response) {
                    return error.response;
                } else {
                    return { status: 500 };
                }
            });
            if (resp?.status === 401) {
                await refreshIdToken();
                console.debug('Retry sendChatMessage ' + i + '/' + (retry - 1));
                continue;
            }
            break;
        }
        return resp;
    }

    /**
     * チャットメッセージ送信します。
     * @summary チャットメッセージ送信
     * @param {string} username ユーザ名
     * @param {number} skimmyType スキミータイプ
     * @param {SendChatMessageWithVoiceRequest} [sendChatMessageWithVoiceRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SkimieApi
     */
    public async sendChatMessageWithVoice(username: string, skimmyType: number, sendChatMessageWithVoiceRequest?: SendChatMessageWithVoiceRequest, options?: AxiosRequestConfig) {
        const retry = 2;
        let resp;
        for (let i = 0; i < retry; i++) {
            resp = await SkimieApiFp(this.configuration).sendChatMessageWithVoice(username, skimmyType, sendChatMessageWithVoiceRequest, options).then((request) => request(this.axios, this.basePath)).catch((error) => {
                if (error?.response) {
                    return error.response;
                } else {
                    return { status: 500 };
                }
            });
            if (resp?.status === 401) {
                await refreshIdToken();
                console.debug('Retry sendChatMessageWithVoice ' + i + '/' + (retry - 1));
                continue;
            }
            break;
        }
        return resp;
    }

    /**
     * バートルテスト結果を設定します。
     * @summary バートルテスト結果設定
     * @param {BartleSetRequest} bartleSetRequest バートルテスト設定情報
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SkimieApi
     */
    public async setBartleResult(bartleSetRequest: BartleSetRequest, options?: AxiosRequestConfig) {
        const retry = 2;
        let resp;
        for (let i = 0; i < retry; i++) {
            resp = await SkimieApiFp(this.configuration).setBartleResult(bartleSetRequest, options).then((request) => request(this.axios, this.basePath)).catch((error) => {
                if (error?.response) {
                    return error.response;
                } else {
                    return { status: 500 };
                }
            });
            if (resp?.status === 401) {
                await refreshIdToken();
                console.debug('Retry setBartleResult ' + i + '/' + (retry - 1));
                continue;
            }
            break;
        }
        return resp;
    }

    /**
     * スキミーデフォルトパーティを設定します。
     * @summary スキミーデフォルトパーティ設定
     * @param {string} username ユーザ名
     * @param {DefaultParty} defaultParty デフォルトパーティ情報
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SkimieApi
     */
    public async setDefaultParty(username: string, defaultParty: DefaultParty, options?: AxiosRequestConfig) {
        const retry = 2;
        let resp;
        for (let i = 0; i < retry; i++) {
            resp = await SkimieApiFp(this.configuration).setDefaultParty(username, defaultParty, options).then((request) => request(this.axios, this.basePath)).catch((error) => {
                if (error?.response) {
                    return error.response;
                } else {
                    return { status: 500 };
                }
            });
            if (resp?.status === 401) {
                await refreshIdToken();
                console.debug('Retry setDefaultParty ' + i + '/' + (retry - 1));
                continue;
            }
            break;
        }
        return resp;
    }

    /**
     * スキミーパーティを設定します。
     * @summary スキミーパーティ設定
     * @param {string} username ユーザ名
     * @param {Parties} parties パーティ一覧
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SkimieApi
     */
    public async setSkimmyParties(username: string, parties: Parties, options?: AxiosRequestConfig) {
        const retry = 2;
        let resp;
        for (let i = 0; i < retry; i++) {
            resp = await SkimieApiFp(this.configuration).setSkimmyParties(username, parties, options).then((request) => request(this.axios, this.basePath)).catch((error) => {
                if (error?.response) {
                    return error.response;
                } else {
                    return { status: 500 };
                }
            });
            if (resp?.status === 401) {
                await refreshIdToken();
                console.debug('Retry setSkimmyParties ' + i + '/' + (retry - 1));
                continue;
            }
            break;
        }
        return resp;
    }
}


/**
 * TaktApi - axios parameter creator
 * @export
 */
export const TaktApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * リポジトリを作成します。
         * @summary リポジトリ作成(未)
         * @param {string} username ユーザ名
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addRepo: async (username: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'username' is not null or undefined
            assertParamExists('addRepo', 'username', username)
            const localVarPath = `/takt/{username}/repos`
                .replace(`{${"username"}}`, encodeURIComponent(String(username)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * リポジトリリストを取得します。
         * @summary リポジトリリスト取得(未)
         * @param {string} username ユーザ名
         * @param {number} [page] ページ番号
         * @param {number} [perPage] 1ページあたりの要素数 (MAX100)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findRepos: async (username: string, page?: number, perPage?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'username' is not null or undefined
            assertParamExists('findRepos', 'username', username)
            const localVarPath = `/takt/{username}/repos`
                .replace(`{${"username"}}`, encodeURIComponent(String(username)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (perPage !== undefined) {
                localVarQueryParameter['per_page'] = perPage;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * リポジトリ情報を取得します。
         * @summary リポジトリ情報取得(未)
         * @param {string} username ユーザ名
         * @param {string} repo リポジトリ名
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRepo: async (username: string, repo: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'username' is not null or undefined
            assertParamExists('getRepo', 'username', username)
            // verify required parameter 'repo' is not null or undefined
            assertParamExists('getRepo', 'repo', repo)
            const localVarPath = `/takt/{username}/repos/{repo}`
                .replace(`{${"username"}}`, encodeURIComponent(String(username)))
                .replace(`{${"repo"}}`, encodeURIComponent(String(repo)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * リポジトリ情報を更新します。
         * @summary リポジトリ情報更新(未)
         * @param {string} username ユーザ名
         * @param {string} repo リポジトリ名
         * @param {RepoRequest} repoRequest リポジトリ情報
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateRepo: async (username: string, repo: string, repoRequest: RepoRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'username' is not null or undefined
            assertParamExists('updateRepo', 'username', username)
            // verify required parameter 'repo' is not null or undefined
            assertParamExists('updateRepo', 'repo', repo)
            // verify required parameter 'repoRequest' is not null or undefined
            assertParamExists('updateRepo', 'repoRequest', repoRequest)
            const localVarPath = `/takt/{username}/repos/{repo}`
                .replace(`{${"username"}}`, encodeURIComponent(String(username)))
                .replace(`{${"repo"}}`, encodeURIComponent(String(repo)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(repoRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * TaktApi - functional programming interface
 * @export
 */
export const TaktApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = TaktApiAxiosParamCreator(configuration)
    return {
        /**
         * リポジトリを作成します。
         * @summary リポジトリ作成(未)
         * @param {string} username ユーザ名
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addRepo(username: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Repo>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addRepo(username, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * リポジトリリストを取得します。
         * @summary リポジトリリスト取得(未)
         * @param {string} username ユーザ名
         * @param {number} [page] ページ番号
         * @param {number} [perPage] 1ページあたりの要素数 (MAX100)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findRepos(username: string, page?: number, perPage?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Repos>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findRepos(username, page, perPage, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * リポジトリ情報を取得します。
         * @summary リポジトリ情報取得(未)
         * @param {string} username ユーザ名
         * @param {string} repo リポジトリ名
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getRepo(username: string, repo: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Repo>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getRepo(username, repo, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * リポジトリ情報を更新します。
         * @summary リポジトリ情報更新(未)
         * @param {string} username ユーザ名
         * @param {string} repo リポジトリ名
         * @param {RepoRequest} repoRequest リポジトリ情報
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateRepo(username: string, repo: string, repoRequest: RepoRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Repo>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateRepo(username, repo, repoRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * TaktApi - factory interface
 * @export
 */
export const TaktApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = TaktApiFp(configuration)
    return {
        /**
         * リポジトリを作成します。
         * @summary リポジトリ作成(未)
         * @param {string} username ユーザ名
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addRepo(username: string, options?: any): AxiosPromise<Repo> {
            return localVarFp.addRepo(username, options).then((request) => request(axios, basePath));
        },
        /**
         * リポジトリリストを取得します。
         * @summary リポジトリリスト取得(未)
         * @param {string} username ユーザ名
         * @param {number} [page] ページ番号
         * @param {number} [perPage] 1ページあたりの要素数 (MAX100)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findRepos(username: string, page?: number, perPage?: number, options?: any): AxiosPromise<Repos> {
            return localVarFp.findRepos(username, page, perPage, options).then((request) => request(axios, basePath));
        },
        /**
         * リポジトリ情報を取得します。
         * @summary リポジトリ情報取得(未)
         * @param {string} username ユーザ名
         * @param {string} repo リポジトリ名
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRepo(username: string, repo: string, options?: any): AxiosPromise<Repo> {
            return localVarFp.getRepo(username, repo, options).then((request) => request(axios, basePath));
        },
        /**
         * リポジトリ情報を更新します。
         * @summary リポジトリ情報更新(未)
         * @param {string} username ユーザ名
         * @param {string} repo リポジトリ名
         * @param {RepoRequest} repoRequest リポジトリ情報
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateRepo(username: string, repo: string, repoRequest: RepoRequest, options?: any): AxiosPromise<Repo> {
            return localVarFp.updateRepo(username, repo, repoRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * TaktApi - object-oriented interface
 * @export
 * @class TaktApi
 * @extends {BaseAPI}
 */
export class TaktApi extends BaseAPI {
    /**
     * リポジトリを作成します。
     * @summary リポジトリ作成(未)
     * @param {string} username ユーザ名
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TaktApi
     */
    public async addRepo(username: string, options?: AxiosRequestConfig) {
        const retry = 2;
        let resp;
        for (let i = 0; i < retry; i++) {
            resp = await TaktApiFp(this.configuration).addRepo(username, options).then((request) => request(this.axios, this.basePath)).catch((error) => {
                if (error?.response) {
                    return error.response;
                } else {
                    return { status: 500 };
                }
            });
            if (resp?.status === 401) {
                await refreshIdToken();
                console.debug('Retry addRepo ' + i + '/' + (retry - 1));
                continue;
            }
            break;
        }
        return resp;
    }

    /**
     * リポジトリリストを取得します。
     * @summary リポジトリリスト取得(未)
     * @param {string} username ユーザ名
     * @param {number} [page] ページ番号
     * @param {number} [perPage] 1ページあたりの要素数 (MAX100)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TaktApi
     */
    public async findRepos(username: string, page?: number, perPage?: number, options?: AxiosRequestConfig) {
        const retry = 2;
        let resp;
        for (let i = 0; i < retry; i++) {
            resp = await TaktApiFp(this.configuration).findRepos(username, page, perPage, options).then((request) => request(this.axios, this.basePath)).catch((error) => {
                if (error?.response) {
                    return error.response;
                } else {
                    return { status: 500 };
                }
            });
            if (resp?.status === 401) {
                await refreshIdToken();
                console.debug('Retry findRepos ' + i + '/' + (retry - 1));
                continue;
            }
            break;
        }
        return resp;
    }

    /**
     * リポジトリ情報を取得します。
     * @summary リポジトリ情報取得(未)
     * @param {string} username ユーザ名
     * @param {string} repo リポジトリ名
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TaktApi
     */
    public async getRepo(username: string, repo: string, options?: AxiosRequestConfig) {
        const retry = 2;
        let resp;
        for (let i = 0; i < retry; i++) {
            resp = await TaktApiFp(this.configuration).getRepo(username, repo, options).then((request) => request(this.axios, this.basePath)).catch((error) => {
                if (error?.response) {
                    return error.response;
                } else {
                    return { status: 500 };
                }
            });
            if (resp?.status === 401) {
                await refreshIdToken();
                console.debug('Retry getRepo ' + i + '/' + (retry - 1));
                continue;
            }
            break;
        }
        return resp;
    }

    /**
     * リポジトリ情報を更新します。
     * @summary リポジトリ情報更新(未)
     * @param {string} username ユーザ名
     * @param {string} repo リポジトリ名
     * @param {RepoRequest} repoRequest リポジトリ情報
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TaktApi
     */
    public async updateRepo(username: string, repo: string, repoRequest: RepoRequest, options?: AxiosRequestConfig) {
        const retry = 2;
        let resp;
        for (let i = 0; i < retry; i++) {
            resp = await TaktApiFp(this.configuration).updateRepo(username, repo, repoRequest, options).then((request) => request(this.axios, this.basePath)).catch((error) => {
                if (error?.response) {
                    return error.response;
                } else {
                    return { status: 500 };
                }
            });
            if (resp?.status === 401) {
                await refreshIdToken();
                console.debug('Retry updateRepo ' + i + '/' + (retry - 1));
                continue;
            }
            break;
        }
        return resp;
    }
}


/**
 * TaskApi - axios parameter creator
 * @export
 */
export const TaskApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 自分自身のタスクを追加します。
         * @summary タスク追加
         * @param {TaskRequest} taskRequest タスク情報
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addMyTask: async (taskRequest: TaskRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'taskRequest' is not null or undefined
            assertParamExists('addMyTask', 'taskRequest', taskRequest)
            const localVarPath = `/tasks`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(taskRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * コメントを追加します。
         * @summary コメントを追加
         * @param {number} taskId タスクID
         * @param {CommentRequest} commentRequest コメント情報
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addTaskComment: async (taskId: number, commentRequest: CommentRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'taskId' is not null or undefined
            assertParamExists('addTaskComment', 'taskId', taskId)
            // verify required parameter 'commentRequest' is not null or undefined
            assertParamExists('addTaskComment', 'commentRequest', commentRequest)
            const localVarPath = `/tasks/{task_id}/comments`
                .replace(`{${"task_id"}}`, encodeURIComponent(String(taskId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(commentRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 自分自身のタスクを追加します。
         * @summary タスク追加
         * @param {string} teamname チーム名
         * @param {number} projectOrder プロジェクトオーダー
         * @param {TaskRequest} taskRequest タスク情報
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addTeamTask: async (teamname: string, projectOrder: number, taskRequest: TaskRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'teamname' is not null or undefined
            assertParamExists('addTeamTask', 'teamname', teamname)
            // verify required parameter 'projectOrder' is not null or undefined
            assertParamExists('addTeamTask', 'projectOrder', projectOrder)
            // verify required parameter 'taskRequest' is not null or undefined
            assertParamExists('addTeamTask', 'taskRequest', taskRequest)
            const localVarPath = `/teams/{teamname}/projs/{project_order}/tasks`
                .replace(`{${"teamname"}}`, encodeURIComponent(String(teamname)))
                .replace(`{${"project_order"}}`, encodeURIComponent(String(projectOrder)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(taskRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * マイタスクを削除します。
         * @summary マイタスク削除
         * @param {number} taskId タスクID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteMyTask: async (taskId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'taskId' is not null or undefined
            assertParamExists('deleteMyTask', 'taskId', taskId)
            const localVarPath = `/tasks/my/delete/{task_id}`
                .replace(`{${"task_id"}}`, encodeURIComponent(String(taskId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * コメントを削除します。
         * @summary コメントを削除
         * @param {number} taskId タスクID
         * @param {number} commentId コメントID
         * @param {CommentRequest} commentRequest タスク情報
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteTaskComment: async (taskId: number, commentId: number, commentRequest: CommentRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'taskId' is not null or undefined
            assertParamExists('deleteTaskComment', 'taskId', taskId)
            // verify required parameter 'commentId' is not null or undefined
            assertParamExists('deleteTaskComment', 'commentId', commentId)
            // verify required parameter 'commentRequest' is not null or undefined
            assertParamExists('deleteTaskComment', 'commentRequest', commentRequest)
            const localVarPath = `/tasks/{task_id}/comments/{comment_id}`
                .replace(`{${"task_id"}}`, encodeURIComponent(String(taskId)))
                .replace(`{${"comment_id"}}`, encodeURIComponent(String(commentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(commentRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 自分自身の期限日超過タスクのリストを取得します。
         * @summary 期限日超過タスクリスト取得
         * @param {number} [page] ページ番号
         * @param {number} [perPage] 1ページあたりの要素数 (MAX100)
         * @param {string} [status] ステータス条件
         * @param {number} [categoryId] カテゴリーID
         * @param {string} [search] 検索キーワード
         * @param {string} [sort] ソート条件
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findExceededTasks: async (page?: number, perPage?: number, status?: string, categoryId?: number, search?: string, sort?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/tasks/exceeded/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (perPage !== undefined) {
                localVarQueryParameter['per_page'] = perPage;
            }

            if (status !== undefined) {
                localVarQueryParameter['status'] = status;
            }

            if (categoryId !== undefined) {
                localVarQueryParameter['category_id'] = categoryId;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 自分自身のタスクのリストを取得します。
         * @summary タスクリスト取得
         * @param {number} [page] ページ番号
         * @param {number} [perPage] 1ページあたりの要素数 (MAX100)
         * @param {string} [status] ステータス条件
         * @param {number} [categoryId] カテゴリーID
         * @param {string} [search] 検索キーワード
         * @param {string} [sort] ソート条件
         * @param {string} [duedate] 対象日付
         * @param {number} [goalId] ゴールID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findMyTasks: async (page?: number, perPage?: number, status?: string, categoryId?: number, search?: string, sort?: string, duedate?: string, goalId?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/tasks`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (perPage !== undefined) {
                localVarQueryParameter['per_page'] = perPage;
            }

            if (status !== undefined) {
                localVarQueryParameter['status'] = status;
            }

            if (categoryId !== undefined) {
                localVarQueryParameter['category_id'] = categoryId;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            if (duedate !== undefined) {
                localVarQueryParameter['duedate'] = duedate;
            }

            if (goalId !== undefined) {
                localVarQueryParameter['goal_id'] = goalId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * タスクのリストを取得します。
         * @summary タスクリスト取得
         * @param {string} teamname チーム名
         * @param {number} projectOrder プロジェクトオーダー
         * @param {number} [page] ページ番号
         * @param {number} [perPage] 1ページあたりの要素数 (MAX100)
         * @param {string} [status] ステータス条件
         * @param {number} [goalId] ゴールID
         * @param {string} [assignee] 担当者
         * @param {number} [categoryId] カテゴリーID
         * @param {string} [search] 検索キーワード
         * @param {string} [sort] ソート条件
         * @param {string} [metaOption] ソート条件
         * @param {string} [duedate] 対象日付
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findTeamTasks: async (teamname: string, projectOrder: number, page?: number, perPage?: number, status?: string, goalId?: number, assignee?: string, categoryId?: number, search?: string, sort?: string, metaOption?: string, duedate?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'teamname' is not null or undefined
            assertParamExists('findTeamTasks', 'teamname', teamname)
            // verify required parameter 'projectOrder' is not null or undefined
            assertParamExists('findTeamTasks', 'projectOrder', projectOrder)
            const localVarPath = `/teams/{teamname}/projs/{project_order}/tasks`
                .replace(`{${"teamname"}}`, encodeURIComponent(String(teamname)))
                .replace(`{${"project_order"}}`, encodeURIComponent(String(projectOrder)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (perPage !== undefined) {
                localVarQueryParameter['per_page'] = perPage;
            }

            if (status !== undefined) {
                localVarQueryParameter['status'] = status;
            }

            if (goalId !== undefined) {
                localVarQueryParameter['goal_id'] = goalId;
            }

            if (assignee !== undefined) {
                localVarQueryParameter['assignee'] = assignee;
            }

            if (categoryId !== undefined) {
                localVarQueryParameter['category_id'] = categoryId;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            if (metaOption !== undefined) {
                localVarQueryParameter['metaOption'] = metaOption;
            }

            if (duedate !== undefined) {
                localVarQueryParameter['duedate'] = duedate;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 自分自身のタスクを取得します。
         * @summary タスクの取得
         * @param {number} taskId タスクID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMyTask: async (taskId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'taskId' is not null or undefined
            assertParamExists('getMyTask', 'taskId', taskId)
            const localVarPath = `/tasks/{task_id}`
                .replace(`{${"task_id"}}`, encodeURIComponent(String(taskId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * コメントのリアクションを取得します。
         * @summary コメントのリアクションを取得
         * @param {number} taskId タスクID
         * @param {number} commentId コメントID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTaskCommentReaction: async (taskId: number, commentId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'taskId' is not null or undefined
            assertParamExists('getTaskCommentReaction', 'taskId', taskId)
            // verify required parameter 'commentId' is not null or undefined
            assertParamExists('getTaskCommentReaction', 'commentId', commentId)
            const localVarPath = `/tasks/{task_id}/comments/{comment_id}/reactions`
                .replace(`{${"task_id"}}`, encodeURIComponent(String(taskId)))
                .replace(`{${"comment_id"}}`, encodeURIComponent(String(commentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 自分自身のタスクを取得します。
         * @summary タスクの取得
         * @param {string} teamname チーム名
         * @param {number} projectOrder プロジェクトオーダー
         * @param {number} taskId タスクID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTeamTask: async (teamname: string, projectOrder: number, taskId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'teamname' is not null or undefined
            assertParamExists('getTeamTask', 'teamname', teamname)
            // verify required parameter 'projectOrder' is not null or undefined
            assertParamExists('getTeamTask', 'projectOrder', projectOrder)
            // verify required parameter 'taskId' is not null or undefined
            assertParamExists('getTeamTask', 'taskId', taskId)
            const localVarPath = `/teams/{teamname}/projs/{project_order}/tasks/{task_id}`
                .replace(`{${"teamname"}}`, encodeURIComponent(String(teamname)))
                .replace(`{${"project_order"}}`, encodeURIComponent(String(projectOrder)))
                .replace(`{${"task_id"}}`, encodeURIComponent(String(taskId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 自分自身のタスクを更新します。
         * @summary タスク更新
         * @param {number} taskId タスクID
         * @param {TaskRequest} taskRequest タスク情報
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setMyTask: async (taskId: number, taskRequest: TaskRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'taskId' is not null or undefined
            assertParamExists('setMyTask', 'taskId', taskId)
            // verify required parameter 'taskRequest' is not null or undefined
            assertParamExists('setMyTask', 'taskRequest', taskRequest)
            const localVarPath = `/tasks/{task_id}`
                .replace(`{${"task_id"}}`, encodeURIComponent(String(taskId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(taskRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * コメントを更新します。
         * @summary コメントを更新
         * @param {number} taskId タスクID
         * @param {number} commentId コメントID
         * @param {CommentRequest} commentRequest タスク情報
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setTaskComment: async (taskId: number, commentId: number, commentRequest: CommentRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'taskId' is not null or undefined
            assertParamExists('setTaskComment', 'taskId', taskId)
            // verify required parameter 'commentId' is not null or undefined
            assertParamExists('setTaskComment', 'commentId', commentId)
            // verify required parameter 'commentRequest' is not null or undefined
            assertParamExists('setTaskComment', 'commentRequest', commentRequest)
            const localVarPath = `/tasks/{task_id}/comments/{comment_id}`
                .replace(`{${"task_id"}}`, encodeURIComponent(String(taskId)))
                .replace(`{${"comment_id"}}`, encodeURIComponent(String(commentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(commentRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * コメントのリアクションを更新します。
         * @summary コメントのリアクションを更新
         * @param {number} taskId タスクID
         * @param {number} commentId コメントID
         * @param {CommentReactionRequest} commentReactionRequest タスク情報
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setTaskCommentReaction: async (taskId: number, commentId: number, commentReactionRequest: CommentReactionRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'taskId' is not null or undefined
            assertParamExists('setTaskCommentReaction', 'taskId', taskId)
            // verify required parameter 'commentId' is not null or undefined
            assertParamExists('setTaskCommentReaction', 'commentId', commentId)
            // verify required parameter 'commentReactionRequest' is not null or undefined
            assertParamExists('setTaskCommentReaction', 'commentReactionRequest', commentReactionRequest)
            const localVarPath = `/tasks/{task_id}/comments/{comment_id}/reactions`
                .replace(`{${"task_id"}}`, encodeURIComponent(String(taskId)))
                .replace(`{${"comment_id"}}`, encodeURIComponent(String(commentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(commentReactionRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 自分自身のタスクに作業時間を記録します。
         * @summary 作業時間記録
         * @param {string} targetDate 年月日
         * @param {TaskTimesRequest} taskTimesRequest 作業時間記録情報
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setTaskTimeEntries: async (targetDate: string, taskTimesRequest: TaskTimesRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'targetDate' is not null or undefined
            assertParamExists('setTaskTimeEntries', 'targetDate', targetDate)
            // verify required parameter 'taskTimesRequest' is not null or undefined
            assertParamExists('setTaskTimeEntries', 'taskTimesRequest', taskTimesRequest)
            const localVarPath = `/tasks/time/{target_date}`
                .replace(`{${"target_date"}}`, encodeURIComponent(String(targetDate)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(taskTimesRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 自分自身のタスクを更新します。
         * @summary タスク更新
         * @param {string} teamname チーム名
         * @param {number} projectOrder プロジェクトオーダー
         * @param {number} taskId タスクID
         * @param {TaskRequest} taskRequest タスク情報
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setTeamTask: async (teamname: string, projectOrder: number, taskId: number, taskRequest: TaskRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'teamname' is not null or undefined
            assertParamExists('setTeamTask', 'teamname', teamname)
            // verify required parameter 'projectOrder' is not null or undefined
            assertParamExists('setTeamTask', 'projectOrder', projectOrder)
            // verify required parameter 'taskId' is not null or undefined
            assertParamExists('setTeamTask', 'taskId', taskId)
            // verify required parameter 'taskRequest' is not null or undefined
            assertParamExists('setTeamTask', 'taskRequest', taskRequest)
            const localVarPath = `/teams/{teamname}/projs/{project_order}/tasks/{task_id}`
                .replace(`{${"teamname"}}`, encodeURIComponent(String(teamname)))
                .replace(`{${"project_order"}}`, encodeURIComponent(String(projectOrder)))
                .replace(`{${"task_id"}}`, encodeURIComponent(String(taskId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(taskRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * TaskApi - functional programming interface
 * @export
 */
export const TaskApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = TaskApiAxiosParamCreator(configuration)
    return {
        /**
         * 自分自身のタスクを追加します。
         * @summary タスク追加
         * @param {TaskRequest} taskRequest タスク情報
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addMyTask(taskRequest: TaskRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Task>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addMyTask(taskRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * コメントを追加します。
         * @summary コメントを追加
         * @param {number} taskId タスクID
         * @param {CommentRequest} commentRequest コメント情報
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addTaskComment(taskId: number, commentRequest: CommentRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Comment>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addTaskComment(taskId, commentRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 自分自身のタスクを追加します。
         * @summary タスク追加
         * @param {string} teamname チーム名
         * @param {number} projectOrder プロジェクトオーダー
         * @param {TaskRequest} taskRequest タスク情報
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addTeamTask(teamname: string, projectOrder: number, taskRequest: TaskRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Task>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addTeamTask(teamname, projectOrder, taskRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * マイタスクを削除します。
         * @summary マイタスク削除
         * @param {number} taskId タスクID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteMyTask(taskId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteMyTask(taskId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * コメントを削除します。
         * @summary コメントを削除
         * @param {number} taskId タスクID
         * @param {number} commentId コメントID
         * @param {CommentRequest} commentRequest タスク情報
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteTaskComment(taskId: number, commentId: number, commentRequest: CommentRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteTaskComment(taskId, commentId, commentRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 自分自身の期限日超過タスクのリストを取得します。
         * @summary 期限日超過タスクリスト取得
         * @param {number} [page] ページ番号
         * @param {number} [perPage] 1ページあたりの要素数 (MAX100)
         * @param {string} [status] ステータス条件
         * @param {number} [categoryId] カテゴリーID
         * @param {string} [search] 検索キーワード
         * @param {string} [sort] ソート条件
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findExceededTasks(page?: number, perPage?: number, status?: string, categoryId?: number, search?: string, sort?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ExceededTasks>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findExceededTasks(page, perPage, status, categoryId, search, sort, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 自分自身のタスクのリストを取得します。
         * @summary タスクリスト取得
         * @param {number} [page] ページ番号
         * @param {number} [perPage] 1ページあたりの要素数 (MAX100)
         * @param {string} [status] ステータス条件
         * @param {number} [categoryId] カテゴリーID
         * @param {string} [search] 検索キーワード
         * @param {string} [sort] ソート条件
         * @param {string} [duedate] 対象日付
         * @param {number} [goalId] ゴールID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findMyTasks(page?: number, perPage?: number, status?: string, categoryId?: number, search?: string, sort?: string, duedate?: string, goalId?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Tasks>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findMyTasks(page, perPage, status, categoryId, search, sort, duedate, goalId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * タスクのリストを取得します。
         * @summary タスクリスト取得
         * @param {string} teamname チーム名
         * @param {number} projectOrder プロジェクトオーダー
         * @param {number} [page] ページ番号
         * @param {number} [perPage] 1ページあたりの要素数 (MAX100)
         * @param {string} [status] ステータス条件
         * @param {number} [goalId] ゴールID
         * @param {string} [assignee] 担当者
         * @param {number} [categoryId] カテゴリーID
         * @param {string} [search] 検索キーワード
         * @param {string} [sort] ソート条件
         * @param {string} [metaOption] ソート条件
         * @param {string} [duedate] 対象日付
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findTeamTasks(teamname: string, projectOrder: number, page?: number, perPage?: number, status?: string, goalId?: number, assignee?: string, categoryId?: number, search?: string, sort?: string, metaOption?: string, duedate?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Tasks>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findTeamTasks(teamname, projectOrder, page, perPage, status, goalId, assignee, categoryId, search, sort, metaOption, duedate, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 自分自身のタスクを取得します。
         * @summary タスクの取得
         * @param {number} taskId タスクID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMyTask(taskId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Task>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getMyTask(taskId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * コメントのリアクションを取得します。
         * @summary コメントのリアクションを取得
         * @param {number} taskId タスクID
         * @param {number} commentId コメントID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTaskCommentReaction(taskId: number, commentId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CommentReactions>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTaskCommentReaction(taskId, commentId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 自分自身のタスクを取得します。
         * @summary タスクの取得
         * @param {string} teamname チーム名
         * @param {number} projectOrder プロジェクトオーダー
         * @param {number} taskId タスクID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTeamTask(teamname: string, projectOrder: number, taskId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Task>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTeamTask(teamname, projectOrder, taskId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 自分自身のタスクを更新します。
         * @summary タスク更新
         * @param {number} taskId タスクID
         * @param {TaskRequest} taskRequest タスク情報
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async setMyTask(taskId: number, taskRequest: TaskRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Task>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.setMyTask(taskId, taskRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * コメントを更新します。
         * @summary コメントを更新
         * @param {number} taskId タスクID
         * @param {number} commentId コメントID
         * @param {CommentRequest} commentRequest タスク情報
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async setTaskComment(taskId: number, commentId: number, commentRequest: CommentRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Comment>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.setTaskComment(taskId, commentId, commentRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * コメントのリアクションを更新します。
         * @summary コメントのリアクションを更新
         * @param {number} taskId タスクID
         * @param {number} commentId コメントID
         * @param {CommentReactionRequest} commentReactionRequest タスク情報
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async setTaskCommentReaction(taskId: number, commentId: number, commentReactionRequest: CommentReactionRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CommentReactions>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.setTaskCommentReaction(taskId, commentId, commentReactionRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 自分自身のタスクに作業時間を記録します。
         * @summary 作業時間記録
         * @param {string} targetDate 年月日
         * @param {TaskTimesRequest} taskTimesRequest 作業時間記録情報
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async setTaskTimeEntries(targetDate: string, taskTimesRequest: TaskTimesRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TaskTimes>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.setTaskTimeEntries(targetDate, taskTimesRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 自分自身のタスクを更新します。
         * @summary タスク更新
         * @param {string} teamname チーム名
         * @param {number} projectOrder プロジェクトオーダー
         * @param {number} taskId タスクID
         * @param {TaskRequest} taskRequest タスク情報
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async setTeamTask(teamname: string, projectOrder: number, taskId: number, taskRequest: TaskRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Task>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.setTeamTask(teamname, projectOrder, taskId, taskRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * TaskApi - factory interface
 * @export
 */
export const TaskApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = TaskApiFp(configuration)
    return {
        /**
         * 自分自身のタスクを追加します。
         * @summary タスク追加
         * @param {TaskRequest} taskRequest タスク情報
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addMyTask(taskRequest: TaskRequest, options?: any): AxiosPromise<Task> {
            return localVarFp.addMyTask(taskRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * コメントを追加します。
         * @summary コメントを追加
         * @param {number} taskId タスクID
         * @param {CommentRequest} commentRequest コメント情報
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addTaskComment(taskId: number, commentRequest: CommentRequest, options?: any): AxiosPromise<Comment> {
            return localVarFp.addTaskComment(taskId, commentRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 自分自身のタスクを追加します。
         * @summary タスク追加
         * @param {string} teamname チーム名
         * @param {number} projectOrder プロジェクトオーダー
         * @param {TaskRequest} taskRequest タスク情報
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addTeamTask(teamname: string, projectOrder: number, taskRequest: TaskRequest, options?: any): AxiosPromise<Task> {
            return localVarFp.addTeamTask(teamname, projectOrder, taskRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * マイタスクを削除します。
         * @summary マイタスク削除
         * @param {number} taskId タスクID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteMyTask(taskId: number, options?: any): AxiosPromise<void> {
            return localVarFp.deleteMyTask(taskId, options).then((request) => request(axios, basePath));
        },
        /**
         * コメントを削除します。
         * @summary コメントを削除
         * @param {number} taskId タスクID
         * @param {number} commentId コメントID
         * @param {CommentRequest} commentRequest タスク情報
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteTaskComment(taskId: number, commentId: number, commentRequest: CommentRequest, options?: any): AxiosPromise<void> {
            return localVarFp.deleteTaskComment(taskId, commentId, commentRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 自分自身の期限日超過タスクのリストを取得します。
         * @summary 期限日超過タスクリスト取得
         * @param {number} [page] ページ番号
         * @param {number} [perPage] 1ページあたりの要素数 (MAX100)
         * @param {string} [status] ステータス条件
         * @param {number} [categoryId] カテゴリーID
         * @param {string} [search] 検索キーワード
         * @param {string} [sort] ソート条件
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findExceededTasks(page?: number, perPage?: number, status?: string, categoryId?: number, search?: string, sort?: string, options?: any): AxiosPromise<ExceededTasks> {
            return localVarFp.findExceededTasks(page, perPage, status, categoryId, search, sort, options).then((request) => request(axios, basePath));
        },
        /**
         * 自分自身のタスクのリストを取得します。
         * @summary タスクリスト取得
         * @param {number} [page] ページ番号
         * @param {number} [perPage] 1ページあたりの要素数 (MAX100)
         * @param {string} [status] ステータス条件
         * @param {number} [categoryId] カテゴリーID
         * @param {string} [search] 検索キーワード
         * @param {string} [sort] ソート条件
         * @param {string} [duedate] 対象日付
         * @param {number} [goalId] ゴールID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findMyTasks(page?: number, perPage?: number, status?: string, categoryId?: number, search?: string, sort?: string, duedate?: string, goalId?: number, options?: any): AxiosPromise<Tasks> {
            return localVarFp.findMyTasks(page, perPage, status, categoryId, search, sort, duedate, goalId, options).then((request) => request(axios, basePath));
        },
        /**
         * タスクのリストを取得します。
         * @summary タスクリスト取得
         * @param {string} teamname チーム名
         * @param {number} projectOrder プロジェクトオーダー
         * @param {number} [page] ページ番号
         * @param {number} [perPage] 1ページあたりの要素数 (MAX100)
         * @param {string} [status] ステータス条件
         * @param {number} [goalId] ゴールID
         * @param {string} [assignee] 担当者
         * @param {number} [categoryId] カテゴリーID
         * @param {string} [search] 検索キーワード
         * @param {string} [sort] ソート条件
         * @param {string} [metaOption] ソート条件
         * @param {string} [duedate] 対象日付
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findTeamTasks(teamname: string, projectOrder: number, page?: number, perPage?: number, status?: string, goalId?: number, assignee?: string, categoryId?: number, search?: string, sort?: string, metaOption?: string, duedate?: string, options?: any): AxiosPromise<Tasks> {
            return localVarFp.findTeamTasks(teamname, projectOrder, page, perPage, status, goalId, assignee, categoryId, search, sort, metaOption, duedate, options).then((request) => request(axios, basePath));
        },
        /**
         * 自分自身のタスクを取得します。
         * @summary タスクの取得
         * @param {number} taskId タスクID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMyTask(taskId: number, options?: any): AxiosPromise<Task> {
            return localVarFp.getMyTask(taskId, options).then((request) => request(axios, basePath));
        },
        /**
         * コメントのリアクションを取得します。
         * @summary コメントのリアクションを取得
         * @param {number} taskId タスクID
         * @param {number} commentId コメントID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTaskCommentReaction(taskId: number, commentId: number, options?: any): AxiosPromise<CommentReactions> {
            return localVarFp.getTaskCommentReaction(taskId, commentId, options).then((request) => request(axios, basePath));
        },
        /**
         * 自分自身のタスクを取得します。
         * @summary タスクの取得
         * @param {string} teamname チーム名
         * @param {number} projectOrder プロジェクトオーダー
         * @param {number} taskId タスクID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTeamTask(teamname: string, projectOrder: number, taskId: number, options?: any): AxiosPromise<Task> {
            return localVarFp.getTeamTask(teamname, projectOrder, taskId, options).then((request) => request(axios, basePath));
        },
        /**
         * 自分自身のタスクを更新します。
         * @summary タスク更新
         * @param {number} taskId タスクID
         * @param {TaskRequest} taskRequest タスク情報
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setMyTask(taskId: number, taskRequest: TaskRequest, options?: any): AxiosPromise<Task> {
            return localVarFp.setMyTask(taskId, taskRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * コメントを更新します。
         * @summary コメントを更新
         * @param {number} taskId タスクID
         * @param {number} commentId コメントID
         * @param {CommentRequest} commentRequest タスク情報
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setTaskComment(taskId: number, commentId: number, commentRequest: CommentRequest, options?: any): AxiosPromise<Comment> {
            return localVarFp.setTaskComment(taskId, commentId, commentRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * コメントのリアクションを更新します。
         * @summary コメントのリアクションを更新
         * @param {number} taskId タスクID
         * @param {number} commentId コメントID
         * @param {CommentReactionRequest} commentReactionRequest タスク情報
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setTaskCommentReaction(taskId: number, commentId: number, commentReactionRequest: CommentReactionRequest, options?: any): AxiosPromise<CommentReactions> {
            return localVarFp.setTaskCommentReaction(taskId, commentId, commentReactionRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 自分自身のタスクに作業時間を記録します。
         * @summary 作業時間記録
         * @param {string} targetDate 年月日
         * @param {TaskTimesRequest} taskTimesRequest 作業時間記録情報
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setTaskTimeEntries(targetDate: string, taskTimesRequest: TaskTimesRequest, options?: any): AxiosPromise<TaskTimes> {
            return localVarFp.setTaskTimeEntries(targetDate, taskTimesRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 自分自身のタスクを更新します。
         * @summary タスク更新
         * @param {string} teamname チーム名
         * @param {number} projectOrder プロジェクトオーダー
         * @param {number} taskId タスクID
         * @param {TaskRequest} taskRequest タスク情報
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setTeamTask(teamname: string, projectOrder: number, taskId: number, taskRequest: TaskRequest, options?: any): AxiosPromise<Task> {
            return localVarFp.setTeamTask(teamname, projectOrder, taskId, taskRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * TaskApi - object-oriented interface
 * @export
 * @class TaskApi
 * @extends {BaseAPI}
 */
export class TaskApi extends BaseAPI {
    /**
     * 自分自身のタスクを追加します。
     * @summary タスク追加
     * @param {TaskRequest} taskRequest タスク情報
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TaskApi
     */
    public async addMyTask(taskRequest: TaskRequest, options?: AxiosRequestConfig) {
        const retry = 2;
        let resp;
        for (let i = 0; i < retry; i++) {
            resp = await TaskApiFp(this.configuration).addMyTask(taskRequest, options).then((request) => request(this.axios, this.basePath)).catch((error) => {
                if (error?.response) {
                    return error.response;
                } else {
                    return { status: 500 };
                }
            });
            if (resp?.status === 401) {
                await refreshIdToken();
                console.debug('Retry addMyTask ' + i + '/' + (retry - 1));
                continue;
            }
            break;
        }
        return resp;
    }

    /**
     * コメントを追加します。
     * @summary コメントを追加
     * @param {number} taskId タスクID
     * @param {CommentRequest} commentRequest コメント情報
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TaskApi
     */
    public async addTaskComment(taskId: number, commentRequest: CommentRequest, options?: AxiosRequestConfig) {
        const retry = 2;
        let resp;
        for (let i = 0; i < retry; i++) {
            resp = await TaskApiFp(this.configuration).addTaskComment(taskId, commentRequest, options).then((request) => request(this.axios, this.basePath)).catch((error) => {
                if (error?.response) {
                    return error.response;
                } else {
                    return { status: 500 };
                }
            });
            if (resp?.status === 401) {
                await refreshIdToken();
                console.debug('Retry addTaskComment ' + i + '/' + (retry - 1));
                continue;
            }
            break;
        }
        return resp;
    }

    /**
     * 自分自身のタスクを追加します。
     * @summary タスク追加
     * @param {string} teamname チーム名
     * @param {number} projectOrder プロジェクトオーダー
     * @param {TaskRequest} taskRequest タスク情報
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TaskApi
     */
    public async addTeamTask(teamname: string, projectOrder: number, taskRequest: TaskRequest, options?: AxiosRequestConfig) {
        const retry = 2;
        let resp;
        for (let i = 0; i < retry; i++) {
            resp = await TaskApiFp(this.configuration).addTeamTask(teamname, projectOrder, taskRequest, options).then((request) => request(this.axios, this.basePath)).catch((error) => {
                if (error?.response) {
                    return error.response;
                } else {
                    return { status: 500 };
                }
            });
            if (resp?.status === 401) {
                await refreshIdToken();
                console.debug('Retry addTeamTask ' + i + '/' + (retry - 1));
                continue;
            }
            break;
        }
        return resp;
    }

    /**
     * マイタスクを削除します。
     * @summary マイタスク削除
     * @param {number} taskId タスクID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TaskApi
     */
    public async deleteMyTask(taskId: number, options?: AxiosRequestConfig) {
        const retry = 2;
        let resp;
        for (let i = 0; i < retry; i++) {
            resp = await TaskApiFp(this.configuration).deleteMyTask(taskId, options).then((request) => request(this.axios, this.basePath)).catch((error) => {
                if (error?.response) {
                    return error.response;
                } else {
                    return { status: 500 };
                }
            });
            if (resp?.status === 401) {
                await refreshIdToken();
                console.debug('Retry deleteMyTask ' + i + '/' + (retry - 1));
                continue;
            }
            break;
        }
        return resp;
    }

    /**
     * コメントを削除します。
     * @summary コメントを削除
     * @param {number} taskId タスクID
     * @param {number} commentId コメントID
     * @param {CommentRequest} commentRequest タスク情報
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TaskApi
     */
    public async deleteTaskComment(taskId: number, commentId: number, commentRequest: CommentRequest, options?: AxiosRequestConfig) {
        const retry = 2;
        let resp;
        for (let i = 0; i < retry; i++) {
            resp = await TaskApiFp(this.configuration).deleteTaskComment(taskId, commentId, commentRequest, options).then((request) => request(this.axios, this.basePath)).catch((error) => {
                if (error?.response) {
                    return error.response;
                } else {
                    return { status: 500 };
                }
            });
            if (resp?.status === 401) {
                await refreshIdToken();
                console.debug('Retry deleteTaskComment ' + i + '/' + (retry - 1));
                continue;
            }
            break;
        }
        return resp;
    }

    /**
     * 自分自身の期限日超過タスクのリストを取得します。
     * @summary 期限日超過タスクリスト取得
     * @param {number} [page] ページ番号
     * @param {number} [perPage] 1ページあたりの要素数 (MAX100)
     * @param {string} [status] ステータス条件
     * @param {number} [categoryId] カテゴリーID
     * @param {string} [search] 検索キーワード
     * @param {string} [sort] ソート条件
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TaskApi
     */
    public async findExceededTasks(page?: number, perPage?: number, status?: string, categoryId?: number, search?: string, sort?: string, options?: AxiosRequestConfig) {
        const retry = 2;
        let resp;
        for (let i = 0; i < retry; i++) {
            resp = await TaskApiFp(this.configuration).findExceededTasks(page, perPage, status, categoryId, search, sort, options).then((request) => request(this.axios, this.basePath)).catch((error) => {
                if (error?.response) {
                    return error.response;
                } else {
                    return { status: 500 };
                }
            });
            if (resp?.status === 401) {
                await refreshIdToken();
                console.debug('Retry findExceededTasks ' + i + '/' + (retry - 1));
                continue;
            }
            break;
        }
        return resp;
    }

    /**
     * 自分自身のタスクのリストを取得します。
     * @summary タスクリスト取得
     * @param {number} [page] ページ番号
     * @param {number} [perPage] 1ページあたりの要素数 (MAX100)
     * @param {string} [status] ステータス条件
     * @param {number} [categoryId] カテゴリーID
     * @param {string} [search] 検索キーワード
     * @param {string} [sort] ソート条件
     * @param {string} [duedate] 対象日付
     * @param {number} [goalId] ゴールID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TaskApi
     */
    public async findMyTasks(page?: number, perPage?: number, status?: string, categoryId?: number, search?: string, sort?: string, duedate?: string, goalId?: number, options?: AxiosRequestConfig) {
        const retry = 2;
        let resp;
        for (let i = 0; i < retry; i++) {
            resp = await TaskApiFp(this.configuration).findMyTasks(page, perPage, status, categoryId, search, sort, duedate, goalId, options).then((request) => request(this.axios, this.basePath)).catch((error) => {
                if (error?.response) {
                    return error.response;
                } else {
                    return { status: 500 };
                }
            });
            if (resp?.status === 401) {
                await refreshIdToken();
                console.debug('Retry findMyTasks ' + i + '/' + (retry - 1));
                continue;
            }
            break;
        }
        return resp;
    }

    /**
     * タスクのリストを取得します。
     * @summary タスクリスト取得
     * @param {string} teamname チーム名
     * @param {number} projectOrder プロジェクトオーダー
     * @param {number} [page] ページ番号
     * @param {number} [perPage] 1ページあたりの要素数 (MAX100)
     * @param {string} [status] ステータス条件
     * @param {number} [goalId] ゴールID
     * @param {string} [assignee] 担当者
     * @param {number} [categoryId] カテゴリーID
     * @param {string} [search] 検索キーワード
     * @param {string} [sort] ソート条件
     * @param {string} [metaOption] ソート条件
     * @param {string} [duedate] 対象日付
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TaskApi
     */
    public async findTeamTasks(teamname: string, projectOrder: number, page?: number, perPage?: number, status?: string, goalId?: number, assignee?: string, categoryId?: number, search?: string, sort?: string, metaOption?: string, duedate?: string, options?: AxiosRequestConfig) {
        const retry = 2;
        let resp;
        for (let i = 0; i < retry; i++) {
            resp = await TaskApiFp(this.configuration).findTeamTasks(teamname, projectOrder, page, perPage, status, goalId, assignee, categoryId, search, sort, metaOption, duedate, options).then((request) => request(this.axios, this.basePath)).catch((error) => {
                if (error?.response) {
                    return error.response;
                } else {
                    return { status: 500 };
                }
            });
            if (resp?.status === 401) {
                await refreshIdToken();
                console.debug('Retry findTeamTasks ' + i + '/' + (retry - 1));
                continue;
            }
            break;
        }
        return resp;
    }

    /**
     * 自分自身のタスクを取得します。
     * @summary タスクの取得
     * @param {number} taskId タスクID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TaskApi
     */
    public async getMyTask(taskId: number, options?: AxiosRequestConfig) {
        const retry = 2;
        let resp;
        for (let i = 0; i < retry; i++) {
            resp = await TaskApiFp(this.configuration).getMyTask(taskId, options).then((request) => request(this.axios, this.basePath)).catch((error) => {
                if (error?.response) {
                    return error.response;
                } else {
                    return { status: 500 };
                }
            });
            if (resp?.status === 401) {
                await refreshIdToken();
                console.debug('Retry getMyTask ' + i + '/' + (retry - 1));
                continue;
            }
            break;
        }
        return resp;
    }

    /**
     * コメントのリアクションを取得します。
     * @summary コメントのリアクションを取得
     * @param {number} taskId タスクID
     * @param {number} commentId コメントID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TaskApi
     */
    public async getTaskCommentReaction(taskId: number, commentId: number, options?: AxiosRequestConfig) {
        const retry = 2;
        let resp;
        for (let i = 0; i < retry; i++) {
            resp = await TaskApiFp(this.configuration).getTaskCommentReaction(taskId, commentId, options).then((request) => request(this.axios, this.basePath)).catch((error) => {
                if (error?.response) {
                    return error.response;
                } else {
                    return { status: 500 };
                }
            });
            if (resp?.status === 401) {
                await refreshIdToken();
                console.debug('Retry getTaskCommentReaction ' + i + '/' + (retry - 1));
                continue;
            }
            break;
        }
        return resp;
    }

    /**
     * 自分自身のタスクを取得します。
     * @summary タスクの取得
     * @param {string} teamname チーム名
     * @param {number} projectOrder プロジェクトオーダー
     * @param {number} taskId タスクID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TaskApi
     */
    public async getTeamTask(teamname: string, projectOrder: number, taskId: number, options?: AxiosRequestConfig) {
        const retry = 2;
        let resp;
        for (let i = 0; i < retry; i++) {
            resp = await TaskApiFp(this.configuration).getTeamTask(teamname, projectOrder, taskId, options).then((request) => request(this.axios, this.basePath)).catch((error) => {
                if (error?.response) {
                    return error.response;
                } else {
                    return { status: 500 };
                }
            });
            if (resp?.status === 401) {
                await refreshIdToken();
                console.debug('Retry getTeamTask ' + i + '/' + (retry - 1));
                continue;
            }
            break;
        }
        return resp;
    }

    /**
     * 自分自身のタスクを更新します。
     * @summary タスク更新
     * @param {number} taskId タスクID
     * @param {TaskRequest} taskRequest タスク情報
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TaskApi
     */
    public async setMyTask(taskId: number, taskRequest: TaskRequest, options?: AxiosRequestConfig) {
        const retry = 2;
        let resp;
        for (let i = 0; i < retry; i++) {
            resp = await TaskApiFp(this.configuration).setMyTask(taskId, taskRequest, options).then((request) => request(this.axios, this.basePath)).catch((error) => {
                if (error?.response) {
                    return error.response;
                } else {
                    return { status: 500 };
                }
            });
            if (resp?.status === 401) {
                await refreshIdToken();
                console.debug('Retry setMyTask ' + i + '/' + (retry - 1));
                continue;
            }
            break;
        }
        return resp;
    }

    /**
     * コメントを更新します。
     * @summary コメントを更新
     * @param {number} taskId タスクID
     * @param {number} commentId コメントID
     * @param {CommentRequest} commentRequest タスク情報
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TaskApi
     */
    public async setTaskComment(taskId: number, commentId: number, commentRequest: CommentRequest, options?: AxiosRequestConfig) {
        const retry = 2;
        let resp;
        for (let i = 0; i < retry; i++) {
            resp = await TaskApiFp(this.configuration).setTaskComment(taskId, commentId, commentRequest, options).then((request) => request(this.axios, this.basePath)).catch((error) => {
                if (error?.response) {
                    return error.response;
                } else {
                    return { status: 500 };
                }
            });
            if (resp?.status === 401) {
                await refreshIdToken();
                console.debug('Retry setTaskComment ' + i + '/' + (retry - 1));
                continue;
            }
            break;
        }
        return resp;
    }

    /**
     * コメントのリアクションを更新します。
     * @summary コメントのリアクションを更新
     * @param {number} taskId タスクID
     * @param {number} commentId コメントID
     * @param {CommentReactionRequest} commentReactionRequest タスク情報
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TaskApi
     */
    public async setTaskCommentReaction(taskId: number, commentId: number, commentReactionRequest: CommentReactionRequest, options?: AxiosRequestConfig) {
        const retry = 2;
        let resp;
        for (let i = 0; i < retry; i++) {
            resp = await TaskApiFp(this.configuration).setTaskCommentReaction(taskId, commentId, commentReactionRequest, options).then((request) => request(this.axios, this.basePath)).catch((error) => {
                if (error?.response) {
                    return error.response;
                } else {
                    return { status: 500 };
                }
            });
            if (resp?.status === 401) {
                await refreshIdToken();
                console.debug('Retry setTaskCommentReaction ' + i + '/' + (retry - 1));
                continue;
            }
            break;
        }
        return resp;
    }

    /**
     * 自分自身のタスクに作業時間を記録します。
     * @summary 作業時間記録
     * @param {string} targetDate 年月日
     * @param {TaskTimesRequest} taskTimesRequest 作業時間記録情報
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TaskApi
     */
    public async setTaskTimeEntries(targetDate: string, taskTimesRequest: TaskTimesRequest, options?: AxiosRequestConfig) {
        const retry = 2;
        let resp;
        for (let i = 0; i < retry; i++) {
            resp = await TaskApiFp(this.configuration).setTaskTimeEntries(targetDate, taskTimesRequest, options).then((request) => request(this.axios, this.basePath)).catch((error) => {
                if (error?.response) {
                    return error.response;
                } else {
                    return { status: 500 };
                }
            });
            if (resp?.status === 401) {
                await refreshIdToken();
                console.debug('Retry setTaskTimeEntries ' + i + '/' + (retry - 1));
                continue;
            }
            break;
        }
        return resp;
    }

    /**
     * 自分自身のタスクを更新します。
     * @summary タスク更新
     * @param {string} teamname チーム名
     * @param {number} projectOrder プロジェクトオーダー
     * @param {number} taskId タスクID
     * @param {TaskRequest} taskRequest タスク情報
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TaskApi
     */
    public async setTeamTask(teamname: string, projectOrder: number, taskId: number, taskRequest: TaskRequest, options?: AxiosRequestConfig) {
        const retry = 2;
        let resp;
        for (let i = 0; i < retry; i++) {
            resp = await TaskApiFp(this.configuration).setTeamTask(teamname, projectOrder, taskId, taskRequest, options).then((request) => request(this.axios, this.basePath)).catch((error) => {
                if (error?.response) {
                    return error.response;
                } else {
                    return { status: 500 };
                }
            });
            if (resp?.status === 401) {
                await refreshIdToken();
                console.debug('Retry setTeamTask ' + i + '/' + (retry - 1));
                continue;
            }
            break;
        }
        return resp;
    }
}


/**
 * TeamApi - axios parameter creator
 * @export
 */
export const TeamApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * チームメンバーを削除します。
         * @summary チームメンバーの削除
         * @param {string} teamname チーム名
         * @param {string} username ユーザ名
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteTeamMembers: async (teamname: string, username: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'teamname' is not null or undefined
            assertParamExists('deleteTeamMembers', 'teamname', teamname)
            // verify required parameter 'username' is not null or undefined
            assertParamExists('deleteTeamMembers', 'username', username)
            const localVarPath = `/teams/{teamname}/members/{username}`
                .replace(`{${"teamname"}}`, encodeURIComponent(String(teamname)))
                .replace(`{${"username"}}`, encodeURIComponent(String(username)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * チームタスクを削除します。
         * @summary チームタスク削除
         * @param {string} teamname チーム名
         * @param {number} taskId タスクID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteTeamTask: async (teamname: string, taskId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'teamname' is not null or undefined
            assertParamExists('deleteTeamTask', 'teamname', teamname)
            // verify required parameter 'taskId' is not null or undefined
            assertParamExists('deleteTeamTask', 'taskId', taskId)
            const localVarPath = `/teams/{teamname}/delete/task/{task_id}`
                .replace(`{${"teamname"}}`, encodeURIComponent(String(teamname)))
                .replace(`{${"task_id"}}`, encodeURIComponent(String(taskId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * チームナレッジを削除します。
         * @summary チームナレッジ削除
         * @param {string} teamname チーム名
         * @param {number} wikiId wikiID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteTeamWiki: async (teamname: string, wikiId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'teamname' is not null or undefined
            assertParamExists('deleteTeamWiki', 'teamname', teamname)
            // verify required parameter 'wikiId' is not null or undefined
            assertParamExists('deleteTeamWiki', 'wikiId', wikiId)
            const localVarPath = `/teams/{teamname}/delete/wiki/{wiki_id}`
                .replace(`{${"teamname"}}`, encodeURIComponent(String(teamname)))
                .replace(`{${"wiki_id"}}`, encodeURIComponent(String(wikiId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * チームのインテグレーション設定情報リストを取得します。
         * @summary チームのインテグレーション設定リストを取得
         * @param {string} teamname チーム名
         * @param {number} [projectOrder] プロジェクトオーダー
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findTeamIntegSettings: async (teamname: string, projectOrder?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'teamname' is not null or undefined
            assertParamExists('findTeamIntegSettings', 'teamname', teamname)
            const localVarPath = `/teams/{teamname}/integrationsettings`
                .replace(`{${"teamname"}}`, encodeURIComponent(String(teamname)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (projectOrder !== undefined) {
                localVarQueryParameter['project_order'] = projectOrder;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * チームメンバーの出席状況のインテグレーション情報を取得します。
         * @summary チームメンバーの出席状況のインテグレーション情報リストを取得
         * @param {string} teamname チーム名
         * @param {string} username ユーザ名
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findTeamMemberAttendanceIntegs: async (teamname: string, username: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'teamname' is not null or undefined
            assertParamExists('findTeamMemberAttendanceIntegs', 'teamname', teamname)
            // verify required parameter 'username' is not null or undefined
            assertParamExists('findTeamMemberAttendanceIntegs', 'username', username)
            const localVarPath = `/teams/{teamname}/members/{username}/attendanceinteg`
                .replace(`{${"teamname"}}`, encodeURIComponent(String(teamname)))
                .replace(`{${"username"}}`, encodeURIComponent(String(username)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * メンバーのスキル詳細のリストを取得します。
         * @summary メンバースキルリスト取得
         * @param {string} teamname チーム名
         * @param {string} username ユーザ名
         * @param {string} skillType スキル種別
         * @param {number} skillOrder スキルオーダー
         * @param {number} [page] ページ番号
         * @param {number} [perPage] 1ページあたりの要素数 (MAX100)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findTeamMemberSkillAnalytics: async (teamname: string, username: string, skillType: string, skillOrder: number, page?: number, perPage?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'teamname' is not null or undefined
            assertParamExists('findTeamMemberSkillAnalytics', 'teamname', teamname)
            // verify required parameter 'username' is not null or undefined
            assertParamExists('findTeamMemberSkillAnalytics', 'username', username)
            // verify required parameter 'skillType' is not null or undefined
            assertParamExists('findTeamMemberSkillAnalytics', 'skillType', skillType)
            // verify required parameter 'skillOrder' is not null or undefined
            assertParamExists('findTeamMemberSkillAnalytics', 'skillOrder', skillOrder)
            const localVarPath = `/teams/{teamname}/members/{username}/skills/{skill_type}/{skill_order}/analytics`
                .replace(`{${"teamname"}}`, encodeURIComponent(String(teamname)))
                .replace(`{${"username"}}`, encodeURIComponent(String(username)))
                .replace(`{${"skill_type"}}`, encodeURIComponent(String(skillType)))
                .replace(`{${"skill_order"}}`, encodeURIComponent(String(skillOrder)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (perPage !== undefined) {
                localVarQueryParameter['per_page'] = perPage;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * チームメンバーのスキル詳細のリストを取得します。
         * @summary チームメンバースキル詳細リストの取得
         * @param {string} teamname チーム名
         * @param {string} username ユーザ名
         * @param {string} skillType スキル種別
         * @param {number} skillOrder スキルオーダー
         * @param {number} [page] ページ番号
         * @param {number} [perPage] 1ページあたりの要素数 (MAX100)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findTeamMemberSkillDetails: async (teamname: string, username: string, skillType: string, skillOrder: number, page?: number, perPage?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'teamname' is not null or undefined
            assertParamExists('findTeamMemberSkillDetails', 'teamname', teamname)
            // verify required parameter 'username' is not null or undefined
            assertParamExists('findTeamMemberSkillDetails', 'username', username)
            // verify required parameter 'skillType' is not null or undefined
            assertParamExists('findTeamMemberSkillDetails', 'skillType', skillType)
            // verify required parameter 'skillOrder' is not null or undefined
            assertParamExists('findTeamMemberSkillDetails', 'skillOrder', skillOrder)
            const localVarPath = `/teams/{teamname}/members/{username}/skills/{skill_type}/{skill_order}/details`
                .replace(`{${"teamname"}}`, encodeURIComponent(String(teamname)))
                .replace(`{${"username"}}`, encodeURIComponent(String(username)))
                .replace(`{${"skill_type"}}`, encodeURIComponent(String(skillType)))
                .replace(`{${"skill_order"}}`, encodeURIComponent(String(skillOrder)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (perPage !== undefined) {
                localVarQueryParameter['per_page'] = perPage;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * スキルのリストを取得します。
         * @summary メンバのスキルリスト取得(未)
         * @param {string} teamname チーム名
         * @param {string} username ユーザ名
         * @param {number} [page] ページ番号
         * @param {number} [perPage] 1ページあたりの要素数 (MAX100)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findTeamMemberSkills: async (teamname: string, username: string, page?: number, perPage?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'teamname' is not null or undefined
            assertParamExists('findTeamMemberSkills', 'teamname', teamname)
            // verify required parameter 'username' is not null or undefined
            assertParamExists('findTeamMemberSkills', 'username', username)
            const localVarPath = `/teams/{teamname}/members/{username}/skills`
                .replace(`{${"teamname"}}`, encodeURIComponent(String(teamname)))
                .replace(`{${"username"}}`, encodeURIComponent(String(username)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (perPage !== undefined) {
                localVarQueryParameter['per_page'] = perPage;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * チームメンバーの作業時間を取得します。
         * @summary チームメンバーの作業時間取得
         * @param {string} teamname チーム名
         * @param {string} username ユーザ名
         * @param {number} [projectOrder] プロジェクトオーダー
         * @param {number} [year] 年
         * @param {number} [month] 月
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findTeamMemberTimeEntries: async (teamname: string, username: string, projectOrder?: number, year?: number, month?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'teamname' is not null or undefined
            assertParamExists('findTeamMemberTimeEntries', 'teamname', teamname)
            // verify required parameter 'username' is not null or undefined
            assertParamExists('findTeamMemberTimeEntries', 'username', username)
            const localVarPath = `/teams/{teamname}/members/{username}/timeentries`
                .replace(`{${"teamname"}}`, encodeURIComponent(String(teamname)))
                .replace(`{${"username"}}`, encodeURIComponent(String(username)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (projectOrder !== undefined) {
                localVarQueryParameter['project_order'] = projectOrder;
            }

            if (year !== undefined) {
                localVarQueryParameter['year'] = year;
            }

            if (month !== undefined) {
                localVarQueryParameter['month'] = month;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * チームメンバーのリストを取得します。
         * @summary チームメンバーリストの取得(未)
         * @param {string} teamname チーム名
         * @param {number} projectOrder プロジェクトオーダー
         * @param {number} [page] ページ番号
         * @param {number} [perPage] 1ページあたりの要素数 (MAX100)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findTeamMembers: async (teamname: string, projectOrder: number, page?: number, perPage?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'teamname' is not null or undefined
            assertParamExists('findTeamMembers', 'teamname', teamname)
            // verify required parameter 'projectOrder' is not null or undefined
            assertParamExists('findTeamMembers', 'projectOrder', projectOrder)
            const localVarPath = `/teams/{teamname}/projects/{project_order}/members`
                .replace(`{${"teamname"}}`, encodeURIComponent(String(teamname)))
                .replace(`{${"project_order"}}`, encodeURIComponent(String(projectOrder)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (perPage !== undefined) {
                localVarQueryParameter['per_page'] = perPage;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * メンションリストを取得します。
         * @summary メンションリスト取得
         * @param {string} teamname チーム名
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findTeamMentions: async (teamname: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'teamname' is not null or undefined
            assertParamExists('findTeamMentions', 'teamname', teamname)
            const localVarPath = `/teams/{teamname}/mentions`
                .replace(`{${"teamname"}}`, encodeURIComponent(String(teamname)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 所属しているチームのリストを取得します。
         * @summary 所属チームリストの取得
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findTeams: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/teams`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * チームの情報を取得します。
         * @summary チーム情報の取得
         * @param {string} teamname チーム名
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTeam: async (teamname: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'teamname' is not null or undefined
            assertParamExists('getTeam', 'teamname', teamname)
            const localVarPath = `/teams/{teamname}`
                .replace(`{${"teamname"}}`, encodeURIComponent(String(teamname)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * チームの紹介ページを取得します。
         * @summary チーム紹介ページの取得
         * @param {string} teamname チーム名
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTeamDescription: async (teamname: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'teamname' is not null or undefined
            assertParamExists('getTeamDescription', 'teamname', teamname)
            const localVarPath = `/teams/{teamname}/description`
                .replace(`{${"teamname"}}`, encodeURIComponent(String(teamname)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 特定のチームメンバー情報を取得します。
         * @summary 特定のチームメンバー情報の取得(未)
         * @param {string} teamname チーム名
         * @param {number} projectOrder プロジェクトオーダー
         * @param {string} username ユーザ名
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTeamMember: async (teamname: string, projectOrder: number, username: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'teamname' is not null or undefined
            assertParamExists('getTeamMember', 'teamname', teamname)
            // verify required parameter 'projectOrder' is not null or undefined
            assertParamExists('getTeamMember', 'projectOrder', projectOrder)
            // verify required parameter 'username' is not null or undefined
            assertParamExists('getTeamMember', 'username', username)
            const localVarPath = `/teams/{teamname}/members/{username}`
                .replace(`{${"teamname"}}`, encodeURIComponent(String(teamname)))
                .replace(`{${"project_order"}}`, encodeURIComponent(String(projectOrder)))
                .replace(`{${"username"}}`, encodeURIComponent(String(username)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 自分自身の出席状況を取得します。
         * @summary チームメンバーの出席状況の取得
         * @param {string} teamname チーム名
         * @param {string} username ユーザ名
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTeamMemberAttendance: async (teamname: string, username: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'teamname' is not null or undefined
            assertParamExists('getTeamMemberAttendance', 'teamname', teamname)
            // verify required parameter 'username' is not null or undefined
            assertParamExists('getTeamMemberAttendance', 'username', username)
            const localVarPath = `/teams/{teamname}/members/{username}/attendance`
                .replace(`{${"teamname"}}`, encodeURIComponent(String(teamname)))
                .replace(`{${"username"}}`, encodeURIComponent(String(username)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * メンバーの特定のスキル情報を取得します。
         * @summary メンバーの特定のスキル情報取得(未)
         * @param {string} teamname チーム名
         * @param {string} username ユーザ名
         * @param {string} skillType スキル種別
         * @param {number} skillOrder スキルオーダー
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTeamMemberSkill: async (teamname: string, username: string, skillType: string, skillOrder: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'teamname' is not null or undefined
            assertParamExists('getTeamMemberSkill', 'teamname', teamname)
            // verify required parameter 'username' is not null or undefined
            assertParamExists('getTeamMemberSkill', 'username', username)
            // verify required parameter 'skillType' is not null or undefined
            assertParamExists('getTeamMemberSkill', 'skillType', skillType)
            // verify required parameter 'skillOrder' is not null or undefined
            assertParamExists('getTeamMemberSkill', 'skillOrder', skillOrder)
            const localVarPath = `/teams/{teamname}/members/{username}/skills/{skill_type}/{skill_order}`
                .replace(`{${"teamname"}}`, encodeURIComponent(String(teamname)))
                .replace(`{${"username"}}`, encodeURIComponent(String(username)))
                .replace(`{${"skill_type"}}`, encodeURIComponent(String(skillType)))
                .replace(`{${"skill_order"}}`, encodeURIComponent(String(skillOrder)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * チームの公開ポートフォリオリストを取得します。
         * @summary チームの公開ポートフォリオリストを取得
         * @param {string} teamname チーム名
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTeamPublicPortfolios: async (teamname: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'teamname' is not null or undefined
            assertParamExists('getTeamPublicPortfolios', 'teamname', teamname)
            const localVarPath = `/teams/{teamname}/portfolios/public`
                .replace(`{${"teamname"}}`, encodeURIComponent(String(teamname)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * チームの情報を取得します。
         * @summary チーム情報の取得(未)
         * @param {string} teamname チーム名
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTeamSkills: async (teamname: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'teamname' is not null or undefined
            assertParamExists('getTeamSkills', 'teamname', teamname)
            const localVarPath = `/teams/{teamname}/skills`
                .replace(`{${"teamname"}}`, encodeURIComponent(String(teamname)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * チームの最新スキル情報を取得します。
         * @summary チームの最新スキル情報
         * @param {string} teamname チーム名
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTeamSkillsReload: async (teamname: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'teamname' is not null or undefined
            assertParamExists('getTeamSkillsReload', 'teamname', teamname)
            const localVarPath = `/teams/{teamname}/skills/reload`
                .replace(`{${"teamname"}}`, encodeURIComponent(String(teamname)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * チームの紹介ページを追加します。
         * @summary チーム紹介ページ追加
         * @param {string} teamname チーム名
         * @param {TeamDescriptionRequest} teamDescriptionRequest チーム紹介情報
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setTeamDescription: async (teamname: string, teamDescriptionRequest: TeamDescriptionRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'teamname' is not null or undefined
            assertParamExists('setTeamDescription', 'teamname', teamname)
            // verify required parameter 'teamDescriptionRequest' is not null or undefined
            assertParamExists('setTeamDescription', 'teamDescriptionRequest', teamDescriptionRequest)
            const localVarPath = `/teams/{teamname}/description`
                .replace(`{${"teamname"}}`, encodeURIComponent(String(teamname)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(teamDescriptionRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * チームのインテグレーション設定情報を更新します。
         * @summary チームのインテグレーション設定の更新
         * @param {string} teamname チーム名
         * @param {TeamIntegSettingReq} teamIntegSettingReq 更新するインテグレーション設定情報
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setTeamIntegSetting: async (teamname: string, teamIntegSettingReq: TeamIntegSettingReq, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'teamname' is not null or undefined
            assertParamExists('setTeamIntegSetting', 'teamname', teamname)
            // verify required parameter 'teamIntegSettingReq' is not null or undefined
            assertParamExists('setTeamIntegSetting', 'teamIntegSettingReq', teamIntegSettingReq)
            const localVarPath = `/teams/{teamname}/integrationsettings`
                .replace(`{${"teamname"}}`, encodeURIComponent(String(teamname)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(teamIntegSettingReq, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 自分自身の出席状況を更新します。
         * @summary チームメンバーの出席状況の更新
         * @param {string} teamname チーム名
         * @param {string} username ユーザ名
         * @param {MemberAttendanceReq} memberAttendanceReq 更新後の出席状況
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setTeamMemberAttendance: async (teamname: string, username: string, memberAttendanceReq: MemberAttendanceReq, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'teamname' is not null or undefined
            assertParamExists('setTeamMemberAttendance', 'teamname', teamname)
            // verify required parameter 'username' is not null or undefined
            assertParamExists('setTeamMemberAttendance', 'username', username)
            // verify required parameter 'memberAttendanceReq' is not null or undefined
            assertParamExists('setTeamMemberAttendance', 'memberAttendanceReq', memberAttendanceReq)
            const localVarPath = `/teams/{teamname}/members/{username}/attendance`
                .replace(`{${"teamname"}}`, encodeURIComponent(String(teamname)))
                .replace(`{${"username"}}`, encodeURIComponent(String(username)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(memberAttendanceReq, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 出席状況へのインテグレーション情報を更新します。
         * @summary チームメンバーの出席状況へのインテグレーション情報の更新
         * @param {string} teamname チーム名
         * @param {string} username ユーザ名
         * @param {MemberAttendanceIntegReq} memberAttendanceIntegReq 更新する出席状況へのインテグレーション情報
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setTeamMemberAttendanceInteg: async (teamname: string, username: string, memberAttendanceIntegReq: MemberAttendanceIntegReq, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'teamname' is not null or undefined
            assertParamExists('setTeamMemberAttendanceInteg', 'teamname', teamname)
            // verify required parameter 'username' is not null or undefined
            assertParamExists('setTeamMemberAttendanceInteg', 'username', username)
            // verify required parameter 'memberAttendanceIntegReq' is not null or undefined
            assertParamExists('setTeamMemberAttendanceInteg', 'memberAttendanceIntegReq', memberAttendanceIntegReq)
            const localVarPath = `/teams/{teamname}/members/{username}/attendanceinteg`
                .replace(`{${"teamname"}}`, encodeURIComponent(String(teamname)))
                .replace(`{${"username"}}`, encodeURIComponent(String(username)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(memberAttendanceIntegReq, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * チーム内表示名を更新します。
         * @summary チーム内表示名の更新(未)
         * @param {string} teamname チーム名
         * @param {string} username ユーザ名
         * @param {IdValue} idValue 更新後の表示名
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setTeamMemberDisplayname: async (teamname: string, username: string, idValue: IdValue, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'teamname' is not null or undefined
            assertParamExists('setTeamMemberDisplayname', 'teamname', teamname)
            // verify required parameter 'username' is not null or undefined
            assertParamExists('setTeamMemberDisplayname', 'username', username)
            // verify required parameter 'idValue' is not null or undefined
            assertParamExists('setTeamMemberDisplayname', 'idValue', idValue)
            const localVarPath = `/teams/{teamname}/members/{username}/displayname`
                .replace(`{${"teamname"}}`, encodeURIComponent(String(teamname)))
                .replace(`{${"username"}}`, encodeURIComponent(String(username)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(idValue, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * チーム情報を更新します。
         * @summary チーム情報の更新(未)
         * @param {string} teamname チーム名
         * @param {UserRequest} userRequest ユーザー情報
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateTeam: async (teamname: string, userRequest: UserRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'teamname' is not null or undefined
            assertParamExists('updateTeam', 'teamname', teamname)
            // verify required parameter 'userRequest' is not null or undefined
            assertParamExists('updateTeam', 'userRequest', userRequest)
            const localVarPath = `/teams/{teamname}`
                .replace(`{${"teamname"}}`, encodeURIComponent(String(teamname)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(userRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * チームメンバーを更新します。
         * @summary チームメンバーの更新(未)
         * @param {string} teamname チーム名
         * @param {string} username ユーザ名
         * @param {UserRequest} userRequest ユーザー情報
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateTeamMember: async (teamname: string, username: string, userRequest: UserRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'teamname' is not null or undefined
            assertParamExists('updateTeamMember', 'teamname', teamname)
            // verify required parameter 'username' is not null or undefined
            assertParamExists('updateTeamMember', 'username', username)
            // verify required parameter 'userRequest' is not null or undefined
            assertParamExists('updateTeamMember', 'userRequest', userRequest)
            const localVarPath = `/teams/{teamname}/members/{username}`
                .replace(`{${"teamname"}}`, encodeURIComponent(String(teamname)))
                .replace(`{${"username"}}`, encodeURIComponent(String(username)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(userRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * チーム情報を更新します。
         * @summary チーム情報の更新(未)
         * @param {string} teamname チーム名
         * @param {UserRequest} userRequest ユーザー情報
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateTeamSkills: async (teamname: string, userRequest: UserRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'teamname' is not null or undefined
            assertParamExists('updateTeamSkills', 'teamname', teamname)
            // verify required parameter 'userRequest' is not null or undefined
            assertParamExists('updateTeamSkills', 'userRequest', userRequest)
            const localVarPath = `/teams/{teamname}/skills`
                .replace(`{${"teamname"}}`, encodeURIComponent(String(teamname)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(userRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * チーム紹介の画像ファイルをアップロードします。
         * @summary チーム紹介の画像ファイルをアップロード
         * @param {string} teamname チーム名
         * @param {File} [image] チーム紹介用にアップロードした画像
         * @param {string} [fileName] ファイル名
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadTeamDescriptionImg: async (teamname: string, image?: File, fileName?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'teamname' is not null or undefined
            assertParamExists('uploadTeamDescriptionImg', 'teamname', teamname)
            const localVarPath = `/uploads/teams/description/{teamname}`
                .replace(`{${"teamname"}}`, encodeURIComponent(String(teamname)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


            if (image !== undefined) { 
                localVarFormParams.append('image', image as any);
            }
    
            if (fileName !== undefined) { 
                localVarFormParams.append('fileName', fileName as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * TeamApi - functional programming interface
 * @export
 */
export const TeamApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = TeamApiAxiosParamCreator(configuration)
    return {
        /**
         * チームメンバーを削除します。
         * @summary チームメンバーの削除
         * @param {string} teamname チーム名
         * @param {string} username ユーザ名
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteTeamMembers(teamname: string, username: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteTeamMembers(teamname, username, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * チームタスクを削除します。
         * @summary チームタスク削除
         * @param {string} teamname チーム名
         * @param {number} taskId タスクID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteTeamTask(teamname: string, taskId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteTeamTask(teamname, taskId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * チームナレッジを削除します。
         * @summary チームナレッジ削除
         * @param {string} teamname チーム名
         * @param {number} wikiId wikiID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteTeamWiki(teamname: string, wikiId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteTeamWiki(teamname, wikiId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * チームのインテグレーション設定情報リストを取得します。
         * @summary チームのインテグレーション設定リストを取得
         * @param {string} teamname チーム名
         * @param {number} [projectOrder] プロジェクトオーダー
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findTeamIntegSettings(teamname: string, projectOrder?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IntegSettings>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findTeamIntegSettings(teamname, projectOrder, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * チームメンバーの出席状況のインテグレーション情報を取得します。
         * @summary チームメンバーの出席状況のインテグレーション情報リストを取得
         * @param {string} teamname チーム名
         * @param {string} username ユーザ名
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findTeamMemberAttendanceIntegs(teamname: string, username: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MemberAttendanceIntegs>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findTeamMemberAttendanceIntegs(teamname, username, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * メンバーのスキル詳細のリストを取得します。
         * @summary メンバースキルリスト取得
         * @param {string} teamname チーム名
         * @param {string} username ユーザ名
         * @param {string} skillType スキル種別
         * @param {number} skillOrder スキルオーダー
         * @param {number} [page] ページ番号
         * @param {number} [perPage] 1ページあたりの要素数 (MAX100)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findTeamMemberSkillAnalytics(teamname: string, username: string, skillType: string, skillOrder: number, page?: number, perPage?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SkillAnalytics>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findTeamMemberSkillAnalytics(teamname, username, skillType, skillOrder, page, perPage, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * チームメンバーのスキル詳細のリストを取得します。
         * @summary チームメンバースキル詳細リストの取得
         * @param {string} teamname チーム名
         * @param {string} username ユーザ名
         * @param {string} skillType スキル種別
         * @param {number} skillOrder スキルオーダー
         * @param {number} [page] ページ番号
         * @param {number} [perPage] 1ページあたりの要素数 (MAX100)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findTeamMemberSkillDetails(teamname: string, username: string, skillType: string, skillOrder: number, page?: number, perPage?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SkillDetails>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findTeamMemberSkillDetails(teamname, username, skillType, skillOrder, page, perPage, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * スキルのリストを取得します。
         * @summary メンバのスキルリスト取得(未)
         * @param {string} teamname チーム名
         * @param {string} username ユーザ名
         * @param {number} [page] ページ番号
         * @param {number} [perPage] 1ページあたりの要素数 (MAX100)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findTeamMemberSkills(teamname: string, username: string, page?: number, perPage?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Skills>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findTeamMemberSkills(teamname, username, page, perPage, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * チームメンバーの作業時間を取得します。
         * @summary チームメンバーの作業時間取得
         * @param {string} teamname チーム名
         * @param {string} username ユーザ名
         * @param {number} [projectOrder] プロジェクトオーダー
         * @param {number} [year] 年
         * @param {number} [month] 月
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findTeamMemberTimeEntries(teamname: string, username: string, projectOrder?: number, year?: number, month?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MemberTimeentries>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findTeamMemberTimeEntries(teamname, username, projectOrder, year, month, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * チームメンバーのリストを取得します。
         * @summary チームメンバーリストの取得(未)
         * @param {string} teamname チーム名
         * @param {number} projectOrder プロジェクトオーダー
         * @param {number} [page] ページ番号
         * @param {number} [perPage] 1ページあたりの要素数 (MAX100)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findTeamMembers(teamname: string, projectOrder: number, page?: number, perPage?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Members>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findTeamMembers(teamname, projectOrder, page, perPage, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * メンションリストを取得します。
         * @summary メンションリスト取得
         * @param {string} teamname チーム名
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findTeamMentions(teamname: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Mentions>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findTeamMentions(teamname, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 所属しているチームのリストを取得します。
         * @summary 所属チームリストの取得
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findTeams(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Teams>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findTeams(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * チームの情報を取得します。
         * @summary チーム情報の取得
         * @param {string} teamname チーム名
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTeam(teamname: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Team>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTeam(teamname, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * チームの紹介ページを取得します。
         * @summary チーム紹介ページの取得
         * @param {string} teamname チーム名
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTeamDescription(teamname: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TeamDescription>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTeamDescription(teamname, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 特定のチームメンバー情報を取得します。
         * @summary 特定のチームメンバー情報の取得(未)
         * @param {string} teamname チーム名
         * @param {number} projectOrder プロジェクトオーダー
         * @param {string} username ユーザ名
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTeamMember(teamname: string, projectOrder: number, username: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<User>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTeamMember(teamname, projectOrder, username, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 自分自身の出席状況を取得します。
         * @summary チームメンバーの出席状況の取得
         * @param {string} teamname チーム名
         * @param {string} username ユーザ名
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTeamMemberAttendance(teamname: string, username: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MemberAttendance>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTeamMemberAttendance(teamname, username, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * メンバーの特定のスキル情報を取得します。
         * @summary メンバーの特定のスキル情報取得(未)
         * @param {string} teamname チーム名
         * @param {string} username ユーザ名
         * @param {string} skillType スキル種別
         * @param {number} skillOrder スキルオーダー
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTeamMemberSkill(teamname: string, username: string, skillType: string, skillOrder: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Skill>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTeamMemberSkill(teamname, username, skillType, skillOrder, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * チームの公開ポートフォリオリストを取得します。
         * @summary チームの公開ポートフォリオリストを取得
         * @param {string} teamname チーム名
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTeamPublicPortfolios(teamname: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Portfolios>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTeamPublicPortfolios(teamname, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * チームの情報を取得します。
         * @summary チーム情報の取得(未)
         * @param {string} teamname チーム名
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTeamSkills(teamname: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<User>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTeamSkills(teamname, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * チームの最新スキル情報を取得します。
         * @summary チームの最新スキル情報
         * @param {string} teamname チーム名
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTeamSkillsReload(teamname: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TeamSkills>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTeamSkillsReload(teamname, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * チームの紹介ページを追加します。
         * @summary チーム紹介ページ追加
         * @param {string} teamname チーム名
         * @param {TeamDescriptionRequest} teamDescriptionRequest チーム紹介情報
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async setTeamDescription(teamname: string, teamDescriptionRequest: TeamDescriptionRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TeamDescription>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.setTeamDescription(teamname, teamDescriptionRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * チームのインテグレーション設定情報を更新します。
         * @summary チームのインテグレーション設定の更新
         * @param {string} teamname チーム名
         * @param {TeamIntegSettingReq} teamIntegSettingReq 更新するインテグレーション設定情報
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async setTeamIntegSetting(teamname: string, teamIntegSettingReq: TeamIntegSettingReq, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.setTeamIntegSetting(teamname, teamIntegSettingReq, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 自分自身の出席状況を更新します。
         * @summary チームメンバーの出席状況の更新
         * @param {string} teamname チーム名
         * @param {string} username ユーザ名
         * @param {MemberAttendanceReq} memberAttendanceReq 更新後の出席状況
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async setTeamMemberAttendance(teamname: string, username: string, memberAttendanceReq: MemberAttendanceReq, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MemberAttendance>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.setTeamMemberAttendance(teamname, username, memberAttendanceReq, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 出席状況へのインテグレーション情報を更新します。
         * @summary チームメンバーの出席状況へのインテグレーション情報の更新
         * @param {string} teamname チーム名
         * @param {string} username ユーザ名
         * @param {MemberAttendanceIntegReq} memberAttendanceIntegReq 更新する出席状況へのインテグレーション情報
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async setTeamMemberAttendanceInteg(teamname: string, username: string, memberAttendanceIntegReq: MemberAttendanceIntegReq, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.setTeamMemberAttendanceInteg(teamname, username, memberAttendanceIntegReq, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * チーム内表示名を更新します。
         * @summary チーム内表示名の更新(未)
         * @param {string} teamname チーム名
         * @param {string} username ユーザ名
         * @param {IdValue} idValue 更新後の表示名
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async setTeamMemberDisplayname(teamname: string, username: string, idValue: IdValue, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.setTeamMemberDisplayname(teamname, username, idValue, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * チーム情報を更新します。
         * @summary チーム情報の更新(未)
         * @param {string} teamname チーム名
         * @param {UserRequest} userRequest ユーザー情報
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateTeam(teamname: string, userRequest: UserRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<User>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateTeam(teamname, userRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * チームメンバーを更新します。
         * @summary チームメンバーの更新(未)
         * @param {string} teamname チーム名
         * @param {string} username ユーザ名
         * @param {UserRequest} userRequest ユーザー情報
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateTeamMember(teamname: string, username: string, userRequest: UserRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<User>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateTeamMember(teamname, username, userRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * チーム情報を更新します。
         * @summary チーム情報の更新(未)
         * @param {string} teamname チーム名
         * @param {UserRequest} userRequest ユーザー情報
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateTeamSkills(teamname: string, userRequest: UserRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<User>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateTeamSkills(teamname, userRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * チーム紹介の画像ファイルをアップロードします。
         * @summary チーム紹介の画像ファイルをアップロード
         * @param {string} teamname チーム名
         * @param {File} [image] チーム紹介用にアップロードした画像
         * @param {string} [fileName] ファイル名
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async uploadTeamDescriptionImg(teamname: string, image?: File, fileName?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UploadResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.uploadTeamDescriptionImg(teamname, image, fileName, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * TeamApi - factory interface
 * @export
 */
export const TeamApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = TeamApiFp(configuration)
    return {
        /**
         * チームメンバーを削除します。
         * @summary チームメンバーの削除
         * @param {string} teamname チーム名
         * @param {string} username ユーザ名
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteTeamMembers(teamname: string, username: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteTeamMembers(teamname, username, options).then((request) => request(axios, basePath));
        },
        /**
         * チームタスクを削除します。
         * @summary チームタスク削除
         * @param {string} teamname チーム名
         * @param {number} taskId タスクID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteTeamTask(teamname: string, taskId: number, options?: any): AxiosPromise<void> {
            return localVarFp.deleteTeamTask(teamname, taskId, options).then((request) => request(axios, basePath));
        },
        /**
         * チームナレッジを削除します。
         * @summary チームナレッジ削除
         * @param {string} teamname チーム名
         * @param {number} wikiId wikiID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteTeamWiki(teamname: string, wikiId: number, options?: any): AxiosPromise<void> {
            return localVarFp.deleteTeamWiki(teamname, wikiId, options).then((request) => request(axios, basePath));
        },
        /**
         * チームのインテグレーション設定情報リストを取得します。
         * @summary チームのインテグレーション設定リストを取得
         * @param {string} teamname チーム名
         * @param {number} [projectOrder] プロジェクトオーダー
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findTeamIntegSettings(teamname: string, projectOrder?: number, options?: any): AxiosPromise<IntegSettings> {
            return localVarFp.findTeamIntegSettings(teamname, projectOrder, options).then((request) => request(axios, basePath));
        },
        /**
         * チームメンバーの出席状況のインテグレーション情報を取得します。
         * @summary チームメンバーの出席状況のインテグレーション情報リストを取得
         * @param {string} teamname チーム名
         * @param {string} username ユーザ名
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findTeamMemberAttendanceIntegs(teamname: string, username: string, options?: any): AxiosPromise<MemberAttendanceIntegs> {
            return localVarFp.findTeamMemberAttendanceIntegs(teamname, username, options).then((request) => request(axios, basePath));
        },
        /**
         * メンバーのスキル詳細のリストを取得します。
         * @summary メンバースキルリスト取得
         * @param {string} teamname チーム名
         * @param {string} username ユーザ名
         * @param {string} skillType スキル種別
         * @param {number} skillOrder スキルオーダー
         * @param {number} [page] ページ番号
         * @param {number} [perPage] 1ページあたりの要素数 (MAX100)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findTeamMemberSkillAnalytics(teamname: string, username: string, skillType: string, skillOrder: number, page?: number, perPage?: number, options?: any): AxiosPromise<SkillAnalytics> {
            return localVarFp.findTeamMemberSkillAnalytics(teamname, username, skillType, skillOrder, page, perPage, options).then((request) => request(axios, basePath));
        },
        /**
         * チームメンバーのスキル詳細のリストを取得します。
         * @summary チームメンバースキル詳細リストの取得
         * @param {string} teamname チーム名
         * @param {string} username ユーザ名
         * @param {string} skillType スキル種別
         * @param {number} skillOrder スキルオーダー
         * @param {number} [page] ページ番号
         * @param {number} [perPage] 1ページあたりの要素数 (MAX100)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findTeamMemberSkillDetails(teamname: string, username: string, skillType: string, skillOrder: number, page?: number, perPage?: number, options?: any): AxiosPromise<SkillDetails> {
            return localVarFp.findTeamMemberSkillDetails(teamname, username, skillType, skillOrder, page, perPage, options).then((request) => request(axios, basePath));
        },
        /**
         * スキルのリストを取得します。
         * @summary メンバのスキルリスト取得(未)
         * @param {string} teamname チーム名
         * @param {string} username ユーザ名
         * @param {number} [page] ページ番号
         * @param {number} [perPage] 1ページあたりの要素数 (MAX100)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findTeamMemberSkills(teamname: string, username: string, page?: number, perPage?: number, options?: any): AxiosPromise<Skills> {
            return localVarFp.findTeamMemberSkills(teamname, username, page, perPage, options).then((request) => request(axios, basePath));
        },
        /**
         * チームメンバーの作業時間を取得します。
         * @summary チームメンバーの作業時間取得
         * @param {string} teamname チーム名
         * @param {string} username ユーザ名
         * @param {number} [projectOrder] プロジェクトオーダー
         * @param {number} [year] 年
         * @param {number} [month] 月
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findTeamMemberTimeEntries(teamname: string, username: string, projectOrder?: number, year?: number, month?: number, options?: any): AxiosPromise<MemberTimeentries> {
            return localVarFp.findTeamMemberTimeEntries(teamname, username, projectOrder, year, month, options).then((request) => request(axios, basePath));
        },
        /**
         * チームメンバーのリストを取得します。
         * @summary チームメンバーリストの取得(未)
         * @param {string} teamname チーム名
         * @param {number} projectOrder プロジェクトオーダー
         * @param {number} [page] ページ番号
         * @param {number} [perPage] 1ページあたりの要素数 (MAX100)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findTeamMembers(teamname: string, projectOrder: number, page?: number, perPage?: number, options?: any): AxiosPromise<Members> {
            return localVarFp.findTeamMembers(teamname, projectOrder, page, perPage, options).then((request) => request(axios, basePath));
        },
        /**
         * メンションリストを取得します。
         * @summary メンションリスト取得
         * @param {string} teamname チーム名
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findTeamMentions(teamname: string, options?: any): AxiosPromise<Mentions> {
            return localVarFp.findTeamMentions(teamname, options).then((request) => request(axios, basePath));
        },
        /**
         * 所属しているチームのリストを取得します。
         * @summary 所属チームリストの取得
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findTeams(options?: any): AxiosPromise<Teams> {
            return localVarFp.findTeams(options).then((request) => request(axios, basePath));
        },
        /**
         * チームの情報を取得します。
         * @summary チーム情報の取得
         * @param {string} teamname チーム名
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTeam(teamname: string, options?: any): AxiosPromise<Team> {
            return localVarFp.getTeam(teamname, options).then((request) => request(axios, basePath));
        },
        /**
         * チームの紹介ページを取得します。
         * @summary チーム紹介ページの取得
         * @param {string} teamname チーム名
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTeamDescription(teamname: string, options?: any): AxiosPromise<TeamDescription> {
            return localVarFp.getTeamDescription(teamname, options).then((request) => request(axios, basePath));
        },
        /**
         * 特定のチームメンバー情報を取得します。
         * @summary 特定のチームメンバー情報の取得(未)
         * @param {string} teamname チーム名
         * @param {number} projectOrder プロジェクトオーダー
         * @param {string} username ユーザ名
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTeamMember(teamname: string, projectOrder: number, username: string, options?: any): AxiosPromise<User> {
            return localVarFp.getTeamMember(teamname, projectOrder, username, options).then((request) => request(axios, basePath));
        },
        /**
         * 自分自身の出席状況を取得します。
         * @summary チームメンバーの出席状況の取得
         * @param {string} teamname チーム名
         * @param {string} username ユーザ名
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTeamMemberAttendance(teamname: string, username: string, options?: any): AxiosPromise<MemberAttendance> {
            return localVarFp.getTeamMemberAttendance(teamname, username, options).then((request) => request(axios, basePath));
        },
        /**
         * メンバーの特定のスキル情報を取得します。
         * @summary メンバーの特定のスキル情報取得(未)
         * @param {string} teamname チーム名
         * @param {string} username ユーザ名
         * @param {string} skillType スキル種別
         * @param {number} skillOrder スキルオーダー
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTeamMemberSkill(teamname: string, username: string, skillType: string, skillOrder: number, options?: any): AxiosPromise<Skill> {
            return localVarFp.getTeamMemberSkill(teamname, username, skillType, skillOrder, options).then((request) => request(axios, basePath));
        },
        /**
         * チームの公開ポートフォリオリストを取得します。
         * @summary チームの公開ポートフォリオリストを取得
         * @param {string} teamname チーム名
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTeamPublicPortfolios(teamname: string, options?: any): AxiosPromise<Portfolios> {
            return localVarFp.getTeamPublicPortfolios(teamname, options).then((request) => request(axios, basePath));
        },
        /**
         * チームの情報を取得します。
         * @summary チーム情報の取得(未)
         * @param {string} teamname チーム名
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTeamSkills(teamname: string, options?: any): AxiosPromise<User> {
            return localVarFp.getTeamSkills(teamname, options).then((request) => request(axios, basePath));
        },
        /**
         * チームの最新スキル情報を取得します。
         * @summary チームの最新スキル情報
         * @param {string} teamname チーム名
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTeamSkillsReload(teamname: string, options?: any): AxiosPromise<TeamSkills> {
            return localVarFp.getTeamSkillsReload(teamname, options).then((request) => request(axios, basePath));
        },
        /**
         * チームの紹介ページを追加します。
         * @summary チーム紹介ページ追加
         * @param {string} teamname チーム名
         * @param {TeamDescriptionRequest} teamDescriptionRequest チーム紹介情報
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setTeamDescription(teamname: string, teamDescriptionRequest: TeamDescriptionRequest, options?: any): AxiosPromise<TeamDescription> {
            return localVarFp.setTeamDescription(teamname, teamDescriptionRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * チームのインテグレーション設定情報を更新します。
         * @summary チームのインテグレーション設定の更新
         * @param {string} teamname チーム名
         * @param {TeamIntegSettingReq} teamIntegSettingReq 更新するインテグレーション設定情報
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setTeamIntegSetting(teamname: string, teamIntegSettingReq: TeamIntegSettingReq, options?: any): AxiosPromise<void> {
            return localVarFp.setTeamIntegSetting(teamname, teamIntegSettingReq, options).then((request) => request(axios, basePath));
        },
        /**
         * 自分自身の出席状況を更新します。
         * @summary チームメンバーの出席状況の更新
         * @param {string} teamname チーム名
         * @param {string} username ユーザ名
         * @param {MemberAttendanceReq} memberAttendanceReq 更新後の出席状況
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setTeamMemberAttendance(teamname: string, username: string, memberAttendanceReq: MemberAttendanceReq, options?: any): AxiosPromise<MemberAttendance> {
            return localVarFp.setTeamMemberAttendance(teamname, username, memberAttendanceReq, options).then((request) => request(axios, basePath));
        },
        /**
         * 出席状況へのインテグレーション情報を更新します。
         * @summary チームメンバーの出席状況へのインテグレーション情報の更新
         * @param {string} teamname チーム名
         * @param {string} username ユーザ名
         * @param {MemberAttendanceIntegReq} memberAttendanceIntegReq 更新する出席状況へのインテグレーション情報
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setTeamMemberAttendanceInteg(teamname: string, username: string, memberAttendanceIntegReq: MemberAttendanceIntegReq, options?: any): AxiosPromise<void> {
            return localVarFp.setTeamMemberAttendanceInteg(teamname, username, memberAttendanceIntegReq, options).then((request) => request(axios, basePath));
        },
        /**
         * チーム内表示名を更新します。
         * @summary チーム内表示名の更新(未)
         * @param {string} teamname チーム名
         * @param {string} username ユーザ名
         * @param {IdValue} idValue 更新後の表示名
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setTeamMemberDisplayname(teamname: string, username: string, idValue: IdValue, options?: any): AxiosPromise<void> {
            return localVarFp.setTeamMemberDisplayname(teamname, username, idValue, options).then((request) => request(axios, basePath));
        },
        /**
         * チーム情報を更新します。
         * @summary チーム情報の更新(未)
         * @param {string} teamname チーム名
         * @param {UserRequest} userRequest ユーザー情報
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateTeam(teamname: string, userRequest: UserRequest, options?: any): AxiosPromise<User> {
            return localVarFp.updateTeam(teamname, userRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * チームメンバーを更新します。
         * @summary チームメンバーの更新(未)
         * @param {string} teamname チーム名
         * @param {string} username ユーザ名
         * @param {UserRequest} userRequest ユーザー情報
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateTeamMember(teamname: string, username: string, userRequest: UserRequest, options?: any): AxiosPromise<User> {
            return localVarFp.updateTeamMember(teamname, username, userRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * チーム情報を更新します。
         * @summary チーム情報の更新(未)
         * @param {string} teamname チーム名
         * @param {UserRequest} userRequest ユーザー情報
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateTeamSkills(teamname: string, userRequest: UserRequest, options?: any): AxiosPromise<User> {
            return localVarFp.updateTeamSkills(teamname, userRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * チーム紹介の画像ファイルをアップロードします。
         * @summary チーム紹介の画像ファイルをアップロード
         * @param {string} teamname チーム名
         * @param {File} [image] チーム紹介用にアップロードした画像
         * @param {string} [fileName] ファイル名
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadTeamDescriptionImg(teamname: string, image?: File, fileName?: string, options?: any): AxiosPromise<UploadResponse> {
            return localVarFp.uploadTeamDescriptionImg(teamname, image, fileName, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * TeamApi - object-oriented interface
 * @export
 * @class TeamApi
 * @extends {BaseAPI}
 */
export class TeamApi extends BaseAPI {
    /**
     * チームメンバーを削除します。
     * @summary チームメンバーの削除
     * @param {string} teamname チーム名
     * @param {string} username ユーザ名
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TeamApi
     */
    public async deleteTeamMembers(teamname: string, username: string, options?: AxiosRequestConfig) {
        const retry = 2;
        let resp;
        for (let i = 0; i < retry; i++) {
            resp = await TeamApiFp(this.configuration).deleteTeamMembers(teamname, username, options).then((request) => request(this.axios, this.basePath)).catch((error) => {
                if (error?.response) {
                    return error.response;
                } else {
                    return { status: 500 };
                }
            });
            if (resp?.status === 401) {
                await refreshIdToken();
                console.debug('Retry deleteTeamMembers ' + i + '/' + (retry - 1));
                continue;
            }
            break;
        }
        return resp;
    }

    /**
     * チームタスクを削除します。
     * @summary チームタスク削除
     * @param {string} teamname チーム名
     * @param {number} taskId タスクID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TeamApi
     */
    public async deleteTeamTask(teamname: string, taskId: number, options?: AxiosRequestConfig) {
        const retry = 2;
        let resp;
        for (let i = 0; i < retry; i++) {
            resp = await TeamApiFp(this.configuration).deleteTeamTask(teamname, taskId, options).then((request) => request(this.axios, this.basePath)).catch((error) => {
                if (error?.response) {
                    return error.response;
                } else {
                    return { status: 500 };
                }
            });
            if (resp?.status === 401) {
                await refreshIdToken();
                console.debug('Retry deleteTeamTask ' + i + '/' + (retry - 1));
                continue;
            }
            break;
        }
        return resp;
    }

    /**
     * チームナレッジを削除します。
     * @summary チームナレッジ削除
     * @param {string} teamname チーム名
     * @param {number} wikiId wikiID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TeamApi
     */
    public async deleteTeamWiki(teamname: string, wikiId: number, options?: AxiosRequestConfig) {
        const retry = 2;
        let resp;
        for (let i = 0; i < retry; i++) {
            resp = await TeamApiFp(this.configuration).deleteTeamWiki(teamname, wikiId, options).then((request) => request(this.axios, this.basePath)).catch((error) => {
                if (error?.response) {
                    return error.response;
                } else {
                    return { status: 500 };
                }
            });
            if (resp?.status === 401) {
                await refreshIdToken();
                console.debug('Retry deleteTeamWiki ' + i + '/' + (retry - 1));
                continue;
            }
            break;
        }
        return resp;
    }

    /**
     * チームのインテグレーション設定情報リストを取得します。
     * @summary チームのインテグレーション設定リストを取得
     * @param {string} teamname チーム名
     * @param {number} [projectOrder] プロジェクトオーダー
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TeamApi
     */
    public async findTeamIntegSettings(teamname: string, projectOrder?: number, options?: AxiosRequestConfig) {
        const retry = 2;
        let resp;
        for (let i = 0; i < retry; i++) {
            resp = await TeamApiFp(this.configuration).findTeamIntegSettings(teamname, projectOrder, options).then((request) => request(this.axios, this.basePath)).catch((error) => {
                if (error?.response) {
                    return error.response;
                } else {
                    return { status: 500 };
                }
            });
            if (resp?.status === 401) {
                await refreshIdToken();
                console.debug('Retry findTeamIntegSettings ' + i + '/' + (retry - 1));
                continue;
            }
            break;
        }
        return resp;
    }

    /**
     * チームメンバーの出席状況のインテグレーション情報を取得します。
     * @summary チームメンバーの出席状況のインテグレーション情報リストを取得
     * @param {string} teamname チーム名
     * @param {string} username ユーザ名
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TeamApi
     */
    public async findTeamMemberAttendanceIntegs(teamname: string, username: string, options?: AxiosRequestConfig) {
        const retry = 2;
        let resp;
        for (let i = 0; i < retry; i++) {
            resp = await TeamApiFp(this.configuration).findTeamMemberAttendanceIntegs(teamname, username, options).then((request) => request(this.axios, this.basePath)).catch((error) => {
                if (error?.response) {
                    return error.response;
                } else {
                    return { status: 500 };
                }
            });
            if (resp?.status === 401) {
                await refreshIdToken();
                console.debug('Retry findTeamMemberAttendanceIntegs ' + i + '/' + (retry - 1));
                continue;
            }
            break;
        }
        return resp;
    }

    /**
     * メンバーのスキル詳細のリストを取得します。
     * @summary メンバースキルリスト取得
     * @param {string} teamname チーム名
     * @param {string} username ユーザ名
     * @param {string} skillType スキル種別
     * @param {number} skillOrder スキルオーダー
     * @param {number} [page] ページ番号
     * @param {number} [perPage] 1ページあたりの要素数 (MAX100)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TeamApi
     */
    public async findTeamMemberSkillAnalytics(teamname: string, username: string, skillType: string, skillOrder: number, page?: number, perPage?: number, options?: AxiosRequestConfig) {
        const retry = 2;
        let resp;
        for (let i = 0; i < retry; i++) {
            resp = await TeamApiFp(this.configuration).findTeamMemberSkillAnalytics(teamname, username, skillType, skillOrder, page, perPage, options).then((request) => request(this.axios, this.basePath)).catch((error) => {
                if (error?.response) {
                    return error.response;
                } else {
                    return { status: 500 };
                }
            });
            if (resp?.status === 401) {
                await refreshIdToken();
                console.debug('Retry findTeamMemberSkillAnalytics ' + i + '/' + (retry - 1));
                continue;
            }
            break;
        }
        return resp;
    }

    /**
     * チームメンバーのスキル詳細のリストを取得します。
     * @summary チームメンバースキル詳細リストの取得
     * @param {string} teamname チーム名
     * @param {string} username ユーザ名
     * @param {string} skillType スキル種別
     * @param {number} skillOrder スキルオーダー
     * @param {number} [page] ページ番号
     * @param {number} [perPage] 1ページあたりの要素数 (MAX100)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TeamApi
     */
    public async findTeamMemberSkillDetails(teamname: string, username: string, skillType: string, skillOrder: number, page?: number, perPage?: number, options?: AxiosRequestConfig) {
        const retry = 2;
        let resp;
        for (let i = 0; i < retry; i++) {
            resp = await TeamApiFp(this.configuration).findTeamMemberSkillDetails(teamname, username, skillType, skillOrder, page, perPage, options).then((request) => request(this.axios, this.basePath)).catch((error) => {
                if (error?.response) {
                    return error.response;
                } else {
                    return { status: 500 };
                }
            });
            if (resp?.status === 401) {
                await refreshIdToken();
                console.debug('Retry findTeamMemberSkillDetails ' + i + '/' + (retry - 1));
                continue;
            }
            break;
        }
        return resp;
    }

    /**
     * スキルのリストを取得します。
     * @summary メンバのスキルリスト取得(未)
     * @param {string} teamname チーム名
     * @param {string} username ユーザ名
     * @param {number} [page] ページ番号
     * @param {number} [perPage] 1ページあたりの要素数 (MAX100)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TeamApi
     */
    public async findTeamMemberSkills(teamname: string, username: string, page?: number, perPage?: number, options?: AxiosRequestConfig) {
        const retry = 2;
        let resp;
        for (let i = 0; i < retry; i++) {
            resp = await TeamApiFp(this.configuration).findTeamMemberSkills(teamname, username, page, perPage, options).then((request) => request(this.axios, this.basePath)).catch((error) => {
                if (error?.response) {
                    return error.response;
                } else {
                    return { status: 500 };
                }
            });
            if (resp?.status === 401) {
                await refreshIdToken();
                console.debug('Retry findTeamMemberSkills ' + i + '/' + (retry - 1));
                continue;
            }
            break;
        }
        return resp;
    }

    /**
     * チームメンバーの作業時間を取得します。
     * @summary チームメンバーの作業時間取得
     * @param {string} teamname チーム名
     * @param {string} username ユーザ名
     * @param {number} [projectOrder] プロジェクトオーダー
     * @param {number} [year] 年
     * @param {number} [month] 月
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TeamApi
     */
    public async findTeamMemberTimeEntries(teamname: string, username: string, projectOrder?: number, year?: number, month?: number, options?: AxiosRequestConfig) {
        const retry = 2;
        let resp;
        for (let i = 0; i < retry; i++) {
            resp = await TeamApiFp(this.configuration).findTeamMemberTimeEntries(teamname, username, projectOrder, year, month, options).then((request) => request(this.axios, this.basePath)).catch((error) => {
                if (error?.response) {
                    return error.response;
                } else {
                    return { status: 500 };
                }
            });
            if (resp?.status === 401) {
                await refreshIdToken();
                console.debug('Retry findTeamMemberTimeEntries ' + i + '/' + (retry - 1));
                continue;
            }
            break;
        }
        return resp;
    }

    /**
     * チームメンバーのリストを取得します。
     * @summary チームメンバーリストの取得(未)
     * @param {string} teamname チーム名
     * @param {number} projectOrder プロジェクトオーダー
     * @param {number} [page] ページ番号
     * @param {number} [perPage] 1ページあたりの要素数 (MAX100)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TeamApi
     */
    public async findTeamMembers(teamname: string, projectOrder: number, page?: number, perPage?: number, options?: AxiosRequestConfig) {
        const retry = 2;
        let resp;
        for (let i = 0; i < retry; i++) {
            resp = await TeamApiFp(this.configuration).findTeamMembers(teamname, projectOrder, page, perPage, options).then((request) => request(this.axios, this.basePath)).catch((error) => {
                if (error?.response) {
                    return error.response;
                } else {
                    return { status: 500 };
                }
            });
            if (resp?.status === 401) {
                await refreshIdToken();
                console.debug('Retry findTeamMembers ' + i + '/' + (retry - 1));
                continue;
            }
            break;
        }
        return resp;
    }

    /**
     * メンションリストを取得します。
     * @summary メンションリスト取得
     * @param {string} teamname チーム名
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TeamApi
     */
    public async findTeamMentions(teamname: string, options?: AxiosRequestConfig) {
        const retry = 2;
        let resp;
        for (let i = 0; i < retry; i++) {
            resp = await TeamApiFp(this.configuration).findTeamMentions(teamname, options).then((request) => request(this.axios, this.basePath)).catch((error) => {
                if (error?.response) {
                    return error.response;
                } else {
                    return { status: 500 };
                }
            });
            if (resp?.status === 401) {
                await refreshIdToken();
                console.debug('Retry findTeamMentions ' + i + '/' + (retry - 1));
                continue;
            }
            break;
        }
        return resp;
    }

    /**
     * 所属しているチームのリストを取得します。
     * @summary 所属チームリストの取得
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TeamApi
     */
    public async findTeams(options?: AxiosRequestConfig) {
        const retry = 2;
        let resp;
        for (let i = 0; i < retry; i++) {
            resp = await TeamApiFp(this.configuration).findTeams(options).then((request) => request(this.axios, this.basePath)).catch((error) => {
                if (error?.response) {
                    return error.response;
                } else {
                    return { status: 500 };
                }
            });
            if (resp?.status === 401) {
                await refreshIdToken();
                console.debug('Retry findTeams ' + i + '/' + (retry - 1));
                continue;
            }
            break;
        }
        return resp;
    }

    /**
     * チームの情報を取得します。
     * @summary チーム情報の取得
     * @param {string} teamname チーム名
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TeamApi
     */
    public async getTeam(teamname: string, options?: AxiosRequestConfig) {
        const retry = 2;
        let resp;
        for (let i = 0; i < retry; i++) {
            resp = await TeamApiFp(this.configuration).getTeam(teamname, options).then((request) => request(this.axios, this.basePath)).catch((error) => {
                if (error?.response) {
                    return error.response;
                } else {
                    return { status: 500 };
                }
            });
            if (resp?.status === 401) {
                await refreshIdToken();
                console.debug('Retry getTeam ' + i + '/' + (retry - 1));
                continue;
            }
            break;
        }
        return resp;
    }

    /**
     * チームの紹介ページを取得します。
     * @summary チーム紹介ページの取得
     * @param {string} teamname チーム名
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TeamApi
     */
    public async getTeamDescription(teamname: string, options?: AxiosRequestConfig) {
        const retry = 2;
        let resp;
        for (let i = 0; i < retry; i++) {
            resp = await TeamApiFp(this.configuration).getTeamDescription(teamname, options).then((request) => request(this.axios, this.basePath)).catch((error) => {
                if (error?.response) {
                    return error.response;
                } else {
                    return { status: 500 };
                }
            });
            if (resp?.status === 401) {
                await refreshIdToken();
                console.debug('Retry getTeamDescription ' + i + '/' + (retry - 1));
                continue;
            }
            break;
        }
        return resp;
    }

    /**
     * 特定のチームメンバー情報を取得します。
     * @summary 特定のチームメンバー情報の取得(未)
     * @param {string} teamname チーム名
     * @param {number} projectOrder プロジェクトオーダー
     * @param {string} username ユーザ名
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TeamApi
     */
    public async getTeamMember(teamname: string, projectOrder: number, username: string, options?: AxiosRequestConfig) {
        const retry = 2;
        let resp;
        for (let i = 0; i < retry; i++) {
            resp = await TeamApiFp(this.configuration).getTeamMember(teamname, projectOrder, username, options).then((request) => request(this.axios, this.basePath)).catch((error) => {
                if (error?.response) {
                    return error.response;
                } else {
                    return { status: 500 };
                }
            });
            if (resp?.status === 401) {
                await refreshIdToken();
                console.debug('Retry getTeamMember ' + i + '/' + (retry - 1));
                continue;
            }
            break;
        }
        return resp;
    }

    /**
     * 自分自身の出席状況を取得します。
     * @summary チームメンバーの出席状況の取得
     * @param {string} teamname チーム名
     * @param {string} username ユーザ名
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TeamApi
     */
    public async getTeamMemberAttendance(teamname: string, username: string, options?: AxiosRequestConfig) {
        const retry = 2;
        let resp;
        for (let i = 0; i < retry; i++) {
            resp = await TeamApiFp(this.configuration).getTeamMemberAttendance(teamname, username, options).then((request) => request(this.axios, this.basePath)).catch((error) => {
                if (error?.response) {
                    return error.response;
                } else {
                    return { status: 500 };
                }
            });
            if (resp?.status === 401) {
                await refreshIdToken();
                console.debug('Retry getTeamMemberAttendance ' + i + '/' + (retry - 1));
                continue;
            }
            break;
        }
        return resp;
    }

    /**
     * メンバーの特定のスキル情報を取得します。
     * @summary メンバーの特定のスキル情報取得(未)
     * @param {string} teamname チーム名
     * @param {string} username ユーザ名
     * @param {string} skillType スキル種別
     * @param {number} skillOrder スキルオーダー
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TeamApi
     */
    public async getTeamMemberSkill(teamname: string, username: string, skillType: string, skillOrder: number, options?: AxiosRequestConfig) {
        const retry = 2;
        let resp;
        for (let i = 0; i < retry; i++) {
            resp = await TeamApiFp(this.configuration).getTeamMemberSkill(teamname, username, skillType, skillOrder, options).then((request) => request(this.axios, this.basePath)).catch((error) => {
                if (error?.response) {
                    return error.response;
                } else {
                    return { status: 500 };
                }
            });
            if (resp?.status === 401) {
                await refreshIdToken();
                console.debug('Retry getTeamMemberSkill ' + i + '/' + (retry - 1));
                continue;
            }
            break;
        }
        return resp;
    }

    /**
     * チームの公開ポートフォリオリストを取得します。
     * @summary チームの公開ポートフォリオリストを取得
     * @param {string} teamname チーム名
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TeamApi
     */
    public async getTeamPublicPortfolios(teamname: string, options?: AxiosRequestConfig) {
        const retry = 2;
        let resp;
        for (let i = 0; i < retry; i++) {
            resp = await TeamApiFp(this.configuration).getTeamPublicPortfolios(teamname, options).then((request) => request(this.axios, this.basePath)).catch((error) => {
                if (error?.response) {
                    return error.response;
                } else {
                    return { status: 500 };
                }
            });
            if (resp?.status === 401) {
                await refreshIdToken();
                console.debug('Retry getTeamPublicPortfolios ' + i + '/' + (retry - 1));
                continue;
            }
            break;
        }
        return resp;
    }

    /**
     * チームの情報を取得します。
     * @summary チーム情報の取得(未)
     * @param {string} teamname チーム名
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TeamApi
     */
    public async getTeamSkills(teamname: string, options?: AxiosRequestConfig) {
        const retry = 2;
        let resp;
        for (let i = 0; i < retry; i++) {
            resp = await TeamApiFp(this.configuration).getTeamSkills(teamname, options).then((request) => request(this.axios, this.basePath)).catch((error) => {
                if (error?.response) {
                    return error.response;
                } else {
                    return { status: 500 };
                }
            });
            if (resp?.status === 401) {
                await refreshIdToken();
                console.debug('Retry getTeamSkills ' + i + '/' + (retry - 1));
                continue;
            }
            break;
        }
        return resp;
    }

    /**
     * チームの最新スキル情報を取得します。
     * @summary チームの最新スキル情報
     * @param {string} teamname チーム名
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TeamApi
     */
    public async getTeamSkillsReload(teamname: string, options?: AxiosRequestConfig) {
        const retry = 2;
        let resp;
        for (let i = 0; i < retry; i++) {
            resp = await TeamApiFp(this.configuration).getTeamSkillsReload(teamname, options).then((request) => request(this.axios, this.basePath)).catch((error) => {
                if (error?.response) {
                    return error.response;
                } else {
                    return { status: 500 };
                }
            });
            if (resp?.status === 401) {
                await refreshIdToken();
                console.debug('Retry getTeamSkillsReload ' + i + '/' + (retry - 1));
                continue;
            }
            break;
        }
        return resp;
    }

    /**
     * チームの紹介ページを追加します。
     * @summary チーム紹介ページ追加
     * @param {string} teamname チーム名
     * @param {TeamDescriptionRequest} teamDescriptionRequest チーム紹介情報
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TeamApi
     */
    public async setTeamDescription(teamname: string, teamDescriptionRequest: TeamDescriptionRequest, options?: AxiosRequestConfig) {
        const retry = 2;
        let resp;
        for (let i = 0; i < retry; i++) {
            resp = await TeamApiFp(this.configuration).setTeamDescription(teamname, teamDescriptionRequest, options).then((request) => request(this.axios, this.basePath)).catch((error) => {
                if (error?.response) {
                    return error.response;
                } else {
                    return { status: 500 };
                }
            });
            if (resp?.status === 401) {
                await refreshIdToken();
                console.debug('Retry setTeamDescription ' + i + '/' + (retry - 1));
                continue;
            }
            break;
        }
        return resp;
    }

    /**
     * チームのインテグレーション設定情報を更新します。
     * @summary チームのインテグレーション設定の更新
     * @param {string} teamname チーム名
     * @param {TeamIntegSettingReq} teamIntegSettingReq 更新するインテグレーション設定情報
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TeamApi
     */
    public async setTeamIntegSetting(teamname: string, teamIntegSettingReq: TeamIntegSettingReq, options?: AxiosRequestConfig) {
        const retry = 2;
        let resp;
        for (let i = 0; i < retry; i++) {
            resp = await TeamApiFp(this.configuration).setTeamIntegSetting(teamname, teamIntegSettingReq, options).then((request) => request(this.axios, this.basePath)).catch((error) => {
                if (error?.response) {
                    return error.response;
                } else {
                    return { status: 500 };
                }
            });
            if (resp?.status === 401) {
                await refreshIdToken();
                console.debug('Retry setTeamIntegSetting ' + i + '/' + (retry - 1));
                continue;
            }
            break;
        }
        return resp;
    }

    /**
     * 自分自身の出席状況を更新します。
     * @summary チームメンバーの出席状況の更新
     * @param {string} teamname チーム名
     * @param {string} username ユーザ名
     * @param {MemberAttendanceReq} memberAttendanceReq 更新後の出席状況
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TeamApi
     */
    public async setTeamMemberAttendance(teamname: string, username: string, memberAttendanceReq: MemberAttendanceReq, options?: AxiosRequestConfig) {
        const retry = 2;
        let resp;
        for (let i = 0; i < retry; i++) {
            resp = await TeamApiFp(this.configuration).setTeamMemberAttendance(teamname, username, memberAttendanceReq, options).then((request) => request(this.axios, this.basePath)).catch((error) => {
                if (error?.response) {
                    return error.response;
                } else {
                    return { status: 500 };
                }
            });
            if (resp?.status === 401) {
                await refreshIdToken();
                console.debug('Retry setTeamMemberAttendance ' + i + '/' + (retry - 1));
                continue;
            }
            break;
        }
        return resp;
    }

    /**
     * 出席状況へのインテグレーション情報を更新します。
     * @summary チームメンバーの出席状況へのインテグレーション情報の更新
     * @param {string} teamname チーム名
     * @param {string} username ユーザ名
     * @param {MemberAttendanceIntegReq} memberAttendanceIntegReq 更新する出席状況へのインテグレーション情報
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TeamApi
     */
    public async setTeamMemberAttendanceInteg(teamname: string, username: string, memberAttendanceIntegReq: MemberAttendanceIntegReq, options?: AxiosRequestConfig) {
        const retry = 2;
        let resp;
        for (let i = 0; i < retry; i++) {
            resp = await TeamApiFp(this.configuration).setTeamMemberAttendanceInteg(teamname, username, memberAttendanceIntegReq, options).then((request) => request(this.axios, this.basePath)).catch((error) => {
                if (error?.response) {
                    return error.response;
                } else {
                    return { status: 500 };
                }
            });
            if (resp?.status === 401) {
                await refreshIdToken();
                console.debug('Retry setTeamMemberAttendanceInteg ' + i + '/' + (retry - 1));
                continue;
            }
            break;
        }
        return resp;
    }

    /**
     * チーム内表示名を更新します。
     * @summary チーム内表示名の更新(未)
     * @param {string} teamname チーム名
     * @param {string} username ユーザ名
     * @param {IdValue} idValue 更新後の表示名
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TeamApi
     */
    public async setTeamMemberDisplayname(teamname: string, username: string, idValue: IdValue, options?: AxiosRequestConfig) {
        const retry = 2;
        let resp;
        for (let i = 0; i < retry; i++) {
            resp = await TeamApiFp(this.configuration).setTeamMemberDisplayname(teamname, username, idValue, options).then((request) => request(this.axios, this.basePath)).catch((error) => {
                if (error?.response) {
                    return error.response;
                } else {
                    return { status: 500 };
                }
            });
            if (resp?.status === 401) {
                await refreshIdToken();
                console.debug('Retry setTeamMemberDisplayname ' + i + '/' + (retry - 1));
                continue;
            }
            break;
        }
        return resp;
    }

    /**
     * チーム情報を更新します。
     * @summary チーム情報の更新(未)
     * @param {string} teamname チーム名
     * @param {UserRequest} userRequest ユーザー情報
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TeamApi
     */
    public async updateTeam(teamname: string, userRequest: UserRequest, options?: AxiosRequestConfig) {
        const retry = 2;
        let resp;
        for (let i = 0; i < retry; i++) {
            resp = await TeamApiFp(this.configuration).updateTeam(teamname, userRequest, options).then((request) => request(this.axios, this.basePath)).catch((error) => {
                if (error?.response) {
                    return error.response;
                } else {
                    return { status: 500 };
                }
            });
            if (resp?.status === 401) {
                await refreshIdToken();
                console.debug('Retry updateTeam ' + i + '/' + (retry - 1));
                continue;
            }
            break;
        }
        return resp;
    }

    /**
     * チームメンバーを更新します。
     * @summary チームメンバーの更新(未)
     * @param {string} teamname チーム名
     * @param {string} username ユーザ名
     * @param {UserRequest} userRequest ユーザー情報
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TeamApi
     */
    public async updateTeamMember(teamname: string, username: string, userRequest: UserRequest, options?: AxiosRequestConfig) {
        const retry = 2;
        let resp;
        for (let i = 0; i < retry; i++) {
            resp = await TeamApiFp(this.configuration).updateTeamMember(teamname, username, userRequest, options).then((request) => request(this.axios, this.basePath)).catch((error) => {
                if (error?.response) {
                    return error.response;
                } else {
                    return { status: 500 };
                }
            });
            if (resp?.status === 401) {
                await refreshIdToken();
                console.debug('Retry updateTeamMember ' + i + '/' + (retry - 1));
                continue;
            }
            break;
        }
        return resp;
    }

    /**
     * チーム情報を更新します。
     * @summary チーム情報の更新(未)
     * @param {string} teamname チーム名
     * @param {UserRequest} userRequest ユーザー情報
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TeamApi
     */
    public async updateTeamSkills(teamname: string, userRequest: UserRequest, options?: AxiosRequestConfig) {
        const retry = 2;
        let resp;
        for (let i = 0; i < retry; i++) {
            resp = await TeamApiFp(this.configuration).updateTeamSkills(teamname, userRequest, options).then((request) => request(this.axios, this.basePath)).catch((error) => {
                if (error?.response) {
                    return error.response;
                } else {
                    return { status: 500 };
                }
            });
            if (resp?.status === 401) {
                await refreshIdToken();
                console.debug('Retry updateTeamSkills ' + i + '/' + (retry - 1));
                continue;
            }
            break;
        }
        return resp;
    }

    /**
     * チーム紹介の画像ファイルをアップロードします。
     * @summary チーム紹介の画像ファイルをアップロード
     * @param {string} teamname チーム名
     * @param {File} [image] チーム紹介用にアップロードした画像
     * @param {string} [fileName] ファイル名
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TeamApi
     */
    public async uploadTeamDescriptionImg(teamname: string, image?: File, fileName?: string, options?: AxiosRequestConfig) {
        const retry = 2;
        let resp;
        for (let i = 0; i < retry; i++) {
            resp = await TeamApiFp(this.configuration).uploadTeamDescriptionImg(teamname, image, fileName, options).then((request) => request(this.axios, this.basePath)).catch((error) => {
                if (error?.response) {
                    return error.response;
                } else {
                    return { status: 500 };
                }
            });
            if (resp?.status === 401) {
                await refreshIdToken();
                console.debug('Retry uploadTeamDescriptionImg ' + i + '/' + (retry - 1));
                continue;
            }
            break;
        }
        return resp;
    }
}


/**
 * TutorialApi - axios parameter creator
 * @export
 */
export const TutorialApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * チュートリアル一覧を取得します。
         * @summary チュートリアル一覧取得
         * @param {string} username ユーザ名
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTutorial: async (username: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'username' is not null or undefined
            assertParamExists('getTutorial', 'username', username)
            const localVarPath = `/tutorials/{username}`
                .replace(`{${"username"}}`, encodeURIComponent(String(username)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * チュートリアル実施状況を更新します。
         * @summary チュートリアル実施状況更新
         * @param {string} username ユーザ名
         * @param {TutorialRequest} tutorialRequest チュートリアル実施状況
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setTutorial: async (username: string, tutorialRequest: TutorialRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'username' is not null or undefined
            assertParamExists('setTutorial', 'username', username)
            // verify required parameter 'tutorialRequest' is not null or undefined
            assertParamExists('setTutorial', 'tutorialRequest', tutorialRequest)
            const localVarPath = `/tutorials/{username}`
                .replace(`{${"username"}}`, encodeURIComponent(String(username)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(tutorialRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * TutorialApi - functional programming interface
 * @export
 */
export const TutorialApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = TutorialApiAxiosParamCreator(configuration)
    return {
        /**
         * チュートリアル一覧を取得します。
         * @summary チュートリアル一覧取得
         * @param {string} username ユーザ名
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTutorial(username: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Tutorials>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTutorial(username, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * チュートリアル実施状況を更新します。
         * @summary チュートリアル実施状況更新
         * @param {string} username ユーザ名
         * @param {TutorialRequest} tutorialRequest チュートリアル実施状況
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async setTutorial(username: string, tutorialRequest: TutorialRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Tutorials>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.setTutorial(username, tutorialRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * TutorialApi - factory interface
 * @export
 */
export const TutorialApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = TutorialApiFp(configuration)
    return {
        /**
         * チュートリアル一覧を取得します。
         * @summary チュートリアル一覧取得
         * @param {string} username ユーザ名
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTutorial(username: string, options?: any): AxiosPromise<Tutorials> {
            return localVarFp.getTutorial(username, options).then((request) => request(axios, basePath));
        },
        /**
         * チュートリアル実施状況を更新します。
         * @summary チュートリアル実施状況更新
         * @param {string} username ユーザ名
         * @param {TutorialRequest} tutorialRequest チュートリアル実施状況
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setTutorial(username: string, tutorialRequest: TutorialRequest, options?: any): AxiosPromise<Tutorials> {
            return localVarFp.setTutorial(username, tutorialRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * TutorialApi - object-oriented interface
 * @export
 * @class TutorialApi
 * @extends {BaseAPI}
 */
export class TutorialApi extends BaseAPI {
    /**
     * チュートリアル一覧を取得します。
     * @summary チュートリアル一覧取得
     * @param {string} username ユーザ名
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TutorialApi
     */
    public async getTutorial(username: string, options?: AxiosRequestConfig) {
        const retry = 2;
        let resp;
        for (let i = 0; i < retry; i++) {
            resp = await TutorialApiFp(this.configuration).getTutorial(username, options).then((request) => request(this.axios, this.basePath)).catch((error) => {
                if (error?.response) {
                    return error.response;
                } else {
                    return { status: 500 };
                }
            });
            if (resp?.status === 401) {
                await refreshIdToken();
                console.debug('Retry getTutorial ' + i + '/' + (retry - 1));
                continue;
            }
            break;
        }
        return resp;
    }

    /**
     * チュートリアル実施状況を更新します。
     * @summary チュートリアル実施状況更新
     * @param {string} username ユーザ名
     * @param {TutorialRequest} tutorialRequest チュートリアル実施状況
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TutorialApi
     */
    public async setTutorial(username: string, tutorialRequest: TutorialRequest, options?: AxiosRequestConfig) {
        const retry = 2;
        let resp;
        for (let i = 0; i < retry; i++) {
            resp = await TutorialApiFp(this.configuration).setTutorial(username, tutorialRequest, options).then((request) => request(this.axios, this.basePath)).catch((error) => {
                if (error?.response) {
                    return error.response;
                } else {
                    return { status: 500 };
                }
            });
            if (resp?.status === 401) {
                await refreshIdToken();
                console.debug('Retry setTutorial ' + i + '/' + (retry - 1));
                continue;
            }
            break;
        }
        return resp;
    }
}


/**
 * UploadApi - axios parameter creator
 * @export
 */
export const UploadApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 自身のファイルをアップロードします。
         * @summary ユーザのファイルをアップロード
         * @param {string} username ユーザ名
         * @param {File} [userImage] ユーザがアップロードした画像
         * @param {string} [fileName] ファイル名
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadUsers: async (username: string, userImage?: File, fileName?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'username' is not null or undefined
            assertParamExists('uploadUsers', 'username', username)
            const localVarPath = `/uploads/users/{username}`
                .replace(`{${"username"}}`, encodeURIComponent(String(username)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


            if (userImage !== undefined) { 
                localVarFormParams.append('userImage', userImage as any);
            }
    
            if (fileName !== undefined) { 
                localVarFormParams.append('fileName', fileName as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UploadApi - functional programming interface
 * @export
 */
export const UploadApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = UploadApiAxiosParamCreator(configuration)
    return {
        /**
         * 自身のファイルをアップロードします。
         * @summary ユーザのファイルをアップロード
         * @param {string} username ユーザ名
         * @param {File} [userImage] ユーザがアップロードした画像
         * @param {string} [fileName] ファイル名
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async uploadUsers(username: string, userImage?: File, fileName?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UploadResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.uploadUsers(username, userImage, fileName, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * UploadApi - factory interface
 * @export
 */
export const UploadApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = UploadApiFp(configuration)
    return {
        /**
         * 自身のファイルをアップロードします。
         * @summary ユーザのファイルをアップロード
         * @param {string} username ユーザ名
         * @param {File} [userImage] ユーザがアップロードした画像
         * @param {string} [fileName] ファイル名
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadUsers(username: string, userImage?: File, fileName?: string, options?: any): AxiosPromise<UploadResponse> {
            return localVarFp.uploadUsers(username, userImage, fileName, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * UploadApi - object-oriented interface
 * @export
 * @class UploadApi
 * @extends {BaseAPI}
 */
export class UploadApi extends BaseAPI {
    /**
     * 自身のファイルをアップロードします。
     * @summary ユーザのファイルをアップロード
     * @param {string} username ユーザ名
     * @param {File} [userImage] ユーザがアップロードした画像
     * @param {string} [fileName] ファイル名
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UploadApi
     */
    public async uploadUsers(username: string, userImage?: File, fileName?: string, options?: AxiosRequestConfig) {
        const retry = 2;
        let resp;
        for (let i = 0; i < retry; i++) {
            resp = await UploadApiFp(this.configuration).uploadUsers(username, userImage, fileName, options).then((request) => request(this.axios, this.basePath)).catch((error) => {
                if (error?.response) {
                    return error.response;
                } else {
                    return { status: 500 };
                }
            });
            if (resp?.status === 401) {
                await refreshIdToken();
                console.debug('Retry uploadUsers ' + i + '/' + (retry - 1));
                continue;
            }
            break;
        }
        return resp;
    }
}


/**
 * UserApi - axios parameter creator
 * @export
 */
export const UserApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Googleカレンダリストを取得します。
         * @summary Googleカレンダリストの取得
         * @param {string} username ユーザ名
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findUserSettingsGcalList: async (username: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'username' is not null or undefined
            assertParamExists('findUserSettingsGcalList', 'username', username)
            const localVarPath = `/users/{username}/settings/gcallist`
                .replace(`{${"username"}}`, encodeURIComponent(String(username)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * ユーザーのインテグレーション設定を取得します。
         * @summary ユーザーのインテグレーション設定詳細の取得
         * @param {string} username ユーザ名
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findUserSettingsIntegrationDetails: async (username: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'username' is not null or undefined
            assertParamExists('findUserSettingsIntegrationDetails', 'username', username)
            const localVarPath = `/users/{username}/settings/integ_details`
                .replace(`{${"username"}}`, encodeURIComponent(String(username)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * ユーザーのインテグレーション設定を取得します。
         * @summary ユーザーのインテグレーション設定の取得
         * @param {string} username ユーザ名
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findUserSettingsIntegrations: async (username: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'username' is not null or undefined
            assertParamExists('findUserSettingsIntegrations', 'username', username)
            const localVarPath = `/users/{username}/settings/integrations`
                .replace(`{${"username"}}`, encodeURIComponent(String(username)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * ユーザ情報を取得します。
         * @summary ユーザ情報の取得
         * @param {string} username ユーザ名
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUser: async (username: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'username' is not null or undefined
            assertParamExists('getUser', 'username', username)
            const localVarPath = `/users/{username}`
                .replace(`{${"username"}}`, encodeURIComponent(String(username)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * ユーザーのインテグレーション設定を更新します。
         * @summary ユーザーのインテグレーション設定の更新
         * @param {string} username ユーザ名
         * @param {SettingsIntegrationDetailReq} settingsIntegrationDetailReq ユーザーのインテグレーション設定
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setUserSettingsIntegrationDetails: async (username: string, settingsIntegrationDetailReq: SettingsIntegrationDetailReq, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'username' is not null or undefined
            assertParamExists('setUserSettingsIntegrationDetails', 'username', username)
            // verify required parameter 'settingsIntegrationDetailReq' is not null or undefined
            assertParamExists('setUserSettingsIntegrationDetails', 'settingsIntegrationDetailReq', settingsIntegrationDetailReq)
            const localVarPath = `/users/{username}/settings/integ_details`
                .replace(`{${"username"}}`, encodeURIComponent(String(username)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(settingsIntegrationDetailReq, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * ユーザーのインテグレーション設定を更新します。
         * @summary ユーザーのインテグレーション設定の更新
         * @param {string} username ユーザ名
         * @param {UserSettingsIntegrationReq} userSettingsIntegrationReq ユーザーのインテグレーション設定
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setUserSettingsIntegrations: async (username: string, userSettingsIntegrationReq: UserSettingsIntegrationReq, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'username' is not null or undefined
            assertParamExists('setUserSettingsIntegrations', 'username', username)
            // verify required parameter 'userSettingsIntegrationReq' is not null or undefined
            assertParamExists('setUserSettingsIntegrations', 'userSettingsIntegrationReq', userSettingsIntegrationReq)
            const localVarPath = `/users/{username}/settings/integrations`
                .replace(`{${"username"}}`, encodeURIComponent(String(username)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(userSettingsIntegrationReq, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * ユーザ情報の更新をします。
         * @summary ユーザ情報の更新(未)
         * @param {string} username ユーザ名
         * @param {UserRequest} userRequest ユーザー情報
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUser: async (username: string, userRequest: UserRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'username' is not null or undefined
            assertParamExists('updateUser', 'username', username)
            // verify required parameter 'userRequest' is not null or undefined
            assertParamExists('updateUser', 'userRequest', userRequest)
            const localVarPath = `/users/{username}`
                .replace(`{${"username"}}`, encodeURIComponent(String(username)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(userRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UserApi - functional programming interface
 * @export
 */
export const UserApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = UserApiAxiosParamCreator(configuration)
    return {
        /**
         * Googleカレンダリストを取得します。
         * @summary Googleカレンダリストの取得
         * @param {string} username ユーザ名
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findUserSettingsGcalList(username: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProjectSettingGcallist>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findUserSettingsGcalList(username, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * ユーザーのインテグレーション設定を取得します。
         * @summary ユーザーのインテグレーション設定詳細の取得
         * @param {string} username ユーザ名
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findUserSettingsIntegrationDetails(username: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SettingsIntegrationDetail>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findUserSettingsIntegrationDetails(username, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * ユーザーのインテグレーション設定を取得します。
         * @summary ユーザーのインテグレーション設定の取得
         * @param {string} username ユーザ名
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findUserSettingsIntegrations(username: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserSettingsIntegration>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findUserSettingsIntegrations(username, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * ユーザ情報を取得します。
         * @summary ユーザ情報の取得
         * @param {string} username ユーザ名
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUser(username: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<User>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUser(username, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * ユーザーのインテグレーション設定を更新します。
         * @summary ユーザーのインテグレーション設定の更新
         * @param {string} username ユーザ名
         * @param {SettingsIntegrationDetailReq} settingsIntegrationDetailReq ユーザーのインテグレーション設定
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async setUserSettingsIntegrationDetails(username: string, settingsIntegrationDetailReq: SettingsIntegrationDetailReq, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SettingsIntegrationDetail>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.setUserSettingsIntegrationDetails(username, settingsIntegrationDetailReq, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * ユーザーのインテグレーション設定を更新します。
         * @summary ユーザーのインテグレーション設定の更新
         * @param {string} username ユーザ名
         * @param {UserSettingsIntegrationReq} userSettingsIntegrationReq ユーザーのインテグレーション設定
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async setUserSettingsIntegrations(username: string, userSettingsIntegrationReq: UserSettingsIntegrationReq, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserSettingsIntegration>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.setUserSettingsIntegrations(username, userSettingsIntegrationReq, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * ユーザ情報の更新をします。
         * @summary ユーザ情報の更新(未)
         * @param {string} username ユーザ名
         * @param {UserRequest} userRequest ユーザー情報
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateUser(username: string, userRequest: UserRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<User>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateUser(username, userRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * UserApi - factory interface
 * @export
 */
export const UserApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = UserApiFp(configuration)
    return {
        /**
         * Googleカレンダリストを取得します。
         * @summary Googleカレンダリストの取得
         * @param {string} username ユーザ名
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findUserSettingsGcalList(username: string, options?: any): AxiosPromise<ProjectSettingGcallist> {
            return localVarFp.findUserSettingsGcalList(username, options).then((request) => request(axios, basePath));
        },
        /**
         * ユーザーのインテグレーション設定を取得します。
         * @summary ユーザーのインテグレーション設定詳細の取得
         * @param {string} username ユーザ名
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findUserSettingsIntegrationDetails(username: string, options?: any): AxiosPromise<SettingsIntegrationDetail> {
            return localVarFp.findUserSettingsIntegrationDetails(username, options).then((request) => request(axios, basePath));
        },
        /**
         * ユーザーのインテグレーション設定を取得します。
         * @summary ユーザーのインテグレーション設定の取得
         * @param {string} username ユーザ名
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findUserSettingsIntegrations(username: string, options?: any): AxiosPromise<UserSettingsIntegration> {
            return localVarFp.findUserSettingsIntegrations(username, options).then((request) => request(axios, basePath));
        },
        /**
         * ユーザ情報を取得します。
         * @summary ユーザ情報の取得
         * @param {string} username ユーザ名
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUser(username: string, options?: any): AxiosPromise<User> {
            return localVarFp.getUser(username, options).then((request) => request(axios, basePath));
        },
        /**
         * ユーザーのインテグレーション設定を更新します。
         * @summary ユーザーのインテグレーション設定の更新
         * @param {string} username ユーザ名
         * @param {SettingsIntegrationDetailReq} settingsIntegrationDetailReq ユーザーのインテグレーション設定
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setUserSettingsIntegrationDetails(username: string, settingsIntegrationDetailReq: SettingsIntegrationDetailReq, options?: any): AxiosPromise<SettingsIntegrationDetail> {
            return localVarFp.setUserSettingsIntegrationDetails(username, settingsIntegrationDetailReq, options).then((request) => request(axios, basePath));
        },
        /**
         * ユーザーのインテグレーション設定を更新します。
         * @summary ユーザーのインテグレーション設定の更新
         * @param {string} username ユーザ名
         * @param {UserSettingsIntegrationReq} userSettingsIntegrationReq ユーザーのインテグレーション設定
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setUserSettingsIntegrations(username: string, userSettingsIntegrationReq: UserSettingsIntegrationReq, options?: any): AxiosPromise<UserSettingsIntegration> {
            return localVarFp.setUserSettingsIntegrations(username, userSettingsIntegrationReq, options).then((request) => request(axios, basePath));
        },
        /**
         * ユーザ情報の更新をします。
         * @summary ユーザ情報の更新(未)
         * @param {string} username ユーザ名
         * @param {UserRequest} userRequest ユーザー情報
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUser(username: string, userRequest: UserRequest, options?: any): AxiosPromise<User> {
            return localVarFp.updateUser(username, userRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * UserApi - object-oriented interface
 * @export
 * @class UserApi
 * @extends {BaseAPI}
 */
export class UserApi extends BaseAPI {
    /**
     * Googleカレンダリストを取得します。
     * @summary Googleカレンダリストの取得
     * @param {string} username ユーザ名
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public async findUserSettingsGcalList(username: string, options?: AxiosRequestConfig) {
        const retry = 2;
        let resp;
        for (let i = 0; i < retry; i++) {
            resp = await UserApiFp(this.configuration).findUserSettingsGcalList(username, options).then((request) => request(this.axios, this.basePath)).catch((error) => {
                if (error?.response) {
                    return error.response;
                } else {
                    return { status: 500 };
                }
            });
            if (resp?.status === 401) {
                await refreshIdToken();
                console.debug('Retry findUserSettingsGcalList ' + i + '/' + (retry - 1));
                continue;
            }
            break;
        }
        return resp;
    }

    /**
     * ユーザーのインテグレーション設定を取得します。
     * @summary ユーザーのインテグレーション設定詳細の取得
     * @param {string} username ユーザ名
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public async findUserSettingsIntegrationDetails(username: string, options?: AxiosRequestConfig) {
        const retry = 2;
        let resp;
        for (let i = 0; i < retry; i++) {
            resp = await UserApiFp(this.configuration).findUserSettingsIntegrationDetails(username, options).then((request) => request(this.axios, this.basePath)).catch((error) => {
                if (error?.response) {
                    return error.response;
                } else {
                    return { status: 500 };
                }
            });
            if (resp?.status === 401) {
                await refreshIdToken();
                console.debug('Retry findUserSettingsIntegrationDetails ' + i + '/' + (retry - 1));
                continue;
            }
            break;
        }
        return resp;
    }

    /**
     * ユーザーのインテグレーション設定を取得します。
     * @summary ユーザーのインテグレーション設定の取得
     * @param {string} username ユーザ名
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public async findUserSettingsIntegrations(username: string, options?: AxiosRequestConfig) {
        const retry = 2;
        let resp;
        for (let i = 0; i < retry; i++) {
            resp = await UserApiFp(this.configuration).findUserSettingsIntegrations(username, options).then((request) => request(this.axios, this.basePath)).catch((error) => {
                if (error?.response) {
                    return error.response;
                } else {
                    return { status: 500 };
                }
            });
            if (resp?.status === 401) {
                await refreshIdToken();
                console.debug('Retry findUserSettingsIntegrations ' + i + '/' + (retry - 1));
                continue;
            }
            break;
        }
        return resp;
    }

    /**
     * ユーザ情報を取得します。
     * @summary ユーザ情報の取得
     * @param {string} username ユーザ名
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public async getUser(username: string, options?: AxiosRequestConfig) {
        const retry = 2;
        let resp;
        for (let i = 0; i < retry; i++) {
            resp = await UserApiFp(this.configuration).getUser(username, options).then((request) => request(this.axios, this.basePath)).catch((error) => {
                if (error?.response) {
                    return error.response;
                } else {
                    return { status: 500 };
                }
            });
            if (resp?.status === 401) {
                await refreshIdToken();
                console.debug('Retry getUser ' + i + '/' + (retry - 1));
                continue;
            }
            break;
        }
        return resp;
    }

    /**
     * ユーザーのインテグレーション設定を更新します。
     * @summary ユーザーのインテグレーション設定の更新
     * @param {string} username ユーザ名
     * @param {SettingsIntegrationDetailReq} settingsIntegrationDetailReq ユーザーのインテグレーション設定
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public async setUserSettingsIntegrationDetails(username: string, settingsIntegrationDetailReq: SettingsIntegrationDetailReq, options?: AxiosRequestConfig) {
        const retry = 2;
        let resp;
        for (let i = 0; i < retry; i++) {
            resp = await UserApiFp(this.configuration).setUserSettingsIntegrationDetails(username, settingsIntegrationDetailReq, options).then((request) => request(this.axios, this.basePath)).catch((error) => {
                if (error?.response) {
                    return error.response;
                } else {
                    return { status: 500 };
                }
            });
            if (resp?.status === 401) {
                await refreshIdToken();
                console.debug('Retry setUserSettingsIntegrationDetails ' + i + '/' + (retry - 1));
                continue;
            }
            break;
        }
        return resp;
    }

    /**
     * ユーザーのインテグレーション設定を更新します。
     * @summary ユーザーのインテグレーション設定の更新
     * @param {string} username ユーザ名
     * @param {UserSettingsIntegrationReq} userSettingsIntegrationReq ユーザーのインテグレーション設定
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public async setUserSettingsIntegrations(username: string, userSettingsIntegrationReq: UserSettingsIntegrationReq, options?: AxiosRequestConfig) {
        const retry = 2;
        let resp;
        for (let i = 0; i < retry; i++) {
            resp = await UserApiFp(this.configuration).setUserSettingsIntegrations(username, userSettingsIntegrationReq, options).then((request) => request(this.axios, this.basePath)).catch((error) => {
                if (error?.response) {
                    return error.response;
                } else {
                    return { status: 500 };
                }
            });
            if (resp?.status === 401) {
                await refreshIdToken();
                console.debug('Retry setUserSettingsIntegrations ' + i + '/' + (retry - 1));
                continue;
            }
            break;
        }
        return resp;
    }

    /**
     * ユーザ情報の更新をします。
     * @summary ユーザ情報の更新(未)
     * @param {string} username ユーザ名
     * @param {UserRequest} userRequest ユーザー情報
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public async updateUser(username: string, userRequest: UserRequest, options?: AxiosRequestConfig) {
        const retry = 2;
        let resp;
        for (let i = 0; i < retry; i++) {
            resp = await UserApiFp(this.configuration).updateUser(username, userRequest, options).then((request) => request(this.axios, this.basePath)).catch((error) => {
                if (error?.response) {
                    return error.response;
                } else {
                    return { status: 500 };
                }
            });
            if (resp?.status === 401) {
                await refreshIdToken();
                console.debug('Retry updateUser ' + i + '/' + (retry - 1));
                continue;
            }
            break;
        }
        return resp;
    }
}


/**
 * WikiApi - axios parameter creator
 * @export
 */
export const WikiApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 自分自身のWikiを追加します。
         * @summary Wiki追加
         * @param {WikiRequest} wikiRequest Wiki情報
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addMyWiki: async (wikiRequest: WikiRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'wikiRequest' is not null or undefined
            assertParamExists('addMyWiki', 'wikiRequest', wikiRequest)
            const localVarPath = `/wikis`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(wikiRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * チームのWikiを追加します。
         * @summary Wiki追加
         * @param {string} teamname チーム名
         * @param {WikiRequest} wikiRequest Wiki情報
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addTeamWiki: async (teamname: string, wikiRequest: WikiRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'teamname' is not null or undefined
            assertParamExists('addTeamWiki', 'teamname', teamname)
            // verify required parameter 'wikiRequest' is not null or undefined
            assertParamExists('addTeamWiki', 'wikiRequest', wikiRequest)
            const localVarPath = `/wikis/teams/{teamname}`
                .replace(`{${"teamname"}}`, encodeURIComponent(String(teamname)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(wikiRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * マイナレッジを削除します。
         * @summary マイナレッジ削除
         * @param {number} wikiId wikiID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteMyWiki: async (wikiId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'wikiId' is not null or undefined
            assertParamExists('deleteMyWiki', 'wikiId', wikiId)
            const localVarPath = `/wikis/my/delete/{wiki_id}`
                .replace(`{${"wiki_id"}}`, encodeURIComponent(String(wikiId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 自分自身のwikiのリストを取得します。
         * @summary wikiリスト取得
         * @param {number} [page] ページ番号
         * @param {number} [perPage] 1ページあたりの要素数 (MAX100)
         * @param {string} [search] 検索キーワード
         * @param {number} [categoryId] カテゴリーID
         * @param {string} [sort] ソート条件
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findMyWikis: async (page?: number, perPage?: number, search?: string, categoryId?: number, sort?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/wikis`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (perPage !== undefined) {
                localVarQueryParameter['per_page'] = perPage;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (categoryId !== undefined) {
                localVarQueryParameter['category_id'] = categoryId;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * チームのwikiのリストを取得します。
         * @summary wikiリスト取得
         * @param {string} teamname チーム名
         * @param {number} [page] ページ番号
         * @param {number} [perPage] 1ページあたりの要素数 (MAX100)
         * @param {string} [search] 検索キーワード
         * @param {number} [categoryId] カテゴリーID
         * @param {string} [sort] ソート条件
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findTeamWikis: async (teamname: string, page?: number, perPage?: number, search?: string, categoryId?: number, sort?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'teamname' is not null or undefined
            assertParamExists('findTeamWikis', 'teamname', teamname)
            const localVarPath = `/wikis/teams/{teamname}`
                .replace(`{${"teamname"}}`, encodeURIComponent(String(teamname)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (perPage !== undefined) {
                localVarQueryParameter['per_page'] = perPage;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (categoryId !== undefined) {
                localVarQueryParameter['category_id'] = categoryId;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 自分自身のwikiを取得します。
         * @summary wikiの取得
         * @param {number} wikiId wikiID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMyWiki: async (wikiId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'wikiId' is not null or undefined
            assertParamExists('getMyWiki', 'wikiId', wikiId)
            const localVarPath = `/wikis/{wiki_id}`
                .replace(`{${"wiki_id"}}`, encodeURIComponent(String(wikiId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * チームのwikiを取得します。
         * @summary wikiの取得
         * @param {string} teamname チーム名
         * @param {number} wikiId wikiID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTeamWiki: async (teamname: string, wikiId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'teamname' is not null or undefined
            assertParamExists('getTeamWiki', 'teamname', teamname)
            // verify required parameter 'wikiId' is not null or undefined
            assertParamExists('getTeamWiki', 'wikiId', wikiId)
            const localVarPath = `/wikis/teams/{teamname}/{wiki_id}`
                .replace(`{${"teamname"}}`, encodeURIComponent(String(teamname)))
                .replace(`{${"wiki_id"}}`, encodeURIComponent(String(wikiId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 自分自身のwikiを更新します。
         * @summary wiki更新
         * @param {number} wikiId wikiID
         * @param {WikiRequest} wikiRequest wiki情報
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setMyWiki: async (wikiId: number, wikiRequest: WikiRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'wikiId' is not null or undefined
            assertParamExists('setMyWiki', 'wikiId', wikiId)
            // verify required parameter 'wikiRequest' is not null or undefined
            assertParamExists('setMyWiki', 'wikiRequest', wikiRequest)
            const localVarPath = `/wikis/{wiki_id}`
                .replace(`{${"wiki_id"}}`, encodeURIComponent(String(wikiId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(wikiRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * チームのwikiを更新します。
         * @summary wiki更新
         * @param {string} teamname チーム名
         * @param {number} wikiId wikiID
         * @param {WikiRequest} wikiRequest wiki情報
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setTeamWiki: async (teamname: string, wikiId: number, wikiRequest: WikiRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'teamname' is not null or undefined
            assertParamExists('setTeamWiki', 'teamname', teamname)
            // verify required parameter 'wikiId' is not null or undefined
            assertParamExists('setTeamWiki', 'wikiId', wikiId)
            // verify required parameter 'wikiRequest' is not null or undefined
            assertParamExists('setTeamWiki', 'wikiRequest', wikiRequest)
            const localVarPath = `/wikis/teams/{teamname}/{wiki_id}`
                .replace(`{${"teamname"}}`, encodeURIComponent(String(teamname)))
                .replace(`{${"wiki_id"}}`, encodeURIComponent(String(wikiId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(wikiRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 指定したwikiへの【いいね】状態を更新します。
         * @summary チームWikiへの【いいね】の更新
         * @param {string} teamname チーム名
         * @param {number} wikiId wikiID
         * @param {WikiLike} wikiLike wiki情報
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setWikiTeamLike: async (teamname: string, wikiId: number, wikiLike: WikiLike, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'teamname' is not null or undefined
            assertParamExists('setWikiTeamLike', 'teamname', teamname)
            // verify required parameter 'wikiId' is not null or undefined
            assertParamExists('setWikiTeamLike', 'wikiId', wikiId)
            // verify required parameter 'wikiLike' is not null or undefined
            assertParamExists('setWikiTeamLike', 'wikiLike', wikiLike)
            const localVarPath = `/wikis/teams/{teamname}/{wiki_id}/like`
                .replace(`{${"teamname"}}`, encodeURIComponent(String(teamname)))
                .replace(`{${"wiki_id"}}`, encodeURIComponent(String(wikiId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(wikiLike, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * WikiApi - functional programming interface
 * @export
 */
export const WikiApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = WikiApiAxiosParamCreator(configuration)
    return {
        /**
         * 自分自身のWikiを追加します。
         * @summary Wiki追加
         * @param {WikiRequest} wikiRequest Wiki情報
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addMyWiki(wikiRequest: WikiRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Wiki>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addMyWiki(wikiRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * チームのWikiを追加します。
         * @summary Wiki追加
         * @param {string} teamname チーム名
         * @param {WikiRequest} wikiRequest Wiki情報
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addTeamWiki(teamname: string, wikiRequest: WikiRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Wiki>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addTeamWiki(teamname, wikiRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * マイナレッジを削除します。
         * @summary マイナレッジ削除
         * @param {number} wikiId wikiID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteMyWiki(wikiId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteMyWiki(wikiId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 自分自身のwikiのリストを取得します。
         * @summary wikiリスト取得
         * @param {number} [page] ページ番号
         * @param {number} [perPage] 1ページあたりの要素数 (MAX100)
         * @param {string} [search] 検索キーワード
         * @param {number} [categoryId] カテゴリーID
         * @param {string} [sort] ソート条件
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findMyWikis(page?: number, perPage?: number, search?: string, categoryId?: number, sort?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Wikis>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findMyWikis(page, perPage, search, categoryId, sort, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * チームのwikiのリストを取得します。
         * @summary wikiリスト取得
         * @param {string} teamname チーム名
         * @param {number} [page] ページ番号
         * @param {number} [perPage] 1ページあたりの要素数 (MAX100)
         * @param {string} [search] 検索キーワード
         * @param {number} [categoryId] カテゴリーID
         * @param {string} [sort] ソート条件
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findTeamWikis(teamname: string, page?: number, perPage?: number, search?: string, categoryId?: number, sort?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Wikis>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findTeamWikis(teamname, page, perPage, search, categoryId, sort, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 自分自身のwikiを取得します。
         * @summary wikiの取得
         * @param {number} wikiId wikiID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMyWiki(wikiId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Wiki>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getMyWiki(wikiId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * チームのwikiを取得します。
         * @summary wikiの取得
         * @param {string} teamname チーム名
         * @param {number} wikiId wikiID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTeamWiki(teamname: string, wikiId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Wiki>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTeamWiki(teamname, wikiId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 自分自身のwikiを更新します。
         * @summary wiki更新
         * @param {number} wikiId wikiID
         * @param {WikiRequest} wikiRequest wiki情報
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async setMyWiki(wikiId: number, wikiRequest: WikiRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Wiki>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.setMyWiki(wikiId, wikiRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * チームのwikiを更新します。
         * @summary wiki更新
         * @param {string} teamname チーム名
         * @param {number} wikiId wikiID
         * @param {WikiRequest} wikiRequest wiki情報
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async setTeamWiki(teamname: string, wikiId: number, wikiRequest: WikiRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Wiki>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.setTeamWiki(teamname, wikiId, wikiRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 指定したwikiへの【いいね】状態を更新します。
         * @summary チームWikiへの【いいね】の更新
         * @param {string} teamname チーム名
         * @param {number} wikiId wikiID
         * @param {WikiLike} wikiLike wiki情報
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async setWikiTeamLike(teamname: string, wikiId: number, wikiLike: WikiLike, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.setWikiTeamLike(teamname, wikiId, wikiLike, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * WikiApi - factory interface
 * @export
 */
export const WikiApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = WikiApiFp(configuration)
    return {
        /**
         * 自分自身のWikiを追加します。
         * @summary Wiki追加
         * @param {WikiRequest} wikiRequest Wiki情報
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addMyWiki(wikiRequest: WikiRequest, options?: any): AxiosPromise<Wiki> {
            return localVarFp.addMyWiki(wikiRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * チームのWikiを追加します。
         * @summary Wiki追加
         * @param {string} teamname チーム名
         * @param {WikiRequest} wikiRequest Wiki情報
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addTeamWiki(teamname: string, wikiRequest: WikiRequest, options?: any): AxiosPromise<Wiki> {
            return localVarFp.addTeamWiki(teamname, wikiRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * マイナレッジを削除します。
         * @summary マイナレッジ削除
         * @param {number} wikiId wikiID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteMyWiki(wikiId: number, options?: any): AxiosPromise<void> {
            return localVarFp.deleteMyWiki(wikiId, options).then((request) => request(axios, basePath));
        },
        /**
         * 自分自身のwikiのリストを取得します。
         * @summary wikiリスト取得
         * @param {number} [page] ページ番号
         * @param {number} [perPage] 1ページあたりの要素数 (MAX100)
         * @param {string} [search] 検索キーワード
         * @param {number} [categoryId] カテゴリーID
         * @param {string} [sort] ソート条件
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findMyWikis(page?: number, perPage?: number, search?: string, categoryId?: number, sort?: string, options?: any): AxiosPromise<Wikis> {
            return localVarFp.findMyWikis(page, perPage, search, categoryId, sort, options).then((request) => request(axios, basePath));
        },
        /**
         * チームのwikiのリストを取得します。
         * @summary wikiリスト取得
         * @param {string} teamname チーム名
         * @param {number} [page] ページ番号
         * @param {number} [perPage] 1ページあたりの要素数 (MAX100)
         * @param {string} [search] 検索キーワード
         * @param {number} [categoryId] カテゴリーID
         * @param {string} [sort] ソート条件
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findTeamWikis(teamname: string, page?: number, perPage?: number, search?: string, categoryId?: number, sort?: string, options?: any): AxiosPromise<Wikis> {
            return localVarFp.findTeamWikis(teamname, page, perPage, search, categoryId, sort, options).then((request) => request(axios, basePath));
        },
        /**
         * 自分自身のwikiを取得します。
         * @summary wikiの取得
         * @param {number} wikiId wikiID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMyWiki(wikiId: number, options?: any): AxiosPromise<Wiki> {
            return localVarFp.getMyWiki(wikiId, options).then((request) => request(axios, basePath));
        },
        /**
         * チームのwikiを取得します。
         * @summary wikiの取得
         * @param {string} teamname チーム名
         * @param {number} wikiId wikiID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTeamWiki(teamname: string, wikiId: number, options?: any): AxiosPromise<Wiki> {
            return localVarFp.getTeamWiki(teamname, wikiId, options).then((request) => request(axios, basePath));
        },
        /**
         * 自分自身のwikiを更新します。
         * @summary wiki更新
         * @param {number} wikiId wikiID
         * @param {WikiRequest} wikiRequest wiki情報
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setMyWiki(wikiId: number, wikiRequest: WikiRequest, options?: any): AxiosPromise<Wiki> {
            return localVarFp.setMyWiki(wikiId, wikiRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * チームのwikiを更新します。
         * @summary wiki更新
         * @param {string} teamname チーム名
         * @param {number} wikiId wikiID
         * @param {WikiRequest} wikiRequest wiki情報
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setTeamWiki(teamname: string, wikiId: number, wikiRequest: WikiRequest, options?: any): AxiosPromise<Wiki> {
            return localVarFp.setTeamWiki(teamname, wikiId, wikiRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 指定したwikiへの【いいね】状態を更新します。
         * @summary チームWikiへの【いいね】の更新
         * @param {string} teamname チーム名
         * @param {number} wikiId wikiID
         * @param {WikiLike} wikiLike wiki情報
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setWikiTeamLike(teamname: string, wikiId: number, wikiLike: WikiLike, options?: any): AxiosPromise<void> {
            return localVarFp.setWikiTeamLike(teamname, wikiId, wikiLike, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * WikiApi - object-oriented interface
 * @export
 * @class WikiApi
 * @extends {BaseAPI}
 */
export class WikiApi extends BaseAPI {
    /**
     * 自分自身のWikiを追加します。
     * @summary Wiki追加
     * @param {WikiRequest} wikiRequest Wiki情報
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WikiApi
     */
    public async addMyWiki(wikiRequest: WikiRequest, options?: AxiosRequestConfig) {
        const retry = 2;
        let resp;
        for (let i = 0; i < retry; i++) {
            resp = await WikiApiFp(this.configuration).addMyWiki(wikiRequest, options).then((request) => request(this.axios, this.basePath)).catch((error) => {
                if (error?.response) {
                    return error.response;
                } else {
                    return { status: 500 };
                }
            });
            if (resp?.status === 401) {
                await refreshIdToken();
                console.debug('Retry addMyWiki ' + i + '/' + (retry - 1));
                continue;
            }
            break;
        }
        return resp;
    }

    /**
     * チームのWikiを追加します。
     * @summary Wiki追加
     * @param {string} teamname チーム名
     * @param {WikiRequest} wikiRequest Wiki情報
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WikiApi
     */
    public async addTeamWiki(teamname: string, wikiRequest: WikiRequest, options?: AxiosRequestConfig) {
        const retry = 2;
        let resp;
        for (let i = 0; i < retry; i++) {
            resp = await WikiApiFp(this.configuration).addTeamWiki(teamname, wikiRequest, options).then((request) => request(this.axios, this.basePath)).catch((error) => {
                if (error?.response) {
                    return error.response;
                } else {
                    return { status: 500 };
                }
            });
            if (resp?.status === 401) {
                await refreshIdToken();
                console.debug('Retry addTeamWiki ' + i + '/' + (retry - 1));
                continue;
            }
            break;
        }
        return resp;
    }

    /**
     * マイナレッジを削除します。
     * @summary マイナレッジ削除
     * @param {number} wikiId wikiID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WikiApi
     */
    public async deleteMyWiki(wikiId: number, options?: AxiosRequestConfig) {
        const retry = 2;
        let resp;
        for (let i = 0; i < retry; i++) {
            resp = await WikiApiFp(this.configuration).deleteMyWiki(wikiId, options).then((request) => request(this.axios, this.basePath)).catch((error) => {
                if (error?.response) {
                    return error.response;
                } else {
                    return { status: 500 };
                }
            });
            if (resp?.status === 401) {
                await refreshIdToken();
                console.debug('Retry deleteMyWiki ' + i + '/' + (retry - 1));
                continue;
            }
            break;
        }
        return resp;
    }

    /**
     * 自分自身のwikiのリストを取得します。
     * @summary wikiリスト取得
     * @param {number} [page] ページ番号
     * @param {number} [perPage] 1ページあたりの要素数 (MAX100)
     * @param {string} [search] 検索キーワード
     * @param {number} [categoryId] カテゴリーID
     * @param {string} [sort] ソート条件
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WikiApi
     */
    public async findMyWikis(page?: number, perPage?: number, search?: string, categoryId?: number, sort?: string, options?: AxiosRequestConfig) {
        const retry = 2;
        let resp;
        for (let i = 0; i < retry; i++) {
            resp = await WikiApiFp(this.configuration).findMyWikis(page, perPage, search, categoryId, sort, options).then((request) => request(this.axios, this.basePath)).catch((error) => {
                if (error?.response) {
                    return error.response;
                } else {
                    return { status: 500 };
                }
            });
            if (resp?.status === 401) {
                await refreshIdToken();
                console.debug('Retry findMyWikis ' + i + '/' + (retry - 1));
                continue;
            }
            break;
        }
        return resp;
    }

    /**
     * チームのwikiのリストを取得します。
     * @summary wikiリスト取得
     * @param {string} teamname チーム名
     * @param {number} [page] ページ番号
     * @param {number} [perPage] 1ページあたりの要素数 (MAX100)
     * @param {string} [search] 検索キーワード
     * @param {number} [categoryId] カテゴリーID
     * @param {string} [sort] ソート条件
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WikiApi
     */
    public async findTeamWikis(teamname: string, page?: number, perPage?: number, search?: string, categoryId?: number, sort?: string, options?: AxiosRequestConfig) {
        const retry = 2;
        let resp;
        for (let i = 0; i < retry; i++) {
            resp = await WikiApiFp(this.configuration).findTeamWikis(teamname, page, perPage, search, categoryId, sort, options).then((request) => request(this.axios, this.basePath)).catch((error) => {
                if (error?.response) {
                    return error.response;
                } else {
                    return { status: 500 };
                }
            });
            if (resp?.status === 401) {
                await refreshIdToken();
                console.debug('Retry findTeamWikis ' + i + '/' + (retry - 1));
                continue;
            }
            break;
        }
        return resp;
    }

    /**
     * 自分自身のwikiを取得します。
     * @summary wikiの取得
     * @param {number} wikiId wikiID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WikiApi
     */
    public async getMyWiki(wikiId: number, options?: AxiosRequestConfig) {
        const retry = 2;
        let resp;
        for (let i = 0; i < retry; i++) {
            resp = await WikiApiFp(this.configuration).getMyWiki(wikiId, options).then((request) => request(this.axios, this.basePath)).catch((error) => {
                if (error?.response) {
                    return error.response;
                } else {
                    return { status: 500 };
                }
            });
            if (resp?.status === 401) {
                await refreshIdToken();
                console.debug('Retry getMyWiki ' + i + '/' + (retry - 1));
                continue;
            }
            break;
        }
        return resp;
    }

    /**
     * チームのwikiを取得します。
     * @summary wikiの取得
     * @param {string} teamname チーム名
     * @param {number} wikiId wikiID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WikiApi
     */
    public async getTeamWiki(teamname: string, wikiId: number, options?: AxiosRequestConfig) {
        const retry = 2;
        let resp;
        for (let i = 0; i < retry; i++) {
            resp = await WikiApiFp(this.configuration).getTeamWiki(teamname, wikiId, options).then((request) => request(this.axios, this.basePath)).catch((error) => {
                if (error?.response) {
                    return error.response;
                } else {
                    return { status: 500 };
                }
            });
            if (resp?.status === 401) {
                await refreshIdToken();
                console.debug('Retry getTeamWiki ' + i + '/' + (retry - 1));
                continue;
            }
            break;
        }
        return resp;
    }

    /**
     * 自分自身のwikiを更新します。
     * @summary wiki更新
     * @param {number} wikiId wikiID
     * @param {WikiRequest} wikiRequest wiki情報
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WikiApi
     */
    public async setMyWiki(wikiId: number, wikiRequest: WikiRequest, options?: AxiosRequestConfig) {
        const retry = 2;
        let resp;
        for (let i = 0; i < retry; i++) {
            resp = await WikiApiFp(this.configuration).setMyWiki(wikiId, wikiRequest, options).then((request) => request(this.axios, this.basePath)).catch((error) => {
                if (error?.response) {
                    return error.response;
                } else {
                    return { status: 500 };
                }
            });
            if (resp?.status === 401) {
                await refreshIdToken();
                console.debug('Retry setMyWiki ' + i + '/' + (retry - 1));
                continue;
            }
            break;
        }
        return resp;
    }

    /**
     * チームのwikiを更新します。
     * @summary wiki更新
     * @param {string} teamname チーム名
     * @param {number} wikiId wikiID
     * @param {WikiRequest} wikiRequest wiki情報
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WikiApi
     */
    public async setTeamWiki(teamname: string, wikiId: number, wikiRequest: WikiRequest, options?: AxiosRequestConfig) {
        const retry = 2;
        let resp;
        for (let i = 0; i < retry; i++) {
            resp = await WikiApiFp(this.configuration).setTeamWiki(teamname, wikiId, wikiRequest, options).then((request) => request(this.axios, this.basePath)).catch((error) => {
                if (error?.response) {
                    return error.response;
                } else {
                    return { status: 500 };
                }
            });
            if (resp?.status === 401) {
                await refreshIdToken();
                console.debug('Retry setTeamWiki ' + i + '/' + (retry - 1));
                continue;
            }
            break;
        }
        return resp;
    }

    /**
     * 指定したwikiへの【いいね】状態を更新します。
     * @summary チームWikiへの【いいね】の更新
     * @param {string} teamname チーム名
     * @param {number} wikiId wikiID
     * @param {WikiLike} wikiLike wiki情報
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WikiApi
     */
    public async setWikiTeamLike(teamname: string, wikiId: number, wikiLike: WikiLike, options?: AxiosRequestConfig) {
        const retry = 2;
        let resp;
        for (let i = 0; i < retry; i++) {
            resp = await WikiApiFp(this.configuration).setWikiTeamLike(teamname, wikiId, wikiLike, options).then((request) => request(this.axios, this.basePath)).catch((error) => {
                if (error?.response) {
                    return error.response;
                } else {
                    return { status: 500 };
                }
            });
            if (resp?.status === 401) {
                await refreshIdToken();
                console.debug('Retry setWikiTeamLike ' + i + '/' + (retry - 1));
                continue;
            }
            break;
        }
        return resp;
    }
}


