export const getDefaultStateResource = () => {
  return {
    coinOperationValues: {
      myTaskCreated: 0,
      myKnowledgeCreated: 0,
      teamTaskCreated: 0,
      teamKnowledgeCreated: 0,
      teamQuestCreated: 0,
      myTaskDeleted: 0,
      myKnowledgeDeleted: 0,
      teamTaskDeleted: 0,
      teamKnowledgeDeleted: 0,
      teamQuestDeleted: 0,
    },
  };
};

export default {
  namespaced: true,
  state: getDefaultStateResource(),
  mutations: {
    drop(state) {
      state.coinOperationValues = {
        myTaskCreated: 0,
        myKnowledgeCreated: 0,
        teamTaskCreated: 0,
        teamKnowledgeCreated: 0,
        teamQuestCreated: 0,
        myTaskDeleted: 0,
        myKnowledgeDeleted: 0,
        teamTaskDeleted: 0,
        teamKnowledgeDeleted: 0,
        teamQuestDeleted: 0,
      };
    },
    store(state, data) {
      state.coinOperationValues.myTaskCreated = data.my_task_created;
      state.coinOperationValues.myKnowledgeCreated = data.my_knowledge_created;
      state.coinOperationValues.teamTaskCreated = data.team_task_created;
      state.coinOperationValues.teamKnowledgeCreated = data.team_knowledge_created;
      state.coinOperationValues.teamQuestCreated = data.team_quest_created;
      state.coinOperationValues.myTaskDeleted = data.my_task_deleted;
      state.coinOperationValues.myKnowledgeDeleted = data.my_knowledge_deleted;
      state.coinOperationValues.teamTaskDeleted = data.team_task_deleted;
      state.coinOperationValues.teamKnowledgeDeleted = data.team_knowledge_deleted;
      state.coinOperationValues.teamQuestDeleted = data.team_quest_deleted;
    },
  },
  actions: {
    drop(context) {
      context.commit("drop");
    },
    store(context, data) {
      context.commit("store", data);
    },
  },
};
