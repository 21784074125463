import Vue from "vue";
import VueCompositionApi from "@vue/composition-api";
import VueMeta from "vue-meta";
import { BootstrapVue, IconsPlugin } from "bootstrap-vue";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue/dist/bootstrap-vue.css";
import "@/assets/css/timeline.css";
import * as Sentry from "@sentry/vue";
import { Integrations } from "@sentry/tracing";
import VTooltip from "v-tooltip";
import VueGtm from "@gtm-support/vue2-gtm";

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import VCalendar from "v-calendar";
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import VueEllipseProgress from "vue-ellipse-progress";
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import VoerroTagsInput from "@voerro/vue-tagsinput";
import VueSocialSharing from "vue-social-sharing";

import { library } from "@fortawesome/fontawesome-svg-core";
import { faAward as fasAward } from "@fortawesome/free-solid-svg-icons";
import { faCopy as fasCopy } from "@fortawesome/free-solid-svg-icons";
import { faCopy as farCopy } from "@fortawesome/free-regular-svg-icons";
import { faFileLines as farFileLines } from "@fortawesome/free-regular-svg-icons";
import { faEdit as fasEdit } from "@fortawesome/free-solid-svg-icons";
import { faEdit as farEdit } from "@fortawesome/free-regular-svg-icons";
import { faThumbtack as fasThumbtack } from "@fortawesome/free-solid-svg-icons";
import { faCog as fasCog } from "@fortawesome/free-solid-svg-icons";
import { faTrash as fasTrash } from "@fortawesome/free-solid-svg-icons";
import { faPlusCircle as fasPlusCircle } from "@fortawesome/free-solid-svg-icons";
import { faMinusCircle as fasMinusCircle } from "@fortawesome/free-solid-svg-icons";
import { faPlus as fasPlus } from "@fortawesome/free-solid-svg-icons";
import { faImages as fasImages } from "@fortawesome/free-solid-svg-icons";
import { faUser as fasUser } from "@fortawesome/free-solid-svg-icons";
import { faUsers as fasUsers } from "@fortawesome/free-solid-svg-icons";
import { faAddressCard as fasAddressCard } from "@fortawesome/free-solid-svg-icons";
import { faEnvelope as fasEnvelope } from "@fortawesome/free-solid-svg-icons";
import { faBug as fasBug } from "@fortawesome/free-solid-svg-icons";
import { faBook as fasBook } from "@fortawesome/free-solid-svg-icons";
import { faExternalLinkAlt as fasExternalLinkAlt } from "@fortawesome/free-solid-svg-icons";
import { faTasks as fasTasks } from "@fortawesome/free-solid-svg-icons";
import { faNewspaper as fasNewspaper } from "@fortawesome/free-solid-svg-icons";
import { faRedoAlt as fasRedoAlt } from "@fortawesome/free-solid-svg-icons";
import { faHistory as fasHistory } from "@fortawesome/free-solid-svg-icons";
import { faMedal as fasMedal } from "@fortawesome/free-solid-svg-icons";
import { faGem as fasGem } from "@fortawesome/free-solid-svg-icons";
import { faGem as farGem } from "@fortawesome/free-regular-svg-icons";
import { faCoins as fasCoins } from "@fortawesome/free-solid-svg-icons";
import { faStore as fasStore } from "@fortawesome/free-solid-svg-icons";
import { faUserAstronaut as fasUserAstronaut } from "@fortawesome/free-solid-svg-icons";
import { faShoppingBag as fasShoppingBag } from "@fortawesome/free-solid-svg-icons";
import { faChartLine as fasChartLine } from "@fortawesome/free-solid-svg-icons";
import { faCertificate as fasCertificate } from "@fortawesome/free-solid-svg-icons";
import { faGlobeAsia as fasGlobeAsia } from "@fortawesome/free-solid-svg-icons";
import { faTags as fasTags } from "@fortawesome/free-solid-svg-icons";
import { faChartBar as fasChartBar } from "@fortawesome/free-solid-svg-icons";
import { faBold as fasBold } from "@fortawesome/free-solid-svg-icons";
import { faItalic as fasItalic } from "@fortawesome/free-solid-svg-icons";
import { faProjectDiagram as fasProjectDiagram } from "@fortawesome/free-solid-svg-icons";
import { faCheckCircle as fasCheckCircle } from "@fortawesome/free-solid-svg-icons";
import { faTimesCircle as fasTimesCircle } from "@fortawesome/free-solid-svg-icons";
// import { faTwitterSquare as fasTwitterSquare} from "@fortawesome/free-solid-svg-icons";
import { faFlag as fasFlag } from "@fortawesome/free-solid-svg-icons";
import { faTable as fasTable } from "@fortawesome/free-solid-svg-icons";
import { faUserCircle as fasUserCircle } from "@fortawesome/free-solid-svg-icons";
import { faBars as fasBars } from "@fortawesome/free-solid-svg-icons";
import { faSeedling as fasSeedling } from "@fortawesome/free-solid-svg-icons";
import { faLandmark as fasLandmark } from "@fortawesome/free-solid-svg-icons";
import { faHandPointer as fasHandPointer } from "@fortawesome/free-solid-svg-icons";
import { faList as faList } from "@fortawesome/free-solid-svg-icons";
import { faArrowRight as fasArrowRight } from "@fortawesome/free-solid-svg-icons";
import { faArrowLeft as fasArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { faTwitter as fabTwitter } from "@fortawesome/free-brands-svg-icons";
import { faFacebook as fabFacebook } from "@fortawesome/free-brands-svg-icons";
import { faLine as fabLine } from "@fortawesome/free-brands-svg-icons";
import { faApple as fasApple } from "@fortawesome/free-brands-svg-icons";
import { faMountain as fasMountain } from "@fortawesome/free-solid-svg-icons";
import { faBullseye as faBullseye } from "@fortawesome/free-solid-svg-icons";
import { faGear as faGear } from "@fortawesome/free-solid-svg-icons";
import { faClock as faClock } from "@fortawesome/free-solid-svg-icons";
import { faCalendar as fasCalendar } from "@fortawesome/free-solid-svg-icons";
import { faCalendarDay as faCalendarDay } from "@fortawesome/free-solid-svg-icons";
import { faCircleCheck as fasCircleCheck } from "@fortawesome/free-solid-svg-icons";
import { faPen as faPen } from "@fortawesome/free-solid-svg-icons";
import { faRocket as fasRocket } from "@fortawesome/free-solid-svg-icons";
import { faHandFist as fasHandFist } from "@fortawesome/free-solid-svg-icons";
import { faPassport as fasPassport } from "@fortawesome/free-solid-svg-icons";
import { faCircleQuestion as fasCircleQuestion } from "@fortawesome/free-solid-svg-icons";
import { faPalette as fasPalette } from "@fortawesome/free-solid-svg-icons";
import { faThumbsUp as fasThumbsUp } from "@fortawesome/free-solid-svg-icons";
import { faStar as fasStar } from "@fortawesome/free-solid-svg-icons";
import { faChessKing as fasChessKing } from "@fortawesome/free-solid-svg-icons";
import { faArrowTrendUp as fasArrowTrendUp } from "@fortawesome/free-solid-svg-icons";
import { faPanorama as faPanorama } from "@fortawesome/free-solid-svg-icons";
import { faTrophy as fasTrophy } from "@fortawesome/free-solid-svg-icons";
import { faShare as fasShare } from "@fortawesome/free-solid-svg-icons";

import { faChevronLeft as fasChevronLeft } from "@fortawesome/free-solid-svg-icons";
import { faChevronRight as fasChevronRight } from "@fortawesome/free-solid-svg-icons";
import { faChevronUp as fasChevronUp } from "@fortawesome/free-solid-svg-icons";
import { faChevronDown as fasChevronDown } from "@fortawesome/free-solid-svg-icons";

import { faCircleInfo as fasCircleInfo } from "@fortawesome/free-solid-svg-icons";
import { faClipboardList as fasClipboardList } from "@fortawesome/free-solid-svg-icons";
import { faGripVertical as fasGripVertical } from "@fortawesome/free-solid-svg-icons";
import { faStopwatch as fasStopwatch } from "@fortawesome/free-solid-svg-icons";
import { faXmark as fasXmark } from "@fortawesome/free-solid-svg-icons";
import { faCaretDown as fasCaretDown } from "@fortawesome/free-solid-svg-icons";
import { faCaretUp as fasCaretUp } from "@fortawesome/free-solid-svg-icons";
import { faEllipsis as fasEllipsis } from "@fortawesome/free-solid-svg-icons";
import { faArrowUpFromBracket as fasArrowUpFromBracket } from "@fortawesome/free-solid-svg-icons";
import { faArrowDown as fasArrowDown } from "@fortawesome/free-solid-svg-icons";
import { faFileArrowDown as fasFileArrowDown } from "@fortawesome/free-solid-svg-icons";
import { faImage as fasImage } from "@fortawesome/free-solid-svg-icons";
import { faCheck as fasCheck } from "@fortawesome/free-solid-svg-icons";
import { faTag as fasTag } from "@fortawesome/free-solid-svg-icons";
import { faFilter as fasFilter } from "@fortawesome/free-solid-svg-icons";
import { faComment as fasComment } from "@fortawesome/free-solid-svg-icons";
import { faQuoteLeft as fasQuoteLeft } from "@fortawesome/free-solid-svg-icons";
import { faRss as fasRss } from "@fortawesome/free-solid-svg-icons";
import { faEye as fasEye } from "@fortawesome/free-solid-svg-icons";
import { faHatWizard as fasHatWizard } from "@fortawesome/free-solid-svg-icons";
import { faWandSparkles as fasWandSparkles } from "@fortawesome/free-solid-svg-icons";
import { faPeopleRoof as fasPeopleRoof } from "@fortawesome/free-solid-svg-icons";
import { faCalendarDay as fasCalendarDay } from "@fortawesome/free-solid-svg-icons";
import { faHouse as fasHouse } from "@fortawesome/free-solid-svg-icons";
import { faGamepad as fasGamepad } from "@fortawesome/free-solid-svg-icons";
import { faBolt as fasBolt } from "@fortawesome/free-solid-svg-icons";
import { faHeading as fasHeading } from "@fortawesome/free-solid-svg-icons";
import { faListOl as fasListOl } from "@fortawesome/free-solid-svg-icons";
import { faUnderline as fasUnderline } from "@fortawesome/free-solid-svg-icons";
import { faStrikethrough as fasStrikethrough } from "@fortawesome/free-solid-svg-icons";
import { faCode as fasCode } from "@fortawesome/free-solid-svg-icons";
import { faQuoteRight as fasQuoteRight } from "@fortawesome/free-solid-svg-icons";
import { faUndo as fasUndo } from "@fortawesome/free-solid-svg-icons";
import { faRedo as fasRedo } from "@fortawesome/free-solid-svg-icons";
import { faHighlighter as fasHighlighter } from "@fortawesome/free-solid-svg-icons";
import { faFilm as fasFilm } from "@fortawesome/free-solid-svg-icons";
import { faLink as fasLink } from "@fortawesome/free-solid-svg-icons";
import { faPlay as fasPlay } from "@fortawesome/free-solid-svg-icons";
import { faSquareUpRight as fasSquareUpRight } from "@fortawesome/free-solid-svg-icons";
import { faCaretRight as fasCaretRight } from "@fortawesome/free-solid-svg-icons";
import { faWandMagic as fasWandMagic } from "@fortawesome/free-solid-svg-icons";
import { faCalendarCheck as fasCalendarCheck } from "@fortawesome/free-solid-svg-icons";
import { faSuitcase as fasSuitcase } from "@fortawesome/free-solid-svg-icons";
import { faSquareArrowUpRight as fasSquareArrowUpRight } from "@fortawesome/free-solid-svg-icons";
import { faIndustry as fasIndustry } from "@fortawesome/free-solid-svg-icons";
import { faSlash as fasSlash } from "@fortawesome/free-solid-svg-icons";
import { faMinus as fasMinus } from "@fortawesome/free-solid-svg-icons";
import { faUpRightAndDownLeftFromCenter as fasUpRightAndDownLeftFromCenter } from "@fortawesome/free-solid-svg-icons";
import { faLocationDot as fasLocationDot } from "@fortawesome/free-solid-svg-icons";
import { faUserNinja as fasUserNinja } from "@fortawesome/free-solid-svg-icons";
import { faFutbol as fasFutbol } from "@fortawesome/free-solid-svg-icons";
import { faPersonRunning as fasPersonRunning } from "@fortawesome/free-solid-svg-icons";
import { faGun as fasGun } from "@fortawesome/free-solid-svg-icons";
import { faMeteor as fasMeteor } from "@fortawesome/free-solid-svg-icons";

import { faRightLong as fasRightLong } from "@fortawesome/free-solid-svg-icons";
import { faLeftLong as fasLeftLong } from "@fortawesome/free-solid-svg-icons";
import { faDownLong as fasDownLong } from "@fortawesome/free-solid-svg-icons";
import { faUpLong as fasUpLong } from "@fortawesome/free-solid-svg-icons";

import { faForward as fasForward } from "@fortawesome/free-solid-svg-icons";

import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
library.add(
  fasAward,
  fasCopy,
  farCopy,
  farFileLines,
  fasEdit,
  farEdit,
  fasThumbtack,
  fasNewspaper,
  fasCog,
  fasTrash,
  fasPlusCircle,
  fasMinusCircle,
  fasPlus,
  fasMinus,
  fasImages,
  fasUser,
  fasUsers,
  fasAddressCard,
  fasEnvelope,
  fasBug,
  fasBook,
  fasExternalLinkAlt,
  fasTasks,
  fasRedoAlt,
  fasHistory,
  fasMedal,
  fasGem,
  farGem,
  fasCoins,
  fasStore,
  fasUserAstronaut,
  fasShoppingBag,
  fasChartLine,
  fasCertificate,
  fasGlobeAsia,
  fasBold,
  fasItalic,
  fasHighlighter,
  fasHeading,
  fasListOl,
  fasUnderline,
  fasStrikethrough,
  fasCode,
  fasQuoteRight,
  fasUndo,
  fasRedo,
  fasLink,
  fasTags,
  fasChartBar,
  fasProjectDiagram,
  fasCheckCircle,
  fasTimesCircle,
  fasFlag,
  fasTable,
  fasUserCircle,
  fasBars,
  fasSeedling,
  fasLandmark,
  fasHandPointer,
  faList,
  fasArrowRight,
  fasArrowLeft,
  fabTwitter,
  fabFacebook,
  fabLine,
  fasApple,
  fasMountain,
  faBullseye,
  faGear,
  faClock,
  fasCalendar,
  faCalendarDay,
  fasCircleCheck,
  faPen,
  fasRocket,
  fasHandFist,
  fasPassport,
  fasCircleQuestion,
  fasPalette,
  fasThumbsUp,
  fasStar,
  fasChessKing,
  fasArrowTrendUp,
  faPanorama,
  fasTrophy,
  fasShare,
  fasChevronLeft,
  fasChevronRight,
  fasChevronUp,
  fasChevronDown,
  fasCircleInfo,
  fasClipboardList,
  fasGripVertical,
  fasStopwatch,
  fasXmark,
  fasCaretDown,
  fasCaretUp,
  fasEllipsis,
  fasArrowUpFromBracket,
  fasArrowDown,
  fasFileArrowDown,
  fasImage,
  fasCheck,
  fasTag,
  fasFilter,
  fasComment,
  fasQuoteLeft,
  fasRss,
  fasEye,
  fasHatWizard,
  fasWandSparkles,
  fasPeopleRoof,
  fasCalendarDay,
  fasHouse,
  fasGamepad,
  fasBolt,
  fasFilm,
  fasPlay,
  fasSquareUpRight,
  fasCaretRight,
  fasWandMagic,
  fasCalendarCheck,
  fasSuitcase,
  fasSuitcase,
  fasSquareArrowUpRight,
  fasIndustry,
  fasSlash,
  fasUpRightAndDownLeftFromCenter,
  fasLocationDot,
  fasUserNinja,
  fasFutbol,
  fasPersonRunning,
  fasGun,
  fasMeteor,
  fasRightLong,
  fasLeftLong,
  fasDownLong,
  fasUpLong,
  fasForward
);

import "@/validate";
import router from "@/router";
import auth from "@/modules/auth";
import store from "@/store";
import i18n from "@/i18n";

import App from "@/App.vue";
import { global } from "./mixins";

Vue.prototype.$payjp = window.Payjp(process.env.VUE_APP_PAYJP_PK);

Sentry.init({
  Vue,
  dsn: "https://9d7f22bfc3c54ef69016aea9d2abcfd9@o975587.ingest.sentry.io/5931751",
  integrations: [
    new Integrations.BrowserTracing({
      routingInstrumentation: Sentry.vueRouterInstrumentation(router),
      tracingOrigins: ["localhost:8080", "skimie.com", "staging.skimie.com", /^\//],
    }),
  ],
  release: process.env.VUE_APP_VERSION,
  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
});

// Google Adsense
// import Ads from "vue-google-adsense";
// Vue.use(require("vue-script2"));
// Vue.use(Ads.Adsense); //広告ユニット
// Vue.use(Ads.InArticleAdsense); //記事内広告
// Vue.use(Ads.InFeedAdsense); //フィード内広告
// Google Adsense

import VueDragscroll from "vue-dragscroll";
Vue.use(VueDragscroll);

Vue.use(VueGtm, {
  id: "GTM-53PPHHQ4", // Your GTM single container ID, array of container ids ['GTM-xxxxxx', 'GTM-yyyyyy'] or array of objects [{id: 'GTM-xxxxxx', queryParams: { gtm_auth: 'abc123', gtm_preview: 'env-4', gtm_cookies_win: 'x'}}, {id: 'GTM-yyyyyy', queryParams: {gtm_auth: 'abc234', gtm_preview: 'env-5', gtm_cookies_win: 'x'}}], // Your GTM single container ID or array of container ids ['GTM-xxxxxx', 'GTM-yyyyyy']
  queryParams: {
    // Add URL query string when loading gtm.js with GTM ID (required when using custom environments)
    gtm_auth: process.env.VUE_APP_GTM_AUTH,
    gtm_preview: process.env.VUE_APP_GTM_PREVIEW,
    gtm_cookies_win: process.env.VUE_APP_GTM_COOKIES_WIN,
  },
  defer: false, // Script can be set to `defer` to speed up page load at the cost of less accurate results (in case visitor leaves before script is loaded, which is unlikely but possible). Defaults to false, so the script is loaded `async` by default
  compatibility: false, // Will add `async` and `defer` to the script tag to not block requests for old browsers that do not support `async`
  // nonce: '2726c7f26c', // Will add `nonce` to the script tag
  enabled: true, // defaults to true. Plugin can be disabled by setting this to false for Ex: enabled: !!GDPR_Cookie (optional)
  debug: process.env.VUE_APP_GTM_DEBUG, // Whether or not display console logs debugs (optional)
  loadScript: true, // Whether or not to load the GTM Script (Helpful if you are including GTM manually, but need the dataLayer functionality in your components) (optional)
  vueRouter: router, // Pass the router instance to automatically sync with router (optional)
  // ignoredViews: ['homepage'], // Don't trigger events for specified router names (optional)
  trackOnNextTick: false, // Whether or not call trackView in Vue.nextTick
});

Vue.use(VueCompositionApi);
Vue.use(VCalendar);
Vue.use(VueEllipseProgress);
Vue.use(BootstrapVue);
Vue.use(IconsPlugin);
Vue.use(VueMeta);
Vue.use(VTooltip);
Vue.use(VueSocialSharing);
Vue.mixin(global.mixins);

Vue.component("LpLayout", () => import(/* webpackChunkName: "lp-layout" */ "@/layouts/Lp.vue"));
Vue.component("DefaultLayout", () => import(/* webpackChunkName: "default-layout" */ "@/layouts/Default.vue"));
Vue.component("SettingLayout", () => import(/* webpackChunkName: "setting-layout" */ "@/layouts/Setting.vue"));
Vue.component("MissionLayout", () => import(/* webpackChunkName: "mission-layout" */ "@/layouts/Mission.vue"));
Vue.component("SkimieLayout", () => import(/* webpackChunkName: "skimie-layout" */ "@/layouts/Skimie.vue"));
Vue.component("PublicLayout", () => import(/* webpackChunkName: "public-layout" */ "@/layouts/Public.vue"));
Vue.component("LegendLayout", () => import(/* webpackChunkName: "legend-layout" */ "@/layouts/Legend.vue"));
Vue.component("FontAwesomeIcon", FontAwesomeIcon);
// Vue.component('font-awesome-icon', () => import('@fortawesome/vue-fontawesome'));
Vue.component("tags-input", VoerroTagsInput);

// Google API
// import VueGapi from "vue-gapi";
// Vue.use(VueGapi, {
//   apiKey: "AIzaSyAys77M2Q88TNeCBW7lkPMRf3-XiYq7sR4",
//   clientId: "965932447337-fetc8mvugvcevn5qh8i8nk70qrdotug5.apps.googleusercontent.com",
//   discoveryDocs: ["https://www.googleapis.com/discovery/v1/apis/calendar/v3/rest"],
//   scope: "https://www.googleapis.com/auth/calendar.events https://www.googleapis.com/auth/calendar",
// });

Vue.config.productionTip = false;

Vue.config.ignoredElements = [
  "a-scene",
  "a-entity",
  "a-camera",
  "a-box",
  "a-ring",
  "a-asset-items",
  "a-assets",
  "a-cursor",
  "a-text",
  "a-light",
  "a-sky",
  "a-animation",
];
new Vue({
  router,
  store,
  i18n,
  mixins: [auth.mixins],
  render: (h) => h(App),
}).$mount("#app");
