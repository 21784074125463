import Vue from "vue";
import Vuex from "vuex";

import alert, { getDefaultStateAlert } from "@/store/alert";
import member, { getDefaultStateMember } from "@/store/member";
import payjp, { getDefaultStatePayjp } from "@/store/payjp";
import portfolio, { getDefaultStatePortfolio } from "@/store/portfolio";
import skill, { getDefaultStateSkill } from "@/store/skill";
import task, { getDefaultStateTask } from "@/store/task";
import team, { getDefaultStateTeam } from "@/store/team";
import user, { getDefaultStateUser } from "@/store/user";
import view, { getDefaultStateView } from "@/store/view";
import wiki, { getDefaultStateWiki } from "@/store/wiki";
import goal, { getDefaultStateGoal } from "@/store/goal";
import imageManager, { getDefaultStateImageManager } from "@/store/imageManager";
import mission, { getDefaultStateMission } from "@/store/mission";
import resource, { getDefaultStateResource } from "@/store/resource";

Vue.use(Vuex);

const getDefaultState = () => {
  return {};
};

export default new Vuex.Store<any>({
  state: getDefaultState(),
  mutations: {
    drop(state) {
      Object.assign(alert.state, getDefaultStateAlert());
      Object.assign(member.state, getDefaultStateMember());
      Object.assign(payjp.state, getDefaultStatePayjp());
      Object.assign(portfolio.state, getDefaultStatePortfolio());
      Object.assign(skill.state, getDefaultStateSkill());
      Object.assign(task.state, getDefaultStateTask());
      Object.assign(team.state, getDefaultStateTeam());
      Object.assign(user.state, getDefaultStateUser());
      Object.assign(view.state, getDefaultStateView());
      Object.assign(wiki.state, getDefaultStateWiki());
      Object.assign(goal.state, getDefaultStateGoal());
      Object.assign(imageManager.state, getDefaultStateImageManager());
      Object.assign(mission.state, getDefaultStateMission());
      Object.assign(resource.state, getDefaultStateResource());
      console.debug("DROP", state);
    },
  },
  actions: {
    drop(context) {
      context.commit("drop");
    },
  },
  modules: {
    user,
    team,
    payjp,
    view,
    alert,
    skill,
    portfolio,
    wiki,
    task,
    goal,
    member,
    imageManager,
    mission,
    resource,
  },
});
