export default {
  daily: "Daily",
  weekly: "Weekly",
  monthly: "Monthly",
  yearly: "Yearly",

  dailydescevery: "毎日繰り返し",
  dailydesc: "%{period} 日ごとに繰り返し",

  weeklydescevery: "毎週 %{weekdays} 曜日に繰り返し",
  weeklydesc: "%{period} 週ごと %{weekdays} 曜日に繰り返し",

  monthlydescevery: "毎月 %{date} 日に繰り返し",
  monthlydesc: "%{period} ヶ月ごと %{date} 日に繰り返し",
  monthlydesceverylast: "毎月末に繰り返し",
  monthlydesclast: "%{period} ヶ月ごと月末に繰り返し",

  yearlydescevery: "毎年 %{month} 月 %{date} 日に繰り返し",
  yearlydesc: "%{period} 年ごと %{month} 月 %{date} 日に繰り返し",

  mon: "Mon",
  tue: "Tue",
  wed: "Wed",
  thu: "Thu",
  fri: "Fri",
  sat: "Sat",
  sun: "Sun",

  endofmonth: "月末",

  skimmy: {
    roomTitle: "スキミーの部屋",
    roomDesc:
      "ここでは、新しい転生先スキミーを獲得したり、ストーリーなどの情報を参照したり、獲得ずみのスキミーと交代ができるよ！",
    partyTitle: "パーティ",
    season: "シーズン",
    limitedSkimmy: "限定スキミー",
    encyclopedia: "スキミー図鑑",
    share: "共有",
    addpartytitle: "パーティへ追加",
    addpartydesc: "パーティへ追加しますか？",
    infoaddedparty: "%{name} をパーティ %{number} に追加しました。",
    infoalreadyadded: "このスキミーは既にパーティへ追加済みです。",
    infopartyfull: "パーティに空きがありません。先にメンバーを削除して空きを作ってください。",
    infogrowskimmy: "新しい転生先を獲得してスキミーを育て始めますか？",
    rebirthtitle: "新しい転生先",
    infoselected: "スキミーを選択しました。",
    infochanged: "スキミーを交代しました。",
    errchange: "スキミーの交代に失敗しました。しばらく経ってから、再度お試しください。",
    removefromparty: "パーティから削除",
    search: "Explore",
    searchTime: "Exploration Time",
    searching: "Exploring",
    searchBonus: "Exploration Bonus",
    searchTimeLeft: "Exploration Time Left",
    isStillSearching: "is Exploring",
    parameters: "Parameters",
  },

  resources: {
    ores: "ores",
    gems: "gems",
    coins: "coins",
  },

  room: {
    shop: "Shop",
    skimmyRoom: "Skimmys' Room",
    grow: "Grow",
    growBtnTooltip: "成長ボタンを長押しすると100ジェム与えることができます。",
    buyItem: "Buy Item",
    strengthen: "Strengthen",
    evolve: "Breakthrough",
    buySeasonItem: "Buy Season Exclusive Item",
  },

  tasksPastDueDate: "Tasks Are Past Due Date",

  avatar: {
    wikis: "Keep your newly found knowledge in a wiki!",
    tasks: "Lay your steps out in tasks to bring more clarity!",
    goals: "Set an objective and record it as a goal!",
    badges: "Which badges have you earned? Kill two birds with one stone by earning some gems🏆",
    grow: "What happens when you long press the grow button...?",
    dailyMissions: "Good luck with your daily missions👍",
    clearDailyMissions: "Find what happens when you clear all your daily missions...",
    registerTeam: "Creating a team opens up so many features😀",
    projects: "Creating a team allows you to manage projects📁",
    portfolios: "Put together a portfolio with your best artwork!",
    softwareSkills: "Record your software skills!💻",
    designSkills: "Record your design skills!🖼",
  },

  tasks: "Tasks",
  wikis: "Wikis",
  goals: "Goals",
  settings: "Settings",
  todaysTasks: "Today's\nTasks",
  todaysTaskTimes: "Today's\nTask Times",
  quests: "Quests",
  projects: "Projects",
  members: "Members",
  myPage: "My Page",
  overview: "Overview",
  tables: "Tables",
  analytics: "Analytics",
  completed: "Completed",

  widgetDailyMissions: {
    team: "Team",
    individual: "Individual",
    badge: "Badge",
    untakenGems: "You have unacquired gems",
    untakenOres: "You have unacquired ores",
  },

  skimmyMode: "Skimmy Mode",

  myStatus: {
    attendanceStatus: "My Status",
    active: "Active",
    private: "Off the Clock",
    onBreak: "On Break",
    notSet: "Not Set",
  },

  moreArticles: "More Articles",

  util: {
    favorites: "Favorites",
    favoritesExplanationText: "Add a page to your favorites by clicking the pin mark in the top right.",
  },

  userHome: {
    resume: "Resume",
    softwareSkills: "Skills - Software",
    designSkills: "Skills - Design",
    portfolios: "Portfolios",
    shareSettings: "Share Settings",
  },

  item: {
    steellump: "鋼の塊",
    polish: "研磨",
    grassdrop: "Droplet from Grass and Leaves",
    treedrop: "Droplet from a Tree",
    worldtreedrop: "Droplet from the World Tree",
    goledndogfood: "Golden Dog Food",

    bronzesmithsword: "Bronzesmith's Sword",
    silversmithsword: "Silversmith's Sword",
    lionknightsword: "Lion Knight Sword",
    cyclopssword: "Cyclops Sword",
    darkfatesword: "Dark Fate Sword",
    crystalsword: "Crystal Sword",
    dragonslayer: "Dragon Slayer",

    metalsmithrevolver: "Metalsmith's Revolver",
    silverbulletrevolver: "Silver Bullet Revolver",
    ghostrifle: "Ghost Rifle",
    goldbulletrifle: "Gold Bullet Rifle",
    xenorifle: "Xeno Rifle",
    sniperrifle: "Sniper Rifle",

    whisperstaff: "Whisper Staff",
    goldsmithstaff: "Goldsmith's Staff",
    emberstaff: "Ember Staff",
    infernostaff: "Inferno Staff",
    pegasusstaff: "Pegasus Staff",
    crystalstaff: "Crystal Staff",
    wisemanstaff: "Wiseman Staff",

    weapon: "Weapon",
    armor: "Armor",
    growth: "Growth",
    evolution: "Evolution",
    reinforce: "Reinforce",
    synthetic: "Synthetic",
    steelsword: "Steel Sword",
    swordtemplate: "Sword Template",
    equipmentgradebronze: "Equipment Grade Bronze",
    equipmentgradesilver: "Equipment Grade Silver",
    equipmentgradegold: "Equipment Grade Gold",
    itemreinforce: "Reinforce",
    equip: "Equip",
    equipped: "Equipped",
    unequippable: "Unequippable",
    equippableweapontype: "Equippable Weapons",
    sword: "Sword",
    staff: "Staff",
    gun: "Gun",
    coinitem: "Coins",
    sheeppillow: "Sheep's Pillow",
    achieverevolutionmaterial: "Mountain Summit Flower",
    socialiserevolutionmaterial: "Droplet of Truth",
    killerevolutionmaterial: "Silver Powder of Aura",
    explorerevolutionmaterial: "Sea Monster Extract",
    gunevolutionmaterial: "Gunminium",
    swordevolutionmaterial: "Knight Steel",
    staffevolutionmaterial: "Branch of Light",
    sheepwoolstaff: "Sheep Wool Staff",
    sheepskingripgun: "Sheep Skin Grip Gun",
    sheephornsword: "Sheep Horn Sword",
    sheepfarmbackground: "Sheep Farm Background",
    unlockskimmykey: "Season Skimmy Key",
    sheepkingportrait: "Sheep King Portrait",
    resource: "Resource",
    background: "Background",
    special: "Special",
    bossportrait: "Event Boss",
  },

  battle: {
    quickbattle: "Quick",
    quickbattle_sub: "",
    storybattle: "Story",
    storybattle_sub: "",
    seasonbattle: "Season",
    seasonbattle_sub: "",
    season3battle: "Season 3",
    season3battle_sub: "Ranch of Darkness",
    achieverevolutionmaterialbattle: "🎖 Evolution",
    achieverevolutionmaterialbattle_sub: "Impossible Mountain Climb",
    socialiserevolutionmaterialbattle: "💬 Evolution",
    socialiserevolutionmaterialbattle_sub: "Parliament of Truth",
    killerevolutionmaterialbattle: "⚔️ Evolution",
    killerevolutionmaterialbattle_sub: "Mansion of Assassins",
    explorerevolutionmaterialbattle: "🧭 Evolution",
    explorerevolutionmaterialbattle_sub: "Grand Ocean of Adventure",
    gunevolutionmaterialbattle: "🔫 Evolution",
    gunevolutionmaterialbattle_sub: "Sniper Duel!",
    swordevolutionmaterialbattle: "🗡️ Evolution",
    swordevolutionmaterialbattle_sub: "Road to Knighthood!",
    staffevolutionmaterialbattle: "🦯 Evolution",
    staffevolutionmaterialbattle_sub: "Magic Competition!",
    coinbattle: "💰 Accrue",
    coinbattle_sub: "Cave of Golden Glow",
  },

  bartle: {
    achiever: "Achiever",
    explorer: "Explorer",
    killer: "Killer",
    socialiser: "Socialiser",
  },
};
