export const getDefaultStateAlert = () => {
  return {
    showError: 0,
    showInfo: 0,
    showAward: 0,
    errorMessage: "",
    infoMessage: "",
    awardIcon: [],
    awardMessage: "",
  };
};

export default {
  namespaced: true,
  state: getDefaultStateAlert(),
  mutations: {
    showError(state, message) {
      state.showError = 3;
      state.errorMessage = message;
    },
    hideError(state) {
      state.showError = 0;
      state.errorMessage = "";
    },
    showInfo(state, message) {
      state.showInfo = 3;
      state.infoMessage = message;
    },
    hideInfo(state) {
      state.showInfo = 0;
      state.infoMessage = "";
    },
    showAward(state, message) {
      state.showAward = 10;
      state.awardIcon = message.icon;
      state.awardMessage = message.message;
    },
    hideAward(state) {
      state.showAward = 0;
      state.awardIcon = [];
      state.awardMessage = "";
    },
  },
  actions: {
    showError(context, message) {
      context.commit("showError", message);
    },
    showInfo(context, message) {
      context.commit("showInfo", message);
    },
    showAward(context, message) {
      context.commit("showAward", message);
    },
    hide(context) {
      context.commit("hideError");
      context.commit("hideInfo");
    },
  },
};
