export const getDefaultStateMember = () => {
  return {
    activeGoals: {
      totalCount: 0,
      offset: 0,
      limit: 0,
      goals: [],
    },
    achievedGoals: {
      totalCount: 0,
      offset: 0,
      limit: 0,
      goals: [],
    },
    current: {},
    currentMemberStatus: {},
  };
};

export default {
  namespaced: true,
  state: getDefaultStateMember(),
  mutations: {
    storeActiveGoals(state, data) {
      state.activeGoals.goals = data.issues;
      state.activeGoals.totalCount = data.total_count;
      state.activeGoals.offset = data.offset;
      state.activeGoals.limit = data.limit;
    },
    storeAchievedGoals(state, data) {
      state.achievedGoals.goals = data.issues;
      state.achievedGoals.totalCount = data.total_count;
      state.achievedGoals.offset = data.offset;
      state.achievedGoals.limit = data.limit;
    },
    storeCurrent(state, data) {
      state.current = data;
    },
    storeCurrentMemberStatus(state, data) {
      state.currentMemberStatus[data.teamname] = data.data;
    },
    drop(state) {
      state.activeGoals = {
        totalCount: 0,
        offset: 0,
        limit: 0,
        goals: [],
      };
      state.achievedGoals = {
        totalCount: 0,
        offset: 0,
        limit: 0,
        goals: [],
      };
      state.current = {};
    },
  },
  actions: {
    storeActiveGoals(context, data) {
      context.commit("storeActiveGoals", data);
    },
    storeAchievedGoals(context, data) {
      context.commit("storeAchievedGoals", data);
    },
    storeCurrent(context, data) {
      context.commit("storeCurrent", data);
    },
    storeCurrentMemberStatus(context, data) {
      context.commit("storeCurrentMemberStatus", data);
    },
    drop(context) {
      context.commit("drop");
    },
  },
};
