import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";
import store from "@/store";
import auth from "@/modules/auth";

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  { path: "/", name: "home", component: loadView("HelloWorld") },
  { path: "/features", name: "features", component: loadView("lp/SkFeatures") },
  { path: "/careers", name: "careers", component: loadView("lp/SkCareers") },
  { path: "/mediakit", name: "mediakit", component: loadView("lp/SkMediakit") },
  { path: "/pricing", name: "pricing", component: loadView("lp/SkPricing") },
  { path: "/law", name: "law", component: loadView("lp/SkLaw") },
  { path: "/terms", name: "terms", component: loadView("lp/SkTerms") },
  { path: "/privacy", name: "privacy", component: loadView("lp/SkPrivacy") },
  { path: "/news", name: "news", component: loadView("news/NewsList") },
  { path: "/news/season1", name: "news-season1", component: loadView("news/NewsSeason1") },
  { path: "/news/season2", name: "news-season2", component: loadView("news/NewsSeason2") },
  { path: "/404", name: "404", component: loadView("lp/404") },
  { path: "/500", name: "500", component: loadView("lp/500") },
  {
    path: "/articles",
    name: "articles",
    meta: {
      title: "記事一覧",
    },
    component: loadView("public/SkArticles"),
  },
  {
    path: "/articles/u/:username",
    name: "user - articles",
    meta: {
      title: "ユーザー記事一覧",
    },
    component: loadView("public/SkArticles"),
  },
  {
    path: "/articles/:articleId",
    name: "article",
    meta: {
      title: "記事",
    },
    component: loadView("public/SkArticle"),
  },
  {
    path: "/login",
    name: "login",
    meta: {
      title: "サインイン",
    },
    component: loadView("SkLogin"),
    beforeEnter: auth.beforeEnter.alreadyAuth,
  },
  {
    path: "/join",
    name: "join",
    meta: {
      title: "サインアップ",
    },
    component: loadView("SkJoin"),
    beforeEnter: auth.beforeEnter.alreadyAuth,
  },
  {
    path: "/contactus",
    name: "contactuslp",
    meta: {
      title: "お問い合わせ",
    },
    component: loadView("lp/SkLpContactUs"),
    beforeEnter: auth.beforeEnter.alreadyAuth,
  },
  {
    path: "/settings",
    name: "settings",
    meta: {
      title: "設定",
    },
    redirect: { name: "profile" },
    beforeEnter: auth.beforeEnter.requiresAuth,
  },
  {
    path: "/settings/profile",
    name: "profile",
    meta: {
      title: "設定 - プロフィール",
    },
    component: loadView("SkProfile"),
    beforeEnter: auth.beforeEnter.requiresAuth,
  },
  {
    path: "/settings/account",
    name: "account",
    meta: {
      title: "設定 - アカウント",
    },
    component: loadView("SkAccount"),
    beforeEnter: auth.beforeEnter.requiresAuth,
  },
  {
    path: "/settings/share",
    name: "share",
    meta: {
      title: "設定 - 共有",
    },
    component: loadView("SkSettingsShare"),
    beforeEnter: auth.beforeEnter.requiresAuth,
  },
  {
    path: "/settings/billing",
    name: "billing",
    meta: {
      title: "設定 - お支払い",
    },
    component: loadView("SkBilling"),
    beforeEnter: auth.beforeEnter.requiresAuth,
  },
  {
    path: "/settings/billing/upgrade",
    name: "upgrade",
    meta: {
      title: "設定 - お支払い - アップグレード",
    },
    component: loadView("SkPlanUpgrade"),
    beforeEnter: auth.beforeEnter.requiresAuth,
  },
  {
    path: "/missions",
    name: "missions",
    meta: {
      title: "ミッション",
    },
    redirect: { name: "missions-tasks" },
    beforeEnter: auth.beforeEnter.requiresAuth,
  },
  {
    path: "/missions/badges",
    name: "missions-badges",
    meta: {
      title: "ミッション - バッジ",
    },
    component: loadView("missions/SkBadges"),
    beforeEnter: auth.beforeEnter.requiresAuth,
  },
  {
    path: "/skimie/room",
    name: "skimie-room",
    meta: {
      title: "スキミーの部屋",
    },
    component: loadView("skimie/SkSkimmyEncyclopedia"),
    beforeEnter: auth.beforeEnter.requiresAuth,
  },
  {
    path: "/skimie/shop",
    name: "skimie-shop",
    meta: {
      title: "ショップ",
    },
    component: loadView("skimie/SkSkimmyShop"),
    beforeEnter: auth.beforeEnter.requiresAuth,
  },
  {
    path: "/skimie/items",
    name: "skimie-items",
    meta: {
      title: "アイテム",
    },
    component: loadView("skimie/SkSkimmyItems"),
    beforeEnter: auth.beforeEnter.requiresAuth,
  },
  {
    path: "/skimie/plant",
    name: "skimie-plant",
    meta: {
      title: "製錬工場",
    },
    component: loadView("skimie/SkSkimmyPlant"),
    beforeEnter: auth.beforeEnter.requiresAuth,
  },
  {
    path: "/skimie/play",
    name: "skimie-play",
    meta: {
      title: "遊び場",
    },
    component: loadView("skimie/SkPlayspace"),
    beforeEnter: auth.beforeEnter.requiresAuth,
  },
  {
    path: "/skimie/battletypes",
    name: "skimie-battle-types",
    meta: {
      title: "バトルタイプ",
    },
    component: loadView("skimie/SkBattleTypes"),
    beforeEnter: auth.beforeEnter.requiresAuth,
  },
  {
    path: "/skimie/battles/:battleTypeCode",
    name: "skimie-battles",
    meta: {
      title: "バトル一覧",
    },
    component: loadView("skimie/SkBattles"),
    beforeEnter: auth.beforeEnter.requiresAuth,
  },
  {
    path: "/skimie/battle",
    name: "skimie-battle",
    meta: {
      title: "バトル",
    },
    component: loadView("skimie/SkBattle"),
    beforeEnter: auth.beforeEnter.requiresAuth,
  },
  {
    path: "/skimie/verse",
    name: "skimie-verse",
    meta: {
      title: "広場",
    },
    component: loadView("skimie/SkVerse"),
    beforeEnter: auth.beforeEnter.requiresAuth,
  },
  {
    path: "/missions/daily",
    name: "missions-daily",
    meta: {
      title: "ミッション - デイリー",
    },
    component: loadView("missions/SkDaily"),
    beforeEnter: auth.beforeEnter.requiresAuth,
  },
  {
    path: "/missions/tasks",
    name: "missions-tasks",
    meta: {
      title: "ミッション - マイタスク",
    },
    component: loadView("missions/SkMyTasks"),
    beforeEnter: auth.beforeEnter.requiresAuth,
  },
  {
    path: "/missions/tasks/new",
    name: "missions-tasks-new",
    meta: {
      title: "ミッション - マイタスク - 新規作成",
    },
    component: loadView("missions/SkMyTaskDetail"),
    beforeEnter: auth.beforeEnter.requiresAuth,
  },
  {
    path: "/missions/tasks/copy",
    name: "missions-tasks-copy",
    meta: {
      title: "ミッション - マイタスク - コピー",
    },
    props: { isCopy: true },
    component: loadView("missions/SkMyTaskDetail"),
    beforeEnter: auth.beforeEnter.requiresAuth,
  },
  {
    path: "/missions/tasks/:taskId",
    name: "missions-tasks-detail",
    meta: {
      title: "ミッション - マイタスク - 詳細",
    },
    component: loadView("missions/SkMyTaskDetail"),
    beforeEnter: auth.beforeEnter.requiresAuth,
  },
  {
    path: "/missions/wikis",
    name: "missions-wikis",
    meta: {
      title: "ミッション - マイナレッジ",
    },
    component: loadView("missions/SkMyWikis"),
    beforeEnter: auth.beforeEnter.requiresAuth,
  },
  {
    path: "/missions/wikis/new",
    name: "missions-wikis-new",
    meta: {
      title: "ミッション - マイナレッジ - 新規作成",
    },
    component: loadView("missions/SkMyWikiDetail"),
    beforeEnter: auth.beforeEnter.requiresAuth,
  },
  {
    path: "/missions/wikis/:wikiId",
    name: "missions-wikis-detail",
    meta: {
      title: "ミッション - マイナレッジ - 詳細",
    },
    component: loadView("missions/SkMyWikiDetail"),
    beforeEnter: auth.beforeEnter.requiresAuth,
  },
  {
    path: "/missions/goals",
    name: "missions-goals",
    meta: {
      title: "ミッション - マイゴール",
    },
    component: loadView("missions/SkMyGoals"),
    beforeEnter: auth.beforeEnter.requiresAuth,
  },
  {
    path: "/missions/goals/new",
    name: "missions-goals-new",
    meta: {
      title: "ミッション - マイゴール - 新規作成",
    },
    component: loadView("missions/SkMyGoalDetail"),
    beforeEnter: auth.beforeEnter.requiresAuth,
  },
  {
    path: "/missions/goals/:goalId",
    name: "missions-goals-detail",
    meta: {
      title: "ミッション - マイゴール - 詳細",
    },
    component: loadView("missions/SkMyGoalDetail"),
    beforeEnter: auth.beforeEnter.requiresAuth,
  },
  {
    path: "/missions/settings",
    name: "missions-settings",
    meta: {
      title: "ミッション - 設定",
    },
    component: loadView("missions/SkMySettings"),
    beforeEnter: auth.beforeEnter.requiresAuth,
  },
  {
    path: "/missions/quests",
    name: "missions-quests",
    meta: {
      title: "ミッション - クエスト",
    },
    component: loadView("missions/SkMyQuests"),
    beforeEnter: auth.beforeEnter.requiresAuth,
  },
  {
    path: "/notifications/list",
    name: "notifications-list",
    meta: {
      title: "通知 - 一覧",
    },
    component: loadView("SkNotificationsList"),
    beforeEnter: auth.beforeEnter.requiresAuth,
  },
  {
    path: "/skills",
    name: "skills",
    meta: {
      title: "スキル",
    },
    redirect: (/* to */) => {
      if (store.state.skill.skillTypes && store.state.skill.skillTypes.length > 0) {
        return { path: "/skills/" + store.state.skill.skillTypes[0].type + "/1" };
      } else {
        return { path: "/" };
      }
    },
    beforeEnter: auth.beforeEnter.requiresAuth,
  },
  {
    path: "/skills/:type",
    name: "skillsType",
    meta: {
      title: "スキル - タイプ別",
    },
    redirect: (to) => ({ path: "/skills/" + to.params.type + "/1" }),
    beforeEnter: auth.beforeEnter.requiresAuth,
  },
  {
    path: "/skills/:type/:order",
    name: "skill-type-order",
    meta: {
      title: "スキル - スキルセット",
    },
    component: loadView("SkSkill"),
    beforeEnter: auth.beforeEnter.requiresAuth,
  },
  {
    path: "/skills/:type/:order/analytics",
    name: "skill",
    meta: {
      title: "スキル - タイプ別 - スキルセット - アナリティクス",
    },
    component: loadView("SkSkillAnalytics"),
    beforeEnter: auth.beforeEnter.requiresAuth,
  },
  {
    path: "/skills/:type/:order/:detailOrder",
    name: "skillDetal",
    meta: {
      title: "スキル - タイプ別 - スキルセット - 詳細",
    },
    component: loadView("SkSkillDetail"),
    beforeEnter: auth.beforeEnter.requiresAuth,
  },
  {
    path: "/portfolios",
    name: "portfolios",
    meta: {
      title: "ポートフォリオ",
    },
    component: loadView("SkPortfolios"),
    beforeEnter: auth.beforeEnter.requiresAuth,
  },
  {
    path: "/portfolios/myportfolio/new",
    name: "myportfolio-new",
    meta: {
      title: "作品集 - 追加",
    },
    component: loadView("SkPortfolioDetail"),
    beforeEnter: auth.beforeEnter.requiresAuth,
  },
  {
    path: "/portfolios/myportfolio/edit",
    name: "myportfolio-edit",
    meta: {
      title: "作品集 - 編集",
    },
    component: loadView("SkPortfolioDetail"),
    beforeEnter: auth.beforeEnter.requiresAuth,
  },
  {
    path: "/portfolios/myportfolio/new/preview",
    name: "myportfolio-new-preview",
    meta: {
      title: "作品集 - プレビュー",
    },
    component: loadView("SkPortfolioView"),
    beforeEnter: auth.beforeEnter.requiresAuth,
  },
  {
    path: "/portfolios/myportfolio/:portfolioId",
    name: "myportfolio-view",
    meta: {
      title: "作品集 - 閲覧",
    },
    component: loadView("SkPortfolioView"),
    beforeEnter: auth.beforeEnter.requiresAuth,
  },
  {
    path: "/portfolios/sharedportfolio/:portfolioId",
    name: "sharedportfolio-view",
    meta: {
      title: "共有作品集 - 閲覧",
    },
    component: loadView("SkPortfolioView"),
    beforeEnter: auth.beforeEnter.requiresAuth,
  },
  {
    path: "/portfolios/approveportfolio/:portfolioId",
    name: "approveportfolio-view",
    meta: {
      title: "承認待ち - 閲覧",
    },
    component: loadView("SkPortfolioView"),
    beforeEnter: auth.beforeEnter.requiresAuth,
  },
  {
    path: "/portfolios/public/:portfolioId",
    name: "publicportfolio-view",
    meta: {
      title: "公開作品集 - 閲覧",
    },
    component: loadView("SkPortfolioView"),
  },
  {
    path: "/embed/portfolios/:portfolioId",
    name: "embed-portfolios",
    meta: {
      title: "公開作品集 - 埋込",
    },
    component: loadView("SkPortfolioEmbed"),
  },
  {
    path: "/embed/articles/:articleId",
    name: "embed-articles",
    meta: {
      title: "公開記事 - 埋込",
    },
    component: loadView("embed/SkEmbedArticle"),
  },
  {
    path: "/embed/parties/:partyId",
    name: "embed-parties",
    meta: {
      title: "公開パーティ - 埋込",
    },
    component: loadView("embed/SkEmbedParty"),
  },
  {
    path: "/embed/teams/:teamname/wikis/:wikiId",
    name: "embed-teams-teamname-wiki-detail",
    component: loadView("teams/SkWikiEmbed"),
    beforeEnter: auth.beforeEnter.requiresAuth,
  },
  {
    path: "/portfolios/shared",
    name: "shared-portfolios",
    meta: {
      title: "公開ポートフォリオ - 一覧",
    },
    component: loadView("public/SkPortfoliosShared"),
  },
  {
    path: "/portfolios/u/:username",
    name: "user-shared-portfolios",
    meta: {
      title: "ユーザー公開ポートフォリオ - 一覧",
    },
    component: loadView("public/SkPortfoliosShared"),
  },
  {
    path: "/portfolios/shared/:hash",
    name: "shared-portfolio",
    meta: {
      title: "公開ポートフォリオ - 閲覧",
    },
    component: loadView("public/SkPortfolioShared"),
  },

  {
    path: "/teams",
    name: "teams",
    meta: {
      title: "チーム",
    },
    component: loadView("SkTeams"),
  },
  {
    path: "/teams/add",
    name: "teams-add",
    meta: {
      title: "チーム - 追加",
    },
    props: { add: true },
    component: loadView("SkTeamDetail"),
    beforeEnter: auth.beforeEnter.requiresAuth,
  },
  {
    path: "/teams/:teamname",
    name: "teams-teamname",
    meta: {
      title: "チーム - 詳細",
    },
    component: loadView("SkTeamDetail"),
    beforeEnter: auth.beforeEnter.requiresAuth,
  },
  {
    path: "/teams/:teamname/description",
    name: "teams-teamname-description",
    meta: {
      title: "チーム - 概要",
    },
    component: loadView("SkTeamDescription"),
    beforeEnter: auth.beforeEnter.requiresAuth,
  },
  {
    path: "/teams/:teamname/articles",
    name: "teams-teamname-articles",
    meta: {
      title: "チーム - 公開記事一覧",
    },
    component: loadView("public/SkTeamArticles"),
    // beforeEnter: auth.beforeEnter.requiresAuth,
  },
  {
    path: "/teams/:teamname/settings",
    name: "teams-teamname-settings",
    meta: {
      title: "チーム - 設定",
    },
    component: loadView("SkTeamSettings"),
    beforeEnter: auth.beforeEnter.requiresAuth,
  },
  {
    path: "/teams/:teamname/wikis",
    name: "teams-teamname-wiki",
    meta: {
      title: "チーム - Wiki",
    },
    component: loadView("teams/SkWikis"),
    beforeEnter: auth.beforeEnter.requiresAuth,
  },
  {
    path: "/teams/:teamname/wikis/new",
    name: "teams-teamname-wiki-new",
    meta: {
      title: "チーム - Wiki - 新規作成",
    },
    component: loadView("teams/SkWiki"),
    beforeEnter: auth.beforeEnter.requiresAuth,
  },
  {
    path: "/teams/:teamname/wikis/:wikiId",
    name: "teams-teamname-wiki-detail",
    meta: {
      title: "チーム - Wiki - 詳細",
    },
    component: loadView("teams/SkWiki"),
    beforeEnter: auth.beforeEnter.requiresAuth,
  },
  {
    path: "/teams/:teamname/quests",
    name: "teams-teamname-quests",
    meta: {
      title: "チーム - クエスト",
    },
    component: loadView("teams/SkQuests"),
    beforeEnter: auth.beforeEnter.requiresAuth,
  },
  {
    path: "/quests",
    name: "public-quests",
    meta: {
      title: "公開クエスト - 閲覧",
    },
    component: loadView("public/SkPublicQuests"),
  },
  {
    path: "/teams/:teamname/quests/:questId",
    name: "teams-teamname-quest",
    meta: {
      title: "チーム - クエスト詳細",
    },
    component: loadView("quests/SkTeamQuestDetails"),
    beforeEnter: auth.beforeEnter.requiresAuth,
  },
  {
    path: "/missions/quests/:questId",
    name: "missions-quest",
    meta: {
      title: "ミッション - クエスト詳細",
    },
    component: loadView("quests/SkMyQuestDetails"),
    beforeEnter: auth.beforeEnter.requiresAuth,
  },
  {
    path: "/quests/:publicUrl",
    name: "public-quest",
    meta: {
      title: "公開クエスト - 詳細",
    },
    component: loadView("quests/SkPublicQuestDetails"),
  },
  {
    path: "/teams/:teamname/projects",
    name: "teams-teamname-projects",
    meta: {
      title: "チーム - プロジェクト",
    },
    component: loadView("teams/SkProjects"),
    beforeEnter: auth.beforeEnter.requiresAuth,
  },
  {
    path: "/teams/:teamname/projects/new",
    name: "teams-teamname-projects-new",
    meta: {
      title: "チーム - プロジェクト - 新規作成",
    },
    component: loadView("SkTeamProjectDetail"),
    beforeEnter: auth.beforeEnter.requiresAuth,
  },
  {
    path: "/teams/:teamname/projects/:projectOrder",
    name: "teams-teamname-projects-detail",
    meta: {
      title: "チーム - プロジェクト - 詳細",
    },
    props: { isProject: true },
    redirect: "/teams/:teamname/projects/:projectOrder/tasks",
    beforeEnter: auth.beforeEnter.requiresAuth,
  },
  {
    path: "/teams/:teamname/projects/:projectOrder/members",
    name: "teams-teamname-projects-members",
    meta: {
      title: "チーム - プロジェクト - メンバー",
    },
    component: loadView("teams/SkMembers"),
    beforeEnter: auth.beforeEnter.requiresAuth,
  },
  {
    path: "/teams/:teamname/-/projects/:projectOrder/tasks",
    redirect: "/teams/:teamname/projects/:projectOrder/tasks",
  },
  {
    path: "/teams/:teamname/projects/:projectOrder/tasks",
    name: "teams-teamname-projects-tasks",
    meta: {
      title: "チーム - プロジェクト - タスク",
    },
    props: { isProject: true },
    component: loadView("teams/SkProjectTasks"),
    beforeEnter: auth.beforeEnter.requiresAuth,
  },
  {
    path: "/teams/:teamname/projects/:projectOrder/tasks/new",
    name: "teams-teamname-projects-task-new",
    meta: {
      title: "チーム - プロジェクト - タスク - 新規作成",
    },
    props: { isProject: true },
    component: loadView("SkTeamTaskDetail"),
    beforeEnter: auth.beforeEnter.requiresAuth,
  },
  {
    path: "/teams/:teamname/projects/:projectOrder/tasks/copy",
    name: "teams-teamname-projects-task-copy",
    meta: {
      title: "チーム - プロジェクト - タスク - 新規作成",
    },
    props: { isProject: true, isCopy: true },
    component: loadView("SkTeamTaskDetail"),
    beforeEnter: auth.beforeEnter.requiresAuth,
  },
  {
    path: "/teams/:teamname/-/projects/:projectOrder/tasks/:taskId",
    redirect: "/teams/:teamname/projects/:projectOrder/tasks/:taskId",
  },
  {
    path: "/teams/:teamname/projects/:projectOrder/task/:taskId",
    redirect: "/teams/:teamname/projects/:projectOrder/tasks/:taskId",
  },
  {
    path: "/teams/:teamname/projects/:projectOrder/tasks/:taskId",
    name: "teams-teamname-projects-task-detail",
    meta: {
      title: "チーム - プロジェクト - タスク - 新規作成",
    },
    props: { isProject: true },
    component: loadView("SkTeamTaskDetail"),
    beforeEnter: auth.beforeEnter.requiresAuth,
  },
  {
    path: "/teams/:teamname/projects/:projectOrder/goals",
    name: "teams-teamname-projects-goals",
    meta: {
      title: "チーム - プロジェクト - ゴール",
    },
    props: { isProject: true },
    component: loadView("teams/SkProjectGoals"),
    beforeEnter: auth.beforeEnter.requiresAuth,
  },
  {
    path: "/teams/:teamname/projects/:projectOrder/goals/new",
    name: "teams-teamname-projects-goals-new",
    meta: {
      title: "チーム - プロジェクト - ゴール - 新規作成",
    },
    component: loadView("teams/SkProjectGoal"),
    beforeEnter: auth.beforeEnter.requiresAuth,
  },
  {
    path: "/teams/:teamname/projects/:projectOrder/goals/:goalId",
    name: "teams-teamname-projects-goals-detail",
    meta: {
      title: "チーム - プロジェクト - ゴール",
    },
    component: loadView("teams/SkProjectGoal"),
    beforeEnter: auth.beforeEnter.requiresAuth,
  },
  {
    path: "/teams/:teamname/projects/:projectOrder/tables",
    name: "teams-teamname-projects-tables",
    meta: {
      title: "チーム - プロジェクト - テーブル一覧",
    },
    component: loadView("projects/SkTables"),
    beforeEnter: auth.beforeEnter.requiresAuth,
  },
  {
    path: "/teams/:teamname/projects/:projectOrder/tables/:tableNumber",
    name: "teams-teamname-projects-tables-detail",
    meta: {
      title: "チーム - プロジェクト - テーブル",
    },
    component: loadView("projects/SkTable"),
    beforeEnter: auth.beforeEnter.requiresAuth,
  },
  {
    path: "/teams/:teamname/projects/:projectOrder/description",
    name: "teams-teamname-projects-description",
    meta: {
      title: "チーム - プロジェクト - 概要",
    },
    props: { isProject: true },
    component: loadView("SkTeamProjectDescription"),
    beforeEnter: auth.beforeEnter.requiresAuth,
  },
  {
    path: "/teams/:teamname/projects/:projectOrder/settings",
    name: "teams-teamname-projects-settings",
    meta: {
      title: "チーム - プロジェクト - 設定",
    },
    props: { isProject: true },
    component: loadView("SkTeamSettings"),
    beforeEnter: auth.beforeEnter.requiresAuth,
  },
  {
    path: "/teams/:teamname/tasks",
    name: "teams-teamname-task",
    meta: {
      title: "チーム - タスク",
    },
    component: loadView("teams/SkTasks"),
    beforeEnter: auth.beforeEnter.requiresAuth,
  },
  {
    path: "/teams/:teamname/tasks/new",
    name: "teams-teamname-task-new",
    meta: {
      title: "チーム - タスク - 新規作成",
    },
    component: loadView("SkTeamTaskDetail"),
    beforeEnter: auth.beforeEnter.requiresAuth,
  },
  {
    path: "/teams/:teamname/tasks/copy",
    name: "teams-teamname-task-copy",
    meta: {
      title: "チーム - タスク - コピー",
    },
    props: { isCopy: true },
    component: loadView("SkTeamTaskDetail"),
    beforeEnter: auth.beforeEnter.requiresAuth,
  },
  {
    path: "/teams/:teamname/-/task/:taskId",
    redirect: "/teams/:teamname/tasks/:taskId",
  },
  {
    path: "/teams/:teamname/-/tasks/:taskId",
    redirect: "/teams/:teamname/tasks/:taskId",
  },
  {
    path: "/teams/:teamname/tasks/:taskId",
    name: "teams-teamname-task-detail",
    meta: {
      title: "チーム - タスク - 詳細",
    },
    component: loadView("SkTeamTaskDetail"),
    beforeEnter: auth.beforeEnter.requiresAuth,
  },
  {
    path: "/teams/:teamname/members",
    name: "teams-teamname-members",
    meta: {
      title: "チーム - 詳細 - メンバー",
    },
    component: loadView("teams/SkMembers"),
    beforeEnter: auth.beforeEnter.requiresAuth,
  },
  {
    path: "/teams/:teamname/members/:username",
    name: "teams-teamname-username",
    meta: {
      title: "チーム - 詳細 - メンバー",
    },
    component: loadView("SkPublicUser"),
    beforeEnter: auth.beforeEnter.requiresAuth,
  },
  {
    path: "/teams/:teamname/members/:username/-/eval/goal/new",
    name: "teams-teamname-username-goal-new",
    meta: {
      title: "チーム - 詳細 - メンバー - ゴール",
    },
    component: loadView("SkMemberEvalGoal"),
    beforeEnter: auth.beforeEnter.requiresAuth,
  },
  {
    path: "/teams/:teamname/members/:username/-/eval/goal/:goalId",
    name: "teams-teamname-username-goal-dtl",
    meta: {
      title: "チーム - 詳細 - メンバー - ゴール",
    },
    component: loadView("SkMemberEvalGoal"),
    beforeEnter: auth.beforeEnter.requiresAuth,
  },
  {
    path: "/teams/:teamname/members/:username/:type/:order",
    name: "teams-teamname-username-order",
    meta: {
      title: "チーム - 詳細 - メンバー - スキル",
    },
    component: loadView("SkSkill"),
    beforeEnter: auth.beforeEnter.requiresAuth,
  },
  {
    path: "/teams/:teamname/members/:username/:type/:order/analytics",
    name: "teams-teamname-username-order-analytics",
    meta: {
      title: "チーム - 詳細 - メンバー - スキル - アナリティクス",
    },
    component: loadView("SkSkillAnalytics"),
    beforeEnter: auth.beforeEnter.requiresAuth,
  },
  {
    path: "/teams/:teamname/members/:username/:type/:order/:detailOrder",
    name: "teams-teamname-username-order-skill",
    meta: {
      title: "チーム - 詳細 - メンバー - スキル - 詳細",
    },
    component: loadView("SkSkillDetail"),
    beforeEnter: auth.beforeEnter.requiresAuth,
  },
  {
    path: "/projects",
    name: "projects",
    component: loadView("projects/SkProjects"),
    beforeEnter: auth.beforeEnter.requiresAuth,
  },
  {
    path: "/projects/:projectIdentifier",
    name: "projects-identifier",
    redirect: { name: "projects-identifier-tasks" },
    beforeEnter: auth.beforeEnter.requiresAuth,
  },
  {
    path: "/projects/:projectIdentifier/tasks",
    name: "projects-identifier-tasks",
    component: loadView("projects/SkTasks"),
    beforeEnter: auth.beforeEnter.requiresAuth,
  },
  {
    path: "/projects/:projectIdentifier/tasks/:taskId",
    name: "projects-identifier-tasks-detail",
    component: loadView("projects/SkTask"),
    beforeEnter: auth.beforeEnter.requiresAuth,
  },
  {
    path: "/u/:username",
    name: "public-profile",
    meta: {
      title: "公開プロフィール",
    },
    component: loadView("missions/SkMyLegend"),
  },
  {
    path: "/publicprofile/search",
    name: "public-profile-search",
    meta: {
      title: "公開プロフィール検索",
    },
    component: loadView("SkPublicProfileSearch"),
  },
  {
    path: "/users/:username",
    name: "users-username",
    meta: {
      title: "パブリックユーザー",
    },
    component: loadView("SkPublicUser"),
    beforeEnter: auth.beforeEnter.requiresAuth,
  },
  {
    path: "/contact/bugreport",
    name: "bugreport",
    meta: {
      title: "バグレポート",
    },
    component: loadView("SkBugReport"),
    beforeEnter: auth.beforeEnter.requiresAuth,
  },
  {
    path: "/contact/contactus",
    name: "contactus",
    meta: {
      title: "お問い合わせ",
    },
    component: loadView("SkContactUs"),
    beforeEnter: auth.beforeEnter.requiresAuth,
  },
];

/**
 * loadView is Dynamic import
 * @param {string} view vue file name
 * @return {object} vue string
 */
function loadView(view: string) {
  return () => import(/* webpackChunkName: "[request]" */ `@/pages/${view}.vue`);
}

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
});

export default router;
