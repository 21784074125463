export default {
  daily: "日ごと",
  weekly: "週ごと",
  monthly: "月ごと",
  yearly: "年ごと",

  dailydescevery: "毎日繰り返し",
  dailydesc: "%{period} 日ごとに繰り返し",

  weeklydescevery: "毎週 %{weekdays} 曜日に繰り返し",
  weeklydesc: "%{period} 週ごと %{weekdays} 曜日に繰り返し",

  monthlydescevery: "毎月 %{date} 日に繰り返し",
  monthlydesc: "%{period} ヶ月ごと %{date} 日に繰り返し",
  monthlydesceverylast: "毎月末に繰り返し",
  monthlydesclast: "%{period} ヶ月ごと月末に繰り返し",

  yearlydescevery: "毎年 %{month} 月 %{date} 日に繰り返し",
  yearlydesc: "%{period} 年ごと %{month} 月 %{date} 日に繰り返し",

  mon: "月",
  tue: "火",
  wed: "水",
  thu: "木",
  fri: "金",
  sat: "土",
  sun: "日",

  endofmonth: "月末",

  skimmy: {
    roomTitle: "スキミーの部屋",
    roomDesc:
      "ここでは、新しい転生先スキミーを獲得したり、ストーリーなどの情報を参照したり、獲得ずみのスキミーと交代ができるよ！",
    partyTitle: "パーティ",
    season: "シーズン",
    limitedSkimmy: "限定スキミー",
    encyclopedia: "スキミー図鑑",
    share: "共有",
    addpartytitle: "パーティへ追加",
    addpartydesc: "パーティへ追加しますか？",
    infoaddedparty: "%{name} をパーティ %{number} に追加しました。",
    infoalreadyadded: "このスキミーは既にパーティへ追加済みです。",
    infopartyfull: "パーティに空きがありません。先にメンバーを削除して空きを作ってください。",
    infogrowskimmy: "新しい転生先を獲得してスキミーを育て始めますか？",
    rebirthtitle: "新しい転生先",
    infoselected: "スキミーを選択しました。",
    infochanged: "スキミーを交代しました。",
    errchange: "スキミーの交代に失敗しました。しばらく経ってから、再度お試しください。",
    removefromparty: "パーティから削除",
    search: "探索",
    searchTime: "探索時間",
    searching: "探索中",
    searchBonus: "探索ボーナス",
    searchTimeLeft: "探索残り時間",
    isStillSearching: "が探索中",
    parameters: "パラメーター",
  },

  resources: {
    ores: "原石",
    gems: "ジェム",
    coins: "コイン",
  },

  room: {
    shop: "ショップ",
    skimmyRoom: "スキミーの部屋",
    grow: "成長",
    growBtnTooltip: "成長ボタンを長押しすると100ジェム与えることができます。",
    buyItem: "アイテム購入",
    strengthen: "強化",
    evolve: "突破",
    buySeasonItem: "シーズン限定アイテム購入",
  },

  tasksPastDueDate: "期限日超過タスクあり",

  avatar: {
    wikis: "知識を得たらナレッジに残そう！",
    tasks: "タスク化するとやるべきことが明確に！",
    goals: "目標を決めてゴールに記録しよう！",
    badges: "獲得したバッジはどれかな？ジェムもゲットで一石二鳥🏆",
    grow: "成長ボタンを長押ししてみたら...？",
    dailyMissions: "デイリーミッションがんばってね👍",
    clearDailyMissions: "デイリーミッションを全部クリアしたら...",
    registerTeam: "チーム登録するとできることが増える😀",
    projects: "チーム登録したら次はプロジェクト管理📁",
    portfolios: "ポートフォリオで気に入った作品をまとめよう！",
    softwareSkills: "ソフトウェアスキルを登録しよう！💻",
    designSkills: "デザインスキルを登録しよう！🖼",
  },

  tasks: "タスク",
  wikis: "ナレッジ",
  goals: "ゴール",
  settings: "設定",
  todaysTasks: "今日の\nタスク",
  todaysTaskTimes: "今日の\n時間記録",
  quests: "クエスト",
  projects: "プロジェクト",
  members: "メンバー",
  myPage: "マイページ",
  overview: "概要",
  tables: "テーブル",
  analytics: "アナリティクス",
  completed: "完了済",

  widgetDailyMissions: {
    team: "チーム",
    individual: "個人",
    badge: "バッジ",
    untakenGems: "未獲得ジェムがあります",
    untakenOres: "未獲得原石があります",
  },

  skimmyMode: "スキミーモード",

  myStatus: {
    attendanceStatus: "出席状態",
    active: "アクティブ",
    private: "プライベート",
    onBreak: "休憩中",
    notSet: "未設定",
  },

  moreArticles: "もっと見てみる",

  util: {
    favorites: "お気に入り",
    favoritesExplanationText: "右上のピンマークからページをお気に入りに追加できます。",
  },

  userHome: {
    resume: "Resume",
    softwareSkills: "スキル - ソフトウェア",
    designSkills: "スキル - デザイン",
    portfolios: "ポートフォリオ",
    shareSettings: "共有設定",
  },

  item: {
    steellump: "鋼の塊",
    polish: "研磨 %{number}",
    grassdrop: "草葉のしずく",
    treedrop: "樹木のしずく",
    worldtreedrop: "世界樹のしずく",
    goledndogfood: "ゴールデンドッグフード",
    bronzesmithsword: "ブロンズスミスソード",
    silversmithsword: "シルバースミスソード",
    lionknightsword: "ライオンナイトソード",
    cyclopssword: "サイクロプスソード",
    darkfatesword: "ダークフェイトソード",
    crystalsword: "クリスタルソード",
    dragonslayer: "ドラゴンスレイヤー",

    metalsmithrevolver: "メタルスミスリボルバー",
    silverbulletrevolver: "シルバーブレットレボルバー",
    ghostrifle: "ゴーストライフル",
    goldbulletrifle: "ゴールドブレットライフル",
    xenorifle: "ゼノライフル",
    sniperrifle: "スナイパーライフル",

    whisperstaff: "ウィスパースタッフ",
    goldsmithstaff: "ゴールドスミススタッフ",
    emberstaff: "エンバースタッフ",
    infernostaff: "インフェルノスタッフ",
    pegasusstaff: "ペガサススタッフ",
    crystalstaff: "クリスタルスタッフ",
    wisemanstaff: "賢者の杖",

    weapon: "武器",
    armor: "防具",
    growth: "成長",
    evolution: "進化",
    reinforce: "補強",
    synthetic: "合成",
    steelsword: "スチールソード",
    swordtemplate: "ソードの型",
    equipmentgradebronze: "装備用の銅",
    equipmentgradesilver: "装備用の銀",
    equipmentgradegold: "装備用の金",
    itemreinforce: "強化",
    equip: "装備する",
    equipped: "装備中",
    unequippable: "装備不可",
    equippableweapontype: "装備可武器",
    sword: "剣",
    staff: "杖",
    gun: "銃",
    coinitem: "コイン",
    sheeppillow: "ひつじの枕",
    achieverevolutionmaterial: "山頂の花",
    socialiserevolutionmaterial: "真実の雫",
    killerevolutionmaterial: "オーラの銀粉",
    explorerevolutionmaterial: "海獣のエキス",
    gunevolutionmaterial: "ジュウミニウム",
    swordevolutionmaterial: "ナイトスチール",
    staffevolutionmaterial: "コウゴウブランチ",
    sheepwoolstaff: "シープウールスタッフ",
    sheepskingripgun: "シープスキングリップガン",
    sheephornsword: "シープホーンソード",
    sheepfarmbackground: "シープファームバックグラウンド",
    unlockskimmykey: "シーズンスキミーキー",
    sheepkingportrait: "シープキングポートレート",
    resource: "リソース",
    background: "背景",
    special: "特殊",
    bossportrait: "イベントボス",
  },

  battle: {
    quickbattle: "サクサク",
    quickbattle_sub: "",
    storybattle: "ストーリー",
    storybattle_sub: "",
    seasonbattle: "シーズン",
    seasonbattle_sub: "",
    season3battle: "シーズン3",
    season3battle_sub: "暗黒の牧場",
    achieverevolutionmaterialbattle: "🎖 突破",
    achieverevolutionmaterialbattle_sub: "山頂見えぬ登山",
    socialiserevolutionmaterialbattle: "💬 突破",
    socialiserevolutionmaterialbattle_sub: "真贋の議事堂",
    killerevolutionmaterialbattle: "⚔️ 突破",
    killerevolutionmaterialbattle_sub: "暗殺の館",
    explorerevolutionmaterialbattle: "🧭 突破",
    explorerevolutionmaterialbattle_sub: "冒険の大海",
    gunevolutionmaterialbattle: "🔫 突破",
    gunevolutionmaterialbattle_sub: "狙撃決闘！",
    swordevolutionmaterialbattle: "🗡️ 突破",
    swordevolutionmaterialbattle_sub: "騎士道邁進！",
    staffevolutionmaterialbattle: "🦯 突破",
    staffevolutionmaterialbattle_sub: "魔術力競技会！",
    coinbattle: "💰 獲得",
    coinbattle_sub: "金色輝く洞窟",
  },

  bartle: {
    achiever: "達成者",
    explorer: "冒険者",
    killer: "行動者",
    socialiser: "外交者",
  },
};
