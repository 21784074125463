export const getDefaultStateView = () => {
  return {
    isLoading: true,
    isSendingMail: false,
    breadcrumb: [],
    notices: [],
    brandLogo: null,
    billingPlans: [],
    isMobile: false,
    isOnline: true,
    currentSkimmyAvatar: "",
    currentSkimmyRoomId: 0,

    chatSocket: null,
    chatSocketEventListners: [],
    growupParicleContainer: null,
  };
};

export default {
  namespaced: true,
  state: getDefaultStateView(),
  getters: {
    socket: (state) => {
      return state.chatSocket;
    },
    socketEventListners: (state) => {
      return state.chatSocketEventListners;
    },
    isMobile: (state) => {
      console.debug("isMobile", state.isMobile);
      return state.isMobile;
    },
    isOnline: (state) => {
      return state.isOnline;
    },
    currentSkimmyAvatar: (state) => {
      return state.currentSkimmyAvatar;
    },
    currentSkimmyRoomId: (state) => {
      return state.currentSkimmyRoomId;
    },
  },
  mutations: {
    loading(state) {
      state.isLoading = true;
    },
    loaded(state) {
      state.isLoading = false;
    },
    sendingMail(state) {
      state.isSendingMail = true;
    },
    sentMail(state) {
      state.isSendingMail = false;
    },
    setBrandLogo(state, brandLogo) {
      if (state.brandLogo == null) {
        console.debug("set brandLog:", brandLogo);
        state.brandLogo = brandLogo;
      }
    },
    storeBillingPlans(state, data) {
      state.billingPlans = data;
    },
    playBrandLogo(state) {
      console.debug("try play brand logo");
      if (state.brandLogo != null) {
        console.debug("animation brand logo");
        state.brandLogo.stop();
        state.brandLogo.play();
      }
    },
    itsMobile(state) {
      state.isMobile = true;
    },
    itsNotMobile(state) {
      state.isMobile = false;
    },
    online(state) {
      state.isOnline = true;
    },
    offline(state) {
      state.isOnline = false;
    },
    setChatSocket(state, socket) {
      state.chatSocket = socket;
    },
    closeChatSocket(state) {
      if (state.chatSocket) {
        state.chatSocket.close();
        state.chatSocket = null;
      }
    },
    addSocketEventListner(state, listner) {
      state.chatSocketEventListners.push(listner);
    },
    setCurrentSkimmyAvatar(state, avatar) {
      state.currentSkimmyAvatar = avatar;
    },

    setCurrentSkimmyRoomId(state, id) {
      state.currentSkimmyRoomId = id;
    },
    setGrowupParicleContainer(state, container) {
      state.growupParicleContainer = container;
    },
    playGrowupParticle(state) {
      if (state.growupParicleContainer) {
        state.growupParicleContainer.refresh();
        state.growupParicleContainer.play(true);
      }
    },
  },
  actions: {
    loading(context) {
      context.commit("loading");
    },
    loaded(context) {
      context.commit("loaded");
    },
    sendingMail(context) {
      context.commit("sendingMail");
    },
    sentMail(context) {
      context.commit("sentMail");
    },
    setBrandLogo(context, brandLogo) {
      context.commit("setBrandLogo", brandLogo);
    },
    storeBillingPlans(context, data) {
      context.commit("storeBillingPlans", data);
    },
    playBrandLogo(context) {
      context.commit("playBrandLogo");
    },
    itsMobile(context) {
      context.commit("itsMobile");
    },
    itsNotMobile(context) {
      context.commit("itsNotMobile");
    },
    online(context) {
      context.commit("online");
    },
    offline(context) {
      context.commit("offline");
    },
    setChatSocket(context, socket) {
      context.commit("setChatSocket", socket);
    },
    closeChatSocket(context) {
      context.commit("closeChatSocket");
    },
    addSocketEventListner(context, listner) {
      context.commit("addSocketEventListner", listner);
    },
    setCurrentSkimmyAvatar(context, avatar) {
      context.commit("setCurrentSkimmyAvatar", avatar);
    },
    setCurrentSkimmyRoomId(context, id) {
      context.commit("setCurrentSkimmyRoomId", id);
    },
    setGrowupParicleContainer(context, container) {
      context.commit("setGrowupParicleContainer", container);
    },
    playGrowupParticle(context) {
      context.commit("playGrowupParticle");
    },
  },
};
