import { User } from "@/client";
import store from "@/store";

const SocketUtils = {
  onOpenEvent(event: Event) {
    console.debug(event);
    console.debug("Successfully connected to the echo websocket server...");
    // this.heatbeat();
  },

  //   heatbeat() {
  //     if (!this.socket || this.socket.readyState !== WebSocket.OPEN) return;
  //     this.socket.send("heatbeat");
  //     setTimeout(this.heatbeat, 10 * 1000);
  //   },

  addMessageEvent(msgEventFn: (e: MessageEvent<string>) => void) {
    store.dispatch("view/addSocketEventListner", msgEventFn);
    const socket = store.getters["view/socket"];
    if (socket) {
      socket.addEventListener("message", msgEventFn);
    }
  },

  readdMessageEvent() {
    const socket = store.getters["view/socket"];
    if (socket) {
      const listners = store.getters["view/socketEventListners"];
      for (let i = 0; i < listners.length; i++) {
        const listner = listners[i];
        socket.addEventListener("message", listner);
      }
    }
  },

  disconnect() {
    console.debug("Close websocket.");
    store.dispatch("view/closeChatSocket");
  },

  loginEvent(e: MessageEvent<string>) {
    // console.debug("core_MessageEvent", e);
    const message = JSON.parse(e.data);
    console.debug("core_MessageEvent", message);
    if (message && message.system && message.content) {
      const systemNotice = JSON.parse(message.content);
      if (systemNotice.title === "login") {
        console.debug("LoginEvent", e);
        store.state.user.loginModalShow = true;
      }
    }

    // const roomId = store.getters["view/currentSkimmyRoomId"] as number;
    // if (roomId > 0) {
    //   console.debug("login award");
    //   // const modal = this.$refs["login-modal"] as BModal;
    //   // modal.show();
    //   this.loginModalShow = true;
    // }
  },

  connect() {
    try {
      const me = store.getters["user/me"]() as User;
      const token = store.getters["user/token"]() as string;
      const url = `${process.env.VUE_APP_WS_URL}/api/ws/skimie/${me.username}/chat/rooms?token=${token}`;

      if (store.getters["view/socket"]) {
        return;
      }
      const socket = new WebSocket(url);
      store.dispatch("view/setChatSocket", socket);
      socket.addEventListener("message", this.loginEvent);
      socket.addEventListener("open", this.onOpenEvent);
      socket.addEventListener("close", () => {
        store.dispatch("view/closeChatSocket");
        if (store.state.user.isLoggedIn) {
          console.debug("Reconnect websocket");
          this.disconnect();
          setTimeout(() => {
            this.connect();
            this.readdMessageEvent();
          }, 3000);
        }
      });
      window.addEventListener("beforeunload", this.disconnect);
    } catch (e) {
      console.debug("Failed websocket", e);
      this.disconnect();
      setTimeout(() => {
        this.connect();
        this.readdMessageEvent();
      }, 3000);
    }
  },
};

export default SocketUtils;
