export const getDefaultStateMission = () => {
  return {
    unearnedDaily: 0,
    maxDaily: 0,
    fixedMissions: [],
    randomMissions: [],
    maxMission: {},
    todayMissionRecord: {
      num_missions_cleared: 0,
      num_missions: 0,
      mission_clear_rate: 0,
    },
    personalUnearnedDaily: 0,
    personalMaxDaily: 0,
    personalFixedMissions: [],
    personalRandomMissions: [],
    personalMaxMission: {},
    personalTodayMissionRecord: {
      num_missions_cleared: 0,
      num_missions: 0,
      mission_clear_rate: 0,
    },
  };
};

export default {
  namespaced: true,
  state: getDefaultStateMission(),
  mutations: {
    drop(state) {
      state.unearnedDaily = 0;
      state.maxDaily = 0;
      state.fixedMissions = [];
      state.randomMissions = [];
      state.maxMission = {};
      state.todayMissionRecord = {
        num_missions_cleared: 0,
        num_missions: 0,
        mission_clear_rate: 0,
      };
      state.personalUnearnedDaily = 0;
      state.personalMaxDaily = 0;
      state.personalFixedMissions = [];
      state.personalRandomMissions = [];
      state.personalMaxMission = {};
      state.personalTodayMissionRecord = {
        num_missions_cleared: 0,
        num_missions: 0,
        mission_clear_rate: 0,
      };
    },
    store(state, data) {
      state.maxDaily = data.mission_records.today.num_missions;
      state.unearnedDaily = data.mission_records.today.num_missions - data.mission_records.today.num_missions_cleared;
      state.todayMissionRecord = data.mission_records.today;
      state.fixedMissions = data.missions.fixed_missions;
      state.randomMissions = data.missions.random_missions;
      state.maxMission = data.missions.max_mission;

      state.personalMaxDaily = data.personal_mission_records.today.num_missions;
      state.personalUnearnedDaily =
        data.personal_mission_records.today.num_missions - data.personal_mission_records.today.num_missions_cleared;
      state.personalTodayMissionRecord = data.personal_mission_records.today;
      state.personalFixedMissions = data.personal_missions.fixed_missions;
      state.personalRandomMissions = data.personal_missions.random_missions;
      state.personalMaxMission = data.personal_missions.max_mission;
    },
  },
  actions: {
    drop(context) {
      context.commit("drop");
    },
    store(context, data) {
      context.commit("store", data);
    },
  },
};
