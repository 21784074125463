export const getDefaultStatePayjp = () => {
  return {
    isLoading: false,
    token: "",
    cards: [],
    hasMore: false,
  };
};

export default {
  namespaced: true,
  state: getDefaultStatePayjp(),
  mutations: {
    storeToken(state, token) {
      state.token = token;
    },
    storeCards(state, data) {
      state.cards = data.cards;
      state.hasMore = data.hasMore;
    },
    loading(state) {
      state.isLoading = true;
    },
    loaded(state) {
      state.isLoading = false;
    },
  },
  actions: {
    storeToken(context, token) {
      context.commit("storeToken", token);
    },
    storeCards(context, data) {
      context.commit("storeCards", data);
    },
    loading(context) {
      context.commit("loading");
    },
    loaded(context) {
      context.commit("loaded");
    },
  },
};
