export const getDefaultStateWiki = () => {
  return {
    private: {
      totalCount: 0,
      offset: 0,
      limit: 0,
      wikis: [],
    },

    public: {
      totalCount: 0,
      offset: 0,
      limit: 0,
      wikis: [],
    },

    all: {
      totalCount: 0,
      offset: 0,
      limit: 0,
      wikis: [],
    },

    current: {},
  };
};

export default {
  namespaced: true,
  state: getDefaultStateWiki(),
  mutations: {
    storeCurrent(state, data) {
      state.current = data;
    },
    storePrivateWikis(state, data) {
      state.private.wikis = data.issues;
      state.private.totalCount = data.total_count;
      state.private.offset = data.offset;
      state.private.limit = data.limit;
    },
    storePublicWikis(state, data) {
      state.public.wikis = data.issues;
      state.public.totalCount = data.total_count;
      state.public.offset = data.offset;
      state.public.limit = data.limit;
    },
    storeAllWikis(state, data) {
      state.all.wikis = data.issues;
      state.all.totalCount = data.total_count;
      state.all.offset = data.offset;
      state.all.limit = data.limit;
    },
    drop(state) {
      state.private = {
        totalCount: 0,
        offset: 0,
        limit: 0,
        wikis: [],
      };

      state.public = {
        totalCount: 0,
        offset: 0,
        limit: 0,
        wikis: [],
      };

      state.all = {
        totalCount: 0,
        offset: 0,
        limit: 0,
        wikis: [],
      };
    },
  },
  actions: {
    store(context, data) {
      context.commit("store", data);
    },
    storePrivateWikis(context, data) {
      context.commit("storePrivateWikis", data);
    },
    storePublicWikis(context, data) {
      context.commit("storePublicWikis", data);
    },
    storeAllWikis(context, data) {
      context.commit("storeAllWikis", data);
    },
    storeCurrent(context, data) {
      context.commit("storeCurrent", data);
    },
    drop(context) {
      context.commit("drop");
    },
  },
};
