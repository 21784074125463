export const getDefaultStateTeam = () => {
  return {
    isLoadingTeams: false,
    isLoadingInvite: false,
    isLoadingCurrentMember: false,
    teams: [],
    currentTeam: {},
    teamShareSkills: [],
    skills: [],
    currentMember: {},
    projects: [],
    currentProject: {},
    currentProjectMembers: [],
    teamWikiCategories: [],
    integrations: [],
  };
};

export default {
  namespaced: true,
  state: getDefaultStateTeam(),
  getters: {
    currentTeam: (state) => {
      if (state.currentTeam) {
        return state.currentTeam;
      }
      return {};
    },
    currentTeamName: (state) => {
      if (state.currentTeam) {
        return state.currentTeam.name;
      }
      return "";
    },
    teams: (state) => {
      return state.teams;
    },
    currentMembers: (state) => {
      return state.currentTeam.members;
    },
    totalMembers: (state) => {
      if (Object.keys(state.currentTeam).includes("members")) {
        return state.currentTeam.members.length;
      } else {
        return 0;
      }
    },
    totalProjectMembers: (state) => {
      return state.currentProjectMembers.length;
    },
    isManager: (state) => (username) => {
      if (Object.keys(state.currentTeam).includes("members")) {
        for (let i = 0; i < state.currentTeam.members.length; i++) {
          const member = state.currentTeam.members[i];
          if (
            member.user.username === username &&
            (member.teamRoleCode === "owner" || member.teamRoleCode === "manager")
          ) {
            return true;
          }
        }
      }
      return false;
    },
  },
  mutations: {
    drop(state) {
      state.isLoadingTeams = false;
      state.isLoadingInvite = false;
      state.isLoadingCurrentMember = false;
      state.teams = [];
      state.currentTeam = {};
      state.teamShareSkills = [];
      state.skills = [];
      state.currentMember = {};
      state.projects = [];
      state.currentProject = {};
      state.currentProjectMembers = [];
      state.teamWikiCategories = [];
      state.integrations = [];
    },
    storeTeams(state, teams) {
      state.teams = teams;
    },
    storeCurrentTeam(state, team) {
      state.currentTeam = team;
    },
    storeCurrentMember(state, data) {
      state.currentMember = data;
    },
    storeProjects(state, data) {
      state.projects = data;
    },
    storeCurrentProject(state, data) {
      state.currentProject = data;
    },
    storeCurrentProjectMembers(state, data) {
      state.currentProjectMembers = data;
    },
    storeTeamWikiCategories(state, data) {
      state.teamWikiCategories = data.categories;
    },
    storeIntegrations(state, data) {
      state.integrations = data;
      console.debug("store integrations");
    },
    addTeamMembers(state, data) {
      data.forEach((member) => {
        state.currentTeam.members.push(member);
      });
      console.debug("added team members:", state.currentTeam.members);
    },
    setTeamMember(state, data) {
      if (state.currentTeam && state.currentTeam.members) {
        for (let index = 0; index < state.currentTeam.members.length; index++) {
          const member = state.currentTeam.members[index];
          if (member.user.username === data.user.username) {
            console.debug("set member:", data);
            state.currentTeam.members[index] = data;
            break;
          }
        }
      }
    },
    storeSkills(state, data) {
      state.skills = data;
      for (let i = 0; i < data.length; i++) {
        for (let j = 0; j < data[i].shareTeams.length; j++) {
          const shareTeam = data[i].shareTeams[j];
          const sTeamId = shareTeam.team.id.toString();
          const sSkillId = shareTeam.skill.id.toString();
          console.debug("sTeamId: " + sTeamId + ", sSkillId: " + sSkillId);
          if (!Object.keys(state.teamShareSkills).includes(sTeamId)) {
            state.teamShareSkills[sTeamId] = [];
          }
          state.teamShareSkills[sTeamId][sSkillId] = shareTeam.isShare;
        }
      }
    },
    loadingTeams(state) {
      state.isLoadingTeams = true;
    },
    loadedTeams(state) {
      state.isLoadingTeams = false;
    },
    loadingCurrentMember(state) {
      state.isLoadingCurrentMember = true;
    },
    loadedCurrentMember(state) {
      state.isLoadingCurrentMember = false;
    },
    loadingInvite(state) {
      state.isLoadingInvite = true;
    },
    loadedInvite(state) {
      state.isLoadingInvite = false;
    },
  },
  actions: {
    drop(context) {
      context.commit("drop");
    },
    storeTeams(context, teams) {
      context.commit("storeTeams", teams);
    },
    storeCurrentTeam(context, team) {
      context.commit("storeCurrentTeam", team);
    },
    storeCurrentMember(context, data) {
      context.commit("storeCurrentMember", data);
    },
    addTeamMembers(context, data) {
      context.commit("addTeamMembers", data);
    },
    setTeamMember(context, data) {
      context.commit("setTeamMember", data);
    },
    storeSkills(context, data) {
      context.commit("storeSkills", data);
    },
    storeProjects(context, data) {
      context.commit("storeProjects", data);
    },
    storeCurrentProject(context, data) {
      context.commit("storeCurrentProject", data);
    },
    storeCurrentProjectMembers(context, data) {
      context.commit("storeCurrentProjectMembers", data);
    },
    storeTeamWikiCategories(context, data) {
      context.commit("storeTeamWikiCategories", data);
    },
    storeIntegrations(context, data) {
      context.commit("storeIntegrations", data);
    },
    loadingTeams(context) {
      context.commit("loadingTeams");
    },
    loadedTeams(context) {
      context.commit("loadedTeams");
    },
    loadingCurrentMember(context) {
      context.commit("loadingCurrentMember");
    },
    loadedCurrentMember(context) {
      context.commit("loadedCurrentMember");
    },
    loadingInvite(context) {
      context.commit("loadingInvite");
    },
    loadedInvite(context) {
      context.commit("loadedInvite");
    },
  },
};
