export const getDefaultStatePortfolio = () => {
  return {
    isLoading: false,
    values: [],
    current: {},
    myportfolios: [],
    artworks: [],
    sharedPortfolios: [],
    approvePortfolios: [],
    portfolioAssignments: [],
    formPortfolio: {},
    artworksTotalCount: 0,
    myportfoliosTotalCount: 0,
  };
};

export default {
  namespaced: true,
  state: getDefaultStatePortfolio(),
  getters: {
    formPortfolio: (state) => {
      return state.formPortfolio;
    },
  },
  mutations: {
    store(state, data) {
      state.values = data;
    },
    storeCurrent(state, data) {
      state.current = data;
    },
    storeArtworks(state, data) {
      state.artworks = data.artworks;
      state.artworksTotalCount = data.total_count;
    },
    storeMyportfolios(state, data) {
      state.myportfolios = data.portfolios;
      state.myportfoliosTotalCount = data.total_count;
    },
    storeSharedportfolios(state, data) {
      state.sharedportfolios = data.portfolios;
      // state.myportfoliosTotalCount = data.total_count;
    },
    storeApproveportfolios(state, data) {
      state.approveportfolios = data.portfolios;
      // state.myportfoliosTotalCount = data.total_count;
    },
    storeFormPortfolio(state, data) {
      state.formPortfolio = data;
    },
    drop(state) {
      state.values = [];
    },
    dropCurrent(state) {
      state.current = {};
    },
    loading(state) {
      state.isLoading = true;
    },
    loaded(state) {
      state.isLoading = false;
    },
  },
  actions: {
    store(context, data) {
      context.commit("store", data);
    },
    storeCurrent(context, data) {
      context.commit("storeCurrent", data);
    },
    storeArtworks(context, data) {
      context.commit("storeArtworks", data);
    },
    storeMyportfolios(context, data) {
      context.commit("storeMyportfolios", data);
    },
    storeSharedportfolios(context, data) {
      context.commit("storeSharedportfolios", data);
    },
    storeApproveportfolios(context, data) {
      context.commit("storeApproveportfolios", data);
    },
    storeFormPortfolio(context, data) {
      context.commit("storeFormPortfolio", data);
    },
    drop(context) {
      context.commit("dropCurrent");
      context.commit("drop");
    },
    dropCurrent(context) {
      context.commit("dropCurrent");
    },
    loading(context) {
      context.commit("loading");
    },
    loaded(context) {
      context.commit("loaded");
    },
  },
};
