
import { defineComponent } from "@vue/composition-api";
export default defineComponent({
  components: {
    SkHeader: () => import(/* webpackChunkName: "header" */ "@/components/SkHeader.vue"),
    SkFooter: () => import(/* webpackChunkName: "footer" */ "@/components/SkFooter.vue"),
    SkAlert: () => import(/* webpackChunkName: "alert" */ "@/components/SkAlert.vue"),
    SkSidebar: () => import(/* webpackChunkName: "sidebar" */ "@/components/SkSidebar.vue"),
    SkMainSpinner: () => import(/* webpackChunkName: "spinner" */ "@/components/SkMainSpinner.vue"),
    SkOnlineState: () => import(/* webpackChunkName: "online" */ "@/components/utils/SkOnlineState.vue"),
    SkSystemNotice: () => import(/* webpackChunkName: "online" */ "@/components/utils/SkSystemNotice.vue"),
  },
  computed: {
    hasTutorial(): boolean {
      return this.$route.query.tutorial ? true : false;
    },
    isEmbed(): boolean {
      if (0 === this.$route.path.indexOf("/embed/")) {
        console.debug("isEmbed", this.$route.path);
        return true;
      }
      console.debug("isNotEmbed", this.$route.path);
      return false;
    },
    isNotEmbed(): boolean {
      return !this.isEmbed;
    },
    isPublicProfile(): boolean {
      if (
        (0 === this.$route.path.indexOf("/u/") && this.$route.params.username && this.$route.params.username != "") ||
        0 === this.$route.path.indexOf("/publicprofile/search")
      ) {
        console.debug("isPublicProfile", this.$route.path);
        return true;
      }
      console.debug("isNotPublicProfile", this.$route.path);
      return false;
    },
  },
  mounted() {
    this.$root.$on("playAudio", (audioPlayerType: string) => {
      const audioPlayerId = audioPlayerType + "_audio_player";
      this.playAudio(audioPlayerId);
    });
  },
  methods: {
    playAudio(audioPlayerId: string) {
      const audioEl = document.getElementById(audioPlayerId) as HTMLAudioElement;
      audioEl.play();
    },
  },
});
