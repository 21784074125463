import axios from "axios";
import store from "@/store";
import { NoticeApi } from "@/client";
import { MissionApi } from "@/client";
import { apiConfig } from "@/mixins";
import { refreshIdToken } from "./auth";

const baseAxios = axios.create({
  baseURL: process.env.VUE_APP_API_URL + "/api/v1",
});
const timeout = 1000 * 60 * 3;
const responseIllegalError = { status: 404, data: { message: "予期せぬエラー" } };

const api = {
  mixins: {
    methods: {
      getAuthHeader() {
        return { Authorization: "Bearer " + store.state.user.token };
      },
      async apiGet(relativePath, data = {}) {
        console.debug("API V1 - GET:", relativePath);
        const retry = 2;
        let resp;
        for (let i = 0; i < retry; i++) {
          resp = await baseAxios
            .get(relativePath, {
              headers: this.getAuthHeader(),
              data: data,
              timeout: timeout,
            })
            .catch((error) => {
              if (error?.response) {
                return error.response;
              } else {
                return { status: 500 };
              }
            });
          if (resp?.status === 401) {
            await refreshIdToken();
            console.debug("Retry API V1 - GET:", relativePath, i + 1 + "/" + retry);
            continue;
          }
          break;
        }

        return resp;
      },
      async apiPost(relativePath, data = {}) {
        console.debug("API V1 - POST:", relativePath);
        const retry = 2;
        let resp;
        for (let i = 0; i < retry; i++) {
          resp = await baseAxios({
            method: "POST",
            url: relativePath,
            dataType: "json",
            params: {},
            data: data,
            headers: this.getAuthHeader(),
            timeout: timeout,
          }).catch((error) => {
            if (error?.response) {
              return error.response;
            } else {
              return { status: 500 };
            }
          });
          if (resp?.status === 401) {
            await refreshIdToken();
            console.debug("Retry API V1 - POST:", relativePath, i + 1 + "/" + retry);
            continue;
          }
          break;
        }

        return resp;
      },
      async apiDelete(relativePath, data = {}) {
        let ret = responseIllegalError;
        await baseAxios({
          method: "DELETE",
          url: relativePath,
          dataType: "json",
          params: {},
          data: data,
          headers: this.getAuthHeader(),
          timeout: timeout,
        })
          .then((response) => {
            ret = { status: response.status, data: response.data };
          })
          .catch((error) => {
            if (error.response) {
              ret = { status: error.response.status, data: error.response.data };
            }
          });
        return ret;
      },
      async apiUpload(relativePath, formData = {}) {
        let ret = responseIllegalError;
        await baseAxios({
          method: "POST",
          url: relativePath,
          params: {},
          data: formData,
          headers: {
            Authorization: "Bearer " + store.state.user.token,
            "Content-Type": "multipart/form-data",
          },
          timeout: timeout,
        })
          .then((response) => {
            ret = { status: response.status, data: response.data };
          })
          .catch((error) => {
            if (error.response) {
              ret = { status: error.response.status, data: error.response.data };
            }
          });
        return ret;
      },
      async apiAcceptTeam(data) {
        return await this.apiPost("/teams/accept", data);
      },
      async apiFetchSkills(skillTypes) {
        const skills = {};
        let response = responseIllegalError;
        for (let i = 0; i < skillTypes.length; i++) {
          const skillType = skillTypes[i];
          response = await this.apiGet("/skills/" + store.state.user.uid + "/" + skillType);
          if (response.status === 200) {
            skills[skillType] = response.data;
          } else {
            return response;
          }
        }
        store.dispatch("skill/storeSkills", skills);
        return response;
      },
      async fetchSkills() {
        store.dispatch("team/loadingTeams");
        const response = await this.apiGet("/skills/" + store.state.user.uid);
        if (200 == response.status) {
          store.dispatch("team/storeSkills", response.data);
        }
        store.dispatch("team/loadedTeams");
      },
      async fetchSkillsByType(skillType) {
        store.dispatch("skill/loading");
        const response = await this.apiGet("/skills/" + store.state.user.uid + "/" + skillType);
        if (200 == response.status) {
          store.dispatch("skill/storeSkillsByType", { skillType: skillType, skills: response.data });
        }
        store.dispatch("skill/loaded");
      },
      async addSkill(skillType) {
        store.dispatch("skill/loading");
        const response = await this.apiPost("/skills", {
          user: {
            uid: store.state.user.uid,
          },
          skill_type: {
            type: skillType,
          },
        });
        if (200 == response.status) {
          store.dispatch("skill/addSkillsByType", { skillType: skillType, skill: response.data });
        }
        store.dispatch("skill/loaded");
      },
      async apiGetPortfolis(username) {
        store.dispatch("portfolio/loading");
        const response = await this.apiGet("/portfolios/" + username);
        if (response.status === 200) {
          store.dispatch("portfolio/store", response.data);
        }
        store.dispatch("portfolio/loaded");
        return response;
      },
      async apiSetPortfolio(portfolio) {
        const response = await this.apiPost("/portfolios/myportfolio", portfolio);
        if (response.status === 200) {
          this.$root.$emit("getUnreadNotices");
        }
        return response;
      },
      async apiGetMyportfolios(offset, limit, options) {
        let url = "/portfolios" + "?offset=" + offset + "&limit=" + limit;
        if (options) {
          if (options.template_type) {
            url += "&template_type=" + options.template_type;
          }
          if (options.sort) {
            url += "&sort=" + options.sort;
          }
        }

        const response = await this.apiGet(url);
        if (response.status === 200) {
          store.dispatch("portfolio/storeMyportfolios", response.data);
        }
        return response;
      },
      async apiGetMyportfolio(username, portfolioId) {
        const url = "/portfolios/" + username + "/" + portfolioId;
        const response = await this.apiGet(url);
        return response;
      },
      async apiGetSharedportfolios(offset, limit, options) {
        let url = "/portfolios/shared" + "?offset=" + offset + "&limit=" + limit;
        if (options) {
          if (options.template_type) {
            url += "&template_type=" + options.template_type;
          }
          if (options.creator) {
            url += "&creator=" + options.creator;
          }
          if (options.share_team) {
            url += "&share_team=" + options.share_team;
          }
          if (options.share_scope) {
            url += "&share_scope=" + options.share_scope;
          }
          if (options.sort) {
            url += "&sort=" + options.sort;
          }
        }

        const response = await this.apiGet(url);
        if (response.status === 200) {
          store.dispatch("portfolio/storeSharedportfolios", response.data);
        }
        return response;
      },
      async apiGetApproveportfolios(offset, limit, options) {
        let url = "/portfolios/approve" + "?offset=" + offset + "&limit=" + limit;
        if (options) {
          if (options.template_type) {
            url += "&template_type=" + options.template_type;
          }
          if (options.creator) {
            url += "&creator=" + options.creator;
          }
          if (options.share_team) {
            url += "&share_team=" + options.share_team;
          }
          if (options.share_scope) {
            url += "&share_scope=" + options.share_scope;
          }
          if (options.sort) {
            url += "&sort=" + options.sort;
          }
        }

        const response = await this.apiGet(url);
        if (response.status === 200) {
          store.dispatch("portfolio/storeApproveportfolios", response.data);
        }
        return response;
      },
      async apiApprovePortfolio(portfolioId) {
        const url = "/portfolios/approve/" + portfolioId;
        const response = await this.apiPost(url);
        return response;
      },
      async apiRescindPortfolioApproval(portfolioId) {
        const url = "/portfolios/rescindapproval/" + portfolioId;
        const response = await this.apiPost(url);
        return response;
      },
      async apiSetPortfolioLike(username, portfolioId, data) {
        const response = await this.apiPost("/portfolios/" + username + "/" + portfolioId + "/" + "like", data);
        return response;
      },
      async getSkill(skillType, order) {
        store.dispatch("skill/loadingSkill");
        store.dispatch("skill/storeCurrentSkillSet", {});
        const response = await this.apiGet("/skills/" + store.state.user.uid + "/" + skillType + "/" + order);
        if (200 == response.status) {
          store.dispatch("skill/storeCurrentSkillSet", response.data);
        } else if (204 == response.status && parseInt(order) == 1) {
          const response2 = await this.apiPost("/skills", {
            user: {
              uid: store.state.user.uid,
            },
            skill_type: {
              type: skillType,
            },
          });
          if (200 == response2.status) {
            store.dispatch("skill/storeCurrentSkillSet", response2.data);
          }
          store.dispatch("skill/loadedSkill");
          return response2;
        }
        store.dispatch("skill/loadedSkill");
        return response;
      },
      async apiGetMemberQualif(teamname, username, type, order, offset, limit) {
        store.dispatch("skill/storeCurrentQualifs", { qualifs: [], totalCount: 0, offset: 0, limit: 0 });
        store.dispatch("skill/loadingQualif");
        const response = await this.apiGet(
          "/teams/" +
            teamname +
            "/" +
            username +
            "/" +
            type +
            "/" +
            order +
            "?offset=" +
            offset +
            "&limit=" +
            limit +
            "&isOnlyQualif=" +
            true
        );
        if (response.status === 200) {
          store.dispatch("skill/storeCurrentQualifs", response.data);
        }
        store.dispatch("skill/loadedQualif");
        return response;
      },
      async getMemberSkill(teamname, username, type, order) {
        store.dispatch("skill/loadingSkill");
        const response = await this.apiGet("/teams/" + teamname + "/" + username + "/" + type + "/" + order);
        if (200 == response.status) {
          store.dispatch("skill/storeCurrentSkillSet", response.data);
        }
        store.dispatch("skill/loadedSkill");
      },
      async getMemberSkillDetail(teamname, username, type, order, detailOrder) {
        const url = "/teams/" + teamname + "/" + username + "/" + type + "/" + order + "/" + detailOrder;
        return await this.apiGet(url);
      },
      async setSkill(skillType, order) {
        store.dispatch("skill/loadingSkill");
        const response = await this.apiPost(
          "/skills/" + store.state.user.uid + "/" + skillType + "/" + order,
          store.state.skill.currentSkillSet
        );
        if (200 == response.status) {
          store.dispatch("skill/storeCurrentSkillSet", response.data);
          this.$root.$emit("getUnreadNotices");
          // const request = {
          //   code: "AddSkills",
          //   period: 1,
          // };
          // await new MissionApi(apiConfig()).setClearedAlertMissionStatus("me", request);
        }
        store.dispatch("skill/loadedSkill");
        return response;
      },
      async setMissionAccomplished(code, type, emit) {
        const request = {
          code: code,
          mission_type: type,
        };
        const response = await new MissionApi(apiConfig()).setDailyMissionAccomplished(request);
        if (response.status === 200) {
          this.$root.$emit("getUnreadNotices");
          if (emit) {
            this.$root.$emit("dailyMissionSetAccomplished");
          }
          await this.$store.dispatch("user/me");
        }
      },
      async exportExcel(username, skillType, order) {
        store.dispatch("skill/loadingExport");
        let ret = null;
        const response = await this.apiGet("/export/excel/" + username + "/" + skillType + "/" + order);
        if (200 == response.status) {
          ret = response.data.url;
        }
        store.dispatch("skill/loadedExport");
        return ret;
      },
      async getMyProfile() {
        let ret = 200;
        store.dispatch("user/loadingMyProfile");
        const response = await this.apiGet("/accounts/profile/" + store.state.user.uid);
        if (200 == response.status) {
          store.dispatch("user/storeMyProfile", response.data);
        } else if (204 == response.status) {
          await this.apiPost("/users", { uid: store.state.user.uid });
        }
        store.dispatch("user/loadedMyProfile");
        return ret;
      },
      async setMyProfile(avatarURL = null) {
        store.dispatch("user/loadingMyProfile");
        if (avatarURL) {
          store.state.user.myProfile.avatarURL = avatarURL;
        }
        const response = await this.apiPost("/accounts/profile/" + store.state.user.uid, store.state.user.myProfile);
        if (200 == response.status) {
          store.dispatch("user/storeMyProfile", response.data);
          store.dispatch("alert/showInfo", "更新しました！");
          this.$root.$emit("getUnreadNotices");
        } else {
          store.dispatch("alert/showError", "更新に失敗しました。しばらく経ってから、再度お試しください。");
        }
        store.dispatch("user/loadedMyProfile");
        return response;
      },
      async getTeam(teamname) {
        let ret = 200;
        store.dispatch("team/loadingTeams");
        const response = await this.apiGet("/teams/" + teamname);
        if (200 == response.status) {
          store.dispatch("team/storeCurrentTeam", response.data);
        }
        console.debug("getTeam response: " + response.status);
        store.dispatch("team/loadedTeams");
        ret = response.status;
        return ret;
      },
      async submitBugReport(subject, body) {
        store.dispatch("view/sendingMail");
        const response = await this.apiPost("/sendmail/bugreport", {
          subject: "バグ報告: " + subject,
          body: body,
        });
        if (200 == response.status) {
          store.dispatch("view/sentMail");
        }
      },
      async submitContactUs(subject, body) {
        store.dispatch("view/sendingMail");
        const response = await this.apiPost("/sendmail/contactus", {
          subject: "お問い合わせ: " + subject,
          body: body,
        });
        if (200 == response.status) {
          store.dispatch("view/sentMail");
        }
      },
      async submitLpContactUs(inquiryForm) {
        let ret = 200;
        store.dispatch("view/sendingMail");
        const response = await this.apiPost("/sendmail/lpcontactus", {
          subject: "お問い合わせ: " + inquiryForm.subject,
          body: inquiryForm.body,
          inquirerName: inquiryForm.inquirerName,
          companyName: inquiryForm.companyName,
          telNumber: inquiryForm.telNumber,
          inquirerEmail: inquiryForm.inquirerEmail,
        });
        if (200 == response.status) {
          store.dispatch("view/sentMail");
        }
        ret = response.status;
        return ret;
      },
      async addCard(token, accountName) {
        store.dispatch("payjp/loading");
        const response = await this.apiPost("/payjp/addcard", {
          token: token,
          accountName: accountName,
        });
        if (200 == response.status) {
          store.dispatch("payjp/loaded");
        }
      },
      async getCards() {
        let ret = 200;
        store.dispatch("payjp/loading");
        const response = await this.apiGet("/payjp");
        if (200 == response.status) {
          store.dispatch("payjp/storeCards", response.data);
        }
        store.dispatch("payjp/loaded");
        ret = response.status;
        return ret;
      },
      async setTeamShareSkill(teamId, skillId, isShare) {
        const response = await this.apiPost("/accounts/share/" + store.state.user.uid, {
          team: { id: teamId },
          skill: { id: skillId },
          isShare: isShare,
        });
        if (200 == response.status) {
          store.dispatch("alert/showInfo", "更新しました！");
          this.$root.$emit("getUnreadNotices");
        } else {
          store.dispatch("alert/showError", "更新に失敗しました");
        }
      },
      async addTeam(teamname, name) {
        store.dispatch("team/loadingTeams");
        const response = await this.apiPost("/teams", {
          teamname: teamname,
          name: name,
          members: [
            {
              user: { username: this.$store.state.user.myProfile.username },
            },
          ],
        });
        if (200 == response.status) {
          store.dispatch("alert/showInfo", "チームを作成しました！");
          this.$root.$emit("getUnreadNotices");
        } else {
          if (response.data.message == "already team owner: parameter error") {
            store.dispatch(
              "alert/showError",
              "チーム作成に失敗しました。既にチームを作成済みです、チーム作成は1アカウント1チームまでです"
            );
          } else {
            store.dispatch("alert/showError", "チーム作成に失敗しました");
          }
        }
        store.dispatch("team/loadedTeams");
        return response.status;
      },
      async apiAddTeamMembers(teamname, usernames) {
        store.dispatch("team/loadingInvite");
        const data = [];
        usernames.forEach((username) => {
          data.push({
            team: { teamname: teamname },
            user: { username: username },
          });
        });

        const response = await this.apiPost("/teams/invite", data);
        if (response.status === 200) {
          console.debug("invited members:", response.data);
          store.dispatch("team/addTeamMembers", response.data);
          this.$root.$emit("getUnreadNotices");
        }
        store.dispatch("team/loadedInvite");
        return response;
      },
      async apiSetMemberRole(teamname, username, roleCode) {
        const data = {
          teamname: teamname,
          username: username,
          roleCode: roleCode,
        };

        const response = await this.apiPost("/teams/role", data);
        if (response.status === 200) {
          // console.debug('invited members:', response.data);
          // store.dispatch('team/addTeamMembers', response.data);
        }
        return response;
      },
      async apiSetMemberDisplayName(teamname, username, displayName) {
        const data = {
          teamname: teamname,
          username: username,
          displayName: displayName,
        };

        const response = await this.apiPost("/teams/displayName", data);
        if (response.status === 200) {
          // console.debug('invited members:', response.data);
          // store.dispatch('team/addTeamMembers', response.data);
          this.$root.$emit("getUnreadNotices");
        }
        return response;
      },
      async apiGetMember(teamname, username) {
        store.dispatch("team/loadingCurrentMember");
        const response = await this.apiGet("/teams/" + teamname + "/" + username);
        if (response.status === 200) {
          store.dispatch("team/storeCurrentMember", response.data);
          this.$root.$emit("getUnreadNotices");
        }
        store.dispatch("team/loadedCurrentMember");
        return response;
      },
      async getTeamMember(teamname, username) {
        let ret = 200;
        store.dispatch("team/loadingCurrentMember");
        const response = await this.apiGet("/teams/" + teamname + "/" + username);
        if (200 == response.status) {
          store.dispatch("team/storeCurrentMember", response.data);
        }
        store.dispatch("team/loadedCurrentMember");
        ret = response.status;
        return ret;
      },
      async setBillingPlan(plan, coupon) {
        const response = await this.apiPost("/accounts/billing", {
          plan: plan,
          coupon: { code: coupon },
        });
        if (200 == response.status) {
          store.dispatch("alert/showInfo", "プランの更新しました！");
          this.$root.$emit("getUnreadNotices");
        } else {
          store.dispatch("alert/showError", "プランの更新に失敗しました。しばらく経ってから、再度お試しください。");
        }
        return response;
      },
      async apiUploadUserAvatar(formData) {
        return await this.apiUpload("/upload/user/avatar", formData);
      },
      async apiUploadUserPublicProfileAvatar(formData) {
        return await this.apiUpload("/upload/user/publicprofile/avatar", formData);
      },
      async apiUploadUserPublicProfileIcon(formData) {
        return await this.apiUpload("/upload/user/publicprofile/icon", formData);
      },
      async apiGetTeamWikis(teamname, offset, limit, options) {
        let url = "/wikis/teams/" + teamname + "?offset=" + offset + "&limit=" + limit;
        let status = "all";
        console.debug(options);
        if (options) {
          if (options.author) {
            url += "&author=" + options.author;
          }
          if (options.category_id) {
            url += "&category_id=" + options.category_id;
          }
          if (options.is_private) {
            url += "&is_private=" + options.is_private;
            if (options.is_private === "false") {
              status = "public";
            } else {
              status = "private";
            }
          }
          if (options.sort) {
            url += "&sort=" + options.sort;
          }
          if (options.search) {
            url += "&search=" + options.search;
          }
        }

        const response = await this.apiGet(url);
        if (response.status === 200) {
          switch (status) {
            case "private":
              store.dispatch("wiki/storePrivateWikis", response.data);
              break;
            case "public":
              store.dispatch("wiki/storePublicWikis", response.data);
              break;
            default:
              store.dispatch("wiki/storeAllWikis", response.data);
              break;
          }
        }
        return response;
      },
      async apiGetTeamWiki(teamname, id) {
        const response = await this.apiGet("/wikis/teams/" + teamname + "/" + id);
        if (response.status === 200) {
          store.dispatch("wiki/storeCurrent", response.data);
        }
        return response;
      },
      async apiGetTeamTasks(teamname, offset, limit, options) {
        let url = "/tasks/teams/" + teamname + "?offset=" + offset + "&limit=" + limit;
        if (options) {
          if (options.assign_to_username) {
            url += "&assign_to_username=" + options.assign_to_username;
          }
          if (options.status_id) {
            url += "&status_id=" + options.status_id;
          }
          if (options.sort) {
            url += "&sort=" + options.sort;
          }
          if (options.category_id) {
            url += "&category_id=" + options.category_id;
          }
          if (options.search) {
            url += "&search=" + options.search;
          }
          if (options.targetDate) {
            url += "&targetDate=" + options.targetDate;
          }
          if (options.metaOptions) {
            url += "&metaOptions=" + options.metaOptions;
          }
        }

        const response = await this.apiGet(url);
        if (response.status === 200) {
          if (options) {
            if (options.status_id == 5) {
              store.dispatch("task/storeClosed", response.data);
            } else if (options.status_id == "*") {
              store.dispatch("task/storeAll", response.data);
            } else {
              store.dispatch("task/store", response.data);
            }
          } else {
            store.dispatch("task/store", response.data);
          }
        }
        return response;
      },
      async apiGetProjectTasks(teamname, projectOrder, offset, limit, options) {
        let url = "/tasks/project/" + teamname + "/" + projectOrder + "?offset=" + offset + "&limit=" + limit;
        if (options) {
          if (options.assign_to_username) {
            url += "&assign_to_username=" + options.assign_to_username;
          }
          if (options.status_id) {
            url += "&status_id=" + options.status_id;
          }
          if (options.category_id) {
            url += "&category_id=" + options.category_id;
          }
          if (options.sort) {
            url += "&sort=" + options.sort;
          }
          if (options.search) {
            url += "&search=" + options.search;
          }
          if (options.metaOptions) {
            url += "&metaOptions=" + options.metaOptions;
          }
          if (options.parentId) {
            url += "&parentId=" + options.parentId;
          }
        }
        const response = await this.apiGet(url);
        return response;
      },
      async apiGetTeamTask(teamname, id) {
        const response = await this.apiGet("/tasks/teams/" + teamname + "/" + id);
        if (response.status === 200) {
          store.dispatch("task/storeCurrent", response.data);
        }
        return response;
      },
      async apiSetTeamTask(teamname, data) {
        const response = await this.apiPost("/tasks/teams/" + teamname, data);
        return response;
      },
      async apiSetTeamWiki(teamname, data) {
        const response = await this.apiPost("/wikis/teams/" + teamname, data);
        return response;
      },
      async apiSetProjectTask(teamname, projectOrder, data) {
        const response = await this.apiPost("/tasks/project/" + teamname + "/" + projectOrder, data);
        return response;
      },
      async apiAddTeamTaskComment(teamname, data) {
        const response = await this.apiPost("/tasks/teams/" + teamname + "/comments", data);
        return response;
      },
      async apiAddTeamWikiComment(teamname, data) {
        const response = await this.apiPost("/wikis/teams/" + teamname + "/comments", data);
        return response;
      },
      async apiGetUnreadNotices() {
        const response = await new NoticeApi(apiConfig()).findUserNotices("me", undefined, undefined, 0);
        if (response.status === 200) {
          store.dispatch("user/storeNotices", response.data.notices);
        }
        return response;
      },
      async apiSetReadNotice(data) {
        const response = await new NoticeApi(apiConfig()).setUserNoticeRead("me", data);
        return response;
      },
      async apiFindProjects(teamname, offset, limit) {
        const response = await this.apiGet("/projects/subteam/" + teamname + "?offset=" + offset + "&limit=" + limit);
        if (response.status === 200) {
          store.dispatch("team/storeProjects", response.data.projects);
        }
        return response;
      },
      async apiGetProject(teamname, projectId) {
        const response = await this.apiGet("/projects/subteam/" + teamname + "/" + projectId);
        if (response.status === 200) {
          store.dispatch("team/storeCurrentProject", response.data);
        }
        return response;
      },
      async apiFindProjectMembers(teamname, projectOrder, offset, limit) {
        const response = await this.apiGet(
          "/projects/subteam/" + teamname + "/" + projectOrder + "/members" + "?offset=" + offset + "&limit=" + limit
        );
        if (response.status === 200) {
          store.dispatch("team/storeCurrentProjectMembers", response.data.memberships);
        }
        return response;
      },
      async apiAddProjectMembers(teamname, usernames, projectOrder) {
        store.dispatch("team/loadingInvite");
        const data = [];
        usernames.forEach((username) => {
          data.push({
            team: { teamname: teamname },
            user: { username: username },
            order: parseInt(projectOrder),
          });
        });

        const response = await this.apiPost("/projects/addmembers", data);
        if (response.status === 200) {
          console.debug("invited members:", response.data);
          store.dispatch("team/addTeamMembers", response.data);
        }
        store.dispatch("team/loadedInvite");
        return response;
      },
      async apiUploadArtwork(formData) {
        return await this.apiUpload("/artworks/upload", formData);
      },
      async apiGetArtworks(offset, limit, options) {
        let url = "/artworks" + "?offset=" + offset + "&limit=" + limit;
        if (options) {
          if (options.file_type) {
            url += "&file_type=" + options.file_type;
          }
          if (options.sort) {
            url += "&sort=" + options.sort;
          }
        }

        const response = await this.apiGet(url);
        if (response.status === 200) {
          store.dispatch("portfolio/storeArtworks", response.data);
        }
        return response;
      },
      async apiSetMemberGoal(teamname, username, data) {
        const response = await this.apiPost("/members/" + teamname + "/" + username + "/goal", data);
        return response;
      },
      async apiAddMemberGoalComment(teamname, username, data) {
        const response = await this.apiPost("/members/" + teamname + "/" + username + "/goal/comments", data);
        return response;
      },
      async apiGetMemberGoal(teamname, username, id) {
        const response = await this.apiGet("/members/" + teamname + "/" + username + "/goal/" + id);
        if (response.status === 200) {
          store.dispatch("member/storeCurrent", response.data);
        }
        return response;
      },
      async apiUploadTeamImg(teamname, formData) {
        return await this.apiUpload("/upload/team/taskImage/" + teamname, formData);
      },
      async apiGetMemberGoals(teamname, username, offset, limit, options = null) {
        let url = "/members/" + teamname + "/" + username + "/goal" + "?offset=" + offset + "&limit=" + limit;
        if (options) {
          if (options.status_id) {
            url += "&status_id=" + options.status_id;
          }
          if (options.sort) {
            url += "&sort=" + options.sort;
          }
        }

        const response = await this.apiGet(url);
        if (response.status === 200) {
          if (options) {
            if (options.status_id == 5) {
              store.dispatch("member/storeAchievedGoals", response.data);
            } else {
              store.dispatch("member/storeActiveGoals", response.data);
            }
          } else {
            store.dispatch("member/storeActiveGoals", response.data);
          }
        }
        return response;
      },
      async apiGetMemberAnalyses(teamname, username) {
        const url = "/analyses/" + teamname + "/" + username;

        const response = await this.apiGet(url);

        return response;
      },
      async apiSetMemberAnalyses(teamname, username, data) {
        const response = await this.apiPost("/analyses/" + teamname + "/" + username, data);
        return response;
      },
      async apiSetAnalysesFormat(teamname, data) {
        const response = await this.apiPost("/analyses/" + teamname, data);
        return response;
      },
      async apiGetTeamMember(teamname, username) {
        console.debug("Team", teamname, "User", username);
        const response = await this.apiGet("/teams/" + teamname + "/" + username);
        return response;
      },
    },
  },
};

export default api;
