import Vue from "vue";
import VueI18n from "vue-i18n";
import ja from "./langs/ja";
import en from "./langs/en";

Vue.use(VueI18n);

const messages = {
  ja,
  en,
};

const locale = navigator.language;
console.debug("LOCALE", locale);

const i18n = new VueI18n({
  locale: locale,
  fallbackLocale: "ja",
  messages,
});

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
Vue.i18n = i18n;

export default i18n;
