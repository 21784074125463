import { SkillApi, TeamApi } from "@/client/api";
import { apiConfig } from "@/mixins";

export const getDefaultStateSkill = () => {
  return {
    isLoadingSkill: false,
    isLoading: false,
    isLoadingSkillDetails: false,
    isLoadingQualif: false,
    isLoadingExport: false,
    skillTypes: [],
    skillsByType: {},
    skillDetails: {},
    currentSkillSet: {},
    currentSkillDetail: {},
    currentQualif: {},

    currentSkills: [],
    currentSkillsTotalCount: 0,
    currentSkillsOffset: 0,
    currentSkillsLimit: 0,

    currentQualifs: [],
    currentQualifsTotalCount: 0,
    currentQualifsOffset: 0,
    currentQualifsLimit: 0,

    // items: [],
    // totalRows: 0,
    // page: 0,
    // perPage: 0,
    skills: {},
    details: {},
    pgscore: [],
  };
};

export default {
  namespaced: true,
  state: getDefaultStateSkill(),
  getters: {
    getSkillType: (state) => (skillTypeId) => {
      for (let index = 0; index < state.skillTypes.length; index++) {
        const element = state.skillTypes[index];
        if (element.id == skillTypeId) {
          return element.type;
        }
      }
      return "";
    },
    getSkillTypeName: (state) => (skillType) => {
      for (let index = 0; index < state.skillTypes.length; index++) {
        const element = state.skillTypes[index];
        if (element.type == skillType) {
          return element.name;
        }
      }
      return skillType;
    },
    lenByType: (state) => (skillType) => {
      if (Object.keys(state.skillsByType).includes(skillType)) {
        return state.skillsByType[skillType].length;
      } else {
        return 0;
      }
    },
    lenQualifs: (state) => () => {
      if (Object.keys(state.currentSkillSet).includes("qualifs")) {
        return state.currentQualifs.length;
      } else {
        return 0;
      }
    },
    details: (state) => (username) => {
      if (Object.keys(state.details).includes(username) && Object.keys(state.details[username]).includes("items")) {
        return state.details[username].items;
      } else {
        return [];
      }
    },
    detailTotalRows: (state) => (username) => {
      if (Object.keys(state.details).includes(username) && Object.keys(state.details[username]).includes("totalRows")) {
        return state.details[username].totalRows;
      } else {
        return 0;
      }
    },
    programScore: (state) => () => {
      return state.pgscore;
    },
    getCurrentSkill: (state) => (username) => {
      console.debug("getCurrentSkill", state.skills[username]);
      return state.skills[username];
    },
  },
  mutations: {
    storeProgramScore(state, payload) {
      state.pgscore = payload.data.analytics;
      console.debug("score", state.pgscore);
    },
    dropProgramScore(state) {
      state.pgscore = [];
    },
    storeCurrentSkills(state, payload) {
      var data = {
        items: payload.data.skills,
        totalRows: payload.data.page_info.total_rows,
        page: payload.data.page_info.page,
        perPage: payload.data.page_info.per_page,
      };
      state.skills[payload.username] = data;
      console.debug("Current Skills", state.skills);
    },
    storeCurrentMemberSkillDetails(state, payload) {
      this.storeCurrentSkillDetails(state, payload);
    },
    storeCurrentSkillDetails(state, payload) {
      var data = {
        items: payload.data.details,
        totalRows: payload.data.page_info.total_rows,
        page: payload.data.page_info.page,
        perPage: payload.data.page_info.per_page,
      };
      state.details[payload.username] = data;
      console.debug("Current Skill Details", state.details);
    },
    dropCurrentSkillDetails(state, payload) {
      state.details[payload.username] = {};
    },
    storeSkillTypes(state, skillTypes) {
      state.skillTypes = skillTypes;
    },
    storeSkills(state, data) {
      state.skillsByType = data;
    },
    storeSkillsByType(state, data) {
      state.skillsByType[data.skillType] = data.skills;
    },
    addSkillsByType(state, data) {
      if (!Object.keys(state.skillsByType).includes(data.skillType)) {
        state.skillsByType[data.skillType] = [];
      }
      state.skillsByType[data.skillType].push(data.skill);
    },
    storeCurrentSkillSet(state, data) {
      state.currentSkillSet = data;
    },
    storeCurrentSkillDetail(state, data) {
      state.currentSkillDetail = data;
    },
    storeCurrentQualif(state, data) {
      state.currentQualif = data;
    },
    storeCurrentQualifs(state, data) {
      state.currentQualifs = data.qualifs;
      state.currentQualifsTotalCount = data.total_count;
      state.currentQualifsOffset = data.offset;
      state.currentQualifsLimit = data.limit;
    },
    addQualif(state, data) {
      if (!state.currentQualifs || state.currentQualifs.length == 0) {
        state.currentQualifs = [];
      }
      state.currentQualifs.push(data);
    },
    setQualif(state, data) {
      const max = state.currentQualifs.length;
      for (let index = 0; index < max; index++) {
        const qualif = state.currentQualifs[index];
        if (qualif.id === data.id) {
          state.currentQualifs.splice(index, 1, data);
          break;
        }
      }
    },
    deleteQualif(state, data) {
      const max = state.currentQualifs.length;
      for (let index = 0; index < max; index++) {
        const qualif = state.currentQualifs[index];
        if (qualif.id === data.id) {
          state.currentQualifs.splice(index, 1);
          break;
        }
      }
    },
    storeSkillDetails(state, data) {
      state.skillDetails = data;
    },
    loading(state) {
      state.isLoading = true;
    },
    loaded(state) {
      state.isLoading = false;
    },
    loadingSkill(state) {
      state.isLoadingSkill = true;
    },
    loadedSkill(state) {
      state.isLoadingSkill = false;
    },
    loadingSkillDetails(state) {
      state.isLoadingSkillDetails = true;
    },
    loadedSkillDetails(state) {
      state.isLoadingSkillDetails = false;
    },
    loadingQualif(state) {
      state.isLoadingQualif = true;
    },
    loadedQualif(state) {
      state.isLoadingQualif = false;
    },
    loadingExport(state) {
      state.isLoadingExport = true;
    },
    loadedExport(state) {
      state.isLoadingExport = false;
    },
  },
  actions: {
    async storeCurrentSkills(context, payload = { username: "", page: 1, perPage: 10 }) {
      const response = await new SkillApi(apiConfig()).findSkills(payload.username, payload.page, payload.perPage);
      if (response.status === 200) {
        context.commit("storeCurrentSkills", { username: payload.username, data: response.data });
      } else {
        console.debug("ERROR: findSkills - " + response.status);
      }
    },
    async storeMyProgramScore(context, payload = { username: "", skillType: "", skillOrder: 0, page: 1, perPage: 10 }) {
      context.commit("dropProgramScore");
      const response = await new SkillApi(apiConfig()).findSkillAnalytics(
        payload.username,
        payload.skillType,
        payload.skillOrder,
        payload.page,
        payload.perPage
      );
      if (response.status === 200) {
        context.commit("storeProgramScore", { data: response.data });
      } else {
        console.debug("ERROR: storeMyProgramScore - " + response.status);
      }
      console.debug(response.data);
    },
    async storeProgramScore(
      context,
      payload = { teamname: "", username: "", skillType: "", skillOrder: 0, page: 1, perPage: 10 }
    ) {
      context.commit("dropProgramScore");
      const response = await new TeamApi(apiConfig()).findTeamMemberSkillAnalytics(
        payload.teamname,
        payload.username,
        payload.skillType,
        payload.skillOrder,
        payload.page,
        payload.perPage
      );
      if (response.status === 200) {
        context.commit("storeProgramScore", { data: response.data });
      } else {
        console.debug("ERROR: storeProgramScore - " + response.status);
      }
      console.debug(response.data);
    },
    async storeCurrentMemberSkillDetails(
      context,
      payload = { teamname: "", username: "", skillType: "", skillOrder: 0, page: 1, perPage: 10 }
    ) {
      context.commit("loadingSkillDetails");
      context.commit("dropCurrentSkillDetails", { username: payload.username });
      const response = await new TeamApi(apiConfig()).findTeamMemberSkillDetails(
        payload.teamname,
        payload.username,
        payload.skillType,
        payload.skillOrder,
        payload.page,
        payload.perPage
      );
      if (response.status === 200) {
        context.commit("storeCurrentSkillDetails", { username: payload.username, data: response.data });
      } else {
        console.debug("ERROR: storeCurrentMemberSkillDetails - " + response.status);
      }
      context.commit("loadedSkillDetails");
    },
    async storeCurrentSkillDetails(
      context,
      payload = { username: "", skillType: "", skillOrder: 0, page: 1, perPage: 10 }
    ) {
      context.commit("loadingSkillDetails");
      context.commit("dropCurrentSkillDetails", { username: payload.username });
      const response = await new SkillApi(apiConfig()).findSkillDetails(
        payload.username,
        payload.skillType,
        payload.skillOrder,
        payload.page,
        payload.perPage
      );
      if (response.status === 200) {
        context.commit("storeCurrentSkillDetails", { username: payload.username, data: response.data });
      } else {
        console.debug("ERROR: storeCurrentSkillDetails - " + response.status);
      }
      context.commit("loadedSkillDetails");
    },
    storeSkillTypes(context, skillTypes) {
      context.commit("storeSkillTypes", skillTypes);
    },
    storeSkills(context, data) {
      context.commit("storeSkills", data);
    },
    storeSkillsByType(context, data) {
      context.commit("storeSkillsByType", data);
    },
    addSkillsByType(context, data) {
      context.commit("addSkillsByType", data);
    },
    storeCurrentSkillSet(context, data) {
      context.commit("storeCurrentSkillSet", data);
    },
    storeCurrentSkillDetail(context, data) {
      context.commit("storeCurrentSkillDetail", data);
    },
    storeCurrentQualif(context, data) {
      context.commit("storeCurrentQualif", data);
    },
    storeCurrentQualifs(context, data) {
      context.commit("storeCurrentQualifs", data);
    },
    addQualif(context, data) {
      context.commit("addQualif", data);
    },
    setQualif(context, data) {
      context.commit("setQualif", data);
    },
    deleteQualif(context, data) {
      context.commit("deleteQualif", data);
    },
    storeSkillDetails(context, data) {
      context.commit("storeSkillDetails", data);
    },
    loading(context) {
      context.commit("loading");
    },
    loaded(context) {
      context.commit("loaded");
    },
    loadingSkill(context) {
      context.commit("loadingSkill");
    },
    loadedSkill(context) {
      context.commit("loadedSkill");
    },
    loadingSkillDetails(context) {
      context.commit("loadingSkillDetails");
    },
    loadedSkillDetails(context) {
      context.commit("loadedSkillDetails");
    },
    loadingQualif(context) {
      context.commit("loadingQualif");
    },
    loadedQualif(context) {
      context.commit("loadedQualif");
    },
    loadingExport(context) {
      context.commit("loadingExport");
    },
    loadedExport(context) {
      context.commit("loadedExport");
    },
  },
};
