export const getDefaultStateGoal = () => {
  return {
    totalCount: 0,
    offset: 0,
    limit: 0,
    goals: [],

    closedTotalCount: 0,
    closedOffset: 0,
    closedLimit: 0,
    closedGoals: [],
    current: {},
  };
};

export default {
  namespaced: true,
  state: getDefaultStateGoal(),
  mutations: {
    store(state, data) {
      state.goals = data.issues;
      state.totalCount = data.total_count;
      state.offset = data.offset;
      state.limit = data.limit;
    },
    storeClosed(state, data) {
      state.closedGoals = data.issues;
      state.closedTotalCount = data.total_count;
      state.closedOffset = data.offset;
      state.closedLimit = data.limit;
    },
    storeCurrent(state, data) {
      state.current = data;
    },
  },
  actions: {
    store(context, data) {
      context.commit("store", data);
    },
    storeCurrent(context, data) {
      context.commit("storeCurrent", data);
    },
    storeClosed(context, data) {
      context.commit("storeClosed", data);
    },
  },
};
