import { initializeApp } from "firebase/app";
import { fetchAndActivate, getRemoteConfig, getValue } from "firebase/remote-config";
import {
  createUserWithEmailAndPassword,
  getAuth,
  GithubAuthProvider,
  GoogleAuthProvider,
  onAuthStateChanged,
  sendEmailVerification,
  sendPasswordResetEmail,
  signInWithEmailAndPassword,
  signInWithPopup,
  signInWithRedirect,
  signOut,
  TwitterAuthProvider,
} from "firebase/auth";
import { getAnalytics, logEvent } from "firebase/analytics";
import axios from "axios";

import store from "@/store";
import api from "@/modules/api";
import { PublicApi, TeamApi } from "@/client";
import { apiConfig } from "@/mixins";

const config =
  process.env.VUE_APP_ENV === "dev"
    ? {
        // Development
        apiKey: "AIzaSyAC4k4RkTU_H3BIObMRN1o4tfEUt70_Yrc",
        authDomain: "skimie-dev.firebaseapp.com",
        projectId: "skimie-dev",
        storageBucket: "skimie-dev.appspot.com",
        messagingSenderId: "159001211773",
        appId: "1:159001211773:web:aa79b7b59655bf46169214",
        measurementId: "G-7C1WV25FQJ",
      }
    : process.env.VUE_APP_ENV === "stg"
    ? {
        // Staging
        apiKey: "AIzaSyD5Mhx5uel8WSlglfXRypMVsoAtKnjKflc",
        authDomain: "skimie-stg.firebaseapp.com",
        projectId: "skimie-stg",
        storageBucket: "skimie-stg.appspot.com",
        messagingSenderId: "516125558512",
        appId: "1:516125558512:web:2942d8d4a07930a863a435",
        measurementId: "G-N0MLVEW49S",
      }
    : {
        // Production
        apiKey: "AIzaSyAys77M2Q88TNeCBW7lkPMRf3-XiYq7sR4",
        authDomain: "auth.skimie.com",
        projectId: "skimie",
        storageBucket: "skimie.appspot.com",
        messagingSenderId: "965932447337",
        appId: "1:965932447337:web:0fce1748989bdb9d3f54f8",
        measurementId: "G-PXFPRSSLCQ",
      };

const firebaseApp = initializeApp(config);
const firebaseAuth = getAuth(firebaseApp);
const analytics = getAnalytics(firebaseApp);
const remoteConfig = getRemoteConfig(firebaseApp);
// デフォルト２時間
remoteConfig.settings.minimumFetchIntervalMillis = 10000;

const SK_FRONTEND_URL = process.env.VUE_APP_API_URL ? "http://localhost:8080" : "https://" + window.location.host;

const storeIdToken = async (user) => {
  // console.debug("StoreIdToken:", user);

  const idToken = await user.getIdToken(/* forceRefresh */ false).catch((error) => {
    console.debug("failed auth", error);
    store.dispatch("alert/showError", "ログインに失敗しました。しばらく経ってから、再度お試しください。");
    store.dispatch("drop");
    return null;
  });
  if (idToken) {
    await store.dispatch("user/store", {
      uid: user.uid,
      token: idToken,
      refreshToken: user.refreshToken,
    });
    await store.dispatch("user/me");
  }
};

export const refreshIdToken = async () => {
  const params = new URLSearchParams();
  params.append("grant_type", "refresh_token");
  params.append("refresh_token", store.getters["user/refreshToken"]());
  const response = await axios
    .post(`https://securetoken.googleapis.com/v1/token?key=${config.apiKey}`, params, {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    })
    .catch((e) => {
      console.debug("refreshIdToken:", e);
      return { status: e.response.status, data: e.response.data };
    });

  if (response.status === 200) {
    console.debug("SUCCESS: Refresh idToken");
    await store.dispatch("user/storeIdToken", {
      token: response.data.id_token,
      refreshToken: response.data.refresh_token,
    });
  } else {
    console.debug("ERROR: Refresh idToken");
  }
};

const auth = {
  beforeEnter: {
    requiresAuth(to, from, next) {
      onAuthStateChanged(firebaseAuth, async (user) => {
        console.debug("RequiresAuth, From:", from, ", To:", to);
        if (user) {
          await storeIdToken(user);
          const me = store.getters["user/me"]();
          const username = me.username;
          console.debug("Me:", me);

          if (!user.emailVerified) {
            if (to.name === "account") {
              next();
              return;
            }
            store.dispatch("alert/showError", "メールアドレスを認証してください。");
            next({ name: "account" });
            return;
          }

          if (to.name.substring(0, "teams".length) === "teams") {
            const teamname = to.params.teamname;
            if (!teamname) {
              next();
              return;
            }
            console.debug("Auth Team", teamname, "User", username);
            const response = await api.mixins.methods.apiGetTeamMember(teamname, username);
            if (response.status === 200) {
              if (response.data["accepted"]) {
                if (to.name === "teams-teamname") {
                  next({ name: "teams-teamname-task", params: to.params });
                  return;
                }
              } else {
                if (to.name !== "teams-teamname") {
                  next({ name: "teams-teamname", params: to.params });
                  return;
                }
              }
            } else {
              const resp = await new TeamApi(apiConfig()).getTeamDescription(teamname).catch((error) => {
                if (error.response) {
                  return { status: error.response.status, data: error.response.data };
                }
              });
              if (resp.status === 200 && resp.data.is_public) {
                if (to.name !== "teams-teamname") {
                  next({ name: "teams-teamname", params: to.params });
                  return;
                } else {
                  next();
                  return;
                }
              } else {
                next({ name: "404" });
                return;
              }
            }
          }
          next();
        } else {
          if (to.name.substring(0, "teams".length) === "teams") {
            const teamname = to.params.teamname;
            if (teamname) {
              const resp = await new PublicApi(apiConfig()).getPublicTeam(teamname);
              if (resp.status === 200 && resp.data.is_public) {
                if (to.name !== "teams-teamname") {
                  next({ name: "teams-teamname", params: to.params });
                  return;
                } else {
                  next();
                  return;
                }
              } else {
                next({ name: "404" });
                return;
              }
            }
          }

          next({ path: "/login", query: { redirect: to.fullPath } });
        }
      });
    },
    alreadyAuth(to, from, next) {
      console.debug("AlreadyAuth, From:", from.path, ", To:", to.path);
      const redirect = to.query.redirect;
      console.debug("Redirect:", redirect);

      onAuthStateChanged(firebaseAuth, async (user) => {
        if (user) {
          await storeIdToken(user);
          if (redirect) {
            next(redirect);
          } else {
            next("/");
          }
        } else {
          next();
        }
      });
    },
  },
  mixins: {
    watch: {
      $route(to, from) {
        console.debug("Auth.js From:", from.path, ", To:", to.path);
        this.onAuthStateChanged();
      },
    },
    mounted() {
      console.debug("Auth.js Mounted");
      this.onAuthStateChanged();
    },
    methods: {
      onAuthStateChanged() {
        onAuthStateChanged(firebaseAuth, async (user) => {
          console.debug("onAuthStateChanged", user);
          if (user) {
            await storeIdToken(user);
          } else {
            store.dispatch("view/closeChatSocket");
            store.dispatch("drop");
          }
          store.dispatch("view/loaded");
        });
      },
      signInWithGoogle() {
        const provider = new GoogleAuthProvider();
        provider.addScope("profile");
        provider.addScope("email");
        signInWithPopup(firebaseAuth, provider);
        // See: https://github.com/firebase/firebase-js-sdk/issues/6716
        // signInWithRedirect(firebaseAuth, provider);
        fireLogEvent("login", { method: "Google" });
      },
      signInWithTwitter() {
        const provider = new TwitterAuthProvider();
        signInWithRedirect(firebaseAuth, provider);
        fireLogEvent("login", { method: "Twitter" });
      },
      signInWithGitHub() {
        const provider = new GithubAuthProvider();
        signInWithRedirect(firebaseAuth, provider);
        fireLogEvent("login", { method: "GitHub" });
      },
      signInWithApple() {
        const provider = new GithubAuthProvider();
        signInWithRedirect(firebaseAuth, provider);
        fireLogEvent("login", { method: "Apple" });
      },
      async signIn(email, password) {
        const result = await signInWithEmailAndPassword(firebaseAuth, email, password).catch(function (error) {
          // Handle Errors here.
          var errorCode = error.code;
          var errorMessage = error.message;
          if (errorCode === "auth/wrong-password") {
            console.debug(errorMessage);
            store.dispatch("alert/showError", "メールアドレスまたはパスワードが誤っています。");
          } else {
            console.debug(errorMessage);
            store.dispatch("alert/showError", "メールアドレスまたはパスワードが誤っています。");
          }
          console.debug(error);
          return null;
        });
        if (result && result.user) {
          fireLogEvent("login", { method: "Email" });
          const redirect = this.$route.query.redirect;
          console.debug("SignIn Redirect:", redirect);
          await storeIdToken(result.user);
          if (redirect) {
            this.pushTo(redirect);
          }
        }
      },
      async signUp(email, password) {
        const r = await createUserWithEmailAndPassword(firebaseAuth, email, password).catch((error) => {
          console.debug(error.message);
          store.dispatch("alert/showError", "登録に失敗しました。しばらく経ってから再度おためしください。");
          return null;
        });
        if (r && r.user) {
          fireLogEvent("sign_up", { method: "Email" });
          await storeIdToken(r.user);
          sendEmailVerification(r.user, { url: `${SK_FRONTEND_URL}/settings/account?emailVerified=true` });
          const redirect = this.$route.query.redirect;
          console.debug("SignUp Redirect:", redirect);
          if (redirect) {
            this.pushTo(redirect);
          }
        }
      },
      async signOut() {
        await signOut(firebaseAuth);
      },
      resetPassword: function (email) {
        sendPasswordResetEmail(firebaseAuth, email);
      },
      pushTo(path) {
        if (path) {
          this.$router.push({ path: path }).catch((error) => {
            console.debug(error);
          });
        } else {
          this.$router.push({ path: "/" }).catch((error) => {
            console.debug(error);
          });
        }
      },
      emailVerified() {
        const emailVerified = firebaseAuth.currentUser.emailVerified;
        console.debug("EmailVerified: " + emailVerified);
        return emailVerified;
      },
      sendEmailVerification() {
        sendEmailVerification(firebaseAuth.currentUser, {
          url: `${SK_FRONTEND_URL}/settings/account?emailVerified=true`,
        });
      },
      getCurrentUserEmail() {
        return firebaseAuth.currentUser.email;
      },
    },
  },
};

export default auth;

export const isBeta = () => {
  const val = getValue(remoteConfig, "is_beta");
  console.debug("IS_BETA", val, val.asBoolean());
  return val.asBoolean();
};

export const getSystemNotices = () => {
  const key = "system_notices";
  const val = getValue(remoteConfig, key);
  const str = val.asString();
  console.debug("SYSTEM_NOTICES", key, val, `"${str}"`);
  if (str) {
    return JSON.parse(str);
  } else {
    return {};
  }
};

export const fireLogEvent = (eventName = "", options = undefined) => {
  logEvent(analytics, eventName, options);
};

var activated = false;
export const remoteConfigFetchActivated = async () => {
  if (!activated) {
    if (await fetchAndActivate(remoteConfig)) {
      activated = true;
    } else {
      console.debug("Already activated");
      activated = true;
    }
  }
  return activated;
};
