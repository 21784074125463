import { required, confirmed, min, email } from "vee-validate/dist/rules";
import { extend } from "vee-validate";

extend("required", {
  ...required,
  message: "このフィールドは必須項目です。",
});

extend("email", {
  ...email,
  message: "メールアドレスの形式に誤りがあります。",
});

extend("confirmed", {
  ...confirmed,
  message: "入力した内容が一致していません。",
});

extend("min", {
  ...min,
  message: "長さが不足しています。",
});

extend("rangeover", {
  // エラーメッセージを設定する
  message: "100以下で割り振ってください。",
  validate(value) {
    console.debug("rangeover validate:", value);
    return value <= 100;
  },
});
