export const getDefaultStateTask = () => {
  return {
    totalCount: 0,
    offset: 0,
    limit: 0,
    tasks: [],

    progressTotalCount: 0,
    progressOffset: 0,
    progressLimit: 0,
    progressTasks: [],

    closedTotalCount: 0,
    closedOffset: 0,
    closedLimit: 0,
    closedTasks: [],

    allTotalCount: 0,
    allOffset: 0,
    allLimit: 0,
    allTasks: [],

    current: {},
    search: {},
  };
};

export default {
  namespaced: true,
  state: getDefaultStateTask(),
  // {"issues":[],"total_count":0,"offset":0,"limit":25}
  mutations: {
    store(state, data) {
      state.tasks.splice(0);
      state.tasks.splice(0, 0, ...(data.issues ? data.issues : []));
      state.totalCount = data.total_count;
      state.offset = data.offset;
      state.limit = data.limit;
    },
    storeClosed(state, data) {
      state.closedTasks.splice(0);
      state.closedTasks.splice(0, 0, ...(data.issues ? data.issues : []));
      state.closedTotalCount = data.total_count;
      state.closedOffset = data.offset;
      state.closedLimit = data.limit;
    },
    storeProgress(state, data) {
      state.progressTasks.splice(0);
      state.progressTasks.splice(0, 0, ...(data.issues ? data.issues : []));
      state.progressTotalCount = data.total_count;
      state.progressOffset = data.offset;
      state.progressLimit = data.limit;
    },
    storeAll(state, data) {
      state.allTasks.splice(0);
      state.allTasks.splice(0, 0, ...(data.issues ? data.issues : []));
      state.allTotalCount = data.total_count;
      state.allOffset = data.offset;
      state.allLimit = data.limit;
    },
    storeCurrent(state, data) {
      state.current = data;
    },
    drop(state) {
      state.totalCount = 0;
      state.tasks.splice(0);
      state.closedTotalCount = 0;
      state.closedTasks.splice(0);
      state.allTotalCount = 0;
      state.allTasks.splice(0);
      state.current = {};

      state.progressTotalCount = 0;
      state.progressOffset = 0;
      state.progressLimit = 0;
      state.progressTasks.splice(0);
    },
  },
  actions: {
    store(context, data) {
      context.commit("store", data);
    },
    storeCurrent(context, data) {
      context.commit("storeCurrent", data);
    },
    storeClosed(context, data) {
      context.commit("storeClosed", data);
    },
    storeProgress(context, data) {
      context.commit("storeProgress", data);
    },
    storeAll(context, data) {
      context.commit("storeAll", data);
    },
    drop(context) {
      context.commit("drop");
    },
  },
};
