import { Configuration } from "@/client";
import store from "@/store";

/**
 * API設定
 * @returns {Configuration}
 */
export const apiConfig = (): Configuration => {
  const lang = navigator.language;
  return new Configuration({
    basePath: process.env.VUE_APP_API_URL + "/api/v2",
    accessToken: store.getters["user/token"](),
    baseOptions: {
      headers: {
        "Accept-Language": lang,
      },
    },
  });
};

/**
 * ページタイトル設定
 * @param {string} pathTitle
 * @returns {string}
 */
export const setTitle = (pathTitle: string): string => {
  const siteTitle = "Skimie";
  const pageTitle = !pathTitle ? siteTitle : pathTitle + " | " + siteTitle;
  return (window.document.title = pageTitle);
};

/**
 * Description設定
 * @param {string} pathMeta
 */
export const setDescription = (pathMeta: string) => {
  const defaultDescription = "チームのスキルを見える化";
  const description = pathMeta ? pathMeta : defaultDescription;
  document.querySelector("meta[name='description']")?.setAttribute("content", description);
};

const defaultTitle = "Skimie";
const defaultDescription =
  "ソフトウェア開発におけるプログラマー・システムエンジニアといった技術者や、アートワークやデザイン・イラストレーションなどのスキルを管理し、可視化します。";
const defaultImageUrl =
  "https://skimie.com/uploads/t/1/2021/06/30/1119227206d02f5595705a65d2737fd35bec5936ee892cdec.jpeg";

const defaultMetaTags = [
  {
    name: "description",
    content: defaultDescription,
  },
  {
    property: "og:url",
    content: "https://skimie.com",
  },
  {
    property: "og:type",
    content: "website",
  },
  {
    property: "og:title",
    content: defaultTitle,
  },
  {
    property: "og:description",
    content: defaultDescription,
  },
  {
    property: "og:site_name",
    content: defaultTitle,
  },
  {
    property: "og:image",
    content: defaultImageUrl,
  },
  {
    name: "twitter:card",
    content: "summary_large_image",
  },
  {
    name: "twitter:site",
    content: "@skimieapp",
  },
  {
    name: "twitter:title",
    content: defaultTitle,
  },
  {
    name: "twitter:description",
    content: defaultDescription,
  },
  {
    name: "twitter:image",
    content: defaultImageUrl,
  },
];

/**
 * Metaタグの設定
 */
export const setMetaTags = (metaTags = defaultMetaTags) => {
  metaTags;
};

export const global = {
  mixins: {
    methods: {
      setTitle,
      setDescription,
      setMetaTags,
    },
  },
};
